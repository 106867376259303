import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../common.service';
import * as CryptoJS from 'crypto-js';
import * as jwt_decode from "jwt-decode";

@Injectable({
    providedIn: 'root'
})


export class LoginService {

    constructor(
        public commonService: CommonService,
        public router: Router,
    ) { }

    // Login With Application
    public loginUser(username: string, password: string, companyname: string, env: string) {
        let query = '';
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(username)) {
            query = '&filter[where][email]=' + username + '' + '&filter[where][companyname]=' + companyname + '' + '&filter[where][environment]=' + env;
        } else {
            query = '&filter[where][username]=' + username + '' + '&filter[where][companyname]=' + companyname + '' + '&filter[where][environment]=' + env;
        }
        return this.commonService.getHttpReqNodeapi_login('users/login?filter[type]=login&filter[where][password]=' + password + query);
    }

    // Get User By Email Google login, SSO login
    public getUser(username: string, companyname: string, env: string) {
        return this.commonService.getHttpReqNodeapi_login(`users/login?filter[where][loginType]=google&filter[where][email]=${username}&filter[where][companyname]=${companyname}&filter[where][environment]=${env}`);
    }

    // Set User Data in localstorage
    public setLoginData(data: any) {
      localStorage.setItem('loginData', CryptoJS.AES.encrypt(JSON.stringify(data), 'loginData').toString());
      // localStorage.setItem("loginData", data);
    }

    // Set Domain URL
    public setDomainUrl(url: any) {
        localStorage.setItem("companydomain", url);
    }

    // First API run ... check Company Status with JWT token
    public checkCompanyStatus(data: any) {
        return this.commonService.postHttpReqNodeapi_Logs('companies_frontend/checkStatus', data);
    }
    
    public checkDomain(domainName: any) {
        return this.commonService.getHttpReqNodeapi_withoutToken(`siteSettings?filter[where][domainName]=${domainName}`);
    }

    // Call Encrypt JWT Token into AES Token
    public setAuthToken(token: string) {
        localStorage.setItem('authToken', this.encryptAesToken(token));
    }

    // Encrypt AES Token
    private encryptAesToken(token: string) {
        return CryptoJS.AES.encrypt(token, 'authToken').toString();
    }

    // Decrypt AES Token
    private decryptAesToken(token: string) {
        return CryptoJS.AES.decrypt(token, 'authToken').toString(CryptoJS.enc.Utf8);
    }

    // Call Decrypt AES Token into JWT Token
    public getAuthToken() {
        return this.decryptAesToken(localStorage.getItem("authToken"));
    }

    // Get Date & time of token Expired
    private getTokenExpirationDate(token: string): Date {
        const decoded = jwt_decode(token);

        if (decoded.exp === undefined) return null;

        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }

    // Check whether token is expired or not
    public isTokenExpired(token?: string): boolean {
        if (!token) token = this.getAuthToken();
        if (!token) return true;

        const date = this.getTokenExpirationDate(token);
        if (date === undefined) return false;
        return !(date.valueOf() > new Date().valueOf());
    }

    public logout(): void {
        localStorage.removeItem("loginData");
        localStorage.removeItem("authToken");
        this.router.navigate(["login"]);
    }


    /******************************************** Frontend user Log ****************************************************/

    // Get User Login & logout Logs
    public getUserLog(userId: any) {
        return this.commonService.getHttpReqNodeapi('frontend_user_log?filter[offset]=0&filter[limit]=1000000&filter[where][frontend_user_id]=' + userId);
        // return this.commonService.getHttpReqNodeapi('frontend_user_log?filter[offset]=0&filter[limit]=10&filter[where][frontend_user_id]='+userId+'&filter[where][action]='+action);
    }

    // Get Single latest User Log
    public getUserLastLog(userId: any, action: string) {
        return this.commonService.getHttpReqNodeapi('frontend_user_log?filter[offset]=1&filter[limit]=1&filter[where][frontend_user_id]=' + userId + '&filter[where][action]=' + action);
    }

    // Post User login & Logout Logs
    public postUserLog(data: any) {
        return this.commonService.postHttpReqNodeapi_Logs('frontend_user_log', data);
    }

    // Get IP Address Of User
    public getIPAddress() {
        return this.commonService.getIPAdressRequest("https://ipapi.co/json/");
    }

    // Forgot User Password
    public forgotPassword(data: any) {
        return this.commonService.postHttpReqNodeapi('users/forgetPwd', data);
    }



}
