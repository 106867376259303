import { LoginService } from './api-service/login-service/login.service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HeadingtitleService } from './headingtitle.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { NzNotificationService } from 'ng-zorro-antd/notification';
@Component({
    selector: 'body',
    template: `
        <div *ngIf="loadingSite" class="h-100">
            <div class="d-flex align-content-stretch" style="justify-content: center;align-items:center;height:100vh;" >
                <div class="spinner-border" role="status" >
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <router-outlet *ngIf="!loadingSite"></router-outlet> 
    `
})


export class AppComponent {

    public loadingSite: boolean = false; public hostname: string;
    public companyname: string;  public trialVersionDate: number; public isTraining: boolean = false;
    public evnname: string;

    public favIcon: HTMLLinkElement = document.querySelector('#appIcon');

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private headingtitleService: HeadingtitleService,
        private loginService: LoginService,
        private titleService: Title,
        private notification: NzNotificationService,
    ) {
        this.loadingSite = true;
        if (!(localStorage.getItem('geoLoc') && localStorage.getItem('geoLoc') != undefined && localStorage.getItem('geoLoc') != null)) {
            navigator.geolocation.getCurrentPosition(pos => {
                localStorage.setItem('geoLoc', JSON.stringify({ 'longitude': +pos.coords.longitude, 'latitude': +pos.coords.latitude, 'zoom': 6 }));
            });
        }
    }

    ngOnInit() {
        
        // Developer Purpose Login
        if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
            this.companyname = 'groupsoft';
            this.evnname = environment.ENVIRONMENT.title;
            // Uncomment or comment when we want to use grcarapp in local machine
            this.hostname = 'grcarapp.com';
            // Uncomment or comment when we want to use compliancecart in local machine
            // this.hostname = 'compliancecart.com';
            // this.hostname = 'hourstock.com';
            this.loginService.setDomainUrl('https://'+this.companyname+'.'+this.evnname+'.'+this.hostname);
        } else {
            this.loginService.setDomainUrl(location.origin); // Set Full Url with SiteUrl
            if ((window.location.hostname.toString().split(".")).length > 3) {   // For dev, qa, training
                this.companyname = window.location.hostname.toString().split(".")[0];
                this.evnname = window.location.hostname.toString().split(".")[1];
                this.hostname = window.location.hostname.toString().split(".")[2] + '.' + window.location.hostname.toString().split(".")[3];
            } else {   //  For Prod
                this.companyname = window.location.hostname.toString().split(".")[0];
                this.evnname = 'prod';
                this.hostname = window.location.hostname.toString().split(".")[1] + '.' + window.location.hostname.toString().split(".")[2];
            }
        }
        localStorage.setItem('evnname', this.evnname);
        localStorage.setItem('companyname', this.companyname);
        localStorage.setItem('hostname', this.hostname);

        this.checkDomain(this.hostname);
        this.checkCompanyStatus(this.evnname, this.companyname); // check evnironment is vaild or not
        this.getIPAddress();
        
        // this.router.events.pipe(
        //     filter(event => event instanceof NavigationEnd),
        // ).subscribe(() => {
        //     let rt = this.getChild(this.activatedRoute);
        //     rt.data.subscribe(data => {
        //         this.titleService.setTitle(data.title)
        //     })
        // });
        
        // Favicon
        if (this.hostname === 'grcarapp.com') {
            this.favIcon.href = 'https://grcarapp-email-images-s3bucket.s3.amazonaws.com/favicon/groupsofticon.ico';
        } else if (this.hostname === 'hourstock.com') {
            this.favIcon.href = 'https://grcarapp-email-images-s3bucket.s3.amazonaws.com/favicon/hourstock.ico';
        } else if (this.hostname === 'compliancecart.com') {
            this.favIcon.href = 'https://grcarapp-email-images-s3bucket.s3.amazonaws.com/favicon/compliancecart.ico';
        }
        
        setTimeout(() => {
            this.loadingSite = false;
        }, 1500);
    }

    // getChild(activatedRoute: ActivatedRoute) {
    //     if (activatedRoute.firstChild) {
    //         return this.getChild(activatedRoute.firstChild);
    //     } else {
    //         return activatedRoute;
    //     }
    // }
    
    private getIPAddress():any {
        this.loginService.getIPAddress().subscribe((res: any) => {
            localStorage.setItem("ip", JSON.stringify(String(res.ip)));
        });
    }

    private checkDomain(domainName: string):void {
        this.loginService.checkDomain(domainName).subscribe(res => {
            if (res['success'] === true) {
                localStorage.setItem("siteSettings", JSON.stringify(res['data'][0]));
                this.titleService.setTitle(res['data'][0].headWebsiteTitle);
            } else {
                this.titleService.setTitle('Grcarapp - Digital Collaboration AI Platform');
            }
        });
    }
   
    private checkCompanyStatus(env: string, siteurl: string):void {
        this.loginService.checkCompanyStatus({ "environment": env, "siteurl": siteurl }).subscribe(res => {
            if (res['success'] === true) {
                if (env === 'training') {
                    this.isTraining = res['data']['training'] === 1 ? true : false;
                    this.trialVersionDate = res['data']['training'] === 1 ? this.headingtitleService.showTrialDays(res['data']['trialversionExpiryDate']) : 0;
                } else {
                    this.isTraining = false;
                }
            } else {
                const hostName = JSON.parse(localStorage.getItem('siteSettings'));
                const environment = (env === 'dev' ? 'Development' : '') || (env === 'qa' ? 'Quality Assurance' : '') || (env === 'prod' ? 'Production' : '') || (env === 'training' ? 'Training' : '')
                this.notification.blank(
                    `${hostName.applicationName === null ? 'Grcarapp':hostName.applicationName} Subscription`,
                    `Sorry, you are not subscribed to the ${hostName.applicationName === null ? 'Grcarapp':hostName.applicationName} ${environment} Environment !`,
                    { nzDuration: 0, nzPlacement: 'bottomRight' }
                );
            }
        });
    }

}