
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from './../api-service/login-service/login.service';
import { HeadingtitleService } from '../headingtitle.service';

@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements CanActivate {

    constructor(
        public loginService: LoginService,
        public router: Router,
        public HeadingtitleService: HeadingtitleService,
    ) { }


    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.loginService.isTokenExpired()) {
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationStart) {
                    window.localStorage.setItem('previousUrl', this.router.url);
                }
            });            
            return true;
        } else {
            this.router.navigate(["login"]);
            return false;
        }
    }



}

