
    <!-- <nz-badge [nzCount]="totalNotificationUnreadCount" [nzOverflowCount]="9" style="margin-right:5px;" > -->
        <!-- <button nz-button nzType="text" nz-dropdown nzTrigger="click" [nzDropdownMenu]="notifyList" (click)="readSwitchChange()" [nzPlacement]="'bottomRight'" style="margin-right:0px;"> -->
            <nz-badge class="pointer"  [nzCount]="sharedService.getUnReadNotificationCount()" style="margin-right:6px;" nz-dropdown nzTrigger="click" [nzDropdownMenu]="notifyList" (click)="readSwitchChange()">
                <i nz-icon nzType="bell" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'" style="font-size: 24px;"></i>
            </nz-badge>
        <!-- </button> -->
    <!-- </nz-badge> -->
    <nz-dropdown-menu #notifyList="nzDropdownMenu">
        <div class="notificationWrapper">
            <div class="notification-header">
                <div nz-row [nzGutter]="[2]">
                    <div nz-col [nzSpan]="12">
                        <h5 class="m-b-0">Notifications</h5>
                    </div>
                    <div nz-col [nzSpan]="12" class="text-right"><span class="markAllread">Only show unread</span>&nbsp;
                        <nz-switch nzSize="small" [(ngModel)]="switchValue"
                            [nzCheckedChildren]="checkedTemplate"
                            [nzUnCheckedChildren]="unCheckedTemplate"
                            (ngModelChange)="readSwitchChange()">
                        </nz-switch>
                    </div>
                    <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                    <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                </div>
            </div>
            <div class="notification-Tab">
                <div class="text-center p-20" *ngIf="loadingNotification">
                    <ng-template #indicatorTemplate><i nz-icon nzType="loading" class="font-27"></i></ng-template>
                    <nz-spin nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
                </div>

                <nz-tabset class="custom-notification-tab" *ngIf="!loadingNotification">
                    <nz-tab *ngFor="let nt of notifications; let i=index; trackBy: trackByFn;" [nzTitle]="titleTemplate">
                        <div class="notification-dropdown">
                            <nz-list [nzBordered]="false" nzSize="small" class="pointer">
                                <ng-container *ngFor="let n of nt.notificationList">
                                    <div nz-row [nzGutter]="[2]" class="notification-group" *ngIf="n.groupNotificationList.length > 0">
                                        <div nz-col [nzSpan]="12">
                                            <h2>{{n.dateGroup}}</h2>
                                        </div>
                                        <div nz-col [nzSpan]="12" class="text-right">
                                            <h2 class="markAllread" (click)="markAllReadCompCart(n.dateGroup, nt.applicationId)">Mark all as read</h2>
                                        </div>
                                    </div>
                                    <nz-list-item *ngFor="let notification of n.groupNotificationList">
                                        <div *ngIf="!notification.isSeen" class="dotBtnPosition" (click)="markSingleNotificationAsRead(notification.notificationId, nt.applicationId)">
                                            <span nz-tooltip nzTooltipTitle="Mark as read" class="dotBtn">
                                              <span nz-icon nzType="read" nzTheme="fill"></span>
                                            </span>
                                        </div>
                                        <nz-list-item-meta>
                                            <nz-list-item-meta-avatar nzSrc="{{getimg(notification.imgUrl)}}" crossorigin="anonymous"></nz-list-item-meta-avatar>
                                            <nz-list-item-meta-title>{{notification.notificationText}} <i *ngIf="notification.icon != undefined || notification.icon != null" nz-icon nzType="{{notification.icon}}" nzTheme="outline"></i></nz-list-item-meta-title>
                                            <nz-list-item-meta-title class="subtitle">
                                                {{notification.notificationDesc}}
                                            </nz-list-item-meta-title>
                                            <nz-list-item-meta-description>
                                                {{notification.moduleName}}
                                                <nz-divider nzType="vertical" *ngIf="notification.notificationDate != undefined || notification.notificationDate != null"></nz-divider>
                                                {{notification.notificationDate === null ? '': notification.notificationDate | timeAgo}}
                                            </nz-list-item-meta-description>
                                        </nz-list-item-meta>
                                    </nz-list-item>
                                </ng-container>
                            </nz-list>
                            <div class="emptyView" *ngIf="getApplicationWiseCount(nt.applicationId) === 0">
                                <nz-empty [nzNotFoundContent]="'You have read all the notifications'"></nz-empty>
                            </div>
                        </div>
                        <ng-template #titleTemplate>
                            <span class="postion-relative">
                                {{nt.applicationName}}
                                <!-- <span class="appCount" >{{getApplicationWiseCount(nt.applicationId)}}</span> -->
                                <!-- {{nt.applicationName}} <span class="appCount" *ngIf="getApplicationWiseCount(nt.applicationId) != 0">{{getApplicationWiseCount(nt.applicationId)}}</span> -->
                            </span>
                        </ng-template>
                    </nz-tab>
                </nz-tabset>
            </div>

        </div>
    </nz-dropdown-menu>
