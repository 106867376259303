

<div *ngIf="loadingSite" class="h-100 w-100">
    <div class="d-flex align-content-stretch" style="justify-content: center;align-items:center;height:100vh;" >
        <div class="spinner-border" role="status" >
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>

<ng-container *ngIf="!loadingSite">

    <ngx-loading-bar [color]="'blue'"></ngx-loading-bar>

    <div *ngIf="!isConnected" >
        <app-overlay-model [template]="'InternetConnection'" [siteSettings]="siteSettings"></app-overlay-model>
    </div>

    <app-carousel-slide [slides]="slides"></app-carousel-slide>

    <div *ngIf="siteSettings[0]?.domainName === 'grcarapp.com' || siteSettings[0]?.domainName === 'compliancecart.com'" nz-row [nzGutter]="[2]" style="position:absolute;top:0;left:0;z-index:1;">
        <div nz-col [nzSpan]="24" *ngIf="siteSettings[0]?.siteLogoLarge != null">
            <img src="../../assets/img/{{siteSettings[0]?.siteLogoLarge}}" [ngStyle]="{'width': siteSettings[0]?.domainName === 'grcarapp.com' ? '25%':'40%', 'padding':'15px' }" />
        </div>
    </div>

    <div class="container-fluid contentSection" style="padding:0px !important;">    
        <div nz-row [nzGutter]="[2]">
            <div *ngIf="siteSettings[0]?.domainName === 'hourstock.com'" class="container-fluid contentSection" style="padding:0px !important;">
                <div nz-col [style.margin]="siteSettings[0]?.domainName != 'grcarapp.com' ? '0px auto':''" [nzXs]="{ span: 5, offset: 5 }" [nzSm]="{ span: 5, offset: 5 }" [nzMd]="{ span: 5, offset: 5 }" [nzLg]="{ span: 7, offset: 5 }" [nzXl]="{ span: 5, offset: 5 }" [nzXXl]="{ span: 5, offset: 5 }" class="contentloginSection" [ngClass]="siteSettings[0]?.domainName != 'grcarapp.com' ? '':'overlayer-Color'">
                    <div class="loginContainer">
                        <div nz-row class="bg-inverse text-center ant-row ng-star-inserted" style="margin-left: -1px; margin-right: -1px;">
                            <div nz-col class="ant-col ant-col-24" style="padding-left: 1px; padding-right: 1px;">
                                <img  src="https://grcarapp-email-images-s3bucket.s3.amazonaws.com/hourstock/Hourstock_logo_whitetagline.png" style="width: 240px;">
                            </div>
                        </div> 
                        <nz-card [nzBordered]="false" nzTitle="Set Password" class="animated flipInY">
                            <form [formGroup]="pwSetForm" (ngSubmit)="setPassword()">
                                <p class="loginDesc">Please enter and confirm your new password below to access your account ! </p>
                                <div class="w-100 text-center m-b-5" *ngIf="is_verifyload">
                                    <div class="spinner-border" role="status" >
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <div nz-row [nzGutter]="[10]" class="m-b-10" *ngIf="!is_verifyload">
                                    <div nz-col [nzSpan]="24">
                                        <nz-form-item>
                                            <nz-form-control [nzErrorTip]="setPwErrorTpl">
                                                <input nz-input type="password" placeholder="Set Password" formControlName="password" name="password">
                                                <ng-template #setPwErrorTpl let-control>
                                                    <ng-container *ngIf="control.hasError('required')"> Set Password is required!<br/></ng-container>
                                                    <ng-container *ngIf="control.hasError('pattern')"> Atleast 1 uppercase, lowercase, number & special character password ! </ng-container>
                                                </ng-template>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                    <div nz-col [nzSpan]="24">
                                        <nz-form-item>
                                            <nz-form-control [nzErrorTip]="'Confirm Password is required!'">
                                                <input nz-input type="password" placeholder="Confirm Password" formControlName="confirmpassword" name="confirmpassword">
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                                <button type="submit" class="credLogin btn">Set Password</button>
                            </form>
                        </nz-card>
                        <p *ngIf="bckTolog" class="bckTolog animated bounceInLeft" routerLink="/login" [state]="{ ignoreLoadingBar: true }">
                            <i nz-icon nzType="arrow-left" nz-Theme="outline"></i> Way to Login <i nz-icon nzType="lock" nz-Theme="outline"></i>
                        </p>
                    </div>
                </div>
            </div>
            <div *ngIf="siteSettings[0]?.domainName === 'compliancecart.com' || siteSettings[0]?.domainName === 'grcarapp.com'" class="container-fluid contentSection" style="padding:0px !important;">
                <div nz-col [style.margin]="siteSettings[0]?.domainName != 'grcarapp.com' ? '0px auto':''" [nzXs]="{ span: 5, offset: 5 }" [nzSm]="{ span: 5, offset: 5 }" [nzMd]="{ span: 5, offset: 5 }" [nzLg]="{ span: 7, offset: 5 }" [nzXl]="{ span: 5, offset: 5 }" [nzXXl]="{ span: 5, offset: 5 }" class="contentloginSection" [ngClass]="siteSettings[0]?.domainName != 'grcarapp.com' ? '':'overlayer-Color'">
                    <div class="loginContainer"> 
                        <nz-card [nzBordered]="false" nzTitle="Set Password" class="animated flipInY">
                            <form [formGroup]="pwSetForm" (ngSubmit)="setPassword()">
                                <p class="loginDesc">Please enter and confirm your new password below to access your account ! </p>
                                <div class="w-100 text-center m-b-5" *ngIf="is_verifyload">
                                    <div class="spinner-border" role="status" >
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <div nz-row [nzGutter]="[10]" class="m-b-10" *ngIf="!is_verifyload">
                                    <div nz-col [nzSpan]="24">
                                        <nz-form-item>
                                            <nz-form-control [nzErrorTip]="setPwErrorTpl">
                                                <input nz-input type="password" placeholder="Set Password" formControlName="password" name="password">
                                                <ng-template #setPwErrorTpl let-control>
                                                    <ng-container *ngIf="control.hasError('required')"> Set Password is required!<br/></ng-container>
                                                    <ng-container *ngIf="control.hasError('pattern')"> Atleast 1 uppercase, lowercase, number & special character password ! </ng-container>
                                                </ng-template>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                    <div nz-col [nzSpan]="24">
                                        <nz-form-item>
                                            <nz-form-control [nzErrorTip]="'Confirm Password is required!'">
                                                <input nz-input type="password" placeholder="Confirm Password" formControlName="confirmpassword" name="confirmpassword">
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                                <button type="submit" class="credLogin btn">Set Password</button>
                            </form>
                        </nz-card>
                        <p *ngIf="bckTolog" class="bckTolog animated bounceInLeft" routerLink="/login" [state]="{ ignoreLoadingBar: true }">
                            <i nz-icon nzType="arrow-left" nz-Theme="outline"></i> Way to Login <i nz-icon nzType="lock" nz-Theme="outline"></i>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-footer [siteSettings]="siteSettings"></app-footer>

    <div *ngIf="isTokenExp" >
        <app-overlay-model [template]="'TokenExpired'" [siteSettings]="siteSettings"></app-overlay-model>
    </div>

</ng-container>