import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';


@Injectable({
    providedIn: 'root'
})


export class TicketService {

    constructor(
        private commonService: CommonService
    ) { }

    // Get All Jira Method Call which has GET & SIngle GET
    public getJiraList(data:any) {
        return this.commonService.postHttpReqNodeapi('jira/list', data);
    }

    // Post All Jira Method Call which has Post
    public getJiraAdd(data:any) {
        return this.commonService.postHttpReqNodeapi('jira/add', data);
    }

    // Post All Jira Method Call which has Put
    public getJiraUpdate(data:any) {
        return this.commonService.putHttpReqNodeapi_ticket('jira/update', data);
    }

    // Post All Jira Method Call which has Post
    public getJiraAttachment(data:any) {
        return this.commonService.postHttpReqNodeapi('jira/attachment', data);
    }

}

