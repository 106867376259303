
<div *ngIf="loadingSite" class="h-100 w-100">
    <div class="d-flex align-content-stretch" style="justify-content: center;align-items:center;height:100vh;" >
        <div class="spinner-border" role="status" >
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>

<ng-container *ngIf="!loadingSite">

    <ngx-loading-bar [color]="'blue'"></ngx-loading-bar>

    <div *ngIf="!isConnected" >
        <app-overlay-model [siteSettings]="siteSettings"></app-overlay-model>
    </div>

    <app-carousel-slide *ngIf="siteSettings[0]?.domainName != 'grcarapp.com'" [slides]="slides"></app-carousel-slide>

    <div *ngIf="siteSettings[0]?.domainName === 'compliancecart.com'" nz-row [nzGutter]="[2]" style="position:absolute;top:0;left:0;z-index:1;">
        <div nz-col [nzSpan]="24" *ngIf="siteSettings[0]?.siteLogoLarge != null">
            <img src="../../assets/img/{{siteSettings[0]?.siteLogoLarge}}" [ngStyle]="{'width': siteSettings[0]?.domainName === 'grcarapp.com' ? '25%':'40%', 'padding':'15px' }" />
        </div>
    </div>

    <div *ngIf="siteSettings[0]?.domainName === 'compliancecart.com'" class="container-fluid contentSection" style="padding:0px !important;">    
        <div nz-row [nzGutter]="[2]">
            <div nz-col [style.margin]="siteSettings[0]?.domainName === 'compliancecart.com' ? '0px auto':''" [nzXs]="{ span: 5, offset: 5 }" [nzSm]="{ span: 5, offset: 5 }" [nzMd]="{ span: 5, offset: 5 }" [nzLg]="{ span: 7, offset: 5 }" [nzXl]="{ span: 5, offset: 5 }" [nzXXl]="{ span: 5, offset: 5 }" class="contentloginSection" [ngClass]="siteSettings[0]?.domainName === 'compliancecart.com' ? '':'overlayer-Color'">
                <div class="loginContainer" [ngClass]="siteClassName">                
                    <ng-container *ngIf="!swipeFormDiv">
                        <nz-card [nzBordered]="false" nzTitle="Enter Credentials" class="animated fadeIn">
                            <form [formGroup]="loginForm" (ngSubmit)="getloginUser()">
                                <div nz-row [nzGutter]="[10]">
                                    <div nz-col [nzSpan]="24">
                                        <nz-form-item>                                        
                                            <nz-form-control [nzErrorTip]="'Username is required !'">
                                                <nz-input-group [nzPrefix]="prefixTemplateUser">
                                                    <input type="text" nzSize="'large'" nz-input placeholder="Username" formControlName="username" />
                                                </nz-input-group>                                            
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                    <div nz-col [nzSpan]="24">
                                        <nz-form-item>                                        
                                            <nz-form-control [nzErrorTip]="'Password is required !'">
                                                <nz-input-group [nzPrefix]="prefixTemplatePassword" [nzSuffix]="suffixTemplate">
                                                    <input nzSize="'large'" [type]="passwordVisible ? 'text' : 'password'" nz-input placeholder="Password" formControlName="password" />
                                                </nz-input-group>                                            
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div> 
                                <button nz-button nzType="text" nzSize="'medium'" type="submit" class="btn-login text-center" nzBlock>
                                    <i nz-icon nzType="login" nz-Theme="outline"></i> Login
                                </button>
                                <nz-divider nzPlain nzText="OR" style="margin:7px 0 !important;"></nz-divider>
                                <button nz-button nzType="text" nzSize="'medium'" type="button" class="btn-login-google animated bounceInLeft m-b-8" nzBlock (click)="signInWithGoogle()">
                                    <i nz-icon nzType="google" nz-Theme="outline"></i> Login with Google
                                </button>
                                <button nz-button nzType="text" nzSize="'medium'" type="button" class="btn-login-outlook animated bounceInRight m-b-8" nzBlock (click)="getSamlloginUser()">
                                    <i nz-icon nzType="mail" nz-Theme="outline"></i> Login with Outlook
                                </button>
                            </form>
                        </nz-card>
                        <div nz-row [nzGutter]="[2]" class="m-t-5">
                            <div nz-col [nzSpan]="12">
                                <span (click)="swipeForm();pass=true;" class="fPass">Forgot Password ?</span>
                            </div>
                            <div nz-col [nzSpan]="12" class="text-right">
                                <span (click)="swipeForm();pass=false;" class="fPass">Forgot Username ?</span>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="swipeFormDiv">
                        <nz-card [nzBordered]="false" nzTitle="Retrieve Credentials" class="animated flipInY">
                            <form [formGroup]="forgotForm" (ngSubmit)="pass === true ? getforgotUser():getforgotUsername()">
                                <p class="loginDesc">Enter email address thats connected to {{siteSettings[0]?.applicationName === null ? 'Grcarapp':siteSettings[0]?.applicationName}} account and we'll send you the details</p>
                                <div nz-row [nzGutter]="[10]">
                                    <div nz-col [nzSpan]="24">
                                        <nz-form-item>
                                            <nz-form-control [nzErrorTip]="'Email Address is required!'">
                                                <input nz-input type="email" placeholder="Email Address" formControlName="email" name="email">
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                                <div class="w-100 text-center m-b-5">
                                    <div class="spinner-border" role="status" *ngIf="pass === true ? is_forgotPwload:is_forgotUUload">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <button type="submit" class="credLogin btn">Submit</button>
                            </form>
                        </nz-card>
                        <p class="bckTolog animated bounceInLeft" (click)="swipeForm()">
                            <i nz-icon nzType="arrow-left" nz-Theme="outline"></i> Back to Login <i nz-icon nzType="lock" nz-Theme="outline"></i>
                        </p>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="siteSettings[0]?.domainName === 'hourstock.com'" class="container-fluid contentSection" style="padding:0px !important;">    
        <div nz-row [nzGutter]="[2]">
            <div nz-col [style.margin]="siteSettings[0]?.domainName === 'hourstock.com' ? '0px auto':''" [nzXs]="{ span: 5, offset: 5 }" [nzSm]="{ span: 5, offset: 5 }"
             [nzMd]="{ span: 5, offset: 5 }" [nzLg]="{ span: 7, offset: 5 }" [nzXl]="{ span: 5, offset: 5 }" [nzXXl]="{ span: 5, offset: 5 }" class="contentloginSection" 
            [ngClass]="siteSettings[0]?.domainName === 'hourstock.com' ? '':'overlayer-Color'">
                <div class="loginContainer" [ngClass]="siteClassName">                
                    <ng-container *ngIf="!swipeFormDiv">
                        <div nz-row [nzGutter]="[2]" class="bg-inverse text-center">
                            <div nz-col [nzSpan]="24">
                                <img src="https://grcarapp-email-images-s3bucket.s3.amazonaws.com/hourstock/Hourstock_logo_whitetagline.png" style="width:240px;" />
                            </div>
                        </div>
                        <nz-card [nzBordered]="false" nzTitle="Enter Credentials" class="animated fadeIn">
                            <form [formGroup]="loginForm" (ngSubmit)="getloginUser()">
                                <div nz-row [nzGutter]="[10]">
                                    <div nz-col [nzSpan]="24">
                                        <nz-form-item>                                        
                                            <nz-form-control [nzErrorTip]="'Username is required !'">
                                                <nz-input-group [nzPrefix]="prefixTemplateUser">
                                                    <input type="text" nzSize="'large'" nz-input placeholder="Username" formControlName="username" />
                                                </nz-input-group>                                            
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                    <div nz-col [nzSpan]="24">
                                        <nz-form-item>                                        
                                            <nz-form-control [nzErrorTip]="'Password is required !'">
                                                <nz-input-group [nzPrefix]="prefixTemplatePassword" [nzSuffix]="suffixTemplate">
                                                    <input nzSize="'large'" [type]="passwordVisible ? 'text' : 'password'" nz-input placeholder="Password" formControlName="password" />
                                                </nz-input-group>                                            
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div> 
                                <button nz-button nzType="text" nzSize="'medium'" type="submit" class="btn-login text-center" nzBlock>
                                    <i nz-icon nzType="login" nz-Theme="outline"></i> Login
                                </button>
                                <nz-divider nzPlain nzText="OR" style="margin:7px 0 !important;"></nz-divider>
                                <button nz-button nzType="text" nzSize="'medium'" type="button" class="btn-login-google animated bounceInLeft m-b-8" nzBlock (click)="signInWithGoogle()">
                                    <i nz-icon nzType="google" nz-Theme="outline"></i> Login with Google
                                </button>
                                <button nz-button nzType="text" nzSize="'medium'" type="button" class="btn-login-outlook animated bounceInRight m-b-8" nzBlock (click)="getSamlloginUser()">
                                    <i nz-icon nzType="mail" nz-Theme="outline"></i> Login with Outlook
                                </button>
                            </form>
                        </nz-card>
                        <div nz-row [nzGutter]="[2]" class="m-t-5">
                            <div nz-col [nzSpan]="12">
                                <span (click)="swipeForm();pass=true;" class="fPass">Forgot Password ?</span>
                            </div>
                            <div nz-col [nzSpan]="12" class="text-right">
                                <span (click)="swipeForm();pass=false;" class="fPass">Forgot Username ?</span>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="swipeFormDiv">
                        <nz-card [nzBordered]="false" nzTitle="Retrieve Credentials" class="animated flipInY">
                            <form [formGroup]="forgotForm" (ngSubmit)="pass === true ? getforgotUser():getforgotUsername()">
                                <p class="loginDesc">Enter email address thats connected to {{siteSettings[0]?.applicationName === null ? 'Grcarapp':siteSettings[0]?.applicationName}} account and we'll send you the details</p>
                                <div nz-row [nzGutter]="[10]">
                                    <div nz-col [nzSpan]="24">
                                        <nz-form-item>
                                            <nz-form-control [nzErrorTip]="'Email Address is required!'">
                                                <input nz-input type="email" placeholder="Email Address" formControlName="email" name="email">
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                                <div class="w-100 text-center m-b-5">
                                    <div class="spinner-border" role="status" *ngIf="pass === true ? is_forgotPwload:is_forgotUUload">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <button type="submit" class="credLogin btn">Submit</button>
                            </form>
                        </nz-card>
                        <p class="bckTolog animated bounceInLeft" (click)="swipeForm()">
                            <i nz-icon nzType="arrow-left" nz-Theme="outline"></i> Back to Login <i nz-icon nzType="lock" nz-Theme="outline"></i>
                        </p>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="siteSettings[0]?.domainName === 'grcarapp.com'" class="container-fluid bg-backgroundImg dm-parent" style="padding:0px !important;">

        <!-- <div class=""> -->
            <div nz-row [nzGutter]="[2]" class="dm-child align-items-center loginContainer" [ngClass]="siteClassName">
                <!-- <div nzOffset="1" nz-col [nzSpan]="22" style="margin-top:-100px">
                    <div class="pull-right font-18 font-400 text-inverse">
                        Home
                        <nz-divider nzType="vertical"></nz-divider>
                        <span class="pointer text-muted" (click)="scrollTo('for-retail')">for Retail</span>
                        <nz-divider nzType="vertical"></nz-divider>
                        <span class="pointer text-muted" (click)="scrollTo('for-consumer-products')">for Consumer Products</span>
                        <nz-divider nzType="vertical"></nz-divider>
                        <span class="pointer text-muted" (click)="scrollTo('about-platform')">about Platform</span>
                        <nz-divider nzType="vertical"></nz-divider>
                        <span class="pointer text-muted" (click)="scrollTo('contactus')">Contact Us</span>
                    </div>
                </div> -->
                <div nzOffset="2" nz-col [nzSpan]="5"  >
                    <ng-container *ngIf="!swipeFormDiv">
                        <nz-card [nzBordered]="false" class="animated fadeIn" style="background:rgb(255 255 255 / 40%);backdrop-filter: blur(8px);height:600px;border:1px solid rgb(255 255 255 / 40%);">
                            <div class="text-center">
                                <img src="./../../assets/dmlogo.png" class="text-center" style="width:80px;">
                                <h3 class="text-center font-700 m-b-30 m-t-20" >Experience the symphony of collaboration</h3>
                            </div>
                            <form [formGroup]="loginForm" (ngSubmit)="getloginUser()">
                                <div nz-row [nzGutter]="[10]">
                                    <div nz-col [nzSpan]="24">
                                        <nz-form-item>                                        
                                            <nz-form-control [nzErrorTip]="'Username is required !'">
                                                <nz-input-group [nzPrefix]="prefixTemplateUser">
                                                    <input type="text" nz-input placeholder="Username" formControlName="username" />
                                                </nz-input-group>                                            
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                    <div nz-col [nzSpan]="24">
                                        <nz-form-item>                                        
                                            <nz-form-control [nzErrorTip]="'Password is required !'">
                                                <nz-input-group [nzPrefix]="prefixTemplatePassword" [nzSuffix]="suffixTemplate">
                                                    <input [type]="passwordVisible ? 'text' : 'password'" nz-input placeholder="Password" formControlName="password" />
                                                </nz-input-group>                                            
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div> 
                                <button nz-button nzType="text" nzSize="'medium'" type="submit" class="btn-login text-center" nzBlock>
                                    <i nz-icon nzType="login" nz-Theme="outline"></i> Login
                                </button>
                                <nz-divider nzPlain nzText="OR" style="margin:7px 0 !important;"></nz-divider>
                                <button nz-button nzType="text" nzSize="'medium'" type="button" class="btn-login-google animated bounceInLeft m-b-8" nzBlock (click)="signInWithGoogle()">
                                    <i nz-icon nzType="google" nz-Theme="outline"></i> Login with Google
                                </button>
                                <button nz-button nzType="text" nzSize="'medium'" type="button" class="btn-login-outlook animated bounceInRight m-b-8" nzBlock (click)="getSamlloginUser()">
                                    <i nz-icon nzType="mail" nz-Theme="outline"></i> Login with Outlook
                                </button>
                            </form>
                        </nz-card>
                        <div nz-row [nzGutter]="[2]" class="m-t-5">
                            <div nz-col [nzSpan]="12">
                                <span (click)="swipeForm();pass=true;" class="fPass">Forgot Password ?</span>
                            </div>
                            <div nz-col [nzSpan]="12" class="text-right">
                                <span (click)="swipeForm();pass=false;" class="fPass">Forgot Username ?</span>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="swipeFormDiv">
                        <nz-card [nzBordered]="false" class="animated flipInY" style="background:rgb(255 255 255 / 40%);backdrop-filter: blur(8px);height:600px;border:1px solid rgb(255 255 255 / 40%);">
                            <div class="text-center ">
                                <img src="./../../assets/dmlogo.png" class="text-center" style="width:80px;">
                                <h3 class="text-center font-700 m-b-30 m-t-20" >Experience the symphony of collaboration</h3>
                            </div>
                            <form [formGroup]="forgotForm" (ngSubmit)="pass === true ? getforgotUser():getforgotUsername()">
                                <p class="loginDesc">Enter email address thats connected to {{siteSettings[0]?.applicationName === null ? 'Grcarapp':siteSettings[0]?.applicationName}} account and we'll send you the details</p>
                                <div nz-row [nzGutter]="[10]">
                                    <div nz-col [nzSpan]="24">
                                        <nz-form-item>
                                            <nz-form-control [nzErrorTip]="'Email Address is required!'">
                                                <input nz-input type="email" placeholder="Email Address" formControlName="email" name="email">
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                                <div class="w-100 text-center m-b-5">
                                    <div class="spinner-border" role="status" *ngIf="pass === true ? is_forgotPwload:is_forgotUUload">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <button type="submit" class="credLogin btn">Submit</button>
                            </form>
                        </nz-card>
                        <p class="bckTolog animated bounceInLeft" (click)="swipeForm()">
                            <i nz-icon nzType="arrow-left" nz-Theme="outline"></i> Back to Login <i nz-icon nzType="lock" nz-Theme="outline"></i>
                        </p>
                    </ng-container>
                </div>
                
                <div nzOffset="3" nz-col [nzSpan]="14" class="text-show-right" >
                    <div style="padding-right:50px;">
                        <img src="./../../assets/dmlogo.png" class="m-b-20 m-t-20 dm-logo"><br/>
                        <h3 class="m-b-5 dm-slogan">A Data Monetization Exchange Platform</h3>
                        <h3 class="m-b-5">by</h3>
                        <h3 class="m-b-0 dm-slogan">Groupsoft US Inc</h3>
                        <h3 class="m-b-30">for</h3>
                        <span class="button-Retail">Retail | Consumer Goods</span>
                        <br/>
                        <br/>
                        <br/>
                        <h2 class="DM-subtitle-slide-1">Strategic Decision & Monetization Platform driven by Data Process Improvisation <br>and Collaboration.</h2>
                    </div>
                </div>
            </div>
            
            <!-- <div id="for-retail" nz-row [nzGutter]="[2]" class="dm-child align-items-center">
                <div nz-col [nzSpan]="11">
                    <img src="./../../assets/slide-2.png" style="width:95%" /> 
                </div>
                <div nz-col [nzSpan]="12">
                    <h2 class="DM-subtitle-slide-2 m-b-30 text-show-right" >
                        <img src="./../../assets/dmlogo.png" style="width:70px;vertical-align: baseline;"> helps retail businesses in <br>
                        Improving profits, cutting costs, and creating opportunities for you, your customers <br>
                        and your partners.
                    </h2>
                    
                    <div nz-row [nzGutter]="[2]">
                        <div nz-col [nzSpan]="6"></div>
                        <div nz-col [nzSpan]="18">
                            <div class="wrapper white">
                                <h3>Improve</h3>
                                <div class="content">
                                    <strong>Store Insights</strong>: KPIs, Inventory Projections, Store Traffic Analytics with Weather Prediction, Competition Comparatives <br />
                                    <strong>Category Data and Insights</strong>: Ranking, Contribution Category KPI's and Productivity Vendor Performance, Vendor KPI's & Spend Analysis <br />
                                    <strong>Retail Calendar</strong> for Promotion, Events, EOS,Trade Promo and Marketing Collaboration
                                </div>
                            </div>
                            <div class="wrapper white" style="min-height: 100px;">
                                <h3>Wrap</h3>
                                <div class="content">
                                    <strong>Store</strong>: Vendor Performance, Trade Promo Opps, Vendor Funds, Local Customer Segmentation <br />
                                    <strong>Vendor Product Analysis, and Insights</strong><br />
                                    <strong>Joint Trade Promotion and Marketing Calendar with Partners </strong>
                                </div>
                            </div>
                            <div class="wrapper white" style="min-height: 100px;">
                                <h3>Sell</h3>
                                <div class="content">
                                    <strong>Growth Share Matrix (BCG Matrix - Stars, Cash cow, Dogs, Question Mark),Customer Affinity Analysis</strong><br />
                                    <strong>Revenue Opportunities proposals</strong><br />
                                    <strong>Vendor Negotiation Platform</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            
            <!-- <div id="for-consumer-products" nz-row [nzGutter]="[2]" class="dm-child align-items-center">
                <div nzOffset="1" nz-col [nzSpan]="12">
                    <h2 class="DM-subtitle-slide-3 m-b-30" >
                        <img src="./../../assets/dmlogo.png" style="width:70px;vertical-align: baseline;"> helps consumer goods companies to<br>
                        improve products, increase productivity and up the market share.
                    </h2>
                    <div nz-row [nzGutter]="[2]">
                        <div nz-col [nzSpan]="18">
                            <div class="wrapper black" style="min-height: 100px;">
                                <h3 class="text-white">Improve</h3>
                                <div class="content">
                                    <strong>Effective utilization of Trade Promotions</strong><br />
                                    <strong>Optimize Supply chain & logistics costs</strong><br />
                                    Streamline Sales & operations planning 
                                </div>
                            </div>
                            <div class="wrapper black" style="min-height: 100px;">
                                <h3 class="text-white">Wrap</h3>
                                <div class="content">
                                    <strong>Introduce direct fulfillment to certain channels / distributors / retailers</strong><br />
                                    <strong>Joint Marketing planning through Retail Calendar</strong><br />
                                    <strong>Channel specific marketing plan</strong>
                                </div>
                            </div>
                            <div class="wrapper black" style="min-height: 100px;">
                                <h3 class="text-white">Sell</h3>
                                <div class="content">
                                    <strong>Introduce an allied  product / Service line</strong><br />
                                    <strong>Launch a new product/category to address an unfulfilled demand</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div nz-col [nzSpan]="11" class="text-right" >
                    <img src="./../../assets/slide-3.png" style="width:95%" /> 
                </div>
            </div> -->
            
            <!-- <div id="about-platform" nz-row [nzGutter]="[2]" class=" dm-child align-items-center">
                <div nzOffset="2" nz-col [nzSpan]="20">
                    <div nz-row [nzGutter]="[16,16]" >
                        <div nz-col class="gutter-row" [nzSpan]="2"></div>
                        <div nz-col class="gutter-row" [nzSpan]="22">
                            <h2 class="DM-subtitle-slide-3 m-b-30" >
                                Unleash the power of our AI to unleash the power of your data.
                            </h2>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <div nz-row [nzGutter]="[48,48]" >
                        <div nz-col class="gutter-row text-center m-b-20" [nzSpan]="8" >
                            <nz-card [nzBorderless]="true" [nzBodyStyle]="{'height':'180px'}">
                                <img src="./../../assets/DM_icons_ReadyIntegrations.png" style="width:100px;margin-top:-80px;" />
                                <h3>READY INTEGRATION FRAMEWORKS</h3>
                                <p class="font-16">With Leading Enterprise ERPs & Point Solution</p>
                            </nz-card>
                        </div>
                        <div nz-col class="gutter-row text-center m-b-20" [nzSpan]="8">
                            <nz-card [nzBorderless]="true" [nzBodyStyle]="{'height':'180px'}">
                                <img src="./../../assets/DM_icons_AutomatedInsights.png" style="width:100px;margin-top:-80px;" />
                                <h3>AUTOMATED INSIGHTS</h3>
                                <p class="font-16">That surface meaningful insights in natural language about significant changes in expected demand</p>
                            </nz-card>
                        </div>
                        <div nz-col class="gutter-row text-center m-b-20" [nzSpan]="8">
                            <nz-card [nzBorderless]="true" [nzBodyStyle]="{'height':'180px'}">
                                <img src="./../../assets/DM_icons_collaborations.png" style="width:100px;margin-top:-80px;" />
                                <h3>COLLABORATION PLANNING TOOL</h3>
                                <p class="font-16">To capture impact of business factors like marketing, pricing and external factors like weather, holidays on demand</p>
                            </nz-card>
                        </div>
                        <div nz-col class="gutter-row text-center m-b-20" [nzSpan]="8">
                            <nz-card [nzBorderless]="true" [nzBodyStyle]="{'height':'180px'}">
                                <img src="./../../assets/DM_icons_MLAlogorithms.png" style="width:100px;margin-top:-80px;" />
                                <h3>ML ALGORITHMS </h3>
                                <p class="font-16">To identify seasonality, anomalous spikes, new product introductions, cannibalization effects, historical stockouts and many more patterns for every SKU/ product category</p>
                            </nz-card>
                        </div>
                        <div nz-col class="gutter-row text-center m-b-20" [nzSpan]="8">
                            <nz-card [nzBorderless]="true" [nzBodyStyle]="{'height':'180px'}">
                                <img src="./../../assets/DM_icons_Forecasting.png" style="width:100px;margin-top:-80px;" />
                                <h3>FORECASTING AUTOMATION </h3>
                                <p class="font-16">To scale the solution along with the customers business expansions</p>
                            </nz-card>
                        </div>
                        <div nz-col class="gutter-row text-center m-b-20" [nzSpan]="8">
                            <nz-card [nzBorderless]="true" [nzBodyStyle]="{'height':'180px'}">
                                <img src="./../../assets/DM_icons_PlugPlay.png" style="width:100px;margin-top:-80px;" />
                                <h3>PLUG & PLAY MODEL</h3>
                                <p class="font-16">Cloud based SaaS platform requires no additional infrastructure</p>
                            </nz-card>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <div nz-row [nzGutter]="[16,16]" >
                        <div nz-col class=" text-center" [nzSpan]="3">
                            <img src="./../../assets/logos/SAP.png" style="width:150px;" /> 
                        </div>
                        <div nz-col class=" text-center" [nzSpan]="3">
                            <img src="./../../assets/logos/Blue_Yonder_rgb.jpeg" style="width:150px;" /> 
                        </div>
                        <div nz-col class=" text-center" [nzSpan]="3">
                            <img src="./../../assets/logos/Snowflake_Logo.png" style="width:150px;margin-top:20px;" /> 
                        </div>
                        <div nz-col class=" text-center" [nzSpan]="3">
                            <img src="./../../assets/logos/google_bigquery.png" style="width:150px;" /> 
                        </div>
                        <div nz-col class=" text-center" [nzSpan]="3">
                            <img src="./../../assets/logos/Azure-Synapse-Analytics.jpeg" style="width:150px;" /> 
                        </div>
                        <div nz-col class=" text-center" [nzSpan]="3">
                            <img src="./../../assets/logos/tableaulogo_highres.png" style="width:150px;margin-top:20px;" /> 
                        </div>
                        <div nz-col class=" text-center" [nzSpan]="3">
                            <img src="./../../assets/logos/microstrategy.png" style="width:150px;margin-top:20px;" /> 
                        </div>
                        <div nz-col class=" text-center" [nzSpan]="3">
                            <img src="./../../assets/logos/amazon-redshift-logo.png" style="width:150px;" /> 
                        </div>
                    </div>
                </div>
            </div> -->

            <!-- <div id="contactus" nz-row [nzGutter]="[2]" class=" dm-child align-items-center">
                <div nzOffset="2" nz-col [nzSpan]="20">
                    <div nz-row [nzGutter]="[16,16]" >
                        <div nz-col class="gutter-row" [nzSpan]="14"></div>
                        <div nz-col class="gutter-row" [nzSpan]="10">
                            <h2 class="DM-subtitle-slide-3 m-b-30" >
                                Have Questions about <img src="./../../assets/dmlogo.png" style="width:70px;vertical-align: baseline;">
                            </h2>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <div nz-row [nzGutter]="[16,16]" >
                        <div nz-col class="gutter-row" [nzSpan]="4"></div>
                        <div nz-col class="gutter-row" [nzSpan]="16">
                            <h2 class="DM-subtitle-slide-3 m-b-10" >
                                Help us reach out to you
                            </h2>
                            <nz-card >
                                <div class="p-20">
                                    <form>
                                        <div nz-row [nzGutter]="[24,16]">
                                            <div nz-col class="gutter-row" [nzSpan]="12">
                                                <nz-form-item>                                        
                                                    <nz-form-control [nzErrorTip]="'Username is required!'">
                                                        <input type="text" nzSize="large" nz-input placeholder="First Name"  />
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                            <div nz-col class="gutter-row" [nzSpan]="12">
                                                <nz-form-item>                                        
                                                    <nz-form-control [nzErrorTip]="'Password is required!'">
                                                        <input type="text" nzSize="large" nz-input placeholder="Last Name"  />                          
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                            <div nz-col class="gutter-row" [nzSpan]="12">
                                                <nz-form-item>                                        
                                                    <nz-form-control [nzErrorTip]="'Username is required!'">
                                                        <input type="text" nzSize="large" nz-input placeholder="Organization"  />
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                            <div nz-col class="gutter-row" [nzSpan]="12">
                                                <nz-form-item>                                        
                                                    <nz-form-control [nzErrorTip]="'Password is required!'">
                                                        <input type="text" nzSize="large" nz-input placeholder="Email Address"  />                          
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                            <div nz-col class="gutter-row" [nzSpan]="24">
                                                <nz-form-item>                                        
                                                    <nz-form-control [nzErrorTip]="'Password is required!'">
                                                        <textarea rows="4" nz-input ></textarea>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                        </div> 
                                        <div nz-row [nzGutter]="[24,16]">
                                            <div nz-col class="gutter-row" [nzSpan]="24" class="pull-right">
                                                <button nz-button nzType="primary">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </nz-card>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                        </div>
                        <div nz-col class="gutter-row" [nzSpan]="4"></div>
                    </div>
                </div>
                
                <div nz-col [nzXs]="{ span: 6 }" [nzSm]="{ span: 6 }" [nzMd]="{ span: 6 }" [nzLg]="{ span: 6 }" [nzXl]="{ span: 6 }" [nzXXl]="{ span: 6 }" style="margin-top:75px">
                    <ul class="list-inline sitelinks m-b-0 text-left ">
                        <li><a href="{{siteSettings[0]?.termServiceUrl}}" target="_blank" class="text-inverse">Terms of Service</a></li>
                        <li><a href="{{siteSettings[0]?.privacyPolicyUrl}}" target="_blank" class="text-inverse">Privacy Policy</a></li>
                        <li><a href="{{siteSettings[0]?.contactUsUrl}}" target="_blank" class="text-inverse">Contact Us</a></li>
                    </ul>
                </div>
                <div nz-col [nzXs]="{ span: 18 }" [nzSm]="{ span: 18 }" [nzMd]="{ span: 18 }" [nzLg]="{ span: 18 }" [nzXl]="{ span: 18 }" [nzXXl]="{ span: 18 }" style="margin-top:75px">
                    <ul class="list-inline m-b-0 text-right">
                        <li><a href="{{siteSettings[0]?.poweredByUrl}}" target="_blank" class="text-inverse">© {{siteSettings[0]?.copyRightYear}} - {{siteSettings[0]?.copyRightCompanyName}} All rights reserved</a></li>
                        <li><a href="{{siteSettings[0]?.poweredByUrl}}" target="_blank" class="text-inverse">Powered by {{siteSettings[0]?.poweredByName}}</a></li>
                    </ul>
                </div>
            </div> -->
        <!-- </div> -->

    </div>

    <app-footer [siteSettings]="siteSettings"></app-footer>

    <ng-template #prefixTemplatePassword><em nz-icon nzType="lock"></em></ng-template>
    <ng-template #suffixTemplate>
        <em nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></em>
    </ng-template>
    <ng-template #prefixTemplateUser><em nz-icon nzType="user"></em></ng-template>
    <ng-template #prefixTemplateEmail><em nz-icon nzType="mail"></em></ng-template>

</ng-container>
