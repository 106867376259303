import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'te-attachments-button-renderer',
    template: `
        <button nz-button nzType="text" nzSize="small" nz-tooltip nzTooltipTitle="View" nzTooltipPlacement="bottom" (click)="onAction($event,'view')">
            <i nz-icon nzType="eye" nzTheme="outline"></i>
        </button> 
        <button nz-button nzType="text" nzSize="small" nz-tooltip nzTooltipTitle="Download" nzTooltipPlacement="bottom" (click)="onAction($event,'download')">
            <i nz-icon nzType="download" nzTheme="outline"></i>                                 
        </button>                                 
        <button nz-button nzType="text" nzSize="small" nz-tooltip nzTooltipTitle="Delete" nzTooltipPlacement="bottom" (click)="onAction($event,'delete')">
            <i nz-icon nzType="delete" nzTheme="outline"></i>                                    
        </button>
        <button nz-button nzType="text" nzSize="small" nz-tooltip nzTooltipTitle="Log" nzTooltipPlacement="bottom" (click)="onAction($event,'log')">
            <i nz-icon nzType="info-circle" nz-Theme="outline"></i>
        </button>        
      `
})

export class AttachmentsButtonRendererComponent implements ICellRendererAngularComp {

    params;

    agInit(params): void {
        this.params = params;
    }

    refresh(params?: any): boolean {
        return true;
    }

    onAction($event, opt: string) {
        if (this.params.onClick instanceof Function) {
            const params = { action: opt, event: $event, rowData: this.params.node.data }
            this.params.onClick(params);
        }
    }
}
