
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from './../api-service/login-service/login.service';
import { HeadingtitleService } from '../headingtitle.service';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})

export class ChildGuard implements CanActivate {

  constructor(
    public loginService: LoginService,
    public router: Router,
    public HeadingtitleService: HeadingtitleService,
  ) { }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let appName = null;
    if (localStorage.getItem('selectedAppRoute') !== null) {
      appName = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('selectedAppRoute'), 'app-route').toString(CryptoJS.enc.Utf8)).toString();
    }
    // let appName = localStorage.getItem('selectedAppRoute');
    if (appName && appName != undefined && appName != null) {
      if (appName?.includes(state.url.substring(1)) === true) {
        return true;
      } else {
        this.router.navigate(["non-subscription"]);
        return false;
      }
    }
  }



}

