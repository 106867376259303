import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ConnectionService } from 'ng-connection-service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { CompanyDetailsService } from '../api-service/companyDetails/companyDetails.service';
import { HeadingtitleService } from '../headingtitle.service';
import { LoginService } from '../api-service/login-service/login.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.less']
})


export class ResetPasswordComponent implements OnInit {

    public loadingSite: boolean = false; public isConnected:boolean = true; public siteSettings: Array<any> = [];
    public companyname: string;  public evnname: string; public hostname: string; public slides: Array<any> = [];
    public isTokenExp: boolean = false;
    
    // password Show
    public resetpwShow: boolean;
    public is_verifyload: boolean = false;
    public bckTolog: boolean = false;

    // String
    public token: string;
    public imagePath: string;

    // Form
    public pwResetForm: FormGroup;

    // Object
    public userData:any = [];

    // atleast 1 Uppercase, 1 Lowercase, 1 Number, 1 Special Character with min 8 character
    //public only1ULNS = "(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$";
    public only1ULNS = "(?=[A-Za-z0-9@#%$^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=]).{8,19}.$";


    constructor(
        private formBuilder: FormBuilder,
        private companydetailsService: CompanyDetailsService,
        private headingtitleService: HeadingtitleService,
        private loginService: LoginService,
        private notification: NzNotificationService,
        private loadingBar: LoadingBarService,
        private _Activatedroute: ActivatedRoute,
        private connectionService: ConnectionService,
    ) {
        this.loadingSite = true;
        this.connectionService.monitor().subscribe(isConnected => {
            this.isConnected = isConnected;
        });
    }

    ngOnInit() {

        this.companyname = localStorage.getItem('companyname');
        this.evnname = localStorage.getItem('evnname');
        this.hostname = localStorage.getItem('hostname');

        this.siteSettings = [JSON.parse(localStorage.getItem("siteSettings"))];
        if (this.siteSettings[0].backgroundSliderImg !== null) {
            let tempSlide = this.siteSettings[0].backgroundSliderImg.split(',');
            tempSlide.forEach(e => {
                let imgUrl = this.headingtitleService.getImagesS3('slider', e.trim(), false);
                this.slides.push({ img: imgUrl });
            });
        }

        this.pwResetForm = this.formBuilder.group({
            password: ['', [Validators.required, Validators.pattern(this.only1ULNS)]],
            confirmpassword: ['', [Validators.required]]
        }, { validator: this.checkPasswords });

        this.token = this._Activatedroute.snapshot.paramMap.get('t');
        this.verifyToken(this._Activatedroute.snapshot.paramMap.get('t'));

        setTimeout(() => {
            this.loadingSite = false;
        }, 1000);

    }

    public checkPasswords(group: FormGroup) { // here we have the 'passwords' group
        let pass = group.get('password').value;
        let confirmPass = group.get('confirmpassword').value;
        return pass === confirmPass ? null : { notSame: true };
    }

    // verify token before reset password in background Process
    public verifyToken(token: string): void {
        if (token != '') {
            this.is_verifyload = true; this.isTokenExp = false;
            this.companydetailsService.verifyToken({ "reset_pwd_token": token }).subscribe(res => {
                if (res['success'] === true) {
                    this.is_verifyload = false;
                } else {
                    this.is_verifyload = false;
                    this.isTokenExp = true;
                }
            });
        }
    }

    // Forgot Password
    public resetPassword() {
        for (const key in this.pwResetForm.controls) {
            this.pwResetForm.controls[key].markAsDirty();
            this.pwResetForm.controls[key].updateValueAndValidity();
        }
        if (this.pwResetForm.valid) {
            this.loadingBar.start();
            if (this.pwResetForm.value.password != '') {
                this.companydetailsService.resetPassword({ "domainName": this.hostname, "reset_pwd_token": this.token, "password": this.pwResetForm.value.password }).subscribe(res => {
                    if (res['success'] === true) {
                        this.bckTolog = true; this.token = '';
                        this.pwResetForm.reset(); 
                        this.notification.create('success', '', res['message'], { nzPlacement: 'topRight' });
                    } else {
                        this.notification.create('error', '', res['message'], { nzPlacement: 'topRight' });
                    }
                });
            }
            this.loadingBar.complete();
        }
    }



}
