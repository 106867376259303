
export class NotificationModel {
  applicationId: number;
  applicationName: string;
  notificationList: Array<NotificationListModel> = [];
}


export class NotificationListModel {
  dateGroup: Array<number>;
  groupNotificationList: Array<GroupNotificationListModel> = [];
}


export class GroupNotificationListModel {
  applicationId: Array<number>;
  comment: string;
  imgUrl: string;
  isSeen: number;
  moduleId: number;
  moduleName: string;
  notificationDate: string;
  notificationDesc: string;
  notificationId: number;
  notificationText: number;
  receiverCompanyId: number;
  receiverUserId: number;
  senderCompanyId: number;
  senderUserId: number;
}
