import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CompanyDetailsService } from './../api-service/companyDetails/companyDetails.service';
import { HeadingtitleService } from '../headingtitle.service';


@Component({
    selector: 'app-logout',
    template: `
        <div *ngIf="loadingSite" class="h-100">
            <div class="d-flex align-content-stretch" style="justify-content: center;align-items:center;height:100vh;" >
                <div class="spinner-border" role="status" >
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    `,
    styles: [``]
})

export class LogoutComponent implements OnInit {

    public loadingSite: boolean = false; public deviceInfo: any;

    constructor(
        private router: Router,
        private cookieService: CookieService,
        private headingTitleService: HeadingtitleService,
        private deviceService: DeviceDetectorService,
        private companyDetailsService: CompanyDetailsService,
    ) {
        this.loadingSite = true;
        this.deviceInfo = this.deviceService.getDeviceInfo();
    }

    ngOnInit() {
        setTimeout(() => {
            this.createUserLogs('Logout', 'Groupsoft', JSON.parse(this.headingTitleService.getLoginData())[0].roleId,
            JSON.parse(this.headingTitleService.getLoginData())[0].companyId, JSON.parse(this.headingTitleService.getLoginData())[0].id);
        }, 2000);
    }

    private createUserLogs(action: string, loginwith: string, roleId: number, companyId: number, userId: number): void {
        let PostData = {
            "frontend_user_id": userId,
            "ip_address": JSON.parse(localStorage.getItem("ip")),
            "frontend_user_role_id": roleId,
            "frontend_user_company_id": companyId,
            "action": action,
            "login_with": loginwith,
            "description": "Logout form Frontend Application",
            "browser_detail": this.deviceInfo.browser_version,
            "user_agent": this.deviceInfo.userAgent,
            "os": this.deviceInfo.os,
            "os_version": this.deviceInfo.os_version,
            "device": (this.deviceInfo.device != 'Unknown' || this.deviceInfo.device != 'unknown') ? this.deviceInfo.device : '-',
            "browser": this.deviceInfo.browser,
            "is_mobile": this.deviceService.isMobile() === true ? 1 : 0,
            "is_tablet": this.deviceService.isTablet() === true ? 1 : 0,
            "is_desktop": this.deviceService.isDesktop() === true ? 1 : 0
        };
        this.companyDetailsService.postUserLog(PostData).subscribe(res => {
            this.logout();
        }, error => {
            console.log(error);
        });
    }

    private logout(): void {
        let keysToRemove = ["loginData", "previousUrl", "selectedAppRoute", "authToken", "selectedApplication"];
        keysToRemove.forEach(i => {
            localStorage.removeItem(i);
        });

        if (this.cookieService.check('access_token')) {
            this.cookieService.delete('access_token');
        }

        if (window.location.hostname != 'localhost') {
            this.loadingSite = false;
            this.router.navigate(['login']);
        } else {
            this.router.navigate(['login']);
        }
    }



}
