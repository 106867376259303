import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../environments/environment';
import { LoginService } from './api-service/login-service/login.service';
import { Auth0Service } from './auth0.service';
import { HeadingtitleService } from './headingtitle.service';
import * as auth0 from 'auth0-js';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-callback',
    template: `
        <p>
            Loading...
        </p>

        <!-- Show Confirmation box while change company -->
        <ng-template #notSubscriptionMTModel let-modal>
            <div class="modal-body p-20">
                <div class="col-lg-12 text-center">
                    <br />
                    <img src="assets/img/logo/gs.png" class="p-10 m-b-20" alt="Groupsoft" style="width:20%;">
                    <h2 class="font-400">Sorry, you are not subscribed to the <br>Grcarapp {{MtEvn}} Environment !</h2>
                    <br />
                </div>
            </div>
        </ng-template>

    `,
    styles: [],
    providers: [NgbModalConfig, NgbModal]
})

export class CallbackComponent implements OnInit {

    // Call model on click
    @ViewChild('notSubscriptionMTModel', { static: true }) private notSubscriptionMTModel;

    public companyname: string;
    public evnname: string;
    public hostname: string;
    public ExpiredDaysMsg: number = 10;

    // Auth0 Config
    auth0 = new auth0.WebAuth({
        audience: environment.ENVIRONMENT.auth0Domain + '/userinfo',
        clientID: environment.ENVIRONMENT.auth0ClientID,
        domain: environment.ENVIRONMENT.auth0Domain,
        responseType: 'id_token token',
        scope: 'openid email profile',
    });

    constructor(
        private router: Router,
        private auth0Service: Auth0Service,
        private loginService: LoginService,
        private notification: NzNotificationService,
        private headingtitleService: HeadingtitleService,
        private cookieService: CookieService,
        private deviceService: DeviceDetectorService,
        private modalService: NgbModal,
        config: NgbModalConfig,
    ) {
        config.backdrop = 'static';
        config.keyboard = false;
    }

    ngOnInit() {

        // Developer Purpose Login
        if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
            this.companyname = 'groupsoft';
            this.evnname = environment.ENVIRONMENT.title;
            // Uncomment or comment when we want to use grcarapp in local machine
            this.hostname = 'grcarapp.com';
            // Uncomment or comment when we want to use compliancecart in local machine
            // this.hostname = 'compliancecart.com';
            localStorage.setItem('evnname', this.evnname);
            this.loginService.setDomainUrl('https://' + this.companyname + this.evnname + this.hostname);
        } else {
            this.loginService.setDomainUrl(location.origin); // Set Full Url with SiteUrl         
            if ((window.location.hostname.toString().split(".")).length > 3) {   // For dev, qa, training             
                this.companyname = window.location.hostname.toString().split(".")[0];
                this.evnname = window.location.hostname.toString().split(".")[1];
                this.hostname = window.location.hostname.toString().split(".")[2] + '.' + window.location.hostname.toString().split(".")[3];
            } else {   //  For Prod
                this.companyname = window.location.hostname.toString().split(".")[0];
                this.evnname = 'prod';
                this.hostname = window.location.hostname.toString().split(".")[1] + '.' + window.location.hostname.toString().split(".")[2];
            }
        }

        this.checkCompanyStatus(this.evnname, this.companyname);
        this.getIPAddress();
        this.deviceInfo = this.deviceService.getDeviceInfo();


        this.auth0Service.handleLoginCallback().then(authresult => {
            if (authresult) {
                this.cookieService.set('access_token', authresult.accessToken);  // store in cookies

                // get Saml user data
                this.getuserData(authresult.accessToken).then(resp => {
                    // console.log(this.headingtitleserivce.getsamlEmailsplitValue('|', res.sub));

                    // login in grcarapp.com
                    this.loginService.getUser(this.headingtitleService.getsamlEmailsplitValue('|', resp.sub), this.companyname, this.evnname).subscribe(res => {
                        if (res['success'] === true) {
                            if (res['token'] != '') {
                                this.loginService.setAuthToken(res['token']); // Set Token 
                                // localStorage.setItem('timer', JSON.stringify(this.loginService.getTimeExpiredToken()));
                                this.loginService.setLoginData(JSON.stringify(res['data'])); // Set Login Data 
                                localStorage.setItem("loginData", JSON.stringify(res['data'])); // Set User Data 
                                this.createUserLogs('Login', 'SSO', res['data'][0].roleId, res['data'][0].companyId, res['data'][0].id);
                                this.notification.create('success', res['message'], this.headingtitleService.getsamlEmailsplitValue('|', resp.sub), { nzPlacement: 'topRight' });
                                // this.toastr.success(res['message'], this.headingtitleserivce.getsamlEmailsplitValue('|', resp.sub), { timeOut: 2000 });
                                setTimeout(() => {
                                    this.router.navigate(['dashboard']);
                                }, 1500);
                            }
                        } else {
                            this.notification.create('error', 'Failed', this.headingtitleService.getsamlEmailsplitValue('|', resp.sub) + ' ' + res['message'], { nzPlacement: 'topRight' });
                            // this.toastr.error(res['message'], this.headingtitleserivce.getsamlEmailsplitValue('|', resp.sub), { timeOut: 2000 });
                        }
                    });

                }, error => {
                    console.log(error);
                });
            }
        });

    }

    // Get Smal User Data
    private getuserData(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.auth0.client.userInfo(data, function (err, user) {
                if (err) {
                    reject(err);
                }
                if (user != '') {
                    resolve(user);
                }
            });
        });
    }

    public MtEvn: string = '';
    //Open popup For Error not subscroption
    public openModal(notSubscriptionMTModel, environmentName) {
        this.MtEvn = environmentName;
        this.modalService.open(notSubscriptionMTModel, { centered: true });
    }

    public trialVersionDate: number; public isTraining: boolean = false;
    private checkCompanyStatus(env: string, siteurl: string) {
        this.loginService.checkCompanyStatus({ "environment": env, "siteurl": siteurl }).subscribe(res => {
            if (res['success'] === true) {
                if (env === 'training') {
                    this.isTraining = res['data']['training'] === 1 ? true : false;
                    this.trialVersionDate = res['data']['training'] === 1 ? this.headingtitleService.showTrialDays(res['data']['trialversionExpiryDate']) : 0;
                } else {
                    this.isTraining = false;
                }
            } else {
                const environment = (env === 'dev' ? 'Development' : '') || (env === 'qa' ? 'Quality Assurance' : '') || (env === 'prod' ? 'Production' : '') || (env === 'training' ? 'Training' : '')
                this.openModal(this.notSubscriptionMTModel, environment);
            }
        });
    }

    public ipAdd: string;
    private getIPAddress() {
        this.loginService.getIPAddress().subscribe((res: any) => {
            this.ipAdd = res.ip;
        });
    }

    public deviceInfo: any;
    private createUserLogs(action: string, loginwith: string, roleId: number, companyId: number, userId: number): void {
        let PostData = {
            "frontend_user_id": userId,
            "ip_address": this.ipAdd,
            "frontend_user_role_id": roleId,
            "frontend_user_company_id": companyId,
            "action": action,
            "login_with": loginwith,
            "description": "Login to Frontend Application",
            "browser_detail": this.deviceInfo.browser_version,
            "user_agent": this.deviceInfo.userAgent,
            "os": this.deviceInfo.os,
            "os_version": this.deviceInfo.os_version,
            "device": (this.deviceInfo.device != 'Unknown' || this.deviceInfo.device != 'unknown') ? this.deviceInfo.device : '-',
            "browser": this.deviceInfo.browser,
            "is_mobile": this.deviceService.isMobile() === true ? 1 : 0,
            "is_tablet": this.deviceService.isTablet() === true ? 1 : 0,
            "is_desktop": this.deviceService.isDesktop() === true ? 1 : 0
        };
        this.loginService.postUserLog(PostData).subscribe();
    }

}