
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { CompanyDetailsService } from '../../api-service/companyDetails/companyDetails.service';
import { HeadingtitleService } from '../../headingtitle.service';
import { NotificationModel, NotificationListModel, GroupNotificationListModel } from './app-notification.model';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { SharedService } from '../../shared/services/shared-services';


@Component({
    selector: 'app-app-notification',
    templateUrl: 'app-notification.component.html',
    styles: [`
        .appCount {
            width: auto;height: auto; border-radius: 4px; background: #fb6565;display: inline-block; vertical-align: middle;text-align: center;color: #fff; font-size: 11px; padding: 0px 5px;
        }
    `]
})

export class AppNotificationComponent implements OnInit {

    @Input() userId:number;
    @Input() companyId:number;
    @Input() applicationId:number;
    @Input() hostname:string;

    // Notification
    public totalNotificationUnreadCount: number; public notificationCount: Array<any> = []; public notifications: Array<any> = [];
    public filters: string; public userTimeZone: string; public userCurrency: string;

    public switchValue = false;
    public loadingNotification:boolean = false;

    constructor(
        private headingtitleService: HeadingtitleService,
        private companyDetailsService: CompanyDetailsService,
        private popUpService: NzNotificationService,
        public sharedService: SharedService
    ) { }

    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.companyId != undefined || this.userId != undefined) {
            this.setSettingsUserData();
            const hostName = JSON.parse(localStorage.getItem('siteSettings'));
            if (hostName.domainName === 'grcarapp.com') {
                this.filters = `filter[where][receiverCompanyId]=${this.companyId}&filter[where][receiverUserId]=${this.userId}`;
            } else {
                this.filters = `filter[where][receiverCompanyId]=${this.companyId}&filter[where][receiverUserId]=${this.userId}&filter[where][applicationId]=${hostName.applicationId}`;
            }
            this.getNotificationsCount();
        }
    }

    trackByFn(index, item) {
        return item.notificationList;
    }

    // Get User List from Selected Company
    public setSettingsUserData():void {
        this.companyDetailsService.getUserSettings(this.userId).subscribe((res:any) => {
            if (res['data'].length > 0) {
                this.userTimeZone = res['data'][0].timeZone;
                this.userCurrency = res['data'][0].baseCurrencyCode;
            } else {
                this.userTimeZone = '';
                this.userCurrency = '';
                this.popUpService.create('info', '', `Profile timezone and currency needs to set!`, { nzPlacement: 'topRight' });
            }
        });
    }


    // Get each Application Count
    public getApplicationWiseCount(appId: number) {
        const unread = this.notificationCount.filter(x => x.applicationId === appId)[0].count.unread;
        return unread > 9 ? '9+':unread;
    }

    // Get all Application Count
    private getNotificationsCount():void {
        this.companyDetailsService.getNotificationsCount(this.filters).subscribe((res:any) => {
            if (res.success === true) {
                this.notificationCount = res.data;
                this.totalNotificationUnreadCount = res.data.map((x:any) => x.count).map((y:any) => y.unread).reduce((a, b) => +a + +b, 0);
                this.sharedService.setUnReadNotificationCount(this.totalNotificationUnreadCount);
            } else {
                this.notificationCount = [];
            }
        });
    }

    // Get All Application Notification
    private getNotifications():void {
        this.companyDetailsService.getNotificationsAll(this.filters).subscribe((res:any) => {
            if (res.success === true) {
                this.notifications = res['data'].map((x:any) => {
                    let b = new NotificationModel();
                    b.applicationId = x.applicationId;
                    b.applicationName = x.applicationName;
                    b.notificationList = x.notificationList.map((y:any) => {
                        let n = new NotificationListModel();
                        n.dateGroup = y.dateGroup;
                        n.groupNotificationList = y.groupNotificationList.map( (z:any) => {
                            let gn = new GroupNotificationListModel();
                            gn.applicationId = z.applicationId;
                            gn.comment = z.comment;
                            gn.imgUrl = z.imgUrl;
                            gn.isSeen = z.isSeen;
                            gn.moduleId = z.moduleId;
                            gn.moduleName = z.moduleName;
                            gn.notificationDesc = z.notificationDesc;
                            gn.notificationId = z.notificationId;
                            gn.notificationText = z.notificationText;
                            gn.receiverCompanyId = z.receiverCompanyId;
                            gn.receiverUserId = z.receiverUserId;
                            gn.senderCompanyId = z.senderCompanyId;
                            gn.senderUserId = z.senderUserId;
                            if (x.applicationName === 'Hourstock') {
                                // gn.notificationDate = z.notificationDate === null ? null : this.companyDetailsService.convertUTCToLocal( z.notificationDate, null, this.userTimeZone );
                                gn.notificationDate = z.notificationDate === null ? null :  gn.notificationDate = z.notificationDate;
                            } else {
                                gn.notificationDate = z.notificationDate;
                            }
                            return gn;
                        })
                        return n;
                    })
                    return b;
                });
                this.loadingNotification = false;
            } else {
                this.notifications = [];
            }
        });



    }

    // Get All Unread Notification
    private getUnreadNotifications() {
        this.companyDetailsService.getNotificationsUnread(this.filters, 0).subscribe((res:any) => {
            if (res.success === true) {
                this.notifications = res['data'].map((x:any) => {
                    let b = new NotificationModel();
                    b.applicationId = x.applicationId;
                    b.applicationName = x.applicationName;
                    b.notificationList = x.notificationList.map((y:any) => {
                        let n = new NotificationListModel();
                        n.dateGroup = y.dateGroup;
                        n.groupNotificationList = y.groupNotificationList.map((z:any) => {
                            let gn = new GroupNotificationListModel();
                            gn.applicationId = z.applicationId;
                            gn.comment = z.comment;
                            gn.imgUrl = z.imgUrl;
                            gn.isSeen = z.isSeen;
                            gn.moduleId = z.moduleId;
                            gn.moduleName = z.moduleName;
                            if (x.applicationName === 'Hourstock') {
                                gn.notificationDate = z.notificationDate === null ? null :gn.notificationDate = z.notificationDate;
                            } else {
                                gn.notificationDate = z.notificationDate;
                            }
                            gn.notificationDesc = z.notificationDesc;
                            gn.notificationId = z.notificationId;
                            gn.notificationText = z.notificationText;
                            gn.receiverCompanyId = z.receiverCompanyId;
                            gn.receiverUserId = z.receiverUserId;
                            gn.senderCompanyId = z.senderCompanyId;
                            gn.senderUserId = z.senderUserId;
                            return gn;
                        })
                        return n;
                    })
                    return b;
                });
                this.loadingNotification = false;
            } else {
                this.notifications = [];
            }
        });
    }

    // Switch to all read with unread OR Only Unread
    public readSwitchChange() {
        this.loadingNotification = true;
        this.notifications = [];
        if (this.switchValue) {
            this.getUnreadNotifications();
        } else {
            this.getNotifications();
        }
    }

    // Mark read as Group wise
    public markAllReadCompCart(groupName:string, applicationId:number) {
        const notificationIds = [];
        this.notifications.filter(x => x.applicationId === applicationId)[0].notificationList.forEach((x:any) => {
            if (x.dateGroup === groupName) {
                notificationIds.push(
                    ...x.groupNotificationList.map((y:any) => {
                        return { notificationId: y.notificationId, isSeen: 1 };
                    })
                );
            }
        });

        this.notifications.forEach(x => {
            if (x.dateGroup === groupName) {
                x.groupNotificationList.forEach((y:any) => {
                    y.isSeen = true;
                });
            }
        });

        this.updateNotificationsAPI(notificationIds);
    }

    // Mark as read to notification
    public markSingleNotificationAsRead(notificationId:number, applicationId:number):void {
        this.notifications.filter(x => x.applicationId === applicationId)[0].notificationList.forEach((x:any) => {
            x.groupNotificationList.forEach((y:any) => {
                if (y.notificationId === notificationId) {
                    y.isSeen = true;
                }
            });
        });
        this.updateNotificationsAPI([{ notificationId, isSeen: 1 }]);
    }

    // Update Read Notification
    private updateNotificationsAPI(notificationIds: any[]) {
        this.companyDetailsService.markNotificationsRead(notificationIds).subscribe((res:any) => {
            if (res.success === true) {
                this.getNotificationsCount();
            }
            if (this.switchValue) {
                this.getUnreadNotifications();
            }
        });
    }

    public getimg(filename) {
        return this.headingtitleService.getImagesS3('groupsoft' + '/profile', filename, true);
    }


}



