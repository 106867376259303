import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-overlay-model',
    template: `

        <div class="container-fluid contentSection" style="padding:0px !important;position:fixed;top:0;z-index:1050;background-color: rgba(0,0,0,0.5)">    
            <div nz-row [nzGutter]="[2]" nzJustify="space-around" nzAlign="middle" style="height:100vh;">
                <div nz-col nzSpan="6" >
                    <nz-card [nzBordered]="false" class="text-center logoContainer ">
                        <ng-container *ngIf="template === 'InternetConnection'">
                            <h2>No Internet Connection</h2>
                        </ng-container>
                        <ng-container *ngIf="template === 'TokenExpired'">
                            <h2 class="font-600">Sorry, your token has expired.</h2>
                            <h5 class="font-400">Please Contact {{siteSettings[0]?.applicationName === null ? 'Grcarapp':siteSettings[0]?.applicationName}} Support Team at support@{{siteSettings[0]?.domainName}} </h5>
                        </ng-container>
                    </nz-card>
                </div>
            </div>
        </div>

    `,
    styles: [`
        
    `]
})
    
    
export class OverlayModelComponent implements OnInit {

    @Input() siteSettings:any;
    @Input() heading:string;
    @Input() description: string;
    @Input() img: string;
    @Input() otherObject: any;
    @Input() template: string;
    
    constructor() { }

    ngOnInit() {

    }

}