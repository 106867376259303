import { Injectable } from '@angular/core';

enum ThemeType {
  dark = 'dark',
  default = 'default',
  red = 'red',
  purple = 'purple',
  lightBlue = 'lightBlue',
  grey = 'grey'
}


@Injectable({
  providedIn: 'root',
})
export class ThemeService {

  currentTheme = ThemeType.default;

  constructor() { }

  // private removeUnusedTheme(theme: ThemeType): void {
  //   document.documentElement.classList.remove(theme);
  //   const removedThemeStyle = document.getElementById(theme);
  //   if (removedThemeStyle) {
  //     document.head.removeChild(removedThemeStyle);
  //   }
  // }

  private loadCss(href: string, id: string): Promise<Event> {
    return new Promise((resolve, reject) => {
      const style = document.createElement('link');
      style.rel = 'stylesheet';
      style.href = href;
      style.id = id;
      style.onload = resolve;
      style.onerror = reject;
      document.head.append(style);
    });
  }

  public loadTheme(firstLoad = true): Promise<Event> {
    const theme = this.currentTheme;
    if (firstLoad) {
      document.documentElement.classList.add(theme);
    }
    return new Promise<Event>((resolve, reject) => {
      this.loadCss(`${theme}.css`, theme).then(
        (e) => {
          if (!firstLoad) {
            document.documentElement.classList.add(theme);
          }
          // this.removeUnusedTheme(this.reverseTheme(theme));
          resolve(e);
        },
        (e) => reject(e)
      );
    });
  }

  public toggleTheme(colorTheme): Promise<Event> {
    this.currentTheme = colorTheme;
    return this.loadTheme(true);
  }
}
