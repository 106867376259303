// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  ENVIRONMENT: {
    title: 'prod',
    baseURL: 'https://groupsoft.grcarapp.com/api/', // AWS Server API
    weatherURL: 'https://groupsoft.grcarapp.com/weather/', // Weather API      
    imageUrl: 'https://groupsoft.grcarapp.com/api-images/',  //Aws Server Profile Image
    auth0ClientID: 'xVw39ZKQHWEJGW8iE7l4xopqCYZbv7hF',
    auth0Domain: 'groupsoftprd.auth0.com',
    auth0Connection: 'akmcoachprd',
	  audience : 'https://groupsoftprd.auth0.com',
    weatherkey:'d7268838f2e2adb9cceb6df1545b41f6/'
  }
};