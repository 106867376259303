
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import * as auth0 from 'auth0-js';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})

export class Auth0Service {
 
  auth0 = new auth0.WebAuth({
    clientID: environment.ENVIRONMENT.auth0ClientID,
    domain: environment.ENVIRONMENT.auth0Domain
  });

  constructor(
    private router: Router,
    private cookieService: CookieService
  ) { }

  public handleLoginCallback(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash({ hash: window.location.hash }, function (err, authResult) {
        if (authResult && authResult.accessToken && authResult.idToken) {
          
          const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
          localStorage.setItem('access_token', authResult.accessToken);
          localStorage.setItem('id_token', authResult.idToken);
          localStorage.setItem('expires_at', expiresAt);
          
          window.location.hash = '';
          resolve(authResult);
        } else {
          reject(err);
        }
      });
    });
  }


}