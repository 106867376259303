import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import 'moment-timezone';

@Injectable({
    providedIn: 'root'
})


export class CompanyDetailsService {

    constructor(
        private commonService: CommonService
    ) { }

    // Get All Company
    public getCompanyList() {
        return this.commonService.getHttpReqNodeapi('companies');
    }

    // Get Single Company
    public getCompany(id: any) {
        return this.commonService.getsingleHttpReqNodeapi('companies/', id);
    }

    // get current UTC date and time
    public getCurrentUTCDateTime() {
        return moment.utc().format('YYYY-MM-DD hh:mm:ss');
    }

    // convert utc time to local timezone
    public convertUTCToLocal(utcDt: any, utcDtFormat: string, timezone: string) {
        if (timezone === null) {
            let toDt = moment.utc(utcDt, utcDtFormat).toDate();
            return moment(toDt).format('YYYY-MM-DD hh:mm:ss');
        } else {
            return moment.utc(utcDt, utcDtFormat).tz(timezone).format('YYYY-MM-DD hh:mm:ss');
        }
    }

    /************************************** Applications ********************************************/

    // Get All Application
    public getApplicationList() {
        return this.commonService.getHttpReqNodeapi('applications');
    }

    // Get Single Application
    public getSingleApplicationList(id: any) {
        return this.commonService.getsingleHttpReqNodeapi('applications/', id);
    }

    // Get Single Application Filter
    public getSingleApplicationfilterList() {
        return this.commonService.getHttpReqNodeapi('applications?filter[fields][applicationName]=true&filter[fields][applicationId]=true&filter[fields][router]=true');
    }

    // Not working Prorperly
    // Get Application Filter by Route Name
    public geApplicationByRoute(route: string) {
        return this.commonService.getHttpReqNodeapi('applications?filter[fields][router]=' + route);
    }

    /********************************** Manage Role and Group in Application **************************************/

    public getRole(id: any, applicationId: any) {
        // return this.commonService.getHttpReqNodeapi('roles?filter[offset]=0&filter[limit]=10&filter[where][roleId]=' + id + '&filter[where][applicationId]=' + applicationId);
        return this.commonService.getHttpReqNodeapi('roles?filter[offset]=0&filter[where][roleId]=' + id + '&filter[where][applicationId]=' + applicationId);
    }

    public getRoles(id: any) {
        return this.commonService.getHttpReqNodeapi('roles?filter[where][roleId]=' + id);
    }

    // Get Application List by company id and role id
    public getApplications(roleid:any, companyid:number) {
        return this.commonService.getHttpReqNodeapi(`roles/applicationRole?filter[where][companyId]=${companyid}&filter[where][roleId]=${roleid}`);
    }

    public getGroupByRole(id: any) {
        return this.commonService.getHttpReqNodeapi(`groups?filter[where][groupId]=${id}`);
    }

    public getCustomerPortal(data: any) {
        return this.commonService.postHttpReqNodeapi_Logs('stripe/checkout', data);
    }


    /*************************************** User Profile **********************************************/

    // Login With Application
    public loginUser(username: string, password: string) {
        let query = '';
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(username)) {
            query = '&filter[where][email]=' + username;
        } else {
            query = '&filter[where][username]=' + username;
        }
        return this.commonService.getHttpReqNodeapi_login('users/login?filter[offset]=0&filter[type]=login&filter[where][password]=' + password + query);
    }

    // Get User Detail By Email
    public getUser(username: any) {
        return this.commonService.getHttpReqNodeapi_login('users/login?filter[offset]=0&filter[where][email]=' + username);
    }

    // Forgot User Password
    public forgotPassword(data: any) {
        return this.commonService.postHttpReqNodeapi('users/forgetPwd', data);
    }

    // Verify user Token
    public verifyToken(data: any) {
        return this.commonService.postHttpReqNodeapi('users_pwd_token_validation', data);
    }

    // User Rest Password
    public resetPassword(data: any) {
        return this.commonService.postHttpReqNodeapi('users_pwd_update', data);
    }

    // Verify Vendor Token
    public verifyVendorToken(data: any) {
        return this.commonService.postHttpReqNodeapi('BPConnect/tokenVerify', data);
    }

    // Update Status Vendors
    public updateStatusVendors(data: any) {
        return this.commonService.putHttpReqNodeapi_Noid('BPConnect/vendorStatus', data);
    }

    /******************************************** Frontend user Log ****************************************************/

    // Get User Login & logout Logs
    public getUserLog(userId: any, limit: number) {
        return this.commonService.getHttpReqNodeapi(`frontend_user_log?filter[offset]=0&filter[limit]=${limit}&filter[where][frontend_user_id]=${userId}`);
    }

    // Get Single latest User Log
    public getUserLastLog(userId: any, action: string) {
        return this.commonService.getHttpReqNodeapi('frontend_user_log?filter[offset]=1&filter[limit]=1&filter[where][frontend_user_id]=' + userId + '&filter[where][action]=' + action);
    }

    // Post User login & Logout Logs
    public postUserLog(data: any) {
        return this.commonService.postHttpReqNodeapi_Logs('frontend_user_log', data);
    }

    /*************************************** Google Map **********************************************/

    public storeMaplist(data: any) {
        return this.commonService.postHttpReqNodeapi('users/latLang', data);
    }

    /***************************************** Encrypt UserDetails ***************************************/

    public setAuthToken(token: string) {
        const ciphertext = CryptoJS.AES.encrypt(token, 'authToken').toString();
        localStorage.setItem('authToken', ciphertext);
    }


    /**************************************** IP Address *****************************************************/

    // Get IP Address Of User
    public getIPAddress() {
        return this.commonService.getIPAdressRequest("getIPAddress");
    }

    /**************************************** Weather Forcast *****************************************************/

    // Get Weather Forecast
    public postWeatherForcast(data: any) {
        return this.commonService.postHttpReqNodeapi("users/weather", data);
    }

    /**************************************** User Setup *****************************************************/

    // Get All Users
    public getAllUsers() {
        return this.commonService.getHttpReqNodeapi('users');
    }

    // Get Stripe products
    public getStripeProducts(route) {
        return this.commonService.getHttpReqNodeapi('application/getPlan?filter[where][router]=' + route)
    }

    // Get List of All Tabs By Application Id
    public getFilterTabByApplication(id: number) {
        return this.commonService.getHttpReqNodeapi(`tabs?filter[where][applicationId]=${id}`);
    }

    // Get All User by Company wise
    public getUserFilterCompanyId(id: number) {
        return this.commonService.getHttpReqNodeapi(`users?filter[where][companyId]=${id}`);
    }

    // Get User Settings by User wise
    public getUserSettings(id: number) {
        return this.commonService.getHttpReqNodeapi(`users/setting?filter[where][userId]=${id}`);
    }

    // Get User Details
    public getUserDetails(id: number) {
        return this.commonService.getHttpReqNodeapi(`users/setting?filter[where][id]=${id}`);
    }

    // User Settings
    public curdUserSettings(data: any) {
        return this.commonService.postHttpReqNodeapi('users/setting', data);
    }

    // Get All ROle By Company wise
    public getCompanybyRoleId(id: number) {
        return this.commonService.getHttpReqNodeapi(`roles?filter[where][companyId]=${id}`);
    }

    // softDelete User
    public softDeleteUsers(data: any) {
        return this.commonService.postHttpReqNodeapi('users/status', data);
    }

    // Get User Details
    public getFilterUserId(id: number) {
        return this.commonService.getHttpReqNodeapi(`users?filter[where][id]=${id}`);
    }

    // Set or Reset password of User
    public resendRestMailUsers(data: any) {
        return this.commonService.postHttpReqNodeapi('users/resend/', data);
    }

    // Create User
    public createUsers(data: any) {
        return this.commonService.postHttpReqNodeapi('users', data);
    }

    // Update User
    public updateUsers(data: any) {
        return this.commonService.putHttpReqNodeapi('users/', data.id, data);
    }

    /**************************************** Profile *****************************************************/

    // Change User Password Or User Profile
    public updateUserProfile(id: number, data: any) {
        return this.commonService.putHttpReqNodeapi('users/', id, data);
    }

    public getStaticCurrency(): any {
        return this.commonService.getHttpReqNodeapi(`tne/currencyMaster`);
    }

    // Get Compliance set List
    public getComplianceSetList(companyId:number) { 
        return this.commonService.getHttpReqNodeapi(`vCompliance/setMaster?filter[where][companyId]=${companyId}&filter[where][isActive]=1`)
  }
  
    // Get Country List
    public getCountryList() {
        return this.commonService.getIPAdressRequest("https://raw.githubusercontent.com/dr5hn/countries-states-cities-database/master/countries.json");
    }

    // Get States List
    public getStateList() {
        return this.commonService.getIPAdressRequest("https://raw.githubusercontent.com/dr5hn/countries-states-cities-database/master/states.json");
    }
    
    public getSubscriptionList(companyId:number): any {
      return this.commonService.getHttpReqNodeapi(`profile/subscriptionList?filter[where][companyId]=${companyId}&filter[where][subscription]=1`);
    }

    public getDownloadUsageList(companyId:number, filter:string): any {
      if(filter === null) {
        return this.commonService.getHttpReqNodeapi(`downloadLog/list?filter[where][companyId]=${companyId}`);
      } else {
        return this.commonService.getHttpReqNodeapi(`downloadLog/list?filter[where][companyId]=${companyId}${filter}`);
      }
  }
  
  // add download usage log
  public postDownloadUsage(data: any) {
    return this.commonService.postHttpReqNodeapi('downloadLog/create', data);
  }

    /**************************************** Notification *****************************************************/

    public getNotificationsUnread(filter, isSeen): any {
        return this.commonService.getHttpReqNodeapipb(
            `vCompliance/requestNotification?${filter}&filter[where][isSeen]=${isSeen}`
        );
    }

    public getNotificationsAll(filter): any {
        return this.commonService.getHttpReqNodeapipb(
            `vCompliance/requestNotification?${filter}`
        );
    }

    public getNotificationsCount(filter): any {
        return this.commonService.getHttpReqNodeapi(
            `notificationCount?${filter}`
        );
    }

    public markNotificationsRead(notificationIds): any {
        return this.commonService.putHttpReqNodeapi_Noid(
            `vCompliance/notification`,
            notificationIds
        );
    }

    /**************************************** Notification settings *****************************************************/

    // Get User Notification Settings by User wise
    public getUserNotificationSettings(id: number) {
        return this.commonService.getHttpReqNodeapi(`user/notificationSetting?filter[where][userId]=${id}`);
    }

    // Post User Notification Settings by User wise
    public postUserNotificationSettings(data: any) {
        return this.commonService.postHttpReqNodeapi('user/notificationSetting', data);
    }

    public vendorList() {
        return [
            {
              "VENDOR_NAME": "03 MEDIA"
            },
            {
              "VENDOR_NAME": "1 SOURCE SAFETY AND HEALTH"
            },
            {
              "VENDOR_NAME": "1015 DEKALB PIKE ASSOCIATES"
            },
            {
              "VENDOR_NAME": "1031 CORP.INC."
            },
            {
              "VENDOR_NAME": "1302 OLD WILMINGTON PIKE"
            },
            {
              "VENDOR_NAME": "1500 HIGH FAMILY LIMITED PARTNSP"
            },
            {
              "VENDOR_NAME": "1520 HIGH LIMITED PARTNERSHIP"
            },
            {
              "VENDOR_NAME": "15th POLICE DISTRICT"
            },
            {
              "VENDOR_NAME": "16A ASSOCIATES LTD"
            },
            {
              "VENDOR_NAME": "1701 PARTNERS LP"
            },
            {
              "VENDOR_NAME": "1701 PARTNERS"
            },
            {
              "VENDOR_NAME": 180
            },
            {
              "VENDOR_NAME": "1-800-FLOWERS.COM"
            },
            {
              "VENDOR_NAME": "1970 VALLEY FORGE ROAD"
            },
            {
              "VENDOR_NAME": "1998 RETA NAT'L CONV"
            },
            {
              "VENDOR_NAME": "19TH CENTURY INVESTMENTS"
            },
            {
              "VENDOR_NAME": "19th CENTURY INVESTMENTS"
            },
            {
              "VENDOR_NAME": "1st UNION NATIONAL-D.W.WOOD TRUST"
            },
            {
              "VENDOR_NAME": "1YARLLC"
            },
            {
              "VENDOR_NAME": "2/90 SIGN SYSTEMS"
            },
            {
              "VENDOR_NAME": "2004 CONFERENCE"
            },
            {
              "VENDOR_NAME": "2005 RETA NATIONAL CONVENTION"
            },
            {
              "VENDOR_NAME": "219 BULLENS LANE"
            },
            {
              "VENDOR_NAME": "2250 LINCOLN HIGHWAY"
            },
            {
              "VENDOR_NAME": "227 GATEWAY LLC"
            },
            {
              "VENDOR_NAME": "2300 LINCOLN HIGHWAY"
            },
            {
              "VENDOR_NAME": "24/7 FIBER NETWORK"
            },
            {
              "VENDOR_NAME": "240104 WITHOLDING"
            },
            {
              "VENDOR_NAME": "2nd POLICE DISTRICT"
            },
            {
              "VENDOR_NAME": "2ND STORY MARKET"
            },
            {
              "VENDOR_NAME": "3 BOYS"
            },
            {
              "VENDOR_NAME": "3420 KIRKWOOD HIGHWAY"
            },
            {
              "VENDOR_NAME": "3520 BETHLEHEM PIKE ASSOC"
            },
            {
              "VENDOR_NAME": "3520 BETHLEHEM PIKE ASSOC."
            },
            {
              "VENDOR_NAME": "3E COMPANY"
            },
            {
              "VENDOR_NAME": "3G CORPORATION"
            },
            {
              "VENDOR_NAME": "3M"
            },
            {
              "VENDOR_NAME": "4 ELEMENTS"
            },
            {
              "VENDOR_NAME": "4IMPRINT"
            },
            {
              "VENDOR_NAME": "4TelecomHelp"
            },
            {
              "VENDOR_NAME": "4U TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "5 S REALTY COMPANY"
            },
            {
              "VENDOR_NAME": "514TH CHIEFS GROUP"
            },
            {
              "VENDOR_NAME": "600 WHP"
            },
            {
              "VENDOR_NAME": "630 SKIPPACK LP"
            },
            {
              "VENDOR_NAME": "6800 RISING SUN"
            },
            {
              "VENDOR_NAME": "706 INVESTMENTS"
            },
            {
              "VENDOR_NAME": "706 INVESTMENTS"
            },
            {
              "VENDOR_NAME": "70WA ONE INC"
            },
            {
              "VENDOR_NAME": "7th P.D.A.C. HOLIDAY DINNER"
            },
            {
              "VENDOR_NAME": "7TH PDAC HOLIDAY DINNER"
            },
            {
              "VENDOR_NAME": 8017
            },
            {
              "VENDOR_NAME": "84 LUMBER COMPANY"
            },
            {
              "VENDOR_NAME": "841 SOUTH ASSOCIATES"
            },
            {
              "VENDOR_NAME": "841 SOUTH ASSOCIATES"
            },
            {
              "VENDOR_NAME": "8900 KREWSTOWN ASSOCIATES"
            },
            {
              "VENDOR_NAME": "8TH POLICE DISTRICT PDAC"
            },
            {
              "VENDOR_NAME": "912 1028 WALNUT ST ASSOCIATES LP"
            },
            {
              "VENDOR_NAME": "912 1028 WALNUT ST ASSOCIATESLP"
            },
            {
              "VENDOR_NAME": "91st RETA NATIONAL CONVENTION"
            },
            {
              "VENDOR_NAME": "941 100"
            },
            {
              "VENDOR_NAME": "A & B GARAGE & MACHINE SHOP"
            },
            {
              "VENDOR_NAME": "A & E CONSTRUCTION CO."
            },
            {
              "VENDOR_NAME": "A & M ENVIROMENTAL SERVICES"
            },
            {
              "VENDOR_NAME": "A & R ASSOCIATES"
            },
            {
              "VENDOR_NAME": "A & R MARKETING & PROMOTIONS"
            },
            {
              "VENDOR_NAME": "A & R MARKETING & PROMOTIONS"
            },
            {
              "VENDOR_NAME": "A & T NEWS SERVICE"
            },
            {
              "VENDOR_NAME": "A E AMER"
            },
            {
              "VENDOR_NAME": "A LITTLE SOMETHING NICE"
            },
            {
              "VENDOR_NAME": "A PREMIER TRAVEL AGENCY"
            },
            {
              "VENDOR_NAME": "A SHARPER PALATE CATERING CO.INC."
            },
            {
              "VENDOR_NAME": "A SIMPLY GREENER DISE"
            },
            {
              "VENDOR_NAME": "A SIMPLY GREENER SIDE"
            },
            {
              "VENDOR_NAME": "A STITCH AHEAD INC"
            },
            {
              "VENDOR_NAME": "A T & T MOBILITY"
            },
            {
              "VENDOR_NAME": "A T CONSTRUCTION"
            },
            {
              "VENDOR_NAME": "A TO Z ABSTRACTING"
            },
            {
              "VENDOR_NAME": "A&M LAWN MAINTENANCE"
            },
            {
              "VENDOR_NAME": "A. EJ'S LOCKSMITH"
            },
            {
              "VENDOR_NAME": "A. R. HUGHES & COMPANY"
            },
            {
              "VENDOR_NAME": "A.B.C.LENDING"
            },
            {
              "VENDOR_NAME": "A.C. DISPENSING EQUIPMENT"
            },
            {
              "VENDOR_NAME": "A.C. RADIO SUPPLY INC."
            },
            {
              "VENDOR_NAME": "A.C.E.PROPERTY MANAGEMENT"
            },
            {
              "VENDOR_NAME": "A.C.S.IMPRESSIONS IN CONCRETE"
            },
            {
              "VENDOR_NAME": "A.DUIE PYLE"
            },
            {
              "VENDOR_NAME": "A.F.CARVOLTH & SON CO"
            },
            {
              "VENDOR_NAME": "A.I. DUPONT H.S. GIRLS SOCCER"
            },
            {
              "VENDOR_NAME": "A.J.BLOSENSKI"
            },
            {
              "VENDOR_NAME": "A.RIFKIN COMPANY"
            },
            {
              "VENDOR_NAME": "A-1 EASY SELF STORAGE"
            },
            {
              "VENDOR_NAME": "A-1 LIMOUSINE INC"
            },
            {
              "VENDOR_NAME": "A-1 SANITATION SERVICE"
            },
            {
              "VENDOR_NAME": "AA DUCKETT"
            },
            {
              "VENDOR_NAME": "AAA DEVELOPMENT & MANAGEMENT"
            },
            {
              "VENDOR_NAME": "AAA ERS PROGRAM"
            },
            {
              "VENDOR_NAME": "AAA LOCK & SAFE CO"
            },
            {
              "VENDOR_NAME": "AAA LOCK & SECURITY"
            },
            {
              "VENDOR_NAME": "AAA MID-ATLANTIC INC."
            },
            {
              "VENDOR_NAME": "AAF INTERNATIONAL"
            },
            {
              "VENDOR_NAME": "AARP HEALTH CARE OPTIONS"
            },
            {
              "VENDOR_NAME": "ABA SERVICES"
            },
            {
              "VENDOR_NAME": "ABACUS INVENTORY SERVICE"
            },
            {
              "VENDOR_NAME": "ABBOTT LABORATORIES -EAS"
            },
            {
              "VENDOR_NAME": "ABBOUD"
            },
            {
              "VENDOR_NAME": "ABBOUD"
            },
            {
              "VENDOR_NAME": "ABC LENDING"
            },
            {
              "VENDOR_NAME": "ABC PAPER & CHEMICAL CO INC."
            },
            {
              "VENDOR_NAME": "ABC TRUCK & TIRE REPAIR"
            },
            {
              "VENDOR_NAME": "ABEL BROTHERS TOWING & AUTO"
            },
            {
              "VENDOR_NAME": "ABERDEEN HEALTH DEPARTMENT"
            },
            {
              "VENDOR_NAME": "ABERDEEN TWP P.B.A. LOCAL 163"
            },
            {
              "VENDOR_NAME": "ABESSINIO PROPERTY MANAGEMENT"
            },
            {
              "VENDOR_NAME": "ABF FREIGHT SYSTEMS"
            },
            {
              "VENDOR_NAME": "ABIC INTERNATIONAL CONSULTNTS"
            },
            {
              "VENDOR_NAME": "ABIGAIL WILLING"
            },
            {
              "VENDOR_NAME": "ABILITY AIR COND & REFRIG"
            },
            {
              "VENDOR_NAME": "ABINGTON TWP LOCAL TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "ABINGTON TWP TREASURER"
            },
            {
              "VENDOR_NAME": "ABINGTON TWSP TREASURER"
            },
            {
              "VENDOR_NAME": "ABLOY SECURITY"
            },
            {
              "VENDOR_NAME": "ABNB FEDERAL CREDIT UNION"
            },
            {
              "VENDOR_NAME": "ABRA ENTERTAINMENT"
            },
            {
              "VENDOR_NAME": "ABSECON FIRE CO. #1"
            },
            {
              "VENDOR_NAME": "ABSOLUTE DISTRIBUTION"
            },
            {
              "VENDOR_NAME": "ABUNDANT LIFE COMMUNITY"
            },
            {
              "VENDOR_NAME": "AC MANNY CONSTRUCTION"
            },
            {
              "VENDOR_NAME": "AC MARKETING"
            },
            {
              "VENDOR_NAME": "AC NIELSEN"
            },
            {
              "VENDOR_NAME": "ACADEMY ELECTRIC"
            },
            {
              "VENDOR_NAME": "ACCENTURE"
            },
            {
              "VENDOR_NAME": "ACCENTURE LLP"
            },
            {
              "VENDOR_NAME": "ACCESS"
            },
            {
              "VENDOR_NAME": "ACCESS SPORTS"
            },
            {
              "VENDOR_NAME": "ACCESSDATA CORPORATION"
            },
            {
              "VENDOR_NAME": "ACCESSIBILITY DEVELOPMENT"
            },
            {
              "VENDOR_NAME": "ACCESSIT GROUP INC."
            },
            {
              "VENDOR_NAME": "ACCOMACK GEN'L DIST. CT."
            },
            {
              "VENDOR_NAME": "ACCOMMODATION MOLLEN"
            },
            {
              "VENDOR_NAME": "ACCOUNT CONTROL TECHNOLOGY"
            },
            {
              "VENDOR_NAME": "ACCOUNTEMPS"
            },
            {
              "VENDOR_NAME": "ACCRAPLY"
            },
            {
              "VENDOR_NAME": "ACCREDITED ENVIRONMENTAL"
            },
            {
              "VENDOR_NAME": "ACCRUENT"
            },
            {
              "VENDOR_NAME": "ACCUME PARTNERS"
            },
            {
              "VENDOR_NAME": "ACCURATE ARCHIVE"
            },
            {
              "VENDOR_NAME": "ACCU-SORT SYSTEMS"
            },
            {
              "VENDOR_NAME": "ACCUTEST LABORATORIES INC"
            },
            {
              "VENDOR_NAME": "ACCUTEST LABORATORIES INC."
            },
            {
              "VENDOR_NAME": "ACCU-TIME SYSTEMS"
            },
            {
              "VENDOR_NAME": "ACE CASH EXPRESS"
            },
            {
              "VENDOR_NAME": "ACE CHECK EXCHANGE"
            },
            {
              "VENDOR_NAME": "ACE ELECTRIC SERVICES"
            },
            {
              "VENDOR_NAME": "ACE OUTDOOR POWER EQUIP"
            },
            {
              "VENDOR_NAME": "ACE OVERHEAD DOOR COMP"
            },
            {
              "VENDOR_NAME": "ACE PLUMBING HEATING & ELECTRICAL S"
            },
            {
              "VENDOR_NAME": "ACE'S SEPTIC KARE"
            },
            {
              "VENDOR_NAME": "ACHIEVEMENT IN MOTION"
            },
            {
              "VENDOR_NAME": "ACJM&M ENTERPRISES"
            },
            {
              "VENDOR_NAME": "ACME NEWSPAPERS"
            },
            {
              "VENDOR_NAME": "ACOSTA RETAIL SOURCE"
            },
            {
              "VENDOR_NAME": "ACP CONTRACTING INC."
            },
            {
              "VENDOR_NAME": "ACSI"
            },
            {
              "VENDOR_NAME": "ACSYS"
            },
            {
              "VENDOR_NAME": "ACTION DUPLICATION INC"
            },
            {
              "VENDOR_NAME": "ACTION LEARNING ASSOCIATES"
            },
            {
              "VENDOR_NAME": "ACTION MANAGEMENT INC."
            },
            {
              "VENDOR_NAME": "ACTION PLUMBING"
            },
            {
              "VENDOR_NAME": "ACTION SERVICES GROUP"
            },
            {
              "VENDOR_NAME": "ACTS"
            },
            {
              "VENDOR_NAME": "ACTUAL COMMUNICATION"
            },
            {
              "VENDOR_NAME": "ACUMEN LEARNING"
            },
            {
              "VENDOR_NAME": "ACXIOM INFO SECURITY SERVICES"
            },
            {
              "VENDOR_NAME": "AD PRODUCTS"
            },
            {
              "VENDOR_NAME": "ADAM SCHALL"
            },
            {
              "VENDOR_NAME": "ADAM TALIAFERRO FOUNDATION"
            },
            {
              "VENDOR_NAME": "ADAM WOOD FOULKE"
            },
            {
              "VENDOR_NAME": "ADAM'S ELECTRICAL CONSTRUCTION"
            },
            {
              "VENDOR_NAME": "ADAMS TRAFFIC"
            },
            {
              "VENDOR_NAME": "ADAMUCCI ASSOCIATES LLC"
            },
            {
              "VENDOR_NAME": "ADAMUCCI OIL CO INC"
            },
            {
              "VENDOR_NAME": "ADCO INDUSTRIES"
            },
            {
              "VENDOR_NAME": "ADDRIENNE WHITFIELD"
            },
            {
              "VENDOR_NAME": "ADECCO STAFFING SERVICES"
            },
            {
              "VENDOR_NAME": "ADELE CROMPTON"
            },
            {
              "VENDOR_NAME": "ADELE G M SANDS"
            },
            {
              "VENDOR_NAME": "ADELE G. SANDS"
            },
            {
              "VENDOR_NAME": "ADELPHIA"
            },
            {
              "VENDOR_NAME": "ADELPHIA DEPTFORD INC."
            },
            {
              "VENDOR_NAME": "ADIRONDACK BEVERAGES"
            },
            {
              "VENDOR_NAME": "ADM. FOR CHILD SUPPORT ENF. ASUME"
            },
            {
              "VENDOR_NAME": "ADMINISTRATION FOR CHILD SUPPORT"
            },
            {
              "VENDOR_NAME": "ADMIRAL BUSINESS ASSOCIATES"
            },
            {
              "VENDOR_NAME": "ADMIRAL LIMOUSINE SERVICE"
            },
            {
              "VENDOR_NAME": "ADR OPTIONS"
            },
            {
              "VENDOR_NAME": "ADTECH SYSTEMS"
            },
            {
              "VENDOR_NAME": "ADULT PROBATION & PAROLE DEPT."
            },
            {
              "VENDOR_NAME": "ADVANCE AMERICA"
            },
            {
              "VENDOR_NAME": "ADVANCE OFFICE INSTALLATIONS"
            },
            {
              "VENDOR_NAME": "ADVANCED AUDIO VISUAL"
            },
            {
              "VENDOR_NAME": "ADVANCED AUTOMATION ASSOC."
            },
            {
              "VENDOR_NAME": "ADVANCED DOCUMENT SYSTEMS & SUPPLY"
            },
            {
              "VENDOR_NAME": "ADVANCED ENERGY COMMERCE INC"
            },
            {
              "VENDOR_NAME": "ADVANCED ENERGY COMMERCE"
            },
            {
              "VENDOR_NAME": "ADVANCED ENTERPRISES INC"
            },
            {
              "VENDOR_NAME": "ADVANCED FUELING SYS INC"
            },
            {
              "VENDOR_NAME": "ADVANCED HYDRAULIC SYSTEMS INC"
            },
            {
              "VENDOR_NAME": "ADVANCED IMAGING SYSTEMS"
            },
            {
              "VENDOR_NAME": "ADVANCED INSTRUMENTS"
            },
            {
              "VENDOR_NAME": "ADVANCED OFFICE ENVIRONMENTS INC"
            },
            {
              "VENDOR_NAME": "ADVANCED POWER CONTROL"
            },
            {
              "VENDOR_NAME": "ADVANCED POWER TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "ADVANCED STAGING PRODUCTIONS"
            },
            {
              "VENDOR_NAME": "ADVANCED UNLIMITED WINDOWS INC"
            },
            {
              "VENDOR_NAME": "ADVANCING OPPORTUNITIES"
            },
            {
              "VENDOR_NAME": "ADVANTAGE ASSET II"
            },
            {
              "VENDOR_NAME": "ADVANTAGE PROMOTIONS"
            },
            {
              "VENDOR_NAME": "ADVENTURE AQUARIUM"
            },
            {
              "VENDOR_NAME": "ADX CREATIVE SERVICES"
            },
            {
              "VENDOR_NAME": "AEB ASSOCIATES INC"
            },
            {
              "VENDOR_NAME": "AERIAL SIGNS & AWNINGS"
            },
            {
              "VENDOR_NAME": "AERO MANUFACTURING CO"
            },
            {
              "VENDOR_NAME": "AEROTEK COMMERCIAL STAFFING"
            },
            {
              "VENDOR_NAME": "AEROTEK PROFESSIONAL SERVICES"
            },
            {
              "VENDOR_NAME": "AETNA"
            },
            {
              "VENDOR_NAME": "AETNA INS CO."
            },
            {
              "VENDOR_NAME": "AETNA U.S. HEALTHCARE"
            },
            {
              "VENDOR_NAME": "AFC SERVICE"
            },
            {
              "VENDOR_NAME": "AFC SERVICES III"
            },
            {
              "VENDOR_NAME": "AFCO"
            },
            {
              "VENDOR_NAME": "AFFORDABLE SEPTIC & EXCAVATING CORP"
            },
            {
              "VENDOR_NAME": "AG TRUCKING"
            },
            {
              "VENDOR_NAME": "AGEX"
            },
            {
              "VENDOR_NAME": "AGILYSYS"
            },
            {
              "VENDOR_NAME": "AGM INDUSTRIES INC"
            },
            {
              "VENDOR_NAME": "AGNES WILSON"
            },
            {
              "VENDOR_NAME": "AGRA INFORMA"
            },
            {
              "VENDOR_NAME": "AGRO FARMA INC."
            },
            {
              "VENDOR_NAME": "AGS"
            },
            {
              "VENDOR_NAME": "AGWA"
            },
            {
              "VENDOR_NAME": "AHOLD FINANCIAL SERVICES"
            },
            {
              "VENDOR_NAME": "AIB"
            },
            {
              "VENDOR_NAME": "AICPA"
            },
            {
              "VENDOR_NAME": "AICPA DUES PROCESSING"
            },
            {
              "VENDOR_NAME": "AID FOR FRIENDS"
            },
            {
              "VENDOR_NAME": "AIG CLAIM SERVICES"
            },
            {
              "VENDOR_NAME": "AIR DELIGHTS"
            },
            {
              "VENDOR_NAME": "AIR-BLAST INC."
            },
            {
              "VENDOR_NAME": "AIRBORNE EXPRESS"
            },
            {
              "VENDOR_NAME": "AIRIUS"
            },
            {
              "VENDOR_NAME": "AIRMAGNET"
            },
            {
              "VENDOR_NAME": "AIR-OIL SYSTEMS"
            },
            {
              "VENDOR_NAME": "AirPac"
            },
            {
              "VENDOR_NAME": "AIRPARK"
            },
            {
              "VENDOR_NAME": "AIRPURE FILTER SALES & SERVICE"
            },
            {
              "VENDOR_NAME": "AIRS HUMAN CAPITAL SOLUTIONS"
            },
            {
              "VENDOR_NAME": "AIRWAYS CLEANING & FIREPROOFING"
            },
            {
              "VENDOR_NAME": "AJ LEHMAN APPRAIAL"
            },
            {
              "VENDOR_NAME": "AJILON PROFESSIONAL STAFFING"
            },
            {
              "VENDOR_NAME": "AKEHURST LANDSCAPE SERVICE"
            },
            {
              "VENDOR_NAME": "AKERMAN SENTERFITT"
            },
            {
              "VENDOR_NAME": "AKJ MOUNT LAUREL"
            },
            {
              "VENDOR_NAME": "AL FRANKEL"
            },
            {
              "VENDOR_NAME": "AL JOHNSON COMPANY"
            },
            {
              "VENDOR_NAME": "AL MEANEY"
            },
            {
              "VENDOR_NAME": "ALABAMA CHILD SUPP.PAYMENT CTR."
            },
            {
              "VENDOR_NAME": "ALABAMA CHILD SUPPORT PAYMENT CTR"
            },
            {
              "VENDOR_NAME": "ALAN H.SCHORR & ASSOCIATES P.C."
            },
            {
              "VENDOR_NAME": "ALAN KRUPNICK"
            },
            {
              "VENDOR_NAME": "ALAN P.KELMAN & ASSOCIATES"
            },
            {
              "VENDOR_NAME": "ALAN W. JOSEPH"
            },
            {
              "VENDOR_NAME": "ALARMWATCH"
            },
            {
              "VENDOR_NAME": "ALBER-HAFF PARKING SERVICE"
            },
            {
              "VENDOR_NAME": "ALBERT & PATRICIA M KENNEY T/E"
            },
            {
              "VENDOR_NAME": "ALBERT BROUGHTON"
            },
            {
              "VENDOR_NAME": "ALBERT ELLIS INC"
            },
            {
              "VENDOR_NAME": "ALBERT L & PATRICIA M /"
            },
            {
              "VENDOR_NAME": "ALBERTA TALLADA"
            },
            {
              "VENDOR_NAME": "ALBERTO G. PEREZ"
            },
            {
              "VENDOR_NAME": "ALBRIGHT COLLEGE"
            },
            {
              "VENDOR_NAME": "ALCOHOL & TOBACCO TAX AND"
            },
            {
              "VENDOR_NAME": "ALCOM PRINTING GROUP INC"
            },
            {
              "VENDOR_NAME": "ALDERFER GLASS CO"
            },
            {
              "VENDOR_NAME": "ALDERSON ENGINEERING"
            },
            {
              "VENDOR_NAME": "ALDRICH SCHOOL PTO"
            },
            {
              "VENDOR_NAME": "ALEX VDOVENKO"
            },
            {
              "VENDOR_NAME": "ALEXANDER & CLEAVER"
            },
            {
              "VENDOR_NAME": "ALEXANDER HAMILTON INSTIT"
            },
            {
              "VENDOR_NAME": "ALEXANDER J LELLI"
            },
            {
              "VENDOR_NAME": "ALEXANDER KROWZOW"
            },
            {
              "VENDOR_NAME": "ALEXANDRIA CIRCUIT COURT"
            },
            {
              "VENDOR_NAME": "ALEXANDRIA GEN'L DIST. COURT"
            },
            {
              "VENDOR_NAME": "ALEX'S LEMONADE STAND FOUNDATION"
            },
            {
              "VENDOR_NAME": "ALFA-TRONICS INC"
            },
            {
              "VENDOR_NAME": "ALFRED MEANEY"
            },
            {
              "VENDOR_NAME": "ALI-ABA"
            },
            {
              "VENDOR_NAME": "ALISHA LEWIS"
            },
            {
              "VENDOR_NAME": "ALISHA TRESPALACIOS"
            },
            {
              "VENDOR_NAME": "ALL HEALTH / AMERINET"
            },
            {
              "VENDOR_NAME": "ALL HOURS TOWING"
            },
            {
              "VENDOR_NAME": "ALL IN THE CARDS"
            },
            {
              "VENDOR_NAME": "ALL RETAIL JOBS"
            },
            {
              "VENDOR_NAME": "ALL RISK"
            },
            {
              "VENDOR_NAME": "ALLAN A MYERS"
            },
            {
              "VENDOR_NAME": "ALLAN A.MYERS"
            },
            {
              "VENDOR_NAME": "ALLEER TRAINING & CONSULTING"
            },
            {
              "VENDOR_NAME": "ALLEGHENY POWER"
            },
            {
              "VENDOR_NAME": "ALLEN AUERBACH"
            },
            {
              "VENDOR_NAME": "ALLEN B FREEDMAN & KAY FREEDMAN"
            },
            {
              "VENDOR_NAME": "ALLEN INDUSTRIES INC"
            },
            {
              "VENDOR_NAME": "ALLEN J MUTZIG"
            },
            {
              "VENDOR_NAME": "ALLEN SYSTEMS GROUP"
            },
            {
              "VENDOR_NAME": "ALLENTOWN CS ASSOCIATES"
            },
            {
              "VENDOR_NAME": "ALLIANCE ENVIROMENTAL SYSTEMS"
            },
            {
              "VENDOR_NAME": "ALLIANCE MARKETING PARTNERS"
            },
            {
              "VENDOR_NAME": "ALLIANCE MARKETING SERV."
            },
            {
              "VENDOR_NAME": "ALLIANCE MECHANICAL SERVICES"
            },
            {
              "VENDOR_NAME": "ALLIANCE REALTY MANAGEMENT"
            },
            {
              "VENDOR_NAME": "ALLIANCE TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "ALLIED CASH ADVANCE"
            },
            {
              "VENDOR_NAME": "ALLIED ELECTRONICS INC"
            },
            {
              "VENDOR_NAME": "ALLIED ELECTRONICS"
            },
            {
              "VENDOR_NAME": "ALLIED INTERSTATE"
            },
            {
              "VENDOR_NAME": "ALLIED INTERSTATE INC"
            },
            {
              "VENDOR_NAME": "ALLIED INTERSTATE INC."
            },
            {
              "VENDOR_NAME": "ALLIED INTERSTATE INC.FLORIDA DOE"
            },
            {
              "VENDOR_NAME": "ALLIED METER SERVICE"
            },
            {
              "VENDOR_NAME": "ALLIED OFFICE PRODUCTS"
            },
            {
              "VENDOR_NAME": "ALLIED VAN LINES"
            },
            {
              "VENDOR_NAME": "ALLIED WASTE SERVICES"
            },
            {
              "VENDOR_NAME": "ALLIES INC"
            },
            {
              "VENDOR_NAME": "ALLISON B. WOOD"
            },
            {
              "VENDOR_NAME": "ALLONARDO BROS GARAGE"
            },
            {
              "VENDOR_NAME": "ALLPAVE PAVEMENT SERVICES"
            },
            {
              "VENDOR_NAME": "ALLSCRIPTS"
            },
            {
              "VENDOR_NAME": "ALL-SET PLUMBING AND HEATING"
            },
            {
              "VENDOR_NAME": "ALL-SHORE PAVING"
            },
            {
              "VENDOR_NAME": "ALL-STATE CAREER"
            },
            {
              "VENDOR_NAME": "ALL-STATE FENCE"
            },
            {
              "VENDOR_NAME": "ALLYSON FILIPPS"
            },
            {
              "VENDOR_NAME": "ALLYSON MORRIS"
            },
            {
              "VENDOR_NAME": "ALM MEDIA LLC"
            },
            {
              "VENDOR_NAME": "ALOYSIUS"
            },
            {
              "VENDOR_NAME": "ALS"
            },
            {
              "VENDOR_NAME": "ALS ASSOCIATION"
            },
            {
              "VENDOR_NAME": "ALTECA LTD."
            },
            {
              "VENDOR_NAME": "ALTERNATIVE FUTURES ASSOC."
            },
            {
              "VENDOR_NAME": "ALTMAN WEIL PUBLICATIONS"
            },
            {
              "VENDOR_NAME": "ALTO'S EXPRESS"
            },
            {
              "VENDOR_NAME": "AltPayee NISC-Vendor"
            },
            {
              "VENDOR_NAME": "AltPayee Vendor"
            },
            {
              "VENDOR_NAME": "AltPayeeNISC Inc"
            },
            {
              "VENDOR_NAME": "ALTWELL"
            },
            {
              "VENDOR_NAME": "ALUMA SHIELD INDUSTRIES"
            },
            {
              "VENDOR_NAME": "ALUMIGRAPH INTERIOR SIGN SYSTEM"
            },
            {
              "VENDOR_NAME": "ALVA HOLCOMBE"
            },
            {
              "VENDOR_NAME": "ALVAREZ & MARSAL"
            },
            {
              "VENDOR_NAME": "ALYCEE R. NELSON RULEY"
            },
            {
              "VENDOR_NAME": "ALZHEIMER'S ASSOCIATION"
            },
            {
              "VENDOR_NAME": "AM EXPRESS"
            },
            {
              "VENDOR_NAME": "AMAKOR"
            },
            {
              "VENDOR_NAME": "AMC DELANCEY EDISON PARTNERS"
            },
            {
              "VENDOR_NAME": "AMCOR"
            },
            {
              "VENDOR_NAME": "AMELIA COMBINED COURTS"
            },
            {
              "VENDOR_NAME": "AMELIA TURGEON"
            },
            {
              "VENDOR_NAME": "AMER SNUFF CO"
            },
            {
              "VENDOR_NAME": "AMERADA HESS CORP."
            },
            {
              "VENDOR_NAME": "AMERICA DISPLAY"
            },
            {
              "VENDOR_NAME": "AMERICAN AGIP COMPANY INC."
            },
            {
              "VENDOR_NAME": "AMERICAN ARBITRATION ASSOCIATION"
            },
            {
              "VENDOR_NAME": "AMERICAN ASSOC.OF UNIVERSITY WOMEN"
            },
            {
              "VENDOR_NAME": "AMERICAN BANK NOTE COMPANY"
            },
            {
              "VENDOR_NAME": "AMERICAN BAR & GRILL"
            },
            {
              "VENDOR_NAME": "AMERICAN BAR ASSOCIATION"
            },
            {
              "VENDOR_NAME": "AMERICAN BENEFITS COUNCIL"
            },
            {
              "VENDOR_NAME": "AMERICAN BEVERAGE CORPORATION"
            },
            {
              "VENDOR_NAME": "AMERICAN BOX & RECYCLING CORP."
            },
            {
              "VENDOR_NAME": "AMERICAN CANCER SOCIETY"
            },
            {
              "VENDOR_NAME": "AMERICAN CANCER SOCIETY REGION VIII"
            },
            {
              "VENDOR_NAME": "AMERICAN CANCER SOCIETY-RELAY FOR L"
            },
            {
              "VENDOR_NAME": "AMERICAN CASTING & MANUFACTURING CO"
            },
            {
              "VENDOR_NAME": "AMERICAN CONVEYOR CORP."
            },
            {
              "VENDOR_NAME": "AMERICAN CONVEYOR SYSTEMS"
            },
            {
              "VENDOR_NAME": "AMERICAN DIABETES ASSOCIATION"
            },
            {
              "VENDOR_NAME": "AMERICAN DISPOSAL SERVICES"
            },
            {
              "VENDOR_NAME": "AMERICAN DISPOSAL SYSTEMS"
            },
            {
              "VENDOR_NAME": "AMERICAN ENGINEERING CORP."
            },
            {
              "VENDOR_NAME": "AMERICAN FINANCE"
            },
            {
              "VENDOR_NAME": "AMERICAN FURNITURE RENTALS"
            },
            {
              "VENDOR_NAME": "AMERICAN GENERAL"
            },
            {
              "VENDOR_NAME": "AMERICAN GENERAL FINANCE"
            },
            {
              "VENDOR_NAME": "AMERICAN GEN'L FINANCIAL SERV"
            },
            {
              "VENDOR_NAME": "AMERICAN HEART ASSOC."
            },
            {
              "VENDOR_NAME": "AMERICAN HEART ASSOCIATION"
            },
            {
              "VENDOR_NAME": "AMERICAN HONDA FINANCE"
            },
            {
              "VENDOR_NAME": "AMERICAN INSTITUTE OF BAKING"
            },
            {
              "VENDOR_NAME": "AMERICAN JEWISH COMMITTEE"
            },
            {
              "VENDOR_NAME": "AMERICAN LEAK DETECTION"
            },
            {
              "VENDOR_NAME": "AMERICAN LICORICE COMPANY"
            },
            {
              "VENDOR_NAME": "AMERICAN LODGE & SUITES"
            },
            {
              "VENDOR_NAME": "AMERICAN LUNG ASSOC.OF"
            },
            {
              "VENDOR_NAME": "AMERICAN MANAGEMENT ASSOC"
            },
            {
              "VENDOR_NAME": "AMERICAN MARKETING CO."
            },
            {
              "VENDOR_NAME": "AMERICAN MULTI-CINEMA INC."
            },
            {
              "VENDOR_NAME": "AMERICAN PAYROLL ASSOC"
            },
            {
              "VENDOR_NAME": "AMERICAN RECYCLED PLASTIC"
            },
            {
              "VENDOR_NAME": "AMERICAN RED CROSS"
            },
            {
              "VENDOR_NAME": "AMERICAN RED CROSS RED BALL 2006"
            },
            {
              "VENDOR_NAME": "AMERICAN RED CROSS-CENTRAL MARYLAND"
            },
            {
              "VENDOR_NAME": "AMERICAN SHEET METAL WORKS"
            },
            {
              "VENDOR_NAME": "AMERICAN SPEAKER"
            },
            {
              "VENDOR_NAME": "AMERICAN SPECIALTY SYSTEMS"
            },
            {
              "VENDOR_NAME": "AMERICAN SPIRIT F.C.U."
            },
            {
              "VENDOR_NAME": "AMERICAN TANK CLEANING"
            },
            {
              "VENDOR_NAME": "AMERICAN TRUCKING ASSOC."
            },
            {
              "VENDOR_NAME": "AMERICAN ULTRAVIOLET COMPANY"
            },
            {
              "VENDOR_NAME": "AMERICAN VALUATION GROUP"
            },
            {
              "VENDOR_NAME": "AMERICAS' SAP USERS' GROUP"
            },
            {
              "VENDOR_NAME": "AMERICREDIT FINANCIAL SERVICES"
            },
            {
              "VENDOR_NAME": "AMERIDRILL"
            },
            {
              "VENDOR_NAME": "AMERIGAS-MALVERN"
            },
            {
              "VENDOR_NAME": "AMERIHEALTH ADMINISTRATORS"
            },
            {
              "VENDOR_NAME": "AMERIHEALTH"
            },
            {
              "VENDOR_NAME": "AMERI-KLEAN INC"
            },
            {
              "VENDOR_NAME": "AMERIQUEST TRANSPORTATION"
            },
            {
              "VENDOR_NAME": "AMERISAN"
            },
            {
              "VENDOR_NAME": "AMERISUITES RIVERS BEND"
            },
            {
              "VENDOR_NAME": "AMF CONCHESTER LANES"
            },
            {
              "VENDOR_NAME": "AMITY TOWNSHIP LOCAL TAX"
            },
            {
              "VENDOR_NAME": "AMOCO"
            },
            {
              "VENDOR_NAME": "Amoco/BP Products"
            },
            {
              "VENDOR_NAME": "AMOROSO BAKING CO"
            },
            {
              "VENDOR_NAME": "AMOROSO BAKING COMPANY"
            },
            {
              "VENDOR_NAME": "AMOROSO'S BAKING CORPORATION"
            },
            {
              "VENDOR_NAME": "AMP BUSINESS INTERIOR INC"
            },
            {
              "VENDOR_NAME": "AMPHIRE SOLUTIONS"
            },
            {
              "VENDOR_NAME": "AMPS"
            },
            {
              "VENDOR_NAME": "AMR RESEARCH"
            },
            {
              "VENDOR_NAME": "AMSPEC SERVICES"
            },
            {
              "VENDOR_NAME": "AMY G. LANZA"
            },
            {
              "VENDOR_NAME": "AMYRIS FUELS"
            },
            {
              "VENDOR_NAME": "AMYRIS FUELS"
            },
            {
              "VENDOR_NAME": "ANABEL McKAY WILBANKS"
            },
            {
              "VENDOR_NAME": "ANALYTICAL LABORATORY SERVICES"
            },
            {
              "VENDOR_NAME": "ANAPOL"
            },
            {
              "VENDOR_NAME": "ANAPOL"
            },
            {
              "VENDOR_NAME": "ANAPOL"
            },
            {
              "VENDOR_NAME": "ANCHOR CONCRETE PRODUCTS"
            },
            {
              "VENDOR_NAME": "ANCHOR PACKAGING"
            },
            {
              "VENDOR_NAME": "ANDERSON INSTRUMENT CO"
            },
            {
              "VENDOR_NAME": "ANDERSON KNIGHT"
            },
            {
              "VENDOR_NAME": "ANDREA KAURIGA"
            },
            {
              "VENDOR_NAME": "ANDREW ALLEGRETO"
            },
            {
              "VENDOR_NAME": "ANDREW ALLEGRETTO"
            },
            {
              "VENDOR_NAME": "ANDREW ALLEGRETTO COURT"
            },
            {
              "VENDOR_NAME": "ANDREW ALLEGRETTO COURT OFFICER"
            },
            {
              "VENDOR_NAME": "ANDREW ALLEGRETTO CT OFFICER"
            },
            {
              "VENDOR_NAME": "ANDREW ALLEGRETTO CT. OFFICER"
            },
            {
              "VENDOR_NAME": "ANDREW ALLEGRETTO CT.OFFICER"
            },
            {
              "VENDOR_NAME": "ANDREW ALLEGRETTO SUP.COURT OFFICER"
            },
            {
              "VENDOR_NAME": "ANDREW ALLEGRETTO(DC00142105)"
            },
            {
              "VENDOR_NAME": "ANDREW ALLEGRETTO"
            },
            {
              "VENDOR_NAME": "ANDREW ALLEGRETTO"
            },
            {
              "VENDOR_NAME": "ANDREW DORLEY"
            },
            {
              "VENDOR_NAME": "ANDREW DUNN"
            },
            {
              "VENDOR_NAME": "ANDREW GOODMAN"
            },
            {
              "VENDOR_NAME": "ANDREW HAY"
            },
            {
              "VENDOR_NAME": "ANDREW HOLICK"
            },
            {
              "VENDOR_NAME": "ANDREW J. GOLDSCHMIDT"
            },
            {
              "VENDOR_NAME": "ANDREW J. KELLEHER"
            },
            {
              "VENDOR_NAME": "ANDREW KELLEHER"
            },
            {
              "VENDOR_NAME": "ANDREW P. YURCHUCK LLC"
            },
            {
              "VENDOR_NAME": "ANDREW W.HAROBIN"
            },
            {
              "VENDOR_NAME": "ANDY HOMKA"
            },
            {
              "VENDOR_NAME": "ANGEL L. MELECIO"
            },
            {
              "VENDOR_NAME": "ANGELA DOANE"
            },
            {
              "VENDOR_NAME": "ANGELA EPTING"
            },
            {
              "VENDOR_NAME": "ANGELA SIMMS"
            },
            {
              "VENDOR_NAME": "ANGOLA CREST II LLC"
            },
            {
              "VENDOR_NAME": "ANIART REMODELING"
            },
            {
              "VENDOR_NAME": "ANIXTER"
            },
            {
              "VENDOR_NAME": "ANJER TRAILER AND TRUCK"
            },
            {
              "VENDOR_NAME": "ANN MARIE MOORE"
            },
            {
              "VENDOR_NAME": "ANN MARIE'S"
            },
            {
              "VENDOR_NAME": "ANN SZELLER"
            },
            {
              "VENDOR_NAME": "ANNA & ANNA P.C."
            },
            {
              "VENDOR_NAME": "ANNA FIDDLER"
            },
            {
              "VENDOR_NAME": "ANNA KELLY"
            },
            {
              "VENDOR_NAME": "ANNALYSA LOVOS"
            },
            {
              "VENDOR_NAME": "ANNALYSA LOVOS"
            },
            {
              "VENDOR_NAME": "ANNAPOLIS CARS"
            },
            {
              "VENDOR_NAME": "ANNE ARUNDEL CO HEALTH DP"
            },
            {
              "VENDOR_NAME": "ANNE ARUNDEL COUNTY"
            },
            {
              "VENDOR_NAME": "ANNE ARUNDEL COUNTY FARP"
            },
            {
              "VENDOR_NAME": "ANNE HALL"
            },
            {
              "VENDOR_NAME": "ANNE M. MURRAY"
            },
            {
              "VENDOR_NAME": "ANNE M.MURRAY"
            },
            {
              "VENDOR_NAME": "ANNE MARIE MURRAY"
            },
            {
              "VENDOR_NAME": "ANNE SKERSKI"
            },
            {
              "VENDOR_NAME": "ANNE SPAGNOLA"
            },
            {
              "VENDOR_NAME": "ANNUAL CALL CENTER EXHIBITION"
            },
            {
              "VENDOR_NAME": "ANRO INC"
            },
            {
              "VENDOR_NAME": "ANSELL HEALTHCARE"
            },
            {
              "VENDOR_NAME": "ANSELL ZARO GRIMM & AARON"
            },
            {
              "VENDOR_NAME": "ANTENNA PLUS"
            },
            {
              "VENDOR_NAME": "ANTHONY A & MARY M CHICO"
            },
            {
              "VENDOR_NAME": "ANTHONY BIZZARO"
            },
            {
              "VENDOR_NAME": "ANTHONY FAVORITO"
            },
            {
              "VENDOR_NAME": "ANTHONY FAVORITO COURT OFFICER"
            },
            {
              "VENDOR_NAME": "ANTHONY FAVORITO CT. OFFICER"
            },
            {
              "VENDOR_NAME": "ANTHONY INTERNATIONAL"
            },
            {
              "VENDOR_NAME": "ANTHONY J.AND JUDITH A.ANGONA"
            },
            {
              "VENDOR_NAME": "ANTHONY LOMBARD & SUSAN LOMBARD"
            },
            {
              "VENDOR_NAME": "ANTHONY MORELLI"
            },
            {
              "VENDOR_NAME": "ANTHONY R.BAILEY"
            },
            {
              "VENDOR_NAME": "ANTHONY TSAGANOS"
            },
            {
              "VENDOR_NAME": "ANTIQUE LANE HOLDINGS"
            },
            {
              "VENDOR_NAME": "ANTOINETTE BOCCUTTO"
            },
            {
              "VENDOR_NAME": "ANTOINETTE CLAY-HALL"
            },
            {
              "VENDOR_NAME": "ANTONIO & GIVALDA CONENNA"
            },
            {
              "VENDOR_NAME": "ANZA INC."
            },
            {
              "VENDOR_NAME": "AON"
            },
            {
              "VENDOR_NAME": "AON BERMUDA"
            },
            {
              "VENDOR_NAME": "AON CONSULTING"
            },
            {
              "VENDOR_NAME": "AON PRIVATE RISK MANAGEMENT"
            },
            {
              "VENDOR_NAME": "AON RISK SERVICES CENTRAL INC"
            },
            {
              "VENDOR_NAME": "AON SELECT"
            },
            {
              "VENDOR_NAME": "APEX FINANCIAL SEARCH"
            },
            {
              "VENDOR_NAME": "APEX OIL COMPANY"
            },
            {
              "VENDOR_NAME": "APEX RESOURCE GROUP"
            },
            {
              "VENDOR_NAME": "APEX SYSTEMS"
            },
            {
              "VENDOR_NAME": "APEX. OIL COMPANY"
            },
            {
              "VENDOR_NAME": "API FUND FOR PAYROLL EDUCATION"
            },
            {
              "VENDOR_NAME": "APICS"
            },
            {
              "VENDOR_NAME": "APOLLO GYMNASTICS"
            },
            {
              "VENDOR_NAME": "APPLE AUTO SALES"
            },
            {
              "VENDOR_NAME": "APPLE INC."
            },
            {
              "VENDOR_NAME": "APPLE VACATIONS EAST"
            },
            {
              "VENDOR_NAME": "APPLEBEES"
            },
            {
              "VENDOR_NAME": "APPLICATION SECURITY"
            },
            {
              "VENDOR_NAME": "APPLIED CONTROLS INC"
            },
            {
              "VENDOR_NAME": "APPLIED DATA SOLUTIONS"
            },
            {
              "VENDOR_NAME": "APPLIED INDUSTRIAL TECH."
            },
            {
              "VENDOR_NAME": "APPLIED MERCHANDISING CONCEPTS"
            },
            {
              "VENDOR_NAME": "APPLIED PREDICTIVE"
            },
            {
              "VENDOR_NAME": "APPLIED WATER MANAGEMENT GROUP"
            },
            {
              "VENDOR_NAME": "APPOMATTOX WETLAND"
            },
            {
              "VENDOR_NAME": "APPRAISAL SOURCE"
            },
            {
              "VENDOR_NAME": "APPROVED CASH ADVANCE"
            },
            {
              "VENDOR_NAME": "APPROVED CASH ADVANCE 615"
            },
            {
              "VENDOR_NAME": "APRIL DeFELICE"
            },
            {
              "VENDOR_NAME": "APRIL FERRANTE"
            },
            {
              "VENDOR_NAME": "APRIL PRICE TRACY"
            },
            {
              "VENDOR_NAME": "APRIL WILBUR"
            },
            {
              "VENDOR_NAME": "APRISMA MANAGEMENT TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "APS GROUP INC."
            },
            {
              "VENDOR_NAME": "APTCOR MANAGEMENT"
            },
            {
              "VENDOR_NAME": "AQUA EXPERTS"
            },
            {
              "VENDOR_NAME": "AQUA NEW JERSEY"
            },
            {
              "VENDOR_NAME": "AQUA PENNSYLVANIA"
            },
            {
              "VENDOR_NAME": "AQUA PENNSYLVANIA"
            },
            {
              "VENDOR_NAME": "AQUA WASTEWATER MANAGEMENT"
            },
            {
              "VENDOR_NAME": "AQUATEC"
            },
            {
              "VENDOR_NAME": "AQUATERRA TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "AQUENT"
            },
            {
              "VENDOR_NAME": "AR PAYMENTALTADIS"
            },
            {
              "VENDOR_NAME": "ARAMARK"
            },
            {
              "VENDOR_NAME": "ARAMARK at CITIZENS BANK PARK"
            },
            {
              "VENDOR_NAME": "ARAMARK at ST.JOSEPH'S UNIVERSITY"
            },
            {
              "VENDOR_NAME": "ARAMARK CORPORATION"
            },
            {
              "VENDOR_NAME": "ARAMARK SPORTS AND ENTERTAINMENT S"
            },
            {
              "VENDOR_NAME": "ARAMARK UNIFORM SERV. INC"
            },
            {
              "VENDOR_NAME": "ARAMARK UNIFORM SERVICES"
            },
            {
              "VENDOR_NAME": "ARAMARK WORK APPAREL & UNIFORM SERV"
            },
            {
              "VENDOR_NAME": "ARAMARK@ YOUTH INTERVENTION CENTER"
            },
            {
              "VENDOR_NAME": "ARBITRON"
            },
            {
              "VENDOR_NAME": "ARBOR LAKES"
            },
            {
              "VENDOR_NAME": "ARBOR MATERIAL HANDLING"
            },
            {
              "VENDOR_NAME": "ARBOR POINTE APARTMENTS"
            },
            {
              "VENDOR_NAME": "ARBORETUM GARDENS"
            },
            {
              "VENDOR_NAME": "ARBRISCO ENTERPRISES"
            },
            {
              "VENDOR_NAME": "ARC MAINTENANCE INC"
            },
            {
              "VENDOR_NAME": "ARC TERMINALS HOLDINGS LLC"
            },
            {
              "VENDOR_NAME": "ARCADIA PUBLISHING"
            },
            {
              "VENDOR_NAME": "ARCH ELECTRONICS"
            },
            {
              "VENDOR_NAME": "ARCH INSURANCE COMPANY"
            },
            {
              "VENDOR_NAME": "ARCH WIRELESS"
            },
            {
              "VENDOR_NAME": "ARCHBISHOP RYAN HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "ARCHBISHOP SPALDING HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "ARCHER AND GREINER"
            },
            {
              "VENDOR_NAME": "ARCHER DANIELS MIDLAND CO."
            },
            {
              "VENDOR_NAME": "ARCHITECTURAL HARDWARE CO."
            },
            {
              "VENDOR_NAME": "ARCHSCAN"
            },
            {
              "VENDOR_NAME": "ARCSIGHT"
            },
            {
              "VENDOR_NAME": "ARCTIC GLACIER"
            },
            {
              "VENDOR_NAME": "ARDETH E.BLACK"
            },
            {
              "VENDOR_NAME": "ARDMORE TIRE INC"
            },
            {
              "VENDOR_NAME": "ARGUS MEDIA"
            },
            {
              "VENDOR_NAME": "ARID TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "ARIEL GROUP"
            },
            {
              "VENDOR_NAME": "ARKANSAS CHILD SUPPORT CLEARHOUSING"
            },
            {
              "VENDOR_NAME": "ARLENE GRIERSON"
            },
            {
              "VENDOR_NAME": "ARLENE KLOTZ"
            },
            {
              "VENDOR_NAME": "ARLENE R. TOMPKINS"
            },
            {
              "VENDOR_NAME": "ARLENE ROSE"
            },
            {
              "VENDOR_NAME": "ARLENE SCALA"
            },
            {
              "VENDOR_NAME": "ARLINGTON GEN'L DIST COURT"
            },
            {
              "VENDOR_NAME": "ARLINGTON GEN'L DIST CT"
            },
            {
              "VENDOR_NAME": "ARMADA HOFFLER CONSTRUCTION CO."
            },
            {
              "VENDOR_NAME": "ARMAND A.BRUNO"
            },
            {
              "VENDOR_NAME": "ARMOUR ECKRICH MEATS"
            },
            {
              "VENDOR_NAME": "ARMSTRONG SPRINGS"
            },
            {
              "VENDOR_NAME": "ARNOLD GLABERSON"
            },
            {
              "VENDOR_NAME": "ARNOLD POLIKOFF REV.TRUST"
            },
            {
              "VENDOR_NAME": "ARNOLD POLIKOFF REVOCABLE TRUST"
            },
            {
              "VENDOR_NAME": "ARPAC L.P."
            },
            {
              "VENDOR_NAME": "ARRAY"
            },
            {
              "VENDOR_NAME": "ARRO ENVIROMENTAL SERVICES"
            },
            {
              "VENDOR_NAME": "ARROW FINANCIAL SERVICES"
            },
            {
              "VENDOR_NAME": "ART APELIAN"
            },
            {
              "VENDOR_NAME": "ART M & RUTH F SHERRILL"
            },
            {
              "VENDOR_NAME": "ART PRESS PRINTING"
            },
            {
              "VENDOR_NAME": "ARTCRAFT PINEDALE"
            },
            {
              "VENDOR_NAME": "ARTCRAFT-PINEDALE"
            },
            {
              "VENDOR_NAME": "ARTESIAN WATER CO"
            },
            {
              "VENDOR_NAME": "ARTHUR BLANK & CO"
            },
            {
              "VENDOR_NAME": "ARTHUR JACOBUS"
            },
            {
              "VENDOR_NAME": "ARTHUR McNERNEY"
            },
            {
              "VENDOR_NAME": "ASA DONLEY"
            },
            {
              "VENDOR_NAME": "ASA GREATER PHILA.CHAPTER"
            },
            {
              "VENDOR_NAME": "ASBURY PARK PRESS"
            },
            {
              "VENDOR_NAME": "ASCENTIAL SOFTWARE"
            },
            {
              "VENDOR_NAME": "ASENKA CREATIVE SERVICES"
            },
            {
              "VENDOR_NAME": "ASHER'S CHOCOLATES"
            },
            {
              "VENDOR_NAME": "ASHPA MANAGEMENT"
            },
            {
              "VENDOR_NAME": "ASI FOOD SAFETY CONSULTANTS"
            },
            {
              "VENDOR_NAME": "ASIS INTERNATIONAL"
            },
            {
              "VENDOR_NAME": "ASPEN PUBLISHERS INC"
            },
            {
              "VENDOR_NAME": "ASPEN PUBLISHERS"
            },
            {
              "VENDOR_NAME": "ASPHALT SERVICES"
            },
            {
              "VENDOR_NAME": "ASPHALT SOLUTIONS"
            },
            {
              "VENDOR_NAME": "ASPIRE FINANCIAL SEARCH"
            },
            {
              "VENDOR_NAME": "ASSELTA"
            },
            {
              "VENDOR_NAME": "ASSET ACCEPT"
            },
            {
              "VENDOR_NAME": "ASSET ACCEPTANCE"
            },
            {
              "VENDOR_NAME": "ASSET ACCEPTANCE LLC"
            },
            {
              "VENDOR_NAME": "ASSET ACCEPTANCE"
            },
            {
              "VENDOR_NAME": "ASSET ACCEPTANCE"
            },
            {
              "VENDOR_NAME": "ASSET MANAGEMENT CONSULTANTS"
            },
            {
              "VENDOR_NAME": "ASSET RECOVERY"
            },
            {
              "VENDOR_NAME": "ASSET RECOVERY GROUP"
            },
            {
              "VENDOR_NAME": "ASSETS ACCEPTANCE LLC"
            },
            {
              "VENDOR_NAME": "ASSOC.OF CERTIFIED FRAUD EXAMINERS"
            },
            {
              "VENDOR_NAME": "ASSOCIATED BUILDING"
            },
            {
              "VENDOR_NAME": "ASSOCIATED BUILDING SPECIALTIES"
            },
            {
              "VENDOR_NAME": "ASSOCIATED DISTIBUTORS"
            },
            {
              "VENDOR_NAME": "ASSOCIATED DISTR"
            },
            {
              "VENDOR_NAME": "ASSOCIATED WHOLESALERS INC."
            },
            {
              "VENDOR_NAME": "ASSOCIATION FOR FINANCIAL"
            },
            {
              "VENDOR_NAME": "ASSOCIATION OF CORPORATE COUNCIL"
            },
            {
              "VENDOR_NAME": "ASSOCIATION OF CORPORATE COUNSEL"
            },
            {
              "VENDOR_NAME": "ASSUMPTION BVM"
            },
            {
              "VENDOR_NAME": "ASSURANCE MEDIA LLC"
            },
            {
              "VENDOR_NAME": "ASTD"
            },
            {
              "VENDOR_NAME": "ASTON AUTO TAGS"
            },
            {
              "VENDOR_NAME": "ASTON GULF"
            },
            {
              "VENDOR_NAME": "ASTON TOWNSHIP"
            },
            {
              "VENDOR_NAME": "ASTON TOWNSHIP WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "ASTON TWP LIONS CHARITABLE"
            },
            {
              "VENDOR_NAME": "ASTOR CHOCOLATE"
            },
            {
              "VENDOR_NAME": "ASTRA OIL COMPANY"
            },
            {
              "VENDOR_NAME": "ASTRA OIL COMPANY"
            },
            {
              "VENDOR_NAME": "AT & T"
            },
            {
              "VENDOR_NAME": "AT&T"
            },
            {
              "VENDOR_NAME": "AT&T CAPITAL SERVICES"
            },
            {
              "VENDOR_NAME": "ATA SERVICES"
            },
            {
              "VENDOR_NAME": "ATC ASSOCIATES"
            },
            {
              "VENDOR_NAME": "ATD AMERICAN CO."
            },
            {
              "VENDOR_NAME": "ATHENIAN PROPERTIES LLC"
            },
            {
              "VENDOR_NAME": "ATHENIAN PROPERTIES LLC AS RECEIVER"
            },
            {
              "VENDOR_NAME": "ATHLETIC WORLD ADVERTISING"
            },
            {
              "VENDOR_NAME": "ATKINS NUTRITIONALS"
            },
            {
              "VENDOR_NAME": "ATKINS NUTRITIONALS INC"
            },
            {
              "VENDOR_NAME": "ATKINSON & MULLEN TRAVEL"
            },
            {
              "VENDOR_NAME": "ATLANTA LOCKBOX"
            },
            {
              "VENDOR_NAME": "ATLANTA NATIONAL LEAGUE"
            },
            {
              "VENDOR_NAME": "ATLANTIC ANESHESIA"
            },
            {
              "VENDOR_NAME": "ATLANTIC ANESTHESIA"
            },
            {
              "VENDOR_NAME": "ATLANTIC ANESTHESIA INC"
            },
            {
              "VENDOR_NAME": "ATLANTIC ANESTHESIA"
            },
            {
              "VENDOR_NAME": "ATLANTIC ANESTHESIA"
            },
            {
              "VENDOR_NAME": "ATLANTIC CAPE MAY CAREER CENTERS"
            },
            {
              "VENDOR_NAME": "ATLANTIC CITY ELECTRIC"
            },
            {
              "VENDOR_NAME": "ATLANTIC CITY HILTON"
            },
            {
              "VENDOR_NAME": "ATLANTIC CITY PRESS"
            },
            {
              "VENDOR_NAME": "ATLANTIC CITY REGIONAL MAINLAND"
            },
            {
              "VENDOR_NAME": "ATLANTIC CITY RESCUE MISSION"
            },
            {
              "VENDOR_NAME": "ATLANTIC CITY SURF"
            },
            {
              "VENDOR_NAME": "ATLANTIC CO. SHERIFF"
            },
            {
              "VENDOR_NAME": "ATLANTIC COMMUNICATIONS"
            },
            {
              "VENDOR_NAME": "ATLANTIC CONTRACTORS"
            },
            {
              "VENDOR_NAME": "ATLANTIC COUNTY CLERK'S OFFICE"
            },
            {
              "VENDOR_NAME": "ATLANTIC COUNTY DEPARTMENT OF"
            },
            {
              "VENDOR_NAME": "ATLANTIC COUNTY SHERIFF"
            },
            {
              "VENDOR_NAME": "ATLANTIC COUNTY UTILITY AUTHORITY"
            },
            {
              "VENDOR_NAME": "ATLANTIC CTY SHERIFF"
            },
            {
              "VENDOR_NAME": "ATLANTIC DENTAL ASSOC"
            },
            {
              "VENDOR_NAME": "ATLANTIC GEN'L HOSPITAL"
            },
            {
              "VENDOR_NAME": "ATLANTIC LIGHTING & SIGN"
            },
            {
              "VENDOR_NAME": "ATLANTIC LOOSE LEAF CO"
            },
            {
              "VENDOR_NAME": "ATLANTIC REFINISHING & RESTORATION"
            },
            {
              "VENDOR_NAME": "ATLANTIC RESOURCES"
            },
            {
              "VENDOR_NAME": "ATLANTIC SNOW PLOWING SERVICES"
            },
            {
              "VENDOR_NAME": "ATLANTIC TRADING MKT"
            },
            {
              "VENDOR_NAME": "ATLANTIC TRAFFIC & DESIGN ENGINEERS"
            },
            {
              "VENDOR_NAME": "ATLANTIC UNITED SOCCER CLUB"
            },
            {
              "VENDOR_NAME": "ATLANTICARE FOUNDATION"
            },
            {
              "VENDOR_NAME": "ATLANTICARE HOSPICE"
            },
            {
              "VENDOR_NAME": "ATLAS FLASHER & SUPPLY CO."
            },
            {
              "VENDOR_NAME": "ATLAS METAL INDUST. INC."
            },
            {
              "VENDOR_NAME": "ATOMIC PLUMBING"
            },
            {
              "VENDOR_NAME": "ATRION CORP."
            },
            {
              "VENDOR_NAME": "ATS ENVIRONMENTAL SERVICES LLC"
            },
            {
              "VENDOR_NAME": "ATTASK"
            },
            {
              "VENDOR_NAME": "ATTENTION FUNDING & TRUST"
            },
            {
              "VENDOR_NAME": "ATTN: SOFTWARE"
            },
            {
              "VENDOR_NAME": "ATTORNEY REGISTRATION OFFICE"
            },
            {
              "VENDOR_NAME": "ATWELL LLC"
            },
            {
              "VENDOR_NAME": "AUDIO-TECH BUSINESS BOOKS"
            },
            {
              "VENDOR_NAME": "AUDIO-TO-GO INC."
            },
            {
              "VENDOR_NAME": "AUDUBON HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "AUDUBON LAND DEVELOPMENT CORP."
            },
            {
              "VENDOR_NAME": "AUDUBON MANAGEMENT CONSULTANTS"
            },
            {
              "VENDOR_NAME": "AUDUBON NEWS AGENCY"
            },
            {
              "VENDOR_NAME": "AUDUBON SQUARE"
            },
            {
              "VENDOR_NAME": "AUDUBON WATER CO."
            },
            {
              "VENDOR_NAME": "AUGUSTA DAIRIES"
            },
            {
              "VENDOR_NAME": "AUGUSTINE DURSO"
            },
            {
              "VENDOR_NAME": "AULETTO CATERERS"
            },
            {
              "VENDOR_NAME": "AURORA INDUSTRIES"
            },
            {
              "VENDOR_NAME": "AURORA WORLD INC."
            },
            {
              "VENDOR_NAME": "AU'SOME SNACK NATURALLY INC"
            },
            {
              "VENDOR_NAME": "AUSTIN JAMES ASSOC."
            },
            {
              "VENDOR_NAME": "AUTISM SPEAKS"
            },
            {
              "VENDOR_NAME": "AUTO CREDIT"
            },
            {
              "VENDOR_NAME": "AUTO CREDIT C/O NORFOLK COURT #3"
            },
            {
              "VENDOR_NAME": "AUTO CREDIT LINE"
            },
            {
              "VENDOR_NAME": "AUTO CREDIT OF VA"
            },
            {
              "VENDOR_NAME": "AUTO FINANCE CO"
            },
            {
              "VENDOR_NAME": "AUTO LOCATOR"
            },
            {
              "VENDOR_NAME": "AUTO TRADER"
            },
            {
              "VENDOR_NAME": "AUTOMATED PRODUCTION"
            },
            {
              "VENDOR_NAME": "AUTOMATED WAGERING INC."
            },
            {
              "VENDOR_NAME": "AUTOMATIC RAIN CORP"
            },
            {
              "VENDOR_NAME": "AUTOMATION AIDES"
            },
            {
              "VENDOR_NAME": "AUTOMOTIVE RENTALS INC"
            },
            {
              "VENDOR_NAME": "AUTOMOTIVE SERVICE"
            },
            {
              "VENDOR_NAME": "AUTUMN PEACOCK"
            },
            {
              "VENDOR_NAME": "AVALON YACHT CLUB"
            },
            {
              "VENDOR_NAME": "AVAYA FINANCIAL SERVICES"
            },
            {
              "VENDOR_NAME": "AVAYA INC."
            },
            {
              "VENDOR_NAME": "AVENTINE RENEWABLE ENERGY"
            },
            {
              "VENDOR_NAME": "AVERETT UNIVERSITY"
            },
            {
              "VENDOR_NAME": "AVERY DENNISON RETAIL"
            },
            {
              "VENDOR_NAME": "AVON GROVE SCHOOL DISTRICT"
            },
            {
              "VENDOR_NAME": "AVON WALK FOR BREAST CANCER"
            },
            {
              "VENDOR_NAME": "AVONDALE BOROUGH"
            },
            {
              "VENDOR_NAME": "AVONDALE WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "AW DIRECT"
            },
            {
              "VENDOR_NAME": "AWARD PROS OF PRINCETON"
            },
            {
              "VENDOR_NAME": "AWG DEPT.GEN'L REV CORP"
            },
            {
              "VENDOR_NAME": "AWG DEPT.GEN'L REV.CORP"
            },
            {
              "VENDOR_NAME": "AWI ASSOCIATED WHOLESALERS INC"
            },
            {
              "VENDOR_NAME": "AXIAL ADVISORY GROUP"
            },
            {
              "VENDOR_NAME": "AXIS ENTERPRISES INC."
            },
            {
              "VENDOR_NAME": "AXZO PRESS LLC"
            },
            {
              "VENDOR_NAME": "AZALEA DRIVE"
            },
            {
              "VENDOR_NAME": "B & D SALES"
            },
            {
              "VENDOR_NAME": "B & F RACING"
            },
            {
              "VENDOR_NAME": "B & F TOWING"
            },
            {
              "VENDOR_NAME": "B & R INDUSTRIAL AUTOMATION CORP."
            },
            {
              "VENDOR_NAME": "B & T SALES COMPANY"
            },
            {
              "VENDOR_NAME": "B B M U"
            },
            {
              "VENDOR_NAME": "B&T ENGINEERING"
            },
            {
              "VENDOR_NAME": "B.L.D."
            },
            {
              "VENDOR_NAME": "B.R.R.ASSOCIATES"
            },
            {
              "VENDOR_NAME": "B2B WORKFORCE"
            },
            {
              "VENDOR_NAME": "B2B WORKFORCE"
            },
            {
              "VENDOR_NAME": "BA MERCHANT SERVICES"
            },
            {
              "VENDOR_NAME": "BACK ON MY FEET"
            },
            {
              "VENDOR_NAME": "BAGCRAFT PACKAGING"
            },
            {
              "VENDOR_NAME": "BAGCRAFT/PAPERCON"
            },
            {
              "VENDOR_NAME": "BAGMASTERS"
            },
            {
              "VENDOR_NAME": "BAILEY AND SON"
            },
            {
              "VENDOR_NAME": "BAIRD PETROLEUM EQUIPMENT"
            },
            {
              "VENDOR_NAME": "BAKE RITE ROLLS"
            },
            {
              "VENDOR_NAME": "BAKER PRINTING"
            },
            {
              "VENDOR_NAME": "BAKER SOUND STUDIOS"
            },
            {
              "VENDOR_NAME": "BALA CONSULTING"
            },
            {
              "VENDOR_NAME": "BALANCE BAR COMPANY"
            },
            {
              "VENDOR_NAME": "BALDI MIDDLE SCHOOL"
            },
            {
              "VENDOR_NAME": "BALDUS REAL ESTATE"
            },
            {
              "VENDOR_NAME": "BALL"
            },
            {
              "VENDOR_NAME": "BALLANTINE LIFT EQUIPMENT"
            },
            {
              "VENDOR_NAME": "BALLARD SPAHR ANDREWS & INGERSOLL"
            },
            {
              "VENDOR_NAME": "BALLARD SPAHR et al"
            },
            {
              "VENDOR_NAME": "BALLARD SPAHR LLP"
            },
            {
              "VENDOR_NAME": "BALLOON CELEBRATIONS"
            },
            {
              "VENDOR_NAME": "BALT GAS & ELEC CO"
            },
            {
              "VENDOR_NAME": "BALTIMORE CITY HEALTH DEPT."
            },
            {
              "VENDOR_NAME": "BALTIMORE COUNTY MD"
            },
            {
              "VENDOR_NAME": "BALTIMORE COUNTY MD COURT HOUSE"
            },
            {
              "VENDOR_NAME": "BALTIMORE COUNTY OFFICE BLDG MD"
            },
            {
              "VENDOR_NAME": "BALTIMORE STORAGE COMPANY"
            },
            {
              "VENDOR_NAME": "BALTIMORE SUN"
            },
            {
              "VENDOR_NAME": "BALTIMORE SUN OUTER MARKET"
            },
            {
              "VENDOR_NAME": "BALTIMORE TANK LINES"
            },
            {
              "VENDOR_NAME": "BALTIMORE WASHINGTON MED CTR"
            },
            {
              "VENDOR_NAME": "BALTIMORE WASHINGTON MEDICAL CENTER"
            },
            {
              "VENDOR_NAME": "BALZER AND ASSOC.INC"
            },
            {
              "VENDOR_NAME": "BANBURY SHOPPES"
            },
            {
              "VENDOR_NAME": "BANC OF AMERICA SECURITIES LLC"
            },
            {
              "VENDOR_NAME": "BANCROFT CONSTRUCTION CO."
            },
            {
              "VENDOR_NAME": "BANCROFT HALL APTS"
            },
            {
              "VENDOR_NAME": "BANCROFT NEUROHEALTH"
            },
            {
              "VENDOR_NAME": "BANK OF AMERICA"
            },
            {
              "VENDOR_NAME": "BANK OF THE COMMONWEALTH"
            },
            {
              "VENDOR_NAME": "BANTLE'S BANNERS AND SIGNS"
            },
            {
              "VENDOR_NAME": "BARBARA CARRAWAY TREASURER"
            },
            {
              "VENDOR_NAME": "BARBARA CARROWAY TREASURER"
            },
            {
              "VENDOR_NAME": "BARBARA DUPERREAULT"
            },
            {
              "VENDOR_NAME": "BARBARA DUPERREAULT MARKET RESEARCH"
            },
            {
              "VENDOR_NAME": "BARBARA ENNIS"
            },
            {
              "VENDOR_NAME": "BARBARA HIGGINS"
            },
            {
              "VENDOR_NAME": "BARBARA LOFTUS TAX COLL."
            },
            {
              "VENDOR_NAME": "BARBARA M. JORDON"
            },
            {
              "VENDOR_NAME": "BARBARA M.JORDAN"
            },
            {
              "VENDOR_NAME": "BARBARA RABSON"
            },
            {
              "VENDOR_NAME": "BARBARA ROVNER"
            },
            {
              "VENDOR_NAME": "BARBARA V CHAMBERS"
            },
            {
              "VENDOR_NAME": "BARBIZON MODELING AGENCY"
            },
            {
              "VENDOR_NAME": "Barcel USA"
            },
            {
              "VENDOR_NAME": "BARCLAY FRIENDS GOLF CLASSIC"
            },
            {
              "VENDOR_NAME": "BARCO PRODUCTS COMPANY"
            },
            {
              "VENDOR_NAME": "BARKER & BARKER CORP."
            },
            {
              "VENDOR_NAME": "BARKER SCOTT & GELFAND"
            },
            {
              "VENDOR_NAME": "BARLEY"
            },
            {
              "VENDOR_NAME": "BARNEGAT FIRE CO"
            },
            {
              "VENDOR_NAME": "BARNEGAT TOWNSHIP"
            },
            {
              "VENDOR_NAME": "BARNETT ROOFING AND SIDING"
            },
            {
              "VENDOR_NAME": "BARNEY P. BOLT"
            },
            {
              "VENDOR_NAME": "BARNSTEAD INTERNATIONAL"
            },
            {
              "VENDOR_NAME": "BARRIE HOUSE COFFEE CO"
            },
            {
              "VENDOR_NAME": "BARRINGTON DOOR INC"
            },
            {
              "VENDOR_NAME": "BARRY & JANET JACKSON"
            },
            {
              "VENDOR_NAME": "BARRY & JANICE JACKSON"
            },
            {
              "VENDOR_NAME": "BARRY CRAWFORD"
            },
            {
              "VENDOR_NAME": "BARRY JAY KAPLAN"
            },
            {
              "VENDOR_NAME": "BARRY L. NEUFELD"
            },
            {
              "VENDOR_NAME": "BARRY NAUFELD COURT OFFICER"
            },
            {
              "VENDOR_NAME": "BARRY NEUFEL"
            },
            {
              "VENDOR_NAME": "BARRY NEUFELD"
            },
            {
              "VENDOR_NAME": "BARRY NEUFELD COURT OFFICER"
            },
            {
              "VENDOR_NAME": "BARRY NEUFELD COURT OFFICER (DC0095"
            },
            {
              "VENDOR_NAME": "BARRY NEUFELD"
            },
            {
              "VENDOR_NAME": "BARRY NEUFELD"
            },
            {
              "VENDOR_NAME": "BARRY NEUFELD"
            },
            {
              "VENDOR_NAME": "BARRY NEUFELD"
            },
            {
              "VENDOR_NAME": "BARRY YORK"
            },
            {
              "VENDOR_NAME": "BARTONS CONFECTIONS"
            },
            {
              "VENDOR_NAME": "BASE ENGINEERING"
            },
            {
              "VENDOR_NAME": "BASEBALL BOOSTERS"
            },
            {
              "VENDOR_NAME": "BASEBALL WEEKLY"
            },
            {
              "VENDOR_NAME": "BASKETS & BOWS"
            },
            {
              "VENDOR_NAME": "BATES TRUCKING COMPANY"
            },
            {
              "VENDOR_NAME": "BATTERIESPLUS"
            },
            {
              "VENDOR_NAME": "BAUDVILLE"
            },
            {
              "VENDOR_NAME": "BAUR ELECTRIC INC"
            },
            {
              "VENDOR_NAME": "BAUSCH & LOMB"
            },
            {
              "VENDOR_NAME": "BAY BREEZE INVESTMENT"
            },
            {
              "VENDOR_NAME": "BAY DESIGN GROUP P.C."
            },
            {
              "VENDOR_NAME": "BAY HARBOR ASSOC"
            },
            {
              "VENDOR_NAME": "BAY VALLEY FOODS"
            },
            {
              "VENDOR_NAME": "BAYER HEALTHCARE LLC"
            },
            {
              "VENDOR_NAME": "BAYHEALTH MEDICAL CTR"
            },
            {
              "VENDOR_NAME": "BAYHEALTH MEDICAL CTR."
            },
            {
              "VENDOR_NAME": "BAYLINE LIFT TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "BAYSHORE TRANSPORTATION"
            },
            {
              "VENDOR_NAME": "BAYVIEW VOLUNTEER FIRE CO. # 4"
            },
            {
              "VENDOR_NAME": "BBH SOLUTIONS"
            },
            {
              "VENDOR_NAME": "BC & G LANDSCAPING"
            },
            {
              "VENDOR_NAME": "BC EXPRESS"
            },
            {
              "VENDOR_NAME": "BCCD CLEAN WATER FUND"
            },
            {
              "VENDOR_NAME": "BCG CORPORATION"
            },
            {
              "VENDOR_NAME": "BCIT-SCHOOL"
            },
            {
              "VENDOR_NAME": "BEAMER'S & SON TRUCK REP"
            },
            {
              "VENDOR_NAME": "BEAR BROOK COMMONS ASSOC"
            },
            {
              "VENDOR_NAME": "BEATON SERVICES"
            },
            {
              "VENDOR_NAME": "BEAVER BROOK PLAZA ASSOC"
            },
            {
              "VENDOR_NAME": "BEAVER TREE & LAWN SERVICE"
            },
            {
              "VENDOR_NAME": "BEAVERBROOK MOTORS"
            },
            {
              "VENDOR_NAME": "BECKER & FRONDORF"
            },
            {
              "VENDOR_NAME": "BECKER MORGAN GROUP"
            },
            {
              "VENDOR_NAME": "BECKER STAFFING SERVICE"
            },
            {
              "VENDOR_NAME": "BECKETT GOLF CLUB"
            },
            {
              "VENDOR_NAME": "BEDC"
            },
            {
              "VENDOR_NAME": "BEGLEY"
            },
            {
              "VENDOR_NAME": "BEILER-CAMPBELL REALTORS"
            },
            {
              "VENDOR_NAME": "BEL AIR MAIN LIMITED"
            },
            {
              "VENDOR_NAME": "BELAIR MAIN LIMITED PARTNERSHIP"
            },
            {
              "VENDOR_NAME": "BELL & BELL LLP"
            },
            {
              "VENDOR_NAME": "BELLA BUCKETS"
            },
            {
              "VENDOR_NAME": "BELL-FAST FIRE PROTECTION"
            },
            {
              "VENDOR_NAME": "BEN SOLAR"
            },
            {
              "VENDOR_NAME": "BENCHMARK ANALYTICS"
            },
            {
              "VENDOR_NAME": "BENDER AND RADCLIFFE"
            },
            {
              "VENDOR_NAME": "BENDER RADCLIFFE"
            },
            {
              "VENDOR_NAME": "BENDON PUBLISHING"
            },
            {
              "VENDOR_NAME": "BENEFICAL VIRGINIA INC"
            },
            {
              "VENDOR_NAME": "BENEFICIAL MARYLAND INC."
            },
            {
              "VENDOR_NAME": "BENEFICIAL SAVINGS BANK"
            },
            {
              "VENDOR_NAME": "BENEFIT FOR ALEXA NAGLE"
            },
            {
              "VENDOR_NAME": "BENEFIT PLAN SYSTEMS"
            },
            {
              "VENDOR_NAME": "BENJAMIN CHANG"
            },
            {
              "VENDOR_NAME": "BENJAMIN F DIDONATO"
            },
            {
              "VENDOR_NAME": "BENJAMIN F du PONT"
            },
            {
              "VENDOR_NAME": "BENJAMIN KUTNER"
            },
            {
              "VENDOR_NAME": "BENNER AND WILD"
            },
            {
              "VENDOR_NAME": "BENNETT'S TRAILER COMPANY"
            },
            {
              "VENDOR_NAME": "BENSALEM TOWNSHIP"
            },
            {
              "VENDOR_NAME": "BENSALEM TOWNSHIP PRIDE DAY"
            },
            {
              "VENDOR_NAME": "BENSALEM TWP SCHOOL DISTRICT"
            },
            {
              "VENDOR_NAME": "BERESFORD COMPANY"
            },
            {
              "VENDOR_NAME": "BERGER REALTY"
            },
            {
              "VENDOR_NAME": "BERGETTA A. STERLING"
            },
            {
              "VENDOR_NAME": "BERGEY'S"
            },
            {
              "VENDOR_NAME": "BERGEY'S INC"
            },
            {
              "VENDOR_NAME": "BERK COMMERCIAL REAL ESTATE INC"
            },
            {
              "VENDOR_NAME": "BERKELEY LITTLE LEAGUE"
            },
            {
              "VENDOR_NAME": "BERKELEY TOWNSHIP"
            },
            {
              "VENDOR_NAME": "BERKELEY TWP.SEWAGE"
            },
            {
              "VENDOR_NAME": "BERKELEY WATER COMPANY"
            },
            {
              "VENDOR_NAME": "BERKHEIMER ADMIN"
            },
            {
              "VENDOR_NAME": "BERKHEIMER ASSOCIATES"
            },
            {
              "VENDOR_NAME": "BERKHEIMER HAB-EIT"
            },
            {
              "VENDOR_NAME": "BERKLEY ROAD ASSOCIATES"
            },
            {
              "VENDOR_NAME": "BERKLEY TOWNSHIP"
            },
            {
              "VENDOR_NAME": "BERKLEY TWP. MUA"
            },
            {
              "VENDOR_NAME": "BERKS COUNTY CONSERVATION"
            },
            {
              "VENDOR_NAME": "BERKS COUNTY EIT BUREAU"
            },
            {
              "VENDOR_NAME": "BERKS E.I.T. BUREAU"
            },
            {
              "VENDOR_NAME": "BERKS E.I.T.BUREAU"
            },
            {
              "VENDOR_NAME": "BERKS EIT BUREAU"
            },
            {
              "VENDOR_NAME": "BERKS PACKING COMPANY"
            },
            {
              "VENDOR_NAME": "BERKSHIRE DAIRY&FOOD PRODUCTS"
            },
            {
              "VENDOR_NAME": "BERKS-MONT NEWSPAPERS"
            },
            {
              "VENDOR_NAME": "BERLIN COMMUNITY SCHOOL HOME ASSOC."
            },
            {
              "VENDOR_NAME": "BERLIN GLASS & MIRROR INC"
            },
            {
              "VENDOR_NAME": "BERLIN INTER COMMUNITY"
            },
            {
              "VENDOR_NAME": "BERLITZ LANGUAGE CENTER"
            },
            {
              "VENDOR_NAME": "BERNADETTE ADAMS"
            },
            {
              "VENDOR_NAME": "BERNARD HODES GROUP INC."
            },
            {
              "VENDOR_NAME": "BERNER FOODS"
            },
            {
              "VENDOR_NAME": "BERNIE GILKIN"
            },
            {
              "VENDOR_NAME": "BERNIE PARENT"
            },
            {
              "VENDOR_NAME": "BERRY KAGAN SAHRADNIK ETA"
            },
            {
              "VENDOR_NAME": "BERRY PLASTICS"
            },
            {
              "VENDOR_NAME": "BERT G KERSTETTER"
            },
            {
              "VENDOR_NAME": "BERT KERSTETTER"
            },
            {
              "VENDOR_NAME": "BERTIE FERGUSON"
            },
            {
              "VENDOR_NAME": "BEST ACCESS SYSTEMS"
            },
            {
              "VENDOR_NAME": "BEST AUTO TAG"
            },
            {
              "VENDOR_NAME": "BEST BUY FOR BUSINESS"
            },
            {
              "VENDOR_NAME": "BEST COMPANIES GROUP"
            },
            {
              "VENDOR_NAME": "BEST CONSTRUCTION"
            },
            {
              "VENDOR_NAME": "BEST IMPRESSIONS"
            },
            {
              "VENDOR_NAME": "BEST INVESTMENTS"
            },
            {
              "VENDOR_NAME": "BEST LINE LEASING"
            },
            {
              "VENDOR_NAME": "BEST PERSONNEL INCORPORATED"
            },
            {
              "VENDOR_NAME": "BEST WESTERN"
            },
            {
              "VENDOR_NAME": "BEST WESTERN ANNAPOLIS"
            },
            {
              "VENDOR_NAME": "BEST WESTERN BORDENTOWN INN"
            },
            {
              "VENDOR_NAME": "BEST WESTERN HOTEL"
            },
            {
              "VENDOR_NAME": "BEST WESTERN INN AT"
            },
            {
              "VENDOR_NAME": "BEST WESTERN INVITATION INN"
            },
            {
              "VENDOR_NAME": "BEST WESTERN POTTSTOWN INN"
            },
            {
              "VENDOR_NAME": "BEST WESTERN-POTOMAC MILLS"
            },
            {
              "VENDOR_NAME": "BETA LIGHTING"
            },
            {
              "VENDOR_NAME": "BETCO CORPORATION"
            },
            {
              "VENDOR_NAME": "BETHANY BEACH-FENWICK AREA"
            },
            {
              "VENDOR_NAME": "BETHANY FLORIST"
            },
            {
              "VENDOR_NAME": "BETHLEHEM AREA SCHOOL DIS"
            },
            {
              "VENDOR_NAME": "BETHLEHEM AREA SCHOOL DISTRICT"
            },
            {
              "VENDOR_NAME": "BETHLEHEM DEVELOPERS"
            },
            {
              "VENDOR_NAME": "BETHLEHEM EQUITIES GROUP"
            },
            {
              "VENDOR_NAME": "BETHLEHEM TAX COLLECTOR"
            },
            {
              "VENDOR_NAME": "BETTE CAMPBELL"
            },
            {
              "VENDOR_NAME": "BETTE'S BOUNCES"
            },
            {
              "VENDOR_NAME": "BETTY JEAN DAUGHERTY"
            },
            {
              "VENDOR_NAME": "BETTY ROBINSON"
            },
            {
              "VENDOR_NAME": "BEUCLER"
            },
            {
              "VENDOR_NAME": "BEVCORe"
            },
            {
              "VENDOR_NAME": "BEVERAGE WORKS"
            },
            {
              "VENDOR_NAME": "BEVERLY CHRISTIAN"
            },
            {
              "VENDOR_NAME": "BEVERLY NOHL"
            },
            {
              "VENDOR_NAME": "BEVTEK"
            },
            {
              "VENDOR_NAME": "BEW GLOBAL"
            },
            {
              "VENDOR_NAME": "BFF LOCAL 735"
            },
            {
              "VENDOR_NAME": "BFI VALLEY FORGE DIVISION 847"
            },
            {
              "VENDOR_NAME": "BFI/ALLIED WASTE NATIONAL ACCOUNTS"
            },
            {
              "VENDOR_NAME": "BFML ASSOCIATES L.L.L.P."
            },
            {
              "VENDOR_NAME": "BFML ASSOCIATES L.L.L.P. C/O"
            },
            {
              "VENDOR_NAME": "BGE-NEW BUSINESS CONSTRUCTION"
            },
            {
              "VENDOR_NAME": "BHS BAND BOOSTERS"
            },
            {
              "VENDOR_NAME": "BIC CONSUMER PRODUCTS"
            },
            {
              "VENDOR_NAME": "BIC USA"
            },
            {
              "VENDOR_NAME": "BIERER & MARGOLIS"
            },
            {
              "VENDOR_NAME": "BIG BROS. & BIG SISTERS"
            },
            {
              "VENDOR_NAME": "BIG BROTHERS BIG SISTERS"
            },
            {
              "VENDOR_NAME": "BIG BROTHERS BIG SISTERS OF"
            },
            {
              "VENDOR_NAME": "BIG BROTHERS BIG SISTERS OF BERKS C"
            },
            {
              "VENDOR_NAME": "BIKERS AGAINST CHILD ABUSE"
            },
            {
              "VENDOR_NAME": "BILL BROWN AND COMPANY"
            },
            {
              "VENDOR_NAME": "BILL CAMPBELL"
            },
            {
              "VENDOR_NAME": "BILL GOODWIN EXCAVATING"
            },
            {
              "VENDOR_NAME": "BILL O'DONNELL"
            },
            {
              "VENDOR_NAME": "BILL SELL"
            },
            {
              "VENDOR_NAME": "BILL STEUER"
            },
            {
              "VENDOR_NAME": "BILLIE J. INC"
            },
            {
              "VENDOR_NAME": "BILMAR INDUSTRIAL PRODUCTS"
            },
            {
              "VENDOR_NAME": "BIMBO BAKERIES USA"
            },
            {
              "VENDOR_NAME": "BIMBO BAKERIES USA"
            },
            {
              "VENDOR_NAME": "BIMBO BAKERIES USA-STROEHMANN NNJ"
            },
            {
              "VENDOR_NAME": "BINGHAM MCCUTCHEN LLP"
            },
            {
              "VENDOR_NAME": "BINSWANGER GLASS"
            },
            {
              "VENDOR_NAME": "BIOFUELS CONNECT"
            },
            {
              "VENDOR_NAME": "BIOFUELS CONNECT"
            },
            {
              "VENDOR_NAME": "BIOMERIEUX"
            },
            {
              "VENDOR_NAME": "BIOURJA TRADING"
            },
            {
              "VENDOR_NAME": "BIRD CONTROL SERVICES"
            },
            {
              "VENDOR_NAME": "BIRDNECK HOLDING CO."
            },
            {
              "VENDOR_NAME": "BIRDNECK VILLAGE APTS."
            },
            {
              "VENDOR_NAME": "BIRDSALL ENGINEERING INC"
            },
            {
              "VENDOR_NAME": "BISGAIER HOFF"
            },
            {
              "VENDOR_NAME": "BJ'S WHOLESALE CLUB"
            },
            {
              "VENDOR_NAME": "BLACK BOX CORPORATION"
            },
            {
              "VENDOR_NAME": "BLACK RIVER TRANSPORTATION"
            },
            {
              "VENDOR_NAME": "BLACKHAWK MARKETING"
            },
            {
              "VENDOR_NAME": "BLACKHAWK MARKETING SERVICES"
            },
            {
              "VENDOR_NAME": "BLAIR'S FLORIST"
            },
            {
              "VENDOR_NAME": "BLANK ROME COMISKY &"
            },
            {
              "VENDOR_NAME": "BLANK ROME COMISKY & MCCAULEY"
            },
            {
              "VENDOR_NAME": "BLANKENBECKLER REAL ESTATE"
            },
            {
              "VENDOR_NAME": "BLAZING PRODUCTS"
            },
            {
              "VENDOR_NAME": "BLEND TEC"
            },
            {
              "VENDOR_NAME": "BLIBAUM & ASSOCIATES P.A."
            },
            {
              "VENDOR_NAME": "BLIBAUM AND JOHNSON"
            },
            {
              "VENDOR_NAME": "BLISTEX"
            },
            {
              "VENDOR_NAME": "BLOCK AND COMPANY"
            },
            {
              "VENDOR_NAME": "BLOCKBUSTER"
            },
            {
              "VENDOR_NAME": "BLOCKBUSTER ENTERTAINMENT"
            },
            {
              "VENDOR_NAME": "BLODGETT OVEN COMPANY"
            },
            {
              "VENDOR_NAME": "BLOOM ASSOCIATES"
            },
            {
              "VENDOR_NAME": "BLOOMBERG LP"
            },
            {
              "VENDOR_NAME": "BLOOMING GLEN CONTRACTORS"
            },
            {
              "VENDOR_NAME": "BLUE CROSS BLUE SHIELD OF DELAWARE"
            },
            {
              "VENDOR_NAME": "BLUE DIAMOND AMUSEMENT PARK"
            },
            {
              "VENDOR_NAME": "BLUE DOG PRINTING"
            },
            {
              "VENDOR_NAME": "BLUE HORSE"
            },
            {
              "VENDOR_NAME": "BLUE MOUNTAIN IPG ASSOCIATES"
            },
            {
              "VENDOR_NAME": "BLUE STAR MARKETING"
            },
            {
              "VENDOR_NAME": "BLUE STREAK COURIER SERVICE"
            },
            {
              "VENDOR_NAME": "BLUMENTHAL"
            },
            {
              "VENDOR_NAME": "BLUM-MOORE REPORTING SERVICES"
            },
            {
              "VENDOR_NAME": "BLX FUNDING LLC"
            },
            {
              "VENDOR_NAME": "BLYTHE McGARVIE"
            },
            {
              "VENDOR_NAME": "BMI-SG"
            },
            {
              "VENDOR_NAME": "BNP MEDIA"
            },
            {
              "VENDOR_NAME": "BO2 Vendor"
            },
            {
              "VENDOR_NAME": "BOARD OF CHILD CARE"
            },
            {
              "VENDOR_NAME": "BOARD OF FIRE COMMIS."
            },
            {
              "VENDOR_NAME": "BOARD OF FIRE COMMISSIONERS"
            },
            {
              "VENDOR_NAME": "BOARDROOM SOFTWARE INC"
            },
            {
              "VENDOR_NAME": "BOARDWALK BASKETBALL CLASSIC"
            },
            {
              "VENDOR_NAME": "BOB ALLEN & SONS INC"
            },
            {
              "VENDOR_NAME": "BOB BOWLING"
            },
            {
              "VENDOR_NAME": "BOB EHRLICH FOR GOVERNOR"
            },
            {
              "VENDOR_NAME": "BOB GIAMPIETRO"
            },
            {
              "VENDOR_NAME": "BOB GROSS DESIGN"
            },
            {
              "VENDOR_NAME": "BOB MANSURE"
            },
            {
              "VENDOR_NAME": "BOB McDONNELL INAUGURAL COMMITTEE"
            },
            {
              "VENDOR_NAME": "BOB WOOD"
            },
            {
              "VENDOR_NAME": "BOBBY JACKSON ENTERPRISES INC."
            },
            {
              "VENDOR_NAME": "BOBBY'S PORTABLE RESTROOMS"
            },
            {
              "VENDOR_NAME": "BOEHRINGER INGELHEIM"
            },
            {
              "VENDOR_NAME": "BOHLER ENGENEERING INC"
            },
            {
              "VENDOR_NAME": "BOHLER ENGINEERING INC"
            },
            {
              "VENDOR_NAME": "BOHLER ENGINEERING P.C."
            },
            {
              "VENDOR_NAME": "BOIS-F&C LIQUIDS SECTION"
            },
            {
              "VENDOR_NAME": "BOK TOWER GARDENS"
            },
            {
              "VENDOR_NAME": "BOLGER BROTHERS INC"
            },
            {
              "VENDOR_NAME": "BOLLING FOR LT.GOVERNOR"
            },
            {
              "VENDOR_NAME": "BOLLINO"
            },
            {
              "VENDOR_NAME": "BOLLINO"
            },
            {
              "VENDOR_NAME": "BON APPETIT"
            },
            {
              "VENDOR_NAME": "BON VENTURE CHURCH BULLETIN"
            },
            {
              "VENDOR_NAME": "BONHAGE"
            },
            {
              "VENDOR_NAME": "BONNER BASEBALL"
            },
            {
              "VENDOR_NAME": "BONNER GOLF CLASSIC"
            },
            {
              "VENDOR_NAME": "BONNIE SALCH"
            },
            {
              "VENDOR_NAME": "BONOBO ENTERPRISES LLC"
            },
            {
              "VENDOR_NAME": "BONREAL AS AGENT FOR"
            },
            {
              "VENDOR_NAME": "BONSANGUE/BONREAL 200"
            },
            {
              "VENDOR_NAME": "BONSANGUE/BONREAL 300"
            },
            {
              "VENDOR_NAME": "BOODEN"
            },
            {
              "VENDOR_NAME": "BOR OF BELLMAWR TAX C0LL"
            },
            {
              "VENDOR_NAME": "BORA BORA LTD"
            },
            {
              "VENDOR_NAME": "BORDENTOWN SEWERAGE AUTH"
            },
            {
              "VENDOR_NAME": "BORDERS BOOK STORE"
            },
            {
              "VENDOR_NAME": "BORDERS INC."
            },
            {
              "VENDOR_NAME": "BORO OF AUDUBON"
            },
            {
              "VENDOR_NAME": "BORO OF BELLMAWR WTR DEPT"
            },
            {
              "VENDOR_NAME": "BORO OF CHESTER HEIGHTS"
            },
            {
              "VENDOR_NAME": "BORO OF COLLINGSWOOD"
            },
            {
              "VENDOR_NAME": "BORO OF DOYLESTOWN"
            },
            {
              "VENDOR_NAME": "BORO OF EAST STROUDSBURG"
            },
            {
              "VENDOR_NAME": "BORO OF FOLSOM/TAX OFFICE"
            },
            {
              "VENDOR_NAME": "BORO OF FOUNTAIN HILL"
            },
            {
              "VENDOR_NAME": "BORO OF KENNETT SQUARE"
            },
            {
              "VENDOR_NAME": "BORO OF LINDENWOLD"
            },
            {
              "VENDOR_NAME": "BORO OF MAGNOLIA TAX COLL"
            },
            {
              "VENDOR_NAME": "BORO OF MEDIA TAX COLLECTOR"
            },
            {
              "VENDOR_NAME": "BORO OF POTTSTOWN"
            },
            {
              "VENDOR_NAME": "BORO OF ROYERSFORD TAX COLLECTOR"
            },
            {
              "VENDOR_NAME": "BORO OF TUCKERTON"
            },
            {
              "VENDOR_NAME": "BORO OF W. CONSHOHOCKEN"
            },
            {
              "VENDOR_NAME": "BORO OF WEST CHESTER"
            },
            {
              "VENDOR_NAME": "BORO PROSPECT PARK"
            },
            {
              "VENDOR_NAME": "BORO-GLASSBORO"
            },
            {
              "VENDOR_NAME": "BOROUGH OF AMBLER"
            },
            {
              "VENDOR_NAME": "BOROUGH OF AVALON"
            },
            {
              "VENDOR_NAME": "BOROUGH OF BELLMAWR"
            },
            {
              "VENDOR_NAME": "BOROUGH OF BERLIN"
            },
            {
              "VENDOR_NAME": "BOROUGH OF BERLIN NJ"
            },
            {
              "VENDOR_NAME": "BOROUGH OF BERLIN TAX COL"
            },
            {
              "VENDOR_NAME": "BOROUGH OF BRIDGEPORT"
            },
            {
              "VENDOR_NAME": "BOROUGH OF BRISTOL"
            },
            {
              "VENDOR_NAME": "BOROUGH OF BROOKHAVEN"
            },
            {
              "VENDOR_NAME": "BOROUGH OF BUENA"
            },
            {
              "VENDOR_NAME": "BOROUGH OF CATASAUQUA"
            },
            {
              "VENDOR_NAME": "BOROUGH OF CHESTER HEIGHTS"
            },
            {
              "VENDOR_NAME": "BOROUGH OF COLLINGSWOOD"
            },
            {
              "VENDOR_NAME": "BOROUGH OF DOWNINGTOWN"
            },
            {
              "VENDOR_NAME": "BOROUGH OF EATONTOWN"
            },
            {
              "VENDOR_NAME": "BOROUGH OF EPHRATA"
            },
            {
              "VENDOR_NAME": "BOROUGH OF FLEMINGTON"
            },
            {
              "VENDOR_NAME": "BOROUGH OF FOLCROFT"
            },
            {
              "VENDOR_NAME": "BOROUGH OF GIBBSBORO"
            },
            {
              "VENDOR_NAME": "BOROUGH OF GLASSBORO"
            },
            {
              "VENDOR_NAME": "BOROUGH OF GLENOLDEN"
            },
            {
              "VENDOR_NAME": "BOROUGH OF HATBORO"
            },
            {
              "VENDOR_NAME": "BOROUGH OF KENNETT SQUARE"
            },
            {
              "VENDOR_NAME": "BOROUGH OF LAKEHURST"
            },
            {
              "VENDOR_NAME": "BOROUGH OF LAKEHURST POLICE DEPT"
            },
            {
              "VENDOR_NAME": "BOROUGH OF LANSDOWNE"
            },
            {
              "VENDOR_NAME": "BOROUGH OF LEBANON"
            },
            {
              "VENDOR_NAME": "BOROUGH OF LINDENWOLD"
            },
            {
              "VENDOR_NAME": "BOROUGH OF MAGNOLIA"
            },
            {
              "VENDOR_NAME": "BOROUGH OF MORTON"
            },
            {
              "VENDOR_NAME": "BOROUGH OF MOUNT EPHRAIM"
            },
            {
              "VENDOR_NAME": "BOROUGH OF NORTH WALES"
            },
            {
              "VENDOR_NAME": "BOROUGH OF OAKLYN"
            },
            {
              "VENDOR_NAME": "BOROUGH OF PALMYRA"
            },
            {
              "VENDOR_NAME": "BOROUGH OF PAULSBORO"
            },
            {
              "VENDOR_NAME": "BOROUGH OF PHOENIXVILLE"
            },
            {
              "VENDOR_NAME": "BOROUGH OF PINE HILL"
            },
            {
              "VENDOR_NAME": "BOROUGH OF PITMAN"
            },
            {
              "VENDOR_NAME": "BOROUGH OF POTTSTOWN"
            },
            {
              "VENDOR_NAME": "BOROUGH OF PRINCETON"
            },
            {
              "VENDOR_NAME": "BOROUGH OF PROSPECT PARK"
            },
            {
              "VENDOR_NAME": "BOROUGH OF QUAKERTOWN"
            },
            {
              "VENDOR_NAME": "BOROUGH OF RED BANK"
            },
            {
              "VENDOR_NAME": "BOROUGH OF RIDLEY PARK"
            },
            {
              "VENDOR_NAME": "BOROUGH OF RUNNEMEDE"
            },
            {
              "VENDOR_NAME": "BOROUGH OF SAYREVILLE"
            },
            {
              "VENDOR_NAME": "BOROUGH OF SHILLINGTON"
            },
            {
              "VENDOR_NAME": "BOROUGH OF SHIP BOTTOM"
            },
            {
              "VENDOR_NAME": "BOROUGH OF SINKING SPRING"
            },
            {
              "VENDOR_NAME": "BOROUGH OF SOMERDALE"
            },
            {
              "VENDOR_NAME": "BOROUGH OF SOUTH RIVER"
            },
            {
              "VENDOR_NAME": "BOROUGH OF SOUTH RIVER FOOD BANK"
            },
            {
              "VENDOR_NAME": "BOROUGH OF STONE HARBOR"
            },
            {
              "VENDOR_NAME": "BOROUGH OF STROUDSBURG"
            },
            {
              "VENDOR_NAME": "BOROUGH OF TINTON FALLS"
            },
            {
              "VENDOR_NAME": "BOROUGH OF TUCKERTON"
            },
            {
              "VENDOR_NAME": "BOROUGH OF WEST CHESTER"
            },
            {
              "VENDOR_NAME": "BOROUGH OF WESTVILLE"
            },
            {
              "VENDOR_NAME": "BOROUGH OF WILDWOOD CREST"
            },
            {
              "VENDOR_NAME": "BOROUGH OF WOODBURY HEIGHTS"
            },
            {
              "VENDOR_NAME": "BOROUGH OF WOODSTOWN"
            },
            {
              "VENDOR_NAME": "BOROUGH OF WYOMISSING"
            },
            {
              "VENDOR_NAME": "BOROUGH OFCHESTER HEIGHTS"
            },
            {
              "VENDOR_NAME": "BOROUGH POINT PLEASANT T"
            },
            {
              "VENDOR_NAME": "BOSTON AMERICA CORP"
            },
            {
              "VENDOR_NAME": "BOS-UTIL"
            },
            {
              "VENDOR_NAME": "BOTTOMLINE TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "BOUCHER & JAMES"
            },
            {
              "VENDOR_NAME": "BOULEVARD ASSOCIATES I"
            },
            {
              "VENDOR_NAME": "BOUTIN CAMPAIGN COMMITTEE"
            },
            {
              "VENDOR_NAME": "BOWMAN CONSULTING"
            },
            {
              "VENDOR_NAME": "BOY SCOUTS OF AMERICA"
            },
            {
              "VENDOR_NAME": "BOYER CANDY COMPANY"
            },
            {
              "VENDOR_NAME": "BOYER'S FOOD MARKETS"
            },
            {
              "VENDOR_NAME": "BOYERS MARKET 66227"
            },
            {
              "VENDOR_NAME": "BOYERTOWN EIT"
            },
            {
              "VENDOR_NAME": "BOYS & GIRLS CLUB OF PHILADELPHIA"
            },
            {
              "VENDOR_NAME": "BP CORPORATION"
            },
            {
              "VENDOR_NAME": "BP PRODUCTS NORTH AMERICA"
            },
            {
              "VENDOR_NAME": "BPG CS DEVELOPMENT L.P."
            },
            {
              "VENDOR_NAME": "BPG CS DEVELOPMENT LP"
            },
            {
              "VENDOR_NAME": "Br2Ho4DF Vendor"
            },
            {
              "VENDOR_NAME": "BRAD C. WALL"
            },
            {
              "VENDOR_NAME": "BRADFORD ENGINEERING ASSOC."
            },
            {
              "VENDOR_NAME": "BRADLEY K. WRIGHT"
            },
            {
              "VENDOR_NAME": "BRAIN INJURY ASSOCIATION OF"
            },
            {
              "VENDOR_NAME": "BRAINSTORM"
            },
            {
              "VENDOR_NAME": "BRAITHWAITE COMMUNICATIONS INC"
            },
            {
              "VENDOR_NAME": "BRAMBLE'S SWEEPING INC."
            },
            {
              "VENDOR_NAME": "BRANCH BANKING & TRUST CO"
            },
            {
              "VENDOR_NAME": "BRANCH BANKING AND TRUST"
            },
            {
              "VENDOR_NAME": "Branch Office 1"
            },
            {
              "VENDOR_NAME": "Branch Office 2"
            },
            {
              "VENDOR_NAME": "Branch Office 3"
            },
            {
              "VENDOR_NAME": "Branch4HO621313 Inc"
            },
            {
              "VENDOR_NAME": "BranchOffice1 Vendor"
            },
            {
              "VENDOR_NAME": "BranchOffice4DF Vendor"
            },
            {
              "VENDOR_NAME": "BRAND NEW PRODUCTS LLC"
            },
            {
              "VENDOR_NAME": "BRANDERS.COM"
            },
            {
              "VENDOR_NAME": "BRANDYWINE BUILDING SERVICES"
            },
            {
              "VENDOR_NAME": "BRANDYWINE CONSERVANCY"
            },
            {
              "VENDOR_NAME": "BRANDYWINE HEALTH & WELLNESS FOUNDA"
            },
            {
              "VENDOR_NAME": "BRANDYWINE LIONS CLUB"
            },
            {
              "VENDOR_NAME": "BRANDYWINE PICNIC PARK"
            },
            {
              "VENDOR_NAME": "BRANDYWINE REALTY MGMT"
            },
            {
              "VENDOR_NAME": "BRANDYWINE REALTY MGMT."
            },
            {
              "VENDOR_NAME": "BRANDYWINE RIVER HOTEL"
            },
            {
              "VENDOR_NAME": "BRANDYWINE VALLEY TALENT"
            },
            {
              "VENDOR_NAME": "BRANDYWINE YMCA"
            },
            {
              "VENDOR_NAME": "BRAUNINGER NEWS"
            },
            {
              "VENDOR_NAME": "BRD NOISE AND VIBRATION CONTROL"
            },
            {
              "VENDOR_NAME": "BREAST CANCER 3-DAY"
            },
            {
              "VENDOR_NAME": "BRECO"
            },
            {
              "VENDOR_NAME": "BRECO Wawa Owned Inventory"
            },
            {
              "VENDOR_NAME": "BRECO"
            },
            {
              "VENDOR_NAME": "BRELLA PRODUCTIONS"
            },
            {
              "VENDOR_NAME": "BRENDA CHARTIER"
            },
            {
              "VENDOR_NAME": "BRENDA MCCULLEN ATT:REX DAVIS CLERK"
            },
            {
              "VENDOR_NAME": "BRENDA POLLARD"
            },
            {
              "VENDOR_NAME": "BRENDA SHAY"
            },
            {
              "VENDOR_NAME": "BRENNAN'S OFFICE INTERIORS"
            },
            {
              "VENDOR_NAME": "BRENNTAGE NORTHEAST"
            },
            {
              "VENDOR_NAME": "BREWSTER DAIRY"
            },
            {
              "VENDOR_NAME": "BRIAN A BLITZ ATTY. FOR THE TIME GP"
            },
            {
              "VENDOR_NAME": "BRIAN A. BLITZ ATTY FOR TIME GROUP"
            },
            {
              "VENDOR_NAME": "BRIAN A. BLITZ"
            },
            {
              "VENDOR_NAME": "BRIAN A.BIRCH"
            },
            {
              "VENDOR_NAME": "BRIAN BLITZ for TC STONEGATE CO."
            },
            {
              "VENDOR_NAME": "BRIAN BLITZ"
            },
            {
              "VENDOR_NAME": "BRIAN CLARKE"
            },
            {
              "VENDOR_NAME": "BRIAN CONNER"
            },
            {
              "VENDOR_NAME": "BRIAN D. PASS"
            },
            {
              "VENDOR_NAME": "BRIAN EIFE"
            },
            {
              "VENDOR_NAME": "BRIAN ENGLEY"
            },
            {
              "VENDOR_NAME": "BRIAN F.McCAFFREY"
            },
            {
              "VENDOR_NAME": "BRIAN HANNON"
            },
            {
              "VENDOR_NAME": "BRIAN KEITH WINDER"
            },
            {
              "VENDOR_NAME": "BRIAN L. DUKE"
            },
            {
              "VENDOR_NAME": "BRIAN MCQUISTON"
            },
            {
              "VENDOR_NAME": "BRIAN SCHALLER"
            },
            {
              "VENDOR_NAME": "BRIAN SCHULTZ-MERCURY"
            },
            {
              "VENDOR_NAME": "BRICE EXCAVATING"
            },
            {
              "VENDOR_NAME": "BRICK TOWNSHIP"
            },
            {
              "VENDOR_NAME": "BRICK TOWNSHIP P.A.L."
            },
            {
              "VENDOR_NAME": "BRICK TWP FIRST AID SQUAD"
            },
            {
              "VENDOR_NAME": "BRICK TWP MUNIC UTIL AUTH"
            },
            {
              "VENDOR_NAME": "BRIDGE OF HOPE"
            },
            {
              "VENDOR_NAME": "BRIDGEHEAD SOFTWARE"
            },
            {
              "VENDOR_NAME": "BRIDGEPORT WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "BRIDGETON BOARD OF EDUCATION"
            },
            {
              "VENDOR_NAME": "BRIDGETON RESTAURANT EQUIPMENT"
            },
            {
              "VENDOR_NAME": "BRIDGEWATER TOWNSHIP"
            },
            {
              "VENDOR_NAME": "BRIGANTINE POLICEMAN'S"
            },
            {
              "VENDOR_NAME": "BRIGANTINE PROFESSIONAL"
            },
            {
              "VENDOR_NAME": "BRIGHT MELON"
            },
            {
              "VENDOR_NAME": "BRIGHTER DAY"
            },
            {
              "VENDOR_NAME": "BRIGHTLINE COMPLIANCE"
            },
            {
              "VENDOR_NAME": "BRIGHTMELON"
            },
            {
              "VENDOR_NAME": "BRIGHTON INTERNATIONAL"
            },
            {
              "VENDOR_NAME": "BRINTON LAKE HOTEL ASSOCIATES"
            },
            {
              "VENDOR_NAME": "BRINTON LAKE"
            },
            {
              "VENDOR_NAME": "BRISTOL BORO-SCH TX COLL"
            },
            {
              "VENDOR_NAME": "BRISTOL BOROUGH"
            },
            {
              "VENDOR_NAME": "BRISTOL TOWNSHIP"
            },
            {
              "VENDOR_NAME": "BRISTOL TOWNSHIP SEWER DEPT."
            },
            {
              "VENDOR_NAME": "BRISTOL TOWNSHIP TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "BRISTOL TWP BD.OF COMMISS"
            },
            {
              "VENDOR_NAME": "BRITTNEY JILES"
            },
            {
              "VENDOR_NAME": "BROAD & STEFKO"
            },
            {
              "VENDOR_NAME": "BROAD RUN GOLFER'S CLUB"
            },
            {
              "VENDOR_NAME": "BROAD STREET PUBLISHING"
            },
            {
              "VENDOR_NAME": "BROADCAST MONITORS"
            },
            {
              "VENDOR_NAME": "BROADHEAD CREEK REGIONAL AUTHORITY"
            },
            {
              "VENDOR_NAME": "BROADWAY ASSOCIATES OF"
            },
            {
              "VENDOR_NAME": "BROADWAY REALTY"
            },
            {
              "VENDOR_NAME": "BROCKTON CONSTRUCTION LLC"
            },
            {
              "VENDOR_NAME": "BROCKWELL'S SEPTIC & SERVICE"
            },
            {
              "VENDOR_NAME": "BRODEUR'S COUNTRY HSE INN"
            },
            {
              "VENDOR_NAME": "BRODY TRANSPORTATION CO. INC."
            },
            {
              "VENDOR_NAME": "BrOf2-4DF Vendor"
            },
            {
              "VENDOR_NAME": "BROGAN LANDSCAPING"
            },
            {
              "VENDOR_NAME": "BRONIEC ASSOCIATES"
            },
            {
              "VENDOR_NAME": "BRONX SCU"
            },
            {
              "VENDOR_NAME": "BROOK POLLACK"
            },
            {
              "VENDOR_NAME": "BROOKHAVEN BORO. WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "BROOKHAVEN TOWN WATCH"
            },
            {
              "VENDOR_NAME": "BROOKHOLLOW HOLIDAY COLLECTION"
            },
            {
              "VENDOR_NAME": "BROOKSIDE MANOR AT SOMERTON SPRINGS"
            },
            {
              "VENDOR_NAME": "BROTHER'S PIZZA AND PASTA"
            },
            {
              "VENDOR_NAME": "BROWN & WILLIAMSON CORP"
            },
            {
              "VENDOR_NAME": "BROWN APPRAISAL COMPANY"
            },
            {
              "VENDOR_NAME": "BROWN BUILDING CORPORATION"
            },
            {
              "VENDOR_NAME": "BROWN DISTRIBUTI"
            },
            {
              "VENDOR_NAME": "BROWN INNOVATIVE GRAPHICS"
            },
            {
              "VENDOR_NAME": "BROWNING-FERRIS INDUSTRIES"
            },
            {
              "VENDOR_NAME": "BROWNING'S LANDSCAPING"
            },
            {
              "VENDOR_NAME": "BRR REFRIGERATION"
            },
            {
              "VENDOR_NAME": "BRT INC"
            },
            {
              "VENDOR_NAME": "BRUBACHER EXCAVATING"
            },
            {
              "VENDOR_NAME": "BRUCE BROOKS"
            },
            {
              "VENDOR_NAME": "BRUCE DODSON"
            },
            {
              "VENDOR_NAME": "BRUCE McMILLIN ASSOCIATES"
            },
            {
              "VENDOR_NAME": "BRUCE RICE"
            },
            {
              "VENDOR_NAME": "BRUCE TEMPONE"
            },
            {
              "VENDOR_NAME": "BRUNSWICK CIRCUIT COURT"
            },
            {
              "VENDOR_NAME": "BRUNSWICK COMBINED COURT"
            },
            {
              "VENDOR_NAME": "BRUSKE PRODUCTS"
            },
            {
              "VENDOR_NAME": "BRYAN BAILEY"
            },
            {
              "VENDOR_NAME": "BRYAN MULHOLLAND"
            },
            {
              "VENDOR_NAME": "BRYAN STEAM LLC"
            },
            {
              "VENDOR_NAME": "BRYAN WOLFINGTON"
            },
            {
              "VENDOR_NAME": "BRYANT B.GOODLOE"
            },
            {
              "VENDOR_NAME": "BRYANT TRAILER CORP."
            },
            {
              "VENDOR_NAME": "BRYN MAWR HOSPITAL COMMUNITY"
            },
            {
              "VENDOR_NAME": "BSF RICHMOND"
            },
            {
              "VENDOR_NAME": "BSW ARCHITECTS"
            },
            {
              "VENDOR_NAME": "BSW INTERNATIONAL"
            },
            {
              "VENDOR_NAME": "BTC FOODS"
            },
            {
              "VENDOR_NAME": "BUCHANAN INGERSOLL CORP."
            },
            {
              "VENDOR_NAME": "BUCK CONSULTANTS LLC"
            },
            {
              "VENDOR_NAME": "BUCK HOTEL"
            },
            {
              "VENDOR_NAME": "BUCKEYE ENERGY"
            },
            {
              "VENDOR_NAME": "BUCKEYE PIPE LINE COMPANY"
            },
            {
              "VENDOR_NAME": "BUCKEYE PIPE LINE COMPANY - ACCRUAL"
            },
            {
              "VENDOR_NAME": "BUCKEYE TERMINALS"
            },
            {
              "VENDOR_NAME": "BUCKINGHAM TOWNSHIP"
            },
            {
              "VENDOR_NAME": "BUCKINGHAM WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "BUCKLEY"
            },
            {
              "VENDOR_NAME": "BUCKLEY"
            },
            {
              "VENDOR_NAME": "BUCKS CO COURIER TIMES"
            },
            {
              "VENDOR_NAME": "BUCKS CO DEPT OF HEALTH"
            },
            {
              "VENDOR_NAME": "BUCKS CO. CLERKS OF COURT"
            },
            {
              "VENDOR_NAME": "BUCKS COUNTY COMMUNITY COLLEGE"
            },
            {
              "VENDOR_NAME": "BUCKS COUNTY HEALTH DEPT."
            },
            {
              "VENDOR_NAME": "BUCKS COUNTY HERO"
            },
            {
              "VENDOR_NAME": "BUCKS COUNTY MAGISTERIAL DIST.07-2-"
            },
            {
              "VENDOR_NAME": "BUCKS COUNTY T.M.A."
            },
            {
              "VENDOR_NAME": "BUCKS COUNTY TAX CLAIM BUREAU"
            },
            {
              "VENDOR_NAME": "BUCKS COUNTY WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "BUCKS COUNTY WATER &"
            },
            {
              "VENDOR_NAME": "BUCKS CTY CONSERVATION"
            },
            {
              "VENDOR_NAME": "BUCKY'S MOBEL TRUCK STOP"
            },
            {
              "VENDOR_NAME": "BUDDY ELLIOTT"
            },
            {
              "VENDOR_NAME": "BUDGET CHEMICAL & CLEANING"
            },
            {
              "VENDOR_NAME": "BUDGET MAINTENANCE"
            },
            {
              "VENDOR_NAME": "BUDGET SERVICES"
            },
            {
              "VENDOR_NAME": "BUDGET SUPPLY"
            },
            {
              "VENDOR_NAME": "BUDGET TRUCK RENTAL LLC"
            },
            {
              "VENDOR_NAME": "BUFORD L. DAVIS"
            },
            {
              "VENDOR_NAME": "BULLDOG CONSTRUCTION CO."
            },
            {
              "VENDOR_NAME": "BULLDOG PRESS/RUSSELL GROUP"
            },
            {
              "VENDOR_NAME": "BULLETIN BOARD"
            },
            {
              "VENDOR_NAME": "BUMPER SPECIALTIES INC."
            },
            {
              "VENDOR_NAME": "BUNDLE OF JOY CHILD DEVELOPMENT CEN"
            },
            {
              "VENDOR_NAME": "BUNDLE OF JOY CHILD DEVELOPMENT CTR"
            },
            {
              "VENDOR_NAME": "BUNN-O-MATIC CORP"
            },
            {
              "VENDOR_NAME": "BUNTING'S GARAGE"
            },
            {
              "VENDOR_NAME": "BUNZL DISTRIBUTION"
            },
            {
              "VENDOR_NAME": "BUNZL DISTRIBUTION INC"
            },
            {
              "VENDOR_NAME": "BUNZL INDUSTRIES INC"
            },
            {
              "VENDOR_NAME": "BUNZL-EDI"
            },
            {
              "VENDOR_NAME": "BURDICKS NEWS AGENCY"
            },
            {
              "VENDOR_NAME": "BUREAU FOR CHILD SUPPORT ENF"
            },
            {
              "VENDOR_NAME": "BUREAU FOR CHILD SUPPORT ENF."
            },
            {
              "VENDOR_NAME": "BUREAU FOR CHILD SUPPORT ENFORCE."
            },
            {
              "VENDOR_NAME": "BUREAU FOR CHILD SUPPORT ENFORCEMEN"
            },
            {
              "VENDOR_NAME": "BUREAU OF CHILD SUPPORT"
            },
            {
              "VENDOR_NAME": "BUREAU OF CHILD SUPPORT ENFORCEMENT"
            },
            {
              "VENDOR_NAME": "BUREAU OF COLLECTIONS LEHIGH CO."
            },
            {
              "VENDOR_NAME": "BUREAU OF COMPLIANCE"
            },
            {
              "VENDOR_NAME": "BUREAU OF FIRE PREVENTION"
            },
            {
              "VENDOR_NAME": "BUREAU OF RECORDS"
            },
            {
              "VENDOR_NAME": "BURGESE BUILDERS & DESIGN"
            },
            {
              "VENDOR_NAME": "BURGESS & NIPLE"
            },
            {
              "VENDOR_NAME": "BURHANS GLASS CO INC"
            },
            {
              "VENDOR_NAME": "BURKLEY'S REWARD"
            },
            {
              "VENDOR_NAME": "BURLINGTON CITY"
            },
            {
              "VENDOR_NAME": "BURLINGTON CO BOARD OF SOC SERV"
            },
            {
              "VENDOR_NAME": "BURLINGTON CO COURTS FACILITY"
            },
            {
              "VENDOR_NAME": "BURLINGTON CO SPEC.CIVIL PART"
            },
            {
              "VENDOR_NAME": "BURLINGTON CO SPECIAL CIVIL"
            },
            {
              "VENDOR_NAME": "BURLINGTON CO SPECIAL CIVIL PART"
            },
            {
              "VENDOR_NAME": "BURLINGTON CO SPECIAL CIVIL PT"
            },
            {
              "VENDOR_NAME": "BURLINGTON CO SPECIAL PART CLERK"
            },
            {
              "VENDOR_NAME": "BURLINGTON CO. BOARD OF SOC SERV"
            },
            {
              "VENDOR_NAME": "BURLINGTON CO. BOARD OF SOC SERV."
            },
            {
              "VENDOR_NAME": "BURLINGTON CO. BOARD OF SOC. SERV."
            },
            {
              "VENDOR_NAME": "BURLINGTON CO. SPECIAL CIVIL PART"
            },
            {
              "VENDOR_NAME": "BURLINGTON CO. SPECIAL CIVIL PART."
            },
            {
              "VENDOR_NAME": "BURLINGTON CO. SPECIAL CIVIL PT"
            },
            {
              "VENDOR_NAME": "BURLINGTON CO.SPECIAL CIVIL PART"
            },
            {
              "VENDOR_NAME": "BURLINGTON CO.SPECIAL CIVIL PT"
            },
            {
              "VENDOR_NAME": "BURLINGTON CO.SUPERIOR COURT"
            },
            {
              "VENDOR_NAME": "BURLINGTON COUNTY"
            },
            {
              "VENDOR_NAME": "BURLINGTON COUNTY BOAD OF SS"
            },
            {
              "VENDOR_NAME": "BURLINGTON COUNTY S.C.P."
            },
            {
              "VENDOR_NAME": "BURLINGTON COUNTY SCP"
            },
            {
              "VENDOR_NAME": "BURLINGTON COUNTY SHERIFF"
            },
            {
              "VENDOR_NAME": "BURLINGTON COUNTY SOIL"
            },
            {
              "VENDOR_NAME": "BURLINGTON COUNTY SOIL CONSERVATION"
            },
            {
              "VENDOR_NAME": "BURLINGTON COUNTY SPC"
            },
            {
              "VENDOR_NAME": "BURLINGTON COUNTY SPEC CIVIL PART"
            },
            {
              "VENDOR_NAME": "BURLINGTON COUNTY SPECIAL CIVIL PAR"
            },
            {
              "VENDOR_NAME": "BURLINGTON COUNTY TIMES"
            },
            {
              "VENDOR_NAME": "BURLINGTON CTY HEALTH DEP"
            },
            {
              "VENDOR_NAME": "BURLINGTON CTY TREASURER"
            },
            {
              "VENDOR_NAME": "BURLINGTON CTY.SCP"
            },
            {
              "VENDOR_NAME": "BURLINGTON ELECTRICAL"
            },
            {
              "VENDOR_NAME": "BURLINGTON TOWNSHIP"
            },
            {
              "VENDOR_NAME": "BURLINGTON TOWNSHIP HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "BURLINGTON TWP"
            },
            {
              "VENDOR_NAME": "BURLINGTON TWP WATER UTILITY"
            },
            {
              "VENDOR_NAME": "BURMONT DEVELOPMENT ASSOC"
            },
            {
              "VENDOR_NAME": "BUSCH GARDENS"
            },
            {
              "VENDOR_NAME": "BUSINESS FORECAST SYSTEMS"
            },
            {
              "VENDOR_NAME": "BUSINESS INSURANCE"
            },
            {
              "VENDOR_NAME": "BUSINESS LAWS INC"
            },
            {
              "VENDOR_NAME": "BUSINESS OBJECTS AMERICAS"
            },
            {
              "VENDOR_NAME": "BUSINESS WEEK"
            },
            {
              "VENDOR_NAME": "BUSTLETON ENTERPRISES LLC"
            },
            {
              "VENDOR_NAME": "BUTLER GARDENS"
            },
            {
              "VENDOR_NAME": "BUTTERBALL"
            },
            {
              "VENDOR_NAME": "BUZ AND NEDS REAL BBQ"
            },
            {
              "VENDOR_NAME": "BVW ASSOCIATES"
            },
            {
              "VENDOR_NAME": "BYB BRANDS"
            },
            {
              "VENDOR_NAME": "BYNUM TRANSPORT"
            },
            {
              "VENDOR_NAME": "BYRD CREEK LLC"
            },
            {
              "VENDOR_NAME": "BYRON BURNES"
            },
            {
              "VENDOR_NAME": "C & A NEWS SERVICE"
            },
            {
              "VENDOR_NAME": "C & E TOWING"
            },
            {
              "VENDOR_NAME": "C & F FINANCE COMPANY"
            },
            {
              "VENDOR_NAME": "C & G BELTSVILLE"
            },
            {
              "VENDOR_NAME": "C & H DISTRIBUTORS"
            },
            {
              "VENDOR_NAME": "C & K"
            },
            {
              "VENDOR_NAME": "C & M ASSOCIATES"
            },
            {
              "VENDOR_NAME": "C & M NEWS SERVICE"
            },
            {
              "VENDOR_NAME": "C & W MECHANICAL"
            },
            {
              "VENDOR_NAME": "C L S C ASSOCIATES"
            },
            {
              "VENDOR_NAME": "C N A"
            },
            {
              "VENDOR_NAME": "C RANDALL MEYERS & CHARLES MEYERS"
            },
            {
              "VENDOR_NAME": "C T CORPORATION SYSTEM"
            },
            {
              "VENDOR_NAME": "C. RANDALL MEYERS & CHARLES MEYERS"
            },
            {
              "VENDOR_NAME": "C. S. LANDRY FOUNDATION"
            },
            {
              "VENDOR_NAME": "C.A.SCHIFMAN & CO."
            },
            {
              "VENDOR_NAME": "C.C. AND F.F. KEESLER INC."
            },
            {
              "VENDOR_NAME": "C.H.ROBINSON COMPANY"
            },
            {
              "VENDOR_NAME": "C.J. HUMMELS RESTAURANT"
            },
            {
              "VENDOR_NAME": "C.P. RANKIN"
            },
            {
              "VENDOR_NAME": "C.P.RANKIN"
            },
            {
              "VENDOR_NAME": "CABANA'S CONTRACTING"
            },
            {
              "VENDOR_NAME": "CACV OF COLORADO"
            },
            {
              "VENDOR_NAME": "CACV OF COLORADO"
            },
            {
              "VENDOR_NAME": "CADAPULT"
            },
            {
              "VENDOR_NAME": "CADBURY ADAMS"
            },
            {
              "VENDOR_NAME": "CADBURY SCHWEPPS AMERICA BEVERAGES"
            },
            {
              "VENDOR_NAME": "CAEL NATIONAL HEADQUARTERS"
            },
            {
              "VENDOR_NAME": "CAESAR"
            },
            {
              "VENDOR_NAME": "CAESARS PLACE"
            },
            {
              "VENDOR_NAME": "CAHILL ASSOCIATES"
            },
            {
              "VENDOR_NAME": "CAL MIL PLASTIC PRODUCTS"
            },
            {
              "VENDOR_NAME": "CALCUTTA HOUSE"
            },
            {
              "VENDOR_NAME": "CALEB BRETT USA INC"
            },
            {
              "VENDOR_NAME": "CALIFORNIA STATE DISBURSEMENT UNIT"
            },
            {
              "VENDOR_NAME": "CALN TOWNSHIP"
            },
            {
              "VENDOR_NAME": "CALN TOWNSHIP / CTMA"
            },
            {
              "VENDOR_NAME": "CALN TOWNSHIP POLICE DEPT."
            },
            {
              "VENDOR_NAME": "CALN TOWNSHIP WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "CALTER AND COMPANY"
            },
            {
              "VENDOR_NAME": "CALVARY-RIVERVIEW"
            },
            {
              "VENDOR_NAME": "CALVERT COUNTY DEPT OF PARKS"
            },
            {
              "VENDOR_NAME": "CALVERT COUNTY HEALTH DEPT."
            },
            {
              "VENDOR_NAME": "CALVERT COUNTY TREASURER"
            },
            {
              "VENDOR_NAME": "CALVERT INDEPENDENT"
            },
            {
              "VENDOR_NAME": "CALVERT TRASH SYSTEMS"
            },
            {
              "VENDOR_NAME": "CALVIN B. DOAN & SON"
            },
            {
              "VENDOR_NAME": "CALVIN E. POWELL"
            },
            {
              "VENDOR_NAME": "CAMBRIDGE EXECUTIVE ENTERPRISES"
            },
            {
              "VENDOR_NAME": "CAMBRIDGE SOUND MANAGEMENT LLC"
            },
            {
              "VENDOR_NAME": "CAMDEN CNTY SPEC. CIVIL PART"
            },
            {
              "VENDOR_NAME": "CAMDEN CO BOARD OF SOCIAL SER"
            },
            {
              "VENDOR_NAME": "CAMDEN CO DIV OF HEALTH"
            },
            {
              "VENDOR_NAME": "CAMDEN CO SHERIFF'S OFFICE"
            },
            {
              "VENDOR_NAME": "CAMDEN CO SHERIFF'S OFFICE-RM200"
            },
            {
              "VENDOR_NAME": "CAMDEN CO. SHERIFF'S OFFICE"
            },
            {
              "VENDOR_NAME": "CAMDEN COUNTY MUNICIPAL"
            },
            {
              "VENDOR_NAME": "CAMDEN COUNTY PARKS DEPARTMENT"
            },
            {
              "VENDOR_NAME": "CAMDEN COUNTY SHERIFF"
            },
            {
              "VENDOR_NAME": "CAMDEN COUNTY SHERIFF'S OFFICE"
            },
            {
              "VENDOR_NAME": "CAMDEN COUNTY SOIL"
            },
            {
              "VENDOR_NAME": "CAMDEN COUNTY TREASURER"
            },
            {
              "VENDOR_NAME": "CAMDEN CTY MUNICIPAL UTIL"
            },
            {
              "VENDOR_NAME": "CAMERON HANOVER"
            },
            {
              "VENDOR_NAME": "CAMILLE VOLPI AND MARY VOLPI"
            },
            {
              "VENDOR_NAME": "CAMIN CARGO CONTROL"
            },
            {
              "VENDOR_NAME": "CAMLOK SYSTEMS"
            },
            {
              "VENDOR_NAME": "CAMP DREAMCATCHER"
            },
            {
              "VENDOR_NAME": "CAMP HORSESHOE"
            },
            {
              "VENDOR_NAME": "CAMPBELL & NOLAN ASSOC.INC."
            },
            {
              "VENDOR_NAME": "CAMPBELL NEWS SERVICE"
            },
            {
              "VENDOR_NAME": "CAMPBELL SOUP COMPANY"
            },
            {
              "VENDOR_NAME": "CAMPISE REPORTING"
            },
            {
              "VENDOR_NAME": "CAMPUS HILLS WATER WORKS"
            },
            {
              "VENDOR_NAME": "CANADA DRY BOTTLING OF NY"
            },
            {
              "VENDOR_NAME": "CANADA DRY BTLG ATL CITY"
            },
            {
              "VENDOR_NAME": "CANADA DRY DEL. VALLEY"
            },
            {
              "VENDOR_NAME": "CANADA DRY OF POTOMAC"
            },
            {
              "VENDOR_NAME": "CANADA DRY POTOMAC CORP"
            },
            {
              "VENDOR_NAME": "CANADA DRY SALISBURY"
            },
            {
              "VENDOR_NAME": "CANADA DRY SCRANTON"
            },
            {
              "VENDOR_NAME": "CANADA DRY-ASBURY"
            },
            {
              "VENDOR_NAME": "Canadian Test Vendor"
            },
            {
              "VENDOR_NAME": "CANDYRIFIC"
            },
            {
              "VENDOR_NAME": "CANINE HEARING COMPANIONS INC"
            },
            {
              "VENDOR_NAME": "CANNON EQUIPMENT"
            },
            {
              "VENDOR_NAME": "CANNON INVESTMENT INC"
            },
            {
              "VENDOR_NAME": "CANON BUSINESS SOLUTIONS-EAST"
            },
            {
              "VENDOR_NAME": "CANON FINANCIAL SERVICES INC"
            },
            {
              "VENDOR_NAME": "CANTERBURY SQUARE"
            },
            {
              "VENDOR_NAME": "CAP GEMINI ERNST & YOUNG"
            },
            {
              "VENDOR_NAME": "CAP INDEX INC."
            },
            {
              "VENDOR_NAME": "CAP OF KENT COUNTY"
            },
            {
              "VENDOR_NAME": "CAPE"
            },
            {
              "VENDOR_NAME": "CAPE ATLANTIC"
            },
            {
              "VENDOR_NAME": "CAPE EXPRESS SOCCER CLUB"
            },
            {
              "VENDOR_NAME": "CAPE GAZETTE"
            },
            {
              "VENDOR_NAME": "CAPE MAY CO COURTHOUSE"
            },
            {
              "VENDOR_NAME": "CAPE MAY CO. SHERRIFFIS OFFICE"
            },
            {
              "VENDOR_NAME": "CAPE MAY CO.SHERIFF'S OFFICE"
            },
            {
              "VENDOR_NAME": "CAPE MAY CO.SPECIAL SERV."
            },
            {
              "VENDOR_NAME": "CAPE MAY COUNTY HEALTH DEPT."
            },
            {
              "VENDOR_NAME": "CAPE MAY COUNTY PARK & ZOO"
            },
            {
              "VENDOR_NAME": "CAPE MAY COUNTY SHERIFF'S OFFICE"
            },
            {
              "VENDOR_NAME": "CAPE MAY CTY. SHERIFF OFFICE"
            },
            {
              "VENDOR_NAME": "CAPE MAY SHERIFF'S OFFICE"
            },
            {
              "VENDOR_NAME": "CAPE VOLUNTEERS IN MEDICINE"
            },
            {
              "VENDOR_NAME": "CAPE WINDS FLORIST"
            },
            {
              "VENDOR_NAME": "CAPITAL - GAZETTE - MARYLAND"
            },
            {
              "VENDOR_NAME": "CAPITAL CREDIT SERVICES"
            },
            {
              "VENDOR_NAME": "CAPITAL GAZETTE NEWSPAPERS"
            },
            {
              "VENDOR_NAME": "CAPITAL HOME CARE & RENOVATIONS"
            },
            {
              "VENDOR_NAME": "CAPITAL MANUFACTURING"
            },
            {
              "VENDOR_NAME": "CAPITAL OIL BROKERS"
            },
            {
              "VENDOR_NAME": "CAPITAL OIL BROKERS-ACCRUAL"
            },
            {
              "VENDOR_NAME": "CAPITAL ONE"
            },
            {
              "VENDOR_NAME": "CAPITAL ONE BANK"
            },
            {
              "VENDOR_NAME": "CAPITAL ONE BANK KOTY WRIGHT-9148"
            },
            {
              "VENDOR_NAME": "CAPITAL ONE SERVICES"
            },
            {
              "VENDOR_NAME": "CAPITAL REGION AIRPORT"
            },
            {
              "VENDOR_NAME": "CAPITAL REGION AIRPORT COMMISSION"
            },
            {
              "VENDOR_NAME": "CAPITALONEBANK"
            },
            {
              "VENDOR_NAME": "CAPITOL CREDIT SERVICES"
            },
            {
              "VENDOR_NAME": "CAPITOL CREDIT SVC"
            },
            {
              "VENDOR_NAME": "CAPITOL CUPS INC"
            },
            {
              "VENDOR_NAME": "CAPLAN BROS INC"
            },
            {
              "VENDOR_NAME": "CAPLUGS EAST"
            },
            {
              "VENDOR_NAME": "CAPOZZOLI CATERING"
            },
            {
              "VENDOR_NAME": "CAPRICORN ALLIED PARTNERS"
            },
            {
              "VENDOR_NAME": "CAPSICUM GROUP LLC"
            },
            {
              "VENDOR_NAME": "CARDINAL O'HARA HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "CARDINGTON HOLIDAY FUND"
            },
            {
              "VENDOR_NAME": "CARDIO-KINETICS"
            },
            {
              "VENDOR_NAME": "CARDS FOR CAUSES"
            },
            {
              "VENDOR_NAME": "CardsDirect"
            },
            {
              "VENDOR_NAME": "CAREBRIDGE CORPORATION"
            },
            {
              "VENDOR_NAME": "CAREER DEVELOPMENT CENTER"
            },
            {
              "VENDOR_NAME": "CAREERBUILDER"
            },
            {
              "VENDOR_NAME": "CAREERBUILDER"
            },
            {
              "VENDOR_NAME": "CAREERS IN FOOD.COM"
            },
            {
              "VENDOR_NAME": "CAREY L. GLENDENNING"
            },
            {
              "VENDOR_NAME": "CAREY VIP CHAUFFEURED SERVICES"
            },
            {
              "VENDOR_NAME": "CARGILL INC.-SALT DIV"
            },
            {
              "VENDOR_NAME": "CARGILL KITCHEN SOLUTIONS"
            },
            {
              "VENDOR_NAME": "CARGILL"
            },
            {
              "VENDOR_NAME": "CARGILL"
            },
            {
              "VENDOR_NAME": "CARILION HEALTHCARE CORP"
            },
            {
              "VENDOR_NAME": "CARIS DISTRIBUTORS"
            },
            {
              "VENDOR_NAME": "CARISMA"
            },
            {
              "VENDOR_NAME": "CARL A.DONATO"
            },
            {
              "VENDOR_NAME": "CARL BATES TRUSTEE"
            },
            {
              "VENDOR_NAME": "CARL M BATES TRUSTEE"
            },
            {
              "VENDOR_NAME": "CARL M. BATES CHAPTER 13 TRUSTEE"
            },
            {
              "VENDOR_NAME": "CARL M. BATES TRUSTEE"
            },
            {
              "VENDOR_NAME": "CARL M. WEST"
            },
            {
              "VENDOR_NAME": "CARL R PURSELL INC"
            },
            {
              "VENDOR_NAME": "CARL"
            },
            {
              "VENDOR_NAME": "CARLIN"
            },
            {
              "VENDOR_NAME": "CARLO EASTON"
            },
            {
              "VENDOR_NAME": "CARLOS A ALARCON"
            },
            {
              "VENDOR_NAME": "CARLSON WAGONLIT TRAVEL"
            },
            {
              "VENDOR_NAME": "CARLSON'S AUTO BODY INC"
            },
            {
              "VENDOR_NAME": "CARMEN BUZZUTO"
            },
            {
              "VENDOR_NAME": "CARMEN R FAIA CORP"
            },
            {
              "VENDOR_NAME": "CARMEN SCHIPANI"
            },
            {
              "VENDOR_NAME": "CARMEN'S AUTO BODY"
            },
            {
              "VENDOR_NAME": "CAROL DOLNICK"
            },
            {
              "VENDOR_NAME": "CAROL JENSEN"
            },
            {
              "VENDOR_NAME": "CAROL REDMOND"
            },
            {
              "VENDOR_NAME": "CAROLE S. MATTHEWS"
            },
            {
              "VENDOR_NAME": "CAROLINA FINANCE"
            },
            {
              "VENDOR_NAME": "CAROLINE FRIEL"
            },
            {
              "VENDOR_NAME": "CAROLINE GENERAL DISTRICT COURT"
            },
            {
              "VENDOR_NAME": "CAROLINE GEN'L DISTRICT COURT"
            },
            {
              "VENDOR_NAME": "CAROLINE WOOD"
            },
            {
              "VENDOR_NAME": "CAROLINE WOOD LANGAN"
            },
            {
              "VENDOR_NAME": "CAROLLO"
            },
            {
              "VENDOR_NAME": "CAROLYN BRATTON"
            },
            {
              "VENDOR_NAME": "CAROLYN DAVISON"
            },
            {
              "VENDOR_NAME": "CAROLYN M.CONEYS"
            },
            {
              "VENDOR_NAME": "CAROLYNE S. KALSON"
            },
            {
              "VENDOR_NAME": "CAROUSEL BEACHFRONT"
            },
            {
              "VENDOR_NAME": "CAROUSEL FOODS OF AMERICA"
            },
            {
              "VENDOR_NAME": "CARR & DUFF INC."
            },
            {
              "VENDOR_NAME": "CARRIE OBERHOLTZER"
            },
            {
              "VENDOR_NAME": "CARRIER & ASSOC REALTORS"
            },
            {
              "VENDOR_NAME": "CARRIER CORPORATION"
            },
            {
              "VENDOR_NAME": "CARROCCIA COMPANY INC."
            },
            {
              "VENDOR_NAME": "CARROLL COUNTY BUREAU OF PERMITS"
            },
            {
              "VENDOR_NAME": "CARROLL COUNTY COMMISSIONERS"
            },
            {
              "VENDOR_NAME": "CARROLL COUNTY TIMES"
            },
            {
              "VENDOR_NAME": "CARROLL ENGINEERING CORPORATION"
            },
            {
              "VENDOR_NAME": "CARROLL J & JOAN C STRONG"
            },
            {
              "VENDOR_NAME": "CARROLL STURGIS"
            },
            {
              "VENDOR_NAME": "CARS FINANCIAL"
            },
            {
              "VENDOR_NAME": "CARSON"
            },
            {
              "VENDOR_NAME": "CARTER & ASSOCIATES"
            },
            {
              "VENDOR_NAME": "CARTER CASKET CO."
            },
            {
              "VENDOR_NAME": "CARUSO & MARTIN PAVING"
            },
            {
              "VENDOR_NAME": "CASA"
            },
            {
              "VENDOR_NAME": "CASH 2 U PAYDAY LOAN"
            },
            {
              "VENDOR_NAME": "CASH 2-U PAYDAY LOANS"
            },
            {
              "VENDOR_NAME": "CASH ADVANCE CENTER"
            },
            {
              "VENDOR_NAME": "CASH ADVANCE CTR OF VIRGINIA INC"
            },
            {
              "VENDOR_NAME": "CASH EXPRESS"
            },
            {
              "VENDOR_NAME": "CASH EXPRESS OF VIRGINIA"
            },
            {
              "VENDOR_NAME": "CASH EXPRESS OF VIRGINIA BEACH"
            },
            {
              "VENDOR_NAME": "CASH NET INC."
            },
            {
              "VENDOR_NAME": "CASH SOLUTIONS"
            },
            {
              "VENDOR_NAME": "CASH-N-GO"
            },
            {
              "VENDOR_NAME": "CASHWELL FINANCIAL OF VA."
            },
            {
              "VENDOR_NAME": "CASSIDY VENDING COMPANY"
            },
            {
              "VENDOR_NAME": "CASSIS FAMILY CORPORATION"
            },
            {
              "VENDOR_NAME": "CASSONE'S BAKERY INC"
            },
            {
              "VENDOR_NAME": "CASSVILLE FIRE COMPANY"
            },
            {
              "VENDOR_NAME": "CASTLE SELF STORAGE"
            },
            {
              "VENDOR_NAME": "CATALYST INTERNATIONAL INC."
            },
            {
              "VENDOR_NAME": "CATASAUQUA WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "CATEGORY 6"
            },
            {
              "VENDOR_NAME": "CATERING BY JESSICA LEE"
            },
            {
              "VENDOR_NAME": "CATERING BY MASSO'S"
            },
            {
              "VENDOR_NAME": "CATHERINE A.SAVITZ"
            },
            {
              "VENDOR_NAME": "CATHERINE D.TYLUS SCHOLARSHIP FUND"
            },
            {
              "VENDOR_NAME": "CATHERINE PULOS"
            },
            {
              "VENDOR_NAME": "CATHERINE T.PULOS"
            },
            {
              "VENDOR_NAME": "CATHOLIC CHARITIES"
            },
            {
              "VENDOR_NAME": "CATHY LANDHERR-BOTTI"
            },
            {
              "VENDOR_NAME": "CATHY NOLAN"
            },
            {
              "VENDOR_NAME": "CATHY RESSLER"
            },
            {
              "VENDOR_NAME": "CAVAN SOCIETY"
            },
            {
              "VENDOR_NAME": "CB RICHARD ELLIS"
            },
            {
              "VENDOR_NAME": "CBE GROUP INC"
            },
            {
              "VENDOR_NAME": "CBS TELEVISION STATIONS"
            },
            {
              "VENDOR_NAME": "CBS"
            },
            {
              "VENDOR_NAME": "CBT NUGGETS LLC"
            },
            {
              "VENDOR_NAME": "CCC HEAVY DUTY TRUCK"
            },
            {
              "VENDOR_NAME": "CCH INC."
            },
            {
              "VENDOR_NAME": "CCH INCORPORATED"
            },
            {
              "VENDOR_NAME": "CCIM INSTITUTE"
            },
            {
              "VENDOR_NAME": "CCR"
            },
            {
              "VENDOR_NAME": "CDA ENGINEERING"
            },
            {
              "VENDOR_NAME": "CDF INDUSTRIES"
            },
            {
              "VENDOR_NAME": "CDMAN OPTICAL DISCS LTD."
            },
            {
              "VENDOR_NAME": "CDR ASSESSMENT GROUP"
            },
            {
              "VENDOR_NAME": "CDW COMPUTER CENTERS"
            },
            {
              "VENDOR_NAME": "CECIL CANADY COURT OFFICER"
            },
            {
              "VENDOR_NAME": "CECIL CANADY OFFICER"
            },
            {
              "VENDOR_NAME": "CECIL CANADY SCNJ"
            },
            {
              "VENDOR_NAME": "CECIL CANADY SR COURT OFFICER"
            },
            {
              "VENDOR_NAME": "CECIL CANADY SR. COURT OFFICER"
            },
            {
              "VENDOR_NAME": "CECIL COUNTY COMMISSIONERS"
            },
            {
              "VENDOR_NAME": "CECIL COUNTY HEALTH DEPT."
            },
            {
              "VENDOR_NAME": "CECIL COUNTY SOIL"
            },
            {
              "VENDOR_NAME": "CECIL COUNTY TREASURER'S OFFICE"
            },
            {
              "VENDOR_NAME": "CECIL E CANADY"
            },
            {
              "VENDOR_NAME": "CECIL E. CANADY COURT OFFICER"
            },
            {
              "VENDOR_NAME": "CECILIA R. HAYWOOD"
            },
            {
              "VENDOR_NAME": "CECILWARE CORPORATION"
            },
            {
              "VENDOR_NAME": "CEDAR LANE ASSOCIATES"
            },
            {
              "VENDOR_NAME": "CEDE CANDY INC."
            },
            {
              "VENDOR_NAME": "CELLEBRITE USA CORP."
            },
            {
              "VENDOR_NAME": "CELLULAR CONCEPTS"
            },
            {
              "VENDOR_NAME": "CENEX"
            },
            {
              "VENDOR_NAME": "CENEX- ACCRUEL"
            },
            {
              "VENDOR_NAME": "CENOVA"
            },
            {
              "VENDOR_NAME": "CENTENNIAL COMPANIES"
            },
            {
              "VENDOR_NAME": "CENTER CITY NEWSPAPER DISTRIBUTORS"
            },
            {
              "VENDOR_NAME": "CENTER CITY PROPRIETORS ASSOC."
            },
            {
              "VENDOR_NAME": "CENTER CITY RESIDENTS ASSOC."
            },
            {
              "VENDOR_NAME": "CENTER DISTRIBUTORS"
            },
            {
              "VENDOR_NAME": "CENTER FOR CREATIVE LEADERSHIP"
            },
            {
              "VENDOR_NAME": "CENTER FOR NETWORKS & ED."
            },
            {
              "VENDOR_NAME": "CENTER MARKETING COMPANY"
            },
            {
              "VENDOR_NAME": "CENTER OIL COMPANY"
            },
            {
              "VENDOR_NAME": "CENTER POINT TANK SERVICES"
            },
            {
              "VENDOR_NAME": "CENTER POINT TERMINAL BALTIMORE"
            },
            {
              "VENDOR_NAME": "CENTER SQUARE ASSOCIATES"
            },
            {
              "VENDOR_NAME": "CENTER STAGE PRODUCTIONS"
            },
            {
              "VENDOR_NAME": "CENTER TERMINAL COMPANY"
            },
            {
              "VENDOR_NAME": "CENTERLEN SERVICES"
            },
            {
              "VENDOR_NAME": "CENTERPLATE"
            },
            {
              "VENDOR_NAME": "CENTIMARK CORP."
            },
            {
              "VENDOR_NAME": "CENTRAL ATL STATES ASSOC"
            },
            {
              "VENDOR_NAME": "CENTRAL BUCKS ATHLETIC ASSOCIATION"
            },
            {
              "VENDOR_NAME": "CENTRAL BUCKS HEALTHIER"
            },
            {
              "VENDOR_NAME": "CENTRAL BUCKS SCHOOL DIST"
            },
            {
              "VENDOR_NAME": "CENTRAL BUCKS SCHOOL DISTRICT"
            },
            {
              "VENDOR_NAME": "CENTRAL BUCKS SD/ BUCKINGHAM TWP"
            },
            {
              "VENDOR_NAME": "CENTRAL CHESTERFIELD LITTLE LEAGUE"
            },
            {
              "VENDOR_NAME": "CENTRAL COLLECTIONS BUREAU"
            },
            {
              "VENDOR_NAME": "CENTRAL JERSEY SIGNS"
            },
            {
              "VENDOR_NAME": "CENTRAL MONTGOMERY HEALTH"
            },
            {
              "VENDOR_NAME": "CENTRAL PARK 1224"
            },
            {
              "VENDOR_NAME": "CENTRAL PARKING SYSTEM"
            },
            {
              "VENDOR_NAME": "CENTRAL PENN GAS"
            },
            {
              "VENDOR_NAME": "CENTRAL RECORDS PUBLICATIONS"
            },
            {
              "VENDOR_NAME": "CENTRAL RESEARCH"
            },
            {
              "VENDOR_NAME": "CENTRAL SIGN SYSTEMS"
            },
            {
              "VENDOR_NAME": "CENTRAL TAX BUREAU OF PA"
            },
            {
              "VENDOR_NAME": "CENTRAL TAX BUREAU OF PA"
            },
            {
              "VENDOR_NAME": "CENTRAL TAX BUREAU OF PA."
            },
            {
              "VENDOR_NAME": "CENTRAL VIRGINIA FOOD BANK"
            },
            {
              "VENDOR_NAME": "CENTRAL VIRGINIA NEW AGENCY"
            },
            {
              "VENDOR_NAME": "CENTRAL VIRGINIA ORTHOPEDICS"
            },
            {
              "VENDOR_NAME": "CENTRE CITY SCHOOL"
            },
            {
              "VENDOR_NAME": "CENTRISERV"
            },
            {
              "VENDOR_NAME": "CENTRO WATT OPERATING"
            },
            {
              "VENDOR_NAME": "CENTURY 21/WILGUS ASSOCIATES"
            },
            {
              "VENDOR_NAME": "CENTURY21 PARADE OF HOMES"
            },
            {
              "VENDOR_NAME": "CENTURYLINK"
            },
            {
              "VENDOR_NAME": "CERIDIAN STORED VALUE"
            },
            {
              "VENDOR_NAME": "CERTEGY CHECK SERVICES"
            },
            {
              "VENDOR_NAME": "CERULLO FIRE PROTECTION"
            },
            {
              "VENDOR_NAME": "CFC INC."
            },
            {
              "VENDOR_NAME": "CFDC"
            },
            {
              "VENDOR_NAME": "CFO SUMMIT"
            },
            {
              "VENDOR_NAME": "CGE CORPORATION"
            },
            {
              "VENDOR_NAME": "CGI COMMUNICATIONS"
            },
            {
              "VENDOR_NAME": "CHAD GAGNON"
            },
            {
              "VENDOR_NAME": "CHAD UNGER"
            },
            {
              "VENDOR_NAME": "CHADDS FORD TOWNSHIP"
            },
            {
              "VENDOR_NAME": "CHADMARK ASSOCIATES"
            },
            {
              "VENDOR_NAME": "CHALFONT-NEW BRITAIN TWP"
            },
            {
              "VENDOR_NAME": "CHALMERS & KUBECK"
            },
            {
              "VENDOR_NAME": "CHAMBER OF COMMERCE"
            },
            {
              "VENDOR_NAME": "CHAMBERS MOTORS"
            },
            {
              "VENDOR_NAME": "CHAMP ONE INCORPORATED"
            },
            {
              "VENDOR_NAME": "CHAMPION AMERICA"
            },
            {
              "VENDOR_NAME": "CHAMPION SERVICES II INC"
            },
            {
              "VENDOR_NAME": "CHANCO ON THE JAMES"
            },
            {
              "VENDOR_NAME": "CHAPMAN AND CUTLER"
            },
            {
              "VENDOR_NAME": "CHARLES A. HIGGINS & SONS"
            },
            {
              "VENDOR_NAME": "CHARLES BLOSENSKI"
            },
            {
              "VENDOR_NAME": "CHARLES BUSH"
            },
            {
              "VENDOR_NAME": "CHARLES C SAVAGE"
            },
            {
              "VENDOR_NAME": "CHARLES COUNTY BOARD OF HEALTH"
            },
            {
              "VENDOR_NAME": "CHARLES COUNTY CHAMBER OF COMMERCE"
            },
            {
              "VENDOR_NAME": "CHARLES COUNTY GOVERNMENT"
            },
            {
              "VENDOR_NAME": "CHARLES COUNTY GOV'T"
            },
            {
              "VENDOR_NAME": "CHARLES COUNTY SHOP WITH A COP INC"
            },
            {
              "VENDOR_NAME": "CHARLES COUNTY TREASURER"
            },
            {
              "VENDOR_NAME": "CHARLES D.CROWSON"
            },
            {
              "VENDOR_NAME": "CHARLES FISHER"
            },
            {
              "VENDOR_NAME": "CHARLES FORD"
            },
            {
              "VENDOR_NAME": "CHARLES H DAGER"
            },
            {
              "VENDOR_NAME": "CHARLES HILDENBRAND"
            },
            {
              "VENDOR_NAME": "CHARLES J & CAROLINE LABIN"
            },
            {
              "VENDOR_NAME": "CHARLES J &CAROLINE LABIN"
            },
            {
              "VENDOR_NAME": "CHARLES J BONSANGUE"
            },
            {
              "VENDOR_NAME": "CHARLES J.CATANIA"
            },
            {
              "VENDOR_NAME": "CHARLES JACKSON"
            },
            {
              "VENDOR_NAME": "CHARLES KENNEDY"
            },
            {
              "VENDOR_NAME": "CHARLES KNOTHE"
            },
            {
              "VENDOR_NAME": "CHARLES KNOTHE ESQ"
            },
            {
              "VENDOR_NAME": "CHARLES KNOTHE P.A."
            },
            {
              "VENDOR_NAME": "CHARLES KNOTHE"
            },
            {
              "VENDOR_NAME": "CHARLES KNOTHE"
            },
            {
              "VENDOR_NAME": "CHARLES KNOTHE"
            },
            {
              "VENDOR_NAME": "CHARLES LEVY CIRCULATING CO"
            },
            {
              "VENDOR_NAME": "CHARLES MARLIN"
            },
            {
              "VENDOR_NAME": "CHARLES MOORE"
            },
            {
              "VENDOR_NAME": "CHARLES MORELLI"
            },
            {
              "VENDOR_NAME": "CHARLES NELSON"
            },
            {
              "VENDOR_NAME": "CHARLES PORRETTA"
            },
            {
              "VENDOR_NAME": "CHARLES R MEYERS JR"
            },
            {
              "VENDOR_NAME": "CHARLES R.MEYERS"
            },
            {
              "VENDOR_NAME": "CHARLES RICHARD COHEN"
            },
            {
              "VENDOR_NAME": "CHARLES S KNOTHE P.A."
            },
            {
              "VENDOR_NAME": "CHARLES S. KNOTHE ESQ."
            },
            {
              "VENDOR_NAME": "CHARLES S. KNOTHE"
            },
            {
              "VENDOR_NAME": "CHARLES S. RONEY"
            },
            {
              "VENDOR_NAME": "CHARLES T. KING"
            },
            {
              "VENDOR_NAME": "CHARLES V OR MARGARET W"
            },
            {
              "VENDOR_NAME": "CHARLES WISE"
            },
            {
              "VENDOR_NAME": "CHARLESTOWN PAVING &"
            },
            {
              "VENDOR_NAME": "CHARLEY'S OTHER BROTHER"
            },
            {
              "VENDOR_NAME": "CHARLIE & SON TRASH"
            },
            {
              "VENDOR_NAME": "CHARLIE'S TRANSMISSION SERVICE"
            },
            {
              "VENDOR_NAME": "CHARLOTTE A.NICOTERA-TAX COLLECTOR"
            },
            {
              "VENDOR_NAME": "CHARLOTTE HALL"
            },
            {
              "VENDOR_NAME": "CHARLOTTE SIMPSON"
            },
            {
              "VENDOR_NAME": "CHARLOTTE WAHLER"
            },
            {
              "VENDOR_NAME": "CHARM SCIENCES"
            },
            {
              "VENDOR_NAME": "CHARMS"
            },
            {
              "VENDOR_NAME": "CHARTER TRIAD TERMINALS"
            },
            {
              "VENDOR_NAME": "CHARTERHOUSE LEARNING"
            },
            {
              "VENDOR_NAME": "CHARTIS CLAIMS"
            },
            {
              "VENDOR_NAME": "CHARTWAY FED'L CREDIT UNION"
            },
            {
              "VENDOR_NAME": "CHAS. A. LIMBURG"
            },
            {
              "VENDOR_NAME": "CHASE AUTOMOTIVE FINANCE"
            },
            {
              "VENDOR_NAME": "CHASE BANK USA"
            },
            {
              "VENDOR_NAME": "CHASE CENTER ON THE RIVERFRONT"
            },
            {
              "VENDOR_NAME": "CHASE INDUSTRIES"
            },
            {
              "VENDOR_NAME": "CHATEAU APT. CO."
            },
            {
              "VENDOR_NAME": "CHECK CITY"
            },
            {
              "VENDOR_NAME": "CHECK FIRST INC."
            },
            {
              "VENDOR_NAME": "CHELTENHAM TOWNSHIP"
            },
            {
              "VENDOR_NAME": "CHELTENHAM TWP FINANCE OFFICER"
            },
            {
              "VENDOR_NAME": "CHELTENHAM TWP WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "CHEMSEARCH"
            },
            {
              "VENDOR_NAME": "CHEMSTAR CORPORATION"
            },
            {
              "VENDOR_NAME": "CHEMSTATION"
            },
            {
              "VENDOR_NAME": "CHEP USA"
            },
            {
              "VENDOR_NAME": "CHEQUE CASHING INC."
            },
            {
              "VENDOR_NAME": "CHEROKEE JOINT VENTURE"
            },
            {
              "VENDOR_NAME": "CHERRY HILL FIRE DEPT."
            },
            {
              "VENDOR_NAME": "CHERRY HILL MIDGET FOOTBALL"
            },
            {
              "VENDOR_NAME": "CHERRY HILL PHOTO"
            },
            {
              "VENDOR_NAME": "CHERRY HILL REGIONAL"
            },
            {
              "VENDOR_NAME": "CHERRY HILL YOUTH ATHLETIC ASSOC."
            },
            {
              "VENDOR_NAME": "CHERYL FARLEY"
            },
            {
              "VENDOR_NAME": "CHERYL JOSEPH"
            },
            {
              "VENDOR_NAME": "CHERYL K.GRIFFITH"
            },
            {
              "VENDOR_NAME": "CHERYL LOWE"
            },
            {
              "VENDOR_NAME": "CHERYL SUMMERFELT"
            },
            {
              "VENDOR_NAME": "CHERYL'S CHALETS"
            },
            {
              "VENDOR_NAME": "CHESAPEAKE BAY GOLF CLUB"
            },
            {
              "VENDOR_NAME": "CHESAPEAKE BAY STORAGE LLC"
            },
            {
              "VENDOR_NAME": "CHESAPEAKE COMMISSIONER OF REVENUE"
            },
            {
              "VENDOR_NAME": "CHESAPEAKE ENVIROMENTAL LAB"
            },
            {
              "VENDOR_NAME": "CHESAPEAKE HEALTH"
            },
            {
              "VENDOR_NAME": "CHESAPEAKE PUBLISHING CORP."
            },
            {
              "VENDOR_NAME": "CHESAPEAKE TREASURER"
            },
            {
              "VENDOR_NAME": "CHESAPEAKE TRESURER"
            },
            {
              "VENDOR_NAME": "CHESAPEAKE UTILITIES SYST"
            },
            {
              "VENDOR_NAME": "CHESBAY DISTRIBU"
            },
            {
              "VENDOR_NAME": "CHESTER BOYS AND GIRLS CLUB"
            },
            {
              "VENDOR_NAME": "CHESTER CADIEUX"
            },
            {
              "VENDOR_NAME": "CHESTER CO TREASURER"
            },
            {
              "VENDOR_NAME": "CHESTER COUNTY"
            },
            {
              "VENDOR_NAME": "CHESTER COUNTY ART ASSOCIATION"
            },
            {
              "VENDOR_NAME": "CHESTER COUNTY BAR ASSOCIATION"
            },
            {
              "VENDOR_NAME": "CHESTER COUNTY CHAMBER OF COMMERCE"
            },
            {
              "VENDOR_NAME": "CHESTER COUNTY COUNCIL"
            },
            {
              "VENDOR_NAME": "CHESTER COUNTY ECONOMIC DEVEL.COUNC"
            },
            {
              "VENDOR_NAME": "CHESTER COUNTY HEROES FUND"
            },
            {
              "VENDOR_NAME": "CHESTER COUNTY HISTORICAL SOCIETY"
            },
            {
              "VENDOR_NAME": "CHESTER COUNTY SPCA"
            },
            {
              "VENDOR_NAME": "CHESTER COUNTY TIMBER INC"
            },
            {
              "VENDOR_NAME": "CHESTER COUNTY WOMEN"
            },
            {
              "VENDOR_NAME": "CHESTER CTY HEALTH DEPT"
            },
            {
              "VENDOR_NAME": "CHESTER CTY.CHAMBER OF BUSINESS & I"
            },
            {
              "VENDOR_NAME": "CHESTER HEIGHTS FIRE DEPT"
            },
            {
              "VENDOR_NAME": "CHESTER WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "CHESTER WATER AUTHORITY"
            },
            {
              "VENDOR_NAME": "CHESTERFIELD"
            },
            {
              "VENDOR_NAME": "CHESTERFIELD CIRCUIT COURT"
            },
            {
              "VENDOR_NAME": "CHESTERFIELD CO GEN'L DIST"
            },
            {
              "VENDOR_NAME": "CHESTERFIELD CO TREASURER"
            },
            {
              "VENDOR_NAME": "CHESTERFIELD COUNTY"
            },
            {
              "VENDOR_NAME": "CHESTERFIELD COUNTY CHAMBER OF COMM"
            },
            {
              "VENDOR_NAME": "CHESTERFIELD COUNTY GEN DIST CT"
            },
            {
              "VENDOR_NAME": "CHESTERFIELD COUNTY TREASURER"
            },
            {
              "VENDOR_NAME": "CHESTERFIELD COUNTY VIRGINIA"
            },
            {
              "VENDOR_NAME": "CHESTERFIELD DISTRICT COURT"
            },
            {
              "VENDOR_NAME": "CHESTERFIELD FED'L CREDIT UNION"
            },
            {
              "VENDOR_NAME": "CHESTERFIELD GEN'L DIST. COURT"
            },
            {
              "VENDOR_NAME": "CHESTERFIELD JDR COURT"
            },
            {
              "VENDOR_NAME": "CHESTERFIELD JDR DIST.COURT"
            },
            {
              "VENDOR_NAME": "CHESTERFIELD JDR DISTRICT COURT"
            },
            {
              "VENDOR_NAME": "CHESTERFIELD TWP TAX"
            },
            {
              "VENDOR_NAME": "CHESTNUT HILL DEV GR"
            },
            {
              "VENDOR_NAME": "CHESTNUT HILL LOCAL"
            },
            {
              "VENDOR_NAME": "CHESTNUT HILL PARK FOUND"
            },
            {
              "VENDOR_NAME": "CHESTNUTHILL TWP INCOME"
            },
            {
              "VENDOR_NAME": "CHEVRON PRODUCTS"
            },
            {
              "VENDOR_NAME": "CHEW CO."
            },
            {
              "VENDOR_NAME": "CHEYNEY ALUMNI SCHOLARSHIP FUND"
            },
            {
              "VENDOR_NAME": "CHEYNEY UNIVERSITY OF PENNSYLVANIA"
            },
            {
              "VENDOR_NAME": "CHIANTI CHEESE COMPANY"
            },
            {
              "VENDOR_NAME": "CHICAGO PAPER TESTING"
            },
            {
              "VENDOR_NAME": "CHICAGO SUN-TIMES"
            },
            {
              "VENDOR_NAME": "CHICHESTER FOR SENATE"
            },
            {
              "VENDOR_NAME": "CHICHESTER ROTARY"
            },
            {
              "VENDOR_NAME": "CHICHESTER SCHOOL DISTRICT"
            },
            {
              "VENDOR_NAME": "CHILD SUP.SERV. FAMILY SUP REG"
            },
            {
              "VENDOR_NAME": "CHILD SUPPORT ENFORCEMENT"
            },
            {
              "VENDOR_NAME": "CHILD SUPPORT SERVICES"
            },
            {
              "VENDOR_NAME": "CHILD SUPPORT SERVICES FAMILY SUPP"
            },
            {
              "VENDOR_NAME": "CHILD SUPPORT SERVICES ORS"
            },
            {
              "VENDOR_NAME": "CHILD SUPPORT SERVICES"
            },
            {
              "VENDOR_NAME": "CHILDHOOD LEUKEMIA FOUNDATION"
            },
            {
              "VENDOR_NAME": "CHILDREN AND ADULT DISABILITY AND"
            },
            {
              "VENDOR_NAME": "CHILDREN FIRST AMERICA"
            },
            {
              "VENDOR_NAME": "CHILDREN OF BELLEVUE"
            },
            {
              "VENDOR_NAME": "CHILDREN OF TARA M MILLIGAN"
            },
            {
              "VENDOR_NAME": "CHILDREN"
            },
            {
              "VENDOR_NAME": "CHILDREN'S CRISIS TREATMENT CENTER"
            },
            {
              "VENDOR_NAME": "CHILDREN'S HOSPITAL"
            },
            {
              "VENDOR_NAME": "CHILDREN'S HOSPITAL FOUNDATION"
            },
            {
              "VENDOR_NAME": "CHILDREN'S HOSPITAL OF PHILADELPHIA"
            },
            {
              "VENDOR_NAME": "CHILDRENS MIRACLE NETWORK"
            },
            {
              "VENDOR_NAME": "CHILDREN'S SCHOLARSHIP FUND-PHILADE"
            },
            {
              "VENDOR_NAME": "CHILDREN'S VILLAGE"
            },
            {
              "VENDOR_NAME": "CHINESE AMERICAN INT'L SCHOOL"
            },
            {
              "VENDOR_NAME": "CHIPPENHAM & JOHNSTON-WILLIS HOSP"
            },
            {
              "VENDOR_NAME": "CHOICE FIRST DISTRIBUTION"
            },
            {
              "VENDOR_NAME": "CHRIS COPPOLA"
            },
            {
              "VENDOR_NAME": "CHRIS GHEYSENS"
            },
            {
              "VENDOR_NAME": "CHRIS LETTS"
            },
            {
              "VENDOR_NAME": "CHRISSIE CALLAHAN FUND"
            },
            {
              "VENDOR_NAME": "CHRIST CHILD SOCIETY"
            },
            {
              "VENDOR_NAME": "CHRISTIAN ALEXANDER"
            },
            {
              "VENDOR_NAME": "CHRISTIANA CARA HEALTH SERVICE"
            },
            {
              "VENDOR_NAME": "CHRISTIANA CARE FOUNDATION"
            },
            {
              "VENDOR_NAME": "CHRISTINA A MURPHY COLL"
            },
            {
              "VENDOR_NAME": "CHRISTINA L.NOLEN"
            },
            {
              "VENDOR_NAME": "CHRISTINA ROTARY CLUB"
            },
            {
              "VENDOR_NAME": "CHRISTINE GARRANT"
            },
            {
              "VENDOR_NAME": "CHRISTINE KELLY"
            },
            {
              "VENDOR_NAME": "CHRISTINE McCARTHY"
            },
            {
              "VENDOR_NAME": "CHRISTOPHER & MIGNONE WOOD"
            },
            {
              "VENDOR_NAME": "CHRISTOPHER &MIGNONE WOOD"
            },
            {
              "VENDOR_NAME": "CHRISTOPHER CONSTRUCTION CO INC"
            },
            {
              "VENDOR_NAME": "CHRISTOPHER D.WRIGHT"
            },
            {
              "VENDOR_NAME": "CHRISTOPHER MADER MEMORIAL FUND"
            },
            {
              "VENDOR_NAME": "CHRISTOPHER NEIL BUCKMASTER"
            },
            {
              "VENDOR_NAME": "CHRISTOPHER NEWPORT UNIVERSITY"
            },
            {
              "VENDOR_NAME": "CHRISTOPHER PERRY"
            },
            {
              "VENDOR_NAME": "CHRISTOPHER PHILLIPS"
            },
            {
              "VENDOR_NAME": "CHRISTOPHER T GHEYSENS"
            },
            {
              "VENDOR_NAME": "CHRISTOPHER T.GHEYSENS"
            },
            {
              "VENDOR_NAME": "CHRISTOPHER W & MIGNONE A"
            },
            {
              "VENDOR_NAME": "CHRISTOPHER W REIN"
            },
            {
              "VENDOR_NAME": "CHRISTOPHER'S LAWN SERVICE"
            },
            {
              "VENDOR_NAME": "CHRIST'S HOME"
            },
            {
              "VENDOR_NAME": "CHUCK BURNS"
            },
            {
              "VENDOR_NAME": "CHURCH & DWIGHT CO"
            },
            {
              "VENDOR_NAME": "CHURCH & DWIGHT CO."
            },
            {
              "VENDOR_NAME": "CHURCH OF THE INCARNATION"
            },
            {
              "VENDOR_NAME": "CHURCHVILLE LIGHTNING"
            },
            {
              "VENDOR_NAME": "CICONTE & WASSERMAN"
            },
            {
              "VENDOR_NAME": "CICONTE WASSERMAN AND SCERBA"
            },
            {
              "VENDOR_NAME": "CICONTE"
            },
            {
              "VENDOR_NAME": "CICONTE"
            },
            {
              "VENDOR_NAME": "CICONTE"
            },
            {
              "VENDOR_NAME": "CICONTE"
            },
            {
              "VENDOR_NAME": "CICONTE"
            },
            {
              "VENDOR_NAME": "CICONTE'S ITALIA PIZZERIA"
            },
            {
              "VENDOR_NAME": "CIES"
            },
            {
              "VENDOR_NAME": "CIFALOGLIO INC."
            },
            {
              "VENDOR_NAME": "CIGARETTE F&R EDI"
            },
            {
              "VENDOR_NAME": "CINDY EMERLE"
            },
            {
              "VENDOR_NAME": "CINDY HOGGE"
            },
            {
              "VENDOR_NAME": "CINDY MERCKX"
            },
            {
              "VENDOR_NAME": "CINGULAR INTERACTIVE"
            },
            {
              "VENDOR_NAME": "CINGULAR WIRELESS"
            },
            {
              "VENDOR_NAME": "CINNAMINSON ATHLETIC ASSOCIATION"
            },
            {
              "VENDOR_NAME": "CINNAMINSON FIRE DISTRICT"
            },
            {
              "VENDOR_NAME": "CINNAMINSON HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "CINNAMINSON POLICE DEPARTMENT"
            },
            {
              "VENDOR_NAME": "CINNAMINSON SEWAGE AUTH"
            },
            {
              "VENDOR_NAME": "CINNAMINSON TOWNSHIP"
            },
            {
              "VENDOR_NAME": "CINTAS CORPORATION"
            },
            {
              "VENDOR_NAME": "CINTAS DOCUMENT MANAGEMENT"
            },
            {
              "VENDOR_NAME": "CINTAS FIRE PROTECTION LOC# D47"
            },
            {
              "VENDOR_NAME": "CINTAS FIRST AID SAFETY"
            },
            {
              "VENDOR_NAME": "CIOFFI TOWING SERVICE INC."
            },
            {
              "VENDOR_NAME": "CIRCLE COMPUTER"
            },
            {
              "VENDOR_NAME": "CIRCUIT COURT FOR DORCHESTER CTY."
            },
            {
              "VENDOR_NAME": "CIRCUS TIME AMUSEMENTS"
            },
            {
              "VENDOR_NAME": "CIRQUA CUSTOMIZED WATER"
            },
            {
              "VENDOR_NAME": "CISCO SYSTEMS"
            },
            {
              "VENDOR_NAME": "CISCO WEBEX LLC"
            },
            {
              "VENDOR_NAME": "CITADEL BROADCASTING COMPANY"
            },
            {
              "VENDOR_NAME": "CITIBANK"
            },
            {
              "VENDOR_NAME": "CITIBANK(SOUTH DAKOTA) NA"
            },
            {
              "VENDOR_NAME": "CITICORP LEASING"
            },
            {
              "VENDOR_NAME": "CITIFINANCIAL"
            },
            {
              "VENDOR_NAME": "CITIZEN ADVOCACY OF CHESTER COUNTY"
            },
            {
              "VENDOR_NAME": "CITIZEN'S CRIME COMMISSION"
            },
            {
              "VENDOR_NAME": "CITIZENS FOR CHRIS COONS"
            },
            {
              "VENDOR_NAME": "CITRIX SYSTEMS"
            },
            {
              "VENDOR_NAME": "CITY & SURBURBAN INC"
            },
            {
              "VENDOR_NAME": "CITY CLERK"
            },
            {
              "VENDOR_NAME": "CITY ICE"
            },
            {
              "VENDOR_NAME": "CITY LINE LITTLE LEAGUE"
            },
            {
              "VENDOR_NAME": "CITY OF ABERDEEN"
            },
            {
              "VENDOR_NAME": "CITY OF ABSECON"
            },
            {
              "VENDOR_NAME": "CITY OF ALLENTOWN"
            },
            {
              "VENDOR_NAME": "CITY OF BETHLEHEM"
            },
            {
              "VENDOR_NAME": "CITY OF BETHLEHEM/POLICE ROSTER"
            },
            {
              "VENDOR_NAME": "CITY OF BORDENTOWN"
            },
            {
              "VENDOR_NAME": "CITY OF BRIGANTINE"
            },
            {
              "VENDOR_NAME": "CITY OF CAMBRIDGE"
            },
            {
              "VENDOR_NAME": "CITY OF CAMDEN"
            },
            {
              "VENDOR_NAME": "CITY OF CAPE MAY"
            },
            {
              "VENDOR_NAME": "CITY OF CHESAPEAKE"
            },
            {
              "VENDOR_NAME": "CITY OF CHESAPEAKE TREASURER"
            },
            {
              "VENDOR_NAME": "CITY OF CHESAPEAKE-(2130-1910-01)"
            },
            {
              "VENDOR_NAME": "CITY OF COLLEGE PARK"
            },
            {
              "VENDOR_NAME": "CITY OF COLONIAL HEIGHTS"
            },
            {
              "VENDOR_NAME": "CITY OF DOVER"
            },
            {
              "VENDOR_NAME": "CITY OF DOVER TAXES"
            },
            {
              "VENDOR_NAME": "CITY OF EASTON"
            },
            {
              "VENDOR_NAME": "CITY OF FREDERICKSBURG"
            },
            {
              "VENDOR_NAME": "CITY OF HAMPTON"
            },
            {
              "VENDOR_NAME": "CITY OF HAMPTON OFFICE TREASURER"
            },
            {
              "VENDOR_NAME": "CITY OF HAMPTON TREASURER"
            },
            {
              "VENDOR_NAME": "CITY OF HAMPTON TREASURER OFFICE"
            },
            {
              "VENDOR_NAME": "CITY OF HAMPTON TREASURE'S OFFICE"
            },
            {
              "VENDOR_NAME": "CITY OF HAMPTON"
            },
            {
              "VENDOR_NAME": "CITY OF HAMPTON"
            },
            {
              "VENDOR_NAME": "CITY OF HOPEWELL"
            },
            {
              "VENDOR_NAME": "CITY OF HOPEWELL"
            },
            {
              "VENDOR_NAME": "CITY OF LANCASTER"
            },
            {
              "VENDOR_NAME": "CITY OF MARGATE TAXOFFICE"
            },
            {
              "VENDOR_NAME": "CITY OF MILFORD"
            },
            {
              "VENDOR_NAME": "CITY OF MILLVILLE"
            },
            {
              "VENDOR_NAME": "CITY OF NEWPORT NEWS"
            },
            {
              "VENDOR_NAME": "CITY OF NEWPORT NEWS"
            },
            {
              "VENDOR_NAME": "CITY OF NORFOLK"
            },
            {
              "VENDOR_NAME": "CITY OF NORFOLK FIRE RESCUE SERV."
            },
            {
              "VENDOR_NAME": "CITY OF NORTH WILDWOOD"
            },
            {
              "VENDOR_NAME": "CITY OF NORTHFIELD"
            },
            {
              "VENDOR_NAME": "CITY OF NORTHFIELD TAXCOL"
            },
            {
              "VENDOR_NAME": "CITY OF OCEAN CITY"
            },
            {
              "VENDOR_NAME": "CITY OF PETERSBURG"
            },
            {
              "VENDOR_NAME": "CITY OF PHILA"
            },
            {
              "VENDOR_NAME": "CITY OF PHILADELPHIA"
            },
            {
              "VENDOR_NAME": "CITY OF PHILADELPHIA WATER DEPT."
            },
            {
              "VENDOR_NAME": "CITY OF PHILADELPHIA-DEPT.OF LICENS"
            },
            {
              "VENDOR_NAME": "CITY OF PLEASANTVILLE"
            },
            {
              "VENDOR_NAME": "CITY OF PORTSMOUTH VIRGINIA"
            },
            {
              "VENDOR_NAME": "CITY OF PORTSMOUTH-PUBLIC WORKS"
            },
            {
              "VENDOR_NAME": "CITY OF READING"
            },
            {
              "VENDOR_NAME": "CITY OF RICHMOND"
            },
            {
              "VENDOR_NAME": "CITY OF RICHMOND CIVIL DIVISION"
            },
            {
              "VENDOR_NAME": "CITY OF RICHMOND GEN'L DIST COURT"
            },
            {
              "VENDOR_NAME": "CITY OF SALISBURY"
            },
            {
              "VENDOR_NAME": "CITY OF SEA ISLE"
            },
            {
              "VENDOR_NAME": "CITY OF SEA ISLE CITY"
            },
            {
              "VENDOR_NAME": "CITY OF SOMERS POINT"
            },
            {
              "VENDOR_NAME": "CITY OF SOMERS POINT SEWER AUTHORIT"
            },
            {
              "VENDOR_NAME": "CITY OF TRENTON"
            },
            {
              "VENDOR_NAME": "CITY OF VA BEACH TREASURER"
            },
            {
              "VENDOR_NAME": "CITY OF VENTNOR"
            },
            {
              "VENDOR_NAME": "CITY OF VINELAND"
            },
            {
              "VENDOR_NAME": "CITY OF VINELAND BOARD"
            },
            {
              "VENDOR_NAME": "CITY OF VIRGINIA BEACH"
            },
            {
              "VENDOR_NAME": "CITY OF VIRGINIA BEACH-DEPT.OF PLAN"
            },
            {
              "VENDOR_NAME": "CITY OF WESTMINSTER"
            },
            {
              "VENDOR_NAME": "CITY OF WILDWOOD"
            },
            {
              "VENDOR_NAME": "CITY OF WILDWOOD FIRE CODE"
            },
            {
              "VENDOR_NAME": "CITY OF WILLIAMSBURG"
            },
            {
              "VENDOR_NAME": "CITY OF WILMINGTON"
            },
            {
              "VENDOR_NAME": "CITY OF WOODBURY"
            },
            {
              "VENDOR_NAME": "CITY OF WOODBURY WTR/SWR"
            },
            {
              "VENDOR_NAME": "CITY OF YOUTH ASSOCIATION"
            },
            {
              "VENDOR_NAME": "CITY SIGN SERVICE INC"
            },
            {
              "VENDOR_NAME": "CITY TEAM MINISTRIES"
            },
            {
              "VENDOR_NAME": "CITY TREASURER"
            },
            {
              "VENDOR_NAME": "CITY TREASURER"
            },
            {
              "VENDOR_NAME": "CITY YEAR GREATER PHILADELPHIA"
            },
            {
              "VENDOR_NAME": "CITYCELEBRATIONS"
            },
            {
              "VENDOR_NAME": "CITYLINE"
            },
            {
              "VENDOR_NAME": "CIVIL SOLUTIONS A DIVISION OF"
            },
            {
              "VENDOR_NAME": "CJ FOUNDATION FOR SIDS"
            },
            {
              "VENDOR_NAME": "CJB FAMILY TRUST"
            },
            {
              "VENDOR_NAME": "CLAIRE CIBOROWSKI"
            },
            {
              "VENDOR_NAME": "CLAIRE GRAY"
            },
            {
              "VENDOR_NAME": "CLAIRE ROGERS"
            },
            {
              "VENDOR_NAME": "CLARIANT CORPORATION"
            },
            {
              "VENDOR_NAME": "CLARION HOTEL"
            },
            {
              "VENDOR_NAME": "CLARION HOTEL ATLANTIC CITY"
            },
            {
              "VENDOR_NAME": "CLARION INN WALDORF"
            },
            {
              "VENDOR_NAME": "CLARITAS INC."
            },
            {
              "VENDOR_NAME": "CLARK C KINGERY PA"
            },
            {
              "VENDOR_NAME": "CLARK C. KINGERY ESQ"
            },
            {
              "VENDOR_NAME": "CLARK C. KINGERY"
            },
            {
              "VENDOR_NAME": "CLARK CONSULTING"
            },
            {
              "VENDOR_NAME": "CLARK KINGERY"
            },
            {
              "VENDOR_NAME": "CLARK KINGERY ESQ."
            },
            {
              "VENDOR_NAME": "CLARK SERVICE & PARTS"
            },
            {
              "VENDOR_NAME": "CLARKE WHITEHILL ENT"
            },
            {
              "VENDOR_NAME": "CLASSIC CREATIONS OF TIDEWATER"
            },
            {
              "VENDOR_NAME": "CLAUDE BANKS"
            },
            {
              "VENDOR_NAME": "CLAUDIA BENJAMIN"
            },
            {
              "VENDOR_NAME": "CLAUDIO DiGIAMBERARDINO"
            },
            {
              "VENDOR_NAME": "CLAYMONT FIRE COMPANY"
            },
            {
              "VENDOR_NAME": "CLAYTON BLOCK CO"
            },
            {
              "VENDOR_NAME": "CLEAN DELAWARE"
            },
            {
              "VENDOR_NAME": "CLEAN DIESEL TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "CLEAN EARTH OF PHILADELPHIA"
            },
            {
              "VENDOR_NAME": "CLEAN RENTAL SERVICES INC"
            },
            {
              "VENDOR_NAME": "CLEAN SWEEP SPORTFISHING"
            },
            {
              "VENDOR_NAME": "CLEAN VENTURE"
            },
            {
              "VENDOR_NAME": "CLEANNET OF PHILADELPHIA"
            },
            {
              "VENDOR_NAME": "CLEAR AIR FUND"
            },
            {
              "VENDOR_NAME": "CLEAR CHANNEL"
            },
            {
              "VENDOR_NAME": "CLEAR CHANNEL BROADCASTING INC."
            },
            {
              "VENDOR_NAME": "CLEAR CHANNEL RADIO"
            },
            {
              "VENDOR_NAME": "CLEAR CHANNEL RADIO NORFOLK"
            },
            {
              "VENDOR_NAME": "CLEARINGHOUSE"
            },
            {
              "VENDOR_NAME": "CLEARINGHOUSE ATLAS #000627994800"
            },
            {
              "VENDOR_NAME": "CLEARSOURCE INC."
            },
            {
              "VENDOR_NAME": "CLEARSOURCE"
            },
            {
              "VENDOR_NAME": "CLEARVIEW REG.HIGH SCHOOL ACCT#1115"
            },
            {
              "VENDOR_NAME": "CLEARWATER TECHNOLOGY"
            },
            {
              "VENDOR_NAME": "CLEGG'S GARAGE"
            },
            {
              "VENDOR_NAME": "CLEMENS MARKET"
            },
            {
              "VENDOR_NAME": "CLEMENT COMMUNICATIONS"
            },
            {
              "VENDOR_NAME": "CLEMENT PAPPAS"
            },
            {
              "VENDOR_NAME": "CLEMMER MOVING & STORAGE"
            },
            {
              "VENDOR_NAME": "CLEMSON UNIVERSITY"
            },
            {
              "VENDOR_NAME": "CLEO COMMUNICATIONS"
            },
            {
              "VENDOR_NAME": "CLERK OF CIRCUIT COURT"
            },
            {
              "VENDOR_NAME": "CLERK OF COURT"
            },
            {
              "VENDOR_NAME": "CLERK OF PWC CIRCUIT COURT"
            },
            {
              "VENDOR_NAME": "CLERK OF THE COURT"
            },
            {
              "VENDOR_NAME": "CLERK"
            },
            {
              "VENDOR_NAME": "CLIA LABORATORY PROGRAM"
            },
            {
              "VENDOR_NAME": "CLICK HERE"
            },
            {
              "VENDOR_NAME": "CLICK IT"
            },
            {
              "VENDOR_NAME": "CLIF BAR"
            },
            {
              "VENDOR_NAME": "CLIFFSTAR CORP"
            },
            {
              "VENDOR_NAME": "CLINICAL CARE ASSOCIATES"
            },
            {
              "VENDOR_NAME": "CLINTON DEVELOPMENT CO"
            },
            {
              "VENDOR_NAME": "CLIPPER MAGAZINE"
            },
            {
              "VENDOR_NAME": "CLOVER GLASS SHOP INC"
            },
            {
              "VENDOR_NAME": "CLOVER HILL HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "CM PROPERTIES & INVESTMENTS"
            },
            {
              "VENDOR_NAME": "CM3 BUILDING SOLUTIONS"
            },
            {
              "VENDOR_NAME": "CMA / BRAND PRESENCE AND DESIGN"
            },
            {
              "VENDOR_NAME": "CMCAR"
            },
            {
              "VENDOR_NAME": "CMX INC."
            },
            {
              "VENDOR_NAME": "CNE"
            },
            {
              "VENDOR_NAME": "CNH CAPITAL"
            },
            {
              "VENDOR_NAME": "CNI SALES"
            },
            {
              "VENDOR_NAME": "COALITION FOR RESPONSIBLE TOBACCO"
            },
            {
              "VENDOR_NAME": "COAST STAR NEWSPAPERS"
            },
            {
              "VENDOR_NAME": "COASTAL COOPERATIVE OF"
            },
            {
              "VENDOR_NAME": "COASTAL COOPERATIVE OF NEW JERSEY"
            },
            {
              "VENDOR_NAME": "COASTAL REFINING AND"
            },
            {
              "VENDOR_NAME": "COASTAL SURGICAL SPECIALIST"
            },
            {
              "VENDOR_NAME": "COASTAL TOWING & RECOVERY"
            },
            {
              "VENDOR_NAME": "COASTAL TRAINING TECHNOLOGIES CORP."
            },
            {
              "VENDOR_NAME": "COATESVILLE AREA SCHOOL DISTRICT"
            },
            {
              "VENDOR_NAME": "COATESVILLE COCA-COLA INC"
            },
            {
              "VENDOR_NAME": "COATESVILLE MIDGET FOOTBALL LEAGUE"
            },
            {
              "VENDOR_NAME": "COATESVILLE WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "COATING SERVICE COMPANY INC"
            },
            {
              "VENDOR_NAME": "COBBLESTONE'S"
            },
            {
              "VENDOR_NAME": "COCA-COLA BEVERAGES FLORIDA"
            },
            {
              "VENDOR_NAME": "COCA-COLA BOTTLING CO. CONSOLIDATED"
            },
            {
              "VENDOR_NAME": "COCA-COLA COMMONWEALTH"
            },
            {
              "VENDOR_NAME": "COCA-COLA ENTERPRISES INC"
            },
            {
              "VENDOR_NAME": "COCA-COLA ENTERPRISES INC."
            },
            {
              "VENDOR_NAME": "COCA-COLA REFRESHMENTS USA"
            },
            {
              "VENDOR_NAME": "COCA-COLA USA"
            },
            {
              "VENDOR_NAME": "COCCI REALTY"
            },
            {
              "VENDOR_NAME": "CODESMITH TOOLS"
            },
            {
              "VENDOR_NAME": "COFFEE ANALYSTS"
            },
            {
              "VENDOR_NAME": "COFFEE SERV INC."
            },
            {
              "VENDOR_NAME": "COGENT COMMUNICATIONS"
            },
            {
              "VENDOR_NAME": "COGGINS WASTE MGM INC"
            },
            {
              "VENDOR_NAME": "COGNEX CORPORATION"
            },
            {
              "VENDOR_NAME": "COGNITIVE TPG"
            },
            {
              "VENDOR_NAME": "COLASURDO BUILDERS"
            },
            {
              "VENDOR_NAME": "COLD STAT REFRIGERATION"
            },
            {
              "VENDOR_NAME": "COLDWELL BANKER LARICKS REAL ESTATE"
            },
            {
              "VENDOR_NAME": "COLE TRANSCRIPTION & RECORDING SER."
            },
            {
              "VENDOR_NAME": "COLE WW PORTSMOUTH VA"
            },
            {
              "VENDOR_NAME": "COLEMAN BRANDWORX"
            },
            {
              "VENDOR_NAME": "COLE-PARMER INST. CO."
            },
            {
              "VENDOR_NAME": "COLGATE-PALMOLIVE CO."
            },
            {
              "VENDOR_NAME": "COLL OF TAXES"
            },
            {
              "VENDOR_NAME": "COLLECTION SERVICE CENTER"
            },
            {
              "VENDOR_NAME": "COLLECTION SERVICES CENTER"
            },
            {
              "VENDOR_NAME": "COLLECTION TECHNOLOGY INC"
            },
            {
              "VENDOR_NAME": "COLLECTION TECHNOLOGY"
            },
            {
              "VENDOR_NAME": "COLLECTOR OF TAXES"
            },
            {
              "VENDOR_NAME": "COLLEEN MARSINI"
            },
            {
              "VENDOR_NAME": "COLLEEN NASE COURT OFFICER"
            },
            {
              "VENDOR_NAME": "COLLEEN NASE CT. OFFICER"
            },
            {
              "VENDOR_NAME": "COLLEEN NASE SPECIAL CIVIL"
            },
            {
              "VENDOR_NAME": "COLLEEN NASE SPECIAL CIVIL PART"
            },
            {
              "VENDOR_NAME": "COLLEEN NASE SPECIAL CIVIL PT OFFIC"
            },
            {
              "VENDOR_NAME": "COLLEEN NASE SPECIAL CIVIL PT."
            },
            {
              "VENDOR_NAME": "COLLEEN NASE SPECIAL CIVILPART"
            },
            {
              "VENDOR_NAME": "COLLEGE PARK SHOPPING LLC"
            },
            {
              "VENDOR_NAME": "COLLEGEVILLE BOROUGH"
            },
            {
              "VENDOR_NAME": "COLLEGEVILLE CONVENIENCE"
            },
            {
              "VENDOR_NAME": "COLLEGEVILLE WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "COLLIERS LANARD &"
            },
            {
              "VENDOR_NAME": "COLLINGDALE ATHLETIC CLUB"
            },
            {
              "VENDOR_NAME": "COLLINGDALE AUTO BODY"
            },
            {
              "VENDOR_NAME": "COLLINGDALE AUTO BODY & REPAIRS"
            },
            {
              "VENDOR_NAME": "COLLINGDALE BOARD OF HEALTH"
            },
            {
              "VENDOR_NAME": "COLLINGS LAKE FIRE DEPT"
            },
            {
              "VENDOR_NAME": "COLLINGSWOOD ATHLETIC HOF"
            },
            {
              "VENDOR_NAME": "COLLINGSWOOD CREW CLUB"
            },
            {
              "VENDOR_NAME": "COLLINGSWOOD FIRE DEPT"
            },
            {
              "VENDOR_NAME": "COLLINGSWOOD PARTNERS"
            },
            {
              "VENDOR_NAME": "COLLINSON"
            },
            {
              "VENDOR_NAME": "COLOMBO GROUP"
            },
            {
              "VENDOR_NAME": "COLOMBO GROUP"
            },
            {
              "VENDOR_NAME": "COLONIAL ELECTRIC SUPPLY"
            },
            {
              "VENDOR_NAME": "COLONIAL FLOWERS"
            },
            {
              "VENDOR_NAME": "COLONIAL FREIGHT"
            },
            {
              "VENDOR_NAME": "COLONIAL HEIGHTS CIRCUIT COURT"
            },
            {
              "VENDOR_NAME": "COLONIAL HEIGHTS CIRCUIT CT"
            },
            {
              "VENDOR_NAME": "COLONIAL HEIGHTS COMBINED COURT"
            },
            {
              "VENDOR_NAME": "COLONIAL HEIGHTS COMBINED CT"
            },
            {
              "VENDOR_NAME": "COLONIAL HEIGHTS COMBINED CT."
            },
            {
              "VENDOR_NAME": "COLONIAL HTS COMBINED COURT"
            },
            {
              "VENDOR_NAME": "COLONIAL NEWS SERVICE"
            },
            {
              "VENDOR_NAME": "COLONIAL OIL"
            },
            {
              "VENDOR_NAME": "COLONIAL OIL INDUSTRIES"
            },
            {
              "VENDOR_NAME": "COLONIAL PIPELINE - ACCRUAL"
            },
            {
              "VENDOR_NAME": "COLONIAL SQUARE ASSOC"
            },
            {
              "VENDOR_NAME": "COLONIAL VOLUNTEER FIRE CO."
            },
            {
              "VENDOR_NAME": "COLONIAL WILLIAMSBURG COMPANY"
            },
            {
              "VENDOR_NAME": "COLONY HOUSE"
            },
            {
              "VENDOR_NAME": "COLOR REFLECTIONS"
            },
            {
              "VENDOR_NAME": "COLORADO BAKING COMPANY"
            },
            {
              "VENDOR_NAME": "COLORTYME INC"
            },
            {
              "VENDOR_NAME": "COLOURWORKS PHOTOGRAPHIC SERVICES"
            },
            {
              "VENDOR_NAME": "COLUMBIA GAS OF VIRGINIA"
            },
            {
              "VENDOR_NAME": "COLUMBUS SHOW CASE"
            },
            {
              "VENDOR_NAME": "COMARCO PRODUCTS"
            },
            {
              "VENDOR_NAME": "COMBINED INSURANCE CO OF AMERICA"
            },
            {
              "VENDOR_NAME": "COMCAST"
            },
            {
              "VENDOR_NAME": "COMCAST CABLE"
            },
            {
              "VENDOR_NAME": "COMCAST CABLE COMMUNICATIONS"
            },
            {
              "VENDOR_NAME": "COMCAST CABLE-VA."
            },
            {
              "VENDOR_NAME": "COMCAST SPECTACOR FOUNDATION"
            },
            {
              "VENDOR_NAME": "COMETS AAU"
            },
            {
              "VENDOR_NAME": "COMFORT INN"
            },
            {
              "VENDOR_NAME": "COMFORT INN - REHOBOTH BEACH"
            },
            {
              "VENDOR_NAME": "COMFORT INN & CONFERENCE CENTER"
            },
            {
              "VENDOR_NAME": "COMFORT INN & SUITES GEORGETOWN"
            },
            {
              "VENDOR_NAME": "COMFORT INN AIRPORT"
            },
            {
              "VENDOR_NAME": "COMFORT INN EDGEWOOD CONFERENCE CTR"
            },
            {
              "VENDOR_NAME": "COMFORT SUITES - INNSBROOK"
            },
            {
              "VENDOR_NAME": "COMFORT SUITES AIRPORT"
            },
            {
              "VENDOR_NAME": "COMFORT SUITES DOVER"
            },
            {
              "VENDOR_NAME": "COMFORT SUITES OCEAN CITY"
            },
            {
              "VENDOR_NAME": "COMFORT SUITES WALDORF"
            },
            {
              "VENDOR_NAME": "COMFORT TELECOMMUNICATIONS INC"
            },
            {
              "VENDOR_NAME": "COMM OF VA NEWPORT NEWS TRAFFIC"
            },
            {
              "VENDOR_NAME": "COMMERCE BANK"
            },
            {
              "VENDOR_NAME": "COMMERCIAL CARPETS OF AMERICA"
            },
            {
              "VENDOR_NAME": "COMMERCIAL COLOR"
            },
            {
              "VENDOR_NAME": "COMMERCIAL CONTRACTORS GROUP"
            },
            {
              "VENDOR_NAME": "COMMERCIAL GLASS & GLAZIN"
            },
            {
              "VENDOR_NAME": "COMMERCIAL INDUST REALTY"
            },
            {
              "VENDOR_NAME": "COMMERCIAL MAINTENANCE"
            },
            {
              "VENDOR_NAME": "COMMERCIAL PROPERTIES MANAGEMENT"
            },
            {
              "VENDOR_NAME": "COMMISSIONER OF LABOR"
            },
            {
              "VENDOR_NAME": "COMMISSIONER OF REV SERV"
            },
            {
              "VENDOR_NAME": "COMMISSIONER OF REVENUE"
            },
            {
              "VENDOR_NAME": "COMMISSIONER OF THE REVENUE"
            },
            {
              "VENDOR_NAME": "COMMISSIONER OF THE REVENUE OFFICE"
            },
            {
              "VENDOR_NAME": "COMMITTEE TO ELECT J HARKINS"
            },
            {
              "VENDOR_NAME": "COMMON OF VIRGINIA"
            },
            {
              "VENDOR_NAME": "COMMONWEALTH CONSTRUCTION CO"
            },
            {
              "VENDOR_NAME": "COMMONWEALTH LAND TITLE"
            },
            {
              "VENDOR_NAME": "COMMONWEALTH OF MASSACHUSETTES"
            },
            {
              "VENDOR_NAME": "COMMONWEALTH OF MASSACHUSETTS"
            },
            {
              "VENDOR_NAME": "COMMONWEALTH OF PA"
            },
            {
              "VENDOR_NAME": "COMMONWEALTH OF PA DEPT OF HEALTH"
            },
            {
              "VENDOR_NAME": "COMMONWEALTH OF PA"
            },
            {
              "VENDOR_NAME": "COMMONWEALTH OF PA."
            },
            {
              "VENDOR_NAME": "COMMONWEALTH OF PA. BUREAU OF COMMI"
            },
            {
              "VENDOR_NAME": "COMMONWEALTH OF PA.CLEAN WATER FUND"
            },
            {
              "VENDOR_NAME": "COMMONWEALTH OF PA.DEP"
            },
            {
              "VENDOR_NAME": "COMMONWEALTH OF PENN"
            },
            {
              "VENDOR_NAME": "COMMONWEALTH OF PENNA"
            },
            {
              "VENDOR_NAME": "COMMONWEALTH OF PENNA."
            },
            {
              "VENDOR_NAME": "COMMONWEALTH OF PENNSYLVANIA"
            },
            {
              "VENDOR_NAME": "COMMONWEALTH OF PENNSYLVANIA-DEP"
            },
            {
              "VENDOR_NAME": "COMMONWEALTH OF VA"
            },
            {
              "VENDOR_NAME": "COMMONWEALTH OF VA DEPT. OF TAXATIO"
            },
            {
              "VENDOR_NAME": "COMMONWEALTH OF VA."
            },
            {
              "VENDOR_NAME": "COMMONWEALTH OF VIRGINIA"
            },
            {
              "VENDOR_NAME": "COMMONWEALTH TELEPHONE CO"
            },
            {
              "VENDOR_NAME": "COMMONWEALTH TRAILER RENTALS"
            },
            {
              "VENDOR_NAME": "COMMONWEALTHOFPA"
            },
            {
              "VENDOR_NAME": "COMMUNICATION PAPER INC"
            },
            {
              "VENDOR_NAME": "COMMUNICATIONS SYSTEMS & SOLUTIONS"
            },
            {
              "VENDOR_NAME": "COMMUNITY COALITION ADVOCACY SLATE"
            },
            {
              "VENDOR_NAME": "COMMUNITY COLLEGE"
            },
            {
              "VENDOR_NAME": "COMMUNITY ENVIROMENTAL"
            },
            {
              "VENDOR_NAME": "COMMUNITY FOOD BANK OF NEW JERSEY"
            },
            {
              "VENDOR_NAME": "COMMUNITY LEARNING CENTER"
            },
            {
              "VENDOR_NAME": "COMMUNITY LEARNING CENTER AT ST.JAM"
            },
            {
              "VENDOR_NAME": "COMMUNITY MEDICAL CENTER"
            },
            {
              "VENDOR_NAME": "COMMUNITY VOLUNTEERS IN MEDICINE"
            },
            {
              "VENDOR_NAME": "COMMUTER SIGNS INC"
            },
            {
              "VENDOR_NAME": "COMP 3"
            },
            {
              "VENDOR_NAME": "COMP ENERGY TRADING AND"
            },
            {
              "VENDOR_NAME": "COMPACTION GROUTING SERVICES"
            },
            {
              "VENDOR_NAME": "COMPAQ COMPUTER-COMMERCIAL"
            },
            {
              "VENDOR_NAME": "COMPAQ COMPUTER-COMMERCIAL REPAIRS"
            },
            {
              "VENDOR_NAME": "COMPLETE DIESEL SERVICE"
            },
            {
              "VENDOR_NAME": "COMPREHENSIVE LEARNING CENTER"
            },
            {
              "VENDOR_NAME": "COMPRO COMPUTER SERVICES"
            },
            {
              "VENDOR_NAME": "COMPTROLLER OF MARYLAND"
            },
            {
              "VENDOR_NAME": "COMPTROLLER OF MARYLAND COMPLIANCE"
            },
            {
              "VENDOR_NAME": "COMPTROLLER OF MD"
            },
            {
              "VENDOR_NAME": "COMPTROLLER OF THE TREAS"
            },
            {
              "VENDOR_NAME": "COMPTROLLER OF THE TREASURY"
            },
            {
              "VENDOR_NAME": "COMPUSIGN AND GRAPHICS"
            },
            {
              "VENDOR_NAME": "COMPUTER AID"
            },
            {
              "VENDOR_NAME": "COMPUTER SOFTWARE PLUS"
            },
            {
              "VENDOR_NAME": "COMPUTERSHARE"
            },
            {
              "VENDOR_NAME": "COMPUTYPE INC."
            },
            {
              "VENDOR_NAME": "COMPUWARE CORPORATION"
            },
            {
              "VENDOR_NAME": "COMTROL CORPORATION"
            },
            {
              "VENDOR_NAME": "CONAGRA FOODS PACKAGED"
            },
            {
              "VENDOR_NAME": "CONAGRA-LAMB WESTON"
            },
            {
              "VENDOR_NAME": "CONCENTRA"
            },
            {
              "VENDOR_NAME": "CONCERN"
            },
            {
              "VENDOR_NAME": "CONCERT TECHNOLOGIES GROUP"
            },
            {
              "VENDOR_NAME": "CONCORD COUNTRY CLUB"
            },
            {
              "VENDOR_NAME": "CONCORD DIV/TRI SALES FINANCE LLC"
            },
            {
              "VENDOR_NAME": "CONCORD ENGINEERING GP.INC."
            },
            {
              "VENDOR_NAME": "CONCORD SEWER AUTHORITY"
            },
            {
              "VENDOR_NAME": "CONCORD TOWING SERVICE"
            },
            {
              "VENDOR_NAME": "CONCORD TOWNSHIP"
            },
            {
              "VENDOR_NAME": "CONCORD TOWNSHIP SENIOR CITIZENS CL"
            },
            {
              "VENDOR_NAME": "CONCORD TWP.SEWER AUTH."
            },
            {
              "VENDOR_NAME": "CONCORD WASTEWATER SERVIC"
            },
            {
              "VENDOR_NAME": "CONCORDVILLE FIRE & PROTECTIVE ASSN"
            },
            {
              "VENDOR_NAME": "CONCORDVILLE INN"
            },
            {
              "VENDOR_NAME": "CONDON & SKELLY"
            },
            {
              "VENDOR_NAME": "CONECTIV POWER DELIVERY"
            },
            {
              "VENDOR_NAME": "CONESTOGA BANK"
            },
            {
              "VENDOR_NAME": "CONESTOGA TELEPHONE COMPANY"
            },
            {
              "VENDOR_NAME": "CONESTOGA VAL SCHOOL DIST"
            },
            {
              "VENDOR_NAME": "CONFERENCE CENTER AT THE MARITIME I"
            },
            {
              "VENDOR_NAME": "CONFERENCE OF FOOD PROTECTION"
            },
            {
              "VENDOR_NAME": "CONNECTICUT -CCSPC"
            },
            {
              "VENDOR_NAME": "CONNECTS FEDERAL CREDIT UNION"
            },
            {
              "VENDOR_NAME": "CONNECTYOURCARE"
            },
            {
              "VENDOR_NAME": "CONNER PRINTING SERVICE"
            },
            {
              "VENDOR_NAME": "CONNEY SAFETY PRODUCTS"
            },
            {
              "VENDOR_NAME": "CONNX SOLUTIONS"
            },
            {
              "VENDOR_NAME": "CONOCO PHILLIPS - ACCRUAL"
            },
            {
              "VENDOR_NAME": "CONOCOPHILLIPS"
            },
            {
              "VENDOR_NAME": "CONOVER APPRAISAL"
            },
            {
              "VENDOR_NAME": "CONOVER APPRAISAL ASSOC."
            },
            {
              "VENDOR_NAME": "CONSERVE"
            },
            {
              "VENDOR_NAME": "CONSERVE AGENT FOR NYSHESC"
            },
            {
              "VENDOR_NAME": "CONSOLE LAW OFFICES"
            },
            {
              "VENDOR_NAME": "CONSOLIDATED CONTAINER COMPANY"
            },
            {
              "VENDOR_NAME": "CONSOLIDATED ENGINEERING SERVICES"
            },
            {
              "VENDOR_NAME": "CONSTABLE"
            },
            {
              "VENDOR_NAME": "CONSTANCE KARAKELIAN"
            },
            {
              "VENDOR_NAME": "CONSTELLATION NEW ENERGY"
            },
            {
              "VENDOR_NAME": "CONSTELLATION NewENERGY"
            },
            {
              "VENDOR_NAME": "CONSTRUCTION HARDWARE"
            },
            {
              "VENDOR_NAME": "CONSTRUCTION SPECIALTIES INC"
            },
            {
              "VENDOR_NAME": "CONSULTING AND COACHING"
            },
            {
              "VENDOR_NAME": "CONSUMER PORTFOLIO SERVICE"
            },
            {
              "VENDOR_NAME": "CONTACTLIFELINE"
            },
            {
              "VENDOR_NAME": "CONTINENTAL REALTY INC"
            },
            {
              "VENDOR_NAME": "CONTRA COSTA DEPT. OF CHILD SUPPORT"
            },
            {
              "VENDOR_NAME": "CONTROL POINT ASSOCIATES"
            },
            {
              "VENDOR_NAME": "CONTROL"
            },
            {
              "VENDOR_NAME": "CONVEYCO TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "CONVEYOR TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "ConWa PROPERTY II"
            },
            {
              "VENDOR_NAME": "ConWa PROPERTY IV"
            },
            {
              "VENDOR_NAME": "ConWA PROPERTY"
            },
            {
              "VENDOR_NAME": "CON-WAY CENTRAL EXPRESS"
            },
            {
              "VENDOR_NAME": "CON-WAY FREIGHT"
            },
            {
              "VENDOR_NAME": "CONWELL LIMITED PARTNERSHIP"
            },
            {
              "VENDOR_NAME": "COOK-A-DOODLE-DOO"
            },
            {
              "VENDOR_NAME": "COONEY BROTHERS"
            },
            {
              "VENDOR_NAME": "COOPER CONVENIENCE"
            },
            {
              "VENDOR_NAME": "COOPER ELECTRIC SUPPLY CO."
            },
            {
              "VENDOR_NAME": "COOPER-ATKINS CORPORATION"
            },
            {
              "VENDOR_NAME": "COPLAY WHITEHALL SEWER AUTHORITY"
            },
            {
              "VENDOR_NAME": "CORA SERVICES"
            },
            {
              "VENDOR_NAME": "CORENET GLOBAL"
            },
            {
              "VENDOR_NAME": "CORESTATES BANK"
            },
            {
              "VENDOR_NAME": "COREY RITTENHOUSE"
            },
            {
              "VENDOR_NAME": "CORINNE SHINNICK"
            },
            {
              "VENDOR_NAME": "CORNELL UNIVERSITY FIM/DEP"
            },
            {
              "VENDOR_NAME": "CORNER FRAME & GALLERY"
            },
            {
              "VENDOR_NAME": "CORNERSTONE APPRAISAL GP."
            },
            {
              "VENDOR_NAME": "CORNERSTONE CONSULTING"
            },
            {
              "VENDOR_NAME": "CORNERSTONE RELOCATION"
            },
            {
              "VENDOR_NAME": "CORNUCOPIA"
            },
            {
              "VENDOR_NAME": "CORPORATE CONSULTING GROUP"
            },
            {
              "VENDOR_NAME": "CORPORATE EXECUTIVE BOARD"
            },
            {
              "VENDOR_NAME": "CORPORATE EXPRESS"
            },
            {
              "VENDOR_NAME": "CORPORATE PROPERTY"
            },
            {
              "VENDOR_NAME": "CORPORATE SERVICE SOLUTIONS"
            },
            {
              "VENDOR_NAME": "CORPORATE UNIVERSITY XCHANGE"
            },
            {
              "VENDOR_NAME": "CORPORATION SERVICE COMPANY"
            },
            {
              "VENDOR_NAME": "CORROSION CONTROL CORP."
            },
            {
              "VENDOR_NAME": "CORTLAND COUNTY SUPP.COLL.UNIT"
            },
            {
              "VENDOR_NAME": "COSTAR SANITATION"
            },
            {
              "VENDOR_NAME": "COTT BEVERAGES USA"
            },
            {
              "VENDOR_NAME": "COTT BEVERAGES"
            },
            {
              "VENDOR_NAME": "COTTAGE DELI & GROCERY"
            },
            {
              "VENDOR_NAME": "COUNCIL OF SUPPLY CHAIN"
            },
            {
              "VENDOR_NAME": "COUNCIL ON EDUCATION IN MANAGEMENT"
            },
            {
              "VENDOR_NAME": "COUNTRY CHRYSLER DODGE JEEP INC"
            },
            {
              "VENDOR_NAME": "COUNTRY CLUB PARK"
            },
            {
              "VENDOR_NAME": "COUNTRY PORCH FLORIST"
            },
            {
              "VENDOR_NAME": "COUNTRY PURE FOODS"
            },
            {
              "VENDOR_NAME": "COUNTRY SCHOOL OF THE SACRED HEART"
            },
            {
              "VENDOR_NAME": "COUNTRY WIDE HOME LOAN"
            },
            {
              "VENDOR_NAME": "COUNTY LINE /"
            },
            {
              "VENDOR_NAME": "COUNTY OF BERKS"
            },
            {
              "VENDOR_NAME": "COUNTY OF BUCKS"
            },
            {
              "VENDOR_NAME": "COUNTY OF CAMDEN"
            },
            {
              "VENDOR_NAME": "COUNTY OF CHESTER"
            },
            {
              "VENDOR_NAME": "COUNTY OF CHESTERFIELD"
            },
            {
              "VENDOR_NAME": "COUNTY OF DELAWARE"
            },
            {
              "VENDOR_NAME": "COUNTY OF GLOUCESTER"
            },
            {
              "VENDOR_NAME": "COUNTY OF GOOCHLAND"
            },
            {
              "VENDOR_NAME": "COUNTY OF HANOVER VIRGINIA"
            },
            {
              "VENDOR_NAME": "COUNTY OF HENRICO"
            },
            {
              "VENDOR_NAME": "COUNTY OF HENRICO TREASURY DIV."
            },
            {
              "VENDOR_NAME": "COUNTY OF HUMBOLT DEPT.IF SOCIAL SE"
            },
            {
              "VENDOR_NAME": "COUNTY OF HUNTERDON"
            },
            {
              "VENDOR_NAME": "COUNTY OF HUNTERDON-DEPT.OF HEALTH"
            },
            {
              "VENDOR_NAME": "COUNTY OF LEHIGH TAX COLLECTION"
            },
            {
              "VENDOR_NAME": "COUNTY OF MIDDLESEX"
            },
            {
              "VENDOR_NAME": "COUNTY OF ORANGE"
            },
            {
              "VENDOR_NAME": "COUNTY OF PRINCE WILLIAM"
            },
            {
              "VENDOR_NAME": "COUNTY OF SAN DIEGO"
            },
            {
              "VENDOR_NAME": "COUNTY OF SOMERSET"
            },
            {
              "VENDOR_NAME": "COUNTY OF SPOTSYLVANIA"
            },
            {
              "VENDOR_NAME": "COUNTY OF STAFFORD"
            },
            {
              "VENDOR_NAME": "COUNTY OF STAFFORD TREAS."
            },
            {
              "VENDOR_NAME": "COUNTY PRESS PUBLICATIONS"
            },
            {
              "VENDOR_NAME": "COUPONS INC"
            },
            {
              "VENDOR_NAME": "COURIER NEWS"
            },
            {
              "VENDOR_NAME": "COURIER POST"
            },
            {
              "VENDOR_NAME": "COURIER-POST"
            },
            {
              "VENDOR_NAME": "COURT AT COLLINGSWOOD INVESTORS"
            },
            {
              "VENDOR_NAME": "COURT OFFICER ALLEGRETTO"
            },
            {
              "VENDOR_NAME": "COURT OFFICER ANDREW ALLEGRETO"
            },
            {
              "VENDOR_NAME": "COURT OFFICER DEGUILLO"
            },
            {
              "VENDOR_NAME": "COURT OFFICER DeGUILO"
            },
            {
              "VENDOR_NAME": "COURT OFFICER DEGUILO"
            },
            {
              "VENDOR_NAME": "COURT OFFICER DEQUILO"
            },
            {
              "VENDOR_NAME": "COURT OFFICER DONALD SHOEMAKER"
            },
            {
              "VENDOR_NAME": "COURT OFFICER JOHN MCKERNAN"
            },
            {
              "VENDOR_NAME": "COURT OFFICER JOSEPH O'DONOGHUE"
            },
            {
              "VENDOR_NAME": "COURT OFFICER KEN MCCLELLAND"
            },
            {
              "VENDOR_NAME": "COURT OFFICER KENNON JENKINS"
            },
            {
              "VENDOR_NAME": "COURT OFFICER LEE BACON"
            },
            {
              "VENDOR_NAME": "COURT OFFICER LESHAUN GADDY"
            },
            {
              "VENDOR_NAME": "COURT OFFICER LOUIS SOTO RIOS"
            },
            {
              "VENDOR_NAME": "COURT OFFICER WILLIAM FALZINI"
            },
            {
              "VENDOR_NAME": "COURT OFFICER WM. FALZINI"
            },
            {
              "VENDOR_NAME": "COURT SQUARE ASSOCIATES"
            },
            {
              "VENDOR_NAME": "COURT TRUSTEE"
            },
            {
              "VENDOR_NAME": "COURT TRUSTEE"
            },
            {
              "VENDOR_NAME": "COURTHOUSE MARKETPLACE"
            },
            {
              "VENDOR_NAME": "COURTYARD BY MARRIOTT"
            },
            {
              "VENDOR_NAME": "COW PARADE HARRISBURG 2004"
            },
            {
              "VENDOR_NAME": "COX COMMUNICATIONS"
            },
            {
              "VENDOR_NAME": "COX COMMUNICATIONS"
            },
            {
              "VENDOR_NAME": "COYLE"
            },
            {
              "VENDOR_NAME": "COYNE CHEMICAL CO INC"
            },
            {
              "VENDOR_NAME": "CPE INC"
            },
            {
              "VENDOR_NAME": "CPH ENGINEERS"
            },
            {
              "VENDOR_NAME": "CRA INTERNATIONAL"
            },
            {
              "VENDOR_NAME": "CRAFT OIL COMPANY"
            },
            {
              "VENDOR_NAME": "CRAIG DEGEORGE"
            },
            {
              "VENDOR_NAME": "CRAIG DENELSBECK"
            },
            {
              "VENDOR_NAME": "CRAIG FARAGALLI"
            },
            {
              "VENDOR_NAME": "CRAIG LAWHORN"
            },
            {
              "VENDOR_NAME": "CRAIG R. TULEJA"
            },
            {
              "VENDOR_NAME": "CRAIG W. WALKER"
            },
            {
              "VENDOR_NAME": "CRANBURY TOWNSHIP"
            },
            {
              "VENDOR_NAME": "CRAYOLA"
            },
            {
              "VENDOR_NAME": "CRC PRESS I"
            },
            {
              "VENDOR_NAME": "CRC WATERSHEDS ASSOCIATION"
            },
            {
              "VENDOR_NAME": "CREAM O LAND DAIRIES"
            },
            {
              "VENDOR_NAME": "CREATIVE ADVERTISING SPEC.INC."
            },
            {
              "VENDOR_NAME": "CREATIVE RESOURCE DEVELOPMENT"
            },
            {
              "VENDOR_NAME": "CREDIT ACCEPTANCE CORP"
            },
            {
              "VENDOR_NAME": "CREDIT ACCEPTANCE CORP."
            },
            {
              "VENDOR_NAME": "CREDIT ACCEPTANCE CORPORATION"
            },
            {
              "VENDOR_NAME": "CREECH CONSTRUCTION CORP."
            },
            {
              "VENDOR_NAME": "CRESA PARTNERS OF PA."
            },
            {
              "VENDOR_NAME": "CRESENT PAINTING"
            },
            {
              "VENDOR_NAME": "CRIME SCENE CLEAN-UP"
            },
            {
              "VENDOR_NAME": "CROIMINAL DIVISION"
            },
            {
              "VENDOR_NAME": "CROMPCO CORPORATION"
            },
            {
              "VENDOR_NAME": "CROMPTON SALES"
            },
            {
              "VENDOR_NAME": "CROSS KEYS REALTY ASSOC."
            },
            {
              "VENDOR_NAME": "CROSS TELECOM CORPORATION"
            },
            {
              "VENDOR_NAME": "CROSSBRIDGE COMMUNITY CHURCH"
            },
            {
              "VENDOR_NAME": "CROSSING MARINERS LANDING"
            },
            {
              "VENDOR_NAME": "CROSSROADS PARTNERSHIP"
            },
            {
              "VENDOR_NAME": "CROWN ASSET MGT"
            },
            {
              "VENDOR_NAME": "CROWN CREDIT COMPANY"
            },
            {
              "VENDOR_NAME": "CROWN PACKAGING"
            },
            {
              "VENDOR_NAME": "CROWN PLAZA HOTEL"
            },
            {
              "VENDOR_NAME": "CROWN SQUARE APARTMENTS"
            },
            {
              "VENDOR_NAME": "CROZER CHESTER MEDICAL CENTER"
            },
            {
              "VENDOR_NAME": "CROZERVILLE PARTNERSHIP"
            },
            {
              "VENDOR_NAME": "CRW LTD"
            },
            {
              "VENDOR_NAME": "CRYSTAL CLEAN"
            },
            {
              "VENDOR_NAME": "CRYSTAL DECISIONS"
            },
            {
              "VENDOR_NAME": "CRYSTAL S. GILFERT"
            },
            {
              "VENDOR_NAME": "CRYSTAL SIGNATURES"
            },
            {
              "VENDOR_NAME": "CRYSTAL SODA WATER CO"
            },
            {
              "VENDOR_NAME": "CRYSTAL SPRINGS LLC"
            },
            {
              "VENDOR_NAME": "CRYSTALUSER.COM"
            },
            {
              "VENDOR_NAME": "CS FOODSERVICE HOLDINGS"
            },
            {
              "VENDOR_NAME": "CSAC"
            },
            {
              "VENDOR_NAME": "CSC INVESTMENTS LLC"
            },
            {
              "VENDOR_NAME": "CSC INVESTMENTS"
            },
            {
              "VENDOR_NAME": "CSC SCIENTIFIC CO"
            },
            {
              "VENDOR_NAME": "CSC SUGAR"
            },
            {
              "VENDOR_NAME": "CSP"
            },
            {
              "VENDOR_NAME": "CSTK"
            },
            {
              "VENDOR_NAME": "C-T UTILITY DEPT"
            },
            {
              "VENDOR_NAME": "CT. OFFICER A. ALLEGRETTO"
            },
            {
              "VENDOR_NAME": "CT. OFFICER KENNON JENKINS"
            },
            {
              "VENDOR_NAME": "CT.OFFICER RICHARD NORMAN"
            },
            {
              "VENDOR_NAME": "CUB SCOUT PACK 34"
            },
            {
              "VENDOR_NAME": "CUE DATA SERVICES"
            },
            {
              "VENDOR_NAME": "CUHACI & PETERSON ARCHITECTS"
            },
            {
              "VENDOR_NAME": "CULINARY CONCEPTS"
            },
            {
              "VENDOR_NAME": "CULLIGAN WATER CO.OFMD."
            },
            {
              "VENDOR_NAME": "CULPEPER COUNTY TREASURER"
            },
            {
              "VENDOR_NAME": "CUMBERLAND CHRISTIAN SCHOOL"
            },
            {
              "VENDOR_NAME": "CUMBERLAND CO.OFFICE OF EMPLOYMENT"
            },
            {
              "VENDOR_NAME": "CUMBERLAND COUNTY"
            },
            {
              "VENDOR_NAME": "CUMBERLAND COUNTY HEALTH DEPT."
            },
            {
              "VENDOR_NAME": "CUMBERLAND COUNTY TREASURER"
            },
            {
              "VENDOR_NAME": "CUMBERLAND COUNTY UTILITIES AUTHORI"
            },
            {
              "VENDOR_NAME": "CUMBERLAND CTY SOIL DIST"
            },
            {
              "VENDOR_NAME": "CUMBERLAND DAIRY INC"
            },
            {
              "VENDOR_NAME": "CUMBERLAND PLAYERS"
            },
            {
              "VENDOR_NAME": "CUOMO & GODDARD"
            },
            {
              "VENDOR_NAME": "CURESEARCH NAT'L CHILDHOOD"
            },
            {
              "VENDOR_NAME": "CURRITUCK SAND COMPANY"
            },
            {
              "VENDOR_NAME": "CURRON-BROMWELL FUNERAL HOME"
            },
            {
              "VENDOR_NAME": "CUSICK ELECTRICAL SALES INC."
            },
            {
              "VENDOR_NAME": "CUSTOM BINDER SALES"
            },
            {
              "VENDOR_NAME": "CUSTOM CULINARY CONNECTIONS"
            },
            {
              "VENDOR_NAME": "CUSTOM DESIGNS FROM THE HEART"
            },
            {
              "VENDOR_NAME": "CUSTOM DIESEL SERV. INC."
            },
            {
              "VENDOR_NAME": "CUSTOM ENVIRONMENTAL TECH. INC"
            },
            {
              "VENDOR_NAME": "CUSTOM METAL DESIGNS"
            },
            {
              "VENDOR_NAME": "CUSTOM PUBLISHING DESIGN GROUP"
            },
            {
              "VENDOR_NAME": "CUSTOM SURFACE CO."
            },
            {
              "VENDOR_NAME": "CUTTING EDGE GLASS CO."
            },
            {
              "VENDOR_NAME": "CV CORPORATION OF VA"
            },
            {
              "VENDOR_NAME": "CVM CONSTRUCTION MANAGERS"
            },
            {
              "VENDOR_NAME": "CVM ENGINEERS"
            },
            {
              "VENDOR_NAME": "CVS PHARMACY"
            },
            {
              "VENDOR_NAME": "CYBERTRUST"
            },
            {
              "VENDOR_NAME": "CYNED TRANSPORT CORPORATION"
            },
            {
              "VENDOR_NAME": "CYNTHIA GRAHAM"
            },
            {
              "VENDOR_NAME": "CYNTHIA RANDALL MEYERS"
            },
            {
              "VENDOR_NAME": "CYNTHIA SKOK"
            },
            {
              "VENDOR_NAME": "CYNTHIA TORRES"
            },
            {
              "VENDOR_NAME": "CYSTIC FIBROSIS FOUND"
            },
            {
              "VENDOR_NAME": "CYTOSPORT"
            },
            {
              "VENDOR_NAME": "D & B THERMAL CO."
            },
            {
              "VENDOR_NAME": "D & D COLLISION SERVICES"
            },
            {
              "VENDOR_NAME": "D & H DISTRIBUTING"
            },
            {
              "VENDOR_NAME": "D & M MOBILEWASH"
            },
            {
              "VENDOR_NAME": "D PATIENCE WELCH"
            },
            {
              "VENDOR_NAME": "D&F BARNEGAT DEVELOPMENT LLC"
            },
            {
              "VENDOR_NAME": "D.D. BEAN AND SONS. CO"
            },
            {
              "VENDOR_NAME": "D.F. STAUFFER BISCUIT COMP"
            },
            {
              "VENDOR_NAME": "D.J.K. ROOFING"
            },
            {
              "VENDOR_NAME": "D.K.BRADY EXCAVATING"
            },
            {
              "VENDOR_NAME": "D.MICHAEL DOWNES"
            },
            {
              "VENDOR_NAME": "D.R.P.A. EZ PASS"
            },
            {
              "VENDOR_NAME": "D.SCOTT GORDON"
            },
            {
              "VENDOR_NAME": "D.Y. NYE CO."
            },
            {
              "VENDOR_NAME": "DAB TECHNICAL SERVICES"
            },
            {
              "VENDOR_NAME": "DAILY LOCAL NEWS"
            },
            {
              "VENDOR_NAME": "DAILY PRESS"
            },
            {
              "VENDOR_NAME": "DAILY PRESS"
            },
            {
              "VENDOR_NAME": "DAILY PRESS-OUTER MARKET"
            },
            {
              "VENDOR_NAME": "DAILY RECORD"
            },
            {
              "VENDOR_NAME": "DAILY TIMES"
            },
            {
              "VENDOR_NAME": "DAIMLER CHRYSLER"
            },
            {
              "VENDOR_NAME": "DAIMLER CHRYSLER ZONE 33"
            },
            {
              "VENDOR_NAME": "DAIRY CONVEYOR CORP"
            },
            {
              "VENDOR_NAME": "DAIRY HOUSE"
            },
            {
              "VENDOR_NAME": "DAIRY INDUSTRY-UNION PENSION FUND"
            },
            {
              "VENDOR_NAME": "DAIRY PRACTICES COUNCIL"
            },
            {
              "VENDOR_NAME": "DAIRY SOLUTIONS"
            },
            {
              "VENDOR_NAME": "DAIRY.COM"
            },
            {
              "VENDOR_NAME": "DAK USA"
            },
            {
              "VENDOR_NAME": "DAKTRONICS"
            },
            {
              "VENDOR_NAME": "DALB"
            },
            {
              "VENDOR_NAME": "DALE & THOMAS POPCORN"
            },
            {
              "VENDOR_NAME": "DALE CARNEGIE TRAINING"
            },
            {
              "VENDOR_NAME": "DALE CARNEGIE TRAINING LEADERSHIP I"
            },
            {
              "VENDOR_NAME": "DALE CITY LITTLE LEAGUE"
            },
            {
              "VENDOR_NAME": "DALE MCCANN'S SEPTIC SERV"
            },
            {
              "VENDOR_NAME": "DALE RUBBO"
            },
            {
              "VENDOR_NAME": "DALE'S APPLIANCE SERVICE"
            },
            {
              "VENDOR_NAME": "DAL-TILE"
            },
            {
              "VENDOR_NAME": "DAMON K. LACEY"
            },
            {
              "VENDOR_NAME": "DAMON LACEY COURT OFFICER"
            },
            {
              "VENDOR_NAME": "DAMON LACEY CT. OFFICER"
            },
            {
              "VENDOR_NAME": "DAN GREGAS"
            },
            {
              "VENDOR_NAME": "DAN MILLER"
            },
            {
              "VENDOR_NAME": "DAN MOORE TREE SERVICE"
            },
            {
              "VENDOR_NAME": "DAN SNYDERS NEW CAR TRADERS"
            },
            {
              "VENDOR_NAME": "DANA RUFFO"
            },
            {
              "VENDOR_NAME": "DANA TRANSPORT SYS.INC."
            },
            {
              "VENDOR_NAME": "DANA-DAVIS ALEXANDER"
            },
            {
              "VENDOR_NAME": "DANIEL & LORRAINCE TUSAVITZ"
            },
            {
              "VENDOR_NAME": "DANIEL & LORRAINE TUSAVITZ"
            },
            {
              "VENDOR_NAME": "DANIEL A.NETZER"
            },
            {
              "VENDOR_NAME": "DANIEL BOON COACHES ASSOCIATION"
            },
            {
              "VENDOR_NAME": "DANIEL BOONE AREA SCHOOL DISTRICT"
            },
            {
              "VENDOR_NAME": "DANIEL BOONE HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "DANIEL BOYLE"
            },
            {
              "VENDOR_NAME": "DANIEL CAFFO HARDWOOD FLOORING"
            },
            {
              "VENDOR_NAME": "DANIEL CALDER - INTERNAL TECH"
            },
            {
              "VENDOR_NAME": "DANIEL CAMPIONE"
            },
            {
              "VENDOR_NAME": "DANIEL D. LEONARD"
            },
            {
              "VENDOR_NAME": "DANIEL E. BERRY"
            },
            {
              "VENDOR_NAME": "DANIEL E.BERRY"
            },
            {
              "VENDOR_NAME": "DANIEL F. McELANEY"
            },
            {
              "VENDOR_NAME": "DANIEL FRENEY"
            },
            {
              "VENDOR_NAME": "DANIEL HUGHES"
            },
            {
              "VENDOR_NAME": "DANIEL J.SHEEDY"
            },
            {
              "VENDOR_NAME": "DANIEL J.SHEEDY"
            },
            {
              "VENDOR_NAME": "DANIEL JACKSON TRACY"
            },
            {
              "VENDOR_NAME": "DANIEL JOSEPH TRACY"
            },
            {
              "VENDOR_NAME": "DANIEL LYONS"
            },
            {
              "VENDOR_NAME": "DANIEL M CAMPIONE"
            },
            {
              "VENDOR_NAME": "DANIEL PERKINS"
            },
            {
              "VENDOR_NAME": "DANIEL RUSHTON"
            },
            {
              "VENDOR_NAME": "DANIEL SCULLY"
            },
            {
              "VENDOR_NAME": "DANIEL SLIPETSKY"
            },
            {
              "VENDOR_NAME": "DANIEL SMITH"
            },
            {
              "VENDOR_NAME": "DANIEL SWAFFORD"
            },
            {
              "VENDOR_NAME": "DANIELLE BROWN"
            },
            {
              "VENDOR_NAME": "DANIELLE NASH PRETTYMAN"
            },
            {
              "VENDOR_NAME": "DARDEN RESTAURANTS"
            },
            {
              "VENDOR_NAME": "DARE TO DREAM FOUNDATION"
            },
            {
              "VENDOR_NAME": "DARRELL HINES"
            },
            {
              "VENDOR_NAME": "DARREN BURDGE"
            },
            {
              "VENDOR_NAME": "DARRYL YOTHERS"
            },
            {
              "VENDOR_NAME": "DAS ENVIRONMENTAL TRAINING INC"
            },
            {
              "VENDOR_NAME": "DAS ENVIRONMENTAL TRAINING INC."
            },
            {
              "VENDOR_NAME": "DATA GUARD RECYCLING"
            },
            {
              "VENDOR_NAME": "DATA GUARD"
            },
            {
              "VENDOR_NAME": "DATA RECOVERY SYSTEMS LLC"
            },
            {
              "VENDOR_NAME": "DATA SERVICES INTERNATIONAL"
            },
            {
              "VENDOR_NAME": "DATADIRECT"
            },
            {
              "VENDOR_NAME": "DATAKEY ELECTRONICS"
            },
            {
              "VENDOR_NAME": "DAUD A. KHAN"
            },
            {
              "VENDOR_NAME": "DAVE & BUSTER'S"
            },
            {
              "VENDOR_NAME": "DAVE DESERIO"
            },
            {
              "VENDOR_NAME": "DAVE LAURO"
            },
            {
              "VENDOR_NAME": "DAVE MAC DONALD"
            },
            {
              "VENDOR_NAME": "DAVE O'KELLY"
            },
            {
              "VENDOR_NAME": "DAVE STITZ"
            },
            {
              "VENDOR_NAME": "DAVECO INDUSTRIAL"
            },
            {
              "VENDOR_NAME": "DAVE'S LIMOUSINE"
            },
            {
              "VENDOR_NAME": "DAVID A. JOHNSTON"
            },
            {
              "VENDOR_NAME": "DAVID A. KRENKEL"
            },
            {
              "VENDOR_NAME": "DAVID A. ROBINSON"
            },
            {
              "VENDOR_NAME": "DAVID A.JOHNSTON"
            },
            {
              "VENDOR_NAME": "DAVID A.WARD"
            },
            {
              "VENDOR_NAME": "DAVID B. HAYNIE"
            },
            {
              "VENDOR_NAME": "DAVID BESTE"
            },
            {
              "VENDOR_NAME": "DAVID C. PERRY"
            },
            {
              "VENDOR_NAME": "DAVID CARDAMONE"
            },
            {
              "VENDOR_NAME": "DAVID CARTER"
            },
            {
              "VENDOR_NAME": "DAVID COOK"
            },
            {
              "VENDOR_NAME": "DAVID CZOPEK"
            },
            {
              "VENDOR_NAME": "DAVID D YEAGER"
            },
            {
              "VENDOR_NAME": "DAVID DICKERSON & ASSOCIATES"
            },
            {
              "VENDOR_NAME": "DAVID DILKS"
            },
            {
              "VENDOR_NAME": "DAVID E. CHAPHE"
            },
            {
              "VENDOR_NAME": "DAVID FILANO"
            },
            {
              "VENDOR_NAME": "DAVID FIORI INC"
            },
            {
              "VENDOR_NAME": "DAVID HARRINGTON"
            },
            {
              "VENDOR_NAME": "DAVID HARRIS"
            },
            {
              "VENDOR_NAME": "DAVID HAY"
            },
            {
              "VENDOR_NAME": "DAVID HENRY RAANAN"
            },
            {
              "VENDOR_NAME": "DAVID K FOSTER D.D.S."
            },
            {
              "VENDOR_NAME": "DAVID K. FOSTER DDS LTD"
            },
            {
              "VENDOR_NAME": "DAVID L. SZUMSKI"
            },
            {
              "VENDOR_NAME": "DAVID L. ZARRILLI"
            },
            {
              "VENDOR_NAME": "DAVID L.ZARRILLI"
            },
            {
              "VENDOR_NAME": "DAVID LAPENTA"
            },
            {
              "VENDOR_NAME": "DAVID LOVOS"
            },
            {
              "VENDOR_NAME": "DAVID MANN"
            },
            {
              "VENDOR_NAME": "DAVID P. MUELLER DDS"
            },
            {
              "VENDOR_NAME": "DAVID PARDEE FOULKE"
            },
            {
              "VENDOR_NAME": "DAVID PREVOST"
            },
            {
              "VENDOR_NAME": "DAVID RANDALL ASSOCIATES"
            },
            {
              "VENDOR_NAME": "DAVID REED"
            },
            {
              "VENDOR_NAME": "DAVID REGIEC"
            },
            {
              "VENDOR_NAME": "DAVID ROSSI"
            },
            {
              "VENDOR_NAME": "DAVID S. ZOCCHI MEMORIAL FUND"
            },
            {
              "VENDOR_NAME": "DAVID S.STEINGARD"
            },
            {
              "VENDOR_NAME": "DAVID SCOTT BORDEN"
            },
            {
              "VENDOR_NAME": "DAVID THOMPSON"
            },
            {
              "VENDOR_NAME": "DAVID TIRELLA"
            },
            {
              "VENDOR_NAME": "DAVID W WOOD JR TRUSTEE"
            },
            {
              "VENDOR_NAME": "DAVID W. HIDDEMEN"
            },
            {
              "VENDOR_NAME": "DAVID W. WOOD JR"
            },
            {
              "VENDOR_NAME": "DAVID W. WOOD"
            },
            {
              "VENDOR_NAME": "DAVID W.and MARIANNE S.WOOD"
            },
            {
              "VENDOR_NAME": "DAVIDSON LUNGER"
            },
            {
              "VENDOR_NAME": "DAVIDSON MBMW"
            },
            {
              "VENDOR_NAME": "DAVIS BEVERAGE CO"
            },
            {
              "VENDOR_NAME": "DAVIS BOWEN & FRIEDEL INC"
            },
            {
              "VENDOR_NAME": "DAVIS DISPOSAL"
            },
            {
              "VENDOR_NAME": "DAVIS ENTERPRISES"
            },
            {
              "VENDOR_NAME": "DAVIS HAULING INC"
            },
            {
              "VENDOR_NAME": "DAVIS INOTEK INSTRUMENTS"
            },
            {
              "VENDOR_NAME": "DAWN M. ARNST"
            },
            {
              "VENDOR_NAME": "DAWN SHIRRE HINES COURT OFFICER"
            },
            {
              "VENDOR_NAME": "DAWNSHIRE HINES COURT OFFICER"
            },
            {
              "VENDOR_NAME": "DAWNSHIRR HINES COURT OFFICER"
            },
            {
              "VENDOR_NAME": "DAWN-SHIRRE HINES"
            },
            {
              "VENDOR_NAME": "DAWN-SHIRRE HINES COURT OFFICE"
            },
            {
              "VENDOR_NAME": "DAWN-SHIRRE HINES COURT OFFICER"
            },
            {
              "VENDOR_NAME": "DAWNSHIRRE HINES CT OFFICER"
            },
            {
              "VENDOR_NAME": "DAY & ZIMMERMAN LLC"
            },
            {
              "VENDOR_NAME": "DAYMON WORLDWIDE DESIGN"
            },
            {
              "VENDOR_NAME": "DAYMON WORLDWIDE"
            },
            {
              "VENDOR_NAME": "DAYS INN"
            },
            {
              "VENDOR_NAME": "DAYS INN EDGEWOOD"
            },
            {
              "VENDOR_NAME": "DAYS INN OF WILDWOOD"
            },
            {
              "VENDOR_NAME": "DC CHILD SUPPORT CLEARING HOUSE"
            },
            {
              "VENDOR_NAME": "DC F&R EDI"
            },
            {
              "VENDOR_NAME": "DC F&R EDI Florida Test"
            },
            {
              "VENDOR_NAME": "DC HANDHELD EDI"
            },
            {
              "VENDOR_NAME": "DC REYNOLDS SR & CJ REYNOLDS"
            },
            {
              "VENDOR_NAME": "DCMS / AFCOM"
            },
            {
              "VENDOR_NAME": "DCSE"
            },
            {
              "VENDOR_NAME": "DDP GROUP INC"
            },
            {
              "VENDOR_NAME": "DDRM CROSSROADS PLAZA"
            },
            {
              "VENDOR_NAME": "DE DIV OF REV"
            },
            {
              "VENDOR_NAME": "DE DIV OF REVENUE - USTIF TAX ACCRU"
            },
            {
              "VENDOR_NAME": "DE.HEALTH AND SOC.SERV."
            },
            {
              "VENDOR_NAME": "DEAF-HEARING COMMUNICATION"
            },
            {
              "VENDOR_NAME": "DEAM"
            },
            {
              "VENDOR_NAME": "DEAN DAIRY PRODUCTS CO."
            },
            {
              "VENDOR_NAME": "DEAN FRONHEISER"
            },
            {
              "VENDOR_NAME": "DEANNE BELLUSCIO"
            },
            {
              "VENDOR_NAME": "DEARING BEVERAGE"
            },
            {
              "VENDOR_NAME": "DEBBIE GLEASON-MORGAN"
            },
            {
              "VENDOR_NAME": "DEBBIE GRUND"
            },
            {
              "VENDOR_NAME": "DEBBIE LARZELERE"
            },
            {
              "VENDOR_NAME": "DeBeukelaer Corp"
            },
            {
              "VENDOR_NAME": "DEBITMAN CARD"
            },
            {
              "VENDOR_NAME": "DEBORAH A MININGER TAX CO"
            },
            {
              "VENDOR_NAME": "DEBORAH A. CAYS"
            },
            {
              "VENDOR_NAME": "DEBORAH A. MASTERTON"
            },
            {
              "VENDOR_NAME": "DEBORAH A.MASTERTON"
            },
            {
              "VENDOR_NAME": "DEBORAH GOURSKY"
            },
            {
              "VENDOR_NAME": "DEBORAH HOSPITAL FOUND."
            },
            {
              "VENDOR_NAME": "DEBORAH LYNNE SMYTH"
            },
            {
              "VENDOR_NAME": "DEBORAH RAYER"
            },
            {
              "VENDOR_NAME": "DEBORAH SATTLER"
            },
            {
              "VENDOR_NAME": "DEBRA CIFALA"
            },
            {
              "VENDOR_NAME": "DECA"
            },
            {
              "VENDOR_NAME": "DECAGON DEVICES"
            },
            {
              "VENDOR_NAME": "DECISIVE BUSINESS SYS INC"
            },
            {
              "VENDOR_NAME": "DECKMAN ELECTRIC"
            },
            {
              "VENDOR_NAME": "DEEB"
            },
            {
              "VENDOR_NAME": "DEER MEADOWS RETIREMENT"
            },
            {
              "VENDOR_NAME": "DEER PARK SPRING WATER"
            },
            {
              "VENDOR_NAME": "DEERFIELD TOWNSHIP"
            },
            {
              "VENDOR_NAME": "DEFA"
            },
            {
              "VENDOR_NAME": "DEFAULT RECP ID DRAFTS PETRON"
            },
            {
              "VENDOR_NAME": "DEGLER-WHITING"
            },
            {
              "VENDOR_NAME": "DEGREE DAY SYSTEMS"
            },
            {
              "VENDOR_NAME": "DEHAVEN'S TRU-CLEAN"
            },
            {
              "VENDOR_NAME": "DEIDRE McCLEARY"
            },
            {
              "VENDOR_NAME": "DEL BONO'S AUTO BODY"
            },
            {
              "VENDOR_NAME": "DEL MONTE CORPORATION"
            },
            {
              "VENDOR_NAME": "DEL MONTE FOODS"
            },
            {
              "VENDOR_NAME": "DEL MONTE FRESH PRODUCE N.A. INC."
            },
            {
              "VENDOR_NAME": "DEL MOTORIZED SOLUTIONS"
            },
            {
              "VENDOR_NAME": "DELANCEY CONCORD ASSOC. L.P."
            },
            {
              "VENDOR_NAME": "DELAND VICTORIA"
            },
            {
              "VENDOR_NAME": "DELANO HARTMAN"
            },
            {
              "VENDOR_NAME": "DELAWARE BLUE HENS"
            },
            {
              "VENDOR_NAME": "DELAWARE CENTER FOR HORTICULTURE"
            },
            {
              "VENDOR_NAME": "DELAWARE CO DAILY TIMES"
            },
            {
              "VENDOR_NAME": "DELAWARE COUNTY"
            },
            {
              "VENDOR_NAME": "DELAWARE COUNTY 4-H"
            },
            {
              "VENDOR_NAME": "DELAWARE COUNTY BAR ASSOCIATION"
            },
            {
              "VENDOR_NAME": "DELAWARE COUNTY BAR FOUNDATION"
            },
            {
              "VENDOR_NAME": "DELAWARE COUNTY CHAMBER OF COMMERCE"
            },
            {
              "VENDOR_NAME": "DELAWARE COUNTY COMMUNITY COLLEGE"
            },
            {
              "VENDOR_NAME": "DELAWARE COUNTY CONERVATION DISTRIC"
            },
            {
              "VENDOR_NAME": "DELAWARE COUNTY CONSERVATION DISTRI"
            },
            {
              "VENDOR_NAME": "DELAWARE COUNTY COURTHOUSE"
            },
            {
              "VENDOR_NAME": "DELAWARE COUNTY DOWN SYNDROME"
            },
            {
              "VENDOR_NAME": "DELAWARE COUNTY FOOT & ANKLE CENTER"
            },
            {
              "VENDOR_NAME": "DELAWARE COUNTY LINEN"
            },
            {
              "VENDOR_NAME": "DELAWARE COUNTY PLANNING DEPARTMENT"
            },
            {
              "VENDOR_NAME": "DELAWARE COUNTY RECORDER OF DEEDS"
            },
            {
              "VENDOR_NAME": "DELAWARE COUNTY SUMMER FESTIVAL IN"
            },
            {
              "VENDOR_NAME": "DELAWARE COUNTY SUPPLY"
            },
            {
              "VENDOR_NAME": "DELAWARE COUNTY TRANSPORTATION"
            },
            {
              "VENDOR_NAME": "DELAWARE CTY CONSERVATION"
            },
            {
              "VENDOR_NAME": "DELAWARE DEPT OF FINANCE"
            },
            {
              "VENDOR_NAME": "DELAWARE DEPT OF LABOR"
            },
            {
              "VENDOR_NAME": "DELAWARE DEPT OF LABOR - ACCRUAL"
            },
            {
              "VENDOR_NAME": "DELAWARE DEPT OF TRANS."
            },
            {
              "VENDOR_NAME": "DELAWARE DEPT. OF TRANSPORTATION"
            },
            {
              "VENDOR_NAME": "DELAWARE DEPT.OF NATURAL RESOURCES"
            },
            {
              "VENDOR_NAME": "DELAWARE DEPT.OF TRANSPORTATION-S"
            },
            {
              "VENDOR_NAME": "DELAWARE DESIGN GROUP"
            },
            {
              "VENDOR_NAME": "DELAWARE DIV OF REVENUE"
            },
            {
              "VENDOR_NAME": "DELAWARE DIVISION OF CHILD SUPPORT"
            },
            {
              "VENDOR_NAME": "DELAWARE DIVISION OF REV"
            },
            {
              "VENDOR_NAME": "DELAWARE DIVISION OF REVENUE"
            },
            {
              "VENDOR_NAME": "DELAWARE FOOD INDUSTRY COUNCIL"
            },
            {
              "VENDOR_NAME": "DELAWARE HEALTH & SOC SERV"
            },
            {
              "VENDOR_NAME": "DELAWARE HEALTH & SOC. CENTER"
            },
            {
              "VENDOR_NAME": "DELAWARE HEALTH & SOCIAL SERV"
            },
            {
              "VENDOR_NAME": "DELAWARE HEALTH & SOCIAL SERV."
            },
            {
              "VENDOR_NAME": "DELAWARE HEALTH & SOCIAL SERVICES"
            },
            {
              "VENDOR_NAME": "DELAWARE HEALTH AND SOC SER"
            },
            {
              "VENDOR_NAME": "DELAWARE HEALTH AND SOCIAL SERV."
            },
            {
              "VENDOR_NAME": "DELAWARE HOSPICE"
            },
            {
              "VENDOR_NAME": "DELAWARE HOSPICE GOLF OUTING"
            },
            {
              "VENDOR_NAME": "DELAWARE LOGOS"
            },
            {
              "VENDOR_NAME": "DELAWARE RIVER PORT AUTHORITY"
            },
            {
              "VENDOR_NAME": "DELAWARE SAFETY COUNCIL"
            },
            {
              "VENDOR_NAME": "DELAWARE STATE POLICE"
            },
            {
              "VENDOR_NAME": "DELAWARE TECHNICAL AND COMM.COLLEGE"
            },
            {
              "VENDOR_NAME": "DELAWARE VALLEY BOBCAT LLC"
            },
            {
              "VENDOR_NAME": "DELAWARE VALLEY COLLEGE"
            },
            {
              "VENDOR_NAME": "DELAWARE VALLEY STROKE COUNCIL"
            },
            {
              "VENDOR_NAME": "DELAWARE VALLEY UTILITY ADVISORS"
            },
            {
              "VENDOR_NAME": "DELCHESTER SERRA CLUB"
            },
            {
              "VENDOR_NAME": "DELCO LLC"
            },
            {
              "VENDOR_NAME": "DELCO STALLIONS YOUTH TRACK CLUB"
            },
            {
              "VENDOR_NAME": "DelDOT TRAFFIC"
            },
            {
              "VENDOR_NAME": "DELIA GROELING"
            },
            {
              "VENDOR_NAME": "DELIA GROELING COURT OFFICER"
            },
            {
              "VENDOR_NAME": "DELIA GROELING CT. OFFICER"
            },
            {
              "VENDOR_NAME": "DELIA GROELING"
            },
            {
              "VENDOR_NAME": "DELIVER ME YOGA"
            },
            {
              "VENDOR_NAME": "DELL CATALOG SALES L.P."
            },
            {
              "VENDOR_NAME": "DELL FINANCIAL SERVICES LLC"
            },
            {
              "VENDOR_NAME": "DELL MARKETING L.P."
            },
            {
              "VENDOR_NAME": "DELL SERVICE SALES"
            },
            {
              "VENDOR_NAME": "DELMAR COMMONS"
            },
            {
              "VENDOR_NAME": "DELMAR FIRE COMPANY"
            },
            {
              "VENDOR_NAME": "DELMAR PUBLIC LIBRARY"
            },
            {
              "VENDOR_NAME": "DELMARVA POWER"
            },
            {
              "VENDOR_NAME": "DELOITTE & TOUCHE LLP"
            },
            {
              "VENDOR_NAME": "DELOITTE TAX"
            },
            {
              "VENDOR_NAME": "DELORES HOUCHENS"
            },
            {
              "VENDOR_NAME": "DELORES TAYLOR"
            },
            {
              "VENDOR_NAME": "DELRAN SEWERAGE AUTHORITY"
            },
            {
              "VENDOR_NAME": "DELRI INDUSTRIAL SUPPLIES INC."
            },
            {
              "VENDOR_NAME": "DELTA DEVELOPMENT GROUP"
            },
            {
              "VENDOR_NAME": "DELTA MANAGEMENT ASSOC"
            },
            {
              "VENDOR_NAME": "DELUXE BUSINESS CHECKS"
            },
            {
              "VENDOR_NAME": "DELVACCA"
            },
            {
              "VENDOR_NAME": "DELVAL EQUIPMENT CORP"
            },
            {
              "VENDOR_NAME": "DEL-VAL SOCCER CLUB"
            },
            {
              "VENDOR_NAME": "DELVAL SOIL & ENVIRO."
            },
            {
              "VENDOR_NAME": "DEL-VAL STAMP CO"
            },
            {
              "VENDOR_NAME": "DELVIN SNELLINGS"
            },
            {
              "VENDOR_NAME": "DEMOLITION & ASBESTOS REMOVAL"
            },
            {
              "VENDOR_NAME": "DENBIGH CROSSING XXX-XX-2004"
            },
            {
              "VENDOR_NAME": "DENISE BETTS TAX COLLECTOR"
            },
            {
              "VENDOR_NAME": "DENISE BILEN"
            },
            {
              "VENDOR_NAME": "DENISE DiILLIO"
            },
            {
              "VENDOR_NAME": "DENISE K. HEIN"
            },
            {
              "VENDOR_NAME": "DENISE MARINELLA"
            },
            {
              "VENDOR_NAME": "DENNEY ELECTRIC SUPPLY OF"
            },
            {
              "VENDOR_NAME": "DENNINGS GARAGE"
            },
            {
              "VENDOR_NAME": "DENNIS AND LISA KOCHANIK"
            },
            {
              "VENDOR_NAME": "DENNIS BRUCE"
            },
            {
              "VENDOR_NAME": "DENNIS J. ELZEY"
            },
            {
              "VENDOR_NAME": "DENNIS McNAMARA FUND"
            },
            {
              "VENDOR_NAME": "DENNIS N. CLAYTOR"
            },
            {
              "VENDOR_NAME": "DENNIS NATIONALEASE"
            },
            {
              "VENDOR_NAME": "DENNIS ROEBUCK"
            },
            {
              "VENDOR_NAME": "DENNIS SHEA"
            },
            {
              "VENDOR_NAME": "DENNIS TOWNSHIP"
            },
            {
              "VENDOR_NAME": "DENNIS W.POLLOCK"
            },
            {
              "VENDOR_NAME": "DENTON KANOUFF"
            },
            {
              "VENDOR_NAME": "DEPARTMENT OF FINANCE"
            },
            {
              "VENDOR_NAME": "DEPARTMENT OF PUBLIC SAFETY"
            },
            {
              "VENDOR_NAME": "DEPARTMENT OF TREASURY"
            },
            {
              "VENDOR_NAME": "DEPERSICO'S INC."
            },
            {
              "VENDOR_NAME": "DEPLOY SOLUTIONS"
            },
            {
              "VENDOR_NAME": "DEPT OF PUBLIC HEALTH EHS"
            },
            {
              "VENDOR_NAME": "DEPT OF SOC. SER."
            },
            {
              "VENDOR_NAME": "DEPT. OF CHILD SUPPORT SERVICES"
            },
            {
              "VENDOR_NAME": "DEPT. OF CONSERVATION & RECREATION"
            },
            {
              "VENDOR_NAME": "DEPT. OF ENVIRONMENTAL QUALITY"
            },
            {
              "VENDOR_NAME": "DEPT. OF TAX ADMIN."
            },
            {
              "VENDOR_NAME": "DEPT.OF THE ENVIROMENT"
            },
            {
              "VENDOR_NAME": "DEPTFORD TOWNSHIP"
            },
            {
              "VENDOR_NAME": "DEPTFORD TOWNSHIP M U A"
            },
            {
              "VENDOR_NAME": "DEREK PIERCE"
            },
            {
              "VENDOR_NAME": "DERICK BROADAWAY DDS"
            },
            {
              "VENDOR_NAME": "DERIVE TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "DESCARTES SYSTEMS"
            },
            {
              "VENDOR_NAME": "DESCARTES SYSTEMS (USA) LLC"
            },
            {
              "VENDOR_NAME": "DESERT SAND RESORT"
            },
            {
              "VENDOR_NAME": "DESHONG & SONS CONTRACTOR"
            },
            {
              "VENDOR_NAME": "DESIGN & CONSTRUCTION RESOURCES"
            },
            {
              "VENDOR_NAME": "DEV-AIR"
            },
            {
              "VENDOR_NAME": "DEVAULT REFRIGERATION INC"
            },
            {
              "VENDOR_NAME": "DEVELOP. OFFICE -BRYN MAWR HOSPITAL"
            },
            {
              "VENDOR_NAME": "DEVOLITES-DAVIS FOR SENATE"
            },
            {
              "VENDOR_NAME": "DEVON PREPARATORY SCHOOL"
            },
            {
              "VENDOR_NAME": "DEWBERRY & DAVIS LLC"
            },
            {
              "VENDOR_NAME": "DEWEY ALLEN"
            },
            {
              "VENDOR_NAME": "DEWEY R.TURNER JR."
            },
            {
              "VENDOR_NAME": "DEWITT FABRICATION AND WELDING"
            },
            {
              "VENDOR_NAME": "DHL ASSOCIATES INC."
            },
            {
              "VENDOR_NAME": "DHL EXPRESS (USA) INC."
            },
            {
              "VENDOR_NAME": "DHSS ATTN: AMR/A.R.M.S."
            },
            {
              "VENDOR_NAME": "DIAGRAPH MARKING & CODING GROUP"
            },
            {
              "VENDOR_NAME": "DIAKONIA"
            },
            {
              "VENDOR_NAME": "DIAMOND CRYSTAL BRANDS"
            },
            {
              "VENDOR_NAME": "DIAMOND DRILLING COMPANY"
            },
            {
              "VENDOR_NAME": "DIAMOND STATE NEWS"
            },
            {
              "VENDOR_NAME": "DIAMOND STATE WAREHOUSING"
            },
            {
              "VENDOR_NAME": "DIAMOND TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "DIAMOND TOOL"
            },
            {
              "VENDOR_NAME": "DIAMOND WAWA LLC"
            },
            {
              "VENDOR_NAME": "DIANA FERGUSON"
            },
            {
              "VENDOR_NAME": "DIANA GENTRY"
            },
            {
              "VENDOR_NAME": "DIANA SHORTS"
            },
            {
              "VENDOR_NAME": "DIANE FRAYNE"
            },
            {
              "VENDOR_NAME": "DIANE HEINTZELMAN"
            },
            {
              "VENDOR_NAME": "DIANE NOCTOR"
            },
            {
              "VENDOR_NAME": "DIANE TELLY-TAX COLLECTOR"
            },
            {
              "VENDOR_NAME": "DIANNA LU"
            },
            {
              "VENDOR_NAME": "DICE CAREER SOLUTIONS"
            },
            {
              "VENDOR_NAME": "DICKINSON EQUIPMENT"
            },
            {
              "VENDOR_NAME": "DICKINSON FLEET SERVICES LLC"
            },
            {
              "VENDOR_NAME": "DIESEL DOCTOR INC."
            },
            {
              "VENDOR_NAME": "DIESEL SYSTEMS"
            },
            {
              "VENDOR_NAME": "DIETZ & WATSON"
            },
            {
              "VENDOR_NAME": "DIG EXCAVATING"
            },
            {
              "VENDOR_NAME": "DILEMMO CONSTRUCTION CO."
            },
            {
              "VENDOR_NAME": "DILIP PATEL"
            },
            {
              "VENDOR_NAME": "DILLER & FISHER REAL ESTATE"
            },
            {
              "VENDOR_NAME": "DILTS & KOESTER"
            },
            {
              "VENDOR_NAME": "DILWORTHTOWN"
            },
            {
              "VENDOR_NAME": "DINKIN AND PURNELL"
            },
            {
              "VENDOR_NAME": "DINKIN AND PURNELL"
            },
            {
              "VENDOR_NAME": "DINKIN"
            },
            {
              "VENDOR_NAME": "DINWIDDIE COMBINED COURT"
            },
            {
              "VENDOR_NAME": "DIR OF FINANCE BALTIMORE"
            },
            {
              "VENDOR_NAME": "DIRECT TV BUSINESS SERVICE CTR."
            },
            {
              "VENDOR_NAME": "DIRECTOR OF FINANCE"
            },
            {
              "VENDOR_NAME": "DIRECTOR OF FINANCE"
            },
            {
              "VENDOR_NAME": "DIRECTTV"
            },
            {
              "VENDOR_NAME": "DIRECTV"
            },
            {
              "VENDOR_NAME": "DIRT KILLER PRESSURE"
            },
            {
              "VENDOR_NAME": "DISCOVER BANK"
            },
            {
              "VENDOR_NAME": "DISCOVERY BANK"
            },
            {
              "VENDOR_NAME": "DISKEEPER CORPORATION"
            },
            {
              "VENDOR_NAME": "DISPENSING SOLUTIONS (USA)"
            },
            {
              "VENDOR_NAME": "DISPENSING SYSTEMS INTERNATIONAL"
            },
            {
              "VENDOR_NAME": "DISPLAY SOURCE ALLIANCE"
            },
            {
              "VENDOR_NAME": "DISPLAY TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "DISTINCTIVE OFFICE FURNITURE"
            },
            {
              "VENDOR_NAME": "DISTRIBUTION MARKETING OF DELAWARE"
            },
            {
              "VENDOR_NAME": "DISTRICT 22D GOVERNOR'S BALL"
            },
            {
              "VENDOR_NAME": "DISTRICT COURT 15-2-05"
            },
            {
              "VENDOR_NAME": "DISTRICT COURT 15-2-07"
            },
            {
              "VENDOR_NAME": "DISTRICT COURT 32-2-44"
            },
            {
              "VENDOR_NAME": "DISTRICT JUSTICE 23-1-03"
            },
            {
              "VENDOR_NAME": "DIV OF REV STATE OF DE"
            },
            {
              "VENDOR_NAME": "DIVERSIFIED COLLECTION SER"
            },
            {
              "VENDOR_NAME": "DIVERSIFIED COLLECTION SERVICE"
            },
            {
              "VENDOR_NAME": "DIVERSIFIED COLLECTION SERVICES"
            },
            {
              "VENDOR_NAME": "DIVERSIFIED COLLECTION SERVICES"
            },
            {
              "VENDOR_NAME": "DIVERSIFIED COLLECTION SERVICES"
            },
            {
              "VENDOR_NAME": "DIVERSIFIED COLLECTIONS SERVICES"
            },
            {
              "VENDOR_NAME": "DIVERSIFIED INDUSTRIAL"
            },
            {
              "VENDOR_NAME": "DIVERSIFIED MEDIA GROUP"
            },
            {
              "VENDOR_NAME": "DIVERSIFIED METAL PRODUCTS"
            },
            {
              "VENDOR_NAME": "DIVERSIFIED SALES COMPANY"
            },
            {
              "VENDOR_NAME": "DIVERSITY INC MEDIA LLC"
            },
            {
              "VENDOR_NAME": "DIVERSITY JOBFAIRS.COM"
            },
            {
              "VENDOR_NAME": "DIVERSITY WORKING.COM"
            },
            {
              "VENDOR_NAME": "DIVISION OF MANAGEMENT SERVICES"
            },
            {
              "VENDOR_NAME": "DIVISION OF REVENUE"
            },
            {
              "VENDOR_NAME": "DK HOSPITALITY"
            },
            {
              "VENDOR_NAME": "DKR INVESTMENTS L.L.C."
            },
            {
              "VENDOR_NAME": "DKR INVESTMENTS"
            },
            {
              "VENDOR_NAME": "DL MANUFACTURING"
            },
            {
              "VENDOR_NAME": "DLC MANAGEMENT"
            },
            {
              "VENDOR_NAME": "DLP HOTEL MANAGEMENT"
            },
            {
              "VENDOR_NAME": "DM WELDING AND FENCE"
            },
            {
              "VENDOR_NAME": "DNB FIRST BANK"
            },
            {
              "VENDOR_NAME": "DOBEY CONSTRUCTION CO.INC."
            },
            {
              "VENDOR_NAME": "DOCUSENSE"
            },
            {
              "VENDOR_NAME": "DODSON BROS EXTERMINATING CO"
            },
            {
              "VENDOR_NAME": "DOLE PACKAGED FOODS"
            },
            {
              "VENDOR_NAME": "DOLORES H PRICE"
            },
            {
              "VENDOR_NAME": "DOLORES PATRICIA BUCKLER"
            },
            {
              "VENDOR_NAME": "DOLPHIN CONSULTING"
            },
            {
              "VENDOR_NAME": "DOMAN TRANSCRIBING & RECORDING SERV"
            },
            {
              "VENDOR_NAME": "DOMENIC'S PIZZA & RESTAURANT"
            },
            {
              "VENDOR_NAME": "DOMESTIC VIOLENCE CENTER"
            },
            {
              "VENDOR_NAME": "DOMINICK J.CAPPELLI"
            },
            {
              "VENDOR_NAME": "DOMINION LEADERSHIP TRUST"
            },
            {
              "VENDOR_NAME": "DOMINION LOCK & SECURITY"
            },
            {
              "VENDOR_NAME": "DOMINION PAVING & SEALING"
            },
            {
              "VENDOR_NAME": "DOMINION VIRGINA POWER"
            },
            {
              "VENDOR_NAME": "DOMINION VIRGINIA POWER"
            },
            {
              "VENDOR_NAME": "DOMINION VIRGINIA POWER-CHESAPEAKE"
            },
            {
              "VENDOR_NAME": "DOMINO FOODS INC."
            },
            {
              "VENDOR_NAME": "DOMINO FOODS"
            },
            {
              "VENDOR_NAME": "DOMUS"
            },
            {
              "VENDOR_NAME": "DON & DIANE KOSTELNIK"
            },
            {
              "VENDOR_NAME": "DON GIOVANNI'S"
            },
            {
              "VENDOR_NAME": "DON KANE"
            },
            {
              "VENDOR_NAME": "DON RANDALL"
            },
            {
              "VENDOR_NAME": "DONALD B. SEALING"
            },
            {
              "VENDOR_NAME": "DONALD C REYNOLDS"
            },
            {
              "VENDOR_NAME": "DONALD E.WRIGHT & CO."
            },
            {
              "VENDOR_NAME": "DONALD F.SCHOLL"
            },
            {
              "VENDOR_NAME": "DONALD H PRICE"
            },
            {
              "VENDOR_NAME": "DONALD H. & DOLORES H. PRICE T/E"
            },
            {
              "VENDOR_NAME": "DONALD H. &DOLORES H. PRICE T/E"
            },
            {
              "VENDOR_NAME": "DONALD H. PRICE"
            },
            {
              "VENDOR_NAME": "DONALD J. BROWN"
            },
            {
              "VENDOR_NAME": "DONALD J. SHOEMAKER COURT OFFICER"
            },
            {
              "VENDOR_NAME": "DONALD MAYER"
            },
            {
              "VENDOR_NAME": "DONALD P. KNECHEL"
            },
            {
              "VENDOR_NAME": "DONALD P. MALONEY"
            },
            {
              "VENDOR_NAME": "DONALD R DUCK"
            },
            {
              "VENDOR_NAME": "DONALD R DUCK JR."
            },
            {
              "VENDOR_NAME": "DONALD REDROW"
            },
            {
              "VENDOR_NAME": "DONALD RUBBO"
            },
            {
              "VENDOR_NAME": "DONALD SWORDS"
            },
            {
              "VENDOR_NAME": "DONALD V.BLAKE AND RUTH B.BLAKE"
            },
            {
              "VENDOR_NAME": "DONALD W. BEDELL"
            },
            {
              "VENDOR_NAME": "DONALD WEST"
            },
            {
              "VENDOR_NAME": "DONATO SPAVENTA"
            },
            {
              "VENDOR_NAME": "DON-B-FRANKS"
            },
            {
              "VENDOR_NAME": "DONNA CHRISTOPH"
            },
            {
              "VENDOR_NAME": "DONNA DAVIS"
            },
            {
              "VENDOR_NAME": "DONNA LASSITER"
            },
            {
              "VENDOR_NAME": "DONNA M HARE"
            },
            {
              "VENDOR_NAME": "DONNA M. DELLINGER"
            },
            {
              "VENDOR_NAME": "DONNA M.WATSON"
            },
            {
              "VENDOR_NAME": "DONNA MERTZ"
            },
            {
              "VENDOR_NAME": "DONNA VAN SANT"
            },
            {
              "VENDOR_NAME": "DONNA WILSON CURRY"
            },
            {
              "VENDOR_NAME": "DONNA WORBY"
            },
            {
              "VENDOR_NAME": "DONNA ZAGIEL"
            },
            {
              "VENDOR_NAME": "DONNELLY CONCRETE"
            },
            {
              "VENDOR_NAME": "DONNELLY NEWS"
            },
            {
              "VENDOR_NAME": "DON'S FOOD PRODUCTS"
            },
            {
              "VENDOR_NAME": "DONTAYA TRIPPETT"
            },
            {
              "VENDOR_NAME": "DOPACO INC"
            },
            {
              "VENDOR_NAME": "DOPACO"
            },
            {
              "VENDOR_NAME": "DORCESTER COUNTY COMMISSIONERS"
            },
            {
              "VENDOR_NAME": "DORCHESTER COUNTY COMMISSIONERS"
            },
            {
              "VENDOR_NAME": "DORCHESTER COUNTY HEALTH DEPARTMENT"
            },
            {
              "VENDOR_NAME": "DORCHESTER COUNTY TREASURER"
            },
            {
              "VENDOR_NAME": "DORCHESTER OWNERS ASSOC"
            },
            {
              "VENDOR_NAME": "DORCHESTER OWNERS ASSOC."
            },
            {
              "VENDOR_NAME": "DORIAN"
            },
            {
              "VENDOR_NAME": "DORIAN"
            },
            {
              "VENDOR_NAME": "DORIS ALEXANDER"
            },
            {
              "VENDOR_NAME": "DORIS FRYMOYER"
            },
            {
              "VENDOR_NAME": "DORIS HANSON"
            },
            {
              "VENDOR_NAME": "DORIS IERADI"
            },
            {
              "VENDOR_NAME": "DORIS J PAIGE"
            },
            {
              "VENDOR_NAME": "DORMONT MANUFACTURING CO."
            },
            {
              "VENDOR_NAME": "DORNEY PARK"
            },
            {
              "VENDOR_NAME": "DOROTHEA S WOOD"
            },
            {
              "VENDOR_NAME": "DOROTHY C.BOYCE"
            },
            {
              "VENDOR_NAME": "DOROTHY CAMPANA"
            },
            {
              "VENDOR_NAME": "DOROTHY SWARTZ"
            },
            {
              "VENDOR_NAME": "DOT FOODS"
            },
            {
              "VENDOR_NAME": "DOUBLE PLAY FOODS"
            },
            {
              "VENDOR_NAME": "DOUBLETREE GUEST SUITES"
            },
            {
              "VENDOR_NAME": "DOUBLETREE HOTEL"
            },
            {
              "VENDOR_NAME": "DOUG DOBSON"
            },
            {
              "VENDOR_NAME": "DOUG SCHULTZ"
            },
            {
              "VENDOR_NAME": "DOUGLAS H. WEST"
            },
            {
              "VENDOR_NAME": "DOUGLAS J SLICK"
            },
            {
              "VENDOR_NAME": "DOVER POLICE DEPARTMENT"
            },
            {
              "VENDOR_NAME": "DOVER POST"
            },
            {
              "VENDOR_NAME": "DOW FINANCE CORP"
            },
            {
              "VENDOR_NAME": "DOW FINANCE CORP."
            },
            {
              "VENDOR_NAME": "DOWN TO EARTH LANDSCAPE IRRIGATION"
            },
            {
              "VENDOR_NAME": "DOWNINGTOWN LIONS CLUB"
            },
            {
              "VENDOR_NAME": "DOWNINGTOWN LITTLE LEAGUE"
            },
            {
              "VENDOR_NAME": "DOWNINGTOWN MUNC WATER"
            },
            {
              "VENDOR_NAME": "DOWNINGTOWN MUNICIPAL"
            },
            {
              "VENDOR_NAME": "DOWNINGTOWN WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "DOWNINGTOWN YOUNG WHIPPETS"
            },
            {
              "VENDOR_NAME": "DOWNS FORD"
            },
            {
              "VENDOR_NAME": "DOYLESTOWN WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "DP SOLUTIONS"
            },
            {
              "VENDOR_NAME": "DQCI SERVICES INC"
            },
            {
              "VENDOR_NAME": "DR EARL L SHUFFORD"
            },
            {
              "VENDOR_NAME": "DR. JAMES L. SMITH"
            },
            {
              "VENDOR_NAME": "DR. THOMAS SCHLEICHER"
            },
            {
              "VENDOR_NAME": "DR.HUGH DOWDY"
            },
            {
              "VENDOR_NAME": "DR.MAGNUS HIRSCHFELD FUND"
            },
            {
              "VENDOR_NAME": "DR.PEPPER SNAPPLE GROUP"
            },
            {
              "VENDOR_NAME": "DR.PETER SANDMAN"
            },
            {
              "VENDOR_NAME": "DR.ROBERT DiTROLIO"
            },
            {
              "VENDOR_NAME": "DRATFIELD ANALYTICS"
            },
            {
              "VENDOR_NAME": "DREAM DECK DESIGNS"
            },
            {
              "VENDOR_NAME": "DREAM STITCHES"
            },
            {
              "VENDOR_NAME": "DREAM UNIVERSITY INC."
            },
            {
              "VENDOR_NAME": "DREAMS IN MOTION"
            },
            {
              "VENDOR_NAME": "DREXEL UNIVERSITY"
            },
            {
              "VENDOR_NAME": "DREXELBROOK ASSOC."
            },
            {
              "VENDOR_NAME": "DREXELBROOK ASSOC./RENTAL OFFICE"
            },
            {
              "VENDOR_NAME": "DRINKER BIDDLE & REATH"
            },
            {
              "VENDOR_NAME": "DrinkMore WATER"
            },
            {
              "VENDOR_NAME": "DRIVE OUT HUNGER GOLF CLASSIC"
            },
            {
              "VENDOR_NAME": "DRIVER'S ALERT INC"
            },
            {
              "VENDOR_NAME": "DRIVSAVERS"
            },
            {
              "VENDOR_NAME": "DRUMMOND AMERICAN CORP"
            },
            {
              "VENDOR_NAME": "DRUMORE CROSSING"
            },
            {
              "VENDOR_NAME": "DRW CONSULTANTS"
            },
            {
              "VENDOR_NAME": "DRYDEN ADVISORY GROUP"
            },
            {
              "VENDOR_NAME": "DRYING SOLUTIONS"
            },
            {
              "VENDOR_NAME": "DSLL"
            },
            {
              "VENDOR_NAME": "DT CREDIT CORP"
            },
            {
              "VENDOR_NAME": "DT CREDIT CORP."
            },
            {
              "VENDOR_NAME": "DT CREDIT CORPORATION"
            },
            {
              "VENDOR_NAME": "DTN CORP"
            },
            {
              "VENDOR_NAME": "DUANE HOLLENBECK"
            },
            {
              "VENDOR_NAME": "DUANE MORRIS LLP"
            },
            {
              "VENDOR_NAME": "DUANE PERRY"
            },
            {
              "VENDOR_NAME": "DUANE W. RUSSICK"
            },
            {
              "VENDOR_NAME": "DUBLIN VACUUM CLEANER SERVICE"
            },
            {
              "VENDOR_NAME": "DUCK ISLAND INC"
            },
            {
              "VENDOR_NAME": "DUCK ISLAND TERMINAL INC.-ACCRUAL"
            },
            {
              "VENDOR_NAME": "DUE AMICI ITALIAN GRILL & CATERING"
            },
            {
              "VENDOR_NAME": "DUFF SUPPLY COMPANY"
            },
            {
              "VENDOR_NAME": "DUFFERS TAVERN"
            },
            {
              "VENDOR_NAME": "DUFFIELD ASSOCIATES"
            },
            {
              "VENDOR_NAME": "DUKE CHILDREN'S HOSPITAL"
            },
            {
              "VENDOR_NAME": "DUKE DIET AND FITNESS CENTER"
            },
            {
              "VENDOR_NAME": "DULUTH TRADING COMPANY"
            },
            {
              "VENDOR_NAME": "DUN & BRADSTREET"
            },
            {
              "VENDOR_NAME": "DUNDALK EAGLE"
            },
            {
              "VENDOR_NAME": "DUNLAP MELLOR & CO INC"
            },
            {
              "VENDOR_NAME": "DURAFLAME"
            },
            {
              "VENDOR_NAME": "DurEquip"
            },
            {
              "VENDOR_NAME": "DURGIN VENDING COMPANY"
            },
            {
              "VENDOR_NAME": "DURHAM LTD"
            },
            {
              "VENDOR_NAME": "DURON PAINTS & WALL COVERINGS"
            },
            {
              "VENDOR_NAME": "DUTCHESS COUNTY SCU"
            },
            {
              "VENDOR_NAME": "DUTTON FOOD EQUIPMENT REPAIR"
            },
            {
              "VENDOR_NAME": "DWYER ENGINEERING"
            },
            {
              "VENDOR_NAME": "DYCHEM INTERNATIONAL LLC"
            },
            {
              "VENDOR_NAME": "DYCO"
            },
            {
              "VENDOR_NAME": "DYNAMETRIC"
            },
            {
              "VENDOR_NAME": "DYNAMIC BUSINESS SOLUTIONS LLC"
            },
            {
              "VENDOR_NAME": "DYNAMIC DESIGNS"
            },
            {
              "VENDOR_NAME": "DYNAMIC ENGINEERING CONSULTANTS"
            },
            {
              "VENDOR_NAME": "DYNATEC"
            },
            {
              "VENDOR_NAME": "E J BROOKS COMPANY"
            },
            {
              "VENDOR_NAME": "E L AMONSON SALES"
            },
            {
              "VENDOR_NAME": "E TENT"
            },
            {
              "VENDOR_NAME": "E. FRANK PUSEY III"
            },
            {
              "VENDOR_NAME": "E.CROWLEY & SONS"
            },
            {
              "VENDOR_NAME": "E.H.IVES CORPORATION"
            },
            {
              "VENDOR_NAME": "E.I.du PONT de NEMOURS & CO."
            },
            {
              "VENDOR_NAME": "E.O.HABHEGGER COMPANY"
            },
            {
              "VENDOR_NAME": "E.P.S.A."
            },
            {
              "VENDOR_NAME": "E.RANDOLPH WOOD"
            },
            {
              "VENDOR_NAME": "E.W.TAPPING SERVICES"
            },
            {
              "VENDOR_NAME": "E3 ENVIRONMENTAL"
            },
            {
              "VENDOR_NAME": "EAGLE DISPOSAL"
            },
            {
              "VENDOR_NAME": "EAGLE ERECTORS"
            },
            {
              "VENDOR_NAME": "EAGLE FINANCIAL INC"
            },
            {
              "VENDOR_NAME": "EAGLE INDUSTRIAL HYGIENE"
            },
            {
              "VENDOR_NAME": "EAGLE LIMOUSINE"
            },
            {
              "VENDOR_NAME": "EAGLE NATIONAL BANK 5K RUN"
            },
            {
              "VENDOR_NAME": "EAGLE POWER & EQUIPMENT CORP."
            },
            {
              "VENDOR_NAME": "EAGLE TRANSPORT CORP."
            },
            {
              "VENDOR_NAME": "EAGLES DIGEST"
            },
            {
              "VENDOR_NAME": "EAGLES STADIUM OPERATOR"
            },
            {
              "VENDOR_NAME": "EAGLES YOUTH PARTNERSHIP"
            },
            {
              "VENDOR_NAME": "EAISE DESIGN & LANDSCAPING CO."
            },
            {
              "VENDOR_NAME": "EAISE SNOW SERVICES LLC"
            },
            {
              "VENDOR_NAME": "EARL BONDS"
            },
            {
              "VENDOR_NAME": "EARL THOMPSON"
            },
            {
              "VENDOR_NAME": "EARLE'S TIRE SERVICE INC"
            },
            {
              "VENDOR_NAME": "EARTH ENGINEERING INC."
            },
            {
              "VENDOR_NAME": "EARTH ENGINEERING"
            },
            {
              "VENDOR_NAME": "EARTH TECH CONTRACTING"
            },
            {
              "VENDOR_NAME": "EARTHBORNE EQUIPMENT"
            },
            {
              "VENDOR_NAME": "EARTHCARE-EAGLE"
            },
            {
              "VENDOR_NAME": "EARTHSENSE PRODUCTS.COM"
            },
            {
              "VENDOR_NAME": "EAST BRADYWINE FIRE COMPANY"
            },
            {
              "VENDOR_NAME": "EAST BRANDYWINE TOWNSHIP"
            },
            {
              "VENDOR_NAME": "EAST BROWN MEDICAL ASSOCIATES"
            },
            {
              "VENDOR_NAME": "EAST BRUNSWICK BASEBALL LEAGUE"
            },
            {
              "VENDOR_NAME": "EAST BRUNSWICK INDEPENDENT FIRE CO"
            },
            {
              "VENDOR_NAME": "EAST BRUNSWICK SEWERAGE"
            },
            {
              "VENDOR_NAME": "EAST BRUNSWICK WATER UTIL"
            },
            {
              "VENDOR_NAME": "EAST CALN TOWNSHIP"
            },
            {
              "VENDOR_NAME": "EAST CALN TWP WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "EAST DOVER VOLUNTEER FIRE CO NO4"
            },
            {
              "VENDOR_NAME": "EAST END PLASTICS"
            },
            {
              "VENDOR_NAME": "EAST GOSHEN TOWNSHIP"
            },
            {
              "VENDOR_NAME": "EAST GOSHEN WAGE TAXE PAYABLE"
            },
            {
              "VENDOR_NAME": "EAST GREENWICH FIRE &"
            },
            {
              "VENDOR_NAME": "EAST HEMPFIELD TWP"
            },
            {
              "VENDOR_NAME": "EAST HEMPFIELD TWP."
            },
            {
              "VENDOR_NAME": "EAST HEMPFIELD TWP.POLICE DEPT."
            },
            {
              "VENDOR_NAME": "EAST LAMPETER TOWNSHIP"
            },
            {
              "VENDOR_NAME": "EAST LAMPETER TWP.c/o FULTON BANK"
            },
            {
              "VENDOR_NAME": "EAST MARLBORO ASSOCIATES"
            },
            {
              "VENDOR_NAME": "EAST MARLBOROUGH TOWNSHIP"
            },
            {
              "VENDOR_NAME": "EAST NORRITON TOWNSHIP"
            },
            {
              "VENDOR_NAME": "EAST NORRITON WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "EAST NOTTINGHAM TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "EAST PIKELAND TOWNSHIP"
            },
            {
              "VENDOR_NAME": "EAST PIKELAND TWP WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "EAST STROUDSBURG TAX COLL"
            },
            {
              "VENDOR_NAME": "EAST STROUDSBURG WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "EAST VINCENT MUNICIPAL"
            },
            {
              "VENDOR_NAME": "EAST VINCENT TOWNSHIPT TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "EAST VINELAND FARM & LITTLE LEAGUE"
            },
            {
              "VENDOR_NAME": "EAST WHITELAND TOWNSHIP"
            },
            {
              "VENDOR_NAME": "EAST WHITELAND TOWNSHIP TAX COLLECT"
            },
            {
              "VENDOR_NAME": "EAST WHITELAND TWP TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "EAST WINDSOR TOWNSHIP"
            },
            {
              "VENDOR_NAME": "EASTBURN AND GRAY"
            },
            {
              "VENDOR_NAME": "EASTBURN AND GRAY"
            },
            {
              "VENDOR_NAME": "EASTER SEALS OF DELAWARE"
            },
            {
              "VENDOR_NAME": "EASTER SEALS SOCIETY"
            },
            {
              "VENDOR_NAME": "EASTER SEALS VIRGINIA"
            },
            {
              "VENDOR_NAME": "EASTERN CONTROLS INC OF PA"
            },
            {
              "VENDOR_NAME": "EASTERN DOOR SERVICE"
            },
            {
              "VENDOR_NAME": "EASTERN ENVIROMENTAL CONTRACTORS"
            },
            {
              "VENDOR_NAME": "EASTERN GENERATOR SALES"
            },
            {
              "VENDOR_NAME": "EASTERN LIFT TRUCK CO."
            },
            {
              "VENDOR_NAME": "EASTERN LOGISTICS INC."
            },
            {
              "VENDOR_NAME": "EASTERN LOGISTICS"
            },
            {
              "VENDOR_NAME": "EASTERN MACHINE"
            },
            {
              "VENDOR_NAME": "EASTERN SHORE GAS CO."
            },
            {
              "VENDOR_NAME": "EASTERN TECH ATHLETIC BOOSTERS"
            },
            {
              "VENDOR_NAME": "EASTERN TECHNOLOGY COUNCIL"
            },
            {
              "VENDOR_NAME": "EASTON AREA EARNED"
            },
            {
              "VENDOR_NAME": "EASTON AREA SCHOOL DIST"
            },
            {
              "VENDOR_NAME": "EASTON COACH COMPANY"
            },
            {
              "VENDOR_NAME": "EASTON ICE CO INC"
            },
            {
              "VENDOR_NAME": "EASTON NEWS CO"
            },
            {
              "VENDOR_NAME": "EASTON SUBURBAN WATER AUTHORITY"
            },
            {
              "VENDOR_NAME": "EASTON UTILITIES"
            },
            {
              "VENDOR_NAME": "EASTON WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "EASTONTOWN ROLLER RINK"
            },
            {
              "VENDOR_NAME": "EASTTOWN TOWNSHIP POLICE ASSOC"
            },
            {
              "VENDOR_NAME": "EASYLINK SERVICES CORP."
            },
            {
              "VENDOR_NAME": "EAT'N PARK HOSPITALITY GROUP"
            },
            {
              "VENDOR_NAME": "EB REALTY MGMT CORP"
            },
            {
              "VENDOR_NAME": "EBC CARPET SERVICES"
            },
            {
              "VENDOR_NAME": "EBCT"
            },
            {
              "VENDOR_NAME": "EBIX"
            },
            {
              "VENDOR_NAME": "EBYACP"
            },
            {
              "VENDOR_NAME": "EC LINER INC."
            },
            {
              "VENDOR_NAME": "ECCE PANIS"
            },
            {
              "VENDOR_NAME": "ECCO/GREGORY INC"
            },
            {
              "VENDOR_NAME": "ECHOSAT COMMUNICATIONS GROUP"
            },
            {
              "VENDOR_NAME": "ECI REALTY PARTNERSHIP"
            },
            {
              "VENDOR_NAME": "ECM"
            },
            {
              "VENDOR_NAME": "ecMARKETS"
            },
            {
              "VENDOR_NAME": "ECMC"
            },
            {
              "VENDOR_NAME": "ECMC LOCKBOX #7096"
            },
            {
              "VENDOR_NAME": "ECMC -PAPA DC 489-70-3226"
            },
            {
              "VENDOR_NAME": "ECOLAB"
            },
            {
              "VENDOR_NAME": "ECOLAB"
            },
            {
              "VENDOR_NAME": "ECONOMY LEAGUE OF GREATER PHILA."
            },
            {
              "VENDOR_NAME": "ECORCAL L P"
            },
            {
              "VENDOR_NAME": "ECO-SCIENCE PROFESSIONALS"
            },
            {
              "VENDOR_NAME": "ECOSURE"
            },
            {
              "VENDOR_NAME": "ECS-MID-ATLANTIC"
            },
            {
              "VENDOR_NAME": "ED FUND"
            },
            {
              "VENDOR_NAME": "ED HARDING"
            },
            {
              "VENDOR_NAME": "ED IAMES"
            },
            {
              "VENDOR_NAME": "EDFUND ACCT REC"
            },
            {
              "VENDOR_NAME": "EDFUND POST DEFAULT SERVICES"
            },
            {
              "VENDOR_NAME": "EDGAR ALDERTON"
            },
            {
              "VENDOR_NAME": "EDGAR M. HERR"
            },
            {
              "VENDOR_NAME": "EDGAR'S GARDEN CENTER & FLORIST"
            },
            {
              "VENDOR_NAME": "EDGEBORO INTERNATIONAL"
            },
            {
              "VENDOR_NAME": "EDGEWOOD NEWS"
            },
            {
              "VENDOR_NAME": "EDGEWORTH INC."
            },
            {
              "VENDOR_NAME": "EDGMONT TOWNSHIP"
            },
            {
              "VENDOR_NAME": "EDIBLE ARRANGEMENTS"
            },
            {
              "VENDOR_NAME": "EDINBURGH EAST"
            },
            {
              "VENDOR_NAME": "EDISON TOWNSHIP"
            },
            {
              "VENDOR_NAME": "EDITH HINTZ"
            },
            {
              "VENDOR_NAME": "EDITH WALDEN"
            },
            {
              "VENDOR_NAME": "EDM"
            },
            {
              "VENDOR_NAME": "EDMEYER INC"
            },
            {
              "VENDOR_NAME": "EDUCATION DEBT SERV.INC."
            },
            {
              "VENDOR_NAME": "EDUCATIONAL CREDIT MANGMT CORP"
            },
            {
              "VENDOR_NAME": "EDUCATIONAL CREDIT MGMT CORP"
            },
            {
              "VENDOR_NAME": "EDWARD CURTAIN"
            },
            {
              "VENDOR_NAME": "EDWARD DYER"
            },
            {
              "VENDOR_NAME": "EDWARD F. MCSHERRY"
            },
            {
              "VENDOR_NAME": "EDWARD GLOVER"
            },
            {
              "VENDOR_NAME": "EDWARD J LAIN"
            },
            {
              "VENDOR_NAME": "EDWARD KURTH & SONS"
            },
            {
              "VENDOR_NAME": "EDWARD POWELL PUMP/WELL DRILLING"
            },
            {
              "VENDOR_NAME": "EDWARD S. DEHN"
            },
            {
              "VENDOR_NAME": "EDWARD SANCHEZ"
            },
            {
              "VENDOR_NAME": "EDWARD T. IAMES"
            },
            {
              "VENDOR_NAME": "EDWARD T.IAMES"
            },
            {
              "VENDOR_NAME": "EDWARD WILSON"
            },
            {
              "VENDOR_NAME": "EDWARDS LANDSCAPE & NURSERY"
            },
            {
              "VENDOR_NAME": "EDWARDS MEDICAL SUPPLY"
            },
            {
              "VENDOR_NAME": "EDWIN STIPE INC"
            },
            {
              "VENDOR_NAME": "E-FILLIATE INC."
            },
            {
              "VENDOR_NAME": "EFT WESTERN PET CO"
            },
            {
              "VENDOR_NAME": "EGG HARBOR CITY"
            },
            {
              "VENDOR_NAME": "EGG HARBOR CITY FIRE DEPT."
            },
            {
              "VENDOR_NAME": "EGG HARBOR TOWNSHIP"
            },
            {
              "VENDOR_NAME": "EGG HARBOR TOWNSHIP POLICE DEPT"
            },
            {
              "VENDOR_NAME": "EGG HARBOR TWP M U A"
            },
            {
              "VENDOR_NAME": "EGGS PARTNERSHIP"
            },
            {
              "VENDOR_NAME": "EHS ENVIROMENTAL"
            },
            {
              "VENDOR_NAME": "EHT BASEBALL BOOSTER CLUB"
            },
            {
              "VENDOR_NAME": "EILEEN A RAFFAELE"
            },
            {
              "VENDOR_NAME": "EILEEN LAZARUS"
            },
            {
              "VENDOR_NAME": "EILEEN RAFFAELE"
            },
            {
              "VENDOR_NAME": "EILEEN SHEEHAN"
            },
            {
              "VENDOR_NAME": "EIMICKE"
            },
            {
              "VENDOR_NAME": "EINSTEIN MEDICAL CENTER"
            },
            {
              "VENDOR_NAME": "EIRC-SJVSAF"
            },
            {
              "VENDOR_NAME": "EISNER AND FOWLER"
            },
            {
              "VENDOR_NAME": "ELBA CORTES 372187"
            },
            {
              "VENDOR_NAME": "ELBOW RIVER MARKETING LTD"
            },
            {
              "VENDOR_NAME": "ELEANOR BROWNING"
            },
            {
              "VENDOR_NAME": "ELEANOR DARRENKAMP"
            },
            {
              "VENDOR_NAME": "ELEANOR HABICHT"
            },
            {
              "VENDOR_NAME": "ELEANOR MACARI"
            },
            {
              "VENDOR_NAME": "ELECTRIC SEWER SERVICE"
            },
            {
              "VENDOR_NAME": "ELECTRONIC ENVIRONMENTS CORP"
            },
            {
              "VENDOR_NAME": "ELECTRONIC SECURITY CORP."
            },
            {
              "VENDOR_NAME": "ELECTRONIS SYSTEMS"
            },
            {
              "VENDOR_NAME": "ELEGANT LIMOUSINE SERVICE"
            },
            {
              "VENDOR_NAME": "ELEMENT K CORPORATION"
            },
            {
              "VENDOR_NAME": "ELEUTHERE I du PONT II"
            },
            {
              "VENDOR_NAME": "ELEUTHERIAN MILLS-HAGLEY"
            },
            {
              "VENDOR_NAME": "ELFREDA EKWUNIFE"
            },
            {
              "VENDOR_NAME": "ELIAS ADAMOPOULOS"
            },
            {
              "VENDOR_NAME": "ELIAS N. DOW"
            },
            {
              "VENDOR_NAME": "ELISE D. ZOLLER"
            },
            {
              "VENDOR_NAME": "ELISE R du PONT"
            },
            {
              "VENDOR_NAME": "ELISSA MATTEOTTI"
            },
            {
              "VENDOR_NAME": "ELITE ENTERTAINMENT"
            },
            {
              "VENDOR_NAME": "ELITE PROPERTIES"
            },
            {
              "VENDOR_NAME": "ELIZABETH BEVERAGE COMPANY"
            },
            {
              "VENDOR_NAME": "ELIZABETH C. REXFORD"
            },
            {
              "VENDOR_NAME": "ELIZABETH GREW"
            },
            {
              "VENDOR_NAME": "ELIZABETH J MARRON"
            },
            {
              "VENDOR_NAME": "ELIZABETH J WOOD"
            },
            {
              "VENDOR_NAME": "ELIZABETH K. SANDS"
            },
            {
              "VENDOR_NAME": "ELIZABETH NEWLIN CARNEY"
            },
            {
              "VENDOR_NAME": "ELIZABETH RIGGS"
            },
            {
              "VENDOR_NAME": "ELIZABETH WAUGH-STEWART"
            },
            {
              "VENDOR_NAME": "ELIZABETHTOWN GAS"
            },
            {
              "VENDOR_NAME": "ELIZABETHTOWN WATER CO"
            },
            {
              "VENDOR_NAME": "ELKAY SALES"
            },
            {
              "VENDOR_NAME": "ELKIND AND DIMENTO P.A."
            },
            {
              "VENDOR_NAME": "ELKO & ASSOCIATES LTD"
            },
            {
              "VENDOR_NAME": "ELKTON GAS"
            },
            {
              "VENDOR_NAME": "ELLEN ETHERIDGE"
            },
            {
              "VENDOR_NAME": "ELLEN LOUISE COHEN"
            },
            {
              "VENDOR_NAME": "ELLEN MITCHELL"
            },
            {
              "VENDOR_NAME": "ELLEN TRACEY MERCURIO"
            },
            {
              "VENDOR_NAME": "ELLEN W. COSBY"
            },
            {
              "VENDOR_NAME": "ELLEN W.COSBY"
            },
            {
              "VENDOR_NAME": "ELLENBY TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "ELLIE HAMEL"
            },
            {
              "VENDOR_NAME": "ELLIOTT MACHINE COMPANY"
            },
            {
              "VENDOR_NAME": "ELMARK PACKAGING"
            },
            {
              "VENDOR_NAME": "ELMER AMBULANCE CORP"
            },
            {
              "VENDOR_NAME": "ELMER SCHULTZ SERVICES"
            },
            {
              "VENDOR_NAME": "ELMER TIMES"
            },
            {
              "VENDOR_NAME": "EL'S TRUCK TIRE CENTER"
            },
            {
              "VENDOR_NAME": "E-MAC DAIRY BRUSH CO"
            },
            {
              "VENDOR_NAME": "EMANUEL & CONSTANCE VASILIADES"
            },
            {
              "VENDOR_NAME": "EMANUEL AND CONSTANCE VASILIADES"
            },
            {
              "VENDOR_NAME": "EMBARQ"
            },
            {
              "VENDOR_NAME": "EMBASSY SUITES HOTEL"
            },
            {
              "VENDOR_NAME": "EMBASSY SUITES HOTEL TAMPA"
            },
            {
              "VENDOR_NAME": "EMBASSY SUITES INTERNATIONAL DR"
            },
            {
              "VENDOR_NAME": "EMBASSY SUITES PARSIPPANY"
            },
            {
              "VENDOR_NAME": "EMCOR SERVICES FLUIDICS"
            },
            {
              "VENDOR_NAME": "EMED CO."
            },
            {
              "VENDOR_NAME": "EMERGENCY SER ASSOC"
            },
            {
              "VENDOR_NAME": "EMERGENCY SERVICE ASSOC"
            },
            {
              "VENDOR_NAME": "EMERGENCY SERVICE ASSOCIATE"
            },
            {
              "VENDOR_NAME": "EMERGENCY TRAINING XCELLENCE"
            },
            {
              "VENDOR_NAME": "EMERSON NETWORK POWER"
            },
            {
              "VENDOR_NAME": "EMG CORPORATION"
            },
            {
              "VENDOR_NAME": "EMILIE JUNE SPRING"
            },
            {
              "VENDOR_NAME": "EMILIE OBRIE CORBIN"
            },
            {
              "VENDOR_NAME": "EMILIE W CORBIN"
            },
            {
              "VENDOR_NAME": "EMILY ROSSITER"
            },
            {
              "VENDOR_NAME": "EMK CONSULTANTS OF FLORIDA"
            },
            {
              "VENDOR_NAME": "EMMANUEL CANCER FOUNDATION"
            },
            {
              "VENDOR_NAME": "EMMANUEL DINING ROOM"
            },
            {
              "VENDOR_NAME": "EMMS ELECTRIC INC."
            },
            {
              "VENDOR_NAME": "EMORY HILL AND COMPANY"
            },
            {
              "VENDOR_NAME": "EMPIRE HOOK AND LADDER"
            },
            {
              "VENDOR_NAME": "EMPRO PRODUCTS CO INC"
            },
            {
              "VENDOR_NAME": "EMPSIGHT INTERNATIONAL"
            },
            {
              "VENDOR_NAME": "EMR"
            },
            {
              "VENDOR_NAME": "EMSL ANALYTICAL"
            },
            {
              "VENDOR_NAME": "END ZONE ATHLETICS"
            },
            {
              "VENDOR_NAME": "ENDRESS + HAUSER"
            },
            {
              "VENDOR_NAME": "ENERCON"
            },
            {
              "VENDOR_NAME": "ENERGY MANAGEMENT INSTITUTE"
            },
            {
              "VENDOR_NAME": "ENERGY MANAGEMENT SYSTEMS"
            },
            {
              "VENDOR_NAME": "ENERGY PRODUCTS COMPANY"
            },
            {
              "VENDOR_NAME": "ENERGY REALTY OP"
            },
            {
              "VENDOR_NAME": "ENERGY RECRUITERS"
            },
            {
              "VENDOR_NAME": "ENERGY SOLUTIONS LLC"
            },
            {
              "VENDOR_NAME": "ENFORCEMENT DIVISION"
            },
            {
              "VENDOR_NAME": "ENGINE 58 FUND"
            },
            {
              "VENDOR_NAME": "ENGINEERING CONSULTING"
            },
            {
              "VENDOR_NAME": "ENGINEERING SERVICES"
            },
            {
              "VENDOR_NAME": "ENGKOH"
            },
            {
              "VENDOR_NAME": "ENGLAND RUN NORTH APTS"
            },
            {
              "VENDOR_NAME": "ENGLISHTOWN-MANALAPAN"
            },
            {
              "VENDOR_NAME": "ENI TRADING & SHIPPING INC"
            },
            {
              "VENDOR_NAME": "ENNIS AUTO DETAILING"
            },
            {
              "VENDOR_NAME": "ENNIS OIL&BURNER SERV INC"
            },
            {
              "VENDOR_NAME": "ENON YOUTH ATHLETIC ASSOCIATION"
            },
            {
              "VENDOR_NAME": "ENRIQUE RAMOS"
            },
            {
              "VENDOR_NAME": "ENSEMBLE BEVERAGE COMPANY LLC"
            },
            {
              "VENDOR_NAME": "ENTEGRIS"
            },
            {
              "VENDOR_NAME": "ENTENMANN/BIMBO FOODS INC"
            },
            {
              "VENDOR_NAME": "ENTERASYS NETWORKS"
            },
            {
              "VENDOR_NAME": "ENTERPRISE APPRAISAL CO."
            },
            {
              "VENDOR_NAME": "ENTERPRISE LEASING"
            },
            {
              "VENDOR_NAME": "ENTERPRISE RENT-A-CAR"
            },
            {
              "VENDOR_NAME": "ENTERTAINMENT ON LOCATION"
            },
            {
              "VENDOR_NAME": "ENTZIAN ENTERPRISES"
            },
            {
              "VENDOR_NAME": "ENVIROMENTAL HEALTH BILLING"
            },
            {
              "VENDOR_NAME": "ENVIROMENTAL LIABILITY MANAGEMENT"
            },
            {
              "VENDOR_NAME": "ENVIROMENTAL RESOURCE SYSTEMS"
            },
            {
              "VENDOR_NAME": "ENVIROMENTAL WASTE MINIMIZATION"
            },
            {
              "VENDOR_NAME": "ENVIRONMENTAL ALLIANCE"
            },
            {
              "VENDOR_NAME": "ENVIRONMENTAL CONSULTING"
            },
            {
              "VENDOR_NAME": "ENVIROTEC"
            },
            {
              "VENDOR_NAME": "ENVIROTROL INC"
            },
            {
              "VENDOR_NAME": "ENVISION IMAGES"
            },
            {
              "VENDOR_NAME": "ENVISION"
            },
            {
              "VENDOR_NAME": "EPATH LEARNING"
            },
            {
              "VENDOR_NAME": "EPEX"
            },
            {
              "VENDOR_NAME": "EPHRATA AREA SCHOOL DIST"
            },
            {
              "VENDOR_NAME": "EPHRATA OCC.PRIV TAX"
            },
            {
              "VENDOR_NAME": "EPILEPSY FOUNDATION OF"
            },
            {
              "VENDOR_NAME": "EQUIP RENTAL & CONST INC"
            },
            {
              "VENDOR_NAME": "EQUIPMENT DEPOT"
            },
            {
              "VENDOR_NAME": "EQUIPMENT RESOURCES"
            },
            {
              "VENDOR_NAME": "EQUIPMENT TRADE SERVICE CO INC"
            },
            {
              "VENDOR_NAME": "EQUIVEST DEVELOPEMENT INC"
            },
            {
              "VENDOR_NAME": "ERCO CEILINGS"
            },
            {
              "VENDOR_NAME": "ERI ECONOMIC RESEARCH INSTITUTE"
            },
            {
              "VENDOR_NAME": "ERIC BRAM & CO."
            },
            {
              "VENDOR_NAME": "ERIC D. MORANO"
            },
            {
              "VENDOR_NAME": "ERIC KLAG"
            },
            {
              "VENDOR_NAME": "ERIC MEIS"
            },
            {
              "VENDOR_NAME": "ERIC POOLE"
            },
            {
              "VENDOR_NAME": "ERIN LYDON MCGOVERN"
            },
            {
              "VENDOR_NAME": "ERIN ZIMMERMAN"
            },
            {
              "VENDOR_NAME": "ERJJ"
            },
            {
              "VENDOR_NAME": "ERMA VOLUNTEER FIRE CO"
            },
            {
              "VENDOR_NAME": "ERNEST M. CONGO FUNERAL HOME"
            },
            {
              "VENDOR_NAME": "ESHA RESEARCH"
            },
            {
              "VENDOR_NAME": "ESI INTERNATIONAL"
            },
            {
              "VENDOR_NAME": "ESRI"
            },
            {
              "VENDOR_NAME": "ESTATE OF BETTY J. SWARTZ"
            },
            {
              "VENDOR_NAME": "ESTATE OF CATHERINE E.DENNELER"
            },
            {
              "VENDOR_NAME": "ESTATE OF EARL S GEHMAN"
            },
            {
              "VENDOR_NAME": "ESTATE OF FREDERIC S. SCHROEDER"
            },
            {
              "VENDOR_NAME": "ESTATE OF FREDERIC S. SCHROEDER JR"
            },
            {
              "VENDOR_NAME": "ESTATE OF GRACE MEYERS C/O"
            },
            {
              "VENDOR_NAME": "ESTATE OF HELEN BERENT"
            },
            {
              "VENDOR_NAME": "ESTATE OF HENRY STURGIS GREW"
            },
            {
              "VENDOR_NAME": "ESTATE OF MAE K ENGLAND"
            },
            {
              "VENDOR_NAME": "ESTATE OF S C WOOD THOMPSON"
            },
            {
              "VENDOR_NAME": "ESTATE OF STANLEY E. LLOYD"
            },
            {
              "VENDOR_NAME": "ESTATE OF STANLEY YESKOLSKE"
            },
            {
              "VENDOR_NAME": "ESW WESTERN BRANCH ASSOC."
            },
            {
              "VENDOR_NAME": "ETC."
            },
            {
              "VENDOR_NAME": "ETHAN C.ROSSITER"
            },
            {
              "VENDOR_NAME": "ETHOSOURCE LLC"
            },
            {
              "VENDOR_NAME": "EUGENE A. DELLE DONNE & SON"
            },
            {
              "VENDOR_NAME": "EUGENE ARNOLD JR NON EXEMPT"
            },
            {
              "VENDOR_NAME": "EUGENE LYNCH"
            },
            {
              "VENDOR_NAME": "EUREKA METAL & GLASS"
            },
            {
              "VENDOR_NAME": "EVAN MESSINGER"
            },
            {
              "VENDOR_NAME": "EVANS MILL ENVIROMENTAL"
            },
            {
              "VENDOR_NAME": "EVANS RESEARCH"
            },
            {
              "VENDOR_NAME": "EVB TOWING"
            },
            {
              "VENDOR_NAME": "EVCO INDUSTRIES"
            },
            {
              "VENDOR_NAME": "EVELYN J.MILLS"
            },
            {
              "VENDOR_NAME": "EVENING BULLETIN"
            },
            {
              "VENDOR_NAME": "EVENTS UNLIMITED"
            },
            {
              "VENDOR_NAME": "EVERBRITE"
            },
            {
              "VENDOR_NAME": "EVERGREEN LANDSCAPING &"
            },
            {
              "VENDOR_NAME": "EVERGREEN PACKAGING"
            },
            {
              "VENDOR_NAME": "EVERGREEN PACKAGING INC"
            },
            {
              "VENDOR_NAME": "EVESHAM FIRE DIST #1"
            },
            {
              "VENDOR_NAME": "EVESHAM MUNUCIPAL UTILITY"
            },
            {
              "VENDOR_NAME": "EWA TRUCK/TRAILER REPAIR"
            },
            {
              "VENDOR_NAME": "EWS WESTERN BRANCH ASSOC."
            },
            {
              "VENDOR_NAME": "EXACT STROKE INC"
            },
            {
              "VENDOR_NAME": "EXCEL DRYER"
            },
            {
              "VENDOR_NAME": "EXCEL SILKSCREENING"
            },
            {
              "VENDOR_NAME": "EXCEL TIRE GAUGE"
            },
            {
              "VENDOR_NAME": "EXCHANGE CLUB FAMILY CENTER"
            },
            {
              "VENDOR_NAME": "EXCHANGE CLUB OF PENNSGROVE"
            },
            {
              "VENDOR_NAME": "EXCHANGE CLUB OF WEST CHESTER"
            },
            {
              "VENDOR_NAME": "EXCITATIONS"
            },
            {
              "VENDOR_NAME": "EXECUTIVE LEADERSHIP"
            },
            {
              "VENDOR_NAME": "EXECUTIVE PRESS"
            },
            {
              "VENDOR_NAME": "EXECUTIVE SOFTWARE INC"
            },
            {
              "VENDOR_NAME": "EXETER TOWNSHIP"
            },
            {
              "VENDOR_NAME": "EXETER TOWNSHIP TAX COLLECTOR"
            },
            {
              "VENDOR_NAME": "EXPEDITE"
            },
            {
              "VENDOR_NAME": "EXPERT PARKING MGMT"
            },
            {
              "VENDOR_NAME": "EXPERT SERVER GROUP"
            },
            {
              "VENDOR_NAME": "EXTENDED STAY AMERICA"
            },
            {
              "VENDOR_NAME": "EXTENDED STAY HOTELS"
            },
            {
              "VENDOR_NAME": "EXTENDED TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "EXTENSITY"
            },
            {
              "VENDOR_NAME": "EXTON NEWS AGENCY"
            },
            {
              "VENDOR_NAME": "EXTON REGION CHAMBER OF COMMERCE"
            },
            {
              "VENDOR_NAME": "EXTRA NEWS"
            },
            {
              "VENDOR_NAME": "EXTRA SPACE STORAGE MORRISVILLE"
            },
            {
              "VENDOR_NAME": "EXTREME COFFEE"
            },
            {
              "VENDOR_NAME": "EXTREME INSULATION"
            },
            {
              "VENDOR_NAME": "EXXON MOBIL OIL CORPORATION"
            },
            {
              "VENDOR_NAME": "EXXONMOBIL CORP"
            },
            {
              "VENDOR_NAME": "EYKON WALLCOVERING SOURCE"
            },
            {
              "VENDOR_NAME": "EYP MISSION CRITICAL FACILITIES"
            },
            {
              "VENDOR_NAME": "EZ CASH OF DELAWARE"
            },
            {
              "VENDOR_NAME": "EZ LOANS"
            },
            {
              "VENDOR_NAME": "EZ LOANS INC"
            },
            {
              "VENDOR_NAME": "EZ LOANS INC @1724"
            },
            {
              "VENDOR_NAME": "EZ LOANS INC. @ 1724"
            },
            {
              "VENDOR_NAME": "EZ LOANS INC@ 1724"
            },
            {
              "VENDOR_NAME": "EZ LOANS"
            },
            {
              "VENDOR_NAME": "EZ LOANS"
            },
            {
              "VENDOR_NAME": "EZ LOANS"
            },
            {
              "VENDOR_NAME": "EZLOAN@1724"
            },
            {
              "VENDOR_NAME": "E-ZPASS MARYLAND"
            },
            {
              "VENDOR_NAME": "F FACCIOLO & WM F BARRETT"
            },
            {
              "VENDOR_NAME": "F.C.F. ASSOCIATES"
            },
            {
              "VENDOR_NAME": "F.W.BAIRD GENERAL CONTRACTOR"
            },
            {
              "VENDOR_NAME": "FAB 4 VENDING LLC"
            },
            {
              "VENDOR_NAME": "FABRI-KAL"
            },
            {
              "VENDOR_NAME": "FACADE TECHNOLOGY"
            },
            {
              "VENDOR_NAME": "FACILITIES SOLUTIONS GROUP"
            },
            {
              "VENDOR_NAME": "FACILITY ROBOTICS"
            },
            {
              "VENDOR_NAME": "FAIA & FRICK"
            },
            {
              "VENDOR_NAME": "FAIR ACRES GERIATRIC CENTER"
            },
            {
              "VENDOR_NAME": "FAIRFAX APARTMENTS"
            },
            {
              "VENDOR_NAME": "FAIRFAX CO GEN DIST CT TRAFFIC"
            },
            {
              "VENDOR_NAME": "FAIRFAX CO GEN'L DIST COURT"
            },
            {
              "VENDOR_NAME": "FAIRFAX CO.GEN.DIST.COURT-TRAFFIC"
            },
            {
              "VENDOR_NAME": "FAIRFAX COUNTY GEN.DIST.COURT TRAFF"
            },
            {
              "VENDOR_NAME": "FAIRFAX GEN'L DIST COURT"
            },
            {
              "VENDOR_NAME": "FAIRFAX GEN'L DIST CT TRAFFIC"
            },
            {
              "VENDOR_NAME": "FAIRFIELD INN & SUITES BY MARRIOTT"
            },
            {
              "VENDOR_NAME": "FAIRMONT PRODUCTS INC"
            },
            {
              "VENDOR_NAME": "FAIRMONT PRODUCTS"
            },
            {
              "VENDOR_NAME": "FAIRMOUNT PARK COMMISSION"
            },
            {
              "VENDOR_NAME": "FAIRWINDS ASSOC. LLC"
            },
            {
              "VENDOR_NAME": "FAITH 1ST EDUC.ASST. CORP."
            },
            {
              "VENDOR_NAME": "FAITH FELLOWSHIP MINISTRIES"
            },
            {
              "VENDOR_NAME": "FAITHFUL FRIENDS"
            },
            {
              "VENDOR_NAME": "FALCON FABRICATORS INC"
            },
            {
              "VENDOR_NAME": "FALL HILL PEDIATRICS"
            },
            {
              "VENDOR_NAME": "FALLS TWSP TAX COLLECTOR"
            },
            {
              "VENDOR_NAME": "FAMCARE"
            },
            {
              "VENDOR_NAME": "FAMECO PROPERTY MGMT SERVICES"
            },
            {
              "VENDOR_NAME": "FAMILIES FOR AUTISTIC CHILDREN"
            },
            {
              "VENDOR_NAME": "FAMILY AND COMMUNITY SERVICE OF"
            },
            {
              "VENDOR_NAME": "FAMILY COURT OF DE."
            },
            {
              "VENDOR_NAME": "FAMILY CRISIS CENTER OF"
            },
            {
              "VENDOR_NAME": "FAMILY FINANCE CORP."
            },
            {
              "VENDOR_NAME": "FAMILY FINANCIAL CORP"
            },
            {
              "VENDOR_NAME": "FAMILY FINANCIAL GROUP"
            },
            {
              "VENDOR_NAME": "FAMILY GUIDANCE CENTER CORP"
            },
            {
              "VENDOR_NAME": "FAMILY SERVICES OF CHESTER COUNTY"
            },
            {
              "VENDOR_NAME": "FAMILY SUPPORT LINE"
            },
            {
              "VENDOR_NAME": "FAMILY SUPPORT PAYMENT CENTER"
            },
            {
              "VENDOR_NAME": "FAMILY SUPPORT REGISTRY"
            },
            {
              "VENDOR_NAME": "FAMOUS DAVE'S"
            },
            {
              "VENDOR_NAME": "FAMOUS DAVE'S RIBS OF MD"
            },
            {
              "VENDOR_NAME": "FAMOUS LUBRICANTS"
            },
            {
              "VENDOR_NAME": "FANG YE"
            },
            {
              "VENDOR_NAME": "FARLEYS & SATHERS CANDY COMPANY"
            },
            {
              "VENDOR_NAME": "FARM HOME"
            },
            {
              "VENDOR_NAME": "FARR"
            },
            {
              "VENDOR_NAME": "FARR"
            },
            {
              "VENDOR_NAME": "FASHION SEAL UNIFORMS"
            },
            {
              "VENDOR_NAME": "FAST DOORS"
            },
            {
              "VENDOR_NAME": "FAST SERVICE"
            },
            {
              "VENDOR_NAME": "FASTENAL COMPANY"
            },
            {
              "VENDOR_NAME": "FASTENERS FOR RETAIL"
            },
            {
              "VENDOR_NAME": "FASTSIGNS"
            },
            {
              "VENDOR_NAME": "FATHER NATURE LANDSCAPING"
            },
            {
              "VENDOR_NAME": "FATHER SAMS BAKERY"
            },
            {
              "VENDOR_NAME": "FAUQUIER COUNTY TREASURER"
            },
            {
              "VENDOR_NAME": "FAYE G.BARTON"
            },
            {
              "VENDOR_NAME": "FBD PARTNERSHIP L.P."
            },
            {
              "VENDOR_NAME": "FCN PUBLISHING DIVISION OF CRC PRES"
            },
            {
              "VENDOR_NAME": "FEARLESS FIRE COMPANY #14"
            },
            {
              "VENDOR_NAME": "FEASTIVITIES"
            },
            {
              "VENDOR_NAME": "FEDERAL APPRAISAL & CONSULTING LLC"
            },
            {
              "VENDOR_NAME": "FEDERAL EXPRESS"
            },
            {
              "VENDOR_NAME": "FEDERAL EXPRESS CORP"
            },
            {
              "VENDOR_NAME": "FEDERAL HEATH SIGN CO"
            },
            {
              "VENDOR_NAME": "FEDERAL RESERVE CLEVELAND"
            },
            {
              "VENDOR_NAME": "FEDERAL WAGE AND LABOR"
            },
            {
              "VENDOR_NAME": "FEDERAL WITHHOLDING TAX(CORBIN"
            },
            {
              "VENDOR_NAME": "FED-EX FREIGHT"
            },
            {
              "VENDOR_NAME": "FEDEX GROUND"
            },
            {
              "VENDOR_NAME": "FED'L RESERVE BANK CLEVE"
            },
            {
              "VENDOR_NAME": "FEDLMEIER EQUIPMENT"
            },
            {
              "VENDOR_NAME": "FEI - FINANCIAL EXECUTIVES INTERNAT"
            },
            {
              "VENDOR_NAME": "FELIX STORCH"
            },
            {
              "VENDOR_NAME": "FERGUSON & McCANN INC"
            },
            {
              "VENDOR_NAME": "FERN TREE APTS"
            },
            {
              "VENDOR_NAME": "FERRARA PAN CANDY COMPANY"
            },
            {
              "VENDOR_NAME": "FERRERO USA"
            },
            {
              "VENDOR_NAME": "FERRIGNO COURT REPORTING"
            },
            {
              "VENDOR_NAME": "FERRY ASSOCIATES"
            },
            {
              "VENDOR_NAME": "FETCO"
            },
            {
              "VENDOR_NAME": "FETZER"
            },
            {
              "VENDOR_NAME": "FFr INC"
            },
            {
              "VENDOR_NAME": "FHCH"
            },
            {
              "VENDOR_NAME": "FIA CARD SERVICE"
            },
            {
              "VENDOR_NAME": "FIA CARD SERVICES"
            },
            {
              "VENDOR_NAME": "FIA CARD SERVICES NA"
            },
            {
              "VENDOR_NAME": "FIBER SOLUTIONS"
            },
            {
              "VENDOR_NAME": "FIBERBLADE EAST"
            },
            {
              "VENDOR_NAME": "FIBERGLASS PRODUCTS COMPANY"
            },
            {
              "VENDOR_NAME": "FIBRELITE CORPORATION"
            },
            {
              "VENDOR_NAME": "FICA TAX (PAYROLL)"
            },
            {
              "VENDOR_NAME": "FIDELITY INSTITUTIONAL OPERATIONS C"
            },
            {
              "VENDOR_NAME": "FIDELITY NATIONAL TITLE"
            },
            {
              "VENDOR_NAME": "FIDELITY TITLE ABSTRACT CO."
            },
            {
              "VENDOR_NAME": "FIFIS ENTERPRISES"
            },
            {
              "VENDOR_NAME": "FIGTREE CONSULTING"
            },
            {
              "VENDOR_NAME": "FILTER TECH INC"
            },
            {
              "VENDOR_NAME": "FINANCE MARYLAND"
            },
            {
              "VENDOR_NAME": "FINANCIAL ACCT STNDRS BD"
            },
            {
              "VENDOR_NAME": "FINANCIAL MODELING SPECIALISTS"
            },
            {
              "VENDOR_NAME": "FINE ARTS & SOFTWARE INC"
            },
            {
              "VENDOR_NAME": "FINE"
            },
            {
              "VENDOR_NAME": "FINE"
            },
            {
              "VENDOR_NAME": "FINLEY/YAEGEL PARTNERSHIP"
            },
            {
              "VENDOR_NAME": "FINTECHEFTFINTECH NET"
            },
            {
              "VENDOR_NAME": "FIRE KING SECURITY PRODUCTS"
            },
            {
              "VENDOR_NAME": "FIRE PREVENT & INSPEC BUREAU"
            },
            {
              "VENDOR_NAME": "FIRE PREVENTION AND INSPECTION BURE"
            },
            {
              "VENDOR_NAME": "FIRMENICH"
            },
            {
              "VENDOR_NAME": "FIRST ADVANTAGE ADR"
            },
            {
              "VENDOR_NAME": "FIRST ADVANTAGE ADR INC."
            },
            {
              "VENDOR_NAME": "FIRST AMER. R.S. SOLUTIONS"
            },
            {
              "VENDOR_NAME": "FIRST AMERICAN CORELOGIC"
            },
            {
              "VENDOR_NAME": "FIRST AMERICAN REAL ESTATE"
            },
            {
              "VENDOR_NAME": "FIRST FEDERAL BANK"
            },
            {
              "VENDOR_NAME": "FIRST LOOK DISPLAY GROUP"
            },
            {
              "VENDOR_NAME": "FIRST MARKET BANK"
            },
            {
              "VENDOR_NAME": "FIRST NATIONAL OIL BROKERS INC"
            },
            {
              "VENDOR_NAME": "FIRST NIGHT - FREDERICKSBURG"
            },
            {
              "VENDOR_NAME": "FIRST PRIORITY HEALTH"
            },
            {
              "VENDOR_NAME": "FIRST SERVICES"
            },
            {
              "VENDOR_NAME": "FIRST UNION NATIONAL- D.W.WOOD TRUS"
            },
            {
              "VENDOR_NAME": "FIRSTRUST SAVINGS BANK"
            },
            {
              "VENDOR_NAME": "FIRSTTECH CORP."
            },
            {
              "VENDOR_NAME": "FISCHER THOMPSON BEVERAGE"
            },
            {
              "VENDOR_NAME": "FISHER & PHILLIPS LLP"
            },
            {
              "VENDOR_NAME": "FISHER BAG COMPANY"
            },
            {
              "VENDOR_NAME": "FISHER SCIENTIFIC"
            },
            {
              "VENDOR_NAME": "FISHER'S TUDOR HOUSE"
            },
            {
              "VENDOR_NAME": "FISHKIND & ASSOCIATES"
            },
            {
              "VENDOR_NAME": "FITCH"
            },
            {
              "VENDOR_NAME": "FITNESS EQUIPMENT SERVICES"
            },
            {
              "VENDOR_NAME": "FITZPATRIC"
            },
            {
              "VENDOR_NAME": "FITZPATRICK"
            },
            {
              "VENDOR_NAME": "FITZSIMMONS & ASSOCIATES"
            },
            {
              "VENDOR_NAME": "FIVE POINT PLAZA ASSOCIATES"
            },
            {
              "VENDOR_NAME": "FIVE POINTS PLAZA ASSOC."
            },
            {
              "VENDOR_NAME": "FIVE POINTS PLAZA ASSOC. C/O"
            },
            {
              "VENDOR_NAME": "FIVE WINDS INTERNATIONAL LP"
            },
            {
              "VENDOR_NAME": "FIXTURE HARDWARE CO."
            },
            {
              "VENDOR_NAME": "FIZZANO BROS.CONCRETE PRODUCTS"
            },
            {
              "VENDOR_NAME": "FL CD"
            },
            {
              "VENDOR_NAME": "FLA OWNER"
            },
            {
              "VENDOR_NAME": "FLAHART TRANSPORT INC"
            },
            {
              "VENDOR_NAME": "FLASTER GREENBERG"
            },
            {
              "VENDOR_NAME": "FLAT ROCK IV"
            },
            {
              "VENDOR_NAME": "FLEMINGTON BOROUGH FIRE SAFETY"
            },
            {
              "VENDOR_NAME": "FLEMINGTON EMT'S"
            },
            {
              "VENDOR_NAME": "FLEMINGTON MALL"
            },
            {
              "VENDOR_NAME": "FLEMINGTON TAX COLLECTOR"
            },
            {
              "VENDOR_NAME": "FLEMINGTON-RARITAN"
            },
            {
              "VENDOR_NAME": "FLEXAMERICA"
            },
            {
              "VENDOR_NAME": "FLEXERA SOFTWARE"
            },
            {
              "VENDOR_NAME": "FLEXi DISPLAY MARKETING"
            },
            {
              "VENDOR_NAME": "FLEXSIGNS"
            },
            {
              "VENDOR_NAME": "FLINT HILLS RESO"
            },
            {
              "VENDOR_NAME": "FLORA LORDI"
            },
            {
              "VENDOR_NAME": "FLORENCE F. JUBB"
            },
            {
              "VENDOR_NAME": "FLORENCE S ERVIN"
            },
            {
              "VENDOR_NAME": "FLORENCE TOWNSHIP FOOTBALL"
            },
            {
              "VENDOR_NAME": "FLORENCE TWP FIRE DIST #1"
            },
            {
              "VENDOR_NAME": "FLORESTANO GENTILE"
            },
            {
              "VENDOR_NAME": "FLORIDA CANDY FACTORY"
            },
            {
              "VENDOR_NAME": "FLORIDA DEPT. OF REVENUE"
            },
            {
              "VENDOR_NAME": "Florida vendor"
            },
            {
              "VENDOR_NAME": "FLORITE INTERNATIONAL"
            },
            {
              "VENDOR_NAME": "FLOURTOWN PLAZA"
            },
            {
              "VENDOR_NAME": "FLOWER WORLD"
            },
            {
              "VENDOR_NAME": "FLOWERS BAKING CO OF LYNCHBERG"
            },
            {
              "VENDOR_NAME": "FLOWERS/TASTYKAKE"
            },
            {
              "VENDOR_NAME": "FLOWSERVE FCD CORPORATION"
            },
            {
              "VENDOR_NAME": "FLUVANNA COMBINED COURT"
            },
            {
              "VENDOR_NAME": "FLYNN APPRAISAL ASSOC."
            },
            {
              "VENDOR_NAME": "FMS DMS LGBS"
            },
            {
              "VENDOR_NAME": "FMS DMS PROGESSIVE"
            },
            {
              "VENDOR_NAME": "FNC REALTY CORP"
            },
            {
              "VENDOR_NAME": "FNC REALTY CORPORATION"
            },
            {
              "VENDOR_NAME": "FOEDUS GROUP"
            },
            {
              "VENDOR_NAME": "FOLCROFT BOROUGH"
            },
            {
              "VENDOR_NAME": "FOLCROFT FIRE COMPANY NO. 1"
            },
            {
              "VENDOR_NAME": "FOLSOM BOROUGH"
            },
            {
              "VENDOR_NAME": "FOOD AUTOMATION SERVICE"
            },
            {
              "VENDOR_NAME": "FOOD BANK OF DELAWARE"
            },
            {
              "VENDOR_NAME": "FOOD BANK OF SOUTH JERSEY"
            },
            {
              "VENDOR_NAME": "FOOD COUNCIL COMMITTEE FOR GOOD"
            },
            {
              "VENDOR_NAME": "FOOD MARKETING INSTITUTE"
            },
            {
              "VENDOR_NAME": "FOOD PAC"
            },
            {
              "VENDOR_NAME": "FOOD SAFER"
            },
            {
              "VENDOR_NAME": "FOOD SERVICE MECHANICAL"
            },
            {
              "VENDOR_NAME": "FOOD SYSTEMS"
            },
            {
              "VENDOR_NAME": "FOOD TRADE NEWS"
            },
            {
              "VENDOR_NAME": "FOODLINE PIPING PRODUCTS"
            },
            {
              "VENDOR_NAME": "FOODQUIP SERVICES"
            },
            {
              "VENDOR_NAME": "FOOT AND ANKLE ASSOCIATES"
            },
            {
              "VENDOR_NAME": "FOOT AND ANKLE SPEC OF DEL CO"
            },
            {
              "VENDOR_NAME": "FOP LODGE #24"
            },
            {
              "VENDOR_NAME": "FORBES"
            },
            {
              "VENDOR_NAME": "FORD GUM &MACHINE COMPANY"
            },
            {
              "VENDOR_NAME": "FORD MOTOR CREDIT CO"
            },
            {
              "VENDOR_NAME": "FORD MOTOR CREDIT COMPANY"
            },
            {
              "VENDOR_NAME": "FOREST CITY-FRANKLINTOWN"
            },
            {
              "VENDOR_NAME": "FOREST VILLAGE MCG SCG"
            },
            {
              "VENDOR_NAME": "FOREVER COLLECTRIBLES"
            },
            {
              "VENDOR_NAME": "FOREVER PETALS n' BUDS"
            },
            {
              "VENDOR_NAME": "FORK PAINT & REPAIR"
            },
            {
              "VENDOR_NAME": "FORMCRAFT INTERIORS INC."
            },
            {
              "VENDOR_NAME": "FORMEX"
            },
            {
              "VENDOR_NAME": "FORSYTHE MCARTHUR ASSOC. INC."
            },
            {
              "VENDOR_NAME": "FORT DEARBORN COMPANY"
            },
            {
              "VENDOR_NAME": "FORT DIX IMWRF"
            },
            {
              "VENDOR_NAME": "FORTUNE"
            },
            {
              "VENDOR_NAME": "FORTUNE PLASTICS"
            },
            {
              "VENDOR_NAME": "FORWARD FOODS"
            },
            {
              "VENDOR_NAME": "FOSS NORTH AMERICA"
            },
            {
              "VENDOR_NAME": "FOSTER PRINTING SERVICES"
            },
            {
              "VENDOR_NAME": "FOUNDATION OF THE UNIV."
            },
            {
              "VENDOR_NAME": "FOUNTAIN HILL WAGE TAX"
            },
            {
              "VENDOR_NAME": "FOUR DIAMONDS FUND C/O"
            },
            {
              "VENDOR_NAME": "FOUR POINTS BY SHERATON HOTEL & SUI"
            },
            {
              "VENDOR_NAME": "FOUR SEASONS HOTEL"
            },
            {
              "VENDOR_NAME": "FOUR SEASONS HOTEL LAS VEGAS"
            },
            {
              "VENDOR_NAME": "FOUR WINDS INN"
            },
            {
              "VENDOR_NAME": "FOUR-D ELECTRIC"
            },
            {
              "VENDOR_NAME": "FOX HOLLOW SWIM CLUB"
            },
            {
              "VENDOR_NAME": "FOX SHUFFLER LLC"
            },
            {
              "VENDOR_NAME": "FOX TRANSPORT LTD"
            },
            {
              "VENDOR_NAME": "FP GROUP LLC"
            },
            {
              "VENDOR_NAME": "FRADKIN & WEBER PA"
            },
            {
              "VENDOR_NAME": "FRALINGER ENGINEERING"
            },
            {
              "VENDOR_NAME": "FRAN"
            },
            {
              "VENDOR_NAME": "FRANCIESCO SENATORE III"
            },
            {
              "VENDOR_NAME": "FRANCIS E. ABESSINIO"
            },
            {
              "VENDOR_NAME": "FRANCIS FARINA MOXHAM"
            },
            {
              "VENDOR_NAME": "FRANCIS FIUMARA"
            },
            {
              "VENDOR_NAME": "FRANCIS G.ZIEGLER"
            },
            {
              "VENDOR_NAME": "FRANK A. COSTELLO"
            },
            {
              "VENDOR_NAME": "FRANK BIANCO BUILDER"
            },
            {
              "VENDOR_NAME": "FRANK D. MANTEGNA"
            },
            {
              "VENDOR_NAME": "FRANK E. PAHLER"
            },
            {
              "VENDOR_NAME": "FRANK J WESNER"
            },
            {
              "VENDOR_NAME": "FRANK J WESNER JR."
            },
            {
              "VENDOR_NAME": "FRANK J.WESNER"
            },
            {
              "VENDOR_NAME": "FRANK KLUCSARITS"
            },
            {
              "VENDOR_NAME": "FRANK LaMOTTE TRUST"
            },
            {
              "VENDOR_NAME": "FRANK MCCALLS INC"
            },
            {
              "VENDOR_NAME": "FRANK MCKEON"
            },
            {
              "VENDOR_NAME": "FRANK MORRA"
            },
            {
              "VENDOR_NAME": "FRANK PICCIONI"
            },
            {
              "VENDOR_NAME": "FRANK SEXTON ENTERPRISES"
            },
            {
              "VENDOR_NAME": "FRANKLIN & GRACE SKEPTON"
            },
            {
              "VENDOR_NAME": "FRANKLIN & WEBER"
            },
            {
              "VENDOR_NAME": "FRANKLIN AVENUE HOLDINGS"
            },
            {
              "VENDOR_NAME": "FRANKLIN CHEMICAL COMPANY"
            },
            {
              "VENDOR_NAME": "FRANKLIN COVEY"
            },
            {
              "VENDOR_NAME": "FRANKLIN ELECTRIC CO"
            },
            {
              "VENDOR_NAME": "FRANKLIN TWP TAX COLLECT"
            },
            {
              "VENDOR_NAME": "FRANK'S UNION WINE MART"
            },
            {
              "VENDOR_NAME": "FRAZER COMTECH"
            },
            {
              "VENDOR_NAME": "F'REAL FOODS"
            },
            {
              "VENDOR_NAME": "F'REAL! FOODS"
            },
            {
              "VENDOR_NAME": "FRED BAKER MEMORIAL"
            },
            {
              "VENDOR_NAME": "FRED BEANS OF WEST CHESTER"
            },
            {
              "VENDOR_NAME": "FRED HILL AND SON CO."
            },
            {
              "VENDOR_NAME": "FRED PRYOR SEMINARS"
            },
            {
              "VENDOR_NAME": "FRED R. FRIEZE"
            },
            {
              "VENDOR_NAME": "FRED WOOD"
            },
            {
              "VENDOR_NAME": "FREDERIC S SCHROEDER"
            },
            {
              "VENDOR_NAME": "FREDERICK A. SIEGFRIED"
            },
            {
              "VENDOR_NAME": "FREDERICK COUNTY COMMISSIONERS"
            },
            {
              "VENDOR_NAME": "FREDERICK COUNTY DIV OF UTILITIES"
            },
            {
              "VENDOR_NAME": "FREDERICK CTY HEALTH"
            },
            {
              "VENDOR_NAME": "FREDERICK KEYS"
            },
            {
              "VENDOR_NAME": "FREDERICK L REIGLE ESQ."
            },
            {
              "VENDOR_NAME": "FREDERICK L WOOD FOR"
            },
            {
              "VENDOR_NAME": "FREDERICK REIGLE TRUSTEE"
            },
            {
              "VENDOR_NAME": "FREDERICK WARD ASSOCIATES"
            },
            {
              "VENDOR_NAME": "FREDERICKSBURG AMBULATORY"
            },
            {
              "VENDOR_NAME": "FREDERICKSBURG AREA RUNNING CLUB"
            },
            {
              "VENDOR_NAME": "FREDERICKSBURG CIRCUIT COURT"
            },
            {
              "VENDOR_NAME": "FREDERICKSBURG D"
            },
            {
              "VENDOR_NAME": "FREDERICKSBURG GENERAL DIST.COURT"
            },
            {
              "VENDOR_NAME": "FREDERICKSBURG GEN'L DIST COURT"
            },
            {
              "VENDOR_NAME": "FREDERICKSBURG GEN'L DIST CT"
            },
            {
              "VENDOR_NAME": "FREDRICK W.MEIER"
            },
            {
              "VENDOR_NAME": "FREEDOM FUND GALA"
            },
            {
              "VENDOR_NAME": "FREEHOLD CARTAGE"
            },
            {
              "VENDOR_NAME": "FREEHOLD SELF STORAGE"
            },
            {
              "VENDOR_NAME": "FREEHOLD SOIL CONSER DIST"
            },
            {
              "VENDOR_NAME": "FREEHOLD SOIL CONSERVATION DISTRICT"
            },
            {
              "VENDOR_NAME": "FREEHOLD/MIDDLESEX SOIL CONSERVATIO"
            },
            {
              "VENDOR_NAME": "FREEMAN BEVERAGE"
            },
            {
              "VENDOR_NAME": "FREEMAN BEVERAGES"
            },
            {
              "VENDOR_NAME": "FREEMIRE & ASSOCIATES"
            },
            {
              "VENDOR_NAME": "FREESTATE HEALTH PLAN"
            },
            {
              "VENDOR_NAME": "FRESH CHANNEL FLORIDA CROSS DOCK"
            },
            {
              "VENDOR_NAME": "FRESH CHANNEL MA CROSS DOCK-HANDLIN"
            },
            {
              "VENDOR_NAME": "FRESH CHANNEL MID-ATLANTIC-ACCRUAL"
            },
            {
              "VENDOR_NAME": "FRESH MARK"
            },
            {
              "VENDOR_NAME": "FREY"
            },
            {
              "VENDOR_NAME": "FRIEDMAN ELECTRIC SUPPLY CO."
            },
            {
              "VENDOR_NAME": "FRIENDLY FINANCE CORP"
            },
            {
              "VENDOR_NAME": "FRIENDLY FINANCE CORP."
            },
            {
              "VENDOR_NAME": "FRIENDS ASSOCIATION"
            },
            {
              "VENDOR_NAME": "FRIENDS CENTRAL SCHOOL"
            },
            {
              "VENDOR_NAME": "FRIENDS OF ALGIE HOWELL"
            },
            {
              "VENDOR_NAME": "FRIENDS OF ANTHONY J. DELUCA"
            },
            {
              "VENDOR_NAME": "FRIENDS OF BARRY GLASSMAN"
            },
            {
              "VENDOR_NAME": "FRIENDS OF BETHANY HALL-LONG"
            },
            {
              "VENDOR_NAME": "FRIENDS OF BILL JANIS"
            },
            {
              "VENDOR_NAME": "FRIENDS OF BOB MARSHALL"
            },
            {
              "VENDOR_NAME": "FRIENDS OF BOB TATA"
            },
            {
              "VENDOR_NAME": "FRIENDS OF BRADLEY P.MARRS"
            },
            {
              "VENDOR_NAME": "FRIENDS OF BRIAN MORAN"
            },
            {
              "VENDOR_NAME": "FRIENDS OF BRUCE REYNOLDS"
            },
            {
              "VENDOR_NAME": "FRIENDS OF CHARLES BOUTIN"
            },
            {
              "VENDOR_NAME": "FRIENDS OF CLARKE N. HOGAN"
            },
            {
              "VENDOR_NAME": "FRIENDS OF DAVE ALBO"
            },
            {
              "VENDOR_NAME": "FRIENDS OF DEBORAH HUDSON"
            },
            {
              "VENDOR_NAME": "FRIENDS OF DELEGATE HOWELL"
            },
            {
              "VENDOR_NAME": "FRIENDS OF DONNA STONE"
            },
            {
              "VENDOR_NAME": "FRIENDS OF DWIGHT JONES"
            },
            {
              "VENDOR_NAME": "FRIENDS OF EDWARD R.HOUCK"
            },
            {
              "VENDOR_NAME": "FRIENDS OF FRANK HALL"
            },
            {
              "VENDOR_NAME": "FRIENDS OF FRANK HARGROVE"
            },
            {
              "VENDOR_NAME": "FRIENDS OF FRANK W. WAGNER"
            },
            {
              "VENDOR_NAME": "FRIENDS OF GERALD BUCKWORTH"
            },
            {
              "VENDOR_NAME": "FRIENDS OF GERALD HOCKER"
            },
            {
              "VENDOR_NAME": "FRIENDS OF GLEN ODER"
            },
            {
              "VENDOR_NAME": "FRIENDS OF HARRY PARRISH"
            },
            {
              "VENDOR_NAME": "FRIENDS OF HARVEY B. MORGAN"
            },
            {
              "VENDOR_NAME": "FRIENDS OF J. ROBERT HOOPER"
            },
            {
              "VENDOR_NAME": "FRIENDS OF J.B.JENNINGS"
            },
            {
              "VENDOR_NAME": "FRIENDS OF J.LOWELL STOLZFUS"
            },
            {
              "VENDOR_NAME": "FRIENDS OF JAMES HARKINS"
            },
            {
              "VENDOR_NAME": "FRIENDS OF JANET GREENNIP"
            },
            {
              "VENDOR_NAME": "FRIENDS OF JEION WARD"
            },
            {
              "VENDOR_NAME": "FRIENDS OF JOANNE PARROTT"
            },
            {
              "VENDOR_NAME": "FRIENDS OF JOHN A. COSGROVE"
            },
            {
              "VENDOR_NAME": "FRIENDS OF JOHN CHICHESTER"
            },
            {
              "VENDOR_NAME": "FRIENDS OF JOHN J.WELCH"
            },
            {
              "VENDOR_NAME": "FRIENDS OF JOHN O'BANNON"
            },
            {
              "VENDOR_NAME": "FRIENDS OF JOHN REID"
            },
            {
              "VENDOR_NAME": "FRIENDS OF JOHN VIOLA"
            },
            {
              "VENDOR_NAME": "FRIENDS OF JOSEPH BOTELER"
            },
            {
              "VENDOR_NAME": "FRIENDS OF JOSEPH E.MIRO"
            },
            {
              "VENDOR_NAME": "FRIENDS OF KATHY GRAZIANO"
            },
            {
              "VENDOR_NAME": "FRIENDS OF KEN MELVIN"
            },
            {
              "VENDOR_NAME": "FRIENDS OF KEN PLUM"
            },
            {
              "VENDOR_NAME": "FRIENDS OF L.SCOTT LINGAMFELTER"
            },
            {
              "VENDOR_NAME": "FRIENDS OF LARRY SUDGEN"
            },
            {
              "VENDOR_NAME": "FRIENDS OF LEE WARE"
            },
            {
              "VENDOR_NAME": "FRIENDS OF LEO C.WARDRUP"
            },
            {
              "VENDOR_NAME": "FRIENDS OF M.KIRKLAND COX"
            },
            {
              "VENDOR_NAME": "FRIENDS OF MANOLI LOUPASSI"
            },
            {
              "VENDOR_NAME": "FRIENDS OF MARK L.COLE"
            },
            {
              "VENDOR_NAME": "FRIENDS OF MAYME BaCOTE"
            },
            {
              "VENDOR_NAME": "FRIENDS OF MELANIE R.RAPP"
            },
            {
              "VENDOR_NAME": "FRIENDS OF MICHELE B MCQUIGG"
            },
            {
              "VENDOR_NAME": "FRIENDS OF MORGAN GRIFFITH"
            },
            {
              "VENDOR_NAME": "FRIENDS OF NANCY JACOBS"
            },
            {
              "VENDOR_NAME": "FRIENDS OF NICK RERRAS"
            },
            {
              "VENDOR_NAME": "FRIENDS OF PHILLIP A. HAMILTON"
            },
            {
              "VENDOR_NAME": "FRIENDS OF PROVIDENCE HOUSE"
            },
            {
              "VENDOR_NAME": "FRIENDS OF RICHARD C. CATHCART"
            },
            {
              "VENDOR_NAME": "FRIENDS OF RICHARD IMPALLARIA"
            },
            {
              "VENDOR_NAME": "FRIENDS OF RICHARD SOSSI"
            },
            {
              "VENDOR_NAME": "FRIENDS OF RILEY E.INGRAM"
            },
            {
              "VENDOR_NAME": "FRIENDS OF ROB BELL"
            },
            {
              "VENDOR_NAME": "FRIENDS OF ROBERT F.GILLIGAN"
            },
            {
              "VENDOR_NAME": "FRIENDS OF ROBERT ORROCK"
            },
            {
              "VENDOR_NAME": "FRIENDS OF RYAN T. MCDOUGLE"
            },
            {
              "VENDOR_NAME": "FRIENDS OF SACRED HEART HS BASEBALL"
            },
            {
              "VENDOR_NAME": "FRIENDS OF SAL IAQUINTO"
            },
            {
              "VENDOR_NAME": "FRIENDS OF SAM NIXON"
            },
            {
              "VENDOR_NAME": "FRIENDS OF SEAN CONNAUGHTON"
            },
            {
              "VENDOR_NAME": "FRIENDS OF STEPHEN H.MARTIN"
            },
            {
              "VENDOR_NAME": "FRIENDS OF TERRIE L.SUIT"
            },
            {
              "VENDOR_NAME": "FRIENDS OF THE DELAWARE COUNTY"
            },
            {
              "VENDOR_NAME": "FRIENDS OF THELMA DRAKE"
            },
            {
              "VENDOR_NAME": "FRIENDS OF TIMOTHY D. HUGO"
            },
            {
              "VENDOR_NAME": "FRIENDS OF TOM GEAR"
            },
            {
              "VENDOR_NAME": "FRIENDS OF TOM SHELDON"
            },
            {
              "VENDOR_NAME": "FRIENDS OF TOMMY NORMENT"
            },
            {
              "VENDOR_NAME": "FRIENDS OF VERONICA CHENOWITH"
            },
            {
              "VENDOR_NAME": "FRIENDS OF VINCE CALLAHAN"
            },
            {
              "VENDOR_NAME": "FRIENDS OF WALTER STOSCH"
            },
            {
              "VENDOR_NAME": "FRIENDS OF WARREN MILLER"
            },
            {
              "VENDOR_NAME": "FRIENDS OF WATKINS ABBITT"
            },
            {
              "VENDOR_NAME": "FRIENDS OF WILLIAM A.OBERLE"
            },
            {
              "VENDOR_NAME": "FRIENDS SELECT SCHOOL"
            },
            {
              "VENDOR_NAME": "FRITO LAY INC"
            },
            {
              "VENDOR_NAME": "FRITZ INDUSTRIES"
            },
            {
              "VENDOR_NAME": "FRITZ NEWS SERVICE LLC"
            },
            {
              "VENDOR_NAME": "FROEHLING & ROBERTSON"
            },
            {
              "VENDOR_NAME": "FROM THE HEART FOUNDATION"
            },
            {
              "VENDOR_NAME": "FRO-ME-A-PARTY"
            },
            {
              "VENDOR_NAME": "FRONTIER"
            },
            {
              "VENDOR_NAME": "FROST VALLEY YMCA"
            },
            {
              "VENDOR_NAME": "FRUITBUD JUICE"
            },
            {
              "VENDOR_NAME": "FTI CONSULTING"
            },
            {
              "VENDOR_NAME": "FUEL LITIGATION DEFENDANTS"
            },
            {
              "VENDOR_NAME": "FUEL LITIGATION JOINT EXPERT FUND"
            },
            {
              "VENDOR_NAME": "FUEL PUBLISHING GROUP"
            },
            {
              "VENDOR_NAME": "FUJI FILM"
            },
            {
              "VENDOR_NAME": "FULL-TIME RESEARCH"
            },
            {
              "VENDOR_NAME": "FULTON FRIEDMAN AND GULLACE"
            },
            {
              "VENDOR_NAME": "FUNKE FILTERS INC"
            },
            {
              "VENDOR_NAME": "FUNTASTIC PARTY RENTALS"
            },
            {
              "VENDOR_NAME": "FURQAN ZAFFAR"
            },
            {
              "VENDOR_NAME": "FUSCO ENTERPRISES"
            },
            {
              "VENDOR_NAME": "FUSCO PROPERTIES"
            },
            {
              "VENDOR_NAME": "FUTURE FINANCE COMPANY"
            },
            {
              "VENDOR_NAME": "FUTUREMEDIA DISPLAYS"
            },
            {
              "VENDOR_NAME": "FX BONNES ASSOCIATES INC"
            },
            {
              "VENDOR_NAME": "G & E SEPTIC"
            },
            {
              "VENDOR_NAME": "G & H SERVICE CO."
            },
            {
              "VENDOR_NAME": "G & J SERVICE CENTER"
            },
            {
              "VENDOR_NAME": "G C COMMUNICATIONS"
            },
            {
              "VENDOR_NAME": "G STARK"
            },
            {
              "VENDOR_NAME": "G&H CREDIT ASSOC. INC"
            },
            {
              "VENDOR_NAME": "G.D. HOUTMAN AND SON"
            },
            {
              "VENDOR_NAME": "G.H. HARRIS ASSOCIATES"
            },
            {
              "VENDOR_NAME": "G.H. HARRIS ASSOCIATES"
            },
            {
              "VENDOR_NAME": "G.H.HARRIS ASSOC."
            },
            {
              "VENDOR_NAME": "G.H.HARRIS ASSOCIATES"
            },
            {
              "VENDOR_NAME": "G.M.HANEY"
            },
            {
              "VENDOR_NAME": "G.M.HANEY CITY TREASURER"
            },
            {
              "VENDOR_NAME": "G.M.HANEY"
            },
            {
              "VENDOR_NAME": "G.STARK"
            },
            {
              "VENDOR_NAME": "GABLE SIGNS & GRAPHICS"
            },
            {
              "VENDOR_NAME": "GABRIELLE ANIK"
            },
            {
              "VENDOR_NAME": "GABRIELLE G. FOX"
            },
            {
              "VENDOR_NAME": "GAIL D. SKOCIK"
            },
            {
              "VENDOR_NAME": "GALETON GLOVES"
            },
            {
              "VENDOR_NAME": "GALLAGHER"
            },
            {
              "VENDOR_NAME": "GALLOWAY 4TH OF JULY COMMITTEE"
            },
            {
              "VENDOR_NAME": "GALLOWAY ENTERPRISES"
            },
            {
              "VENDOR_NAME": "GALLOWAY TOWNSHIP"
            },
            {
              "VENDOR_NAME": "GALLOWAY TOWNSHIP M.U.D."
            },
            {
              "VENDOR_NAME": "GALLOWAY TOWNSHIP POLICE DEPT."
            },
            {
              "VENDOR_NAME": "GALLOWAY TWP SEWERAGE AUTHORITY"
            },
            {
              "VENDOR_NAME": "GALLOWAY TWSP AMBULANCE SQD"
            },
            {
              "VENDOR_NAME": "GALLS"
            },
            {
              "VENDOR_NAME": "GALLS"
            },
            {
              "VENDOR_NAME": "GAMBONE DEVELOPMENT COMPANY"
            },
            {
              "VENDOR_NAME": "GAMBONE/MAXI BP PARTNERS"
            },
            {
              "VENDOR_NAME": "GAMBRILLS ATHLETIC CLUB"
            },
            {
              "VENDOR_NAME": "GAMSE LITHOGRAPHING CO."
            },
            {
              "VENDOR_NAME": "GANNETT NJ NEWSPAPERS"
            },
            {
              "VENDOR_NAME": "GARABET KARAKELAIN"
            },
            {
              "VENDOR_NAME": "GARCO ENTERPRIZES"
            },
            {
              "VENDOR_NAME": "GARDA CL ATLANTIC"
            },
            {
              "VENDOR_NAME": "GARDEN SQUARE LANDSCAPING"
            },
            {
              "VENDOR_NAME": "GARDEN STATE SIGN CO"
            },
            {
              "VENDOR_NAME": "GARNER MANN KOONS"
            },
            {
              "VENDOR_NAME": "GARNET VALLEY SCHOOL DISTRICT"
            },
            {
              "VENDOR_NAME": "GARRETT M.CONOVER"
            },
            {
              "VENDOR_NAME": "GARRISON NEWS"
            },
            {
              "VENDOR_NAME": "GARTNER"
            },
            {
              "VENDOR_NAME": "GARY DILL"
            },
            {
              "VENDOR_NAME": "GARY GUNDERSON GLASS"
            },
            {
              "VENDOR_NAME": "GARY GUNDERSON GLASS & MIRRORS"
            },
            {
              "VENDOR_NAME": "GARY IBER"
            },
            {
              "VENDOR_NAME": "GARY KAUFMANN"
            },
            {
              "VENDOR_NAME": "GARY MARKS"
            },
            {
              "VENDOR_NAME": "GARY PALMER"
            },
            {
              "VENDOR_NAME": "GARY W. ABER"
            },
            {
              "VENDOR_NAME": "GARY WINCKEL"
            },
            {
              "VENDOR_NAME": "GASPARRE LIMITED PARTNERSHIP"
            },
            {
              "VENDOR_NAME": "GASTROINTESTINAL SPECIALIST"
            },
            {
              "VENDOR_NAME": "GATE 1 TRAVEL"
            },
            {
              "VENDOR_NAME": "GATEWAY PETROLEUM TECH."
            },
            {
              "VENDOR_NAME": "GATEWAY REGIONAL HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "GATEWAY SEARCH ASSOC.INC"
            },
            {
              "VENDOR_NAME": "GAVILON"
            },
            {
              "VENDOR_NAME": "GAYLIEN M. SMALL"
            },
            {
              "VENDOR_NAME": "GAZETTE-JOURNAL"
            },
            {
              "VENDOR_NAME": "GAZZELLA CORPORATE COURIER EXPRESS"
            },
            {
              "VENDOR_NAME": "GC SERVICES"
            },
            {
              "VENDOR_NAME": "GCS SERVICE"
            },
            {
              "VENDOR_NAME": "GDP ENTERPRISES INC"
            },
            {
              "VENDOR_NAME": "GDT"
            },
            {
              "VENDOR_NAME": "GE CAPITAL"
            },
            {
              "VENDOR_NAME": "GE CAPITAL FLEET SERVICES"
            },
            {
              "VENDOR_NAME": "GE CAPITAL FRANCHISE FINANCE CORP."
            },
            {
              "VENDOR_NAME": "GE MONEY BANK"
            },
            {
              "VENDOR_NAME": "GEAC COMPUTER SYSTEMS INC"
            },
            {
              "VENDOR_NAME": "GEICO"
            },
            {
              "VENDOR_NAME": "GEIGER BROS."
            },
            {
              "VENDOR_NAME": "GEIGER BROS./DELAWARE"
            },
            {
              "VENDOR_NAME": "GENE GRAHAM ELECTRIC"
            },
            {
              "VENDOR_NAME": "GENE RANSOM ASSOCIATE COUNSEL"
            },
            {
              "VENDOR_NAME": "GENERAL BINDING CORP"
            },
            {
              "VENDOR_NAME": "GENERAL FILMS"
            },
            {
              "VENDOR_NAME": "GENERAL FIRE EQUIP.INC."
            },
            {
              "VENDOR_NAME": "GENERAL LAND COMMERCIAL"
            },
            {
              "VENDOR_NAME": "GENERAL MILLS"
            },
            {
              "VENDOR_NAME": "GENERAL PAINTING OF PENNSYLVANIA"
            },
            {
              "VENDOR_NAME": "GENERAL PROGRAMMING INC"
            },
            {
              "VENDOR_NAME": "GENERAL RESERVE CORP."
            },
            {
              "VENDOR_NAME": "GENERAL REV CORP"
            },
            {
              "VENDOR_NAME": "GENERAL REV CORP WAGE W/H UNIT"
            },
            {
              "VENDOR_NAME": "GENERAL REV CORP."
            },
            {
              "VENDOR_NAME": "GENERAL REV. CORP."
            },
            {
              "VENDOR_NAME": "GENERAL REV.CORP"
            },
            {
              "VENDOR_NAME": "GENERAL REVENUE CORP"
            },
            {
              "VENDOR_NAME": "GENERAL REVENUE CORP."
            },
            {
              "VENDOR_NAME": "GENERAL REVENUE CORPORATION"
            },
            {
              "VENDOR_NAME": "GENERAL REVENUE CORPORATION / NV"
            },
            {
              "VENDOR_NAME": "GENERAL REVENUE CORRP."
            },
            {
              "VENDOR_NAME": "GENERAL SALES REAL ESTATE"
            },
            {
              "VENDOR_NAME": "GENERATIONS RESTAURANT"
            },
            {
              "VENDOR_NAME": "GENEX SERVICES INC"
            },
            {
              "VENDOR_NAME": "GENISOY FOOD COMPANY"
            },
            {
              "VENDOR_NAME": "GEN'L REV CORP"
            },
            {
              "VENDOR_NAME": "GEN'L REV. CORP."
            },
            {
              "VENDOR_NAME": "GEN'L REVENUE CORP."
            },
            {
              "VENDOR_NAME": "GENO SAMERO"
            },
            {
              "VENDOR_NAME": "GENTEK PREFERENCE LITIGATION TRUST"
            },
            {
              "VENDOR_NAME": "GENTLE BEAR ENTERPRISES"
            },
            {
              "VENDOR_NAME": "GEOFFREY K. SANDS"
            },
            {
              "VENDOR_NAME": "GEOGRAPHIA MAP COMPANY"
            },
            {
              "VENDOR_NAME": "GEOLOGIC SERVICES CORP."
            },
            {
              "VENDOR_NAME": "GEORGE A COOK COURT OFFICER"
            },
            {
              "VENDOR_NAME": "GEORGE A.COOK"
            },
            {
              "VENDOR_NAME": "GEORGE CATHERS"
            },
            {
              "VENDOR_NAME": "GEORGE COOK COURT OFFICER"
            },
            {
              "VENDOR_NAME": "GEORGE COOK CT OFFICER"
            },
            {
              "VENDOR_NAME": "GEORGE COOK CT. OFFICER"
            },
            {
              "VENDOR_NAME": "GEORGE COOK"
            },
            {
              "VENDOR_NAME": "GEORGE COOK"
            },
            {
              "VENDOR_NAME": "GEORGE COOK"
            },
            {
              "VENDOR_NAME": "GEORGE FAISON"
            },
            {
              "VENDOR_NAME": "GEORGE GORDON"
            },
            {
              "VENDOR_NAME": "GEORGE HAHNE"
            },
            {
              "VENDOR_NAME": "GEORGE HAUGHEY"
            },
            {
              "VENDOR_NAME": "GEORGE HERBERT COURT OFFICER"
            },
            {
              "VENDOR_NAME": "GEORGE HERBERT OFFICER"
            },
            {
              "VENDOR_NAME": "GEORGE HIRN CO INC"
            },
            {
              "VENDOR_NAME": "GEORGE J GAUGHAN"
            },
            {
              "VENDOR_NAME": "GEORGE LECK & SON"
            },
            {
              "VENDOR_NAME": "GEORGE MARAVELIAS"
            },
            {
              "VENDOR_NAME": "GEORGE MARAVELIAS AND"
            },
            {
              "VENDOR_NAME": "GEORGE R. SZYMANSKI"
            },
            {
              "VENDOR_NAME": "GEORGE S.MAIER COMPANY"
            },
            {
              "VENDOR_NAME": "GEORGE SHERMAN CORP"
            },
            {
              "VENDOR_NAME": "GEORGE W FURNESS"
            },
            {
              "VENDOR_NAME": "GEORGE W NIXON CO INC"
            },
            {
              "VENDOR_NAME": "GEORGE W. NEAL CHAPTER 13 TRUSTEE"
            },
            {
              "VENDOR_NAME": "GEORGE W. PLUMMER & SON"
            },
            {
              "VENDOR_NAME": "GEORGE W.STAFFORD"
            },
            {
              "VENDOR_NAME": "GEORGE WARREN"
            },
            {
              "VENDOR_NAME": "GEORGE WESTON BAKERIES"
            },
            {
              "VENDOR_NAME": "GEORGE WILLIAM STEPHENS &"
            },
            {
              "VENDOR_NAME": "GEORGE WOOD"
            },
            {
              "VENDOR_NAME": "GEORGIA INSTITUTE OF TECH."
            },
            {
              "VENDOR_NAME": "GEORGIA PACIFIC/ DIXIE"
            },
            {
              "VENDOR_NAME": "GEORGIA SOFTWORKS"
            },
            {
              "VENDOR_NAME": "GEORGIA-PACIFIC CORP."
            },
            {
              "VENDOR_NAME": "GEOSTRUCTURES"
            },
            {
              "VENDOR_NAME": "GEOTECHNICAL CONSULTING"
            },
            {
              "VENDOR_NAME": "GEPPERT BROS"
            },
            {
              "VENDOR_NAME": "GERALD C.McMANUS"
            },
            {
              "VENDOR_NAME": "GERALD KATZOFF"
            },
            {
              "VENDOR_NAME": "GERALD M. O'DONNELL"
            },
            {
              "VENDOR_NAME": "GERALD S. CONICELLO"
            },
            {
              "VENDOR_NAME": "GERALDINE SCHOLI"
            },
            {
              "VENDOR_NAME": "GERARD KEFFORD"
            },
            {
              "VENDOR_NAME": "GERARD McFADDEN"
            },
            {
              "VENDOR_NAME": "GERARD R VETTER TRUSTEE"
            },
            {
              "VENDOR_NAME": "GERARD SCANLAN"
            },
            {
              "VENDOR_NAME": "GERARD VETTER CHAP.13 TRUSTEE"
            },
            {
              "VENDOR_NAME": "GERARD VETTER"
            },
            {
              "VENDOR_NAME": "GERARD VETTER"
            },
            {
              "VENDOR_NAME": "GERBERT LIMITED"
            },
            {
              "VENDOR_NAME": "GERRY BONHAGE"
            },
            {
              "VENDOR_NAME": "GESSNER"
            },
            {
              "VENDOR_NAME": "GETTY PETROLEUM"
            },
            {
              "VENDOR_NAME": "GETTY PETROLEUM MARKETING INC."
            },
            {
              "VENDOR_NAME": "GEUTING PRODUCTIONS"
            },
            {
              "VENDOR_NAME": "GEXPRO"
            },
            {
              "VENDOR_NAME": "GGI"
            },
            {
              "VENDOR_NAME": "GHIRADELLI CHOCOLATE COMPANY"
            },
            {
              "VENDOR_NAME": "GIASEMI ISIHOS"
            },
            {
              "VENDOR_NAME": "GIELOW PICKLES"
            },
            {
              "VENDOR_NAME": "GIFT OF LIFE"
            },
            {
              "VENDOR_NAME": "GILBARCO"
            },
            {
              "VENDOR_NAME": "GILBERT'S TILE"
            },
            {
              "VENDOR_NAME": "GILDA'S CLUB SOUTH JERSEY"
            },
            {
              "VENDOR_NAME": "GILES & RANSOME"
            },
            {
              "VENDOR_NAME": "GILL & ASSOCIATES"
            },
            {
              "VENDOR_NAME": "GILLEN REALTY INC"
            },
            {
              "VENDOR_NAME": "GILLESPIE & RITCHIE INC"
            },
            {
              "VENDOR_NAME": "GILLIAT G SCHROEDER JR"
            },
            {
              "VENDOR_NAME": "GILMORE AND ASSOCIATES"
            },
            {
              "VENDOR_NAME": "GINA M.SEARL"
            },
            {
              "VENDOR_NAME": "GIOIA CARTA"
            },
            {
              "VENDOR_NAME": "GIORDANO"
            },
            {
              "VENDOR_NAME": "GIORDANO"
            },
            {
              "VENDOR_NAME": "GIRARD DI RUSSO"
            },
            {
              "VENDOR_NAME": "GIRL SCOUT OF DELAWARE-RARITAN"
            },
            {
              "VENDOR_NAME": "GIRL SCOUTS OF AMERICA"
            },
            {
              "VENDOR_NAME": "GIRL SCOUTS OF SOUTH JERSEY PINES"
            },
            {
              "VENDOR_NAME": "GISH LOGGING"
            },
            {
              "VENDOR_NAME": "GIUSEPPE'S PIZZA REST."
            },
            {
              "VENDOR_NAME": "GLACKIN THOMAS PANZAK"
            },
            {
              "VENDOR_NAME": "GLADSON & ASSOCIATES"
            },
            {
              "VENDOR_NAME": "GLADYS SEWELL"
            },
            {
              "VENDOR_NAME": "GLAN MASS COURT OFFICER OF NJ"
            },
            {
              "VENDOR_NAME": "GLASS CONCEPTS"
            },
            {
              "VENDOR_NAME": "GLASSBORO BUREAU OF FIRE PREVENTION"
            },
            {
              "VENDOR_NAME": "GLASSBORO MUNICIPAL COURT"
            },
            {
              "VENDOR_NAME": "GLASSER AND GLASSER"
            },
            {
              "VENDOR_NAME": "GLASSER AND GLASSER P.L.C."
            },
            {
              "VENDOR_NAME": "GLEBA TRUCKING"
            },
            {
              "VENDOR_NAME": "GLEN ALLEN YOUTH ATHLETIC ASSOC."
            },
            {
              "VENDOR_NAME": "GLEN FOERD CONSERVATION CORP"
            },
            {
              "VENDOR_NAME": "GLEN MASS COURT OFFICER"
            },
            {
              "VENDOR_NAME": "GLEN MILLS SAND & GRAVEL"
            },
            {
              "VENDOR_NAME": "GLEN MILLS STUDENT SCHOLARSHIP FUND"
            },
            {
              "VENDOR_NAME": "GLEN ROAD SYSTEMS"
            },
            {
              "VENDOR_NAME": "GLENCORE LTD"
            },
            {
              "VENDOR_NAME": "GLENDOLA FIRE COMPANY"
            },
            {
              "VENDOR_NAME": "GLENMOORE GLASS CO."
            },
            {
              "VENDOR_NAME": "GLENN M. ROSS"
            },
            {
              "VENDOR_NAME": "GLENN MASS COURT OFFICER"
            },
            {
              "VENDOR_NAME": "GLENN MASS CT. OFFICER"
            },
            {
              "VENDOR_NAME": "GLENN P.SCHIFFERDECKER"
            },
            {
              "VENDOR_NAME": "GLENORE OVER"
            },
            {
              "VENDOR_NAME": "GLENORE R. OVER"
            },
            {
              "VENDOR_NAME": "GLENORE R.OVER"
            },
            {
              "VENDOR_NAME": "GLO GERM COMPANY"
            },
            {
              "VENDOR_NAME": "GLOBAL BUSINESS PARTNERS"
            },
            {
              "VENDOR_NAME": "GLOBAL COMPANIES"
            },
            {
              "VENDOR_NAME": "GLOBAL EQUIPMENT COMPANY"
            },
            {
              "VENDOR_NAME": "GLOBAL FINANCIAL"
            },
            {
              "VENDOR_NAME": "GLOBAL FOOD GROUP"
            },
            {
              "VENDOR_NAME": "GLOBAL KNOWLEDGE NETWORK"
            },
            {
              "VENDOR_NAME": "GLOBALVIEW SOFTWARE"
            },
            {
              "VENDOR_NAME": "GLOBE KNUDSEN CORP"
            },
            {
              "VENDOR_NAME": "GLO-QUIPS"
            },
            {
              "VENDOR_NAME": "GLORSON INVESTMENTS"
            },
            {
              "VENDOR_NAME": "GLOUCESTER CITY"
            },
            {
              "VENDOR_NAME": "GLOUCESTER CITY FIRE DEPT"
            },
            {
              "VENDOR_NAME": "GLOUCESTER CITY HEALTH DT"
            },
            {
              "VENDOR_NAME": "GLOUCESTER CITY HOUSING & BUILDING"
            },
            {
              "VENDOR_NAME": "GLOUCESTER CITY NEWS"
            },
            {
              "VENDOR_NAME": "GLOUCESTER CITY WATER DEP"
            },
            {
              "VENDOR_NAME": "GLOUCESTER CITY YMCA"
            },
            {
              "VENDOR_NAME": "GLOUCESTER CO TIMES"
            },
            {
              "VENDOR_NAME": "GLOUCESTER COUNTY"
            },
            {
              "VENDOR_NAME": "GLOUCESTER COUNTY PROBATION"
            },
            {
              "VENDOR_NAME": "GLOUCESTER COUNTY PROBATION DEPT."
            },
            {
              "VENDOR_NAME": "GLOUCESTER COUNTY SHERIFF"
            },
            {
              "VENDOR_NAME": "GLOUCESTER COUNTY SOIL"
            },
            {
              "VENDOR_NAME": "GLOUCESTER COUNTY SOIL CONSERVATION"
            },
            {
              "VENDOR_NAME": "GLOUCESTER COUNTY UTILITIES"
            },
            {
              "VENDOR_NAME": "GLOUCESTER COUNTY UTILITIES DEPT."
            },
            {
              "VENDOR_NAME": "GLOUCESTER CS ASSOCIATES"
            },
            {
              "VENDOR_NAME": "GLOUCESTER TOWNSHIP"
            },
            {
              "VENDOR_NAME": "GLOUCESTER TOWNSHIP ASSOCATION"
            },
            {
              "VENDOR_NAME": "GLOUCESTER TOWNSHIP FIRST"
            },
            {
              "VENDOR_NAME": "GLOUCESTER TOWNSHIP LIONS"
            },
            {
              "VENDOR_NAME": "GLOUCESTER TWP DISTRICT 4"
            },
            {
              "VENDOR_NAME": "GLOUCESTER TWP.ECONOMIC"
            },
            {
              "VENDOR_NAME": "GLOUCESTER TWP.GIRLS ATHLETIC ASSOC"
            },
            {
              "VENDOR_NAME": "GLOUCESTER TWSP"
            },
            {
              "VENDOR_NAME": "GNI PROPERTIES"
            },
            {
              "VENDOR_NAME": "GOETZ PRINTING CO."
            },
            {
              "VENDOR_NAME": "GOETZES CANDY COMPANY"
            },
            {
              "VENDOR_NAME": "GOLD COAST BAKING COMPANY"
            },
            {
              "VENDOR_NAME": "GOLDBLUM & HESS"
            },
            {
              "VENDOR_NAME": "GOLDEN CORRAL"
            },
            {
              "VENDOR_NAME": "GOLDEN MECHANICAL CONTR"
            },
            {
              "VENDOR_NAME": "GOLDEN SLIPPER CAMP"
            },
            {
              "VENDOR_NAME": "GOLDEN WEST EQUIPMENT"
            },
            {
              "VENDOR_NAME": "GONDOLA TRAIN"
            },
            {
              "VENDOR_NAME": "GOOCHLAND COUNTY"
            },
            {
              "VENDOR_NAME": "GOOCHLAND COUNTY TREASURER"
            },
            {
              "VENDOR_NAME": "GOOD FOOD INC./L&S SWEETENERS"
            },
            {
              "VENDOR_NAME": "GOOD LUCK CREATIONS"
            },
            {
              "VENDOR_NAME": "GOOD NEIGHBOR DAY"
            },
            {
              "VENDOR_NAME": "GOOD REALTY COMPANY"
            },
            {
              "VENDOR_NAME": "GOOD TECHNOLOGY"
            },
            {
              "VENDOR_NAME": "GOOD VIBRATIONS"
            },
            {
              "VENDOR_NAME": "GOOD WORKS"
            },
            {
              "VENDOR_NAME": "GOODALL RUBBER COMPANY"
            },
            {
              "VENDOR_NAME": "GOODE REFRIGERATION INC."
            },
            {
              "VENDOR_NAME": "GOODMAN & COMPANY LLP"
            },
            {
              "VENDOR_NAME": "GOODMAN & COMPANY"
            },
            {
              "VENDOR_NAME": "GOODMAN FAMILY HOLDINGS"
            },
            {
              "VENDOR_NAME": "GOODMAN FAMILY HOLDINGS"
            },
            {
              "VENDOR_NAME": "GOODMAN TANK LINES"
            },
            {
              "VENDOR_NAME": "GOODMILL"
            },
            {
              "VENDOR_NAME": "GOODNOE FARM DAIRY BAR"
            },
            {
              "VENDOR_NAME": "GOODWEST INDUSTRIES"
            },
            {
              "VENDOR_NAME": "GOODWILL FIRE COMPANY"
            },
            {
              "VENDOR_NAME": "GOOMBAA PIZZARIA"
            },
            {
              "VENDOR_NAME": "GOPA IT CONSULTANTS"
            },
            {
              "VENDOR_NAME": "GOPI CORPORATION"
            },
            {
              "VENDOR_NAME": "GORDON PAPER"
            },
            {
              "VENDOR_NAME": "GORDONS CORNER FIRE COMPANY"
            },
            {
              "VENDOR_NAME": "GORDONS CORNER WATER COMP"
            },
            {
              "VENDOR_NAME": "GOROVE/SLADE ASSOCIATES"
            },
            {
              "VENDOR_NAME": "GORSKI CONSTRUCTION"
            },
            {
              "VENDOR_NAME": "GORSKI ENGINEERING"
            },
            {
              "VENDOR_NAME": "GORSKI TRASH REMOVAL INC."
            },
            {
              "VENDOR_NAME": "GOSHEN FIRE COMPANY"
            },
            {
              "VENDOR_NAME": "GOV.PETER S duPONT"
            },
            {
              "VENDOR_NAME": "GOVERNOR MIFFLIN BOOSTER CLUB"
            },
            {
              "VENDOR_NAME": "GOYA FOODS"
            },
            {
              "VENDOR_NAME": "GP W INC DBA CEN"
            },
            {
              "VENDOR_NAME": "GRABBER"
            },
            {
              "VENDOR_NAME": "GRACE A. ODUMS"
            },
            {
              "VENDOR_NAME": "GRACE DONAHUE"
            },
            {
              "VENDOR_NAME": "GRACE DONOHUE"
            },
            {
              "VENDOR_NAME": "GRACE E PHILLIPS"
            },
            {
              "VENDOR_NAME": "GRACE E.MEYERS"
            },
            {
              "VENDOR_NAME": "GRACIANO LOPEZ"
            },
            {
              "VENDOR_NAME": "GRAD & COMPANY"
            },
            {
              "VENDOR_NAME": "GRADY'S WELDING"
            },
            {
              "VENDOR_NAME": "GRAHAM PAINTING INC."
            },
            {
              "VENDOR_NAME": "GRAINGER"
            },
            {
              "VENDOR_NAME": "GRAJILLAS AUTO BODY"
            },
            {
              "VENDOR_NAME": "GRAND FURNITURE"
            },
            {
              "VENDOR_NAME": "GRAND FURNITURE DISCOUNT"
            },
            {
              "VENDOR_NAME": "GRANGER"
            },
            {
              "VENDOR_NAME": "GRANITE PACKAGING INC."
            },
            {
              "VENDOR_NAME": "GRANITE TELECOMMUNICATIONS"
            },
            {
              "VENDOR_NAME": "GRANOR & CO"
            },
            {
              "VENDOR_NAME": "GRANTLAND ENTERPRISES"
            },
            {
              "VENDOR_NAME": "GRANTOR RETAINED ANNUITY TRUST"
            },
            {
              "VENDOR_NAME": "GRANT'S SERVICE CENTER"
            },
            {
              "VENDOR_NAME": "GRAPHIC CONTROLS LLC"
            },
            {
              "VENDOR_NAME": "GRASP"
            },
            {
              "VENDOR_NAME": "GRATTAN ASSOCIATES"
            },
            {
              "VENDOR_NAME": "GRAY & COMPANY PUBLISHING"
            },
            {
              "VENDOR_NAME": "GRAY MANUFACTURING CO."
            },
            {
              "VENDOR_NAME": "GRAYBAR ELECTRIC CO"
            },
            {
              "VENDOR_NAME": "GRAYBEALE CONSTRUCTION"
            },
            {
              "VENDOR_NAME": "GRC"
            },
            {
              "VENDOR_NAME": "GREAT ADVENTURE"
            },
            {
              "VENDOR_NAME": "GREAT AMERICAN TROLLEY CO."
            },
            {
              "VENDOR_NAME": "GREAT ATLANTIC AGENCY"
            },
            {
              "VENDOR_NAME": "GREAT BAY REG.VOLUNTEER"
            },
            {
              "VENDOR_NAME": "GREAT DANE ENTERPRISES"
            },
            {
              "VENDOR_NAME": "GREAT DANE TRAILERS"
            },
            {
              "VENDOR_NAME": "GREAT FOODS TO GO"
            },
            {
              "VENDOR_NAME": "GREAT LAKES CHEESE CO. INC"
            },
            {
              "VENDOR_NAME": "GREAT LAKES HIGHER GUARANTY CORP"
            },
            {
              "VENDOR_NAME": "GREAT PLACE TO WORK INSTITUTE"
            },
            {
              "VENDOR_NAME": "GREAT SENECA FINANCIAL CORP."
            },
            {
              "VENDOR_NAME": "GREAT SENECA FINANCIAL CORP.- VA. B"
            },
            {
              "VENDOR_NAME": "GREAT SENECA FINANICAL CORP"
            },
            {
              "VENDOR_NAME": "GREAT VALLEY HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "GREAT VALLEY SCHOOL DIST"
            },
            {
              "VENDOR_NAME": "GREAT WOLF LODGE OF WILLIAMSBURG"
            },
            {
              "VENDOR_NAME": "GREATER BALTIMORE MEDICAL CENTER"
            },
            {
              "VENDOR_NAME": "GREATER BEL AIR"
            },
            {
              "VENDOR_NAME": "GREATER CAPE MAY ELKS #2839"
            },
            {
              "VENDOR_NAME": "GREATER MERCER COUNTY CHAMBER OF"
            },
            {
              "VENDOR_NAME": "GREATER MILLVILLE CHAMBER OF COMMER"
            },
            {
              "VENDOR_NAME": "GREATER N.E.PHILADELPHIA"
            },
            {
              "VENDOR_NAME": "GREATER NEWARK BOYS & GIRLS CLUB"
            },
            {
              "VENDOR_NAME": "GREATER NORTHEAST PHILA."
            },
            {
              "VENDOR_NAME": "GREATER PHILA CHAMBER OF COMMERCE"
            },
            {
              "VENDOR_NAME": "GREATER PHILADELPHIA"
            },
            {
              "VENDOR_NAME": "GREATER PHILADELPHIA FOOD BANK"
            },
            {
              "VENDOR_NAME": "GREATER RICHMOND SCAN"
            },
            {
              "VENDOR_NAME": "GREATER SPRINGFIELD BUSINESS ASSOC."
            },
            {
              "VENDOR_NAME": "GREEN ACRES RECREATION ASSOC."
            },
            {
              "VENDOR_NAME": "GREEN EARTH FORCE"
            },
            {
              "VENDOR_NAME": "GREEN ESTATES LAWN"
            },
            {
              "VENDOR_NAME": "GREEN RUN HOMES ASSOCIATION"
            },
            {
              "VENDOR_NAME": "GREEN SPRING DAIRY"
            },
            {
              "VENDOR_NAME": "GREENBERG TRAURIG"
            },
            {
              "VENDOR_NAME": "GREENBRIAR ASSOCIATES"
            },
            {
              "VENDOR_NAME": "GREENBRIAR FARMS LANDSCAPING"
            },
            {
              "VENDOR_NAME": "GREENBRIER PROPERTY OWNERS ASSO"
            },
            {
              "VENDOR_NAME": "GREEN-FIELDS SWIM CLUB"
            },
            {
              "VENDOR_NAME": "GREENSPARK ENERGY SOLUTIONS"
            },
            {
              "VENDOR_NAME": "GREENVILLE COUNTY FAMILY COURT"
            },
            {
              "VENDOR_NAME": "GREENWAY SOUTH LLC"
            },
            {
              "VENDOR_NAME": "GREENWICH TOWNSHIP"
            },
            {
              "VENDOR_NAME": "GREENWICH TWP. BOARD OF HEALTH"
            },
            {
              "VENDOR_NAME": "GREG FORSTER"
            },
            {
              "VENDOR_NAME": "GREG HARVEY"
            },
            {
              "VENDOR_NAME": "GREGG TAYLOR"
            },
            {
              "VENDOR_NAME": "GREGORY ENGEL DMDMS"
            },
            {
              "VENDOR_NAME": "GREGORY MALLOY"
            },
            {
              "VENDOR_NAME": "GREGORY REES"
            },
            {
              "VENDOR_NAME": "GREGORY S PRICE"
            },
            {
              "VENDOR_NAME": "GREGORY SOTO INC"
            },
            {
              "VENDOR_NAME": "GREGORY SOTO INC."
            },
            {
              "VENDOR_NAME": "GREGORY V & COLEEN J SOTO"
            },
            {
              "VENDOR_NAME": "GRENALD WALDRON ASSOCIATES"
            },
            {
              "VENDOR_NAME": "GRETCHEN S. CORBIN"
            },
            {
              "VENDOR_NAME": "GREYCO INC"
            },
            {
              "VENDOR_NAME": "GREYSTONE REALTY ADVISORS"
            },
            {
              "VENDOR_NAME": "GRIM"
            },
            {
              "VENDOR_NAME": "GRINDMASTER CORP"
            },
            {
              "VENDOR_NAME": "GROGAN AND ASSOC."
            },
            {
              "VENDOR_NAME": "GROM ASSOCIATES"
            },
            {
              "VENDOR_NAME": "GRONE'S WRECKER&RECOVERY"
            },
            {
              "VENDOR_NAME": "GROOM ENERGY SOLUTIONS"
            },
            {
              "VENDOR_NAME": "GROSSE & QUADE MGMT CO"
            },
            {
              "VENDOR_NAME": "GROTTO PIZZA"
            },
            {
              "VENDOR_NAME": "GROUNDSCAPE TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "GROUNDWATER AND ENVIRONMENTAL"
            },
            {
              "VENDOR_NAME": "GROUP 1 SOFTWARE"
            },
            {
              "VENDOR_NAME": "GROUP PROPERTY MANAGEMENT"
            },
            {
              "VENDOR_NAME": "GS PROCTOR & ASSOCIATES"
            },
            {
              "VENDOR_NAME": "GS1 US"
            },
            {
              "VENDOR_NAME": "GSC/KLEINFELDER"
            },
            {
              "VENDOR_NAME": "GSP MARKETING TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "GST EXEMPT TRUST"
            },
            {
              "VENDOR_NAME": "GT TELECOM"
            },
            {
              "VENDOR_NAME": "GTDSC 5K RUN/ROD GRECO"
            },
            {
              "VENDOR_NAME": "GTS PREPAID INC.(PREPAID MERCH SERV"
            },
            {
              "VENDOR_NAME": "GUENTHER'S MOBIL SERVICE"
            },
            {
              "VENDOR_NAME": "GULF COAST ENERGY"
            },
            {
              "VENDOR_NAME": "GULF COAST ENERGY"
            },
            {
              "VENDOR_NAME": "GULF OIL LIMITED"
            },
            {
              "VENDOR_NAME": "GULF OIL LIMITED PARTNERSHIP"
            },
            {
              "VENDOR_NAME": "GULPH MILLS GOLF CLUB"
            },
            {
              "VENDOR_NAME": "GUMRUNNERS"
            },
            {
              "VENDOR_NAME": "GUNTON CORPORATION"
            },
            {
              "VENDOR_NAME": "GUS DURSO"
            },
            {
              "VENDOR_NAME": "GUY FESTA"
            },
            {
              "VENDOR_NAME": "GUY JENSEN CONSTABLE"
            },
            {
              "VENDOR_NAME": "GUY JENSEN/MORRA RECEIVERWSHIP"
            },
            {
              "VENDOR_NAME": "GUZZO LANDSCAPING"
            },
            {
              "VENDOR_NAME": "GVH (EASTON AVE) LP"
            },
            {
              "VENDOR_NAME": "GVH (EASTON AVE)"
            },
            {
              "VENDOR_NAME": "GYRO WORLDWIDE"
            },
            {
              "VENDOR_NAME": "H & D HOLDINGS"
            },
            {
              "VENDOR_NAME": "H & D HOLDINGS"
            },
            {
              "VENDOR_NAME": "H & H DISTRIBUTORS"
            },
            {
              "VENDOR_NAME": "H & S FENCING & LANDSCAPING"
            },
            {
              "VENDOR_NAME": "H A DEHART & SON"
            },
            {
              "VENDOR_NAME": "H I SALWEN & J L SALWEN"
            },
            {
              "VENDOR_NAME": "H. CARLSON & SONS"
            },
            {
              "VENDOR_NAME": "H. WARD ALBERT JR"
            },
            {
              "VENDOR_NAME": "H.B.MAYNARD AND CO.INC."
            },
            {
              "VENDOR_NAME": "H.E.L.P INC"
            },
            {
              "VENDOR_NAME": "H.E.S.A."
            },
            {
              "VENDOR_NAME": "H.M.H.T.T.C. RESPONSE TEAM"
            },
            {
              "VENDOR_NAME": "H.R. CONNECT"
            },
            {
              "VENDOR_NAME": "H.R.EWELL"
            },
            {
              "VENDOR_NAME": "HAB-BPT"
            },
            {
              "VENDOR_NAME": "HABITAT FOR HUMANITY"
            },
            {
              "VENDOR_NAME": "HABITAT MANAGEMENT & DESIGN"
            },
            {
              "VENDOR_NAME": "HAB-OPT"
            },
            {
              "VENDOR_NAME": "HAB-RET"
            },
            {
              "VENDOR_NAME": "HADDON SURGICAL ASSOCIATES"
            },
            {
              "VENDOR_NAME": "HADDON TOWNSHIP CREW"
            },
            {
              "VENDOR_NAME": "HADDONTOWNE SWIM CLUB"
            },
            {
              "VENDOR_NAME": "HAGEMEYER NORTH AMERICA"
            },
            {
              "VENDOR_NAME": "HAGERTY ENVIRONMENTAL"
            },
            {
              "VENDOR_NAME": "HAHN TRANSPORTATION"
            },
            {
              "VENDOR_NAME": "HAINES JONES AND CADBURY"
            },
            {
              "VENDOR_NAME": "HAINES TOWING INC"
            },
            {
              "VENDOR_NAME": "HAINESPORT TOWNSHIP"
            },
            {
              "VENDOR_NAME": "HAINESPORT TWP FIRE"
            },
            {
              "VENDOR_NAME": "HAJOCA CORPORATION"
            },
            {
              "VENDOR_NAME": "HALEY PAINT COMPANY"
            },
            {
              "VENDOR_NAME": "HALIFAX GEN'L DIST CT"
            },
            {
              "VENDOR_NAME": "HAMBURG"
            },
            {
              "VENDOR_NAME": "HAMILTON AREA YMCA"
            },
            {
              "VENDOR_NAME": "HAMILTON AUTO BODY"
            },
            {
              "VENDOR_NAME": "HAMILTON AVENUE ASSOCIATE"
            },
            {
              "VENDOR_NAME": "HAMILTON BEACH BRANDS"
            },
            {
              "VENDOR_NAME": "HAMILTON GIRLS SOFTBALL ASSOC."
            },
            {
              "VENDOR_NAME": "HAMILTON SOCCER CLUB"
            },
            {
              "VENDOR_NAME": "HAMILTON TWP. MUNICIPAL"
            },
            {
              "VENDOR_NAME": "HAMMER AND COMPANY"
            },
            {
              "VENDOR_NAME": "HAMMER LITHOGRAPH CORP"
            },
            {
              "VENDOR_NAME": "HAMMONTON FIRE DEPARTMENT"
            },
            {
              "VENDOR_NAME": "HAMMONTON GAZETTE"
            },
            {
              "VENDOR_NAME": "HAMMONTON MUNICIPAL COURT"
            },
            {
              "VENDOR_NAME": "HAMPTON CITY TREASURER"
            },
            {
              "VENDOR_NAME": "HAMPTON INN"
            },
            {
              "VENDOR_NAME": "HAMPTON INN - WALDORF"
            },
            {
              "VENDOR_NAME": "HAMPTON INN & SUITES"
            },
            {
              "VENDOR_NAME": "HAMPTON INN FREDERICK"
            },
            {
              "VENDOR_NAME": "HAMPTON INN RICHMOND-WEST"
            },
            {
              "VENDOR_NAME": "HAMPTON INN SEAFORD"
            },
            {
              "VENDOR_NAME": "HAMPTON INN-LEXINGTON PARK"
            },
            {
              "VENDOR_NAME": "HAMPTON INN-OLIVE BRANCH"
            },
            {
              "VENDOR_NAME": "HAMPTON INN-WHITE MARSH"
            },
            {
              "VENDOR_NAME": "HAMPTON LAKES EMS"
            },
            {
              "VENDOR_NAME": "HAMPTON LAKES VOLUNTEER FIRE CO.#1"
            },
            {
              "VENDOR_NAME": "HAMPTON MID-ATLANTIC CONVENIENCE PR"
            },
            {
              "VENDOR_NAME": "HAMPTON RDS FIANANCE CO"
            },
            {
              "VENDOR_NAME": "HAMPTON ROADS BAKING COMPANY"
            },
            {
              "VENDOR_NAME": "HAMPTON ROADS CHAMBER OF COMMERCE"
            },
            {
              "VENDOR_NAME": "HAMPTON ROADS FINANCE COMPANY"
            },
            {
              "VENDOR_NAME": "HAMPTON ROADS HEATING & COOLING"
            },
            {
              "VENDOR_NAME": "HAMPTON ROADS SANITATION DISTRICT"
            },
            {
              "VENDOR_NAME": "HAMPTON ROAIS FINANCE COMPANY"
            },
            {
              "VENDOR_NAME": "HAMPTON TOWNSHIP"
            },
            {
              "VENDOR_NAME": "HAMPTON/TASTYKAKE"
            },
            {
              "VENDOR_NAME": "HANCO PROPERTY MANAGEMENT"
            },
            {
              "VENDOR_NAME": "HANCO PROPERTY MANAGEMENT AND"
            },
            {
              "VENDOR_NAME": "HANDEX ENVIROMENTAL"
            },
            {
              "VENDOR_NAME": "HANDI-VANGELISM MINISTRIES INTNT'L"
            },
            {
              "VENDOR_NAME": "HANDY STORE FIXTURES INC"
            },
            {
              "VENDOR_NAME": "HAN-EMERGENCY PHYSICIANS"
            },
            {
              "VENDOR_NAME": "HANK GALGAN"
            },
            {
              "VENDOR_NAME": "HANNA'S PHARMACEUTICAL"
            },
            {
              "VENDOR_NAME": "HANOVER CIRCUIT COURT"
            },
            {
              "VENDOR_NAME": "HANOVER CO. TREASURER"
            },
            {
              "VENDOR_NAME": "HANOVER COUNTY"
            },
            {
              "VENDOR_NAME": "HANOVER COUNTY DEPARTMENT"
            },
            {
              "VENDOR_NAME": "HANOVER COUNTY TREASURER"
            },
            {
              "VENDOR_NAME": "HANOVER GENERAL DISTRICT COURT"
            },
            {
              "VENDOR_NAME": "HANOVER GEN'L DIST COURT"
            },
            {
              "VENDOR_NAME": "HANOVER GEN'L DIST CT."
            },
            {
              "VENDOR_NAME": "HANOVER GEN'L DIST. COURT"
            },
            {
              "VENDOR_NAME": "HANOVER GEN'L DIST. CT."
            },
            {
              "VENDOR_NAME": "HANOVER JDR COURT"
            },
            {
              "VENDOR_NAME": "HANOVER JDR DISTRICT COURT"
            },
            {
              "VENDOR_NAME": "HANOVER TOWNSHIP"
            },
            {
              "VENDOR_NAME": "HANOVER TWP TREASURY DEPT"
            },
            {
              "VENDOR_NAME": "HANOVER WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "HANSEN BEVERAGE COMPANY"
            },
            {
              "VENDOR_NAME": "HAPPY LAWN"
            },
            {
              "VENDOR_NAME": "HARFORD COUNTY"
            },
            {
              "VENDOR_NAME": "HARFORD COUNTY DEPT OF PUBLIC WORKS"
            },
            {
              "VENDOR_NAME": "HARFORD COUNTY DEPT. OF"
            },
            {
              "VENDOR_NAME": "HARFORD COUNTY DEPT. OF WATER & SEW"
            },
            {
              "VENDOR_NAME": "HARFORD COUNTY GOVERNMENT"
            },
            {
              "VENDOR_NAME": "HARFORD COUNTY HEALTH DEPT."
            },
            {
              "VENDOR_NAME": "HARFORD CTY"
            },
            {
              "VENDOR_NAME": "HARFORD IND. PARK BUSINESS TRUST"
            },
            {
              "VENDOR_NAME": "HARFORD IND.Pk. BUSINESS TRUST"
            },
            {
              "VENDOR_NAME": "HARFORD SANITATION SERV"
            },
            {
              "VENDOR_NAME": "HARFORD UNITED CHARITIES"
            },
            {
              "VENDOR_NAME": "HARIBO OF AMERICA INC"
            },
            {
              "VENDOR_NAME": "HARKINS & HARKINS"
            },
            {
              "VENDOR_NAME": "HARLEYSVILLE SENIOR CENTER"
            },
            {
              "VENDOR_NAME": "HARLEYSVILLE TOWNSHIP WAGE TAX PAYA"
            },
            {
              "VENDOR_NAME": "HARMONY CONSTRUCTION"
            },
            {
              "VENDOR_NAME": "HARMONY FOUR ASSOC L P"
            },
            {
              "VENDOR_NAME": "HAROLD AND LAURA SWARTZ"
            },
            {
              "VENDOR_NAME": "HAROLD ANDERSON"
            },
            {
              "VENDOR_NAME": "HAROLD C SUTTON"
            },
            {
              "VENDOR_NAME": "HAROLD C. SUTTON"
            },
            {
              "VENDOR_NAME": "HAROLD C.SUTTON"
            },
            {
              "VENDOR_NAME": "HARPER ASSOCIATES"
            },
            {
              "VENDOR_NAME": "HARRINGTON INDUSTRIAL PLASTICS"
            },
            {
              "VENDOR_NAME": "HARRISBURG DAIRIES"
            },
            {
              "VENDOR_NAME": "HARRISBURG NEWS COMPANY"
            },
            {
              "VENDOR_NAME": "HARRISBURG NEWS-OUTER MARKET"
            },
            {
              "VENDOR_NAME": "HARRISON SUPPLY CO INC"
            },
            {
              "VENDOR_NAME": "HARRISON TOWNSHIP"
            },
            {
              "VENDOR_NAME": "HARRISON TWP FIRE DIST."
            },
            {
              "VENDOR_NAME": "HARROWGATE ATHLETIC ASSN SOFTBALL"
            },
            {
              "VENDOR_NAME": "HARRY F. ORTLIP COMPANY"
            },
            {
              "VENDOR_NAME": "HARRY MC HUGH"
            },
            {
              "VENDOR_NAME": "HARRY PECK"
            },
            {
              "VENDOR_NAME": "HARRY'S SAVOY GRILL"
            },
            {
              "VENDOR_NAME": "HARTEFELD NATIONAL GOLF CLUB"
            },
            {
              "VENDOR_NAME": "HARTZELL MACHINE WORKS"
            },
            {
              "VENDOR_NAME": "HARVARD BATTERY"
            },
            {
              "VENDOR_NAME": "HARVARD BUSINESS REVIEW"
            },
            {
              "VENDOR_NAME": "HARVARD BUSINESS SCHOOL"
            },
            {
              "VENDOR_NAME": "HARVEST CREDIT MANAGEMENT"
            },
            {
              "VENDOR_NAME": "HARVEY HALL"
            },
            {
              "VENDOR_NAME": "HARVEY MACK SALES & SERVICE"
            },
            {
              "VENDOR_NAME": "HARVEY MACK SALES & SERVICE"
            },
            {
              "VENDOR_NAME": "HATBORO BAPTIST CHURCH"
            },
            {
              "VENDOR_NAME": "HATBORO WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "HATBORO-HORSHAM SCHOOL DIST."
            },
            {
              "VENDOR_NAME": "HATFIELD QUALITY MEATS"
            },
            {
              "VENDOR_NAME": "HATFIELD QUALITY MEATS"
            },
            {
              "VENDOR_NAME": "HATFIELD TOWNSHIP"
            },
            {
              "VENDOR_NAME": "HATFIELD TOWNSHIP TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "HATFIELD TWP MUN AUTH"
            },
            {
              "VENDOR_NAME": "HAUSER'S TRUCK SERVICE"
            },
            {
              "VENDOR_NAME": "HAVERFORD GIRLS BASKETBALL"
            },
            {
              "VENDOR_NAME": "HAVERFORD HIGH SCHOOL CHEERLEADING"
            },
            {
              "VENDOR_NAME": "HAVERFORD TOWNSHIP"
            },
            {
              "VENDOR_NAME": "HAVERFORD TWP"
            },
            {
              "VENDOR_NAME": "HAVERFORD TWP SCHOOL DISTRICT"
            },
            {
              "VENDOR_NAME": "HAWAIIAN HOST INC"
            },
            {
              "VENDOR_NAME": "HAWORTH INC"
            },
            {
              "VENDOR_NAME": "HAY GROUP"
            },
            {
              "VENDOR_NAME": "HAZLET TOWNSHIP BUREAU OF"
            },
            {
              "VENDOR_NAME": "HAZLET TWP SEWERAGE AUTH"
            },
            {
              "VENDOR_NAME": "HCA HEALTH SERVICES OF VA"
            },
            {
              "VENDOR_NAME": "HCAC FINANCE"
            },
            {
              "VENDOR_NAME": "HCR MANORCARE"
            },
            {
              "VENDOR_NAME": "HD ELECTRIC"
            },
            {
              "VENDOR_NAME": "HD SUPPLY"
            },
            {
              "VENDOR_NAME": "HEA-COR INC"
            },
            {
              "VENDOR_NAME": "Head Office Test"
            },
            {
              "VENDOR_NAME": "HeadOffice AP-Vendor"
            },
            {
              "VENDOR_NAME": "HEADSETS.COM"
            },
            {
              "VENDOR_NAME": "HEALTH ADVOCATES"
            },
            {
              "VENDOR_NAME": "HEALTH AT WORK WELLNESS"
            },
            {
              "VENDOR_NAME": "HEALTH PARTNERS"
            },
            {
              "VENDOR_NAME": "HEARING SAFETY"
            },
            {
              "VENDOR_NAME": "HEATHER AND ANDREW SMITH"
            },
            {
              "VENDOR_NAME": "HEBERT CONFECTIONS"
            },
            {
              "VENDOR_NAME": "HECO CORPORATION"
            },
            {
              "VENDOR_NAME": "HECTOR HERNANDEZ"
            },
            {
              "VENDOR_NAME": "HEEREMA COMPANY"
            },
            {
              "VENDOR_NAME": "HEIDI A.SCHNORR & MARK J.LEAVY"
            },
            {
              "VENDOR_NAME": "HEIDRICK & STRUGGLES"
            },
            {
              "VENDOR_NAME": "HEILBRUNN"
            },
            {
              "VENDOR_NAME": "HEILBRUNN PAPE"
            },
            {
              "VENDOR_NAME": "HEINZ NORTH AMERICA"
            },
            {
              "VENDOR_NAME": "HELEN EHLERS"
            },
            {
              "VENDOR_NAME": "HELEN F.GRAHAM CANCER CENTER"
            },
            {
              "VENDOR_NAME": "HELEN JANE M. FREAS-FOGG"
            },
            {
              "VENDOR_NAME": "HELEN SCHROEDER ERVIN"
            },
            {
              "VENDOR_NAME": "HELEN WILLIAMS"
            },
            {
              "VENDOR_NAME": "HELLO DIRECT INC"
            },
            {
              "VENDOR_NAME": "HELP DESK INSTITUTE"
            },
            {
              "VENDOR_NAME": "HELPING HANDS PANTRY"
            },
            {
              "VENDOR_NAME": "HEMPFIELD SCHOOL DISTRICT"
            },
            {
              "VENDOR_NAME": "HENDERSEN WEBB"
            },
            {
              "VENDOR_NAME": "HENDERSON-WEBB INC"
            },
            {
              "VENDOR_NAME": "HENKEL ADHESIVES"
            },
            {
              "VENDOR_NAME": "HENNESSY'S RESTAURANT & CATERING"
            },
            {
              "VENDOR_NAME": "HENRICO CIRCUIT COURT"
            },
            {
              "VENDOR_NAME": "HENRICO CO. GEN'L DIST COURT"
            },
            {
              "VENDOR_NAME": "HENRICO COUNTY"
            },
            {
              "VENDOR_NAME": "HENRICO FEDERAL CREDIT UNION"
            },
            {
              "VENDOR_NAME": "HENRICO FIRE DEPARTMENT"
            },
            {
              "VENDOR_NAME": "HENRICO GEN'L DIST COURT"
            },
            {
              "VENDOR_NAME": "HENRICO JDR COURT"
            },
            {
              "VENDOR_NAME": "HENRICO PAL"
            },
            {
              "VENDOR_NAME": "HENRY & SHIRLEY FRANKEL"
            },
            {
              "VENDOR_NAME": "HENRY B. dUPONT MIDDLE SCHOOL"
            },
            {
              "VENDOR_NAME": "HENRY CIFALOGLIO"
            },
            {
              "VENDOR_NAME": "HENRY CLARK GREW TRUST"
            },
            {
              "VENDOR_NAME": "HENRY CLARK GREW TRUST UAD 10/21/05"
            },
            {
              "VENDOR_NAME": "HENRY D WOOD"
            },
            {
              "VENDOR_NAME": "HENRY FERRIS"
            },
            {
              "VENDOR_NAME": "HENRY FINTAN MCHUGH"
            },
            {
              "VENDOR_NAME": "HENRY GRIERSON"
            },
            {
              "VENDOR_NAME": "HENRY HARTMAN"
            },
            {
              "VENDOR_NAME": "HENRY J GEUTING"
            },
            {
              "VENDOR_NAME": "HENRY J MCHUGH"
            },
            {
              "VENDOR_NAME": "HENRY J OR MARG M MCHUGH"
            },
            {
              "VENDOR_NAME": "HENRY J. MCHUGH"
            },
            {
              "VENDOR_NAME": "HENRY J.MANCINI & ASSOC."
            },
            {
              "VENDOR_NAME": "HENRY L SAVAGE JR"
            },
            {
              "VENDOR_NAME": "HENRY S. GREW"
            },
            {
              "VENDOR_NAME": "HERBEIN+COMPANY INC"
            },
            {
              "VENDOR_NAME": "HERBERT HICKS"
            },
            {
              "VENDOR_NAME": "HERBERT SPEACH"
            },
            {
              "VENDOR_NAME": "HERBERT YENTIS & CO"
            },
            {
              "VENDOR_NAME": "HERCULES REAL ESTATE"
            },
            {
              "VENDOR_NAME": "HERCULES REAL ESTATE SERVICES"
            },
            {
              "VENDOR_NAME": "HERITAGE HOUSE AND PAPERWAITER"
            },
            {
              "VENDOR_NAME": "HERITAGE LAND TRANSFER CO."
            },
            {
              "VENDOR_NAME": "HERKER BLDG & LAWN MAINT"
            },
            {
              "VENDOR_NAME": "HERO CAMPAIGN"
            },
            {
              "VENDOR_NAME": "HERO SCHOLARSHIP FUND"
            },
            {
              "VENDOR_NAME": "HERPETOLOGICAL ASSOC."
            },
            {
              "VENDOR_NAME": "HERRS POTATO CHIPS INC"
            },
            {
              "VENDOR_NAME": "HERSHEY ENTERTAINMENT"
            },
            {
              "VENDOR_NAME": "HERSHEYPARK"
            },
            {
              "VENDOR_NAME": "HERSHEY'S MILL GOLF CLUB"
            },
            {
              "VENDOR_NAME": "HERTLESS BROTHERS ROOFING"
            },
            {
              "VENDOR_NAME": "HESS"
            },
            {
              "VENDOR_NAME": "HESS CORPORATION"
            },
            {
              "VENDOR_NAME": "HESS ENERGY TRADING COMPANY LLC"
            },
            {
              "VENDOR_NAME": "HEWLETT-PACKARD"
            },
            {
              "VENDOR_NAME": "HICKEY & HICKEY"
            },
            {
              "VENDOR_NAME": "HIGG SERVICES INC"
            },
            {
              "VENDOR_NAME": "HIGH GRADE BEVERAGE"
            },
            {
              "VENDOR_NAME": "HIGHLAND PARK CHURCH OF GOD"
            },
            {
              "VENDOR_NAME": "HIGHLAND YOUTH SOCCER CLUB"
            },
            {
              "VENDOR_NAME": "HIGHMARK BLUE SHIELD"
            },
            {
              "VENDOR_NAME": "HIGHWAY GARAGE"
            },
            {
              "VENDOR_NAME": "HIGHWAY MARKINGS"
            },
            {
              "VENDOR_NAME": "HIGHWOODS REALTY"
            },
            {
              "VENDOR_NAME": "HILCO"
            },
            {
              "VENDOR_NAME": "HILDA BARG CANDIDATE"
            },
            {
              "VENDOR_NAME": "HILEX POLY COMPANY"
            },
            {
              "VENDOR_NAME": "HILL ELECTRICAL"
            },
            {
              "VENDOR_NAME": "HILL MANAGEMENT SERVICES"
            },
            {
              "VENDOR_NAME": "HILL PHOENIX"
            },
            {
              "VENDOR_NAME": "HILL STORAGE"
            },
            {
              "VENDOR_NAME": "HILLSBORO RESCUE SQUAD"
            },
            {
              "VENDOR_NAME": "HILLSBOROUGH TWP.VOL.FIRE CO."
            },
            {
              "VENDOR_NAME": "HILLTOWN CIVIC ASSOCIATION"
            },
            {
              "VENDOR_NAME": "HILLTOWN TOWNSHIP"
            },
            {
              "VENDOR_NAME": "HILLTOWN TOWNSHIP POLICE"
            },
            {
              "VENDOR_NAME": "HILLTOWN TOWNSHIP WATER & SEWER AUT"
            },
            {
              "VENDOR_NAME": "HILLYARD DELAWARE VALLEY"
            },
            {
              "VENDOR_NAME": "HILTI"
            },
            {
              "VENDOR_NAME": "HILTON CHERRY HILL"
            },
            {
              "VENDOR_NAME": "HILTON GARDEN INN"
            },
            {
              "VENDOR_NAME": "HILTON GARDEN INN-EDISON"
            },
            {
              "VENDOR_NAME": "HILTON GRAND VACATION CLUB"
            },
            {
              "VENDOR_NAME": "HILTON PARSIPPANY"
            },
            {
              "VENDOR_NAME": "HILTON-WILMINGTON/CHRISTIANA"
            },
            {
              "VENDOR_NAME": "HINKLE"
            },
            {
              "VENDOR_NAME": "HIONIS HOTEL INC.."
            },
            {
              "VENDOR_NAME": "HIRSCHORN BUILDERS"
            },
            {
              "VENDOR_NAME": "HIRSHLAND AND COMPANY"
            },
            {
              "VENDOR_NAME": "HISTORIC BOK SANCTUARY"
            },
            {
              "VENDOR_NAME": "HITCHENS TIRE SERVICE"
            },
            {
              "VENDOR_NAME": "HI-Z BUSINESS EQUIPMENT"
            },
            {
              "VENDOR_NAME": "HK LIGHTING DESIGN INC"
            },
            {
              "VENDOR_NAME": "HMC INT'L DIV."
            },
            {
              "VENDOR_NAME": "HNTB ARCHITECTS ENGINEERS PLANNERS"
            },
            {
              "VENDOR_NAME": "HO4DF Test Inc"
            },
            {
              "VENDOR_NAME": "HOBART CORP"
            },
            {
              "VENDOR_NAME": "HOCKEY IN MIDDLETOWN"
            },
            {
              "VENDOR_NAME": "HODGE PRODUCTS"
            },
            {
              "VENDOR_NAME": "HOFFMAN AGENCY"
            },
            {
              "VENDOR_NAME": "HOFFMAN BEVERAGE"
            },
            {
              "VENDOR_NAME": "HOFING GROUP LIMITED"
            },
            {
              "VENDOR_NAME": "HOGAN & HARTSON LLP"
            },
            {
              "VENDOR_NAME": "HOLABIRD EAST INC"
            },
            {
              "VENDOR_NAME": "HOLCOMB BEHAVIORAL HEALTH SYSTEM"
            },
            {
              "VENDOR_NAME": "HOLIDAY CLASSICS"
            },
            {
              "VENDOR_NAME": "HOLIDAY INN"
            },
            {
              "VENDOR_NAME": "HOLIDAY INN - RUNNEMEDE"
            },
            {
              "VENDOR_NAME": "HOLIDAY INN CHERRY HILL"
            },
            {
              "VENDOR_NAME": "HOLIDAY INN EXPRESS"
            },
            {
              "VENDOR_NAME": "HOLIDAY INN EXPRESS PRINCE FREDERIC"
            },
            {
              "VENDOR_NAME": "HOLIDAY INN EXPRESS-EDGEWOOD"
            },
            {
              "VENDOR_NAME": "HOLIDAY INN HAZLET"
            },
            {
              "VENDOR_NAME": "HOLIDAY INN HOTEL & SUITES"
            },
            {
              "VENDOR_NAME": "HOLIDAY INN NORTH"
            },
            {
              "VENDOR_NAME": "HOLIDAY INN OF TOMS RIVER"
            },
            {
              "VENDOR_NAME": "HOLIDAY INN PATRIOT"
            },
            {
              "VENDOR_NAME": "HOLIDAY INN PHILADELPHIA-STADIUM"
            },
            {
              "VENDOR_NAME": "HOLIDAY INN SELECT"
            },
            {
              "VENDOR_NAME": "HOLIDAY INN SELECT SOLOMONS"
            },
            {
              "VENDOR_NAME": "HOLIDAY INN-CHESAPEAKE HOUSE"
            },
            {
              "VENDOR_NAME": "HOLIDAY SIGNS"
            },
            {
              "VENDOR_NAME": "HOLISTISYS"
            },
            {
              "VENDOR_NAME": "HOLLY RAVINE EQUITIES LLC"
            },
            {
              "VENDOR_NAME": "HOLLY S. MURRAY"
            },
            {
              "VENDOR_NAME": "HOLMES FIRE COMPANY"
            },
            {
              "VENDOR_NAME": "HOLY FAMILY UNIVERSITY"
            },
            {
              "VENDOR_NAME": "HOLY GHOST UKRANIAN"
            },
            {
              "VENDOR_NAME": "HOLY REDEEMER"
            },
            {
              "VENDOR_NAME": "HOLY REDEEMER FOUNDATION"
            },
            {
              "VENDOR_NAME": "HOME DEPOT"
            },
            {
              "VENDOR_NAME": "HOME DEPOT/GECF"
            },
            {
              "VENDOR_NAME": "HOME PROPERTIES OF DEVON"
            },
            {
              "VENDOR_NAME": "HOMESTEAD INVESTMENT PROPERTIES"
            },
            {
              "VENDOR_NAME": "HOMESTEAD PUBLISHING"
            },
            {
              "VENDOR_NAME": "HOMESTEAD STUDIO SUITES"
            },
            {
              "VENDOR_NAME": "HOMESTEAD STUDIO SUITES-MALVERN"
            },
            {
              "VENDOR_NAME": "HOMEWOOD SUITES"
            },
            {
              "VENDOR_NAME": "HONDA OF TOMS RIVER"
            },
            {
              "VENDOR_NAME": "HONEYBAKED HAM CO."
            },
            {
              "VENDOR_NAME": "HONORABLE ROBERT L.EHRLICH"
            },
            {
              "VENDOR_NAME": "HOOT SYSTEMS"
            },
            {
              "VENDOR_NAME": "HOPE HEALTH/IHAC"
            },
            {
              "VENDOR_NAME": "HOPE PARTNERSHIP FOR EDUCATION"
            },
            {
              "VENDOR_NAME": "HOPEWELL CIRCUIT COURT"
            },
            {
              "VENDOR_NAME": "HOPEWELL COMBINED COURT"
            },
            {
              "VENDOR_NAME": "HOPEWELL COURT"
            },
            {
              "VENDOR_NAME": "HOPEWELL GEN'L DIST COURT"
            },
            {
              "VENDOR_NAME": "HOPEWELL GEN'L DIST CT"
            },
            {
              "VENDOR_NAME": "HOPEWELL NEWS"
            },
            {
              "VENDOR_NAME": "HOPEWELL VALLEY YMCA"
            },
            {
              "VENDOR_NAME": "HOPKINS & WAYSON"
            },
            {
              "VENDOR_NAME": "HORACE K. CORBIN"
            },
            {
              "VENDOR_NAME": "HORIZON CONSULTANTS"
            },
            {
              "VENDOR_NAME": "HORMEL FINACIAL SERVICES"
            },
            {
              "VENDOR_NAME": "HORNER & CANTER ASSOC INC"
            },
            {
              "VENDOR_NAME": "HORSHAM CHAMBERS OF COMMERCE"
            },
            {
              "VENDOR_NAME": "HORSHAM TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "HORSHAM WATER & SEWER AUTHORITY"
            },
            {
              "VENDOR_NAME": "HOSPICE CARE OF S.JERSEY"
            },
            {
              "VENDOR_NAME": "HOSPITAL AUTHORITY OF NORFOLK"
            },
            {
              "VENDOR_NAME": "HOSPITAL OF FOX CHASE CANCER CENTER"
            },
            {
              "VENDOR_NAME": "HOSTESS-INTERSTATE BRANDS CORP"
            },
            {
              "VENDOR_NAME": "HOSTMYSITE.COM"
            },
            {
              "VENDOR_NAME": "HOTEL DUPONT"
            },
            {
              "VENDOR_NAME": "HOTSY EQUIPMENT COMPANY"
            },
            {
              "VENDOR_NAME": "HOUCK & GOFUS FUNERAL HOME INC."
            },
            {
              "VENDOR_NAME": "HOUGHTON CHEMICAL CORPORATION"
            },
            {
              "VENDOR_NAME": "HOUSTON REFINING"
            },
            {
              "VENDOR_NAME": "HOWARD B STOECKEL"
            },
            {
              "VENDOR_NAME": "HOWARD B.OR MARIETTA C.STOECKEL"
            },
            {
              "VENDOR_NAME": "HOWARD ENERGY INC."
            },
            {
              "VENDOR_NAME": "HOWARD FISCHER ASSOCIATES INTERNATI"
            },
            {
              "VENDOR_NAME": "HOWARD GOLDSTEIN"
            },
            {
              "VENDOR_NAME": "HOWARD STOECKEL"
            },
            {
              "VENDOR_NAME": "HOWELL DAY FESTIVAL 2005"
            },
            {
              "VENDOR_NAME": "HOWELL FIRE BUREAU"
            },
            {
              "VENDOR_NAME": "HOWELL TOWNSHIP FIRE COMPANY #1"
            },
            {
              "VENDOR_NAME": "HOWELL TOWNSHIP MUA"
            },
            {
              "VENDOR_NAME": "HOWELL TWP MUNICIPAL UTIL"
            },
            {
              "VENDOR_NAME": "HOWELL'S HEATING & AIR CONDITIONING"
            },
            {
              "VENDOR_NAME": "HP HOOD LLC"
            },
            {
              "VENDOR_NAME": "HPK PROPERTIES"
            },
            {
              "VENDOR_NAME": "HPK PROPERTY MAINTENANCE INC."
            },
            {
              "VENDOR_NAME": "HR INVESTMENT CONSULTANTS INC."
            },
            {
              "VENDOR_NAME": "HR SMART"
            },
            {
              "VENDOR_NAME": "HRD PRESS"
            },
            {
              "VENDOR_NAME": "HRDQ"
            },
            {
              "VENDOR_NAME": "HRLP NC-VA"
            },
            {
              "VENDOR_NAME": "HRM USA INC."
            },
            {
              "VENDOR_NAME": "HRUBS"
            },
            {
              "VENDOR_NAME": "HSBC"
            },
            {
              "VENDOR_NAME": "HSBC BUSINESS SOLUTIONS"
            },
            {
              "VENDOR_NAME": "HTRBA LITTLE LEAGUE"
            },
            {
              "VENDOR_NAME": "HUA MEI"
            },
            {
              "VENDOR_NAME": "HUBERT DISTRIBUTING CO"
            },
            {
              "VENDOR_NAME": "HUDGINS CONTRACTING CORP."
            },
            {
              "VENDOR_NAME": "HUDSON CAMPAIGN COMMITTEE"
            },
            {
              "VENDOR_NAME": "HUDSON CO NEWS MAGAZINES"
            },
            {
              "VENDOR_NAME": "HUFF"
            },
            {
              "VENDOR_NAME": "HUMAN CAPITAL INSTITUTE"
            },
            {
              "VENDOR_NAME": "HUMAN CAPITAL SOLUTIONS"
            },
            {
              "VENDOR_NAME": "HUMAN CONCEPTS LLC"
            },
            {
              "VENDOR_NAME": "HUMAN RESOURCE COUNCIL"
            },
            {
              "VENDOR_NAME": "HUMAN RESOURCE EXECUTIVE"
            },
            {
              "VENDOR_NAME": "HUMANSCALE CORP."
            },
            {
              "VENDOR_NAME": "HUMENTUM CORPORATION"
            },
            {
              "VENDOR_NAME": "HUMM FOODS"
            },
            {
              "VENDOR_NAME": "HUNTER ASSOCIATES LABORATORY"
            },
            {
              "VENDOR_NAME": "HUNTER LOTT REALTY COMPANY"
            },
            {
              "VENDOR_NAME": "HUNTERDON COUNTY PLANNING BOARD"
            },
            {
              "VENDOR_NAME": "HUNTERDON COUNTY SOIL CONS.DIST."
            },
            {
              "VENDOR_NAME": "HUNTER'S LAWN SERVICE"
            },
            {
              "VENDOR_NAME": "HUNTER'S LAWN SERVICES"
            },
            {
              "VENDOR_NAME": "HUNTINGTOWN GOLF TOURNAMENT"
            },
            {
              "VENDOR_NAME": "HUNTON & WILLIAMS LLP"
            },
            {
              "VENDOR_NAME": "HUSSMANN CORPORATION"
            },
            {
              "VENDOR_NAME": "HVAC CONCEPTS"
            },
            {
              "VENDOR_NAME": "HYATT REGENCY LaJOLLA"
            },
            {
              "VENDOR_NAME": "HYDROCARBON RECOVERY"
            },
            {
              "VENDOR_NAME": "HYPERCOM SALES & SERVICE"
            },
            {
              "VENDOR_NAME": "I P E C"
            },
            {
              "VENDOR_NAME": "I SQUARE APTS."
            },
            {
              "VENDOR_NAME": "I.T. LANDES & SONS INC."
            },
            {
              "VENDOR_NAME": "IAN CAMPBELL PHOTOGRAPHY"
            },
            {
              "VENDOR_NAME": "iANYWHERE SOLUTIONS"
            },
            {
              "VENDOR_NAME": "IASTA.COM"
            },
            {
              "VENDOR_NAME": "IBM CORPORATION"
            },
            {
              "VENDOR_NAME": "ICE WORKS"
            },
            {
              "VENDOR_NAME": "ICEE USA"
            },
            {
              "VENDOR_NAME": "ICIX NORTH AMERICA LLC"
            },
            {
              "VENDOR_NAME": "ICON IDENTITY SOLUTIONS"
            },
            {
              "VENDOR_NAME": "ICR"
            },
            {
              "VENDOR_NAME": "ICSC PENNSYLVANIA/NEW JERSEY/DELAWA"
            },
            {
              "VENDOR_NAME": "ICSC VIRGINIA IDEA EXCHANGE"
            },
            {
              "VENDOR_NAME": "IDEA INTEGRATION"
            },
            {
              "VENDOR_NAME": "IDEAL SHIELD"
            },
            {
              "VENDOR_NAME": "IDEAVILLAGE PRODUCTS CORP"
            },
            {
              "VENDOR_NAME": "IDL WORLDWIDE"
            },
            {
              "VENDOR_NAME": "IDMS - ACCOUNT ABILITY"
            },
            {
              "VENDOR_NAME": "IDS SCHEER AMERICAS"
            },
            {
              "VENDOR_NAME": "IEEE COMPUTER SOCIETY"
            },
            {
              "VENDOR_NAME": "IESI-MD CORPORATION"
            },
            {
              "VENDOR_NAME": "iET SOLUTIONS"
            },
            {
              "VENDOR_NAME": "iFRACTAL LLC"
            },
            {
              "VENDOR_NAME": "IFT"
            },
            {
              "VENDOR_NAME": "IGA NEPHROPATHY FOUNDATION"
            },
            {
              "VENDOR_NAME": "IGOR PRONIN"
            },
            {
              "VENDOR_NAME": "IHS GLOBAL"
            },
            {
              "VENDOR_NAME": "IM OSDP - PHILADELPHIA"
            },
            {
              "VENDOR_NAME": "IMAGE EXPRESS INC."
            },
            {
              "VENDOR_NAME": "IMAGE MANIA"
            },
            {
              "VENDOR_NAME": "IMAGES BUILDING SERV.INC"
            },
            {
              "VENDOR_NAME": "IMAGEWORKS DISPLAY"
            },
            {
              "VENDOR_NAME": "IMAGING CENTER FOR WOMEN"
            },
            {
              "VENDOR_NAME": "IMAGING CTR. FOR WOMEN"
            },
            {
              "VENDOR_NAME": "IMAJE USA"
            },
            {
              "VENDOR_NAME": "IMI CORNELIUS INC"
            },
            {
              "VENDOR_NAME": "IMMACULATA INVITATIONAL"
            },
            {
              "VENDOR_NAME": "IMMACULATA UNIVERSITY"
            },
            {
              "VENDOR_NAME": "IMMACULATA UNIVERSITY ART SHOW"
            },
            {
              "VENDOR_NAME": "IMPACT CONFECTIONS"
            },
            {
              "VENDOR_NAME": "IMPACTING YOUR WORLD MINISTRIES"
            },
            {
              "VENDOR_NAME": "IMPRESS PACKAGING INC"
            },
            {
              "VENDOR_NAME": "IMPRIANO ROFFING & SIDING INC."
            },
            {
              "VENDOR_NAME": "IMPULSE MARKETING CO. LLC"
            },
            {
              "VENDOR_NAME": "IMS AUDIO VISUAL INC"
            },
            {
              "VENDOR_NAME": "IMS ENVIROMENTAL SERV"
            },
            {
              "VENDOR_NAME": "IN THE MOOD TO CRUISE & TRAVEL"
            },
            {
              "VENDOR_NAME": "IN THE NEWS"
            },
            {
              "VENDOR_NAME": "IN ZONE BRANDS"
            },
            {
              "VENDOR_NAME": "INA VANESSA SCOTT"
            },
            {
              "VENDOR_NAME": "INC. LIGHTHOUSE FURNITURE & APPL."
            },
            {
              "VENDOR_NAME": "INCREDIBLY EDIBLE DELITES"
            },
            {
              "VENDOR_NAME": "INDEPENDENCE BLUE CROSS"
            },
            {
              "VENDOR_NAME": "INDEPENDENCE CHAPTER"
            },
            {
              "VENDOR_NAME": "INDEPENDENCE COMM. INC"
            },
            {
              "VENDOR_NAME": "INDEPENDENCE COMMUNICATIONS"
            },
            {
              "VENDOR_NAME": "INDEPENDENCE CONSTRUCTION"
            },
            {
              "VENDOR_NAME": "INDEPENDENCE VISITOR CENTER CORP."
            },
            {
              "VENDOR_NAME": "INDEPENDENT NEWSPAPERS"
            },
            {
              "VENDOR_NAME": "INDEPENDENT SIGNS"
            },
            {
              "VENDOR_NAME": "INDIAN VALLEY APPRAISAL COMPANY"
            },
            {
              "VENDOR_NAME": "INDIAN VALLEY TRUCKING"
            },
            {
              "VENDOR_NAME": "INDIANA STATE CENTRAL COLL. UNIT"
            },
            {
              "VENDOR_NAME": "INDIANA STATE CENTRAL COLLECTIONS"
            },
            {
              "VENDOR_NAME": "INDOOR ENVIRONMENTS"
            },
            {
              "VENDOR_NAME": "INDUSTRIAL APPRAISAL COMPANY"
            },
            {
              "VENDOR_NAME": "INDUSTRIAL CONTROL"
            },
            {
              "VENDOR_NAME": "INDUSTRIAL CONTROLS DISTRIBUTORS"
            },
            {
              "VENDOR_NAME": "INDUSTRIAL EQUIPMENT COMPANY"
            },
            {
              "VENDOR_NAME": "INDUSTRIAL MARINE SERVICE"
            },
            {
              "VENDOR_NAME": "INDUSTRIAL SUPPLIES CO"
            },
            {
              "VENDOR_NAME": "INDUSTRIAL WASTE CONTROL"
            },
            {
              "VENDOR_NAME": "INEGRATED WATER RESOURCES CONSULT."
            },
            {
              "VENDOR_NAME": "INFINITY BROADCASTING CORP."
            },
            {
              "VENDOR_NAME": "INFO SYSTEMS"
            },
            {
              "VENDOR_NAME": "INFOGUARD"
            },
            {
              "VENDOR_NAME": "INFOLINE"
            },
            {
              "VENDOR_NAME": "INFOLOGIX INC."
            },
            {
              "VENDOR_NAME": "ING"
            },
            {
              "VENDOR_NAME": "INGERSOLL RAND/HUSSMANN"
            },
            {
              "VENDOR_NAME": "INITIAL IDEAS"
            },
            {
              "VENDOR_NAME": "INLAND MID-ATLANTIC MANAGEMENT CORP"
            },
            {
              "VENDOR_NAME": "INNERSITE"
            },
            {
              "VENDOR_NAME": "INNISCRONE GOLF CLUB"
            },
            {
              "VENDOR_NAME": "INNO-PAK INC"
            },
            {
              "VENDOR_NAME": "INNO-PAK"
            },
            {
              "VENDOR_NAME": "INNOPLAST"
            },
            {
              "VENDOR_NAME": "INNOVATIVE AWARDS"
            },
            {
              "VENDOR_NAME": "INNOVATIVE CANDY CONCEPTS"
            },
            {
              "VENDOR_NAME": "INNOVATIVE CONSULTING"
            },
            {
              "VENDOR_NAME": "INNOVATIVE PACKAGING INC"
            },
            {
              "VENDOR_NAME": "INNOVATIVE TOTAL SECURITY LLC"
            },
            {
              "VENDOR_NAME": "INROADS"
            },
            {
              "VENDOR_NAME": "INSCCU"
            },
            {
              "VENDOR_NAME": "INSCCU-ASFE"
            },
            {
              "VENDOR_NAME": "INSIDE EDGE COMMERCIAL INTERIOR"
            },
            {
              "VENDOR_NAME": "INSIDEOUT DEVELOPMENT LLC"
            },
            {
              "VENDOR_NAME": "INSIGHT BEVERAGES"
            },
            {
              "VENDOR_NAME": "INSPECTORARE INSPECTION AND CONTROL"
            },
            {
              "VENDOR_NAME": "INSPECTORATE AMERICA CORPORATION"
            },
            {
              "VENDOR_NAME": "INSPIRED PERSPECTIVES"
            },
            {
              "VENDOR_NAME": "INSTA SIGNS PLUS"
            },
            {
              "VENDOR_NAME": "INSTANTWHIP BALTIMORE INC"
            },
            {
              "VENDOR_NAME": "INSTITUTE FOR APPLIED MANAGEMENT &"
            },
            {
              "VENDOR_NAME": "INSTITUTE FOR FORECASTING EDUCATION"
            },
            {
              "VENDOR_NAME": "INSTITUTE FOR INTERNATIONAL RESEARC"
            },
            {
              "VENDOR_NAME": "INSTITUTE FOR PARALEGAL EDUCATION"
            },
            {
              "VENDOR_NAME": "INSTITUTE OF BUSINESS FORECASTING"
            },
            {
              "VENDOR_NAME": "INSTITUTE OF FOOD"
            },
            {
              "VENDOR_NAME": "INSTITUTE OF INTERNAL AUDITORS"
            },
            {
              "VENDOR_NAME": "INSTITUTE OF INTERNAL AUDITORS"
            },
            {
              "VENDOR_NAME": "INSTITUTE OF INTERNAL AUDITORS"
            },
            {
              "VENDOR_NAME": "INSTRUMENTS & EQUIPMENT CO."
            },
            {
              "VENDOR_NAME": "INTEGRA REALTY RESOURCES"
            },
            {
              "VENDOR_NAME": "INTEGRA REALTY RESOURCES-NNJ"
            },
            {
              "VENDOR_NAME": "INTEGRATED LABELING SYSTEMS"
            },
            {
              "VENDOR_NAME": "INTELLAPRICE LLC"
            },
            {
              "VENDOR_NAME": "INTELLIFIBER NETWORKS"
            },
            {
              "VENDOR_NAME": "INTELLISOURCE SOLUTIONS LLC"
            },
            {
              "VENDOR_NAME": "INTELLISYNC CORPORATION"
            },
            {
              "VENDOR_NAME": "INTERACTIVE MEDIA"
            },
            {
              "VENDOR_NAME": "INTERBORO RECOVERY SER.INC."
            },
            {
              "VENDOR_NAME": "INTERBORO SENIOR HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "INTERCALL"
            },
            {
              "VENDOR_NAME": "INTERCHANGE PLAZA"
            },
            {
              "VENDOR_NAME": "INTERCON TRUCK EQUIPMENT"
            },
            {
              "VENDOR_NAME": "INTERCOUNTY NEWSPAPERS"
            },
            {
              "VENDOR_NAME": "INTERFAITH HOSPITALITY NETWORK"
            },
            {
              "VENDOR_NAME": "INTERIOR ARCHITECTURAL"
            },
            {
              "VENDOR_NAME": "INTER'L COUNCIL OF"
            },
            {
              "VENDOR_NAME": "INTERLIFT INC."
            },
            {
              "VENDOR_NAME": "INTERMETRO INDUSTRIES COR"
            },
            {
              "VENDOR_NAME": "INTERNAL REVENUE SERVICE"
            },
            {
              "VENDOR_NAME": "INTERNATIONAL ACCOUNTS PAYABLE"
            },
            {
              "VENDOR_NAME": "INTERNATIONAL ASSOCIATION OF"
            },
            {
              "VENDOR_NAME": "INTERNATIONAL CODE COUNCIL"
            },
            {
              "VENDOR_NAME": "INTERNATIONAL COMPUTER NEGOTIATIONS"
            },
            {
              "VENDOR_NAME": "INTERNATIONAL E-Z UP"
            },
            {
              "VENDOR_NAME": "INTERNATIONAL FACILITY"
            },
            {
              "VENDOR_NAME": "INTERNATIONAL PAPER"
            },
            {
              "VENDOR_NAME": "INTERNATIONAL PAPER COMPANY"
            },
            {
              "VENDOR_NAME": "INTERNATIONAL PAYMENT SERVICES"
            },
            {
              "VENDOR_NAME": "INTERNATIONAL RISK MANAGEMENT"
            },
            {
              "VENDOR_NAME": "INTERNET SECURITY SYSTEMS"
            },
            {
              "VENDOR_NAME": "INTERSTATE BATTERIES DELCO"
            },
            {
              "VENDOR_NAME": "INTERSTATE BRANDS COPR."
            },
            {
              "VENDOR_NAME": "INTERSTATE BRANDS CORPORATION"
            },
            {
              "VENDOR_NAME": "INTERSTATE LOGO'S INC"
            },
            {
              "VENDOR_NAME": "INTERSTATE LOGOS LLC"
            },
            {
              "VENDOR_NAME": "INTERTEK USA"
            },
            {
              "VENDOR_NAME": "INTER-TEL TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "INT'L DAIRY FOODS ASSOC"
            },
            {
              "VENDOR_NAME": "INT'L DAIRY-DELI-BAKERY ASSOC."
            },
            {
              "VENDOR_NAME": "INTRALOX"
            },
            {
              "VENDOR_NAME": "INVESTORS TRUST CO"
            },
            {
              "VENDOR_NAME": "IOMA"
            },
            {
              "VENDOR_NAME": "IOWA ROTOCAST PLASTICS"
            },
            {
              "VENDOR_NAME": "IPC USA"
            },
            {
              "VENDOR_NAME": "IPC USA"
            },
            {
              "VENDOR_NAME": "IRA D & BETTY J SWARTZ"
            },
            {
              "VENDOR_NAME": "IRA S. SWARTZ"
            },
            {
              "VENDOR_NAME": "IRENE SHEARY"
            },
            {
              "VENDOR_NAME": "IRISH AMERICAN BUSINESS CHAMBER"
            },
            {
              "VENDOR_NAME": "IRON AGE CORPORATION"
            },
            {
              "VENDOR_NAME": "IRON HILL APARTMENTS"
            },
            {
              "VENDOR_NAME": "IRRIGATION RESEARCH & DESIGN"
            },
            {
              "VENDOR_NAME": "IRS"
            },
            {
              "VENDOR_NAME": "IRS/ACS"
            },
            {
              "VENDOR_NAME": "IRS-US DEPARTMENT OF TREASURY"
            },
            {
              "VENDOR_NAME": "IRVIN S.NAYLOR"
            },
            {
              "VENDOR_NAME": "IRVIN STERN CO"
            },
            {
              "VENDOR_NAME": "ISABEL BALBOA"
            },
            {
              "VENDOR_NAME": "ISE"
            },
            {
              "VENDOR_NAME": "ISGOOD"
            },
            {
              "VENDOR_NAME": "ISLE OF WIGHT GEN'L DISTRICT COURT"
            },
            {
              "VENDOR_NAME": "ISLE OF WRIGHT GENERAL DIST.COURT"
            },
            {
              "VENDOR_NAME": "ISLE OF WRIGHT GENERAL DIST.CT."
            },
            {
              "VENDOR_NAME": "ISMAEL TORRES"
            },
            {
              "VENDOR_NAME": "ISTA"
            },
            {
              "VENDOR_NAME": "ITRON"
            },
            {
              "VENDOR_NAME": "ITT WATER AND WASTEWATER USA"
            },
            {
              "VENDOR_NAME": "IVAN JACKSON"
            },
            {
              "VENDOR_NAME": "IVCI"
            },
            {
              "VENDOR_NAME": "IVCI"
            },
            {
              "VENDOR_NAME": "IVCi"
            },
            {
              "VENDOR_NAME": "IVCi"
            },
            {
              "VENDOR_NAME": "J & D ENTERPRISES INC"
            },
            {
              "VENDOR_NAME": "J & J SALES & LOGISTICS CO"
            },
            {
              "VENDOR_NAME": "J & J STAFFING RESOURCES"
            },
            {
              "VENDOR_NAME": "J & K TRASH REMOVAL"
            },
            {
              "VENDOR_NAME": "J & W ASSOCIATES"
            },
            {
              "VENDOR_NAME": "J C OTTON REAL ESTATE INC"
            },
            {
              "VENDOR_NAME": "J D B ENTERPRISES"
            },
            {
              "VENDOR_NAME": "J E B CONSTRUCTION"
            },
            {
              "VENDOR_NAME": "J F FICK INC"
            },
            {
              "VENDOR_NAME": "J J KELLER & ASSOC. INC."
            },
            {
              "VENDOR_NAME": "J J KELLER & ASSOCIATES"
            },
            {
              "VENDOR_NAME": "J M CAPPELLO ASSOCIATES"
            },
            {
              "VENDOR_NAME": "J M K INVESTMENTS LTD"
            },
            {
              "VENDOR_NAME": "J P MOHAN-TAX COLLECTOR"
            },
            {
              "VENDOR_NAME": "J W & E A MESSICK"
            },
            {
              "VENDOR_NAME": "J&J SNACK FOODS"
            },
            {
              "VENDOR_NAME": "J&T TSAI"
            },
            {
              "VENDOR_NAME": "J. CATALANO GENERAL CONTRACTING"
            },
            {
              "VENDOR_NAME": "J. J. CLARK"
            },
            {
              "VENDOR_NAME": "J. M. GASKE"
            },
            {
              "VENDOR_NAME": "J.A. NEARY EXCAVATING CORP."
            },
            {
              "VENDOR_NAME": "J.C.EHRLICH CO. INC."
            },
            {
              "VENDOR_NAME": "J.CLARK GREW"
            },
            {
              "VENDOR_NAME": "J.FRANKLIN STYER"
            },
            {
              "VENDOR_NAME": "J.G.COOK'S RIVERVIEW INN"
            },
            {
              "VENDOR_NAME": "J.J. CASSONE"
            },
            {
              "VENDOR_NAME": "J.KENT WILLING"
            },
            {
              "VENDOR_NAME": "J.KENT WILLING"
            },
            {
              "VENDOR_NAME": "J.M. SMUCKER COMPANY"
            },
            {
              "VENDOR_NAME": "J.M.BASILE & ASSOC."
            },
            {
              "VENDOR_NAME": "J.MARGULIS"
            },
            {
              "VENDOR_NAME": "J.P.HARRIS ASSOCIATES-EIT"
            },
            {
              "VENDOR_NAME": "J.P.JENSEN MANAGEMENT SERVICE"
            },
            {
              "VENDOR_NAME": "J.P.MASCARO & SONS"
            },
            {
              "VENDOR_NAME": "J.P.SEWOROOTER"
            },
            {
              "VENDOR_NAME": "J.P.SMITH CONTRACTORS"
            },
            {
              "VENDOR_NAME": "J.R. REALTY ASSOCIATES"
            },
            {
              "VENDOR_NAME": "J.S.WORRELL ENVIROMENTAL"
            },
            {
              "VENDOR_NAME": "J.SPOTSWOOD"
            },
            {
              "VENDOR_NAME": "J.SPOTSWOOD"
            },
            {
              "VENDOR_NAME": "J.TIMOTHY KERNAN"
            },
            {
              "VENDOR_NAME": "J/C TRUCKING"
            },
            {
              "VENDOR_NAME": "j2 GLOBAL COMMUNICATIONS"
            },
            {
              "VENDOR_NAME": "J2K PRINCE WILLIAM COUNTY"
            },
            {
              "VENDOR_NAME": "JACEK WALCZEWSKI"
            },
            {
              "VENDOR_NAME": "JACK & JILL ICE CREAM CO"
            },
            {
              "VENDOR_NAME": "JACK & JILL ICE CREAM CO."
            },
            {
              "VENDOR_NAME": "JACK KRAMER'S CATERING"
            },
            {
              "VENDOR_NAME": "JACK LINKS BEEF JERKY"
            },
            {
              "VENDOR_NAME": "JACK MANCINO"
            },
            {
              "VENDOR_NAME": "JACKIE LEWIS"
            },
            {
              "VENDOR_NAME": "JACKSON BUREAU OF FIRE PREVENTION"
            },
            {
              "VENDOR_NAME": "JACKSON BUREAU OF FIRE SAFETY"
            },
            {
              "VENDOR_NAME": "JACKSON POP WARNER"
            },
            {
              "VENDOR_NAME": "JACKSON PROPERTY INVESTMENT LLC"
            },
            {
              "VENDOR_NAME": "JACKSON SOCCER CLUB"
            },
            {
              "VENDOR_NAME": "JACKSON SQUARE II"
            },
            {
              "VENDOR_NAME": "JACKSON TOWNSHIP"
            },
            {
              "VENDOR_NAME": "JACKSON TOWNSHIP BOARD OF EDUCATION"
            },
            {
              "VENDOR_NAME": "JACKSON TWP POLICE DEPARTMENT"
            },
            {
              "VENDOR_NAME": "JACKSON TWP-TAX COLLECTOR"
            },
            {
              "VENDOR_NAME": "JACKSON-LIBERTY FOOTBALL"
            },
            {
              "VENDOR_NAME": "JACOB FLEMING"
            },
            {
              "VENDOR_NAME": "JACOBS WYPER ARCHITECHS"
            },
            {
              "VENDOR_NAME": "JACOBS WYPER ARCHITECTS"
            },
            {
              "VENDOR_NAME": "JACQUELINE L.MEDLEY"
            },
            {
              "VENDOR_NAME": "JACQUELINE LINDNER"
            },
            {
              "VENDOR_NAME": "JADCO ENTERPRISES"
            },
            {
              "VENDOR_NAME": "JAEGER INCORPORATED"
            },
            {
              "VENDOR_NAME": "JAMES A. NOLEN"
            },
            {
              "VENDOR_NAME": "JAMES A.NOLAN"
            },
            {
              "VENDOR_NAME": "JAMES BARTIE COURT OFFICER"
            },
            {
              "VENDOR_NAME": "JAMES BARTIE CT. OFFICER"
            },
            {
              "VENDOR_NAME": "JAMES BRETTELL"
            },
            {
              "VENDOR_NAME": "JAMES CRAWFORD"
            },
            {
              "VENDOR_NAME": "JAMES DOORCHECK"
            },
            {
              "VENDOR_NAME": "JAMES E SOUDER JR"
            },
            {
              "VENDOR_NAME": "JAMES E.BOWER"
            },
            {
              "VENDOR_NAME": "JAMES F.LEWIS"
            },
            {
              "VENDOR_NAME": "JAMES GARDNER"
            },
            {
              "VENDOR_NAME": "JAMES H BARTIE COURT OFFICER"
            },
            {
              "VENDOR_NAME": "JAMES H.GILBERT"
            },
            {
              "VENDOR_NAME": "JAMES HAHN"
            },
            {
              "VENDOR_NAME": "JAMES HAND"
            },
            {
              "VENDOR_NAME": "JAMES J. VACCA"
            },
            {
              "VENDOR_NAME": "JAMES J.REILLY"
            },
            {
              "VENDOR_NAME": "JAMES K BLUEBELLO"
            },
            {
              "VENDOR_NAME": "JAMES K BLUEBELLO &"
            },
            {
              "VENDOR_NAME": "JAMES KELLY TRUSTEE SUPERIOR COURT"
            },
            {
              "VENDOR_NAME": "JAMES KINKADE - SENTINEL"
            },
            {
              "VENDOR_NAME": "JAMES KOWAL"
            },
            {
              "VENDOR_NAME": "JAMES L. WATERS"
            },
            {
              "VENDOR_NAME": "JAMES L. WILLIAMS"
            },
            {
              "VENDOR_NAME": "JAMES L.WATERS"
            },
            {
              "VENDOR_NAME": "JAMES LEDGER"
            },
            {
              "VENDOR_NAME": "JAMES LEE"
            },
            {
              "VENDOR_NAME": "JAMES M BARTIE COURT OFFICER"
            },
            {
              "VENDOR_NAME": "JAMES MARTIN COMPANY"
            },
            {
              "VENDOR_NAME": "JAMES MASTERS"
            },
            {
              "VENDOR_NAME": "JAMES MAYER ESQ"
            },
            {
              "VENDOR_NAME": "JAMES PALMIERI"
            },
            {
              "VENDOR_NAME": "JAMES QUAILE"
            },
            {
              "VENDOR_NAME": "JAMES R GOBLE"
            },
            {
              "VENDOR_NAME": "JAMES R. DAU"
            },
            {
              "VENDOR_NAME": "JAMES REDROW"
            },
            {
              "VENDOR_NAME": "JAMES SANDS"
            },
            {
              "VENDOR_NAME": "JAMES SANDS"
            },
            {
              "VENDOR_NAME": "JAMES SHIFLETT"
            },
            {
              "VENDOR_NAME": "JAMES SHORTALL"
            },
            {
              "VENDOR_NAME": "JAMES STADTMUELLER"
            },
            {
              "VENDOR_NAME": "JAMES STEWART PAINTING CO."
            },
            {
              "VENDOR_NAME": "JAMES T PLOUSIS SHERIFF"
            },
            {
              "VENDOR_NAME": "JAMES TASHJIAN"
            },
            {
              "VENDOR_NAME": "JAMES W. CAVANAUGH"
            },
            {
              "VENDOR_NAME": "JAMES W. CRAWFORD"
            },
            {
              "VENDOR_NAME": "JAMES W. GESLAK"
            },
            {
              "VENDOR_NAME": "JAMES W. HENDRICKS"
            },
            {
              "VENDOR_NAME": "JAMES W.CRAWFORD"
            },
            {
              "VENDOR_NAME": "JAMES WALLACE DDS"
            },
            {
              "VENDOR_NAME": "JAMES WILLIAMS"
            },
            {
              "VENDOR_NAME": "JAMES WILLIAMS"
            },
            {
              "VENDOR_NAME": "JAMIE GARDNER"
            },
            {
              "VENDOR_NAME": "JAMIE KELLY COURT OFFICER"
            },
            {
              "VENDOR_NAME": "JAMIE KELLY TRUSTEE SUPERIOR CT."
            },
            {
              "VENDOR_NAME": "JAMIE SPROUSE"
            },
            {
              "VENDOR_NAME": "JANE COLEMAN"
            },
            {
              "VENDOR_NAME": "JANE DELUCA"
            },
            {
              "VENDOR_NAME": "JANE GRIFFITH"
            },
            {
              "VENDOR_NAME": "JANE RAPPLEYEA"
            },
            {
              "VENDOR_NAME": "JANET CENTANINO"
            },
            {
              "VENDOR_NAME": "JANET MCLAUGHLIN"
            },
            {
              "VENDOR_NAME": "JANICE CAIN"
            },
            {
              "VENDOR_NAME": "JARED AND JOSHUA WERTZ"
            },
            {
              "VENDOR_NAME": "JARED CULOTTA"
            },
            {
              "VENDOR_NAME": "JARED DETWILER"
            },
            {
              "VENDOR_NAME": "JARRELL"
            },
            {
              "VENDOR_NAME": "JASMIN PICKENS"
            },
            {
              "VENDOR_NAME": "JASON BURNS"
            },
            {
              "VENDOR_NAME": "JASON FLEMING"
            },
            {
              "VENDOR_NAME": "JASON MANOS"
            },
            {
              "VENDOR_NAME": "JASON RIENZO COURT OFFICER"
            },
            {
              "VENDOR_NAME": "JASON RIENZO CT. OFFICER"
            },
            {
              "VENDOR_NAME": "JASON STALNECKER"
            },
            {
              "VENDOR_NAME": "JAVITCH"
            },
            {
              "VENDOR_NAME": "JAVO BEVERAGE COMPANY"
            },
            {
              "VENDOR_NAME": "JAWS YOUTH FUND"
            },
            {
              "VENDOR_NAME": "JAY C. WISSLER"
            },
            {
              "VENDOR_NAME": "JAY DAVIS"
            },
            {
              "VENDOR_NAME": "JAY FERRELL"
            },
            {
              "VENDOR_NAME": "JAY GRESS INC"
            },
            {
              "VENDOR_NAME": "JAY PAUL PHOTOGRAPHY"
            },
            {
              "VENDOR_NAME": "JB BAKERY"
            },
            {
              "VENDOR_NAME": "JB SUPPLY INC."
            },
            {
              "VENDOR_NAME": "JC CONSTRUCTION EQUIP."
            },
            {
              "VENDOR_NAME": "JCC OF WESTERN MONMOUTH"
            },
            {
              "VENDOR_NAME": "JCV INC"
            },
            {
              "VENDOR_NAME": "JDA SOFTWARE GROUP"
            },
            {
              "VENDOR_NAME": "JDA SOFTWARE"
            },
            {
              "VENDOR_NAME": "JDN PROPERTIES at LEBANON"
            },
            {
              "VENDOR_NAME": "JDRF"
            },
            {
              "VENDOR_NAME": "JDRF-INTERNATIONAL"
            },
            {
              "VENDOR_NAME": "JEAN HUGHES"
            },
            {
              "VENDOR_NAME": "JEAN PAINTER"
            },
            {
              "VENDOR_NAME": "JEAN SMITH TAX COLLECTOR"
            },
            {
              "VENDOR_NAME": "JEANNE EICHINGER"
            },
            {
              "VENDOR_NAME": "JEANNETTE CHITTICK"
            },
            {
              "VENDOR_NAME": "JEFF ALEXANDER"
            },
            {
              "VENDOR_NAME": "JEFF BOMMELYN"
            },
            {
              "VENDOR_NAME": "JEFF GREEN"
            },
            {
              "VENDOR_NAME": "JEFF KITNER"
            },
            {
              "VENDOR_NAME": "JEFF R.JONES-LOCKSMITH"
            },
            {
              "VENDOR_NAME": "JEFFREY A.SCHLESINGER"
            },
            {
              "VENDOR_NAME": "JEFFREY BOYER LLC"
            },
            {
              "VENDOR_NAME": "JEFFREY BRITT"
            },
            {
              "VENDOR_NAME": "JEFFREY HART"
            },
            {
              "VENDOR_NAME": "JEFFREY HORAN"
            },
            {
              "VENDOR_NAME": "JEFFREY HULAK"
            },
            {
              "VENDOR_NAME": "JEFFREY LAMBERT"
            },
            {
              "VENDOR_NAME": "JEFFREY MCCANTS"
            },
            {
              "VENDOR_NAME": "JEFFREY OFFREDO"
            },
            {
              "VENDOR_NAME": "JEFFREY OLESON"
            },
            {
              "VENDOR_NAME": "JEFFREY PIERSON"
            },
            {
              "VENDOR_NAME": "JEFFREY R. JONES"
            },
            {
              "VENDOR_NAME": "JEFFREY SEYFERT"
            },
            {
              "VENDOR_NAME": "JEFFREY SIMPSON"
            },
            {
              "VENDOR_NAME": "JEFFREY V.BAGLEY"
            },
            {
              "VENDOR_NAME": "JEFFREY Y.FLYNN"
            },
            {
              "VENDOR_NAME": "JELLY BELLY CANDY COMPANY"
            },
            {
              "VENDOR_NAME": "JENKINTOWN FOOTBALL BOOSTERS"
            },
            {
              "VENDOR_NAME": "JENNIFER FAULKNER"
            },
            {
              "VENDOR_NAME": "JENNIFER MEYERS"
            },
            {
              "VENDOR_NAME": "JENNIFER SCHANCK"
            },
            {
              "VENDOR_NAME": "JENNIFER STRANIX"
            },
            {
              "VENDOR_NAME": "JEREMY C. LOPER"
            },
            {
              "VENDOR_NAME": "JEROME E.PEULER"
            },
            {
              "VENDOR_NAME": "JERREHIAN PARTNERSHIP"
            },
            {
              "VENDOR_NAME": "JERRY JACKSON"
            },
            {
              "VENDOR_NAME": "JERRY SHATKUS"
            },
            {
              "VENDOR_NAME": "JERSEY CAPE REALTY"
            },
            {
              "VENDOR_NAME": "JERSEY CENTRAL POWER & LIGHT"
            },
            {
              "VENDOR_NAME": "JERSEY CENTRAL POWER & LIGHT OF BAY"
            },
            {
              "VENDOR_NAME": "JERSEY CENTRAL POWER AND LIGHT"
            },
            {
              "VENDOR_NAME": "JERSEY KNIGHTS SOCCER CLUB"
            },
            {
              "VENDOR_NAME": "JERSEY SHORE UNIVERSITY"
            },
            {
              "VENDOR_NAME": "JESS MILLER MACHINE SHOP"
            },
            {
              "VENDOR_NAME": "JESSE FRANK WILLIAMS III"
            },
            {
              "VENDOR_NAME": "JESSE PITTMAN"
            },
            {
              "VENDOR_NAME": "JESSICA A.MONZO"
            },
            {
              "VENDOR_NAME": "JESSICA BYERS 386225"
            },
            {
              "VENDOR_NAME": "JESSICA PAIGE WILLING"
            },
            {
              "VENDOR_NAME": "JET DIRECT AVIATION"
            },
            {
              "VENDOR_NAME": "JET PLASTICA"
            },
            {
              "VENDOR_NAME": "JFCS"
            },
            {
              "VENDOR_NAME": "JFG FINANCIAL LLC"
            },
            {
              "VENDOR_NAME": "JGK"
            },
            {
              "VENDOR_NAME": "JILCO EQUIPMENT LEASING"
            },
            {
              "VENDOR_NAME": "JILL HAGEN"
            },
            {
              "VENDOR_NAME": "JILL L HICKS"
            },
            {
              "VENDOR_NAME": "JILLIAN'S ARUNDEL MILLS"
            },
            {
              "VENDOR_NAME": "JIM BLUEBELLO"
            },
            {
              "VENDOR_NAME": "JIM BOYCE"
            },
            {
              "VENDOR_NAME": "JIM CLIFT DESIGN"
            },
            {
              "VENDOR_NAME": "JIM DiMATTEO"
            },
            {
              "VENDOR_NAME": "JIM FLEMING & ASSOCIATES"
            },
            {
              "VENDOR_NAME": "JIM MARGERISON LANDSCAPING"
            },
            {
              "VENDOR_NAME": "JIM PERLOFF"
            },
            {
              "VENDOR_NAME": "JIM WALZ"
            },
            {
              "VENDOR_NAME": "JIM'S AUTO BODY AND REPAIRS"
            },
            {
              "VENDOR_NAME": "JINGLES LLC"
            },
            {
              "VENDOR_NAME": "JIN-SHENG LU"
            },
            {
              "VENDOR_NAME": "JL SEPTIC OF SJ"
            },
            {
              "VENDOR_NAME": "JOAN A WARRELL"
            },
            {
              "VENDOR_NAME": "JOAN ARCHER"
            },
            {
              "VENDOR_NAME": "JOAN CARTER"
            },
            {
              "VENDOR_NAME": "JOAN MCCLEARY"
            },
            {
              "VENDOR_NAME": "JOANN TUMELAVICH"
            },
            {
              "VENDOR_NAME": "JOANNE LOSAK BENNETT"
            },
            {
              "VENDOR_NAME": "JOANNE M.DiJOSEPH"
            },
            {
              "VENDOR_NAME": "JOB FAIR COMPANY"
            },
            {
              "VENDOR_NAME": "JOBEXPO.COM"
            },
            {
              "VENDOR_NAME": "JOCAR ENTERPRISES"
            },
            {
              "VENDOR_NAME": "JODA PROPERTIES LLC"
            },
            {
              "VENDOR_NAME": "JODIE SIKORSKI"
            },
            {
              "VENDOR_NAME": "JOE AND DOROTHY MARUCCI"
            },
            {
              "VENDOR_NAME": "JOE BENDAS"
            },
            {
              "VENDOR_NAME": "JOE GAGLIARDI"
            },
            {
              "VENDOR_NAME": "JOE GALLAGHER"
            },
            {
              "VENDOR_NAME": "JOE HANSEN"
            },
            {
              "VENDOR_NAME": "JOE KIRWAN COMPANY"
            },
            {
              "VENDOR_NAME": "JOE O'DONOGHUE"
            },
            {
              "VENDOR_NAME": "JOE ROBBINS"
            },
            {
              "VENDOR_NAME": "JOE SCHICKLER"
            },
            {
              "VENDOR_NAME": "JOE SCHWENGER"
            },
            {
              "VENDOR_NAME": "JOE VARI"
            },
            {
              "VENDOR_NAME": "JOEL IAN BELL"
            },
            {
              "VENDOR_NAME": "JOE'S AUTO REPAIR & TOWING"
            },
            {
              "VENDOR_NAME": "JOGUE INC"
            },
            {
              "VENDOR_NAME": "JOHANNA FOODS"
            },
            {
              "VENDOR_NAME": "JOHANNA FOODS"
            },
            {
              "VENDOR_NAME": "JOHN & JOSEPH VASKO"
            },
            {
              "VENDOR_NAME": "JOHN & KATHLEEN WILKS"
            },
            {
              "VENDOR_NAME": "JOHN A SKOCIK"
            },
            {
              "VENDOR_NAME": "JOHN ATKINSON"
            },
            {
              "VENDOR_NAME": "JOHN B. SANFILIPPO & SON"
            },
            {
              "VENDOR_NAME": "JOHN B. SANFILIPPO & SONS"
            },
            {
              "VENDOR_NAME": "JOHN BALDINO"
            },
            {
              "VENDOR_NAME": "JOHN BARE"
            },
            {
              "VENDOR_NAME": "JOHN BATTISTI"
            },
            {
              "VENDOR_NAME": "JOHN BELT"
            },
            {
              "VENDOR_NAME": "JOHN BLOSENSKI & SONS INC"
            },
            {
              "VENDOR_NAME": "JOHN BONHAGE"
            },
            {
              "VENDOR_NAME": "JOHN BOUCOUVALAS"
            },
            {
              "VENDOR_NAME": "JOHN BRUNKOW"
            },
            {
              "VENDOR_NAME": "JOHN C. WEISBECKER"
            },
            {
              "VENDOR_NAME": "JOHN CALLINAN SHERIFF"
            },
            {
              "VENDOR_NAME": "JOHN CALLINAN"
            },
            {
              "VENDOR_NAME": "JOHN CASSIDY"
            },
            {
              "VENDOR_NAME": "JOHN CHAMBERS WOBENSMITH"
            },
            {
              "VENDOR_NAME": "JOHN CRAIG"
            },
            {
              "VENDOR_NAME": "JOHN CUNNINGHAM"
            },
            {
              "VENDOR_NAME": "JOHN CURRY"
            },
            {
              "VENDOR_NAME": "JOHN DELCOCO"
            },
            {
              "VENDOR_NAME": "JOHN DELVECCHIO"
            },
            {
              "VENDOR_NAME": "JOHN DIJULIUS CONSULTING"
            },
            {
              "VENDOR_NAME": "JOHN DIMEGLIO"
            },
            {
              "VENDOR_NAME": "JOHN DISAVERIO"
            },
            {
              "VENDOR_NAME": "JOHN DUDICK"
            },
            {
              "VENDOR_NAME": "JOHN E. LINDNER"
            },
            {
              "VENDOR_NAME": "JOHN E. MILLER"
            },
            {
              "VENDOR_NAME": "JOHN E. SIPPEL"
            },
            {
              "VENDOR_NAME": "JOHN E. SIRINE AND ASSOCIATES"
            },
            {
              "VENDOR_NAME": "JOHN ENGSTROM"
            },
            {
              "VENDOR_NAME": "JOHN F BROWN 187"
            },
            {
              "VENDOR_NAME": "JOHN F CAMPBELL"
            },
            {
              "VENDOR_NAME": "JOHN F.NOLEN"
            },
            {
              "VENDOR_NAME": "JOHN FORMAN"
            },
            {
              "VENDOR_NAME": "JOHN FRANKLIN COURT OFFICER"
            },
            {
              "VENDOR_NAME": "JOHN G. DONNELLY"
            },
            {
              "VENDOR_NAME": "JOHN GIBSON"
            },
            {
              "VENDOR_NAME": "JOHN H FRANKLIN COURT OFFICER"
            },
            {
              "VENDOR_NAME": "JOHN H WILLIAMS #8578"
            },
            {
              "VENDOR_NAME": "JOHN H. FRANKLIN COURT OFFICER"
            },
            {
              "VENDOR_NAME": "JOHN H. WILLIAMS #8578"
            },
            {
              "VENDOR_NAME": "JOHN H. WORDEN"
            },
            {
              "VENDOR_NAME": "JOHN HOPKINS UNIVERSITY"
            },
            {
              "VENDOR_NAME": "JOHN HOULHAN"
            },
            {
              "VENDOR_NAME": "JOHN HOULIHAN # 0692"
            },
            {
              "VENDOR_NAME": "JOHN HOULIHAN # 7031"
            },
            {
              "VENDOR_NAME": "JOHN HOULIHAN #0692"
            },
            {
              "VENDOR_NAME": "JOHN HOULIHAN ESQ"
            },
            {
              "VENDOR_NAME": "JOHN HOULIHAN ESQ#0692"
            },
            {
              "VENDOR_NAME": "JOHN HOULIHAN"
            },
            {
              "VENDOR_NAME": "JOHN INGERSOLL"
            },
            {
              "VENDOR_NAME": "JOHN J FINLEY III"
            },
            {
              "VENDOR_NAME": "JOHN J WALLS AND SONS INC."
            },
            {
              "VENDOR_NAME": "JOHN J. CUNNINGHAM"
            },
            {
              "VENDOR_NAME": "JOHN J.CUNNINGHAM"
            },
            {
              "VENDOR_NAME": "JOHN J.FINLEY"
            },
            {
              "VENDOR_NAME": "JOHN J.STANZIONE"
            },
            {
              "VENDOR_NAME": "JOHN J.STANZIONE"
            },
            {
              "VENDOR_NAME": "JOHN KEEGAN"
            },
            {
              "VENDOR_NAME": "JOHN L BRIGGS & CO."
            },
            {
              "VENDOR_NAME": "JOHN LUND KELLER #0775"
            },
            {
              "VENDOR_NAME": "JOHN M. POPLAWSKI"
            },
            {
              "VENDOR_NAME": "JOHN M.POPLAWSKI"
            },
            {
              "VENDOR_NAME": "JOHN MAIORANO"
            },
            {
              "VENDOR_NAME": "JOHN MCALLISTER"
            },
            {
              "VENDOR_NAME": "JOHN McCARTHY"
            },
            {
              "VENDOR_NAME": "JOHN MCKERNAN COURT OFFICER"
            },
            {
              "VENDOR_NAME": "JOHN MCKERNAN"
            },
            {
              "VENDOR_NAME": "JOHN MEUWISSEN CONSTRUCTION"
            },
            {
              "VENDOR_NAME": "JOHN MEUWISSEN CONSTRUCTN"
            },
            {
              "VENDOR_NAME": "JOHN MEYER"
            },
            {
              "VENDOR_NAME": "JOHN MIDDLETON"
            },
            {
              "VENDOR_NAME": "JOHN MILLARD"
            },
            {
              "VENDOR_NAME": "JOHN MILLER"
            },
            {
              "VENDOR_NAME": "JOHN MONTMORE"
            },
            {
              "VENDOR_NAME": "JOHN MOREY"
            },
            {
              "VENDOR_NAME": "JOHN P HOULIHAN ESQ."
            },
            {
              "VENDOR_NAME": "JOHN P. HOULIHAN # 0692"
            },
            {
              "VENDOR_NAME": "JOHN P. HOULIHAN"
            },
            {
              "VENDOR_NAME": "JOHN P. MCALLISTER"
            },
            {
              "VENDOR_NAME": "JOHN P.HOULIHAN #0692"
            },
            {
              "VENDOR_NAME": "JOHN PAUL KEOHAN"
            },
            {
              "VENDOR_NAME": "JOHN POPATTO"
            },
            {
              "VENDOR_NAME": "JOHN POPLAWSKI"
            },
            {
              "VENDOR_NAME": "JOHN PRIEST"
            },
            {
              "VENDOR_NAME": "JOHN R. HAINES"
            },
            {
              "VENDOR_NAME": "JOHN RACIOPPI"
            },
            {
              "VENDOR_NAME": "JOHN RANDOLPH HOSPITAL"
            },
            {
              "VENDOR_NAME": "JOHN RICCIUTTI"
            },
            {
              "VENDOR_NAME": "JOHN RILEY TOWNSEND"
            },
            {
              "VENDOR_NAME": "JOHN S & ELAINE MCMICHAEL"
            },
            {
              "VENDOR_NAME": "JOHN S MAIORANO &"
            },
            {
              "VENDOR_NAME": "JOHN S MAIORANO & BERNADETTE MAIORA"
            },
            {
              "VENDOR_NAME": "JOHN S MCMICHAEL"
            },
            {
              "VENDOR_NAME": "JOHN SCHOUTEN"
            },
            {
              "VENDOR_NAME": "JOHN SERENELLI"
            },
            {
              "VENDOR_NAME": "JOHN SERRUTO"
            },
            {
              "VENDOR_NAME": "JOHN SKOCIK"
            },
            {
              "VENDOR_NAME": "JOHN SPENCER DEMPSEY"
            },
            {
              "VENDOR_NAME": "JOHN SWAB"
            },
            {
              "VENDOR_NAME": "JOHN T ATKINSON TREASURER"
            },
            {
              "VENDOR_NAME": "JOHN T. & ELIZABETH BARULIC"
            },
            {
              "VENDOR_NAME": "JOHN T. ATKINSON TREASURER"
            },
            {
              "VENDOR_NAME": "JOHN T. KALITA"
            },
            {
              "VENDOR_NAME": "JOHN T.& ELIZABETH BARULIC"
            },
            {
              "VENDOR_NAME": "JOHN TAYLOR"
            },
            {
              "VENDOR_NAME": "JOHN TUCKERMAN"
            },
            {
              "VENDOR_NAME": "JOHN WALLING COURT OFFICER"
            },
            {
              "VENDOR_NAME": "JOHN WESTERFER"
            },
            {
              "VENDOR_NAME": "JOHN WILEY & SONS INC"
            },
            {
              "VENDOR_NAME": "JOHN WILLIAMS 8578"
            },
            {
              "VENDOR_NAME": "JOHN ZUCZEK"
            },
            {
              "VENDOR_NAME": "JOHNS HOPKINS CHILDREN'S CHARITIES"
            },
            {
              "VENDOR_NAME": "JOHN'S NEWS SERVICE"
            },
            {
              "VENDOR_NAME": "JOHNSON"
            },
            {
              "VENDOR_NAME": "JOHNSON'S HOME IMPROVEMENTS"
            },
            {
              "VENDOR_NAME": "JOHNSTON SQUARE APTS"
            },
            {
              "VENDOR_NAME": "JOINT PURCHASING CORP"
            },
            {
              "VENDOR_NAME": "JOMY PRODUCTS"
            },
            {
              "VENDOR_NAME": "JON AXELSSON"
            },
            {
              "VENDOR_NAME": "JON FELDMAN"
            },
            {
              "VENDOR_NAME": "JON LACAL"
            },
            {
              "VENDOR_NAME": "JONATHAN ABBATE"
            },
            {
              "VENDOR_NAME": "JONATHAN GIBBS"
            },
            {
              "VENDOR_NAME": "JONATHAN PHELPS"
            },
            {
              "VENDOR_NAME": "JONES & FRANK CORP"
            },
            {
              "VENDOR_NAME": "JONES OF ANNAPOLIS"
            },
            {
              "VENDOR_NAME": "JONES"
            },
            {
              "VENDOR_NAME": "JONES"
            },
            {
              "VENDOR_NAME": "JOPPA EXPLORER POST 6600"
            },
            {
              "VENDOR_NAME": "JOPPA MAGNOLIA FIRE COMPANY"
            },
            {
              "VENDOR_NAME": "JORDAN CONSULTING"
            },
            {
              "VENDOR_NAME": "JORDYN ENTERPRISES"
            },
            {
              "VENDOR_NAME": "JORMANDY LLC"
            },
            {
              "VENDOR_NAME": "JORMANDY"
            },
            {
              "VENDOR_NAME": "JOSAM COMPANY"
            },
            {
              "VENDOR_NAME": "JOSEPH & CAROL GRACE"
            },
            {
              "VENDOR_NAME": "JOSEPH A & CAROL GRACE"
            },
            {
              "VENDOR_NAME": "JOSEPH ASHE"
            },
            {
              "VENDOR_NAME": "JOSEPH B TOWNSEND III"
            },
            {
              "VENDOR_NAME": "JOSEPH B TOWNSEND JR"
            },
            {
              "VENDOR_NAME": "JOSEPH BARBATO ASSOCIATES LLC"
            },
            {
              "VENDOR_NAME": "JOSEPH BURNS"
            },
            {
              "VENDOR_NAME": "JOSEPH C LOSAK"
            },
            {
              "VENDOR_NAME": "JOSEPH C. LOSAK"
            },
            {
              "VENDOR_NAME": "JOSEPH CASULLI"
            },
            {
              "VENDOR_NAME": "JOSEPH DiDONATO"
            },
            {
              "VENDOR_NAME": "JOSEPH DUEGAW"
            },
            {
              "VENDOR_NAME": "JOSEPH E JACOBY"
            },
            {
              "VENDOR_NAME": "JOSEPH EDWARD MCCAIN III"
            },
            {
              "VENDOR_NAME": "JOSEPH EPPOLITO"
            },
            {
              "VENDOR_NAME": "JOSEPH ESPOSITO CONSTABLE"
            },
            {
              "VENDOR_NAME": "JOSEPH F.CONLIN"
            },
            {
              "VENDOR_NAME": "JOSEPH FILINUK"
            },
            {
              "VENDOR_NAME": "JOSEPH FLASINSKI"
            },
            {
              "VENDOR_NAME": "JOSEPH J URBAN"
            },
            {
              "VENDOR_NAME": "JOSEPH L WILLIAMS"
            },
            {
              "VENDOR_NAME": "JOSEPH LAVELLE"
            },
            {
              "VENDOR_NAME": "JOSEPH MARCIANO"
            },
            {
              "VENDOR_NAME": "JOSEPH MORRISON GREW TRUST"
            },
            {
              "VENDOR_NAME": "JOSEPH MORRISON GREW TRUST UAD"
            },
            {
              "VENDOR_NAME": "JOSEPH MULLAN"
            },
            {
              "VENDOR_NAME": "JOSEPH O'DONGHUE COURT OFFICER"
            },
            {
              "VENDOR_NAME": "JOSEPH O'DONOGHUE"
            },
            {
              "VENDOR_NAME": "JOSEPH ODONOGHUE COURT OFFICER"
            },
            {
              "VENDOR_NAME": "JOSEPH O'DONOGHUE COURT OFFICER"
            },
            {
              "VENDOR_NAME": "JOSEPH O'DONOGHUE CT. OFFICER"
            },
            {
              "VENDOR_NAME": "JOSEPH O'DONOGHUE SUPERIOR CT"
            },
            {
              "VENDOR_NAME": "JOSEPH P CHRIST"
            },
            {
              "VENDOR_NAME": "JOSEPH P. HAYES THEATRE"
            },
            {
              "VENDOR_NAME": "JOSEPH P. KELLY"
            },
            {
              "VENDOR_NAME": "JOSEPH R.ARSENAULT"
            },
            {
              "VENDOR_NAME": "JOSEPH SCHAFFER"
            },
            {
              "VENDOR_NAME": "JOSEPH STEMMLE"
            },
            {
              "VENDOR_NAME": "JOSEPH T. CONAHAN"
            },
            {
              "VENDOR_NAME": "JOSEPH V.PALMONARI"
            },
            {
              "VENDOR_NAME": "JOSEPH W MCGINNIS SR INC"
            },
            {
              "VENDOR_NAME": "JOSEPH W. & SELENA L.DENNELER"
            },
            {
              "VENDOR_NAME": "JOSEPH W. TIEDEMAN"
            },
            {
              "VENDOR_NAME": "JOSEPH W.& SELENA L.DENNELER"
            },
            {
              "VENDOR_NAME": "JOSEPHINE GRIFFIN"
            },
            {
              "VENDOR_NAME": "JOSH EMBREE"
            },
            {
              "VENDOR_NAME": "JOSH PLOENER"
            },
            {
              "VENDOR_NAME": "JOSHUA D. PLOENER"
            },
            {
              "VENDOR_NAME": "JOSHUA D.PLOENER"
            },
            {
              "VENDOR_NAME": "JOSHUA M. WORTH"
            },
            {
              "VENDOR_NAME": "JOURNAL HERALD"
            },
            {
              "VENDOR_NAME": "JOY CAWLEY"
            },
            {
              "VENDOR_NAME": "JOYCE LAUGHNER"
            },
            {
              "VENDOR_NAME": "JOYCE REALTY CORP."
            },
            {
              "VENDOR_NAME": "JP ASSOCIATES"
            },
            {
              "VENDOR_NAME": "JP HARRIS ASSOC."
            },
            {
              "VENDOR_NAME": "JP JENSON MANAGEMENT"
            },
            {
              "VENDOR_NAME": "JP MORGAN CHASE BANK"
            },
            {
              "VENDOR_NAME": "JPW ASSOCIATES INC."
            },
            {
              "VENDOR_NAME": "JR PAINTING & SNOWPLOWING"
            },
            {
              "VENDOR_NAME": "JRP MARKETING RESEARCH SERVICES"
            },
            {
              "VENDOR_NAME": "JRT SERVICES"
            },
            {
              "VENDOR_NAME": "JT JACKSON COMPANY"
            },
            {
              "VENDOR_NAME": "JUAN ACEVEDO"
            },
            {
              "VENDOR_NAME": "JUAN D. VARGAS"
            },
            {
              "VENDOR_NAME": "JUDGE TECHNICAL STAFFING"
            },
            {
              "VENDOR_NAME": "JUDITH A MYERS"
            },
            {
              "VENDOR_NAME": "JUDY B. MICHELSON"
            },
            {
              "VENDOR_NAME": "JUDY MYERS - OUTER MARKET"
            },
            {
              "VENDOR_NAME": "JUICE HACCP WORKSHOP"
            },
            {
              "VENDOR_NAME": "JUICE TYME"
            },
            {
              "VENDOR_NAME": "JULIANNE CHILDS"
            },
            {
              "VENDOR_NAME": "JULIE BLAMPHIN"
            },
            {
              "VENDOR_NAME": "JULIE HINMAN"
            },
            {
              "VENDOR_NAME": "JULIUS L COLINA"
            },
            {
              "VENDOR_NAME": "JUNE O'NEILL"
            },
            {
              "VENDOR_NAME": "JUNE O'NEILL"
            },
            {
              "VENDOR_NAME": "JUNGLE LASERS"
            },
            {
              "VENDOR_NAME": "JUNIOR ACHIEV OF DEL VAL"
            },
            {
              "VENDOR_NAME": "JUNIOR ACHIEVEMENT NATIONAL"
            },
            {
              "VENDOR_NAME": "JUNIOR ACHIEVEMENT OF DELAWARE VALL"
            },
            {
              "VENDOR_NAME": "JUNIOR RAPTORS ICE HOCKEY"
            },
            {
              "VENDOR_NAME": "JUST BORN INC."
            },
            {
              "VENDOR_NAME": "JUST CASES.COM"
            },
            {
              "VENDOR_NAME": "JUST FOUR WHEELS INC."
            },
            {
              "VENDOR_NAME": "JUST RIGHT IMAGES"
            },
            {
              "VENDOR_NAME": "JUSTIN DIEM"
            },
            {
              "VENDOR_NAME": "JUSTIN T. HAZARD"
            },
            {
              "VENDOR_NAME": "JUSTINE KRYVEN"
            },
            {
              "VENDOR_NAME": "JUSTINE L.KAUFFMAN"
            },
            {
              "VENDOR_NAME": "JUVENILE DIABETES ASSOC."
            },
            {
              "VENDOR_NAME": "JUVENILE DIABETES FOUND."
            },
            {
              "VENDOR_NAME": "JUVENILE DIABETES FOUNDDATION"
            },
            {
              "VENDOR_NAME": "JUVENILE DIABETES RESEARCH"
            },
            {
              "VENDOR_NAME": "JUVENILE DIABETES RESEARCH FOUNDATI"
            },
            {
              "VENDOR_NAME": "K & S AND GREENDAY"
            },
            {
              "VENDOR_NAME": "K M TRANSPORTATION CO."
            },
            {
              "VENDOR_NAME": "K OF C CLASSIC"
            },
            {
              "VENDOR_NAME": "K SYSTEMS CORPORATION"
            },
            {
              "VENDOR_NAME": "K.F.WILSON CONTRACTOR"
            },
            {
              "VENDOR_NAME": "K.WAGNER MACHINE"
            },
            {
              "VENDOR_NAME": "K2 LIGHTING"
            },
            {
              "VENDOR_NAME": "K2HP CONSULTING GROUP"
            },
            {
              "VENDOR_NAME": "KAESTNER"
            },
            {
              "VENDOR_NAME": "KAHN JOINT VENTURES"
            },
            {
              "VENDOR_NAME": "KAHRS LAW OFFICE"
            },
            {
              "VENDOR_NAME": "KAINE INAUGURAL 2006"
            },
            {
              "VENDOR_NAME": "KAIRAK"
            },
            {
              "VENDOR_NAME": "KALEMJIAN INC"
            },
            {
              "VENDOR_NAME": "KALLOWHILL CONVENIENCE"
            },
            {
              "VENDOR_NAME": "KAMAN INDUSTRIAL TECHNOL."
            },
            {
              "VENDOR_NAME": "KAMCO BUILDING SUPPLIES CORP."
            },
            {
              "VENDOR_NAME": "KAMI HAZAVEH"
            },
            {
              "VENDOR_NAME": "KAN CHIN KONG"
            },
            {
              "VENDOR_NAME": "KAN KEE SHUM & LAN SHUM"
            },
            {
              "VENDOR_NAME": "KANDY KASTLE INC"
            },
            {
              "VENDOR_NAME": "KAN-PAK"
            },
            {
              "VENDOR_NAME": "KAN-PAK"
            },
            {
              "VENDOR_NAME": "KANSAS PAYMENT CENTER"
            },
            {
              "VENDOR_NAME": "KANSAS PAYMENT CTR"
            },
            {
              "VENDOR_NAME": "KANSAS PAYMENT CTR/MR05DM000032"
            },
            {
              "VENDOR_NAME": "KAPLAN & ZUBRIN INC."
            },
            {
              "VENDOR_NAME": "KAPLAN'S"
            },
            {
              "VENDOR_NAME": "KAPLIN STEWART MELOFF ET."
            },
            {
              "VENDOR_NAME": "KAPSER DISPOSAL SERVICE"
            },
            {
              "VENDOR_NAME": "KAREN CASALE"
            },
            {
              "VENDOR_NAME": "KAREN COOPER"
            },
            {
              "VENDOR_NAME": "KAREN FARISS"
            },
            {
              "VENDOR_NAME": "KAREN FITZPATRICK"
            },
            {
              "VENDOR_NAME": "KAREN HOLLOWAY"
            },
            {
              "VENDOR_NAME": "KAREN J CASALE"
            },
            {
              "VENDOR_NAME": "KAREN J. PHOLERIC"
            },
            {
              "VENDOR_NAME": "KAREN JOHNSON-MCCALL"
            },
            {
              "VENDOR_NAME": "KAREN MICHELIN"
            },
            {
              "VENDOR_NAME": "KAREN OWSLEY"
            },
            {
              "VENDOR_NAME": "KAREN R. TWIGG"
            },
            {
              "VENDOR_NAME": "KAREN SWANSON"
            },
            {
              "VENDOR_NAME": "KAREN SYNIC"
            },
            {
              "VENDOR_NAME": "KAREN UMSTETTER"
            },
            {
              "VENDOR_NAME": "KAREN WEISSERT"
            },
            {
              "VENDOR_NAME": "KARIM DAVIDSON"
            },
            {
              "VENDOR_NAME": "KARL D WEISSERT"
            },
            {
              "VENDOR_NAME": "KARL MULNAR"
            },
            {
              "VENDOR_NAME": "KARMA INC"
            },
            {
              "VENDOR_NAME": "KARPF"
            },
            {
              "VENDOR_NAME": "KARPF"
            },
            {
              "VENDOR_NAME": "KARRASS EFFECTIVE NEGOTIATING SEMIN"
            },
            {
              "VENDOR_NAME": "KASHI SALES LLC"
            },
            {
              "VENDOR_NAME": "KASHTAN TRANSPOR INC."
            },
            {
              "VENDOR_NAME": "KASHTAN TRANSPORT"
            },
            {
              "VENDOR_NAME": "KASSAB ARCHBOLD & O'BRIEN"
            },
            {
              "VENDOR_NAME": "KASTLE SYSTEMS OF PHILADELPHIA"
            },
            {
              "VENDOR_NAME": "KATFISH DESIGN"
            },
            {
              "VENDOR_NAME": "KATHERINE ANN LANGRELL"
            },
            {
              "VENDOR_NAME": "KATHERINE DICKINSON"
            },
            {
              "VENDOR_NAME": "KATHLEEN BETCHNER"
            },
            {
              "VENDOR_NAME": "KATHLEEN BUERLE"
            },
            {
              "VENDOR_NAME": "KATHLEEN MONTE"
            },
            {
              "VENDOR_NAME": "KATHLEEN MONZO"
            },
            {
              "VENDOR_NAME": "KATHLEEN MUELLER"
            },
            {
              "VENDOR_NAME": "KATHLEEN WEGMANN"
            },
            {
              "VENDOR_NAME": "KATHRYN BEKKA"
            },
            {
              "VENDOR_NAME": "KATHRYN H. CORIELL"
            },
            {
              "VENDOR_NAME": "KATHRYN MEYERS"
            },
            {
              "VENDOR_NAME": "KATHY CHEESEMAN"
            },
            {
              "VENDOR_NAME": "KATHY CIALLELLA"
            },
            {
              "VENDOR_NAME": "KATHY FOMALONT"
            },
            {
              "VENDOR_NAME": "KATHY JONES"
            },
            {
              "VENDOR_NAME": "KATHY P.SMITH"
            },
            {
              "VENDOR_NAME": "KATHY PINTO"
            },
            {
              "VENDOR_NAME": "KATLYN ANNE TRACY"
            },
            {
              "VENDOR_NAME": "KATS EXTREME ALL STAR CHEERLEADING"
            },
            {
              "VENDOR_NAME": "KAUFMAN AND CANOLES"
            },
            {
              "VENDOR_NAME": "KAVESH PANCARI TEDESCO"
            },
            {
              "VENDOR_NAME": "KAVESH PANCARI TESDESCO & PANCARI"
            },
            {
              "VENDOR_NAME": "KAY & SONS"
            },
            {
              "VENDOR_NAME": "KB ACRYLICS"
            },
            {
              "VENDOR_NAME": "KC SIGN COMPANY"
            },
            {
              "VENDOR_NAME": "KDV LABEL CO."
            },
            {
              "VENDOR_NAME": "KEANE"
            },
            {
              "VENDOR_NAME": "KEEN COMPRESSED GAS CO"
            },
            {
              "VENDOR_NAME": "KEITH CORNELIUS"
            },
            {
              "VENDOR_NAME": "KEITH DUNCAN"
            },
            {
              "VENDOR_NAME": "KEITH HARDVARD COURT OFFICER"
            },
            {
              "VENDOR_NAME": "KEITH M COX"
            },
            {
              "VENDOR_NAME": "KEITH SPRINZEN"
            },
            {
              "VENDOR_NAME": "KELE INC"
            },
            {
              "VENDOR_NAME": "KELLEHER ASSOCIATES"
            },
            {
              "VENDOR_NAME": "KELLERS CREAMERY"
            },
            {
              "VENDOR_NAME": "KELLOGGS FAFH"
            },
            {
              "VENDOR_NAME": "KELLY A. DOWNES"
            },
            {
              "VENDOR_NAME": "KELLY DISTRIBUTORS LLC"
            },
            {
              "VENDOR_NAME": "KELLY INDUSTRIAL SUPPLY"
            },
            {
              "VENDOR_NAME": "KELLY KILOWATT ELECTRIC"
            },
            {
              "VENDOR_NAME": "KELLY"
            },
            {
              "VENDOR_NAME": "KELLY'S TROPHIES"
            },
            {
              "VENDOR_NAME": "KEN BARTOW"
            },
            {
              "VENDOR_NAME": "KEN CULLIGAN"
            },
            {
              "VENDOR_NAME": "KEN CULLIGAN COURT OFFICER"
            },
            {
              "VENDOR_NAME": "KEN LODER"
            },
            {
              "VENDOR_NAME": "KEN MCCLELLAND CONSTABLE"
            },
            {
              "VENDOR_NAME": "KEN MELVIN FOR DELEGATE"
            },
            {
              "VENDOR_NAME": "KEN MULLER"
            },
            {
              "VENDOR_NAME": "KEN PAULUS"
            },
            {
              "VENDOR_NAME": "KENAN ADVANTAGE GROUP"
            },
            {
              "VENDOR_NAME": "KENAN-FLAGLER BUSINESS SCHOOL"
            },
            {
              "VENDOR_NAME": "KEN-CREST TRANSITION CONFERENCE"
            },
            {
              "VENDOR_NAME": "KENDERIAN ZILINSKI ASSOC"
            },
            {
              "VENDOR_NAME": "KENEXA TECHNOLOGY"
            },
            {
              "VENDOR_NAME": "KENLEA PLUMBING & HEATING"
            },
            {
              "VENDOR_NAME": "KENNEDY KRIEGER INSTITUTE"
            },
            {
              "VENDOR_NAME": "KENNETH A.MOSS REAL ESTATE"
            },
            {
              "VENDOR_NAME": "KENNETH AMEY"
            },
            {
              "VENDOR_NAME": "KENNETH BRAND"
            },
            {
              "VENDOR_NAME": "KENNETH CLARK"
            },
            {
              "VENDOR_NAME": "KENNETH CULLIGAN"
            },
            {
              "VENDOR_NAME": "KENNETH CULLIGAN COURT OFFICE"
            },
            {
              "VENDOR_NAME": "KENNETH CULLIGAN COURT OFFICER"
            },
            {
              "VENDOR_NAME": "KENNETH CULLIGAN"
            },
            {
              "VENDOR_NAME": "KENNETH MCCLELLAND"
            },
            {
              "VENDOR_NAME": "KENNETH MCCLELLAND CONSTABLE"
            },
            {
              "VENDOR_NAME": "KENNETH MCCLELLAND CT OFFICER"
            },
            {
              "VENDOR_NAME": "KENNETH MCCLELLAND"
            },
            {
              "VENDOR_NAME": "KENNETH OERTEL"
            },
            {
              "VENDOR_NAME": "KENNETH R PEIFER JR"
            },
            {
              "VENDOR_NAME": "KENNETH W. AVERY"
            },
            {
              "VENDOR_NAME": "KENNETT CONSOLIDATED SCHOOL DISTRIC"
            },
            {
              "VENDOR_NAME": "KENNETT FOOTBALL PARENTS GROUP"
            },
            {
              "VENDOR_NAME": "KENNETT SQUARE BORO. WAGE TAX PAYAB"
            },
            {
              "VENDOR_NAME": "KENNETT SQUARE GOLF AND COUNTRY CLU"
            },
            {
              "VENDOR_NAME": "KENNETT SQUARE POLICE DEPARTMENT"
            },
            {
              "VENDOR_NAME": "KENNON JENKINS COURT OFFICER"
            },
            {
              "VENDOR_NAME": "KENNON JENKINS CT. OFFICER"
            },
            {
              "VENDOR_NAME": "KENNON JENKINS"
            },
            {
              "VENDOR_NAME": "KENNY KUNS"
            },
            {
              "VENDOR_NAME": "KENNYS CANDY COMPANY"
            },
            {
              "VENDOR_NAME": "KENNY'S FLOWER SHOPPE INC"
            },
            {
              "VENDOR_NAME": "KENRIC INC."
            },
            {
              "VENDOR_NAME": "KENSOL AIRWAYS"
            },
            {
              "VENDOR_NAME": "KENT CONSERVATION DISTRICT"
            },
            {
              "VENDOR_NAME": "KENT COUNTY RECEIVER TAX"
            },
            {
              "VENDOR_NAME": "KENT COUNTY TREASURER"
            },
            {
              "VENDOR_NAME": "KENT DIAGNOSTIC RADIOLOGY"
            },
            {
              "VENDOR_NAME": "KERKIN INC/SUPERIOR DISPOSAL"
            },
            {
              "VENDOR_NAME": "KERMIT B. GOOD"
            },
            {
              "VENDOR_NAME": "KERR & ASSOCIATES"
            },
            {
              "VENDOR_NAME": "KERRY FOOD & BEVERAGE"
            },
            {
              "VENDOR_NAME": "KEVIN ANDERSON'S WELL DRILLING"
            },
            {
              "VENDOR_NAME": "KEVIN CONNELL"
            },
            {
              "VENDOR_NAME": "KEVIN F. NAUGHTON"
            },
            {
              "VENDOR_NAME": "KEVIN GILMORE"
            },
            {
              "VENDOR_NAME": "KEVIN HARDVARD COURT OFFICER"
            },
            {
              "VENDOR_NAME": "KEVIN HARVARD"
            },
            {
              "VENDOR_NAME": "KEVIN HARVARD COURT OFFICER"
            },
            {
              "VENDOR_NAME": "KEVIN HARVARD SUP.COURT OFFICER"
            },
            {
              "VENDOR_NAME": "KEVIN HARVARD"
            },
            {
              "VENDOR_NAME": "KEVIN M.COSTELLO"
            },
            {
              "VENDOR_NAME": "KEVIN McDEVITT"
            },
            {
              "VENDOR_NAME": "KEVIN METZ"
            },
            {
              "VENDOR_NAME": "KEVIN MULLEN"
            },
            {
              "VENDOR_NAME": "KEVIN PETER"
            },
            {
              "VENDOR_NAME": "KEVIN SHUMAN"
            },
            {
              "VENDOR_NAME": "KEVIN VIERICK"
            },
            {
              "VENDOR_NAME": "KEVIN WIGGINS"
            },
            {
              "VENDOR_NAME": "KEY ENGINEERS INC"
            },
            {
              "VENDOR_NAME": "KEY SYSTEMS INC."
            },
            {
              "VENDOR_NAME": "KEYES TOWING"
            },
            {
              "VENDOR_NAME": "KEYSTONE COFFEE ASSOCIATION"
            },
            {
              "VENDOR_NAME": "KEYSTONE COLLECTIONS GROUP"
            },
            {
              "VENDOR_NAME": "KEYSTONE ENGINEERING GROUP"
            },
            {
              "VENDOR_NAME": "KEYSTONE FIRE CO # 1"
            },
            {
              "VENDOR_NAME": "KEYSTONE FLEET SERVICES"
            },
            {
              "VENDOR_NAME": "KEYSTONE HEALTH PLAN CENTRAL"
            },
            {
              "VENDOR_NAME": "KEYSTONE HEALTH PLAN EAST"
            },
            {
              "VENDOR_NAME": "KEYSTONE MASCOTS"
            },
            {
              "VENDOR_NAME": "KEYSTONE PETROLEUM LTD"
            },
            {
              "VENDOR_NAME": "KEYSTONE STRUCTURES"
            },
            {
              "VENDOR_NAME": "KEYSTONE TAX ASSOCIATES"
            },
            {
              "VENDOR_NAME": "KEYSTONE TAX BUREAU"
            },
            {
              "VENDOR_NAME": "KF NOCKAMIXON LLC"
            },
            {
              "VENDOR_NAME": "KFORCE PROFESSIONAL STAFFING"
            },
            {
              "VENDOR_NAME": "KGI HOLDINGS HAMPTON WALK"
            },
            {
              "VENDOR_NAME": "KGL HOLDING HAMPTON WALK@643472"
            },
            {
              "VENDOR_NAME": "KHALIQ AL-SHABAZZ"
            },
            {
              "VENDOR_NAME": "KIDDER TOWNSHIP"
            },
            {
              "VENDOR_NAME": "KIDRON"
            },
            {
              "VENDOR_NAME": "KIDSMANIA INC."
            },
            {
              "VENDOR_NAME": "KILEY WRIGHT"
            },
            {
              "VENDOR_NAME": "KIM A.LOPDRUP"
            },
            {
              "VENDOR_NAME": "KIM AXEL LOPDRUP"
            },
            {
              "VENDOR_NAME": "KIM FIORENZO"
            },
            {
              "VENDOR_NAME": "KIM HAWN"
            },
            {
              "VENDOR_NAME": "KIM M. GINOPOLAS"
            },
            {
              "VENDOR_NAME": "KIMBERLY CLARK COMPANY"
            },
            {
              "VENDOR_NAME": "KIMBERLY GUNN"
            },
            {
              "VENDOR_NAME": "KIMBERLY ROBINSON"
            },
            {
              "VENDOR_NAME": "KIMBERTON 501"
            },
            {
              "VENDOR_NAME": "KIMBERTON 722"
            },
            {
              "VENDOR_NAME": "KIMLEY-HORN AND ASSOCIATES"
            },
            {
              "VENDOR_NAME": "KINANECO PRINTING"
            },
            {
              "VENDOR_NAME": "KIND"
            },
            {
              "VENDOR_NAME": "KING & QUEEN CIRCUIT COURT"
            },
            {
              "VENDOR_NAME": "KING AND QUEEN CIRCUIT COURT"
            },
            {
              "VENDOR_NAME": "KING GEORGE GENERAL DISTRICT COURT"
            },
            {
              "VENDOR_NAME": "KING GEORGE JOURNAL"
            },
            {
              "VENDOR_NAME": "KING LIMOUSINE AND"
            },
            {
              "VENDOR_NAME": "KING WILLIAM GEN'L DIST CT"
            },
            {
              "VENDOR_NAME": "KING WILLIAM GEN'L DIST. COURT"
            },
            {
              "VENDOR_NAME": "KING WILLIAM GEN'L DIST. CT"
            },
            {
              "VENDOR_NAME": "KING WILLIAM GEN'L DIST.CT."
            },
            {
              "VENDOR_NAME": "KINGS MILL"
            },
            {
              "VENDOR_NAME": "KINGSWAY DRAGONS"
            },
            {
              "VENDOR_NAME": "KINGSWAY ELITE"
            },
            {
              "VENDOR_NAME": "KINGSWOOD APARTMENTS"
            },
            {
              "VENDOR_NAME": "KIPLINGER LETTER"
            },
            {
              "VENDOR_NAME": "KIR-ASSOCIATES C/O MUSI COMMERCIAL"
            },
            {
              "VENDOR_NAME": "KIR-ASSOCIATES COLDWELL BANKER"
            },
            {
              "VENDOR_NAME": "KIRKWOOD FITNESS CLUBS"
            },
            {
              "VENDOR_NAME": "KIRSTEN DEAL"
            },
            {
              "VENDOR_NAME": "KISTLER-O'BRIEN FIREPROTECTION"
            },
            {
              "VENDOR_NAME": "KITCHEN & BATH WHOLESALERS"
            },
            {
              "VENDOR_NAME": "KITCHEN KETTLE VILLAGE"
            },
            {
              "VENDOR_NAME": "KITTY WARD TRAVEL"
            },
            {
              "VENDOR_NAME": "KIWI CODERS CORPORATION"
            },
            {
              "VENDOR_NAME": "KJ ELECTRIC"
            },
            {
              "VENDOR_NAME": "KLEE'S BAR & GRILL"
            },
            {
              "VENDOR_NAME": "KLENZOID INC."
            },
            {
              "VENDOR_NAME": "KLETT ROONEY LIEBER & SCHORLING"
            },
            {
              "VENDOR_NAME": "KLOBE CONSULTING"
            },
            {
              "VENDOR_NAME": "KLUBER LUBRICATION"
            },
            {
              "VENDOR_NAME": "KNIGHT PARK TRUSTEES/PLAYGROUND"
            },
            {
              "VENDOR_NAME": "KNIGHT"
            },
            {
              "VENDOR_NAME": "KNIGHTS INN GLEN ALLEN"
            },
            {
              "VENDOR_NAME": "KNIGHTS OF COLUMBUS"
            },
            {
              "VENDOR_NAME": "KNIGHTS OF COLUMBUS #3826"
            },
            {
              "VENDOR_NAME": "KNOUSE FOODS"
            },
            {
              "VENDOR_NAME": "KNOX COMPANY"
            },
            {
              "VENDOR_NAME": "KNOX EQUIPMENT RENTALS"
            },
            {
              "VENDOR_NAME": "KOKO'S CONFECTIONARY"
            },
            {
              "VENDOR_NAME": "KOLMAR"
            },
            {
              "VENDOR_NAME": "KOLMAR - ACCRUAL"
            },
            {
              "VENDOR_NAME": "KONICA MINOLTA"
            },
            {
              "VENDOR_NAME": "KONICA MINOLTA INC"
            },
            {
              "VENDOR_NAME": "KORBYN SIMPSON"
            },
            {
              "VENDOR_NAME": "KORMAN COMMERCIAL PROPERTIES"
            },
            {
              "VENDOR_NAME": "KORMAN COMMUNITIES"
            },
            {
              "VENDOR_NAME": "KOST KLIP MANUFACTURING"
            },
            {
              "VENDOR_NAME": "KOTESWARA R PANQULURI"
            },
            {
              "VENDOR_NAME": "KOURGELIS AND KESOGLOU"
            },
            {
              "VENDOR_NAME": "KOZAK BEVERAGE"
            },
            {
              "VENDOR_NAME": "KOZY SHACK ENTERPRISES"
            },
            {
              "VENDOR_NAME": "KP HOME REMODELING"
            },
            {
              "VENDOR_NAME": "KPM LLC"
            },
            {
              "VENDOR_NAME": "KPMG LLP"
            },
            {
              "VENDOR_NAME": "KRAFT FOODS"
            },
            {
              "VENDOR_NAME": "KRAFT FOODS"
            },
            {
              "VENDOR_NAME": "KRAFT FOODS-REFRIGERATED"
            },
            {
              "VENDOR_NAME": "KRAMONT REALTY TRUST"
            },
            {
              "VENDOR_NAME": "KRAPF'S COACHES"
            },
            {
              "VENDOR_NAME": "KREIDER FARMS EGG DIVISION"
            },
            {
              "VENDOR_NAME": "KRISANN MARTIN"
            },
            {
              "VENDOR_NAME": "KRISTEN BRENNAN"
            },
            {
              "VENDOR_NAME": "KRISTEN M.FOX"
            },
            {
              "VENDOR_NAME": "KRISTIN A KEILT"
            },
            {
              "VENDOR_NAME": "KRISTINA FRONCEK"
            },
            {
              "VENDOR_NAME": "KRISTINA M.STAUGHTON"
            },
            {
              "VENDOR_NAME": "KRISTINA M.STAUGHTON"
            },
            {
              "VENDOR_NAME": "KRONES INC"
            },
            {
              "VENDOR_NAME": "KRONOS INC"
            },
            {
              "VENDOR_NAME": "KS MILLS"
            },
            {
              "VENDOR_NAME": "K-SEA TRANSPORTATION"
            },
            {
              "VENDOR_NAME": "KUNZLER AND COMPANY"
            },
            {
              "VENDOR_NAME": "KYSOR PANEL SYSTEMS"
            },
            {
              "VENDOR_NAME": "KYW-TV"
            },
            {
              "VENDOR_NAME": "L & H SIGN INDUSTRIES"
            },
            {
              "VENDOR_NAME": "L & S ASSOCIATES"
            },
            {
              "VENDOR_NAME": "L & S RACING"
            },
            {
              "VENDOR_NAME": "L PROVIDENCE WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "L. SOUTHHAMPTON EIT"
            },
            {
              "VENDOR_NAME": "L.T. HAWTHORNE & CO."
            },
            {
              "VENDOR_NAME": "L.T. VERRASTRO"
            },
            {
              "VENDOR_NAME": "L.TAGUS REALTY"
            },
            {
              "VENDOR_NAME": "L.W.CAIRN'S LAWN"
            },
            {
              "VENDOR_NAME": "LA CASA DI LUCIA"
            },
            {
              "VENDOR_NAME": "LA ESPERANZA"
            },
            {
              "VENDOR_NAME": "LA FITNESS INTERNATIONAL LLC"
            },
            {
              "VENDOR_NAME": "LA QUINTA INN & SUITES"
            },
            {
              "VENDOR_NAME": "LA-1600 WALNUT"
            },
            {
              "VENDOR_NAME": "LAB SAFETY SUPPLY INC"
            },
            {
              "VENDOR_NAME": "LAB SUPPORT"
            },
            {
              "VENDOR_NAME": "LABELPACK AUTOMATION"
            },
            {
              "VENDOR_NAME": "LABIBY JOSEPH"
            },
            {
              "VENDOR_NAME": "LABOR RELATIONS INSTITUTE"
            },
            {
              "VENDOR_NAME": "LACEY ASSOCIATES"
            },
            {
              "VENDOR_NAME": "LACEY CHAMBER OF COMMERCE INC."
            },
            {
              "VENDOR_NAME": "LACEY FOOTBALL FOUNDATION"
            },
            {
              "VENDOR_NAME": "LACEY LITTLE LEAGUE"
            },
            {
              "VENDOR_NAME": "LACEY MUNICIPAL UTILITIES"
            },
            {
              "VENDOR_NAME": "LACEY TOWNSHIP"
            },
            {
              "VENDOR_NAME": "LACEY TOWNSHIP FIRST AID SQUAD"
            },
            {
              "VENDOR_NAME": "LACEY TOWNSHIP TAX COLLEC"
            },
            {
              "VENDOR_NAME": "LACEY TWSP BD OF HEALTH"
            },
            {
              "VENDOR_NAME": "LAFAYETTE TITLE AND ESCROW"
            },
            {
              "VENDOR_NAME": "LAKE BOOK MANUFACTURING"
            },
            {
              "VENDOR_NAME": "LAKE ROEDER HILLARD & ASSOC."
            },
            {
              "VENDOR_NAME": "LAKEHURST BORO WATER DEPT"
            },
            {
              "VENDOR_NAME": "LAKEHURST CIRCLE"
            },
            {
              "VENDOR_NAME": "LAKEHURST FIRE DEPT."
            },
            {
              "VENDOR_NAME": "LAKEISHA DUMONT"
            },
            {
              "VENDOR_NAME": "LAKEVIEW APARTMENTS"
            },
            {
              "VENDOR_NAME": "LAKEVIEW FARMS"
            },
            {
              "VENDOR_NAME": "LAKEVIEW GARAGE"
            },
            {
              "VENDOR_NAME": "LAKEWEST GROUP LTD."
            },
            {
              "VENDOR_NAME": "LAKEWOOD POST 166"
            },
            {
              "VENDOR_NAME": "LAMBERT FOR SENATE"
            },
            {
              "VENDOR_NAME": "LAMOTTE MCCOLLISTER"
            },
            {
              "VENDOR_NAME": "LANCASTER AREA SEWER AUTHORITY"
            },
            {
              "VENDOR_NAME": "LANCASTER COUNTY"
            },
            {
              "VENDOR_NAME": "LANCASTER COUNTY WEEKLIES"
            },
            {
              "VENDOR_NAME": "LANCASTER CTY PLAN. COMM."
            },
            {
              "VENDOR_NAME": "LANCASTER CTY TAX"
            },
            {
              "VENDOR_NAME": "LANCASTER GEN DIST CT"
            },
            {
              "VENDOR_NAME": "LANCASTER NEWSPAPERS"
            },
            {
              "VENDOR_NAME": "LANCASTER TRUCK BODIES"
            },
            {
              "VENDOR_NAME": "LANCE INC"
            },
            {
              "VENDOR_NAME": "LANCE W.STAUGHTON"
            },
            {
              "VENDOR_NAME": "LAND AMERICA COMMERCIAL SERVICES"
            },
            {
              "VENDOR_NAME": "LAND AMERICA LAWYERS TITLE INS.CO."
            },
            {
              "VENDOR_NAME": "LAND AMERICA TAX AND FLOOD"
            },
            {
              "VENDOR_NAME": "LAND DEVELOPMENT DESIGN"
            },
            {
              "VENDOR_NAME": "LAND DIMENSIONS ENGINEER"
            },
            {
              "VENDOR_NAME": "LAND SERVICES USA"
            },
            {
              "VENDOR_NAME": "LAND SERVICES USA"
            },
            {
              "VENDOR_NAME": "LANDAMERICA COMMONWEALTH"
            },
            {
              "VENDOR_NAME": "LANDAMERICA FINANCIAL GROUP"
            },
            {
              "VENDOR_NAME": "LANDAMERICA NATIONAL COMMERCIAL SER"
            },
            {
              "VENDOR_NAME": "LANDESK SOFTWARE"
            },
            {
              "VENDOR_NAME": "LANDIS SEWERAGE AUTH"
            },
            {
              "VENDOR_NAME": "LANDIS THEATER FOUNDATION"
            },
            {
              "VENDOR_NAME": "LANDMARK COMMUNICATIONS"
            },
            {
              "VENDOR_NAME": "LANDMARK ENGINEERING"
            },
            {
              "VENDOR_NAME": "LANDMARK FINANCIAL SERVICES"
            },
            {
              "VENDOR_NAME": "LANDMARK FINANCIAL SERVICES"
            },
            {
              "VENDOR_NAME": "LANDMARK LANDSCAPE"
            },
            {
              "VENDOR_NAME": "LANDREW FLOORING"
            },
            {
              "VENDOR_NAME": "LANDS END CORPORATE SALES"
            },
            {
              "VENDOR_NAME": "LANDSCAPE CONSTRUCTION SERVICES"
            },
            {
              "VENDOR_NAME": "LANDSCAPE GARDENING INC."
            },
            {
              "VENDOR_NAME": "LANDVIEW REALTY GROUP"
            },
            {
              "VENDOR_NAME": "LANDVISIONS"
            },
            {
              "VENDOR_NAME": "LANE LIMITED"
            },
            {
              "VENDOR_NAME": "LANE R. JUBB"
            },
            {
              "VENDOR_NAME": "LANGAN ENGINEERING"
            },
            {
              "VENDOR_NAME": "LANGEVIN LEARNING SERVICES (US) INC"
            },
            {
              "VENDOR_NAME": "LANGLEY FEDERAL CREDIT UNION"
            },
            {
              "VENDOR_NAME": "LANGLEY FED'L CREDIT UNION"
            },
            {
              "VENDOR_NAME": "LANG'S SEPTIC SERVICE INC"
            },
            {
              "VENDOR_NAME": "LANKENAU HOSPITAL"
            },
            {
              "VENDOR_NAME": "LANKENAU INSTITUTE FOR MEDICAL RESE"
            },
            {
              "VENDOR_NAME": "LANOKA HARBOR"
            },
            {
              "VENDOR_NAME": "LANSDOWNE TAX COLLECTOR"
            },
            {
              "VENDOR_NAME": "LANSING ETHANOL SERVICES"
            },
            {
              "VENDOR_NAME": "LANTECH INC"
            },
            {
              "VENDOR_NAME": "LARMON PHOTO INC"
            },
            {
              "VENDOR_NAME": "LARRY ALBRIGHT"
            },
            {
              "VENDOR_NAME": "LARRY DUBS"
            },
            {
              "VENDOR_NAME": "LARRY H. MOORE"
            },
            {
              "VENDOR_NAME": "LARRY HARRINGTON"
            },
            {
              "VENDOR_NAME": "LARRY ISACSON"
            },
            {
              "VENDOR_NAME": "LARSON ENGINEERING"
            },
            {
              "VENDOR_NAME": "LaSALLE BANK"
            },
            {
              "VENDOR_NAME": "LaSALLE UNIVERSITY"
            },
            {
              "VENDOR_NAME": "LATINUM NETWORK"
            },
            {
              "VENDOR_NAME": "LATOYA ZAIRE"
            },
            {
              "VENDOR_NAME": "LAUDONE & ASSOCIATES"
            },
            {
              "VENDOR_NAME": "LAURA BIORDI"
            },
            {
              "VENDOR_NAME": "LAURA DAVIS"
            },
            {
              "VENDOR_NAME": "LAURA FERRY"
            },
            {
              "VENDOR_NAME": "LAURA FOULKE"
            },
            {
              "VENDOR_NAME": "LAURA RODGERS"
            },
            {
              "VENDOR_NAME": "LAURA SPAVENTA"
            },
            {
              "VENDOR_NAME": "LAURA TAYLOR FOULKE"
            },
            {
              "VENDOR_NAME": "LAUREL TOMLIN"
            },
            {
              "VENDOR_NAME": "LAUREN M. CORCORAN"
            },
            {
              "VENDOR_NAME": "LAUREN ROSE ALBERT FOUNDATION"
            },
            {
              "VENDOR_NAME": "LAURETTA SMITH"
            },
            {
              "VENDOR_NAME": "LAURIE SULITKA"
            },
            {
              "VENDOR_NAME": "LAURY HEATING CO INC"
            },
            {
              "VENDOR_NAME": "LAW ENFORCEMENT MEMORIAL FUND"
            },
            {
              "VENDOR_NAME": "LAW JOURNAL PRESS"
            },
            {
              "VENDOR_NAME": "LAW OFFICES OF CHAPMAN"
            },
            {
              "VENDOR_NAME": "LAW OFFICES OF PATRICK SCANLON PA"
            },
            {
              "VENDOR_NAME": "LAW OFFICES OF R. E. YASKIN"
            },
            {
              "VENDOR_NAME": "LAW OFFICES OF R.DALE WINGET"
            },
            {
              "VENDOR_NAME": "LAW OFFICES OF RICHARD L PRESS"
            },
            {
              "VENDOR_NAME": "LAW OFFICES OF STEPHAN T. MASHEL"
            },
            {
              "VENDOR_NAME": "LAW OFFICES OF STEPHAN T.MASHEL"
            },
            {
              "VENDOR_NAME": "LAW OFFIES OF RALPH A.PAOLONE"
            },
            {
              "VENDOR_NAME": "LAWN DOCTOR OF ASTON-MIDDLETOWN"
            },
            {
              "VENDOR_NAME": "LAWN PERFECTIONS LANDSCAPING"
            },
            {
              "VENDOR_NAME": "LAWNCREST 4TH JULY COMMITTEE"
            },
            {
              "VENDOR_NAME": "LAWRENCE F HIMM"
            },
            {
              "VENDOR_NAME": "LAWRENCE JAY COHEN"
            },
            {
              "VENDOR_NAME": "LAWRENCE L LATHROP"
            },
            {
              "VENDOR_NAME": "LAWRENCE METAL PRODUCTS INC"
            },
            {
              "VENDOR_NAME": "LAWRENCE VALENTI"
            },
            {
              "VENDOR_NAME": "LAWRENSON"
            },
            {
              "VENDOR_NAME": "LAWYER ASSESSMENT"
            },
            {
              "VENDOR_NAME": "LAWYERS DIARY AND MANUAL"
            },
            {
              "VENDOR_NAME": "LAYNE COMMUNICATIONS"
            },
            {
              "VENDOR_NAME": "LAZAROS KARASAVAS"
            },
            {
              "VENDOR_NAME": "LAZGOR LLC"
            },
            {
              "VENDOR_NAME": "LB POWELL LLC"
            },
            {
              "VENDOR_NAME": "LBP MANUFACTURING"
            },
            {
              "VENDOR_NAME": "LBP MANUFACTURING"
            },
            {
              "VENDOR_NAME": "LCB BAND BOOSTERS"
            },
            {
              "VENDOR_NAME": "LCG TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "LEADERSHIP STUDIES"
            },
            {
              "VENDOR_NAME": "LEAGUE SCHOLARSHIP PROGRAM"
            },
            {
              "VENDOR_NAME": "LEARNING COMMUNICATIONS"
            },
            {
              "VENDOR_NAME": "LEARNING TREE INT'L"
            },
            {
              "VENDOR_NAME": "LEARY & SONS FLORIST"
            },
            {
              "VENDOR_NAME": "LEBANON BOROUGH SEWERAGE AUTHORITY"
            },
            {
              "VENDOR_NAME": "LECLAIR RYAN"
            },
            {
              "VENDOR_NAME": "LECLAIRE RYAN"
            },
            {
              "VENDOR_NAME": "LECTRO TECH"
            },
            {
              "VENDOR_NAME": "LEE ANN LIEPE"
            },
            {
              "VENDOR_NAME": "LEE BACON"
            },
            {
              "VENDOR_NAME": "LEE BACON COURT OFFICER"
            },
            {
              "VENDOR_NAME": "LEE BACON"
            },
            {
              "VENDOR_NAME": "LEE BACON"
            },
            {
              "VENDOR_NAME": "LEE H BACON- SALEM COUNTY OFFICER"
            },
            {
              "VENDOR_NAME": "LEE H. BACON COURT OFFICER"
            },
            {
              "VENDOR_NAME": "LEE H.BACON"
            },
            {
              "VENDOR_NAME": "LEE HECHT HARRISON"
            },
            {
              "VENDOR_NAME": "LEE STRAUBE"
            },
            {
              "VENDOR_NAME": "LEGAL COMMUNICATIONS LTD"
            },
            {
              "VENDOR_NAME": "LEGAL DIRECTORIES PUBLISHING CO."
            },
            {
              "VENDOR_NAME": "LEGAL SEARCH"
            },
            {
              "VENDOR_NAME": "LEGGETTE"
            },
            {
              "VENDOR_NAME": "LeGORE & JONES"
            },
            {
              "VENDOR_NAME": "LEHIGH CONCRETE TECH.INC."
            },
            {
              "VENDOR_NAME": "LEHIGH COUNTY AUTHORITY"
            },
            {
              "VENDOR_NAME": "LEHIGH CTY.CONS.DISTRICT"
            },
            {
              "VENDOR_NAME": "LEHIGH ENGINEERING ASSOCIATES"
            },
            {
              "VENDOR_NAME": "LEHIGH NORTHAMPTON"
            },
            {
              "VENDOR_NAME": "LEHIGH VALLEY INSPECTIONS"
            },
            {
              "VENDOR_NAME": "LEHIGH VALLEY IRON PIGS"
            },
            {
              "VENDOR_NAME": "LEHIGH VALLEY SAFETY"
            },
            {
              "VENDOR_NAME": "LEHIGH VALLEY SITE"
            },
            {
              "VENDOR_NAME": "LEHMAN DESIGN"
            },
            {
              "VENDOR_NAME": "LEISURE FITNESS EQUIPMENT"
            },
            {
              "VENDOR_NAME": "LENDMARK FIN SER INC"
            },
            {
              "VENDOR_NAME": "LENDMARK FINANCIAL SERVICES"
            },
            {
              "VENDOR_NAME": "LENNI HEIGHTS FIRE COMPANY"
            },
            {
              "VENDOR_NAME": "LENNOX INDUSTRIES"
            },
            {
              "VENDOR_NAME": "LENNY'S TROPHY COMPANY"
            },
            {
              "VENDOR_NAME": "LENOVO (UNITED STATES) INC."
            },
            {
              "VENDOR_NAME": "LENROW"
            },
            {
              "VENDOR_NAME": "LEOMAT"
            },
            {
              "VENDOR_NAME": "LEON J. MASCARO MEMORIAL"
            },
            {
              "VENDOR_NAME": "LEON LUST"
            },
            {
              "VENDOR_NAME": "LEONARD & MAX GLANTZ"
            },
            {
              "VENDOR_NAME": "LEONARD NOVELTY BAKERY"
            },
            {
              "VENDOR_NAME": "LEROY MOTLEY"
            },
            {
              "VENDOR_NAME": "LES BROWN ENTERPRISES"
            },
            {
              "VENDOR_NAME": "LESCO PAPER AND BOX COMPANY"
            },
            {
              "VENDOR_NAME": "LESHAUN GADDY"
            },
            {
              "VENDOR_NAME": "LESHAUN GADDY COURT OFFICER"
            },
            {
              "VENDOR_NAME": "LESHAUN GADDY"
            },
            {
              "VENDOR_NAME": "LESLIE CHAMPION"
            },
            {
              "VENDOR_NAME": "LESLIE FRENZEL"
            },
            {
              "VENDOR_NAME": "LESTER"
            },
            {
              "VENDOR_NAME": "LET US PRODUCE"
            },
            {
              "VENDOR_NAME": "LETICA CORP"
            },
            {
              "VENDOR_NAME": "LETICA FREIGHTLINES"
            },
            {
              "VENDOR_NAME": "LET'S PARTY"
            },
            {
              "VENDOR_NAME": "LEUKEMIA & LYMPHOMA"
            },
            {
              "VENDOR_NAME": "LEUKEMIA & LYMPHOMA SOCIETY"
            },
            {
              "VENDOR_NAME": "LEUKEMIA SOCIETY OF AMER"
            },
            {
              "VENDOR_NAME": "LEVEL 3 COMMUNICATIONS"
            },
            {
              "VENDOR_NAME": "LEVIN"
            },
            {
              "VENDOR_NAME": "LEVOW & COSTELLO"
            },
            {
              "VENDOR_NAME": "LEVY UNITED NEWS CO BOOKS"
            },
            {
              "VENDOR_NAME": "LEWES ELECTRIC INC."
            },
            {
              "VENDOR_NAME": "LEWES FIRE DEPARTMENT"
            },
            {
              "VENDOR_NAME": "LEWIS ENVIRONMENTAL GROUP"
            },
            {
              "VENDOR_NAME": "LEWIS EQUIPMENT INC"
            },
            {
              "VENDOR_NAME": "LEWIS GOETZ AND COMPANY"
            },
            {
              "VENDOR_NAME": "LEWIS GOLIN"
            },
            {
              "VENDOR_NAME": "LEWIS RIDGE RETAIL"
            },
            {
              "VENDOR_NAME": "LEXMARK INTERNATIONAL INC"
            },
            {
              "VENDOR_NAME": "LEYMAN MANUFACTURING CORP"
            },
            {
              "VENDOR_NAME": "LEYNA ZAGIEL"
            },
            {
              "VENDOR_NAME": "LEZGUS CORP."
            },
            {
              "VENDOR_NAME": "LGA ENGINEERING"
            },
            {
              "VENDOR_NAME": "LGT ASSOCIATES"
            },
            {
              "VENDOR_NAME": "LIA'S CATERING"
            },
            {
              "VENDOR_NAME": "LIBERTY BELL BICYCLE"
            },
            {
              "VENDOR_NAME": "LIBERTY COCA-COLA BEVERAGES LLC"
            },
            {
              "VENDOR_NAME": "LIBERTY KENWORTH TRUCK"
            },
            {
              "VENDOR_NAME": "LIBERTY KENWORTH TRUCK SALES"
            },
            {
              "VENDOR_NAME": "LIBERTY NEWS DISTRIBUTORS INC"
            },
            {
              "VENDOR_NAME": "LIBERTY NEWS DISTRIBUTORS"
            },
            {
              "VENDOR_NAME": "LIBERTY PERSONNEL SERVICES"
            },
            {
              "VENDOR_NAME": "LIBERTY PROPERTY LTD."
            },
            {
              "VENDOR_NAME": "LIBERTY PROPERTY TRUST"
            },
            {
              "VENDOR_NAME": "LIBERTY RECYCLING INC."
            },
            {
              "VENDOR_NAME": "LIBERTY SAFE & LOCK CO."
            },
            {
              "VENDOR_NAME": "LID WORKS M&N PLASTICS INC"
            },
            {
              "VENDOR_NAME": "LIDWORKS M&N PLASTICS"
            },
            {
              "VENDOR_NAME": "LIFE CENTER TABERNACLE"
            },
            {
              "VENDOR_NAME": "LIFE INSURANCE COMPANY"
            },
            {
              "VENDOR_NAME": "LIFESCAPE DESIGNS"
            },
            {
              "VENDOR_NAME": "LIFT OFF DISTRIBUTION"
            },
            {
              "VENDOR_NAME": "LIGGETT VECTOR"
            },
            {
              "VENDOR_NAME": "LIGHTHOUSE FURNITURE & APPL."
            },
            {
              "VENDOR_NAME": "LIGHTHOUSE PLASTICS LLC"
            },
            {
              "VENDOR_NAME": "LIGHTSTAT"
            },
            {
              "VENDOR_NAME": "LIL DRUG STORE"
            },
            {
              "VENDOR_NAME": "LIMA COMPANY"
            },
            {
              "VENDOR_NAME": "LIMA FIRE COMPANY"
            },
            {
              "VENDOR_NAME": "LIMA RESIDENTS ASSOCIATION"
            },
            {
              "VENDOR_NAME": "LIMERICK BOWL"
            },
            {
              "VENDOR_NAME": "LIMERICK LANDSCAPING &"
            },
            {
              "VENDOR_NAME": "LIMERICK NEWS SERVICE"
            },
            {
              "VENDOR_NAME": "LIMERICK TOWNSHIP"
            },
            {
              "VENDOR_NAME": "LIMERICK TOWNSHIP M.U.A."
            },
            {
              "VENDOR_NAME": "LIMERICK TOWNSHIP SEWER DEPT."
            },
            {
              "VENDOR_NAME": "LIMERICK TOWNSHIP WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "LIMESTONE LAND HOLDINGS"
            },
            {
              "VENDOR_NAME": "LINCKS & ASSOCIATES"
            },
            {
              "VENDOR_NAME": "LINCOLN HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "LINCOLN J. WALZ"
            },
            {
              "VENDOR_NAME": "LINCOLN J.WALZ"
            },
            {
              "VENDOR_NAME": "LINCOLN SNACKS COMPANY"
            },
            {
              "VENDOR_NAME": "LINDA ARTMAN"
            },
            {
              "VENDOR_NAME": "LINDA B GREW"
            },
            {
              "VENDOR_NAME": "LINDA BAKER"
            },
            {
              "VENDOR_NAME": "LINDA BELCHER"
            },
            {
              "VENDOR_NAME": "LINDA BROOKS"
            },
            {
              "VENDOR_NAME": "LINDA CRACOLICI"
            },
            {
              "VENDOR_NAME": "LINDA DEPRINZIO"
            },
            {
              "VENDOR_NAME": "LINDA DIGIOVAN"
            },
            {
              "VENDOR_NAME": "LINDA HOULDIN"
            },
            {
              "VENDOR_NAME": "LINDA HYLAND"
            },
            {
              "VENDOR_NAME": "LINDA KOLK"
            },
            {
              "VENDOR_NAME": "LINDA LUDEKE"
            },
            {
              "VENDOR_NAME": "LINDA ROOT"
            },
            {
              "VENDOR_NAME": "LINDA SWIGERT"
            },
            {
              "VENDOR_NAME": "LINDEN HILL JOINT VENTURE"
            },
            {
              "VENDOR_NAME": "LINDENMEYR MUNROE"
            },
            {
              "VENDOR_NAME": "LINDENWOLD 5K"
            },
            {
              "VENDOR_NAME": "LINDENWOLD BUREAU OF"
            },
            {
              "VENDOR_NAME": "LINDENWOLD FIRE COMPANY # 2"
            },
            {
              "VENDOR_NAME": "LINDT & SPRUNGLI"
            },
            {
              "VENDOR_NAME": "LINEBARGER GOGGAN BLAIR & SAMPSONS"
            },
            {
              "VENDOR_NAME": "LINEBARGER"
            },
            {
              "VENDOR_NAME": "LINEBARGER"
            },
            {
              "VENDOR_NAME": "LINEBARGER"
            },
            {
              "VENDOR_NAME": "LINETTE QUALITY CHOCOLATE"
            },
            {
              "VENDOR_NAME": "LINFIELD NATIONAL GOLF CLUB"
            },
            {
              "VENDOR_NAME": "LINFORD STOLTZFUS"
            },
            {
              "VENDOR_NAME": "LINGUIS-TECHS"
            },
            {
              "VENDOR_NAME": "LINN COUNTY SHERIFF"
            },
            {
              "VENDOR_NAME": "LINVILLA ORCHARDS"
            },
            {
              "VENDOR_NAME": "LINWOOD MUNICIPAL BLDG."
            },
            {
              "VENDOR_NAME": "LINX-AS"
            },
            {
              "VENDOR_NAME": "LIONEL LAUER-CARLSON WAGONLIT"
            },
            {
              "VENDOR_NAME": "LIPMAN FRIZZELL"
            },
            {
              "VENDOR_NAME": "LIPSON ALPORT GLASS & ASSOC."
            },
            {
              "VENDOR_NAME": "LIRON KATNA"
            },
            {
              "VENDOR_NAME": "LISA JONES"
            },
            {
              "VENDOR_NAME": "LISA L.OLIVER"
            },
            {
              "VENDOR_NAME": "LISA M.KUHNS"
            },
            {
              "VENDOR_NAME": "LISA NICKERSON"
            },
            {
              "VENDOR_NAME": "LISA PIGNATARO"
            },
            {
              "VENDOR_NAME": "LISA PRAWDZIK"
            },
            {
              "VENDOR_NAME": "LISA PRIEST"
            },
            {
              "VENDOR_NAME": "LISA RESSLER"
            },
            {
              "VENDOR_NAME": "LISA RUGGERI"
            },
            {
              "VENDOR_NAME": "LISA TRASCHER"
            },
            {
              "VENDOR_NAME": "LISA WOLLAN"
            },
            {
              "VENDOR_NAME": "LITTLE EGG HARBOR MUNICIP"
            },
            {
              "VENDOR_NAME": "LITTLE EGG HARBOR TOWNSHIP"
            },
            {
              "VENDOR_NAME": "LITTLE EGG HARBOR TWP"
            },
            {
              "VENDOR_NAME": "LITTLE FOLKS LEARNING CTR"
            },
            {
              "VENDOR_NAME": "LITTLE WASHINGTON WASTEWATER CO."
            },
            {
              "VENDOR_NAME": "LITTLELEAF ENTERPRISES"
            },
            {
              "VENDOR_NAME": "LIVENGRIN FOUNDATION"
            },
            {
              "VENDOR_NAME": "LIVING ESSENTIALS LLC"
            },
            {
              "VENDOR_NAME": "LIZZA CONSTRUCTION"
            },
            {
              "VENDOR_NAME": "LJ CLASSIC CORP."
            },
            {
              "VENDOR_NAME": "LJR PUBLISHING LLC"
            },
            {
              "VENDOR_NAME": "LLOYD FEIGENBAUM"
            },
            {
              "VENDOR_NAME": "LLOYDS-GENERAL MILLS"
            },
            {
              "VENDOR_NAME": "LLOYN FEIGENBAUM"
            },
            {
              "VENDOR_NAME": "LMA ARCHITECTS"
            },
            {
              "VENDOR_NAME": "LNT EIT"
            },
            {
              "VENDOR_NAME": "LOCAL 463"
            },
            {
              "VENDOR_NAME": "LOCAL TAXES - BERKHEIMER"
            },
            {
              "VENDOR_NAME": "LOCKE FOR SENATE"
            },
            {
              "VENDOR_NAME": "LOCUS TRAXX"
            },
            {
              "VENDOR_NAME": "LOCUST HILL FARMS"
            },
            {
              "VENDOR_NAME": "LOGAN SOCCER CLUB"
            },
            {
              "VENDOR_NAME": "LOGAN TOWNSHIP"
            },
            {
              "VENDOR_NAME": "LOGAN'S HARDSCAPE &"
            },
            {
              "VENDOR_NAME": "LOGAN'S HARDSCAPE & LAND DESIGN"
            },
            {
              "VENDOR_NAME": "LOGICAL CONSULTING"
            },
            {
              "VENDOR_NAME": "LOGUE TOWING"
            },
            {
              "VENDOR_NAME": "LOIEDERMAN SOLTESZ ASSOC."
            },
            {
              "VENDOR_NAME": "LONDON GROVE TOWNSHIP"
            },
            {
              "VENDOR_NAME": "LONDON TOWNE APARTMENTS"
            },
            {
              "VENDOR_NAME": "LONG & FOSTER REALTORS-"
            },
            {
              "VENDOR_NAME": "LONG BEACH HEALTH DEPT"
            },
            {
              "VENDOR_NAME": "LONG BEACH ISLAND PBA LOCAL175"
            },
            {
              "VENDOR_NAME": "LONG BEACH SEWERAGE AUTHO"
            },
            {
              "VENDOR_NAME": "LONG BEACH TOWNSHIP"
            },
            {
              "VENDOR_NAME": "LONGVIEW OF AMERICA"
            },
            {
              "VENDOR_NAME": "LONGVIEW SOLUTIONS"
            },
            {
              "VENDOR_NAME": "LONGWOOD GARDENS"
            },
            {
              "VENDOR_NAME": "LOOMIS RELIEF FUND"
            },
            {
              "VENDOR_NAME": "LORETTA H. YIN"
            },
            {
              "VENDOR_NAME": "LORETTA M. GOLDWEIN"
            },
            {
              "VENDOR_NAME": "LORI BRUCE"
            },
            {
              "VENDOR_NAME": "LORILLARD"
            },
            {
              "VENDOR_NAME": "LORMAN EDUCATION SERVICES"
            },
            {
              "VENDOR_NAME": "LORRAINE GAVENDA"
            },
            {
              "VENDOR_NAME": "LORRAINE RATTIGAN"
            },
            {
              "VENDOR_NAME": "LOU REDA FURNITURE"
            },
            {
              "VENDOR_NAME": "LOUIS C. VOGT"
            },
            {
              "VENDOR_NAME": "LOUIS DREYFUS"
            },
            {
              "VENDOR_NAME": "LOUIS DREYFUS CITRUS"
            },
            {
              "VENDOR_NAME": "LOUIS J.VITIELLO"
            },
            {
              "VENDOR_NAME": "LOUIS VAIRO"
            },
            {
              "VENDOR_NAME": "LOUIS WESTBROOK"
            },
            {
              "VENDOR_NAME": "LOUISA FOULKE NEWLIN"
            },
            {
              "VENDOR_NAME": "LOUISA L.F.FOULKE HENZLER"
            },
            {
              "VENDOR_NAME": "LOUISA L.W.FOULKE"
            },
            {
              "VENDOR_NAME": "LOUISA L.W.FOULKE NON-QTIP FAMILY"
            },
            {
              "VENDOR_NAME": "LOUISA L.W.FOULKE/QTIP GST EXEMPT T"
            },
            {
              "VENDOR_NAME": "LOUISE F NOLEN"
            },
            {
              "VENDOR_NAME": "LOUISIANA DEPT.OF REVENUE & TAXATIO"
            },
            {
              "VENDOR_NAME": "LOVE THE CHILDREN"
            },
            {
              "VENDOR_NAME": "Loveland Distrib"
            },
            {
              "VENDOR_NAME": "Loveland Distributors"
            },
            {
              "VENDOR_NAME": "LOWER BUCKS CO MUN"
            },
            {
              "VENDOR_NAME": "LOWER CAPE HOCKEY ASSOC."
            },
            {
              "VENDOR_NAME": "LOWER CHICHESTER TWP"
            },
            {
              "VENDOR_NAME": "LOWER CHICHESTER WAGE TAX"
            },
            {
              "VENDOR_NAME": "LOWER FREDERICK TOWNSHIP"
            },
            {
              "VENDOR_NAME": "LOWER GWYNEDD TOWNSHIP"
            },
            {
              "VENDOR_NAME": "LOWER GWYNEDD TWP"
            },
            {
              "VENDOR_NAME": "LOWER MACUNGIE TOWNSHIP"
            },
            {
              "VENDOR_NAME": "LOWER MACUNGIE TWP"
            },
            {
              "VENDOR_NAME": "LOWER MERION TWP"
            },
            {
              "VENDOR_NAME": "LOWER MERION TWP TREASURER"
            },
            {
              "VENDOR_NAME": "LOWER NAZARETH TOWNSHIP"
            },
            {
              "VENDOR_NAME": "LOWER PERKIOMEN VALLEY REGIONAL"
            },
            {
              "VENDOR_NAME": "LOWER POTTSGROVE LOCAL TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "LOWER PROVIDENCE TOWNSHIP"
            },
            {
              "VENDOR_NAME": "LOWER PROVIDENCE TWSP"
            },
            {
              "VENDOR_NAME": "LOWER S HAMPTON AUTH"
            },
            {
              "VENDOR_NAME": "LOWER SALFORD TOWNSHIP"
            },
            {
              "VENDOR_NAME": "LOWER SOUTHAMPTON TOWNSHIP"
            },
            {
              "VENDOR_NAME": "LOWER SOUTHAMPTON TWP"
            },
            {
              "VENDOR_NAME": "LOWER TWP BUR FIRE SAFTEY"
            },
            {
              "VENDOR_NAME": "LOWE'S"
            },
            {
              "VENDOR_NAME": "LOWE'S COMPANIES INC."
            },
            {
              "VENDOR_NAME": "LOWE'S HOME CENTERS INC"
            },
            {
              "VENDOR_NAME": "LOZIER CORPORATION"
            },
            {
              "VENDOR_NAME": "LSI GRAPHIC SOLUTIONS PLUS"
            },
            {
              "VENDOR_NAME": "LSQ FUNDING GROUP"
            },
            {
              "VENDOR_NAME": "LUCE"
            },
            {
              "VENDOR_NAME": "LUCKNOW HIGHSPIRE TERM - ACCRUAL"
            },
            {
              "VENDOR_NAME": "LUCKNOW HIGHSPIRE TERMINALS CO."
            },
            {
              "VENDOR_NAME": "LUCY M BORGESE"
            },
            {
              "VENDOR_NAME": "LUIS RENTAS"
            },
            {
              "VENDOR_NAME": "LUISA MARTINEZ"
            },
            {
              "VENDOR_NAME": "LUKOIL PAN AMERICAS"
            },
            {
              "VENDOR_NAME": "LUMBERTON TOWNSHIP"
            },
            {
              "VENDOR_NAME": "LUMINARY SERIES"
            },
            {
              "VENDOR_NAME": "LUMS POND STATE PARK"
            },
            {
              "VENDOR_NAME": "LUTRON SHADING SOLUTIONS BY VIMCO"
            },
            {
              "VENDOR_NAME": "LVNV"
            },
            {
              "VENDOR_NAME": "LVNV FUNDING"
            },
            {
              "VENDOR_NAME": "LVNV FUNDING LLC"
            },
            {
              "VENDOR_NAME": "LXE INC."
            },
            {
              "VENDOR_NAME": "LYNCH/MARTINEZ"
            },
            {
              "VENDOR_NAME": "LYNDA JONES"
            },
            {
              "VENDOR_NAME": "LYNN HOCHBERG"
            },
            {
              "VENDOR_NAME": "LYNN PALMIERI"
            },
            {
              "VENDOR_NAME": "LYNNE CONLAN"
            },
            {
              "VENDOR_NAME": "LYNNHAVEN LANDING APTS."
            },
            {
              "VENDOR_NAME": "LYONS AND HOHL"
            },
            {
              "VENDOR_NAME": "LYONS DOUGHTY & VEIDHUIS"
            },
            {
              "VENDOR_NAME": "LYONS DOUGHTY & VELDHUIS"
            },
            {
              "VENDOR_NAME": "LYONS DOUGHTY AND VELDHUIS"
            },
            {
              "VENDOR_NAME": "LYONS DOUGHTY VELDHUIS"
            },
            {
              "VENDOR_NAME": "LYONS DOUGHTY&VELDHUIS"
            },
            {
              "VENDOR_NAME": "LYON'S TOWING INC."
            },
            {
              "VENDOR_NAME": "LYONS"
            },
            {
              "VENDOR_NAME": "LYONS"
            },
            {
              "VENDOR_NAME": "LYONS"
            },
            {
              "VENDOR_NAME": "LYONS"
            },
            {
              "VENDOR_NAME": "LYONS"
            },
            {
              "VENDOR_NAME": "LYONS"
            },
            {
              "VENDOR_NAME": "LYONS"
            },
            {
              "VENDOR_NAME": "LYONS"
            },
            {
              "VENDOR_NAME": "LYONS"
            },
            {
              "VENDOR_NAME": "LYONS"
            },
            {
              "VENDOR_NAME": "LYONS"
            },
            {
              "VENDOR_NAME": "LYONS"
            },
            {
              "VENDOR_NAME": "LYONS"
            },
            {
              "VENDOR_NAME": "LYONS"
            },
            {
              "VENDOR_NAME": "M & L NEWS"
            },
            {
              "VENDOR_NAME": "M & L NEWS-OUTER MARKET"
            },
            {
              "VENDOR_NAME": "M & M CELLULAR INC"
            },
            {
              "VENDOR_NAME": "M & M DISPLAYS INC"
            },
            {
              "VENDOR_NAME": "M & M PRODUCTIONS"
            },
            {
              "VENDOR_NAME": "M & N INTERNATIONAL INC"
            },
            {
              "VENDOR_NAME": "M & S MAINTENANCE SERVICE"
            },
            {
              "VENDOR_NAME": "M & T BANK"
            },
            {
              "VENDOR_NAME": "M B I GLUCKSHAW"
            },
            {
              "VENDOR_NAME": "M C F ASSOCIATES"
            },
            {
              "VENDOR_NAME": "M E A LTD"
            },
            {
              "VENDOR_NAME": "M PRICE DISTRIB"
            },
            {
              "VENDOR_NAME": "M&M DEVELOPMENT"
            },
            {
              "VENDOR_NAME": "M. A.B. PAINTS"
            },
            {
              "VENDOR_NAME": "M. JOSEPH"
            },
            {
              "VENDOR_NAME": "M. JOSEPH"
            },
            {
              "VENDOR_NAME": "M.A.B PAINTS"
            },
            {
              "VENDOR_NAME": "M.A.B. PAINTS"
            },
            {
              "VENDOR_NAME": "M.C.A.T.O"
            },
            {
              "VENDOR_NAME": "M.E.L.L."
            },
            {
              "VENDOR_NAME": "M.J. REIDER ASSOCIATES"
            },
            {
              "VENDOR_NAME": "M.L.RUBERTON CONSTRUCTION CO."
            },
            {
              "VENDOR_NAME": "M.LEE SMITH PUBLISHERS"
            },
            {
              "VENDOR_NAME": "M.T. SOLOMON & ASSOCIATES"
            },
            {
              "VENDOR_NAME": "M.T.Y.B."
            },
            {
              "VENDOR_NAME": "MA YOUNG C/O CITIZEN'S BANK"
            },
            {
              "VENDOR_NAME": "MAB WASTE SYSTEMS"
            },
            {
              "VENDOR_NAME": "MACDADE COMMERCIAL LAUNDRY"
            },
            {
              "VENDOR_NAME": "MACE CONSULTING ENGINEERS PC"
            },
            {
              "VENDOR_NAME": "MACLENS AUTO BODY &RADIATOR CO"
            },
            {
              "VENDOR_NAME": "MACNEILL SERVICES"
            },
            {
              "VENDOR_NAME": "MACUNGIE VOLUNTEER FIRE CO.#1"
            },
            {
              "VENDOR_NAME": "MADELINE JOSSE ROYSTON"
            },
            {
              "VENDOR_NAME": "MAGEE REHAB HOSPITAL"
            },
            {
              "VENDOR_NAME": "MAGELLAN MIDSTR"
            },
            {
              "VENDOR_NAME": "MAGELLAN PROFESSIONAL"
            },
            {
              "VENDOR_NAME": "MAGELLEN TERMINAL HOLDINGS"
            },
            {
              "VENDOR_NAME": "MAGESTERIAL DISTRICT NO. 07-1-12"
            },
            {
              "VENDOR_NAME": "MAGGIANO'S LITTLE ITALY"
            },
            {
              "VENDOR_NAME": "MAGIC"
            },
            {
              "VENDOR_NAME": "MAGICAL ENTERPRISES"
            },
            {
              "VENDOR_NAME": "MAGISTERIAL DIST. 07-01-09"
            },
            {
              "VENDOR_NAME": "MAGISTERIAL DIST. 15-3-07"
            },
            {
              "VENDOR_NAME": "MAGISTERIAL DISTRICT # 07-2-07"
            },
            {
              "VENDOR_NAME": "MAGISTERIAL DISTRICT 15-3-01"
            },
            {
              "VENDOR_NAME": "MAGISTERIAL DISTRICT 38-1-21"
            },
            {
              "VENDOR_NAME": "MAGISTERIAL DISTRICT 38-1-28"
            },
            {
              "VENDOR_NAME": "MAGISTERIAL DISTRICT NO 15-4-02"
            },
            {
              "VENDOR_NAME": "MAGISTERIAL DISTRICT NO 32-1-32"
            },
            {
              "VENDOR_NAME": "MAGISTERIAL DISTRICT NO. 07-1-01"
            },
            {
              "VENDOR_NAME": "MAGISTERIAL DISTRICT NO. 32-1-25"
            },
            {
              "VENDOR_NAME": "MAGISTERIAL DISTRICT NO. 32-1-29"
            },
            {
              "VENDOR_NAME": "MAGISTERIAL DISTRICT NO. 32-2-51"
            },
            {
              "VENDOR_NAME": "MAGISTERIAL DISTRICT NO.07-1-04"
            },
            {
              "VENDOR_NAME": "MAGISTERIAL DISTRICT NUMBER 38-1-19"
            },
            {
              "VENDOR_NAME": "MAGISTERIAL DISTRICT OFFICE 38-1-01"
            },
            {
              "VENDOR_NAME": "MAGNOLIA FIRE COMPANY"
            },
            {
              "VENDOR_NAME": "MAHONEY DESIGN & COMMUNICATIONS INC"
            },
            {
              "VENDOR_NAME": "MAIDA ENGINEERING INC."
            },
            {
              "VENDOR_NAME": "MAIN LINE EMBROIDERY & DESIGN"
            },
            {
              "VENDOR_NAME": "MAIN LINE TODAY"
            },
            {
              "VENDOR_NAME": "MAIN STREET HAMMONTON"
            },
            {
              "VENDOR_NAME": "MAINLAND HS AFTER PROM"
            },
            {
              "VENDOR_NAME": "MAINSTAY ENGINEERING GROUP"
            },
            {
              "VENDOR_NAME": "MAJEK FIRE PROTECTION"
            },
            {
              "VENDOR_NAME": "MAJESTIC ROSE CORP."
            },
            {
              "VENDOR_NAME": "MAJORY L. FINLEY"
            },
            {
              "VENDOR_NAME": "MAKE A WISH FOUNDATION"
            },
            {
              "VENDOR_NAME": "MALCOLM JAMES"
            },
            {
              "VENDOR_NAME": "MALCOLM PIRNIE"
            },
            {
              "VENDOR_NAME": "MALI"
            },
            {
              "VENDOR_NAME": "MALTZ SALES COMPANY"
            },
            {
              "VENDOR_NAME": "MALVERN BOROUGH"
            },
            {
              "VENDOR_NAME": "MALVERN PREP B.A.S.H."
            },
            {
              "VENDOR_NAME": "MALVERN RETREAT HOUSE"
            },
            {
              "VENDOR_NAME": "MALVERN SEWER FUND"
            },
            {
              "VENDOR_NAME": "MALVERN SHOPPINGCTR ASSOC"
            },
            {
              "VENDOR_NAME": "MALVERN WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "MAN FINANCIAL"
            },
            {
              "VENDOR_NAME": "MANAGEMENT DEVELOPMENT SOLUTIONS"
            },
            {
              "VENDOR_NAME": "MANAHAWKIN SHELL SERVICE CENTER"
            },
            {
              "VENDOR_NAME": "MANALAPAN MAYOR'S CHARITY BALL"
            },
            {
              "VENDOR_NAME": "MANALAPAN TOWNSHIP"
            },
            {
              "VENDOR_NAME": "MANALAPAN VIOLATIONS BUREAU"
            },
            {
              "VENDOR_NAME": "MANCHESTER FIRST AID &"
            },
            {
              "VENDOR_NAME": "MANCHESTER HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "MANCHESTER TOWNSHIP"
            },
            {
              "VENDOR_NAME": "MANCHESTER TOWNSHIP VETERANS"
            },
            {
              "VENDOR_NAME": "MANCHESTER TWP DEPT OF UTILITIES"
            },
            {
              "VENDOR_NAME": "MANDY LAIN"
            },
            {
              "VENDOR_NAME": "MANFREDI"
            },
            {
              "VENDOR_NAME": "MANFREDI"
            },
            {
              "VENDOR_NAME": "MANGES MARKETING SERVICES"
            },
            {
              "VENDOR_NAME": "MAN-HOW INC."
            },
            {
              "VENDOR_NAME": "MANITOWOC OVENS &"
            },
            {
              "VENDOR_NAME": "MANKO"
            },
            {
              "VENDOR_NAME": "MANN BRACKEN"
            },
            {
              "VENDOR_NAME": "MANN BRACKEN LLC"
            },
            {
              "VENDOR_NAME": "MANN BRACKEN LLP"
            },
            {
              "VENDOR_NAME": "MANNA"
            },
            {
              "VENDOR_NAME": "MANON LAUDERDALE"
            },
            {
              "VENDOR_NAME": "MANSFIELD OIL CO"
            },
            {
              "VENDOR_NAME": "MANSION PARK INC"
            },
            {
              "VENDOR_NAME": "MANTUA TOWNSHIP"
            },
            {
              "VENDOR_NAME": "MANTUA TOWNSHIP FIRE DISTRICT"
            },
            {
              "VENDOR_NAME": "MANTUA TOWNSHIP M U A"
            },
            {
              "VENDOR_NAME": "MAPLE LEAF BAKERY"
            },
            {
              "VENDOR_NAME": "MAPLE SHADE BUSINESS ASSOCIATION"
            },
            {
              "VENDOR_NAME": "MAPLE SHADE FIRE DEPARTMENT"
            },
            {
              "VENDOR_NAME": "MAPLE-PLUMSTED LTD"
            },
            {
              "VENDOR_NAME": "MARAMOR CHOCOLATES"
            },
            {
              "VENDOR_NAME": "MARATHON ENGINEERING & ENVIROMENTAL"
            },
            {
              "VENDOR_NAME": "MARATHON PETROLEUM COMPANY"
            },
            {
              "VENDOR_NAME": "MARC MAIOLINO"
            },
            {
              "VENDOR_NAME": "MARC S.TREDANARI"
            },
            {
              "VENDOR_NAME": "MARCAL MANUFACTURING LLC"
            },
            {
              "VENDOR_NAME": "MARCEL MOREAU ASSOCIATES"
            },
            {
              "VENDOR_NAME": "MARCELENA EURE"
            },
            {
              "VENDOR_NAME": "MARCH OF DIMES"
            },
            {
              "VENDOR_NAME": "MARCH OF DIMES-SOUTHEAST"
            },
            {
              "VENDOR_NAME": "MARCHETTY MACHINERY"
            },
            {
              "VENDOR_NAME": "MARCO INCORPORATED"
            },
            {
              "VENDOR_NAME": "MARCUS HOOK FLORISTS"
            },
            {
              "VENDOR_NAME": "MARCY & PARTNERS"
            },
            {
              "VENDOR_NAME": "MARDINLY ENTERPRISES"
            },
            {
              "VENDOR_NAME": "MARG. M CONLIN REV LIV TR"
            },
            {
              "VENDOR_NAME": "MARGARET A. YOUNG"
            },
            {
              "VENDOR_NAME": "MARGARET A.JANNUZZIO"
            },
            {
              "VENDOR_NAME": "MARGARET COOPER"
            },
            {
              "VENDOR_NAME": "MARGARET CORTRIGHT ERVIN"
            },
            {
              "VENDOR_NAME": "MARGARET COUSLER"
            },
            {
              "VENDOR_NAME": "MARGARET LOWENBURG"
            },
            {
              "VENDOR_NAME": "MARGARET M MCHUGH"
            },
            {
              "VENDOR_NAME": "MARGARET R. WHITE"
            },
            {
              "VENDOR_NAME": "MARGARET W.HILDENBRAND"
            },
            {
              "VENDOR_NAME": "MARGARET YOUNG"
            },
            {
              "VENDOR_NAME": "MARGATE CITY LITTLE LEAGUE"
            },
            {
              "VENDOR_NAME": "MARGATE WATER AND SEWER UTILITIES"
            },
            {
              "VENDOR_NAME": "MARGOT ANNE GRANT"
            },
            {
              "VENDOR_NAME": "MARGUERITE M. BORDEN"
            },
            {
              "VENDOR_NAME": "MARGUERITE PARLIMENT"
            },
            {
              "VENDOR_NAME": "MARIA E.GONZALEZ"
            },
            {
              "VENDOR_NAME": "MARIA J DRISCOLL"
            },
            {
              "VENDOR_NAME": "MARIA N. MARINAKIS AND"
            },
            {
              "VENDOR_NAME": "MARIA PERRETA"
            },
            {
              "VENDOR_NAME": "MARIANI ENTERPRISES"
            },
            {
              "VENDOR_NAME": "MARIANNE PARKER"
            },
            {
              "VENDOR_NAME": "MARIE CHASE"
            },
            {
              "VENDOR_NAME": "MARIE MARLEY"
            },
            {
              "VENDOR_NAME": "MARIE MCCHESNEY"
            },
            {
              "VENDOR_NAME": "MARIE READ TUCKER"
            },
            {
              "VENDOR_NAME": "MARIELLYN ZEOCK"
            },
            {
              "VENDOR_NAME": "MARILYN CAMPBELL"
            },
            {
              "VENDOR_NAME": "MARINE TOYS FOR TOTS FOUNDATION"
            },
            {
              "VENDOR_NAME": "MARINER FINANCE"
            },
            {
              "VENDOR_NAME": "MARINER FINANCE OF VA"
            },
            {
              "VENDOR_NAME": "MARION NEAL"
            },
            {
              "VENDOR_NAME": "MARITIME INSTITUTE OF TECHNOLOGY"
            },
            {
              "VENDOR_NAME": "MARJORIE W. SAMERO"
            },
            {
              "VENDOR_NAME": "MARK A. ELZEY & ALICIA M. ELZEY"
            },
            {
              "VENDOR_NAME": "MARK CIMINO"
            },
            {
              "VENDOR_NAME": "MARK E.TANDARICH"
            },
            {
              "VENDOR_NAME": "MARK ELECTRIC"
            },
            {
              "VENDOR_NAME": "MARK FIELDS"
            },
            {
              "VENDOR_NAME": "MARK G. HIGGINS"
            },
            {
              "VENDOR_NAME": "MARK G.L.FERGUSON"
            },
            {
              "VENDOR_NAME": "MARK G.L.FERGUSON"
            },
            {
              "VENDOR_NAME": "MARK GAUDIOSI"
            },
            {
              "VENDOR_NAME": "MARK INVESTMENT MID ATLANTIC INC"
            },
            {
              "VENDOR_NAME": "MARK J.STREATER"
            },
            {
              "VENDOR_NAME": "MARK KITRICK"
            },
            {
              "VENDOR_NAME": "MARK LOTITA"
            },
            {
              "VENDOR_NAME": "MARK MCCLURE"
            },
            {
              "VENDOR_NAME": "MARK MORGERA"
            },
            {
              "VENDOR_NAME": "MARK N. SUPRENANT"
            },
            {
              "VENDOR_NAME": "MARK N.SUPRENANT"
            },
            {
              "VENDOR_NAME": "MARK NEAL"
            },
            {
              "VENDOR_NAME": "MARK PAINTER"
            },
            {
              "VENDOR_NAME": "MARK RAMBO - CHRIS NOEDER"
            },
            {
              "VENDOR_NAME": "MARK ROGERS"
            },
            {
              "VENDOR_NAME": "MARK S BOWEN"
            },
            {
              "VENDOR_NAME": "MARK S. GERTEL ATTORNEY"
            },
            {
              "VENDOR_NAME": "MARK SAMUELIAN"
            },
            {
              "VENDOR_NAME": "MARK SLATER"
            },
            {
              "VENDOR_NAME": "MARK STIEFEL"
            },
            {
              "VENDOR_NAME": "MARK STREATER"
            },
            {
              "VENDOR_NAME": "MARKEIM-CHALMERS APPRAISALS"
            },
            {
              "VENDOR_NAME": "MARKET POINT"
            },
            {
              "VENDOR_NAME": "MARKET SQUARE RETAIL CENTER LLC"
            },
            {
              "VENDOR_NAME": "MARKET SQUARE"
            },
            {
              "VENDOR_NAME": "MARKETING & SALES ESSENTIALS"
            },
            {
              "VENDOR_NAME": "MARKETING AND SALES ESSENTIALS"
            },
            {
              "VENDOR_NAME": "MARKOW FLORIST"
            },
            {
              "VENDOR_NAME": "MARKWARD GROUP"
            },
            {
              "VENDOR_NAME": "MARLAINE SMITH"
            },
            {
              "VENDOR_NAME": "MARLBORO BOYS & GIRLS CLUB"
            },
            {
              "VENDOR_NAME": "MARLBORO MUNICIPAL COMPL"
            },
            {
              "VENDOR_NAME": "MARLBORO TOWNSHIP"
            },
            {
              "VENDOR_NAME": "MARLBORO TWP FOP LODGE 15"
            },
            {
              "VENDOR_NAME": "MARLEE CONTRACTORS"
            },
            {
              "VENDOR_NAME": "MARLOWE'S WE CARE COMPANY"
            },
            {
              "VENDOR_NAME": "MARLYN COLE"
            },
            {
              "VENDOR_NAME": "MARPLE NEWTOWN SCHOOL DISTRICT"
            },
            {
              "VENDOR_NAME": "MARRAZZO'S THRIFTWAY"
            },
            {
              "VENDOR_NAME": "MARRIOTT INTERNATIONAL"
            },
            {
              "VENDOR_NAME": "MARS CHOCOLATE NORTH AMERICA LLC"
            },
            {
              "VENDOR_NAME": "MARSH USA INC."
            },
            {
              "VENDOR_NAME": "MARSHALL GEOSCIENCE"
            },
            {
              "VENDOR_NAME": "MARTHA CHIOFFE"
            },
            {
              "VENDOR_NAME": "MARTIN & PHILLIPS"
            },
            {
              "VENDOR_NAME": "MARTIN BIENSTOCK"
            },
            {
              "VENDOR_NAME": "MARTIN BIENSTOCK CITY MARSHAL"
            },
            {
              "VENDOR_NAME": "MARTIN BIENSTOCK"
            },
            {
              "VENDOR_NAME": "MARTIN DUFFY"
            },
            {
              "VENDOR_NAME": "MARTIN DUFFY"
            },
            {
              "VENDOR_NAME": "MARTIN J MAGLIO"
            },
            {
              "VENDOR_NAME": "MARTIN J.MAGLIO"
            },
            {
              "VENDOR_NAME": "MARTIN J.PAULINA"
            },
            {
              "VENDOR_NAME": "MARTIN M.MAGER"
            },
            {
              "VENDOR_NAME": "MARTIN MAGLIO"
            },
            {
              "VENDOR_NAME": "MARTIN REIMAN"
            },
            {
              "VENDOR_NAME": "MARTIN'S PASTRY SHOPPE"
            },
            {
              "VENDOR_NAME": "MARTY EUBANK"
            },
            {
              "VENDOR_NAME": "MARTY G. EUBANK"
            },
            {
              "VENDOR_NAME": "MARVAL CHARACHTER APPEARANCE PROGRA"
            },
            {
              "VENDOR_NAME": "MARVIN H.ANDERESON"
            },
            {
              "VENDOR_NAME": "MARVIN H.ANDERSON"
            },
            {
              "VENDOR_NAME": "MARY & GERALD McMANUS"
            },
            {
              "VENDOR_NAME": "MARY ANN FINLEY BOND"
            },
            {
              "VENDOR_NAME": "MARY ANN SUPLEE"
            },
            {
              "VENDOR_NAME": "MARY BETH DUDLEY"
            },
            {
              "VENDOR_NAME": "MARY BETH LIMBURG"
            },
            {
              "VENDOR_NAME": "MARY C TOWNSEND"
            },
            {
              "VENDOR_NAME": "MARY C. FUNSTON TRUST"
            },
            {
              "VENDOR_NAME": "MARY CATHERINE BISHOP"
            },
            {
              "VENDOR_NAME": "MARY HANLEY"
            },
            {
              "VENDOR_NAME": "MARY K. LAUDENSLAGER"
            },
            {
              "VENDOR_NAME": "MARY LOU BRITTON"
            },
            {
              "VENDOR_NAME": "MARY SCHWARTZ"
            },
            {
              "VENDOR_NAME": "MARY SUE CANDIES"
            },
            {
              "VENDOR_NAME": "MARY WASHINGTON"
            },
            {
              "VENDOR_NAME": "MARY WASHINGTON HOSPITAL"
            },
            {
              "VENDOR_NAME": "MARY WOOD ERVIN"
            },
            {
              "VENDOR_NAME": "MARYANN FURLONG"
            },
            {
              "VENDOR_NAME": "MARYLAND & VIRGINIA MILK PRODUCERS"
            },
            {
              "VENDOR_NAME": "MARYLAND AREA ASSOCIATION OF"
            },
            {
              "VENDOR_NAME": "MARYLAND AUTO INSURANCE FUND"
            },
            {
              "VENDOR_NAME": "MARYLAND CHILD SUPPORT"
            },
            {
              "VENDOR_NAME": "MARYLAND DEPARTMENT OF THE ENVIROME"
            },
            {
              "VENDOR_NAME": "MARYLAND DEPT AGRICULTURE"
            },
            {
              "VENDOR_NAME": "MARYLAND DEPT OF THE"
            },
            {
              "VENDOR_NAME": "MARYLAND DEPT OF TRANS."
            },
            {
              "VENDOR_NAME": "MARYLAND DEPT.OF THE ENVIROMENT"
            },
            {
              "VENDOR_NAME": "MARYLAND MANAGEMENT COMPANY"
            },
            {
              "VENDOR_NAME": "MARYLAND NATION CAPITAL PARK"
            },
            {
              "VENDOR_NAME": "MARYLAND NATIONAL CAPITAL PARK &"
            },
            {
              "VENDOR_NAME": "MARYLAND RETAILERS ASSOCIATION"
            },
            {
              "VENDOR_NAME": "MARYLAND STATE HWY ADMIN."
            },
            {
              "VENDOR_NAME": "MASS MUTUAL FINANCIAL GROUP"
            },
            {
              "VENDOR_NAME": "MASS MUTUAL INSURANCE GROUP"
            },
            {
              "VENDOR_NAME": "MASS NEWS INC."
            },
            {
              "VENDOR_NAME": "MASSACHUSETTES DEPT.OF REVENUE"
            },
            {
              "VENDOR_NAME": "MASSACHUSETTS DEPT OF REV"
            },
            {
              "VENDOR_NAME": "MASSACHUSETTS DEPT OF REV."
            },
            {
              "VENDOR_NAME": "MASSACHUSETTS ENVIRONMENTAL"
            },
            {
              "VENDOR_NAME": "MASSACHUSETTS INSTITUTE OF TECHNOLO"
            },
            {
              "VENDOR_NAME": "MASSAPONAX HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "MASSIE FOR DELEGATE"
            },
            {
              "VENDOR_NAME": "MASSMUTUAL FINANCIAL GROUP"
            },
            {
              "VENDOR_NAME": "MASTER LOCATORS"
            },
            {
              "VENDOR_NAME": "MASTER-BILT PRODUCTS"
            },
            {
              "VENDOR_NAME": "MASTERS GROUP INC."
            },
            {
              "VENDOR_NAME": "MASTORIS"
            },
            {
              "VENDOR_NAME": "MASTROIENI AND ASSOCIATES"
            },
            {
              "VENDOR_NAME": "MATERIAL HANDLING SUPPLY CO"
            },
            {
              "VENDOR_NAME": "MATIS WARFIELD"
            },
            {
              "VENDOR_NAME": "MATRIX"
            },
            {
              "VENDOR_NAME": "MATRIX TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "MATS"
            },
            {
              "VENDOR_NAME": "MATT BAUMAN"
            },
            {
              "VENDOR_NAME": "MATT SHANAHAN"
            },
            {
              "VENDOR_NAME": "MATTHEW BRADY"
            },
            {
              "VENDOR_NAME": "MATTHEW EVANS"
            },
            {
              "VENDOR_NAME": "MATTHEW H. GLASS SR."
            },
            {
              "VENDOR_NAME": "MATTHEW H. KESLING"
            },
            {
              "VENDOR_NAME": "MATTHEW WINTERS"
            },
            {
              "VENDOR_NAME": "MATTLEMAN"
            },
            {
              "VENDOR_NAME": "MATTLEMAN"
            },
            {
              "VENDOR_NAME": "MATUS TECHNICAL SERVICES"
            },
            {
              "VENDOR_NAME": "MATZ LAND TRANSFER SERVICES"
            },
            {
              "VENDOR_NAME": "MAUGER & CO."
            },
            {
              "VENDOR_NAME": "MAUI CUP DIVISION OF LETICA CORP"
            },
            {
              "VENDOR_NAME": "MAUREEN MCFADDEN"
            },
            {
              "VENDOR_NAME": "MAUREEN MURPHY"
            },
            {
              "VENDOR_NAME": "MAURICE MUNICIPAL COURT"
            },
            {
              "VENDOR_NAME": "MAURICE P MINNO"
            },
            {
              "VENDOR_NAME": "MAURICE RIVER TOWNSHIP"
            },
            {
              "VENDOR_NAME": "MAX YOUR IMAGE"
            },
            {
              "VENDOR_NAME": "MAXFIELD CANDY COMPANY"
            },
            {
              "VENDOR_NAME": "MAXIMAGE LLC"
            },
            {
              "VENDOR_NAME": "MAXIMUS"
            },
            {
              "VENDOR_NAME": "MAX-INTERNATIONAL"
            },
            {
              "VENDOR_NAME": "MAXPITCH MEDIA"
            },
            {
              "VENDOR_NAME": "MAXWELL SYSTEMS"
            },
            {
              "VENDOR_NAME": "MAY DEPARTMENT STORES"
            },
            {
              "VENDOR_NAME": "MAY DEPT.STORE"
            },
            {
              "VENDOR_NAME": "MAYFAIR BUSINESS ASSOCIATION"
            },
            {
              "VENDOR_NAME": "MAYFAIR SALES"
            },
            {
              "VENDOR_NAME": "MAYFAIR SALES"
            },
            {
              "VENDOR_NAME": "MAYOR & COUNCIL OF MIDDLETOWN"
            },
            {
              "VENDOR_NAME": "MAYS LANDING FIRE DEPT."
            },
            {
              "VENDOR_NAME": "MAZZEO DELI CATERING"
            },
            {
              "VENDOR_NAME": "MB Refridgerated Grocery"
            },
            {
              "VENDOR_NAME": "MBA"
            },
            {
              "VENDOR_NAME": "MBIA MUNISERVICES"
            },
            {
              "VENDOR_NAME": "MBIA MUNISERVICES COMPANY"
            },
            {
              "VENDOR_NAME": "MC SIGN COMPANY"
            },
            {
              "VENDOR_NAME": "McAFEE"
            },
            {
              "VENDOR_NAME": "M-CAT MECHANICAL"
            },
            {
              "VENDOR_NAME": "MCATO"
            },
            {
              "VENDOR_NAME": "MCAULIFFE & ASSOCIATES"
            },
            {
              "VENDOR_NAME": "MCAULIFFE & SONS"
            },
            {
              "VENDOR_NAME": "MCBRIDE & ZIEGLER"
            },
            {
              "VENDOR_NAME": "MCCAIN FOODS"
            },
            {
              "VENDOR_NAME": "McCARTHY AND ASSOCIATES"
            },
            {
              "VENDOR_NAME": "McCARTHY AND SCHATZMAN"
            },
            {
              "VENDOR_NAME": "MCCARTHY MASONRY & CONCRETE INC"
            },
            {
              "VENDOR_NAME": "MCCARTHY TIRE SERVICE CO. INC"
            },
            {
              "VENDOR_NAME": "McCARTHY"
            },
            {
              "VENDOR_NAME": "McCAUSLAND LOCK SERVICE"
            },
            {
              "VENDOR_NAME": "MCCD/CLEAN WATER FUND"
            },
            {
              "VENDOR_NAME": "McCLAREN MACHINE & TOOL"
            },
            {
              "VENDOR_NAME": "MCCORMICK COMPANY"
            },
            {
              "VENDOR_NAME": "MCCRAW'S CANDIES"
            },
            {
              "VENDOR_NAME": "MCCRONE INC"
            },
            {
              "VENDOR_NAME": "MCCUSKER & OGBORNE # 324"
            },
            {
              "VENDOR_NAME": "McDERMOTT"
            },
            {
              "VENDOR_NAME": "MCDONALD SAFETY EQUIPMENT INC"
            },
            {
              "VENDOR_NAME": "McFADDEN'S RESTAURAN T & SALOON"
            },
            {
              "VENDOR_NAME": "MCFARMER"
            },
            {
              "VENDOR_NAME": "MCGINLEY'S GARDEN CENTER"
            },
            {
              "VENDOR_NAME": "MCGRATH CONSTRUCTION"
            },
            {
              "VENDOR_NAME": "MCGRAW-HILL INC"
            },
            {
              "VENDOR_NAME": "MCGUIRE SERVICES"
            },
            {
              "VENDOR_NAME": "McGUIRE WOODS LLP"
            },
            {
              "VENDOR_NAME": "MCI"
            },
            {
              "VENDOR_NAME": "MCI COMM SERVICE"
            },
            {
              "VENDOR_NAME": "MCI TELECOMMUNICATIONS"
            },
            {
              "VENDOR_NAME": "MCI WORLCOM CONFERENCING"
            },
            {
              "VENDOR_NAME": "MCI WORLDCOM"
            },
            {
              "VENDOR_NAME": "McINTIRE SCHOOL OF COMMERCE"
            },
            {
              "VENDOR_NAME": "MCINTOSH INNS"
            },
            {
              "VENDOR_NAME": "MCKINLEY BLACKSMITH INC"
            },
            {
              "VENDOR_NAME": "MCLANE"
            },
            {
              "VENDOR_NAME": "MCLANE MIDATLANTIC"
            },
            {
              "VENDOR_NAME": "MCLANE MID-ATLANTIC"
            },
            {
              "VENDOR_NAME": "MCLANE SUNEAST"
            },
            {
              "VENDOR_NAME": "MCLEAN PACKAGING"
            },
            {
              "VENDOR_NAME": "MCLEAN PACKAGING CORP"
            },
            {
              "VENDOR_NAME": "McMAHON ASSOCIATES"
            },
            {
              "VENDOR_NAME": "MCMASTER-CARR SUPPLY CO"
            },
            {
              "VENDOR_NAME": "McMILLAN/DOOLITTLE"
            },
            {
              "VENDOR_NAME": "MCNAIRN PACKAGING"
            },
            {
              "VENDOR_NAME": "MCNEIL NUTRITIONALS INC."
            },
            {
              "VENDOR_NAME": "MCOMBER & MCOMBER"
            },
            {
              "VENDOR_NAME": "MCT NEWS"
            },
            {
              "VENDOR_NAME": "MCV PHYSICANS"
            },
            {
              "VENDOR_NAME": "MCV PHYSICIANS"
            },
            {
              "VENDOR_NAME": "MD DEPT HEALTH"
            },
            {
              "VENDOR_NAME": "MD DEPT OF AGRICULTURE"
            },
            {
              "VENDOR_NAME": "MD LANDSCAPE MGMT."
            },
            {
              "VENDOR_NAME": "MD UNEMPLOYMENT INS FUND"
            },
            {
              "VENDOR_NAME": "MD UNEMPLOYMENT INS FUND - ACCRUAL"
            },
            {
              "VENDOR_NAME": "MDA LOCK-UP"
            },
            {
              "VENDOR_NAME": "MD-AMERICAN WATER CO"
            },
            {
              "VENDOR_NAME": "MDE CLEAN AIR FUND"
            },
            {
              "VENDOR_NAME": "MEADOWBROOK HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "MECHANICAL SERVANTS"
            },
            {
              "VENDOR_NAME": "MECHELLE COX"
            },
            {
              "VENDOR_NAME": "MED CTR PHARMACY INFUSION SER"
            },
            {
              "VENDOR_NAME": "MEDCO HEALTH SOLUTIONS"
            },
            {
              "VENDOR_NAME": "MEDFARE INC"
            },
            {
              "VENDOR_NAME": "MEDIA BORO WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "MEDIA CAMPING CENTER"
            },
            {
              "VENDOR_NAME": "MEDIA FIRE CO. #1"
            },
            {
              "VENDOR_NAME": "MEDIA FLORIST INC"
            },
            {
              "VENDOR_NAME": "MEDIA PARTNERS CORPORATION"
            },
            {
              "VENDOR_NAME": "MEDIA TWO"
            },
            {
              "VENDOR_NAME": "MEDICAL IMAGING OF FREDERICKSBURG"
            },
            {
              "VENDOR_NAME": "MEDICAL LABORATORIES OF VA."
            },
            {
              "VENDOR_NAME": "MEDLAB LOGISTICS"
            },
            {
              "VENDOR_NAME": "MEGAN PIERCE FUND"
            },
            {
              "VENDOR_NAME": "MEGARENTS"
            },
            {
              "VENDOR_NAME": "MEI CATERING"
            },
            {
              "VENDOR_NAME": "MELANOMA INTERNATIONAL FOUNDATION"
            },
            {
              "VENDOR_NAME": "MELISSA MULLIN"
            },
            {
              "VENDOR_NAME": "MELISSA THOMAS"
            },
            {
              "VENDOR_NAME": "MELMARK"
            },
            {
              "VENDOR_NAME": "MELTZER"
            },
            {
              "VENDOR_NAME": "MEMBER ONE FEDERAL CREDIT UNION"
            },
            {
              "VENDOR_NAME": "MEMORIAL SLOAN-KETTERING"
            },
            {
              "VENDOR_NAME": "MEMORIES CATERING"
            },
            {
              "VENDOR_NAME": "MENDENHALL INN"
            },
            {
              "VENDOR_NAME": "MENLO ENGINEERING ASSOCIATES INC"
            },
            {
              "VENDOR_NAME": "MEN'S HEALTH MAGAZINE"
            },
            {
              "VENDOR_NAME": "MENTOR 4 INC."
            },
            {
              "VENDOR_NAME": "MERCER COUNTY FOP # 96"
            },
            {
              "VENDOR_NAME": "MERCER COUNTY PARK COMMISSION"
            },
            {
              "VENDOR_NAME": "MERCER JUNIOR-SENIOR HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "MERCER REGIONAL CHAMBER OF COMMERCE"
            },
            {
              "VENDOR_NAME": "MERCHANDISING CORP.OF AMERICA"
            },
            {
              "VENDOR_NAME": "MERCHANDISING CORPORATION OF AMERIC"
            },
            {
              "VENDOR_NAME": "MERCHANT RETAIL SUPPLIES"
            },
            {
              "VENDOR_NAME": "MERCHANTS EXPRESS MONEY ORDER CO."
            },
            {
              "VENDOR_NAME": "MERCHANTVILLE WATER COM"
            },
            {
              "VENDOR_NAME": "MERCY HEALTH SERVICES"
            },
            {
              "VENDOR_NAME": "MERICLE LOGISTICS"
            },
            {
              "VENDOR_NAME": "MERIDIAN FOUNDATION"
            },
            {
              "VENDOR_NAME": "MERION ART & REPRO CENTER"
            },
            {
              "VENDOR_NAME": "MERRIMAN INVESTMENTS"
            },
            {
              "VENDOR_NAME": "MESH CONSULTING"
            },
            {
              "VENDOR_NAME": "METCOM"
            },
            {
              "VENDOR_NAME": "METECNO-USA"
            },
            {
              "VENDOR_NAME": "MET-ED"
            },
            {
              "VENDOR_NAME": "METRO GROUP"
            },
            {
              "VENDOR_NAME": "METRO NETWORKS INC."
            },
            {
              "VENDOR_NAME": "METRO NETWORKS"
            },
            {
              "VENDOR_NAME": "METRO WASTE"
            },
            {
              "VENDOR_NAME": "METRO WIRE ROPE CORP."
            },
            {
              "VENDOR_NAME": "METRO WOOD PRODUCTS"
            },
            {
              "VENDOR_NAME": "METROCORP"
            },
            {
              "VENDOR_NAME": "METROPLEX"
            },
            {
              "VENDOR_NAME": "METROPOLITAN AMBULANCE"
            },
            {
              "VENDOR_NAME": "METROPOLITAN COMMUNICATIONS"
            },
            {
              "VENDOR_NAME": "METROPOLITAN MANAGEMENT CORP."
            },
            {
              "VENDOR_NAME": "MEYERS CHARITABLE REMAINDER TRUST"
            },
            {
              "VENDOR_NAME": "MEYERS TRUST UNDER WILL"
            },
            {
              "VENDOR_NAME": "M-G ASSOCIATES L.P."
            },
            {
              "VENDOR_NAME": "MG MILLER VALUATIONS INC"
            },
            {
              "VENDOR_NAME": "MIA GIBSON"
            },
            {
              "VENDOR_NAME": "MICHAEL & TRACY DOCKMAN"
            },
            {
              "VENDOR_NAME": "MICHAEL ANTHONY GRAPHIC"
            },
            {
              "VENDOR_NAME": "MICHAEL ARKFELD"
            },
            {
              "VENDOR_NAME": "MICHAEL B JOSEPH"
            },
            {
              "VENDOR_NAME": "MICHAEL B.HAENER"
            },
            {
              "VENDOR_NAME": "MICHAEL B.JOSEPH"
            },
            {
              "VENDOR_NAME": "MICHAEL B.KAPLAN"
            },
            {
              "VENDOR_NAME": "MICHAEL CONLAN"
            },
            {
              "VENDOR_NAME": "MICHAEL COOPER"
            },
            {
              "VENDOR_NAME": "MICHAEL DALL"
            },
            {
              "VENDOR_NAME": "MICHAEL DOMENA"
            },
            {
              "VENDOR_NAME": "MICHAEL DWYER"
            },
            {
              "VENDOR_NAME": "MICHAEL E. MOFFETT"
            },
            {
              "VENDOR_NAME": "MICHAEL F AND"
            },
            {
              "VENDOR_NAME": "MICHAEL F WOERNER"
            },
            {
              "VENDOR_NAME": "MICHAEL FOGELMAN"
            },
            {
              "VENDOR_NAME": "MICHAEL FOODS"
            },
            {
              "VENDOR_NAME": "MICHAEL G. JOHNS"
            },
            {
              "VENDOR_NAME": "MICHAEL GALLAGHER"
            },
            {
              "VENDOR_NAME": "MICHAEL GREEN"
            },
            {
              "VENDOR_NAME": "MICHAEL HANLEY"
            },
            {
              "VENDOR_NAME": "MICHAEL HOLMES"
            },
            {
              "VENDOR_NAME": "MICHAEL HORNFECK"
            },
            {
              "VENDOR_NAME": "MICHAEL J .ECKHARDT"
            },
            {
              "VENDOR_NAME": "MICHAEL J WASHKO"
            },
            {
              "VENDOR_NAME": "MICHAEL J. MCKEOWN"
            },
            {
              "VENDOR_NAME": "MICHAEL J.BARTH COMPANY"
            },
            {
              "VENDOR_NAME": "MICHAEL J.BARTH"
            },
            {
              "VENDOR_NAME": "MICHAEL J.DAVIS"
            },
            {
              "VENDOR_NAME": "MICHAEL J.ECKENRODE"
            },
            {
              "VENDOR_NAME": "MICHAEL J.ECKHARDT"
            },
            {
              "VENDOR_NAME": "MICHAEL J.FITZGERALD"
            },
            {
              "VENDOR_NAME": "MICHAEL J.FOX FOUNDATION"
            },
            {
              "VENDOR_NAME": "MICHAEL JOSEPH"
            },
            {
              "VENDOR_NAME": "MICHAEL KAPLAN"
            },
            {
              "VENDOR_NAME": "MICHAEL KAPLAN"
            },
            {
              "VENDOR_NAME": "MICHAEL KENDRICK"
            },
            {
              "VENDOR_NAME": "MICHAEL KINZLY"
            },
            {
              "VENDOR_NAME": "MICHAEL M. DWYER"
            },
            {
              "VENDOR_NAME": "MICHAEL MAGGIO"
            },
            {
              "VENDOR_NAME": "MICHAEL MAKOWSKI"
            },
            {
              "VENDOR_NAME": "MICHAEL PROPST"
            },
            {
              "VENDOR_NAME": "MICHAEL REIGHTNEOUR"
            },
            {
              "VENDOR_NAME": "MICHAEL REIMER"
            },
            {
              "VENDOR_NAME": "MICHAEL RUBEL"
            },
            {
              "VENDOR_NAME": "MICHAEL SCAGLIONE"
            },
            {
              "VENDOR_NAME": "MICHAEL SHERLOCK"
            },
            {
              "VENDOR_NAME": "MICHAEL SOFFA"
            },
            {
              "VENDOR_NAME": "MICHAEL STIEF"
            },
            {
              "VENDOR_NAME": "MICHAEL SUBIN"
            },
            {
              "VENDOR_NAME": "MICHAEL VASSALLO"
            },
            {
              "VENDOR_NAME": "MICHAEL WEAVER"
            },
            {
              "VENDOR_NAME": "MICHAEL WELSH"
            },
            {
              "VENDOR_NAME": "MICHAEL WOERNER"
            },
            {
              "VENDOR_NAME": "MICHELE BROWN"
            },
            {
              "VENDOR_NAME": "MICHELE P.IANNETTA"
            },
            {
              "VENDOR_NAME": "MICHELLE E. JOSEPH"
            },
            {
              "VENDOR_NAME": "MICHELLE ERIN TRACY"
            },
            {
              "VENDOR_NAME": "MICHELLE L. JOHNSON"
            },
            {
              "VENDOR_NAME": "MICHELLE PEREZ"
            },
            {
              "VENDOR_NAME": "MICHELLE ROBINSON"
            },
            {
              "VENDOR_NAME": "MICHELLE RUPERT"
            },
            {
              "VENDOR_NAME": "MICHELLE SULLIVAN"
            },
            {
              "VENDOR_NAME": "MICHELLE VOLKWINE"
            },
            {
              "VENDOR_NAME": "MICHELLE WALSH"
            },
            {
              "VENDOR_NAME": "MICHELLE ZAPATA-HALEY"
            },
            {
              "VENDOR_NAME": "MICHIGAN SAFETY PRODUCTS OF FLINT"
            },
            {
              "VENDOR_NAME": "MICHIGAN STATE DISBURSEMENT UNIT"
            },
            {
              "VENDOR_NAME": "MICKEY VERNON MUSEUM"
            },
            {
              "VENDOR_NAME": "MICRO CENTER SALES CORPORATION"
            },
            {
              "VENDOR_NAME": "MICRO DAQ.COM"
            },
            {
              "VENDOR_NAME": "MICROBAC LABORATORIES"
            },
            {
              "VENDOR_NAME": "MICROEDGE"
            },
            {
              "VENDOR_NAME": "MICROLOG CORP."
            },
            {
              "VENDOR_NAME": "MICROMATION SCIENCES CORP"
            },
            {
              "VENDOR_NAME": "MICROSOFT LICENSING"
            },
            {
              "VENDOR_NAME": "MICROSOFT SERVICES"
            },
            {
              "VENDOR_NAME": "MICROSOFT TECHNET"
            },
            {
              "VENDOR_NAME": "MICROTRAIN TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "MICROVISION DEVELOPMENT"
            },
            {
              "VENDOR_NAME": "MID ATLANTIC COCA COLA VA"
            },
            {
              "VENDOR_NAME": "MID ATLANTIC CONSTRUCTION GROUP"
            },
            {
              "VENDOR_NAME": "MID ATLANTIC REALITY"
            },
            {
              "VENDOR_NAME": "MID ATLANTIC REALTY"
            },
            {
              "VENDOR_NAME": "MID ATLANTIC REALTY CO"
            },
            {
              "VENDOR_NAME": "MID STATES DISTRIBUTORS"
            },
            {
              "VENDOR_NAME": "MID-ATLANTIC CENTER FOR THE ARTS"
            },
            {
              "VENDOR_NAME": "MID-ATLANTIC COCA-COLA CO"
            },
            {
              "VENDOR_NAME": "MID-ATLANTIC MERCHANDISING"
            },
            {
              "VENDOR_NAME": "MID-ATLANTIC PETROLEUM"
            },
            {
              "VENDOR_NAME": "MID-ATLANTIC TRUCK CENTRE INC"
            },
            {
              "VENDOR_NAME": "MIDCO WASTE SYSTEMS"
            },
            {
              "VENDOR_NAME": "MIDCOM DATA TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "MIDDLE SMITHFIELD TOWNSHIP"
            },
            {
              "VENDOR_NAME": "MIDDLE TOWNSHIP ALARM PROGRAM"
            },
            {
              "VENDOR_NAME": "MIDDLE TWP"
            },
            {
              "VENDOR_NAME": "MIDDLE TWP AMBULANCE CORPS"
            },
            {
              "VENDOR_NAME": "MIDDLE TWP MUNICIPAL COURT"
            },
            {
              "VENDOR_NAME": "MIDDLE TWSP TAX COLLECTOR"
            },
            {
              "VENDOR_NAME": "MIDDLESEX COUNTY BOARD OF SOCIAL SE"
            },
            {
              "VENDOR_NAME": "MIDDLESEX COUNTY HEALTH DEPT."
            },
            {
              "VENDOR_NAME": "MIDDLESEX COUNTY PLANNING BOARD"
            },
            {
              "VENDOR_NAME": "MIDDLESEX COUNTY TREAS."
            },
            {
              "VENDOR_NAME": "MIDDLETOWN AUTO PARTS CO"
            },
            {
              "VENDOR_NAME": "MIDDLETOWN TOWNSHIP BUSINESS &"
            },
            {
              "VENDOR_NAME": "MIDDLETOWN TOWNSHIP REPUBLICAN COMM"
            },
            {
              "VENDOR_NAME": "MIDDLETOWN TRANSCRIPT"
            },
            {
              "VENDOR_NAME": "MIDDLETOWN TWP REPUBLICAN COMMITTEE"
            },
            {
              "VENDOR_NAME": "MIDDLETOWN TWP. MUNICIPAL BLDG."
            },
            {
              "VENDOR_NAME": "MIDDLETOWN TWP.SEWER AUTH"
            },
            {
              "VENDOR_NAME": "MIDLAND CREDIT MANAGEMENT"
            },
            {
              "VENDOR_NAME": "MIDLAND CREDIT MGT."
            },
            {
              "VENDOR_NAME": "MIDLAND FUNDING"
            },
            {
              "VENDOR_NAME": "MIDLAND FUNDING LLC"
            },
            {
              "VENDOR_NAME": "MIDLANTIC VENDING"
            },
            {
              "VENDOR_NAME": "MID-VIRGINIA TRADING POST"
            },
            {
              "VENDOR_NAME": "MIDWAY MECHANICAL CONTRACTORS"
            },
            {
              "VENDOR_NAME": "MIDWEST CAN COMPANY"
            },
            {
              "VENDOR_NAME": "MIECO INC"
            },
            {
              "VENDOR_NAME": "MIGHTY MAT LLC."
            },
            {
              "VENDOR_NAME": "MIHAIL I. EXADAKTILOS"
            },
            {
              "VENDOR_NAME": "MIKAL'S FUNERAL HOME"
            },
            {
              "VENDOR_NAME": "MIKE ALBERT LEASING INC"
            },
            {
              "VENDOR_NAME": "MIKE ALBERT LEASING"
            },
            {
              "VENDOR_NAME": "MIKE BILLINGS"
            },
            {
              "VENDOR_NAME": "MIKE BONNEY"
            },
            {
              "VENDOR_NAME": "MIKE CONDON CHILDREN EDUCATIONAL FU"
            },
            {
              "VENDOR_NAME": "MIKE DELP"
            },
            {
              "VENDOR_NAME": "MIKE DiNARDO"
            },
            {
              "VENDOR_NAME": "MIKE DUNN"
            },
            {
              "VENDOR_NAME": "MIKE EICHINGER"
            },
            {
              "VENDOR_NAME": "MIKE GALLAGHER"
            },
            {
              "VENDOR_NAME": "MIKE LATINO"
            },
            {
              "VENDOR_NAME": "MIKE NAJMOLA"
            },
            {
              "VENDOR_NAME": "MIKE POMEROY"
            },
            {
              "VENDOR_NAME": "MIKE REDEL"
            },
            {
              "VENDOR_NAME": "MIKE'S WHOLESALE BAIT CO"
            },
            {
              "VENDOR_NAME": "MILBERN BUILDERS ASSOC."
            },
            {
              "VENDOR_NAME": "MILDRED F WESNER"
            },
            {
              "VENDOR_NAME": "MILDRED GREBLE FINLEY TRUST II"
            },
            {
              "VENDOR_NAME": "MILEA TRUCK SALES CORP"
            },
            {
              "VENDOR_NAME": "MILES & STOCKBRIDGE"
            },
            {
              "VENDOR_NAME": "MILFORD CHRONICLE"
            },
            {
              "VENDOR_NAME": "MILFORD DIAGNOSTIC IMAGING"
            },
            {
              "VENDOR_NAME": "MILFORD TOWNSHIP"
            },
            {
              "VENDOR_NAME": "MILFORD TOWNSHIP EITO"
            },
            {
              "VENDOR_NAME": "MILFORD TOWNSHIP WATER AUTH."
            },
            {
              "VENDOR_NAME": "MILFORD TRUMBAUERSVILLE AREA"
            },
            {
              "VENDOR_NAME": "MILK PAYABLES VENDER-ACCRUAL"
            },
            {
              "VENDOR_NAME": "MILLENIUM CLEANING SERVICES"
            },
            {
              "VENDOR_NAME": "MILLENNIUM BROKERAGE SERVICES"
            },
            {
              "VENDOR_NAME": "MILLER EDGE"
            },
            {
              "VENDOR_NAME": "MILLER ENVIRONMENTAL GROUP"
            },
            {
              "VENDOR_NAME": "MILLER METALCRAFT INC."
            },
            {
              "VENDOR_NAME": "MILLER REALTY"
            },
            {
              "VENDOR_NAME": "MILLIKEN AND COMPANY"
            },
            {
              "VENDOR_NAME": "MILLSTONE CHARITY GALA INC."
            },
            {
              "VENDOR_NAME": "MILLVILLE ARMY AIR FIELD MUSEUM"
            },
            {
              "VENDOR_NAME": "MILLVILLE HISTORICAL SOCIETY"
            },
            {
              "VENDOR_NAME": "MILLVILLE MUNICIPAL COURT"
            },
            {
              "VENDOR_NAME": "MILLVILLE NATIONAL CAL RIPKEN"
            },
            {
              "VENDOR_NAME": "MILWAUKEE SIGN CO INC"
            },
            {
              "VENDOR_NAME": "MIMEO.com"
            },
            {
              "VENDOR_NAME": "MIMET USA LLC"
            },
            {
              "VENDOR_NAME": "MIMI VI"
            },
            {
              "VENDOR_NAME": "MIMOSA DEVELOPMENT"
            },
            {
              "VENDOR_NAME": "MIND BODY SPIRIT"
            },
            {
              "VENDOR_NAME": "MINISCALCO DEVELOPMENT"
            },
            {
              "VENDOR_NAME": "MINISCALCO DEVELOPMENT & MANAGEMENT"
            },
            {
              "VENDOR_NAME": "MINKUS & ASSOC."
            },
            {
              "VENDOR_NAME": "MINKUS & ASSOCIATES"
            },
            {
              "VENDOR_NAME": "MINNESOTA CHILD SUPPORT PAYMENT CTR"
            },
            {
              "VENDOR_NAME": "MINQUADALE FIRE CO."
            },
            {
              "VENDOR_NAME": "MINSI TRAILS COUNCIL"
            },
            {
              "VENDOR_NAME": "MINWEST"
            },
            {
              "VENDOR_NAME": "MIRIAM ROBERTS ERVIN"
            },
            {
              "VENDOR_NAME": "MISSA BAY"
            },
            {
              "VENDOR_NAME": "MISSA BAY CITRUS COOMNI/AMOROSO"
            },
            {
              "VENDOR_NAME": "MISSA BAY COFFEE"
            },
            {
              "VENDOR_NAME": "MISSA BAY JUICES"
            },
            {
              "VENDOR_NAME": "MISSA BAY SNACK CAKE"
            },
            {
              "VENDOR_NAME": "MISSA BAY"
            },
            {
              "VENDOR_NAME": "MISSA BAY"
            },
            {
              "VENDOR_NAME": "MISSA BAY"
            },
            {
              "VENDOR_NAME": "MISSABAY BREAKFAST PURCH"
            },
            {
              "VENDOR_NAME": "MISSABAY EXPRESS"
            },
            {
              "VENDOR_NAME": "MISSING LINK FENCE INC"
            },
            {
              "VENDOR_NAME": "MISSION KIDS CHILD ADVOCACY CENTER"
            },
            {
              "VENDOR_NAME": "MISSISSIPPI DEPT. OF HUMAN SERVICE"
            },
            {
              "VENDOR_NAME": "MISSISSIPPI STATE TAX COMMISSION"
            },
            {
              "VENDOR_NAME": "MISTER COOKIE FACE"
            },
            {
              "VENDOR_NAME": "MIT SLOAN MANAGEMENT REVIEW"
            },
            {
              "VENDOR_NAME": "MJ DISTRIBUTION"
            },
            {
              "VENDOR_NAME": "MKG ASSOCIATES LTD"
            },
            {
              "VENDOR_NAME": "MNJ DISTRIBUTING INC."
            },
            {
              "VENDOR_NAME": "MOAB OIL"
            },
            {
              "VENDOR_NAME": "MOAB OIL"
            },
            {
              "VENDOR_NAME": "MOBILE LIFTS"
            },
            {
              "VENDOR_NAME": "MOBILE MERCHANDISERS"
            },
            {
              "VENDOR_NAME": "MOBILE MERCHANDISERS INC"
            },
            {
              "VENDOR_NAME": "MOBILE MINI"
            },
            {
              "VENDOR_NAME": "MOBO SYSTEMS"
            },
            {
              "VENDOR_NAME": "MODERN CONTROLS"
            },
            {
              "VENDOR_NAME": "MODERN EQUIPMENT"
            },
            {
              "VENDOR_NAME": "MOHAMED SALEH"
            },
            {
              "VENDOR_NAME": "MOHAWK ASSOCIATES INC"
            },
            {
              "VENDOR_NAME": "MOHAWK RESOURCES"
            },
            {
              "VENDOR_NAME": "MOHAWK TILE AND MARBLE"
            },
            {
              "VENDOR_NAME": "MOI"
            },
            {
              "VENDOR_NAME": "MOLINARO TOOL AND DIE"
            },
            {
              "VENDOR_NAME": "MOLLY BAUER"
            },
            {
              "VENDOR_NAME": "MONARCH HUMAN RESOURCES"
            },
            {
              "VENDOR_NAME": "MONARCH STAFFING"
            },
            {
              "VENDOR_NAME": "MONDOSOFT"
            },
            {
              "VENDOR_NAME": "MONICA A.BELL"
            },
            {
              "VENDOR_NAME": "MONIKA MERRITT"
            },
            {
              "VENDOR_NAME": "MONIKERS"
            },
            {
              "VENDOR_NAME": "MONIN INC"
            },
            {
              "VENDOR_NAME": "MONITORING AUTOMATION SYSTEMS"
            },
            {
              "VENDOR_NAME": "MONMOUTH BEVERAGES INC"
            },
            {
              "VENDOR_NAME": "MONMOUTH CO SHERIFF'S OFFICE"
            },
            {
              "VENDOR_NAME": "MONMOUTH COUNTY"
            },
            {
              "VENDOR_NAME": "MONMOUTH COUNTY CIVIL PART"
            },
            {
              "VENDOR_NAME": "MONMOUTH COUNTY REGIONAL"
            },
            {
              "VENDOR_NAME": "MONMOUTH COUNTY SCP"
            },
            {
              "VENDOR_NAME": "MONMOUTH COUNTY SHERIFF"
            },
            {
              "VENDOR_NAME": "MONMOUTH COUNTY SHERIFF OFFICE"
            },
            {
              "VENDOR_NAME": "MONMOUTH COUNTY SHERIFFS OFFICE"
            },
            {
              "VENDOR_NAME": "MONMOUTH CTY.FRATERNAL ORDER POLICE"
            },
            {
              "VENDOR_NAME": "MONOGRAM INTERNATIONAL INC"
            },
            {
              "VENDOR_NAME": "MONRIDGE CONSTRUCTION INC"
            },
            {
              "VENDOR_NAME": "MONROE 33 DEVELOPERS"
            },
            {
              "VENDOR_NAME": "MONROE CENTER ASSOC LLC"
            },
            {
              "VENDOR_NAME": "MONROE CONSTRUCTION DEPARTMENT"
            },
            {
              "VENDOR_NAME": "MONROE COUNTY CONSERVATION DISTRICT"
            },
            {
              "VENDOR_NAME": "MONROE COUNTY MUNICIPAL WASTE"
            },
            {
              "VENDOR_NAME": "MONROE COUNTY SCU"
            },
            {
              "VENDOR_NAME": "MONROE COUNTY TAX CLAIM BUREAU"
            },
            {
              "VENDOR_NAME": "MONROE MUN UTIL AUTH"
            },
            {
              "VENDOR_NAME": "MONROE TOWNSHIP"
            },
            {
              "VENDOR_NAME": "MONROE TOWNSHIP-TAX COLLE"
            },
            {
              "VENDOR_NAME": "MONT CO & UPPER DUBLIN"
            },
            {
              "VENDOR_NAME": "MONT TWSP BOARD OF HEALTH"
            },
            {
              "VENDOR_NAME": "MONTEGO BAY"
            },
            {
              "VENDOR_NAME": "MONTEREY REFUSE SERVICE"
            },
            {
              "VENDOR_NAME": "MONTGOMERY & MCCRACKEN"
            },
            {
              "VENDOR_NAME": "MONTGOMERY CO.HEALTH DEPT."
            },
            {
              "VENDOR_NAME": "MONTGOMERY COUNTY"
            },
            {
              "VENDOR_NAME": "MONTGOMERY COUNTY CAMP CADET"
            },
            {
              "VENDOR_NAME": "MONTGOMERY COUNTY TAX CLAIM BUREAU"
            },
            {
              "VENDOR_NAME": "MONTGOMERY COUNTY TREASURER"
            },
            {
              "VENDOR_NAME": "MONTGOMERY EMS"
            },
            {
              "VENDOR_NAME": "MONTGOMERY NEWSPAPERS"
            },
            {
              "VENDOR_NAME": "MONTGOMERY SCHOOL"
            },
            {
              "VENDOR_NAME": "MONTGOMERY TOWNSHIP"
            },
            {
              "VENDOR_NAME": "MONTGOMERY TOWNSHIP HEALTH"
            },
            {
              "VENDOR_NAME": "MONTGOMERY TWP LOCAL TAX PA"
            },
            {
              "VENDOR_NAME": "MOODY DUNBAR"
            },
            {
              "VENDOR_NAME": "MOONBOUNCE ADVENTURES"
            },
            {
              "VENDOR_NAME": "MOONBOUNCE ADVENTURES"
            },
            {
              "VENDOR_NAME": "MOOR DISPOSAL SVC.INC."
            },
            {
              "VENDOR_NAME": "MOORE MEDICAL LLC"
            },
            {
              "VENDOR_NAME": "MOORE OUTDOOR REJUVENATION"
            },
            {
              "VENDOR_NAME": "MOORE'S ELECTRICAL & MECHANICAL"
            },
            {
              "VENDOR_NAME": "MOORE'S HOME"
            },
            {
              "VENDOR_NAME": "MOORESTOWN FIRE DIST NO 1"
            },
            {
              "VENDOR_NAME": "MOOSIE WRAPPERS"
            },
            {
              "VENDOR_NAME": "MORA FIRE EQUIPMENT CO"
            },
            {
              "VENDOR_NAME": "MORAL/WELFARE DEVELOPMENT FUND"
            },
            {
              "VENDOR_NAME": "MORGAN STANLEY"
            },
            {
              "VENDOR_NAME": "MORGAN STANLEY DEAN WITTER"
            },
            {
              "VENDOR_NAME": "MORGAN"
            },
            {
              "VENDOR_NAME": "MORNING CALL-OUTER MARKET"
            },
            {
              "VENDOR_NAME": "MORNING STAR PUBLICATIONS"
            },
            {
              "VENDOR_NAME": "MORNINGSTAR FOODS"
            },
            {
              "VENDOR_NAME": "MORNINGSTAR FOODS"
            },
            {
              "VENDOR_NAME": "MORRELL FAMILY"
            },
            {
              "VENDOR_NAME": "MORRILL MOBILE REPAIR"
            },
            {
              "VENDOR_NAME": "MORRIS & RITCHIE ASSO INC"
            },
            {
              "VENDOR_NAME": "MORRIS COUNTY PLANNING BOARD"
            },
            {
              "VENDOR_NAME": "MORRIS COUNTY SOIL CONSERVATION"
            },
            {
              "VENDOR_NAME": "MORRIS NATIONAL INC"
            },
            {
              "VENDOR_NAME": "MORRIS NATIONAL INC."
            },
            {
              "VENDOR_NAME": "MORRIS"
            },
            {
              "VENDOR_NAME": "MORRISON & TRIMBLE"
            },
            {
              "VENDOR_NAME": "MORRISVILLE MUNICIPAL AUT"
            },
            {
              "VENDOR_NAME": "MORTON SALT COMPANY"
            },
            {
              "VENDOR_NAME": "MOSELEY CORPORATION"
            },
            {
              "VENDOR_NAME": "MOSSIE WRAPPER"
            },
            {
              "VENDOR_NAME": "MOTHER PARKERS"
            },
            {
              "VENDOR_NAME": "MOTION INDUSTRIES"
            },
            {
              "VENDOR_NAME": "MOTIVA ENTERPRISES LLC"
            },
            {
              "VENDOR_NAME": "MOTIVA ENTERPRISES LLC-ACCRUAL"
            },
            {
              "VENDOR_NAME": "MOTOR VEHICLE ADMINISTRATION"
            },
            {
              "VENDOR_NAME": "MOULTON LADDER & SUPPLY CO."
            },
            {
              "VENDOR_NAME": "MOUNT HOLLY TWP"
            },
            {
              "VENDOR_NAME": "MOUNT HOLLY WATER CO"
            },
            {
              "VENDOR_NAME": "MOUNT LAUREL FIRE DIST"
            },
            {
              "VENDOR_NAME": "MOUNT LAUREL PLANNING BOARD"
            },
            {
              "VENDOR_NAME": "MOUNT LAUREL TOWNSHIP"
            },
            {
              "VENDOR_NAME": "MOUNT LAUREL TWP MUN CTR"
            },
            {
              "VENDOR_NAME": "MOUNT POCONO BOROUGH"
            },
            {
              "VENDOR_NAME": "MOUNTAIN CONCESSIONS"
            },
            {
              "VENDOR_NAME": "MOUNTAIN LAKE CORPORATION"
            },
            {
              "VENDOR_NAME": "MOUNTAINVIEW ASSOC. LLC"
            },
            {
              "VENDOR_NAME": "MOWBRAY FARMS"
            },
            {
              "VENDOR_NAME": "MOYER'S CLEANING SERVICES"
            },
            {
              "VENDOR_NAME": "MPSI SYSTEMS"
            },
            {
              "VENDOR_NAME": "MR DREW J SHANAHAN"
            },
            {
              "VENDOR_NAME": "MR SCOTT KENT"
            },
            {
              "VENDOR_NAME": "MR. LESLIE E. GOODMAN"
            },
            {
              "VENDOR_NAME": "MR. MIKI MODH"
            },
            {
              "VENDOR_NAME": "MR.ALAN DORMAN"
            },
            {
              "VENDOR_NAME": "MR.ASPHALT"
            },
            {
              "VENDOR_NAME": "MR.BILL KINDER"
            },
            {
              "VENDOR_NAME": "MR.GEORGE MAXWELL"
            },
            {
              "VENDOR_NAME": "MR.JINESH PATEL"
            },
            {
              "VENDOR_NAME": "MR.JOHN"
            },
            {
              "VENDOR_NAME": "MR.JOSEPH GAMBONE"
            },
            {
              "VENDOR_NAME": "MR.JOSEPH STASKEWICZ"
            },
            {
              "VENDOR_NAME": "MR.KENNETH HORODENSKI"
            },
            {
              "VENDOR_NAME": "MR.LARRY BIRNEY"
            },
            {
              "VENDOR_NAME": "MR.LOCK"
            },
            {
              "VENDOR_NAME": "MR.MICHAEL KENNON"
            },
            {
              "VENDOR_NAME": "MR.ROBERT PLATT"
            },
            {
              "VENDOR_NAME": "MR.VIMAL PATEL"
            },
            {
              "VENDOR_NAME": "MR.WILLIS HERR"
            },
            {
              "VENDOR_NAME": "MRC RECEIVABLES CORP"
            },
            {
              "VENDOR_NAME": "MRS FIELDS BRANDED RETAIL GROUP"
            },
            {
              "VENDOR_NAME": "MRS HELEN C BERENT"
            },
            {
              "VENDOR_NAME": "MRS MARIE LEITAO"
            },
            {
              "VENDOR_NAME": "MRT BASEBALL"
            },
            {
              "VENDOR_NAME": "MS LISA WOOD WRIGHT"
            },
            {
              "VENDOR_NAME": "MS. CAROL WYETH"
            },
            {
              "VENDOR_NAME": "MS.BARBARA CUDDHY"
            },
            {
              "VENDOR_NAME": "MS.BLYTHE McGARVIE"
            },
            {
              "VENDOR_NAME": "MS.JACQUELINE TUCKER"
            },
            {
              "VENDOR_NAME": "MS.MAURA M.PRIMUS"
            },
            {
              "VENDOR_NAME": "MS.WINNIE BERNAZER"
            },
            {
              "VENDOR_NAME": "MSA"
            },
            {
              "VENDOR_NAME": "MSC INDUSTRIAL SUPPLY CO.INC."
            },
            {
              "VENDOR_NAME": "MSGR BONNER HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "MSGR.BONNER HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "MSGR.BONNER ICE HOCKEY"
            },
            {
              "VENDOR_NAME": "MSP LIGHTING PRODUCTS"
            },
            {
              "VENDOR_NAME": "MT HOLLY MUNICIPAL UTIL AUTHORITY-M"
            },
            {
              "VENDOR_NAME": "MT LAUREL TWP"
            },
            {
              "VENDOR_NAME": "MT MISERY INC"
            },
            {
              "VENDOR_NAME": "MTBPA"
            },
            {
              "VENDOR_NAME": "MTEK"
            },
            {
              "VENDOR_NAME": "MTG ELECTRONICS & SIGNWORKS"
            },
            {
              "VENDOR_NAME": "MTM TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "MTMSA"
            },
            {
              "VENDOR_NAME": "MUDD"
            },
            {
              "VENDOR_NAME": "MUDD"
            },
            {
              "VENDOR_NAME": "MUHLENBERG LOCAL TAX"
            },
            {
              "VENDOR_NAME": "MUHLENBERG TOWNSHIP"
            },
            {
              "VENDOR_NAME": "MUHLENBERG TWP.AUTHORITY"
            },
            {
              "VENDOR_NAME": "MULLANEY"
            },
            {
              "VENDOR_NAME": "MULTI FLOW"
            },
            {
              "VENDOR_NAME": "MUNICIPAL COURT"
            },
            {
              "VENDOR_NAME": "MUNICIPAL COURT OF ABSECON CITY"
            },
            {
              "VENDOR_NAME": "MUNICIPAL COURT OF SEA ISLE CITY"
            },
            {
              "VENDOR_NAME": "MUNICIPAL COURT OF TWP.OF CINNAMINS"
            },
            {
              "VENDOR_NAME": "MUNICIPAL COURT OF VINELAND CITY"
            },
            {
              "VENDOR_NAME": "MUNICIPAL COURT OF WOODBURY HGTS"
            },
            {
              "VENDOR_NAME": "MUNICIPAL MAINTENANCE COMPANY"
            },
            {
              "VENDOR_NAME": "MUNICIPAL PUBLICATIONS"
            },
            {
              "VENDOR_NAME": "MUNISERVICES"
            },
            {
              "VENDOR_NAME": "MUREX NA Ltd"
            },
            {
              "VENDOR_NAME": "MURPHY FORD"
            },
            {
              "VENDOR_NAME": "MURPHY OIL GENER"
            },
            {
              "VENDOR_NAME": "MURPHY'S GARAGE"
            },
            {
              "VENDOR_NAME": "MURRAY HOWELL"
            },
            {
              "VENDOR_NAME": "MUSCULAR DYSTROPHY ASSOC."
            },
            {
              "VENDOR_NAME": "MUSCULAR DYSTROPHY ASSOCIATION"
            },
            {
              "VENDOR_NAME": "MUSKET CORPORATION"
            },
            {
              "VENDOR_NAME": "MUTUAL PRESS CLIPPING SERVICE"
            },
            {
              "VENDOR_NAME": "MUTZEL & WESNER LLP SAVINGS PLAN"
            },
            {
              "VENDOR_NAME": "MUZAK"
            },
            {
              "VENDOR_NAME": "MUZAK LLC"
            },
            {
              "VENDOR_NAME": "MVR'S INC."
            },
            {
              "VENDOR_NAME": "MWH HOUSE PHYSICIANS"
            },
            {
              "VENDOR_NAME": "MWT CORPORATION"
            },
            {
              "VENDOR_NAME": "MXI ENVIRONMENTAL SERVICES LLC"
            },
            {
              "VENDOR_NAME": "MY FAVORITE COMPANY"
            },
            {
              "VENDOR_NAME": "MYCO COMPANY"
            },
            {
              "VENDOR_NAME": "MyHo AltPayee Vendor"
            },
            {
              "VENDOR_NAME": "MYRA INEZ WHITAKER"
            },
            {
              "VENDOR_NAME": "N C CHILD SUPPORT CENTRAL COLL.(DOC"
            },
            {
              "VENDOR_NAME": "N J DEPARTMENT OF AGRICULTURE"
            },
            {
              "VENDOR_NAME": "N J STATE DEPT OF HEALTH"
            },
            {
              "VENDOR_NAME": "N.A.E FEDERAL CREDIT UNION"
            },
            {
              "VENDOR_NAME": "N2G DISTRIBUTING"
            },
            {
              "VENDOR_NAME": "NACS"
            },
            {
              "VENDOR_NAME": "NAES LAKEHURST"
            },
            {
              "VENDOR_NAME": "Name"
            },
            {
              "VENDOR_NAME": "NANCY BRUTSCHE"
            },
            {
              "VENDOR_NAME": "NANCY C.PAUL"
            },
            {
              "VENDOR_NAME": "NANCY DEMATTEO"
            },
            {
              "VENDOR_NAME": "NANCY FORMAN"
            },
            {
              "VENDOR_NAME": "NANCY KAUFMANN"
            },
            {
              "VENDOR_NAME": "NANCY LANE & FRANCIS MCGOWEN"
            },
            {
              "VENDOR_NAME": "NANCY SPENCER GRIGSBY"
            },
            {
              "VENDOR_NAME": "NANCY VERLANDER"
            },
            {
              "VENDOR_NAME": "NANCY WENDIG"
            },
            {
              "VENDOR_NAME": "NAPA CHILD SUPPORT SERVICE"
            },
            {
              "VENDOR_NAME": "NASSAU COUNTY SCU"
            },
            {
              "VENDOR_NAME": "NATALE J. FALANGA MD FACP PC"
            },
            {
              "VENDOR_NAME": "NATALIE HAMILL TRUST 2001"
            },
            {
              "VENDOR_NAME": "NATHAN YEAGLE"
            },
            {
              "VENDOR_NAME": "NATIONAL ADOPTION CENTER"
            },
            {
              "VENDOR_NAME": "NATIONAL APPRAISAL &"
            },
            {
              "VENDOR_NAME": "NATIONAL AQUARIUM IN BALTIMORE"
            },
            {
              "VENDOR_NAME": "NATIONAL ASSOC.OF COLLEGES & EMPLOY"
            },
            {
              "VENDOR_NAME": "NATIONAL ASSOCIATION FOR"
            },
            {
              "VENDOR_NAME": "NATIONAL ASSOCIATION FOR EMPLOYEE"
            },
            {
              "VENDOR_NAME": "NATIONAL BANKCARD SERVICES"
            },
            {
              "VENDOR_NAME": "NATIONAL BUILDING FACILITY SERV."
            },
            {
              "VENDOR_NAME": "NATIONAL BUSINESS INSTITUTE"
            },
            {
              "VENDOR_NAME": "NATIONAL CAREER DEVELOPMENT ASSO."
            },
            {
              "VENDOR_NAME": "NATIONAL CHEMICAL AND CLEANING"
            },
            {
              "VENDOR_NAME": "NATIONAL CITY BANK"
            },
            {
              "VENDOR_NAME": "NATIONAL D.R.I.V.E."
            },
            {
              "VENDOR_NAME": "NATIONAL DISTRIBUTING COMPANY"
            },
            {
              "VENDOR_NAME": "NATIONAL DISTRIBUTION ALLIANCE"
            },
            {
              "VENDOR_NAME": "NATIONAL DISTRIBUTION ALLIANCE LLC"
            },
            {
              "VENDOR_NAME": "NATIONAL ELEVATOR INSPECTION SERV.I"
            },
            {
              "VENDOR_NAME": "NATIONAL ENVIRO. HEALTH ASSN."
            },
            {
              "VENDOR_NAME": "NATIONAL FALLEN FIREFIGHTERS"
            },
            {
              "VENDOR_NAME": "NATIONAL FOOD PROCESSORS ASSOC."
            },
            {
              "VENDOR_NAME": "NATIONAL HOTLINE SERVICES"
            },
            {
              "VENDOR_NAME": "NATIONAL HOTLINE SERVICES"
            },
            {
              "VENDOR_NAME": "NATIONAL IN-STORE MARKETING"
            },
            {
              "VENDOR_NAME": "NATIONAL KIDNEY FOUNDATION"
            },
            {
              "VENDOR_NAME": "NATIONAL LOAN RECOVERY"
            },
            {
              "VENDOR_NAME": "NATIONAL MEDICAL SERVICES"
            },
            {
              "VENDOR_NAME": "NATIONAL MULTIPLE SCLEROSIS SOCIETY"
            },
            {
              "VENDOR_NAME": "NATIONAL NOTARY ASSOCIATION"
            },
            {
              "VENDOR_NAME": "NATIONAL PAYMENT CENTER"
            },
            {
              "VENDOR_NAME": "NATIONAL PEN CORPORATION"
            },
            {
              "VENDOR_NAME": "NATIONAL PENN BANCSHARES"
            },
            {
              "VENDOR_NAME": "NATIONAL PETROLEUM ENERGY"
            },
            {
              "VENDOR_NAME": "NATIONAL PETROLEUM ENERGY CREDIT AS"
            },
            {
              "VENDOR_NAME": "NATIONAL PETROLEUM NEWS"
            },
            {
              "VENDOR_NAME": "NATIONAL POWER CORPORATION"
            },
            {
              "VENDOR_NAME": "NATIONAL PRIVATE TRUCK COUNCIL"
            },
            {
              "VENDOR_NAME": "NATIONAL PROPERTY DAMAGE"
            },
            {
              "VENDOR_NAME": "NATIONAL REALTY & DEVELOPMENT"
            },
            {
              "VENDOR_NAME": "NATIONAL REFERRAL SERVICE"
            },
            {
              "VENDOR_NAME": "NATIONAL REFRIGERATION"
            },
            {
              "VENDOR_NAME": "NATIONAL RESTAURANT ASSOC"
            },
            {
              "VENDOR_NAME": "NATIONAL RESTAURANT ASSOC."
            },
            {
              "VENDOR_NAME": "NATIONAL RESTAURANT ASSOCIATION"
            },
            {
              "VENDOR_NAME": "NATIONAL RESTORATION"
            },
            {
              "VENDOR_NAME": "NATIONAL RETAIL TENANTS"
            },
            {
              "VENDOR_NAME": "NATIONAL SAFETY COMPLIANCE INC"
            },
            {
              "VENDOR_NAME": "NATIONAL SATELLITE"
            },
            {
              "VENDOR_NAME": "NATIONAL SERVICE CENTER"
            },
            {
              "VENDOR_NAME": "NATIONAL UNION/AIGRM"
            },
            {
              "VENDOR_NAME": "NATIONAL VINYL PRODUCTS"
            },
            {
              "VENDOR_NAME": "NATIONAL YOUNG LEADERS CONFERENCE"
            },
            {
              "VENDOR_NAME": "NATIONWIDE SERVICES"
            },
            {
              "VENDOR_NAME": "NATIVITY OF OUR LORD CHURCH"
            },
            {
              "VENDOR_NAME": "NAT'L ASSOCIATION FOR FEMALE EXECUT"
            },
            {
              "VENDOR_NAME": "NAT'L DATACOMPUTER INC"
            },
            {
              "VENDOR_NAME": "NAT'L FIRE PROTECT ASSN"
            },
            {
              "VENDOR_NAME": "NAT'L FLUID MILK PROCESSR"
            },
            {
              "VENDOR_NAME": "NAVAJO MFG"
            },
            {
              "VENDOR_NAME": "NAVY-MARINE CORPS RELIEF SOCIETY NE"
            },
            {
              "VENDOR_NAME": "NAWKAW MID-ATLANTIC"
            },
            {
              "VENDOR_NAME": "NBCMA"
            },
            {
              "VENDOR_NAME": "NBPA"
            },
            {
              "VENDOR_NAME": "NC CHILD SUPPORT"
            },
            {
              "VENDOR_NAME": "NC CHILD SUPPORT CENTRAL COLL"
            },
            {
              "VENDOR_NAME": "NC CHILD SUPPORT CENTRAL COLL."
            },
            {
              "VENDOR_NAME": "NC CHILD SUPPORT CENTRAL.COLL."
            },
            {
              "VENDOR_NAME": "NC CHILD SUPPORT CENTRALIZED COLL"
            },
            {
              "VENDOR_NAME": "NC CHILD SUPPORT CENTRALIZED COLL."
            },
            {
              "VENDOR_NAME": "NC CHILD SUPPORT CENTRALIZED COLLEC"
            },
            {
              "VENDOR_NAME": "NC SECRETARY OF STATE"
            },
            {
              "VENDOR_NAME": "NCCHILD SUPPORT CENTRALIZED COLL"
            },
            {
              "VENDOR_NAME": "NCO FINANCIAL SERVICES"
            },
            {
              "VENDOR_NAME": "NCO FINANCIAL SYSTEM"
            },
            {
              "VENDOR_NAME": "NCO FINANCIAL SYSTEMS"
            },
            {
              "VENDOR_NAME": "NCO FINANCIAL SYSTEMS INC"
            },
            {
              "VENDOR_NAME": "NCO FINANCIAL SYSTEMS"
            },
            {
              "VENDOR_NAME": "NCO PORTFOLIO MANAGEMENT"
            },
            {
              "VENDOR_NAME": "NCO PORTFOLIO MGMT"
            },
            {
              "VENDOR_NAME": "NEAL MCHUGH"
            },
            {
              "VENDOR_NAME": "NEBRASKA CHILD SUPPORT PAY CENTER"
            },
            {
              "VENDOR_NAME": "NEED IN DEED"
            },
            {
              "VENDOR_NAME": "NEELI BENDAPUDI"
            },
            {
              "VENDOR_NAME": "NEHMAD PERILLO & DAVIS P.C."
            },
            {
              "VENDOR_NAME": "NEIBAUER PRESS"
            },
            {
              "VENDOR_NAME": "NEIGHBOR TO NEIGHBOR"
            },
            {
              "VENDOR_NAME": "NEIGHBORHOOD HOUSING"
            },
            {
              "VENDOR_NAME": "NEIGHBORHOOD RENTALS"
            },
            {
              "VENDOR_NAME": "NEIL F McCARTHY"
            },
            {
              "VENDOR_NAME": "NEIL LEVITSKY"
            },
            {
              "VENDOR_NAME": "NEIL MCCARTHY"
            },
            {
              "VENDOR_NAME": "NEIL SCOTT MEYER"
            },
            {
              "VENDOR_NAME": "NELSON JAMESON INC"
            },
            {
              "VENDOR_NAME": "NELSON LONG"
            },
            {
              "VENDOR_NAME": "NEMCO FOOD EQUIPMENT"
            },
            {
              "VENDOR_NAME": "NE-MOS BAKERY"
            },
            {
              "VENDOR_NAME": "NEOCON EAST REGISTRATION"
            },
            {
              "VENDOR_NAME": "NEOGEN CORPORATION"
            },
            {
              "VENDOR_NAME": "NEPTUNE - SEWER UTILITY"
            },
            {
              "VENDOR_NAME": "NEPTUNE CITY FIRE DEPT"
            },
            {
              "VENDOR_NAME": "NEPTUNE TOWNSHIP"
            },
            {
              "VENDOR_NAME": "NESTLE PURINA PET CARE"
            },
            {
              "VENDOR_NAME": "NESTLE WATERS NORTH"
            },
            {
              "VENDOR_NAME": "NESTLE WATERS NORTH AMERICA INC"
            },
            {
              "VENDOR_NAME": "NESTOR-RANALLI REALTY"
            },
            {
              "VENDOR_NAME": "NETHER PROVIDENCE TOWNSHP"
            },
            {
              "VENDOR_NAME": "NETHER PROVIDENCE TWSP"
            },
            {
              "VENDOR_NAME": "NETIQ CORP."
            },
            {
              "VENDOR_NAME": "NETIQ CORPORATION"
            },
            {
              "VENDOR_NAME": "NETS ELECTRONICS"
            },
            {
              "VENDOR_NAME": "NETS PRODUCTS GROUP LLC"
            },
            {
              "VENDOR_NAME": "NETWORK GENERAL CORP."
            },
            {
              "VENDOR_NAME": "NETWORK OF EXECUTIVE WOMEN"
            },
            {
              "VENDOR_NAME": "NETWORK PARKING COMPANY"
            },
            {
              "VENDOR_NAME": "NEUMANN COLLEGE"
            },
            {
              "VENDOR_NAME": "NEUMANN COLLEGE DIVISION OF CONTINU"
            },
            {
              "VENDOR_NAME": "NEVADA LEARNING SERIES USA"
            },
            {
              "VENDOR_NAME": "NEW ACTON MOBILE INDUSTRIES LLC"
            },
            {
              "VENDOR_NAME": "NEW AGE BEVERAGE"
            },
            {
              "VENDOR_NAME": "NEW BEGINNINGS OBGYN"
            },
            {
              "VENDOR_NAME": "NEW BRITAIN TOWNSHIP"
            },
            {
              "VENDOR_NAME": "NEW BRITAIN TOWNSHIP WAGE TAX PAYAB"
            },
            {
              "VENDOR_NAME": "NEW BRITAIN TWP. TAX COLL"
            },
            {
              "VENDOR_NAME": "NEW CASTLE COUNT"
            },
            {
              "VENDOR_NAME": "NEW CASTLE COUNTY"
            },
            {
              "VENDOR_NAME": "NEW CASTLE COUNTY FOOTBALL LEAGUE"
            },
            {
              "VENDOR_NAME": "NEW CASTLE COUNTY PRIDE"
            },
            {
              "VENDOR_NAME": "NEW CASTLE COUNTY SEWER"
            },
            {
              "VENDOR_NAME": "NEW CASTLE CTY DEPT."
            },
            {
              "VENDOR_NAME": "NEW CENTURY FINANCIAL SERVICES"
            },
            {
              "VENDOR_NAME": "NEW EGYPT HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "NEW EGYPT WARRIORS"
            },
            {
              "VENDOR_NAME": "NEW EGYPT YOUTH SOCCER"
            },
            {
              "VENDOR_NAME": "NEW ENGLAND CONFECTIONERY CO"
            },
            {
              "VENDOR_NAME": "NEW ENGLAND PETROLEUM LP"
            },
            {
              "VENDOR_NAME": "NEW ERA FLOORING"
            },
            {
              "VENDOR_NAME": "NEW GLENBROOK EAST LP"
            },
            {
              "VENDOR_NAME": "NEW HANOVER TOWNSHIP"
            },
            {
              "VENDOR_NAME": "NEW HOPE BOROUGH"
            },
            {
              "VENDOR_NAME": "NEW HOPE LOCAL INCOME TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "NEW HORIZONS COPMPUTER LEARNING CTR"
            },
            {
              "VENDOR_NAME": "NEW JERSEY AMERICAN WATER"
            },
            {
              "VENDOR_NAME": "NEW JERSEY AMVETS CONVENTION CORP"
            },
            {
              "VENDOR_NAME": "NEW JERSEY APSE"
            },
            {
              "VENDOR_NAME": "NEW JERSEY CHAMBER OF COMMERCE"
            },
            {
              "VENDOR_NAME": "NEW JERSEY D.O.T."
            },
            {
              "VENDOR_NAME": "NEW JERSEY DEPT OF TRANS"
            },
            {
              "VENDOR_NAME": "NEW JERSEY DEPT.ENVIROMENTAL PROTEC"
            },
            {
              "VENDOR_NAME": "NEW JERSEY DEPT.OF LABOR"
            },
            {
              "VENDOR_NAME": "NEW JERSEY DEPT.OF TRANSPORTATION"
            },
            {
              "VENDOR_NAME": "NEW JERSEY E-Z PASS"
            },
            {
              "VENDOR_NAME": "NEW JERSEY FAM SUPP"
            },
            {
              "VENDOR_NAME": "NEW JERSEY FAMILY SUPP CTR"
            },
            {
              "VENDOR_NAME": "NEW JERSEY FFA ALUMNI ASSOCIATION"
            },
            {
              "VENDOR_NAME": "NEW JERSEY FIREMEN'S ASSN"
            },
            {
              "VENDOR_NAME": "NEW JERSEY FOOD COUNCIL"
            },
            {
              "VENDOR_NAME": "NEW JERSEY NATURAL GAS"
            },
            {
              "VENDOR_NAME": "NEW JERSEY NATURAL GAS CO"
            },
            {
              "VENDOR_NAME": "NEW JERSEY PAYMENT CTR"
            },
            {
              "VENDOR_NAME": "NEW JERSEY PINELANDS COMMISSION"
            },
            {
              "VENDOR_NAME": "NEW JERSEY STATE BAR"
            },
            {
              "VENDOR_NAME": "NEW JERSEY STATE EXEMPT FIREMAN ASS"
            },
            {
              "VENDOR_NAME": "NEW JERSEY STATE EXEMPT FIREMANS AS"
            },
            {
              "VENDOR_NAME": "NEW JERSEY STATE SAFETY COUNCIL"
            },
            {
              "VENDOR_NAME": "NEW JERSEY WEIGHTS AND MEASURES ASS"
            },
            {
              "VENDOR_NAME": "NEW LIFE WORLD"
            },
            {
              "VENDOR_NAME": "NEW LONDON ELECTRIC"
            },
            {
              "VENDOR_NAME": "NEW LOOK CLOTHING COMPANY"
            },
            {
              "VENDOR_NAME": "NEW PENN MOTOR EXPRESS"
            },
            {
              "VENDOR_NAME": "NEW PIG CORPORATION"
            },
            {
              "VENDOR_NAME": "NEW PLUMSTEAD PARTNERS"
            },
            {
              "VENDOR_NAME": "NEW WAVE LANDSCAPING & EXCAVATING"
            },
            {
              "VENDOR_NAME": "NEW YORK CHILD SUPPORT PROCESS CTR."
            },
            {
              "VENDOR_NAME": "NEW YORK SCU"
            },
            {
              "VENDOR_NAME": "NEW YORK STATE CHILD SUPPORT"
            },
            {
              "VENDOR_NAME": "NEW YORK STATE CHILD SUPPORT PROCES"
            },
            {
              "VENDOR_NAME": "NEW YORK STATE DAIRY FOODS"
            },
            {
              "VENDOR_NAME": "NEWARK ELECTRONICS"
            },
            {
              "VENDOR_NAME": "NEWLIN GRIST MILL"
            },
            {
              "VENDOR_NAME": "NEWMAN GLOUCESTER ASSOC LLC"
            },
            {
              "VENDOR_NAME": "NEWMAN"
            },
            {
              "VENDOR_NAME": "NEWMAN"
            },
            {
              "VENDOR_NAME": "NEWMAN"
            },
            {
              "VENDOR_NAME": "NEWPORT NEWS CIRCUIT COURT"
            },
            {
              "VENDOR_NAME": "NEWPORT NEWS GEN'L DIST CT"
            },
            {
              "VENDOR_NAME": "NEWPORT NEWS JDR COURT"
            },
            {
              "VENDOR_NAME": "NEWPORT NEWS TRAFFIC"
            },
            {
              "VENDOR_NAME": "NEWPORT NEWS WATERWORKS"
            },
            {
              "VENDOR_NAME": "NEWPORT WAVE"
            },
            {
              "VENDOR_NAME": "NEWS & MESSENGER"
            },
            {
              "VENDOR_NAME": "NEWS ONE NEWSPAPER DISTRIBUTION SVC"
            },
            {
              "VENDOR_NAME": "NEWTOWN SQUARE FIRE CO.#1 INC"
            },
            {
              "VENDOR_NAME": "NEWTOWN STREET"
            },
            {
              "VENDOR_NAME": "NEWTOWN TWSP SEWER DEPT"
            },
            {
              "VENDOR_NAME": "NEXT PROTEINS"
            },
            {
              "VENDOR_NAME": "NEXTELL COMMUNICATIONS"
            },
            {
              "VENDOR_NAME": "NIAGARA BOTTLING LLC"
            },
            {
              "VENDOR_NAME": "NIC HOLDING CORP"
            },
            {
              "VENDOR_NAME": "NICHOLAS DITTA"
            },
            {
              "VENDOR_NAME": "NICHOLAS F.ALTIERI"
            },
            {
              "VENDOR_NAME": "NICHOLAS MARINAKIS"
            },
            {
              "VENDOR_NAME": "NICHOLAS NEWLIN"
            },
            {
              "VENDOR_NAME": "NICHOLAS PICOZZI MEMORIAL FUND"
            },
            {
              "VENDOR_NAME": "NICHOLAS R. FRANCHI"
            },
            {
              "VENDOR_NAME": "NICK FRANCE"
            },
            {
              "VENDOR_NAME": "NICK GREGORY"
            },
            {
              "VENDOR_NAME": "NICK MALISZEWSKI"
            },
            {
              "VENDOR_NAME": "NICKEL MINES CHILDREN'S FUND"
            },
            {
              "VENDOR_NAME": "NICK'S"
            },
            {
              "VENDOR_NAME": "NICOLE GALLAGHER"
            },
            {
              "VENDOR_NAME": "NICOLE PETERPAUL"
            },
            {
              "VENDOR_NAME": "NICOLE RATHER"
            },
            {
              "VENDOR_NAME": "NICOLE ROSSITER"
            },
            {
              "VENDOR_NAME": "NIELSEN MEDIA RESEARCH"
            },
            {
              "VENDOR_NAME": "NILDA D. PADILLA"
            },
            {
              "VENDOR_NAME": "NIMAL MITRA"
            },
            {
              "VENDOR_NAME": "NINA HATCHER"
            },
            {
              "VENDOR_NAME": "NINAMARIE GULDIN"
            },
            {
              "VENDOR_NAME": "NISSIN FOODS"
            },
            {
              "VENDOR_NAME": "NITE BRITE SIGN CO.INC."
            },
            {
              "VENDOR_NAME": "NITESH CORPORATION"
            },
            {
              "VENDOR_NAME": "NJ AMERICAN WATER CO"
            },
            {
              "VENDOR_NAME": "NJ BUREAU OF FIRE SAFTEY"
            },
            {
              "VENDOR_NAME": "NJ COMMISIONER OF LABOR"
            },
            {
              "VENDOR_NAME": "NJ DEPARTMENT OF AGRICULTURE"
            },
            {
              "VENDOR_NAME": "NJ DEPARTMENT OF TRANSPORTATION"
            },
            {
              "VENDOR_NAME": "NJ DEPT OF TRANSPORTATION"
            },
            {
              "VENDOR_NAME": "NJ DEPT.OF TRANSPORTATION"
            },
            {
              "VENDOR_NAME": "NJ DIV OF EMPLOY"
            },
            {
              "VENDOR_NAME": "NJ DIVISION OF EMPLOYMENT - ACCRUA"
            },
            {
              "VENDOR_NAME": "NJ DOT"
            },
            {
              "VENDOR_NAME": "NJ EMP GROSS INCOME"
            },
            {
              "VENDOR_NAME": "NJ FAMILY SUPP PAY CTR"
            },
            {
              "VENDOR_NAME": "NJ FAMILY SUPPORT PAYMENT CTR"
            },
            {
              "VENDOR_NAME": "NJ ICLE"
            },
            {
              "VENDOR_NAME": "NJ LAWYERS FUND FOR"
            },
            {
              "VENDOR_NAME": "NJ LAWYERS FUND FOR CLIENT PROTECTI"
            },
            {
              "VENDOR_NAME": "NJ MOTOR VEHICLE COMMISSION"
            },
            {
              "VENDOR_NAME": "NJCLASS"
            },
            {
              "VENDOR_NAME": "NJDEP"
            },
            {
              "VENDOR_NAME": "NJDEP LAND USE REGULATIONS"
            },
            {
              "VENDOR_NAME": "NJN PUBLISHING"
            },
            {
              "VENDOR_NAME": "NJSP SOCIAL COMMITTEE"
            },
            {
              "VENDOR_NAME": "NKL SAFE CO"
            },
            {
              "VENDOR_NAME": "NM LLC"
            },
            {
              "VENDOR_NAME": "NNN & K INVESTMENTS"
            },
            {
              "VENDOR_NAME": "NNN TRS"
            },
            {
              "VENDOR_NAME": "NO FLY ZONE"
            },
            {
              "VENDOR_NAME": "NOAH JOFFE"
            },
            {
              "VENDOR_NAME": "NOBLE AMERICAS"
            },
            {
              "VENDOR_NAME": "NOBLE FINANCE CORP."
            },
            {
              "VENDOR_NAME": "NOBLE FINANCE CORP./JEFFREY BOYER"
            },
            {
              "VENDOR_NAME": "NOLAN FINANCIAL GROUP"
            },
            {
              "VENDOR_NAME": "NOLEN INVESTMENTS LLC"
            },
            {
              "VENDOR_NAME": "NON-EXEMPT QTIP RESIDUARY TRUST"
            },
            {
              "VENDOR_NAME": "NONNIS FOOD COMPANY"
            },
            {
              "VENDOR_NAME": "NORCO FIRE COMPANY"
            },
            {
              "VENDOR_NAME": "NORDON HOLDINGS"
            },
            {
              "VENDOR_NAME": "NORFOLK AIRPORT AUTHORITY"
            },
            {
              "VENDOR_NAME": "NORFOLK COMMISSIONER OF THE REVENUE"
            },
            {
              "VENDOR_NAME": "NORFOLK DEPT OF PUBLIC HEALTH"
            },
            {
              "VENDOR_NAME": "NORFOLK GEN'L DIST COURT"
            },
            {
              "VENDOR_NAME": "NORFOLK GEN'L DIST CT"
            },
            {
              "VENDOR_NAME": "NORFOLK GEN'L DIST CT."
            },
            {
              "VENDOR_NAME": "NORFOLK GEN'L DISTRICT COURT"
            },
            {
              "VENDOR_NAME": "NORFOLK PAINT"
            },
            {
              "VENDOR_NAME": "NORFOLK SOUTHERN RAILWAY COMPANY"
            },
            {
              "VENDOR_NAME": "NOR-GWYN BASEBALL INC"
            },
            {
              "VENDOR_NAME": "NORMA BRECKER"
            },
            {
              "VENDOR_NAME": "NORMA E. ROSENBERG"
            },
            {
              "VENDOR_NAME": "NORMAN DEAMICIS"
            },
            {
              "VENDOR_NAME": "NORMAN EDIE AND ANN K.EDIE"
            },
            {
              "VENDOR_NAME": "NORMAN TURIANO"
            },
            {
              "VENDOR_NAME": "NORMANDEAU ASSOCIATES"
            },
            {
              "VENDOR_NAME": "NORMENT FOR SENATE"
            },
            {
              "VENDOR_NAME": "NORRISTOWN AREA SCHOOL DISTRICT"
            },
            {
              "VENDOR_NAME": "NORRISTOWN BORO INC TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "NORTH AMERICAN ENERGY GROUP LLC"
            },
            {
              "VENDOR_NAME": "NORTH AMERICAN TANK LINES"
            },
            {
              "VENDOR_NAME": "NORTH BRUNSWICK TOWNSHIP"
            },
            {
              "VENDOR_NAME": "NORTH CAROLINA CENTRALIZED COLLECTI"
            },
            {
              "VENDOR_NAME": "NORTH CAROLINA CHILD SUPPORT"
            },
            {
              "VENDOR_NAME": "NORTH CAROLINA CHILD SUPPORT 000181"
            },
            {
              "VENDOR_NAME": "NORTH CAROLINA SUPPORT CENTRALIZED"
            },
            {
              "VENDOR_NAME": "NORTH CLIFF CONSULTANTS"
            },
            {
              "VENDOR_NAME": "NORTH COVENTRY MUNICIPAL"
            },
            {
              "VENDOR_NAME": "NORTH COVENTRY TOWNSHIP"
            },
            {
              "VENDOR_NAME": "NORTH COVENTRY TWP"
            },
            {
              "VENDOR_NAME": "NORTH COVENTRY TWP."
            },
            {
              "VENDOR_NAME": "NORTH JERSEY TRAILER &"
            },
            {
              "VENDOR_NAME": "NORTH PENN FEDERAL CREDIT UNION"
            },
            {
              "VENDOR_NAME": "NORTH PENN SCHOOL DIST."
            },
            {
              "VENDOR_NAME": "NORTH PENN SCHOOL DISTRICT"
            },
            {
              "VENDOR_NAME": "NORTH PENN SCHOOL DISTRICT EDUCATIO"
            },
            {
              "VENDOR_NAME": "NORTH PENN SCHOOL TAX"
            },
            {
              "VENDOR_NAME": "NORTH PENN WATER AUTH"
            },
            {
              "VENDOR_NAME": "NORTH POINT VILLAGE"
            },
            {
              "VENDOR_NAME": "NORTH VINELAND LITTLE LEAGUE"
            },
            {
              "VENDOR_NAME": "NORTH WALES BORO. WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "NORTH WALES TAX COLLECTOR"
            },
            {
              "VENDOR_NAME": "NORTH WALES WATER AUTHORITY"
            },
            {
              "VENDOR_NAME": "NORTH WILDWOOD VOLUNTEER"
            },
            {
              "VENDOR_NAME": "NORTHAMPTON BORO MUA"
            },
            {
              "VENDOR_NAME": "NORTHAMPTON COUNTY CONSERVATION"
            },
            {
              "VENDOR_NAME": "NORTHAMPTON COUNTY RECORDER OF DEED"
            },
            {
              "VENDOR_NAME": "NORTHAMPTON TOWNSHIP"
            },
            {
              "VENDOR_NAME": "NORTHAMPTON TWP"
            },
            {
              "VENDOR_NAME": "NORTHAMPTON"
            },
            {
              "VENDOR_NAME": "NORTHEAST GREAT DANE"
            },
            {
              "VENDOR_NAME": "NORTHEAST LOGISTICS SERVICES INC"
            },
            {
              "VENDOR_NAME": "NORTHEAST MESA LLC"
            },
            {
              "VENDOR_NAME": "NORTHEAST PLASTICS SUPPLY CO"
            },
            {
              "VENDOR_NAME": "NORTHEAST TIMES"
            },
            {
              "VENDOR_NAME": "NORTHEAST YMCA"
            },
            {
              "VENDOR_NAME": "NORTHERN HOME CHILDREN AND"
            },
            {
              "VENDOR_NAME": "NORTHERN HOME FOR CHILDREN"
            },
            {
              "VENDOR_NAME": "NORTHERN NECK COCA-COLA"
            },
            {
              "VENDOR_NAME": "NORTHERN SAFETY CO INC"
            },
            {
              "VENDOR_NAME": "NORTHHAMPTON WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "NORTH-SHRED"
            },
            {
              "VENDOR_NAME": "NORTHSTAR APPRAISAL COMPANY"
            },
            {
              "VENDOR_NAME": "NORTHSTAR EXCAVATING"
            },
            {
              "VENDOR_NAME": "NORTHSTAR MARINE INC."
            },
            {
              "VENDOR_NAME": "NORTHWAY FINANCIAL"
            },
            {
              "VENDOR_NAME": "NORTHWEST CASCADE INC."
            },
            {
              "VENDOR_NAME": "NORTHWESTERN HUMAN SERVICES INC."
            },
            {
              "VENDOR_NAME": "NOTHING BUTT CIGARETTES LLC"
            },
            {
              "VENDOR_NAME": "NOTTINGHAM ATHLETIC"
            },
            {
              "VENDOR_NAME": "NOTTINGHAM HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "NOTTOWAY COMBINED"
            },
            {
              "VENDOR_NAME": "NOTTOWAY COMBINED COURT"
            },
            {
              "VENDOR_NAME": "NOTTOWAY COMBINED CTR."
            },
            {
              "VENDOR_NAME": "NOVA RECORDS MANAGEMENT"
            },
            {
              "VENDOR_NAME": "NOVACARE OCCUPATIONAL"
            },
            {
              "VENDOR_NAME": "NOVACARE REHABILITATION"
            },
            {
              "VENDOR_NAME": "NOVAK ENTERPRISES"
            },
            {
              "VENDOR_NAME": "NOVARTIS CONSUMER HEALTH"
            },
            {
              "VENDOR_NAME": "NOVATIONS TRAINING SOLUTIONS"
            },
            {
              "VENDOR_NAME": "NRI DATA & BUSINESS PRODUCTS"
            },
            {
              "VENDOR_NAME": "NSF INTERNATIONAL"
            },
            {
              "VENDOR_NAME": "NTL INSTITUTE"
            },
            {
              "VENDOR_NAME": "NUCAR CONNECTION"
            },
            {
              "VENDOR_NAME": "NU-DELL MFG.CO.INC."
            },
            {
              "VENDOR_NAME": "NUGO NUTRITION"
            },
            {
              "VENDOR_NAME": "NU-HOME CREDIT SERVICES"
            },
            {
              "VENDOR_NAME": "NUOVA DISTRIBUTION USA"
            },
            {
              "VENDOR_NAME": "NUS CONSULTING GROUP INC"
            },
            {
              "VENDOR_NAME": "NUSTAR ENERGY LP"
            },
            {
              "VENDOR_NAME": "NUTRITION GROUP INC."
            },
            {
              "VENDOR_NAME": "NUTRITION RESOURCES"
            },
            {
              "VENDOR_NAME": "NUTRITIONAL DEVELOPMENT"
            },
            {
              "VENDOR_NAME": "NUVELL CREDIT CORP."
            },
            {
              "VENDOR_NAME": "NVE PHARMACEUTICAL"
            },
            {
              "VENDOR_NAME": "NVISION"
            },
            {
              "VENDOR_NAME": "NW SIGN INDUSTRIES"
            },
            {
              "VENDOR_NAME": "NWS FEDERAL CREDIT UNION"
            },
            {
              "VENDOR_NAME": "NYAA"
            },
            {
              "VENDOR_NAME": "NYS BOARD OF LAW EXAMINERS"
            },
            {
              "VENDOR_NAME": "NYS CHILD SUPPORT PROCESS CENTER"
            },
            {
              "VENDOR_NAME": "NYS DEPT OF AGRICULTURE & MARKETS"
            },
            {
              "VENDOR_NAME": "NYS HESC"
            },
            {
              "VENDOR_NAME": "NYS HESC - VERNA XXX-XX-1307"
            },
            {
              "VENDOR_NAME": "NYS HIGHER EDUCATION SERVICES CORP."
            },
            {
              "VENDOR_NAME": "NYSHESC"
            },
            {
              "VENDOR_NAME": "O BEVERAGES"
            },
            {
              "VENDOR_NAME": "O C TANNER COMPANY"
            },
            {
              "VENDOR_NAME": "O P T TAX RECEIVER"
            },
            {
              "VENDOR_NAME": "O.DALE BREEDLOVE"
            },
            {
              "VENDOR_NAME": "OAK CREEK ASSOCIATES"
            },
            {
              "VENDOR_NAME": "OAK HALL SHOPPING CENTER"
            },
            {
              "VENDOR_NAME": "OAKHURST DAIRY"
            },
            {
              "VENDOR_NAME": "OAKLEY CENTER LLC"
            },
            {
              "VENDOR_NAME": "OAKLYN CATS CHEERLEADING"
            },
            {
              "VENDOR_NAME": "OAKLYN FIRE DEPARTMENT"
            },
            {
              "VENDOR_NAME": "OAKSTONE WELLNESS PUBLISHING"
            },
            {
              "VENDOR_NAME": "OBER"
            },
            {
              "VENDOR_NAME": "O'BRIAN FOR SENATE"
            },
            {
              "VENDOR_NAME": "O'BRIEN COMPANY"
            },
            {
              "VENDOR_NAME": "OBT COMMERCIAL PARK"
            },
            {
              "VENDOR_NAME": "OCCUPATIONAL SAFETY SERVICES"
            },
            {
              "VENDOR_NAME": "OCEAN BEACH VOLUNTEER FIRE CO#3"
            },
            {
              "VENDOR_NAME": "OCEAN CITY BEACH PATROL"
            },
            {
              "VENDOR_NAME": "OCEAN CITY INTERNATIONAL"
            },
            {
              "VENDOR_NAME": "OCEAN CITY PBA LOCAL NO. 61"
            },
            {
              "VENDOR_NAME": "OCEAN CITY SHADE TREE COMMISSION"
            },
            {
              "VENDOR_NAME": "OCEAN CO SHERIFF'S DEPT"
            },
            {
              "VENDOR_NAME": "OCEAN CO SPECIAL CIVIL PART"
            },
            {
              "VENDOR_NAME": "OCEAN CO. SHERIFF DEPT."
            },
            {
              "VENDOR_NAME": "OCEAN CO. SHERIFF'S DEPT"
            },
            {
              "VENDOR_NAME": "OCEAN CO. SHERIFF'S DEPT."
            },
            {
              "VENDOR_NAME": "OCEAN COUNTY CIVIL PART."
            },
            {
              "VENDOR_NAME": "OCEAN COUNTY COLUMBUS DAY PARADE"
            },
            {
              "VENDOR_NAME": "OCEAN COUNTY DECOY & GUNNING SHOW"
            },
            {
              "VENDOR_NAME": "OCEAN COUNTY F.O.P.LODGE #10"
            },
            {
              "VENDOR_NAME": "OCEAN COUNTY FIRE MARSHAL"
            },
            {
              "VENDOR_NAME": "OCEAN COUNTY HEALTH DEPARTMENT"
            },
            {
              "VENDOR_NAME": "OCEAN COUNTY SCP"
            },
            {
              "VENDOR_NAME": "OCEAN COUNTY SCP - VJ-006859-03"
            },
            {
              "VENDOR_NAME": "OCEAN COUNTY SCP - VJ-007668-03"
            },
            {
              "VENDOR_NAME": "OCEAN COUNTY SCP - VJ-008947-03"
            },
            {
              "VENDOR_NAME": "OCEAN COUNTY SHERIFF DEPARTMENT"
            },
            {
              "VENDOR_NAME": "OCEAN COUNTY SHERIFF DEPT."
            },
            {
              "VENDOR_NAME": "OCEAN COUNTY SHERIFF OFFICE"
            },
            {
              "VENDOR_NAME": "OCEAN COUNTY SHERIFF'S DEPT"
            },
            {
              "VENDOR_NAME": "OCEAN COUNTY SHERIFF'S DEPT."
            },
            {
              "VENDOR_NAME": "OCEAN COUNTY SHERRIFF'S DEPT"
            },
            {
              "VENDOR_NAME": "OCEAN COUNTY SHERRIFF'S DEPT."
            },
            {
              "VENDOR_NAME": "OCEAN COUNTY SHERRIFS DEPARTMENT"
            },
            {
              "VENDOR_NAME": "OCEAN COUNTY SOIL"
            },
            {
              "VENDOR_NAME": "OCEAN COUNTY TREASURER"
            },
            {
              "VENDOR_NAME": "OCEAN COUNTY UTILITIES AUTHORITY"
            },
            {
              "VENDOR_NAME": "OCEAN CTY TREASURER"
            },
            {
              "VENDOR_NAME": "OCEAN NEWS DISTRIBUTION"
            },
            {
              "VENDOR_NAME": "OCEAN SPRAY CRANBERRIES INC"
            },
            {
              "VENDOR_NAME": "OCEAN VIEW VOLUNTEER FIRE CO."
            },
            {
              "VENDOR_NAME": "OCEANA CROSSING"
            },
            {
              "VENDOR_NAME": "OCEANPORT INDUSTRIES INC"
            },
            {
              "VENDOR_NAME": "OCEANS OF LOVE"
            },
            {
              "VENDOR_NAME": "OCTORARA PUBLISHING"
            },
            {
              "VENDOR_NAME": "ODD MAN RACING"
            },
            {
              "VENDOR_NAME": "ODDZON"
            },
            {
              "VENDOR_NAME": "ODESSA FIRE COMPANY"
            },
            {
              "VENDOR_NAME": "ODWALLA"
            },
            {
              "VENDOR_NAME": "OFF THE WALL COMPANY INC"
            },
            {
              "VENDOR_NAME": "OFF. RICHARD NORMAN"
            },
            {
              "VENDOR_NAME": "OFFICE BASICS INC"
            },
            {
              "VENDOR_NAME": "OFFICE DEPOT"
            },
            {
              "VENDOR_NAME": "OFFICE DEPOT EDI"
            },
            {
              "VENDOR_NAME": "OFFICE OF ATTORNEY GENERAL - TEXAS"
            },
            {
              "VENDOR_NAME": "OFFICE OF COMPLIANCE"
            },
            {
              "VENDOR_NAME": "OFFICE OF STATE FIRE MARSHALL"
            },
            {
              "VENDOR_NAME": "OFFICE OF THE ATTORNEY GENERAL"
            },
            {
              "VENDOR_NAME": "OFFICE OF THE ATTORNEY GENERAL"
            },
            {
              "VENDOR_NAME": "OFFICE OF THE SHERIFF"
            },
            {
              "VENDOR_NAME": "OFFICE OF THE STATE FIRE MARSHALL"
            },
            {
              "VENDOR_NAME": "OFFICE SCAPES DIRECT"
            },
            {
              "VENDOR_NAME": "OFFICE TEAM"
            },
            {
              "VENDOR_NAME": "OFFICER CECIL CANADY"
            },
            {
              "VENDOR_NAME": "OFFICER CECIL CANADY SR"
            },
            {
              "VENDOR_NAME": "OFFICER CECIL CANADY SR SCNJ"
            },
            {
              "VENDOR_NAME": "OFFICER CECIL CANADY SR."
            },
            {
              "VENDOR_NAME": "OFFICER CECIL CANADY"
            },
            {
              "VENDOR_NAME": "OFFICER CECIL CANDY"
            },
            {
              "VENDOR_NAME": "OFFICER CECIL E CANADY SR"
            },
            {
              "VENDOR_NAME": "OFFICER CECIL E. CANADY"
            },
            {
              "VENDOR_NAME": "OFFICER EDWARD AJAMIAN"
            },
            {
              "VENDOR_NAME": "OFFICER JASON RIENZO"
            },
            {
              "VENDOR_NAME": "OFFICER JOHN PAWLOWSKI MEM. FUND"
            },
            {
              "VENDOR_NAME": "OFFICER RICHARD NORMAN"
            },
            {
              "VENDOR_NAME": "OFFICER ROCHARD NORMAN (DC-016007-0"
            },
            {
              "VENDOR_NAME": "OH RYAN'S IRISH POTATOES"
            },
            {
              "VENDOR_NAME": "OHANA AKAHI"
            },
            {
              "VENDOR_NAME": "OHIO CHILD SUPPORT PAY CENTER"
            },
            {
              "VENDOR_NAME": "OHIO CHILD SUPPORT PAYMENT CENTER"
            },
            {
              "VENDOR_NAME": "OHIO CHILD SUPPORT PAYMENT CENTRAL"
            },
            {
              "VENDOR_NAME": "OHIO CHILD SUPPORT PAY'T CENTRAL"
            },
            {
              "VENDOR_NAME": "OHIO SHILD SUPPORT"
            },
            {
              "VENDOR_NAME": "OIL EXPRESS"
            },
            {
              "VENDOR_NAME": "OKLAHOMA DEPT OF HUMAN SER"
            },
            {
              "VENDOR_NAME": "OKLAHOMA DEPT OF PUBLIC SAFETY"
            },
            {
              "VENDOR_NAME": "OLD BRIDGE DOMESTIC VIOLENCE"
            },
            {
              "VENDOR_NAME": "OLD BRIDGE M.U.A."
            },
            {
              "VENDOR_NAME": "OLD BRIDGE TURNPIKE"
            },
            {
              "VENDOR_NAME": "OLD DOMINION SOCCER CLUB"
            },
            {
              "VENDOR_NAME": "OLD LONDON PIZZA"
            },
            {
              "VENDOR_NAME": "OLD MARLTON ASSOCIATES"
            },
            {
              "VENDOR_NAME": "OLD NELSON FOOD CO."
            },
            {
              "VENDOR_NAME": "OLD NELSON FOOD I"
            },
            {
              "VENDOR_NAME": "OLD NELSON FOOD II"
            },
            {
              "VENDOR_NAME": "OLD WISCONSIN SAUSAGE"
            },
            {
              "VENDOR_NAME": "OLD WORLD INDUSTRIES"
            },
            {
              "VENDOR_NAME": "OLDFIELDS SCHOOL"
            },
            {
              "VENDOR_NAME": "OLIVER M. DEAN"
            },
            {
              "VENDOR_NAME": "OLIVER SPRINKLER CO INC"
            },
            {
              "VENDOR_NAME": "OLIVIA ROSE TRACY"
            },
            {
              "VENDOR_NAME": "OLLIE J GRIMSLEY JR"
            },
            {
              "VENDOR_NAME": "OLLIS BROTHERS"
            },
            {
              "VENDOR_NAME": "OLYMPIA PROMOTIONS"
            },
            {
              "VENDOR_NAME": "OLYMPIA SUPPLY COMPANY"
            },
            {
              "VENDOR_NAME": "OLYMPIC EMBROIDERY & WHOLESALE CO."
            },
            {
              "VENDOR_NAME": "OLYMPUS GROUP"
            },
            {
              "VENDOR_NAME": "OMAL LLC"
            },
            {
              "VENDOR_NAME": "OMANOFF AMERICA"
            },
            {
              "VENDOR_NAME": "OMEGA ENGINEERING INC"
            },
            {
              "VENDOR_NAME": "OMEGA SPECIALTY PRODUCT"
            },
            {
              "VENDOR_NAME": "OMEGA SPECIALTY PRODUCTS"
            },
            {
              "VENDOR_NAME": "OMICRON CONSULTING LLC"
            },
            {
              "VENDOR_NAME": "OMNI NEWPORT NEWS HOTEL"
            },
            {
              "VENDOR_NAME": "OMNI SPECIAL INSTRUMENTS"
            },
            {
              "VENDOR_NAME": "OMNI/AMOROSO"
            },
            {
              "VENDOR_NAME": "OMNILIFT"
            },
            {
              "VENDOR_NAME": "OMPAY LLC"
            },
            {
              "VENDOR_NAME": "ON Q PRODUCTIONS"
            },
            {
              "VENDOR_NAME": "ONE LIBERTY PROPERTIES"
            },
            {
              "VENDOR_NAME": "ONE SEVENTEEN HORNER ST"
            },
            {
              "VENDOR_NAME": "ONE TIME SHOW"
            },
            {
              "VENDOR_NAME": "ONE UP"
            },
            {
              "VENDOR_NAME": "O'NEAL SCIENTIFIC SERVICES"
            },
            {
              "VENDOR_NAME": "ONLINE CONSULTING"
            },
            {
              "VENDOR_NAME": "ONNIE L. WOODRUFF TREASURER"
            },
            {
              "VENDOR_NAME": "ONONDAGA COUNTY SCU"
            },
            {
              "VENDOR_NAME": "ONSET COMPUTER CORPORATION"
            },
            {
              "VENDOR_NAME": "ON-SITE MARKETING"
            },
            {
              "VENDOR_NAME": "OPDENAKER"
            },
            {
              "VENDOR_NAME": "OPEN NET FOUNDATION"
            },
            {
              "VENDOR_NAME": "OPERATION FIRST RESPONSE"
            },
            {
              "VENDOR_NAME": "OPERATION WARM"
            },
            {
              "VENDOR_NAME": "OPIS"
            },
            {
              "VENDOR_NAME": "OPIS CONFERENCES"
            },
            {
              "VENDOR_NAME": "OPIS ENERGY GROUP"
            },
            {
              "VENDOR_NAME": "OPT COLLECTOR"
            },
            {
              "VENDOR_NAME": "OPTICAL APPARATUS CO."
            },
            {
              "VENDOR_NAME": "OPTIMAL STRATEGIX"
            },
            {
              "VENDOR_NAME": "OR DEPT OF JUSTICE"
            },
            {
              "VENDOR_NAME": "ORACLE AMERICA"
            },
            {
              "VENDOR_NAME": "ORACLE ENGINEERING INC"
            },
            {
              "VENDOR_NAME": "ORBIS CORPORATION"
            },
            {
              "VENDOR_NAME": "ORDER-MATIC ELECTRONICS CORP"
            },
            {
              "VENDOR_NAME": "OREGON DEPT OF JUSTICE"
            },
            {
              "VENDOR_NAME": "ORLANDINI TILE SUPPLIES"
            },
            {
              "VENDOR_NAME": "ORMSBY'S"
            },
            {
              "VENDOR_NAME": "ORTHODOX JEWISH COUNCIL"
            },
            {
              "VENDOR_NAME": "ORTLEY BEACH FIRST AID SQUAD"
            },
            {
              "VENDOR_NAME": "OSBORNE PROPERTIES"
            },
            {
              "VENDOR_NAME": "OSBORNE PROPERTIES"
            },
            {
              "VENDOR_NAME": "OSCAR MAYER- KRAFT"
            },
            {
              "VENDOR_NAME": "OSI EDUCATION"
            },
            {
              "VENDOR_NAME": "OSI EDUCATION SERVICES"
            },
            {
              "VENDOR_NAME": "OSI EDUCATION SERVICES INC."
            },
            {
              "VENDOR_NAME": "OSI FUNDING"
            },
            {
              "VENDOR_NAME": "OTIS ELEVATOR COMPANY"
            },
            {
              "VENDOR_NAME": "OTTO HUBOKY"
            },
            {
              "VENDOR_NAME": "OUR GANG PLAYERS"
            },
            {
              "VENDOR_NAME": "OUR LADY OF LOURDES"
            },
            {
              "VENDOR_NAME": "OUR LADY OF MT CARMEL"
            },
            {
              "VENDOR_NAME": "OUTBACK STEAKHOUSE"
            },
            {
              "VENDOR_NAME": "OUTTA THE BOX DISPENSERS"
            },
            {
              "VENDOR_NAME": "OUTTA THE BOX DISPENSERS"
            },
            {
              "VENDOR_NAME": "Ovens R Us"
            },
            {
              "VENDOR_NAME": "OVERBROOK SCH FOR BLIND"
            },
            {
              "VENDOR_NAME": "OVERHEAD DOOR"
            },
            {
              "VENDOR_NAME": "OVERHEAD DOOR CO"
            },
            {
              "VENDOR_NAME": "OVERHEAD DOOR CO OF NORFOLK"
            },
            {
              "VENDOR_NAME": "OVERHEAD DOOR CO. OF BALTIMORE"
            },
            {
              "VENDOR_NAME": "OVERHEAD DOOR CO. OF CENTRAL VA"
            },
            {
              "VENDOR_NAME": "OVERHEAD DOOR COMPANY"
            },
            {
              "VENDOR_NAME": "OVERHEAD DOOR OF CENTRAL JERSEY"
            },
            {
              "VENDOR_NAME": "OVERNIGHT TRANSPORTATION CO."
            },
            {
              "VENDOR_NAME": "OWEN H. CARTER"
            },
            {
              "VENDOR_NAME": "OWEN J ROBERTS SCH DIST"
            },
            {
              "VENDOR_NAME": "OXFORD ASSOCIATES"
            },
            {
              "VENDOR_NAME": "OXFORD ELECTRIC"
            },
            {
              "VENDOR_NAME": "P G A MACHINE CO"
            },
            {
              "VENDOR_NAME": "P&M CONSULTING GROUP"
            },
            {
              "VENDOR_NAME": "P.A.P.A"
            },
            {
              "VENDOR_NAME": "P.A.P.A. SPRING GOLF OUTING"
            },
            {
              "VENDOR_NAME": "P.AGNES"
            },
            {
              "VENDOR_NAME": "P.M.ASSOCIATES"
            },
            {
              "VENDOR_NAME": "PA ASSOC OF MILK DEALERS"
            },
            {
              "VENDOR_NAME": "PA DEP"
            },
            {
              "VENDOR_NAME": "PA DEPARTMENT OF REVENUE"
            },
            {
              "VENDOR_NAME": "PA DEPT OF LABOR & INDUST"
            },
            {
              "VENDOR_NAME": "PA DEPT OF REV."
            },
            {
              "VENDOR_NAME": "PA DEPT OF REVENUE"
            },
            {
              "VENDOR_NAME": "PA DEPT OF TRANSPORTATION"
            },
            {
              "VENDOR_NAME": "PA DEPT. OF REVENUE"
            },
            {
              "VENDOR_NAME": "PA DEPT.OF TRANSPORTATION"
            },
            {
              "VENDOR_NAME": "PA DOT"
            },
            {
              "VENDOR_NAME": "PA DUTCH CONVENTION &"
            },
            {
              "VENDOR_NAME": "PA TOURISM SIGNING PROGRAM"
            },
            {
              "VENDOR_NAME": "PA TURNPIKE LOGO FUND"
            },
            {
              "VENDOR_NAME": "PA UNEMP COM FUND"
            },
            {
              "VENDOR_NAME": "PA UNEMPLOYMENT COMP FUND - ACCRUAL"
            },
            {
              "VENDOR_NAME": "PA. DEPT/TRANSPORTATION"
            },
            {
              "VENDOR_NAME": "PA.DEPT.OF ENVIRONMENTAL PROTECTION"
            },
            {
              "VENDOR_NAME": "PA.DEPT.OF REVENUE"
            },
            {
              "VENDOR_NAME": "PA.DEPT.OF TRANSPORTATION"
            },
            {
              "VENDOR_NAME": "PA.SCDU"
            },
            {
              "VENDOR_NAME": "PA/NJ/DE CCIM CHAPTER"
            },
            {
              "VENDOR_NAME": "PABLO GONZALEZ"
            },
            {
              "VENDOR_NAME": "PACE ONE RESTAURANT"
            },
            {
              "VENDOR_NAME": "PACE PACKAGING CORPORATION"
            },
            {
              "VENDOR_NAME": "PACE TRAILER SALES & SERVICE"
            },
            {
              "VENDOR_NAME": "PACEjet LOGISTICS"
            },
            {
              "VENDOR_NAME": "PACHTER & ASSOCIATES"
            },
            {
              "VENDOR_NAME": "PACIFIC HANDY CUTTER INC"
            },
            {
              "VENDOR_NAME": "PACIFIC LIFE & ANNUITY"
            },
            {
              "VENDOR_NAME": "PACIFICO MARPLE FORD LLC"
            },
            {
              "VENDOR_NAME": "PACK 225"
            },
            {
              "VENDOR_NAME": "PACKAGING HORIZONS CORP"
            },
            {
              "VENDOR_NAME": "PacketLogix"
            },
            {
              "VENDOR_NAME": "PADGETT THOMPSON"
            },
            {
              "VENDOR_NAME": "PAK-IT METAL DISPLAY CORP"
            },
            {
              "VENDOR_NAME": "PAKSENSE"
            },
            {
              "VENDOR_NAME": "PALACE BOWLING & ENTERTAINMENT"
            },
            {
              "VENDOR_NAME": "PALISADES COLLECTION"
            },
            {
              "VENDOR_NAME": "PALISADES COLLECTIONS"
            },
            {
              "VENDOR_NAME": "PALMA T. CAPPELLI"
            },
            {
              "VENDOR_NAME": "PALMER TOWNSHIP UTILITY"
            },
            {
              "VENDOR_NAME": "PALMER TWP EARNED INCOME"
            },
            {
              "VENDOR_NAME": "PALMYRA AMBULANCE ASSOCIATION"
            },
            {
              "VENDOR_NAME": "PAMELA SWARTZ-COPE"
            },
            {
              "VENDOR_NAME": "PAMFES"
            },
            {
              "VENDOR_NAME": "PAMLICO PROPERTIES"
            },
            {
              "VENDOR_NAME": "PAN"
            },
            {
              "VENDOR_NAME": "PANASONIC"
            },
            {
              "VENDOR_NAME": "PAOLI ENTERPRISE"
            },
            {
              "VENDOR_NAME": "PAOLINO PROPERTIES"
            },
            {
              "VENDOR_NAME": "PAONE WOODWORKING CORP"
            },
            {
              "VENDOR_NAME": "PAPCO"
            },
            {
              "VENDOR_NAME": "PAPER DIRECT"
            },
            {
              "VENDOR_NAME": "PAPER RECYCLING"
            },
            {
              "VENDOR_NAME": "PAPER SHOP"
            },
            {
              "VENDOR_NAME": "PAPPAS LANDCARE"
            },
            {
              "VENDOR_NAME": "PARADE OF HOMES REALTORS"
            },
            {
              "VENDOR_NAME": "PARAMOUNT CARDS INC."
            },
            {
              "VENDOR_NAME": "PARASENSE INC."
            },
            {
              "VENDOR_NAME": "PARDO'S TRUCK SERV.PARTS WAREHOUSE"
            },
            {
              "VENDOR_NAME": "PARENT & KIRKBRIDE"
            },
            {
              "VENDOR_NAME": "PARISH AND LEBAR L.L.P"
            },
            {
              "VENDOR_NAME": "PARISI ENTERPRISES L L C"
            },
            {
              "VENDOR_NAME": "PARISI ENTERPRISES LLC"
            },
            {
              "VENDOR_NAME": "PARK AMERICA"
            },
            {
              "VENDOR_NAME": "PARK CITY GROUP"
            },
            {
              "VENDOR_NAME": "PARK INN & SUITES BRANDYWINE VALLEY"
            },
            {
              "VENDOR_NAME": "PARK PLACE VALET"
            },
            {
              "VENDOR_NAME": "PARKE PLACE REALTY COMPANY"
            },
            {
              "VENDOR_NAME": "PARKE"
            },
            {
              "VENDOR_NAME": "PARKE"
            },
            {
              "VENDOR_NAME": "PARKER COLLECTION AGENCY"
            },
            {
              "VENDOR_NAME": "PARKLAND PLASTICS"
            },
            {
              "VENDOR_NAME": "PARKLAND SCHOOL DIST."
            },
            {
              "VENDOR_NAME": "PARKSIDE BORO WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "PARKWAY CORPORATION"
            },
            {
              "VENDOR_NAME": "PARKWAY PARKING OF PENNSYLVANIA INC"
            },
            {
              "VENDOR_NAME": "PARKWAY WATER COMPANY"
            },
            {
              "VENDOR_NAME": "PARKWOOD JOINT VENTURE C/O"
            },
            {
              "VENDOR_NAME": "PARR & ABERNATHY"
            },
            {
              "VENDOR_NAME": "PARRIS AND LEBAR"
            },
            {
              "VENDOR_NAME": "PARRISH & LEBAR"
            },
            {
              "VENDOR_NAME": "PARRISH AND LEBAR"
            },
            {
              "VENDOR_NAME": "PARRISH AND LEBAR LLP"
            },
            {
              "VENDOR_NAME": "PARRISH AND LEBAR"
            },
            {
              "VENDOR_NAME": "PARS INTERNATIONAL CORP."
            },
            {
              "VENDOR_NAME": "PARTNER ADVISORS"
            },
            {
              "VENDOR_NAME": "PARTNERSHIP FOR DELAWARE"
            },
            {
              "VENDOR_NAME": "PARTY CENTRAL RENTAL & SALES"
            },
            {
              "VENDOR_NAME": "PARTY FAIR"
            },
            {
              "VENDOR_NAME": "PARTY PERFECT"
            },
            {
              "VENDOR_NAME": "PARTY RENTAL"
            },
            {
              "VENDOR_NAME": "PARTY WAREHOUSE NORTHFIELD LLC"
            },
            {
              "VENDOR_NAME": "PARTY ZONE RENTALS LLC"
            },
            {
              "VENDOR_NAME": "PASADENA RECEIVABLES"
            },
            {
              "VENDOR_NAME": "PASADENA RECEIVABLES D.ALVIANI"
            },
            {
              "VENDOR_NAME": "PASQUALE SCARANTINO"
            },
            {
              "VENDOR_NAME": "PassGo TECHNOLOGIES LTD"
            },
            {
              "VENDOR_NAME": "PassGo TECHNOLOGIES LTD."
            },
            {
              "VENDOR_NAME": "PASTEURIZER OPERATOR WORKSHOP"
            },
            {
              "VENDOR_NAME": "PAT C LUPIA"
            },
            {
              "VENDOR_NAME": "PAT HAGAN"
            },
            {
              "VENDOR_NAME": "PAT WALL TIRE SERVICE"
            },
            {
              "VENDOR_NAME": "PATEL PROPERTIES"
            },
            {
              "VENDOR_NAME": "PATHWAYS EDUCATION PROGRAMS"
            },
            {
              "VENDOR_NAME": "PATIENCE WELCH"
            },
            {
              "VENDOR_NAME": "PATRICIA A. MALLOY"
            },
            {
              "VENDOR_NAME": "PATRICIA CAHILL"
            },
            {
              "VENDOR_NAME": "PATRICIA GRIFFIN"
            },
            {
              "VENDOR_NAME": "PATRICIA JONES"
            },
            {
              "VENDOR_NAME": "PATRICIA KENDRICK"
            },
            {
              "VENDOR_NAME": "PATRICIA LASSITER"
            },
            {
              "VENDOR_NAME": "PATRICIA MCKENNA"
            },
            {
              "VENDOR_NAME": "PATRICIA MOONEYHAM"
            },
            {
              "VENDOR_NAME": "PATRICIA NOLEN SIMS"
            },
            {
              "VENDOR_NAME": "PATRICIA PARK"
            },
            {
              "VENDOR_NAME": "PATRICIA THOMAS"
            },
            {
              "VENDOR_NAME": "PATRICK CERRONE"
            },
            {
              "VENDOR_NAME": "PATRICK CONNELLY"
            },
            {
              "VENDOR_NAME": "PATRICK DOUGHERTY"
            },
            {
              "VENDOR_NAME": "PATRICK J.HAGAN"
            },
            {
              "VENDOR_NAME": "PATRICK SCANLON"
            },
            {
              "VENDOR_NAME": "PATRICK SCANLON"
            },
            {
              "VENDOR_NAME": "PATRICK SCANLON"
            },
            {
              "VENDOR_NAME": "PATRICK SCANLON"
            },
            {
              "VENDOR_NAME": "PATSY A.RAHN"
            },
            {
              "VENDOR_NAME": "PATT CROCKETT CORBIN TRST"
            },
            {
              "VENDOR_NAME": "PATTERSON & CO."
            },
            {
              "VENDOR_NAME": "PATTY BARLOW"
            },
            {
              "VENDOR_NAME": "PATTY NELSON"
            },
            {
              "VENDOR_NAME": "PATTY WALLACE"
            },
            {
              "VENDOR_NAME": "PAUL AND SONS PAINTING & REMODEL"
            },
            {
              "VENDOR_NAME": "PAUL B.LAUSCH"
            },
            {
              "VENDOR_NAME": "PAUL C HAND"
            },
            {
              "VENDOR_NAME": "PAUL C. NOLEN"
            },
            {
              "VENDOR_NAME": "PAUL C.NOLAN"
            },
            {
              "VENDOR_NAME": "PAUL CHELI"
            },
            {
              "VENDOR_NAME": "PAUL FINLEY"
            },
            {
              "VENDOR_NAME": "PAUL FREEHART"
            },
            {
              "VENDOR_NAME": "PAUL GRIFFIN"
            },
            {
              "VENDOR_NAME": "PAUL HESS"
            },
            {
              "VENDOR_NAME": "PAUL JAMSKY T/A PAUL & SONS"
            },
            {
              "VENDOR_NAME": "PAUL LEAND"
            },
            {
              "VENDOR_NAME": "PAUL M BENNETT"
            },
            {
              "VENDOR_NAME": "PAUL MODUGNO"
            },
            {
              "VENDOR_NAME": "PAUL RABINOWITZ GLASS CO."
            },
            {
              "VENDOR_NAME": "PAUL REPLENSKI"
            },
            {
              "VENDOR_NAME": "PAUL RESTALL COMPANY"
            },
            {
              "VENDOR_NAME": "PAUL RITZ"
            },
            {
              "VENDOR_NAME": "PAUL'S AUTO RADIATOR SERV"
            },
            {
              "VENDOR_NAME": "PAUL'S CUSTOM AWARDS &"
            },
            {
              "VENDOR_NAME": "PAUL'S CUSTOM AWARDS&TROPHIES INC"
            },
            {
              "VENDOR_NAME": "PAULSBORO MUNICIPAL COURT"
            },
            {
              "VENDOR_NAME": "PAWLOWSKI FAMILY TRUST FUND"
            },
            {
              "VENDOR_NAME": "PAWS: PHILADELPHIA ANIMAL"
            },
            {
              "VENDOR_NAME": "PAXON HOLLOW COUNTRY CLUB"
            },
            {
              "VENDOR_NAME": "PAYDAY FINANCIAL LLC"
            },
            {
              "VENDOR_NAME": "PAYFLEX SYSTEMS USA"
            },
            {
              "VENDOR_NAME": "PAYNE & SONS"
            },
            {
              "VENDOR_NAME": "PAYNE & SONS"
            },
            {
              "VENDOR_NAME": "PBGH"
            },
            {
              "VENDOR_NAME": "PBV OF SALISBURY LLC"
            },
            {
              "VENDOR_NAME": "PC CONNECTION SALES CORP."
            },
            {
              "VENDOR_NAME": "PCE"
            },
            {
              "VENDOR_NAME": "PCI SECURITY STANDARDS"
            },
            {
              "VENDOR_NAME": "PD SIGNATURE"
            },
            {
              "VENDOR_NAME": "PD SUPPLY INC."
            },
            {
              "VENDOR_NAME": "PDS"
            },
            {
              "VENDOR_NAME": "PEACE FROGS BANK TO BANK SWIM"
            },
            {
              "VENDOR_NAME": "PEACHTREE BUSINESS PRODUCTS"
            },
            {
              "VENDOR_NAME": "PEARL PRESSMAN LIBERTY"
            },
            {
              "VENDOR_NAME": "PEARSON CANDY COMPANY"
            },
            {
              "VENDOR_NAME": "PEARSON EDUCATION"
            },
            {
              "VENDOR_NAME": "PECO"
            },
            {
              "VENDOR_NAME": "PECO ENERGY"
            },
            {
              "VENDOR_NAME": "PECO ENERGY CO"
            },
            {
              "VENDOR_NAME": "PECO ENERGY CO."
            },
            {
              "VENDOR_NAME": "PECO ENERGY COMPANY"
            },
            {
              "VENDOR_NAME": "PECO Utility"
            },
            {
              "VENDOR_NAME": "PEGGY WINTERS"
            },
            {
              "VENDOR_NAME": "PEMBERTON FABRICATORS INC"
            },
            {
              "VENDOR_NAME": "PEMBERTON TOWNSHIP"
            },
            {
              "VENDOR_NAME": "PEMBERTON TOWNSHIP HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "PEMBERTON TOWNSHIP WATER DEPT"
            },
            {
              "VENDOR_NAME": "PEMBERTON TWP HISTORIC TRUST"
            },
            {
              "VENDOR_NAME": "PEMBERTON TWSP M U A"
            },
            {
              "VENDOR_NAME": "PEMBROKE FINANCE"
            },
            {
              "VENDOR_NAME": "PENDERGAST SAFETY EQUIP"
            },
            {
              "VENDOR_NAME": "PENINSULA EMERGENCY"
            },
            {
              "VENDOR_NAME": "PENINSULA EMERGENCY SERVICES"
            },
            {
              "VENDOR_NAME": "PENINSULA HEATING & AIR CONDITIONIN"
            },
            {
              "VENDOR_NAME": "PENINSULA PRESSURE SYSTEMS"
            },
            {
              "VENDOR_NAME": "PENINSULA REGIONAL HOSPITAL"
            },
            {
              "VENDOR_NAME": "PENJERDEL EMP BENEFITS &"
            },
            {
              "VENDOR_NAME": "PENN DELCO SCHOOL DIST"
            },
            {
              "VENDOR_NAME": "PENN DETROIT DIESEL"
            },
            {
              "VENDOR_NAME": "PENN DOT"
            },
            {
              "VENDOR_NAME": "PENN E & R"
            },
            {
              "VENDOR_NAME": "PENN FOOD MERCHANTS ASSOC"
            },
            {
              "VENDOR_NAME": "PENN JERSEY PAPER CO."
            },
            {
              "VENDOR_NAME": "PENN LINEN & UNIFORM SERV.INC."
            },
            {
              "VENDOR_NAME": "PENN MEDICINE AT RADNOR"
            },
            {
              "VENDOR_NAME": "PENN OAKS GOLF CLUB"
            },
            {
              "VENDOR_NAME": "PENN POWER SYSTEMS"
            },
            {
              "VENDOR_NAME": "PENN RIDGE SCHOOL DISTRICT"
            },
            {
              "VENDOR_NAME": "PENN STATE BRANDYWINE"
            },
            {
              "VENDOR_NAME": "PENN STATE DELAWARE COUNTY"
            },
            {
              "VENDOR_NAME": "PENN STATE TREASURY"
            },
            {
              "VENDOR_NAME": "PENN STATE UNIVERSITY"
            },
            {
              "VENDOR_NAME": "PENN STATE-DELCO CAMPUS"
            },
            {
              "VENDOR_NAME": "PENN TANK LINES"
            },
            {
              "VENDOR_NAME": "PENN. RESOURCE COUNCIL"
            },
            {
              "VENDOR_NAME": "PENNA AMERICAN WATER CO"
            },
            {
              "VENDOR_NAME": "PENNA BAR INSTITUTE"
            },
            {
              "VENDOR_NAME": "PENNA TURNPIKE COMMISSION"
            },
            {
              "VENDOR_NAME": "PENNA.TURNPIKE COMMISSION"
            },
            {
              "VENDOR_NAME": "PENN-AIR AND HYDRAULICS"
            },
            {
              "VENDOR_NAME": "PENNCREST CLASS OF 2001"
            },
            {
              "VENDOR_NAME": "PENNINGTON PAVING INC"
            },
            {
              "VENDOR_NAME": "PENNOCK DEVELOPMENT ASSOC"
            },
            {
              "VENDOR_NAME": "PENNONI ASSOC. INC"
            },
            {
              "VENDOR_NAME": "PENNONI ASSOCIATES"
            },
            {
              "VENDOR_NAME": "PENNPOST PARKING"
            },
            {
              "VENDOR_NAME": "PENNRIDGE SCHOOL DISTRICT WAGE TAX"
            },
            {
              "VENDOR_NAME": "PENNS GROVE HIGH BAND BOOSTERS"
            },
            {
              "VENDOR_NAME": "PENNSAUKEN BRD OF HEALTH"
            },
            {
              "VENDOR_NAME": "PENNSAUKEN FIRE DEPARTMENT"
            },
            {
              "VENDOR_NAME": "PENNSAUKEN MUNICIPAL COURT"
            },
            {
              "VENDOR_NAME": "PENNSAUKEN SEWERAGE AUTH"
            },
            {
              "VENDOR_NAME": "PENNSVILLE SEWERAGE"
            },
            {
              "VENDOR_NAME": "PENNSVILLE TAX COLLECTOR"
            },
            {
              "VENDOR_NAME": "PENNSYLVANIA BAR ASSOCIATION"
            },
            {
              "VENDOR_NAME": "PENNSYLVANIA CLEAN WATER FUND"
            },
            {
              "VENDOR_NAME": "PENNSYLVANIA DAIRY PRINCESS &"
            },
            {
              "VENDOR_NAME": "PENNSYLVANIA DEPARTMENT OF REVENUE"
            },
            {
              "VENDOR_NAME": "PENNSYLVANIA DEPT.OF LABOR & INDUST"
            },
            {
              "VENDOR_NAME": "PENNSYLVANIA DEPT.OF REVENUE"
            },
            {
              "VENDOR_NAME": "PENNSYLVANIA DEPT.OF TRANSPORTATION"
            },
            {
              "VENDOR_NAME": "PENNSYLVANIA EDUCATION SYSTEMS INST"
            },
            {
              "VENDOR_NAME": "PENNSYLVANIA HAZARDOUS MATERIAL"
            },
            {
              "VENDOR_NAME": "PENNSYLVANIA HOME OF THE SPARROW"
            },
            {
              "VENDOR_NAME": "PENNSYLVANIA INSTITUTE OF CPA'S"
            },
            {
              "VENDOR_NAME": "PENNSYLVANIA LAND TITLE INSURANCE"
            },
            {
              "VENDOR_NAME": "PENNSYLVANIA LOCKSMITH CO"
            },
            {
              "VENDOR_NAME": "PENNSYLVANIA LOGO SIGNING"
            },
            {
              "VENDOR_NAME": "PENNSYLVANIA MOTOR TRUCK ASSOC."
            },
            {
              "VENDOR_NAME": "PENNSYLVANIA ONE CALL SYSTEM"
            },
            {
              "VENDOR_NAME": "PENNSYLVANIA PETROLEUM MARKETERS &"
            },
            {
              "VENDOR_NAME": "PENNSYLVANIA PROMOTIONS"
            },
            {
              "VENDOR_NAME": "PENNSYLVANIA RESOURCES COUNCIL"
            },
            {
              "VENDOR_NAME": "PENNSYLVANIA SCDU"
            },
            {
              "VENDOR_NAME": "PENNSYLVANIA STATE"
            },
            {
              "VENDOR_NAME": "PENNSYLVANIA STATE ASSOC. OF"
            },
            {
              "VENDOR_NAME": "PENNSYLVANIA STEEL"
            },
            {
              "VENDOR_NAME": "PENNSYLVANIA VETERANS MUSEUM"
            },
            {
              "VENDOR_NAME": "PENNSYLVANIA WEEKLIES"
            },
            {
              "VENDOR_NAME": "PENNWOOD MANAGEMENT LLC"
            },
            {
              "VENDOR_NAME": "PENNWOOD PLAZA L.P. PRUDENTIAL"
            },
            {
              "VENDOR_NAME": "PENNYPACKER & FRY"
            },
            {
              "VENDOR_NAME": "PENROSE CONTRACTING LLC"
            },
            {
              "VENDOR_NAME": "PENROSE CONTRACTING"
            },
            {
              "VENDOR_NAME": "PENSINGER INC."
            },
            {
              "VENDOR_NAME": "PENSKE 3PL"
            },
            {
              "VENDOR_NAME": "PENSKE LOGISTICS"
            },
            {
              "VENDOR_NAME": "PENSKE TRUCK LEASING CO."
            },
            {
              "VENDOR_NAME": "PENTON CO INC"
            },
            {
              "VENDOR_NAME": "PENTON COMPANY C/O"
            },
            {
              "VENDOR_NAME": "PENTON TECHNOLOGY MEDIA"
            },
            {
              "VENDOR_NAME": "PENWA GIFTS/RICHARD BOBLENZ"
            },
            {
              "VENDOR_NAME": "PEOPLE FLEX"
            },
            {
              "VENDOR_NAME": "PEOPLE TO PEOPLE"
            },
            {
              "VENDOR_NAME": "PEOPLE'S EMERGENCY CENTER"
            },
            {
              "VENDOR_NAME": "PEOPLE'S LIGHT & THEATRE CO."
            },
            {
              "VENDOR_NAME": "PEPCO"
            },
            {
              "VENDOR_NAME": "PEPCO GAS SERVICES"
            },
            {
              "VENDOR_NAME": "PEPPER HAMILTON LLP"
            },
            {
              "VENDOR_NAME": "PEPPER HAMILTON"
            },
            {
              "VENDOR_NAME": "PEPPERIDGE FARM"
            },
            {
              "VENDOR_NAME": "PEPPERIDGE FARM BREAD"
            },
            {
              "VENDOR_NAME": "PEPPERIDGE FARMS INC"
            },
            {
              "VENDOR_NAME": "PEPSI BTLG HAVRE DEGRACE"
            },
            {
              "VENDOR_NAME": "PEPSI COLA COMPANY"
            },
            {
              "VENDOR_NAME": "PEPSI-COLA NATIONAL BRAND"
            },
            {
              "VENDOR_NAME": "PEPSI-COLA OF CENTRAL VA"
            },
            {
              "VENDOR_NAME": "PEQUEA VALLEY SCHOOL DISTRICT"
            },
            {
              "VENDOR_NAME": "PERDUE FARMS"
            },
            {
              "VENDOR_NAME": "PERFECT APPEARANCE LAWN"
            },
            {
              "VENDOR_NAME": "PERFETTI VAN MELLE"
            },
            {
              "VENDOR_NAME": "PERFICIENT"
            },
            {
              "VENDOR_NAME": "PERFORMANCE DEVELOPMENT GROUP"
            },
            {
              "VENDOR_NAME": "PERFORMANCE MEDIA WORKS"
            },
            {
              "VENDOR_NAME": "PERFORMANCE MEDIA WORKS"
            },
            {
              "VENDOR_NAME": "PERFORMANCE POINT"
            },
            {
              "VENDOR_NAME": "PERISCOPE"
            },
            {
              "VENDOR_NAME": "PERKIOMEN VALLEY SCHOOL DISTRICT"
            },
            {
              "VENDOR_NAME": "PERKIOMEN WATERSHED CONSERVANCY"
            },
            {
              "VENDOR_NAME": "PERKIOMEN WOODS PROPERTY OWNERS ASS"
            },
            {
              "VENDOR_NAME": "PERNA WASTEWATER MANAGEMENT"
            },
            {
              "VENDOR_NAME": "PEROUTKA & PEROUTKA"
            },
            {
              "VENDOR_NAME": "PEROUTKA AND PEROUTKA"
            },
            {
              "VENDOR_NAME": "PERSKIE NEHMAD & PERILLO"
            },
            {
              "VENDOR_NAME": "PERSONNEL POLICY SERV INC"
            },
            {
              "VENDOR_NAME": "PESKIN/SCHLACKMAN INV"
            },
            {
              "VENDOR_NAME": "PET BRANDS"
            },
            {
              "VENDOR_NAME": "PETER & ALICE THIEL"
            },
            {
              "VENDOR_NAME": "PETER & MARY E GILLIGAN"
            },
            {
              "VENDOR_NAME": "PETER A. STAFFIERI"
            },
            {
              "VENDOR_NAME": "PETER ANTO"
            },
            {
              "VENDOR_NAME": "PETER F GILLIGAN"
            },
            {
              "VENDOR_NAME": "PETER GERTLER"
            },
            {
              "VENDOR_NAME": "PETER GILLIGAN"
            },
            {
              "VENDOR_NAME": "PETER I. KASSIS"
            },
            {
              "VENDOR_NAME": "PETER J. ROHANA"
            },
            {
              "VENDOR_NAME": "PETER JONES"
            },
            {
              "VENDOR_NAME": "PETER JOSEPH MCHUGH"
            },
            {
              "VENDOR_NAME": "PETER M. MAGNER"
            },
            {
              "VENDOR_NAME": "PETER S.SWAIN"
            },
            {
              "VENDOR_NAME": "PETER TRIOZZI"
            },
            {
              "VENDOR_NAME": "PETER W. SMULSKI"
            },
            {
              "VENDOR_NAME": "PETERBURG GEN'L DIST. CT."
            },
            {
              "VENDOR_NAME": "PETERSBURG GEN DIST CT"
            },
            {
              "VENDOR_NAME": "PETERSBURG GENERAL DIST. COURT"
            },
            {
              "VENDOR_NAME": "PETERSBURG GENERALS"
            },
            {
              "VENDOR_NAME": "PETERSBURG GEN'L DIST COURT"
            },
            {
              "VENDOR_NAME": "PETERSBURG GEN'L DIST. COURT"
            },
            {
              "VENDOR_NAME": "PETERSBURG GEN'L DIST.COURT"
            },
            {
              "VENDOR_NAME": "PETERSBURG JDR COURT"
            },
            {
              "VENDOR_NAME": "PETERSON SERVICE COMPANY"
            },
            {
              "VENDOR_NAME": "PETRIKIN"
            },
            {
              "VENDOR_NAME": "PETRO SERVICES"
            },
            {
              "VENDOR_NAME": "PETROBRAS AMERICA"
            },
            {
              "VENDOR_NAME": "PETROCOM ENERGY"
            },
            {
              "VENDOR_NAME": "PETROLEUM"
            },
            {
              "VENDOR_NAME": "PETROLEUM CONVENIENCE ALLIANCE FOR"
            },
            {
              "VENDOR_NAME": "PETROLEUM EQUIPMENT INC."
            },
            {
              "VENDOR_NAME": "PETROLEUM EQUIPMENT INSTITUTE"
            },
            {
              "VENDOR_NAME": "PETROLEUM MARKETERS OF VIRGINIA"
            },
            {
              "VENDOR_NAME": "PETROLEUM PRODUCTS CORPORATION"
            },
            {
              "VENDOR_NAME": "PETROLEUM SYSTEMS OF VIRGINIA"
            },
            {
              "VENDOR_NAME": "PETROLEUM TRADER"
            },
            {
              "VENDOR_NAME": "PETROLEUM TRADERS CORPORATION"
            },
            {
              "VENDOR_NAME": "PETRON"
            },
            {
              "VENDOR_NAME": "PETRON ENERGY LLC"
            },
            {
              "VENDOR_NAME": "PEYTON'S PROMISE"
            },
            {
              "VENDOR_NAME": "PEZ CANDY INC"
            },
            {
              "VENDOR_NAME": "PHANTOM SHADOW"
            },
            {
              "VENDOR_NAME": "PHEAA"
            },
            {
              "VENDOR_NAME": "PHILA BUSINESS JOURNAL"
            },
            {
              "VENDOR_NAME": "PHILA DEPT/PUBLIC HEALTH"
            },
            {
              "VENDOR_NAME": "PHILA EAGLES FOOTBALLCLUB"
            },
            {
              "VENDOR_NAME": "PHILA EAGLES"
            },
            {
              "VENDOR_NAME": "PHILA NEWSPAPERS INC"
            },
            {
              "VENDOR_NAME": "PHILA STRING BAND ASSOC."
            },
            {
              "VENDOR_NAME": "PHILA. CORP. INVESTMENT SERVICES"
            },
            {
              "VENDOR_NAME": "PHILA. S. JERSEY ASHFSA"
            },
            {
              "VENDOR_NAME": "PHILA.-S.JERSEY ASHFSA"
            },
            {
              "VENDOR_NAME": "PHILA.-SOUTH JERSEY ASHFSA"
            },
            {
              "VENDOR_NAME": "PHILABUNDANCE"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA 2000"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA AFFILIATE"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA ATHLETIC CHARITIES"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA BAR 10K RUN"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA BAR ASSOC"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA BUSINESS SERVICES"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA CHILDREN'S ALLIANCE"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA CHINATOWN"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA CORP.FOR AGING"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA DIVERSITY"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA EAGLES LTD.PARTNERSHIP"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA FIRE DEPT. - ENGINE 2"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA FLYERS"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA GAS WORKS"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA MIXING SOLUTIONS"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA MURAL ARTS ADVOCATES"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA MUSEUM OF ART"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA NAACP"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA NEWSPAPERS"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA NEWSPAPERS"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA OCCHEALTH"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA PARKING AUTH"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA PHILLIES"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA PRISON SYSTEM"
            },
            {
              "VENDOR_NAME": "Philadelphia READS"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA SECURITY PRODUCTS"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA SIGN CO"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA TRIBUNE"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA TROLLEY WORKS"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA UNIVERSITY"
            },
            {
              "VENDOR_NAME": "PHILADELPHIA WORKFORCE DEVELOPMENT"
            },
            {
              "VENDOR_NAME": "PHILADLEPHIA BAR ASSOC."
            },
            {
              "VENDOR_NAME": "PHILIP J KELLAM COMM. OF REVENUE"
            },
            {
              "VENDOR_NAME": "PHILIP MORRIS"
            },
            {
              "VENDOR_NAME": "PHILIP ROSENAU CO."
            },
            {
              "VENDOR_NAME": "PHILLIES BALLPARK LP"
            },
            {
              "VENDOR_NAME": "PHILLIES MERCHANDISE"
            },
            {
              "VENDOR_NAME": "PHILLIPS & PHILLIPS ENTERPRISES"
            },
            {
              "VENDOR_NAME": "PHILLIPS PREISS GRYGIEL LLC"
            },
            {
              "VENDOR_NAME": "PHILLIPS PREISS SHAPIRO ASSOC"
            },
            {
              "VENDOR_NAME": "PHILLIPSBURG HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "PHILLIPSBURG SEWER UTILITY"
            },
            {
              "VENDOR_NAME": "PHILLIPSBURGH EMERGENCY SQUAD"
            },
            {
              "VENDOR_NAME": "PHOENIX"
            },
            {
              "VENDOR_NAME": "PHOENIX PROPERTIES"
            },
            {
              "VENDOR_NAME": "PHOENIX STORAGE"
            },
            {
              "VENDOR_NAME": "PHOENIX STRATEGIES INC."
            },
            {
              "VENDOR_NAME": "PHOENIXVILLE AREA SCHOOL DISTRICT"
            },
            {
              "VENDOR_NAME": "PHOENIXVILLE BOROUGH"
            },
            {
              "VENDOR_NAME": "PHOENIXVILLE WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "PHOTO COMMUNICATIONS CORP."
            },
            {
              "VENDOR_NAME": "PHYLLIS CHEN"
            },
            {
              "VENDOR_NAME": "PHYLLIS MCLEOD"
            },
            {
              "VENDOR_NAME": "PICKERING CORTS & SUMMERSON INC"
            },
            {
              "VENDOR_NAME": "PIECO SERVICES"
            },
            {
              "VENDOR_NAME": "PIERRE J.ALIX"
            },
            {
              "VENDOR_NAME": "PIERRE S du PONT"
            },
            {
              "VENDOR_NAME": "PIHO ENGINEERING"
            },
            {
              "VENDOR_NAME": "PIKELAND CONSTRUCTION"
            },
            {
              "VENDOR_NAME": "PILESGROVE JOINT MUNICIPAL COURT"
            },
            {
              "VENDOR_NAME": "PILLARI"
            },
            {
              "VENDOR_NAME": "PILLSBURY- GENERAL MILLS"
            },
            {
              "VENDOR_NAME": "PILLSBURY-GENERAL MILLS"
            },
            {
              "VENDOR_NAME": "PINCKNEY MOLDED PLASTICS"
            },
            {
              "VENDOR_NAME": "PINE BELT CB"
            },
            {
              "VENDOR_NAME": "PINE ENVIROMENTAL SERVICES"
            },
            {
              "VENDOR_NAME": "PINE HILL BOROUGH"
            },
            {
              "VENDOR_NAME": "PINE HILL BOROUGH MUA"
            },
            {
              "VENDOR_NAME": "PINE HILL FIRE DEPT."
            },
            {
              "VENDOR_NAME": "PINE HILL POLICE K-9 FUND"
            },
            {
              "VENDOR_NAME": "PINE RUN CONSTRUCTION CORP"
            },
            {
              "VENDOR_NAME": "PINE TREE INN ASSOC.L.L.C."
            },
            {
              "VENDOR_NAME": "PINE TREE INN ASSOCIATES LLC"
            },
            {
              "VENDOR_NAME": "PINE WOODS INC #3456"
            },
            {
              "VENDOR_NAME": "PINELAND PLAYERS"
            },
            {
              "VENDOR_NAME": "PINELANDS REG.BOYS TRACK"
            },
            {
              "VENDOR_NAME": "PINELANDS REGIONAL HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "PINEWALD PIONEER GOLF FUND"
            },
            {
              "VENDOR_NAME": "PINK ELEPHANT CORP"
            },
            {
              "VENDOR_NAME": "PINNACLE BUSINESS SYSTEMS"
            },
            {
              "VENDOR_NAME": "PINNACLE CORPORATION"
            },
            {
              "VENDOR_NAME": "PINNACLE FOOD PRODUCTS"
            },
            {
              "VENDOR_NAME": "PINTO BROTHERS DISPOSAL"
            },
            {
              "VENDOR_NAME": "PINTZUK-491 ROUTE 73"
            },
            {
              "VENDOR_NAME": "PIONEER CREDIT RECOVERY"
            },
            {
              "VENDOR_NAME": "PIONEER CREDIT RECOVERY INC"
            },
            {
              "VENDOR_NAME": "PIONEER CREDIT RECOVERY"
            },
            {
              "VENDOR_NAME": "PIONEER CREDIT RECOVERY"
            },
            {
              "VENDOR_NAME": "PIONEER MFG. COMPANY INC."
            },
            {
              "VENDOR_NAME": "PIONEER RESEARCH CORP."
            },
            {
              "VENDOR_NAME": "PIONEER ROOFING SYSTEMS"
            },
            {
              "VENDOR_NAME": "PIPE DATA VIEW SERVICES"
            },
            {
              "VENDOR_NAME": "PIPE SERVICES CORP."
            },
            {
              "VENDOR_NAME": "PIPESHARK"
            },
            {
              "VENDOR_NAME": "PIROEFF"
            },
            {
              "VENDOR_NAME": "PITNEY BOWES CREDIT CORP"
            },
            {
              "VENDOR_NAME": "PITNEY BOWES INC."
            },
            {
              "VENDOR_NAME": "PITNEY BOWES MANAGEMENT SERVICES"
            },
            {
              "VENDOR_NAME": "PITNEY BOWES SERVICE SOLUTIONS"
            },
            {
              "VENDOR_NAME": "PITNEY BOWES SOFTWARE INC."
            },
            {
              "VENDOR_NAME": "PITNEY-BOWES INC"
            },
            {
              "VENDOR_NAME": "PITNEY-BOWES PURCHASE POWER"
            },
            {
              "VENDOR_NAME": "PITT OHIO EXPRESS INC"
            },
            {
              "VENDOR_NAME": "PITT OHIO EXPRESS INC."
            },
            {
              "VENDOR_NAME": "PITTSBURGH BUSINESS GROUP"
            },
            {
              "VENDOR_NAME": "PITTSGROVE TWP TAX COLL"
            },
            {
              "VENDOR_NAME": "PJS SERVICES INC."
            },
            {
              "VENDOR_NAME": "PLACER DEPARTMENT OF CHILD SERVICES"
            },
            {
              "VENDOR_NAME": "PLAINS MARKETING"
            },
            {
              "VENDOR_NAME": "PLAINS PRODUCTS TERMINALS LLC"
            },
            {
              "VENDOR_NAME": "PLAN EXPRESS INC"
            },
            {
              "VENDOR_NAME": "PLANALYTICS"
            },
            {
              "VENDOR_NAME": "PLANT SERVICE SUPERBOX"
            },
            {
              "VENDOR_NAME": "PLASTIC CRAFTERS"
            },
            {
              "VENDOR_NAME": "PLATINUM DISTRIBUTION"
            },
            {
              "VENDOR_NAME": "PLATINUM FINANCIAL SER"
            },
            {
              "VENDOR_NAME": "PLATINUM FINANCIAL SERV CORP"
            },
            {
              "VENDOR_NAME": "PLATINUM FINANCIAL SERVICE"
            },
            {
              "VENDOR_NAME": "PLATINUM FINANCIAL SERVICES"
            },
            {
              "VENDOR_NAME": "PLATINUM FINANCIAL SERVICES CORP."
            },
            {
              "VENDOR_NAME": "PLATINUM TELEVISION GROUP"
            },
            {
              "VENDOR_NAME": "PLATTS"
            },
            {
              "VENDOR_NAME": "PLAYTEX PRODUCTS INC"
            },
            {
              "VENDOR_NAME": "PLAZA RESEARCH"
            },
            {
              "VENDOR_NAME": "PLEASANT HARDWARE &"
            },
            {
              "VENDOR_NAME": "PLEASANT PLAINS FIRST AID SQUAD"
            },
            {
              "VENDOR_NAME": "PLEASANTVILLE TAX-COL"
            },
            {
              "VENDOR_NAME": "PLOENER'S AUTOMOTIVE"
            },
            {
              "VENDOR_NAME": "PLUMBMASTER"
            },
            {
              "VENDOR_NAME": "PLUMSTEAD TOWNSHIP"
            },
            {
              "VENDOR_NAME": "PLUMSTEAD TWP FIRE DISTRICT #1"
            },
            {
              "VENDOR_NAME": "PLUMSTEAD TWP.EDA"
            },
            {
              "VENDOR_NAME": "PLUMSTED SCHOOL DISTRICT"
            },
            {
              "VENDOR_NAME": "PLYMOUTH GARDENS APARTMENTS"
            },
            {
              "VENDOR_NAME": "PLYMOUTH PROPERTIES"
            },
            {
              "VENDOR_NAME": "PLYMOUTH TOWNSHIP"
            },
            {
              "VENDOR_NAME": "PLYMOUTH TWP WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "PNC BANK CENTER"
            },
            {
              "VENDOR_NAME": "PNC BANK N A"
            },
            {
              "VENDOR_NAME": "PNC BANK N.A."
            },
            {
              "VENDOR_NAME": "PNC BANK"
            },
            {
              "VENDOR_NAME": "PNC BANK"
            },
            {
              "VENDOR_NAME": "PNC CAPITAL MARKETS"
            },
            {
              "VENDOR_NAME": "POCONO RECORD"
            },
            {
              "VENDOR_NAME": "POINT OF VIEW EYECARE"
            },
            {
              "VENDOR_NAME": "POINT PLAZA HOTEL"
            },
            {
              "VENDOR_NAME": "POLAR TECH INDUSTRIES"
            },
            {
              "VENDOR_NAME": "POLICE & COMMUNITY"
            },
            {
              "VENDOR_NAME": "POLICE AND FIRE MEMORIAL"
            },
            {
              "VENDOR_NAME": "POLICE ATHLETIC LEAGUE OF DE"
            },
            {
              "VENDOR_NAME": "POLICE UNITY TOUR INC."
            },
            {
              "VENDOR_NAME": "POLISH AMERICAN STRING BAND"
            },
            {
              "VENDOR_NAME": "POLK TWP VOLUNTEER FIRE COMPANY"
            },
            {
              "VENDOR_NAME": "POLLEY ASSOCIATES"
            },
            {
              "VENDOR_NAME": "POLYCOM"
            },
            {
              "VENDOR_NAME": "POMEROY INVESTMENTS LLC"
            },
            {
              "VENDOR_NAME": "PORT OF WILMINGTON"
            },
            {
              "VENDOR_NAME": "PORT RICHMOND DEV.II"
            },
            {
              "VENDOR_NAME": "PORT-A-WALL"
            },
            {
              "VENDOR_NAME": "PORTFOLIO EVALUATIONS"
            },
            {
              "VENDOR_NAME": "PORTFOLIO RECOVERY ASSOC"
            },
            {
              "VENDOR_NAME": "PORTFOLIO RECOVERY ASSOC."
            },
            {
              "VENDOR_NAME": "PORTFOLIO RECOVERY ASSOCIATES"
            },
            {
              "VENDOR_NAME": "PORTOLA PACKAGING"
            },
            {
              "VENDOR_NAME": "PORTSMOUTH CITY TREASURER"
            },
            {
              "VENDOR_NAME": "PORTSMOUTH CITY TTREAS."
            },
            {
              "VENDOR_NAME": "PORTSMOUTH GEN'L DIST COURT"
            },
            {
              "VENDOR_NAME": "PORTSMOUTH PUBLIC UTILITIES DEPT."
            },
            {
              "VENDOR_NAME": "POSITIVE PROMOTIONS"
            },
            {
              "VENDOR_NAME": "POST 137 BASEBALL PROGRAM"
            },
            {
              "VENDOR_NAME": "POSTMASTER"
            },
            {
              "VENDOR_NAME": "POSTMASTER - MEDIA"
            },
            {
              "VENDOR_NAME": "POST-NEWSWEEK MEDIA"
            },
            {
              "VENDOR_NAME": "POTOMAC ELEC POWER CO"
            },
            {
              "VENDOR_NAME": "POTOMAC ELECTRIC POWER CO."
            },
            {
              "VENDOR_NAME": "POTTSTOWN TAX COLLECTOR"
            },
            {
              "VENDOR_NAME": "POTTSTOWN WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "POUCHTTEC INDUSTRIES"
            },
            {
              "VENDOR_NAME": "PO-WELL"
            },
            {
              "VENDOR_NAME": "POWELL ROGERS & SPEAKS"
            },
            {
              "VENDOR_NAME": "POWELL"
            },
            {
              "VENDOR_NAME": "POWELL"
            },
            {
              "VENDOR_NAME": "POWELL"
            },
            {
              "VENDOR_NAME": "POWELL"
            },
            {
              "VENDOR_NAME": "POWER ASSOCIATES"
            },
            {
              "VENDOR_NAME": "POWERFUL SOLUTIONS"
            },
            {
              "VENDOR_NAME": "POWERS EQUIPMENT CO. INC"
            },
            {
              "VENDOR_NAME": "POWERTRON MAINTENANCE CO."
            },
            {
              "VENDOR_NAME": "POWHATAN CIRCUIT COURT"
            },
            {
              "VENDOR_NAME": "POWHATAN CO. TREASURER'S OFFICE"
            },
            {
              "VENDOR_NAME": "POWHATAN COMBINED COURT"
            },
            {
              "VENDOR_NAME": "POWHATAN LAWN & LANDSCAPE"
            },
            {
              "VENDOR_NAME": "POWHATAN RENEGADES"
            },
            {
              "VENDOR_NAME": "PPL ELECTRIC UTILITIES"
            },
            {
              "VENDOR_NAME": "PPL ELECTRIC UTILITIES CORP"
            },
            {
              "VENDOR_NAME": "PPL ELECTRIC UTILITIES"
            },
            {
              "VENDOR_NAME": "PPL GAS UTILITIES"
            },
            {
              "VENDOR_NAME": "PPL UTILITIES"
            },
            {
              "VENDOR_NAME": "PPL-TANNERSVILLE"
            },
            {
              "VENDOR_NAME": "PPS PRINT SOLUTIONS"
            },
            {
              "VENDOR_NAME": "PRABAKARAN KESAVAN"
            },
            {
              "VENDOR_NAME": "PRACTISING LAW INSTITUTE"
            },
            {
              "VENDOR_NAME": "PRAGMA SYSTEMS"
            },
            {
              "VENDOR_NAME": "PRAP ASSOC"
            },
            {
              "VENDOR_NAME": "PRAXAIR SERVICES INC."
            },
            {
              "VENDOR_NAME": "PRC ROTARY CHARITIES"
            },
            {
              "VENDOR_NAME": "PRE SOLUTIONS"
            },
            {
              "VENDOR_NAME": "PRECISION LAWN CARE"
            },
            {
              "VENDOR_NAME": "PRECISION SOLUTIONS"
            },
            {
              "VENDOR_NAME": "PRE-FAB STRUCTURES INC"
            },
            {
              "VENDOR_NAME": "PREFERRED CHOICE"
            },
            {
              "VENDOR_NAME": "PREMCOR"
            },
            {
              "VENDOR_NAME": "PREMIER OFFICE SOLUTIONS"
            },
            {
              "VENDOR_NAME": "PREMIERE CREDIT OF NORTH AMER"
            },
            {
              "VENDOR_NAME": "PREMIERE CREDIT OF NORTH AMERICA"
            },
            {
              "VENDOR_NAME": "PREMIUM ASSIGNMENT CORP"
            },
            {
              "VENDOR_NAME": "PREMIUM DISTRIBU"
            },
            {
              "VENDOR_NAME": "PREMIUM INGREDIENTS"
            },
            {
              "VENDOR_NAME": "PRESBYTERIAN MEDICAL CENTER"
            },
            {
              "VENDOR_NAME": "PRESIDIO NETWORKED SOLUTIONS INC."
            },
            {
              "VENDOR_NAME": "PRESSLER & PRESSLER"
            },
            {
              "VENDOR_NAME": "PRESSLER AND PRESSLER"
            },
            {
              "VENDOR_NAME": "PRESTIGE FINANCIAL SERVICE"
            },
            {
              "VENDOR_NAME": "PRESTIGE"
            },
            {
              "VENDOR_NAME": "PRETZEL CITY CHORUS"
            },
            {
              "VENDOR_NAME": "PRETZELS"
            },
            {
              "VENDOR_NAME": "PREVISOR"
            },
            {
              "VENDOR_NAME": "PRG REAL ESTATE MGMT CO INC"
            },
            {
              "VENDOR_NAME": "PRICED RITE TOWING"
            },
            {
              "VENDOR_NAME": "PRICKETT"
            },
            {
              "VENDOR_NAME": "PRIMEPAK COMPANY"
            },
            {
              "VENDOR_NAME": "PRIMETIME ADVERTISING"
            },
            {
              "VENDOR_NAME": "PRIMO PROPERTIES LLC"
            },
            {
              "VENDOR_NAME": "PRINCE EDWARD COMBINE COURT"
            },
            {
              "VENDOR_NAME": "PRINCE EDWARD COMBINED COURT"
            },
            {
              "VENDOR_NAME": "PRINCE EDWARD COUNTY"
            },
            {
              "VENDOR_NAME": "PRINCE GEORGE CIRCUIT COURT"
            },
            {
              "VENDOR_NAME": "PRINCE GEORGE COMBINED COURT"
            },
            {
              "VENDOR_NAME": "PRINCE GEORGE COUNTY"
            },
            {
              "VENDOR_NAME": "PRINCE GEORGE JOURNAL"
            },
            {
              "VENDOR_NAME": "PRINCE GEORGE'S COUNTY"
            },
            {
              "VENDOR_NAME": "PRINCE WILLIAM COUNTY"
            },
            {
              "VENDOR_NAME": "PRINCETON REGIONAL HEALTH"
            },
            {
              "VENDOR_NAME": "PRINCETON UNIV/RENT DEPT"
            },
            {
              "VENDOR_NAME": "PRINCETON UNIVERSITY"
            },
            {
              "VENDOR_NAME": "PRINT ART"
            },
            {
              "VENDOR_NAME": "PRINT ART"
            },
            {
              "VENDOR_NAME": "PRINT-ART"
            },
            {
              "VENDOR_NAME": "PRINTGLOBE"
            },
            {
              "VENDOR_NAME": "PRINTING & PACKAGING GROUP"
            },
            {
              "VENDOR_NAME": "PRIOLO BAKERY"
            },
            {
              "VENDOR_NAME": "PRIORITY EXPRESS COURIER"
            },
            {
              "VENDOR_NAME": "PRIORITY TOYOTA"
            },
            {
              "VENDOR_NAME": "PRISCILLA G. SANDS"
            },
            {
              "VENDOR_NAME": "PRL ROCKY 518"
            },
            {
              "VENDOR_NAME": "PROCESS IMPROVEMENT INSTITUTE"
            },
            {
              "VENDOR_NAME": "PROCESS ONE"
            },
            {
              "VENDOR_NAME": "PROCESSFLO"
            },
            {
              "VENDOR_NAME": "PROCTER & GAMBLE"
            },
            {
              "VENDOR_NAME": "ProData"
            },
            {
              "VENDOR_NAME": "PRODUCT EMPHASIS DIVISION OF"
            },
            {
              "VENDOR_NAME": "PROFESSIONAL COURIER"
            },
            {
              "VENDOR_NAME": "PROFESSIONAL CREATIVE SERVICES"
            },
            {
              "VENDOR_NAME": "PROFESSIONAL CREDIT MANAGEMENT"
            },
            {
              "VENDOR_NAME": "PROFESSIONAL DUPLICATING"
            },
            {
              "VENDOR_NAME": "PROFESSIONAL EDUCATION SYSTEMS"
            },
            {
              "VENDOR_NAME": "PROFESSIONAL REPORTERS"
            },
            {
              "VENDOR_NAME": "PROFESSIONAL SERVICE INDUSTRIES"
            },
            {
              "VENDOR_NAME": "PROGRESSIVE BUSINESS PUBLICATIONS"
            },
            {
              "VENDOR_NAME": "PROGRESSIVE FINANCIAL SERVICE"
            },
            {
              "VENDOR_NAME": "PROGRESSIVE FINANCIAL SERVICES"
            },
            {
              "VENDOR_NAME": "PROGRESSIVE SOFTWARE"
            },
            {
              "VENDOR_NAME": "PROJECT FORWARD LEAP"
            },
            {
              "VENDOR_NAME": "PROJECT FREEDOM"
            },
            {
              "VENDOR_NAME": "PROJECT H.O.P.E"
            },
            {
              "VENDOR_NAME": "PROJECT MANAGEMENT INST."
            },
            {
              "VENDOR_NAME": "PROLINE CONSTRUCTION COMPANY"
            },
            {
              "VENDOR_NAME": "PROMOTIONAL MARKETING INC."
            },
            {
              "VENDOR_NAME": "PROMOWORKS LLC"
            },
            {
              "VENDOR_NAME": "PRO-ONE"
            },
            {
              "VENDOR_NAME": "PROPERTY RESTORATION"
            },
            {
              "VENDOR_NAME": "PROQUIRE LLC"
            },
            {
              "VENDOR_NAME": "PROSCI INC"
            },
            {
              "VENDOR_NAME": "PROSPECT PARK FIRE COMPANY"
            },
            {
              "VENDOR_NAME": "PROSPECT TRANSPORTATION"
            },
            {
              "VENDOR_NAME": "PROSPECT UNITED METHODIST CHURCH"
            },
            {
              "VENDOR_NAME": "PROSPERITY BEACH"
            },
            {
              "VENDOR_NAME": "PRO-TECH FLOORS LLC"
            },
            {
              "VENDOR_NAME": "PROVIDENCE DANE"
            },
            {
              "VENDOR_NAME": "PROVIDENCE ENGINEERING CORPORATION"
            },
            {
              "VENDOR_NAME": "PROVIDENCE HOUSE"
            },
            {
              "VENDOR_NAME": "PRSI TRADING COMPANY LP"
            },
            {
              "VENDOR_NAME": "PRUDENTIAL ZACK"
            },
            {
              "VENDOR_NAME": "PS BUSINESS INTERIORS"
            },
            {
              "VENDOR_NAME": "PSP BIKE PATROL"
            },
            {
              "VENDOR_NAME": "PT PLEASANT BD OF HEALTH"
            },
            {
              "VENDOR_NAME": "PUBLIC AGENCY TRAINING COUNCIL (PA"
            },
            {
              "VENDOR_NAME": "PUBLIC GOODS POOL"
            },
            {
              "VENDOR_NAME": "PUBLIC STORAGE"
            },
            {
              "VENDOR_NAME": "PUBLIC STORAGE #5440"
            },
            {
              "VENDOR_NAME": "PULS"
            },
            {
              "VENDOR_NAME": "PURCHASE POWER"
            },
            {
              "VENDOR_NAME": "PURVEYORS CREDIT ASSOC INC"
            },
            {
              "VENDOR_NAME": "PWC CIRCUIT COURT"
            },
            {
              "VENDOR_NAME": "PWC PRODUCT SALES LLC"
            },
            {
              "VENDOR_NAME": "PWD LUBRICANTS"
            },
            {
              "VENDOR_NAME": "PWI INCORPORATED"
            },
            {
              "VENDOR_NAME": "PYNN CORPORATION"
            },
            {
              "VENDOR_NAME": "PYRAMYD DESIGNS"
            },
            {
              "VENDOR_NAME": "Q BARBEQUE"
            },
            {
              "VENDOR_NAME": "Q P INVESTMENTS"
            },
            {
              "VENDOR_NAME": "QDC PLASTIC CONTAINER CO."
            },
            {
              "VENDOR_NAME": "QUAD656 LLC"
            },
            {
              "VENDOR_NAME": "QUAD656"
            },
            {
              "VENDOR_NAME": "QUAKER"
            },
            {
              "VENDOR_NAME": "QUAKER FOODS AND BEVERAGES"
            },
            {
              "VENDOR_NAME": "QUAKERTOWN BOROUGH"
            },
            {
              "VENDOR_NAME": "QUAKERTOWN COMM SCH DIST"
            },
            {
              "VENDOR_NAME": "QUAKERTOWN FIRE CO. NO. 1"
            },
            {
              "VENDOR_NAME": "QUALITY BAKERIES LLC"
            },
            {
              "VENDOR_NAME": "QUALITY BROKERAGE"
            },
            {
              "VENDOR_NAME": "QUALITY CONTROL LABORATORY"
            },
            {
              "VENDOR_NAME": "QUALITY ENTERPRISES USA"
            },
            {
              "VENDOR_NAME": "QUALITY INN"
            },
            {
              "VENDOR_NAME": "QUALITY INN & SUITES"
            },
            {
              "VENDOR_NAME": "QUALITY PENN PRODUCTS"
            },
            {
              "VENDOR_NAME": "QUALITY STONE & CONCRETE"
            },
            {
              "VENDOR_NAME": "QUALITY SUITES AT LAKE WRIGHT"
            },
            {
              "VENDOR_NAME": "QUANTAFIRE"
            },
            {
              "VENDOR_NAME": "QUEEN VILLAGE NEIGHBORS ASSOC."
            },
            {
              "VENDOR_NAME": "QUEENS LAKE MIDDLE SCHOOL"
            },
            {
              "VENDOR_NAME": "QUEENS SCU"
            },
            {
              "VENDOR_NAME": "QUEENSTOWN BANK OF MARYLAND"
            },
            {
              "VENDOR_NAME": "QUENCH USA"
            },
            {
              "VENDOR_NAME": "QUEST DIAGNOSTICS"
            },
            {
              "VENDOR_NAME": "QUETECH LTD"
            },
            {
              "VENDOR_NAME": "QUICK CASH"
            },
            {
              "VENDOR_NAME": "QUICK FITNESS SOLUTIONS"
            },
            {
              "VENDOR_NAME": "QUIKTRIP CORPORATION"
            },
            {
              "VENDOR_NAME": "QUINLAN WELL DRILLING"
            },
            {
              "VENDOR_NAME": "QUINN CONSTRUCTION"
            },
            {
              "VENDOR_NAME": "QUINN PROPERTIES"
            },
            {
              "VENDOR_NAME": "QUINT COMPANY"
            },
            {
              "VENDOR_NAME": "QWEST"
            },
            {
              "VENDOR_NAME": "QWEST GOVERNMENT SERVICES"
            },
            {
              "VENDOR_NAME": "R & R SYSTEMS"
            },
            {
              "VENDOR_NAME": "R & T TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "R A GOLDENBERG&I M POPKIN"
            },
            {
              "VENDOR_NAME": "R C CROCKETT JR CONSTABLE"
            },
            {
              "VENDOR_NAME": "R CLINTON STACKHOUSE"
            },
            {
              "VENDOR_NAME": "R CLINTON STACKHOUSE JR"
            },
            {
              "VENDOR_NAME": "R W FETTERS INC"
            },
            {
              "VENDOR_NAME": "R&R PETROLEUM L.P."
            },
            {
              "VENDOR_NAME": "R. C. CROCKETT CONSTABLE"
            },
            {
              "VENDOR_NAME": "R. C. CROCKETT JR"
            },
            {
              "VENDOR_NAME": "R. C. CROCKETT JR."
            },
            {
              "VENDOR_NAME": "R. F. WELSH COURT OFFICER"
            },
            {
              "VENDOR_NAME": "R. TORRE & COMPANY"
            },
            {
              "VENDOR_NAME": "R.A.BARRETT & ASSOCIATES"
            },
            {
              "VENDOR_NAME": "R.C. CROCKETT"
            },
            {
              "VENDOR_NAME": "R.C. CROCKETT CONSTABLE"
            },
            {
              "VENDOR_NAME": "R.C. CROCKETT JR"
            },
            {
              "VENDOR_NAME": "R.C. CROCKETT JR CONSTABLE"
            },
            {
              "VENDOR_NAME": "R.C. CROCKETT JR."
            },
            {
              "VENDOR_NAME": "R.C. CROCKETT JR. CONSTABLE"
            },
            {
              "VENDOR_NAME": "R.C. CROCKETT"
            },
            {
              "VENDOR_NAME": "R.C. CROCKETT"
            },
            {
              "VENDOR_NAME": "R.C. CROCKETT"
            },
            {
              "VENDOR_NAME": "R.C. CROCKETT"
            },
            {
              "VENDOR_NAME": "R.C.CROCKETT JR."
            },
            {
              "VENDOR_NAME": "R.C.CROCKETT JR. CONSTABLE"
            },
            {
              "VENDOR_NAME": "R.C.CROCKETT JR."
            },
            {
              "VENDOR_NAME": "R.C.CROCKETT"
            },
            {
              "VENDOR_NAME": "R.C.CROCKETT"
            },
            {
              "VENDOR_NAME": "R.C.CROCKETT"
            },
            {
              "VENDOR_NAME": "R.F. WELSH COURT OFFICER"
            },
            {
              "VENDOR_NAME": "R.F.WELSH CT OFFICER"
            },
            {
              "VENDOR_NAME": "R.F.WELSH CT. OFFICER"
            },
            {
              "VENDOR_NAME": "R.H.VASSALLO INC"
            },
            {
              "VENDOR_NAME": "R.J. MCCARVILLE ASSOCIATES"
            },
            {
              "VENDOR_NAME": "R.L.Albert & Sons Inc."
            },
            {
              "VENDOR_NAME": "R.M. PALMER COMPANY"
            },
            {
              "VENDOR_NAME": "R.P.MURPHY"
            },
            {
              "VENDOR_NAME": "R.P.MURPHY"
            },
            {
              "VENDOR_NAME": "R.STOCKER & SONS"
            },
            {
              "VENDOR_NAME": "R.T.O. INC"
            },
            {
              "VENDOR_NAME": "R.V.TURNER CONSTRUCTION"
            },
            {
              "VENDOR_NAME": "R.W.RIGGINS"
            },
            {
              "VENDOR_NAME": "R.W.WALLACE INC"
            },
            {
              "VENDOR_NAME": "R.WAYNE & SUSAN J.RAFFERTY"
            },
            {
              "VENDOR_NAME": "RACCAP-RAPPAHANNOCK AREA COUNCIL"
            },
            {
              "VENDOR_NAME": "RACHEL A.WETTSTEIN"
            },
            {
              "VENDOR_NAME": "RACHEL BRANDNER"
            },
            {
              "VENDOR_NAME": "RACHEL ENTERPRISES"
            },
            {
              "VENDOR_NAME": "RACHEL PINARDO"
            },
            {
              "VENDOR_NAME": "RADCLYFFE F & MARIA M THOMPSON"
            },
            {
              "VENDOR_NAME": "RADCLYFFE F. AND MARIA M THOMPSON"
            },
            {
              "VENDOR_NAME": "RADIANT SYSTEMS"
            },
            {
              "VENDOR_NAME": "RADIO COMMUNICATIONS"
            },
            {
              "VENDOR_NAME": "RADISSON HOTEL"
            },
            {
              "VENDOR_NAME": "RADISSON HOTEL MT LAUREL"
            },
            {
              "VENDOR_NAME": "RADISSON HOTELS & RESORTS"
            },
            {
              "VENDOR_NAME": "RADISSON PLAZA WARWICK HOTEL"
            },
            {
              "VENDOR_NAME": "RADNOR TOWNSHIP"
            },
            {
              "VENDOR_NAME": "RAFFEINEE S WORSLEY"
            },
            {
              "VENDOR_NAME": "RAGAN COMMUNICATIONS"
            },
            {
              "VENDOR_NAME": "RAGE SOFTBALL"
            },
            {
              "VENDOR_NAME": "RAHMAN RICE"
            },
            {
              "VENDOR_NAME": "RAINBOW CAB"
            },
            {
              "VENDOR_NAME": "RAINMAKER INC"
            },
            {
              "VENDOR_NAME": "RAJAH SHRINE CHANTERS"
            },
            {
              "VENDOR_NAME": "RALCORP FROZEN BAKERY"
            },
            {
              "VENDOR_NAME": "RALPH A.CORBMAN"
            },
            {
              "VENDOR_NAME": "RALPH A.PAOLONE"
            },
            {
              "VENDOR_NAME": "RALPH B WATSON & SON"
            },
            {
              "VENDOR_NAME": "RALPH CLAYTON & SONS"
            },
            {
              "VENDOR_NAME": "RALPH GARROW"
            },
            {
              "VENDOR_NAME": "RALPH H COLFLESH"
            },
            {
              "VENDOR_NAME": "RALPH L.BIBBUS"
            },
            {
              "VENDOR_NAME": "RALPH MORAN"
            },
            {
              "VENDOR_NAME": "RALPH MORAN COURT OFFICER"
            },
            {
              "VENDOR_NAME": "RALPH MORAN CT OFFICER"
            },
            {
              "VENDOR_NAME": "RALPH MORAN -XXX-XX-9038"
            },
            {
              "VENDOR_NAME": "RALPH MORAN"
            },
            {
              "VENDOR_NAME": "RALPH MORAN"
            },
            {
              "VENDOR_NAME": "RALPH MORGAN COURT OFFICER"
            },
            {
              "VENDOR_NAME": "RALPH P. DelCOLLO"
            },
            {
              "VENDOR_NAME": "RALPH P.DelCOLLO"
            },
            {
              "VENDOR_NAME": "RALPH TAGGETT"
            },
            {
              "VENDOR_NAME": "RAMADA"
            },
            {
              "VENDOR_NAME": "RAMADA CONFERENCE CENTER"
            },
            {
              "VENDOR_NAME": "RAMADA HOTEL"
            },
            {
              "VENDOR_NAME": "RAMADA INN"
            },
            {
              "VENDOR_NAME": "RAMADA INN & CONFERENCE CENTER"
            },
            {
              "VENDOR_NAME": "RAMADA INN AIRPORT SOUTH"
            },
            {
              "VENDOR_NAME": "RAMADA INN VINELAND"
            },
            {
              "VENDOR_NAME": "RAMADA INN-SOUTH"
            },
            {
              "VENDOR_NAME": "RAMADA INN-TRENTON"
            },
            {
              "VENDOR_NAME": "RAMEY KEMP & ASSOC. OF RICHMOND"
            },
            {
              "VENDOR_NAME": "RAMIRO A. MACHADO"
            },
            {
              "VENDOR_NAME": "RAMTOWN PLAZA"
            },
            {
              "VENDOR_NAME": "RAMZ LLC"
            },
            {
              "VENDOR_NAME": "RAMZ"
            },
            {
              "VENDOR_NAME": "RANALLI & JOYCE LLC"
            },
            {
              "VENDOR_NAME": "RANALLI INC. SOUTH"
            },
            {
              "VENDOR_NAME": "RANDELL MANUFACTURING INC"
            },
            {
              "VENDOR_NAME": "RANDOLPH ASSOCIATES"
            },
            {
              "VENDOR_NAME": "RANDOLPH WARD BRINTON"
            },
            {
              "VENDOR_NAME": "RANDSTAD"
            },
            {
              "VENDOR_NAME": "RANKIN AUTOMATION"
            },
            {
              "VENDOR_NAME": "RANSOME LIFT"
            },
            {
              "VENDOR_NAME": "RANSOME RENTAL CO."
            },
            {
              "VENDOR_NAME": "RAP PHOENIXVILLE"
            },
            {
              "VENDOR_NAME": "RAPHEL MARKETING"
            },
            {
              "VENDOR_NAME": "RAPID RESPONSE"
            },
            {
              "VENDOR_NAME": "RAPID7"
            },
            {
              "VENDOR_NAME": "RAPIDFORMS"
            },
            {
              "VENDOR_NAME": "RAPPAHANNOCK AREA YMCA"
            },
            {
              "VENDOR_NAME": "RAPPAHANNOCK ELECTRIC COOPERATIVE"
            },
            {
              "VENDOR_NAME": "RAPPAHANNOCK FAMILY PHYSICIANS"
            },
            {
              "VENDOR_NAME": "RAPPAHANNOCK PETROLEUM LLC"
            },
            {
              "VENDOR_NAME": "RARITAN BAY COUGARS"
            },
            {
              "VENDOR_NAME": "RARITAN TOWNSHIP"
            },
            {
              "VENDOR_NAME": "RARITAN TWP MUA"
            },
            {
              "VENDOR_NAME": "RARITAN VALLEY DISPOSAL"
            },
            {
              "VENDOR_NAME": "RARITAN VALLEY HABITAT"
            },
            {
              "VENDOR_NAME": "RASHMI BUDHRAM"
            },
            {
              "VENDOR_NAME": "RATHGEBER AND ASSOCIATES"
            },
            {
              "VENDOR_NAME": "RATIONAL COOKING SYSTEMS"
            },
            {
              "VENDOR_NAME": "RAVINDRA YALAMANCHILI"
            },
            {
              "VENDOR_NAME": "RAY BANASIAK"
            },
            {
              "VENDOR_NAME": "RAY CHAPMAN"
            },
            {
              "VENDOR_NAME": "RAY PICCOLO REALTY"
            },
            {
              "VENDOR_NAME": "RAY ROSE"
            },
            {
              "VENDOR_NAME": "RAY SCHEIER"
            },
            {
              "VENDOR_NAME": "RAY WALL"
            },
            {
              "VENDOR_NAME": "RAY YARKEY"
            },
            {
              "VENDOR_NAME": "RAYMOND A. PERONE"
            },
            {
              "VENDOR_NAME": "RAYMOND CAVANAUGH"
            },
            {
              "VENDOR_NAME": "RAYMOND EMERLE"
            },
            {
              "VENDOR_NAME": "RAYMOND F WELSH COURT OFFICER"
            },
            {
              "VENDOR_NAME": "RAYMOND F. WELSH COURT OFFICER"
            },
            {
              "VENDOR_NAME": "RAYMOND F. WELSH"
            },
            {
              "VENDOR_NAME": "RAYMOND LONG"
            },
            {
              "VENDOR_NAME": "RAYMOND M.PHILLIPS &"
            },
            {
              "VENDOR_NAME": "RAYMOND ROSE"
            },
            {
              "VENDOR_NAME": "RAYMOND TONDREAU"
            },
            {
              "VENDOR_NAME": "RAYMOND WALSH COURT OFFICER"
            },
            {
              "VENDOR_NAME": "RAYMOND WELSH"
            },
            {
              "VENDOR_NAME": "RAYMOND WELSH COURT OFFICER"
            },
            {
              "VENDOR_NAME": "RAYMOND WELSH CT. OFFICER"
            },
            {
              "VENDOR_NAME": "RAYMOND WELSH CT.OFFICER"
            },
            {
              "VENDOR_NAME": "R-B ASSOCIATES"
            },
            {
              "VENDOR_NAME": "RB QUAKERTOWN LP"
            },
            {
              "VENDOR_NAME": "RBMM"
            },
            {
              "VENDOR_NAME": "RC COMMERCIAL REALTY"
            },
            {
              "VENDOR_NAME": "RC CROCKETT"
            },
            {
              "VENDOR_NAME": "RC CROCKETT JR CONSTABLE"
            },
            {
              "VENDOR_NAME": "RDS OF VA"
            },
            {
              "VENDOR_NAME": "READING EAGLE COMPANY"
            },
            {
              "VENDOR_NAME": "READING ROYALS"
            },
            {
              "VENDOR_NAME": "READINGTON TOWNSHIP SEWER"
            },
            {
              "VENDOR_NAME": "REAL FOUNDATIONS"
            },
            {
              "VENDOR_NAME": "REALTIME MEDIA"
            },
            {
              "VENDOR_NAME": "REAVES C LUKENS COMPANY"
            },
            {
              "VENDOR_NAME": "RECEIPTING UNIT"
            },
            {
              "VENDOR_NAME": "RECKITT BENCKISER INC."
            },
            {
              "VENDOR_NAME": "RECO BIOTECHNOLOGY"
            },
            {
              "VENDOR_NAME": "RECORDER OF DEEDS-DE"
            },
            {
              "VENDOR_NAME": "RECOVERY CONCEPTS INC."
            },
            {
              "VENDOR_NAME": "RED BULL DISTRIBUTING COMPANY"
            },
            {
              "VENDOR_NAME": "RED HAWK"
            },
            {
              "VENDOR_NAME": "RED LION GROUP INC"
            },
            {
              "VENDOR_NAME": "RED ROOF INN"
            },
            {
              "VENDOR_NAME": "RED TREE LEADERSHIP"
            },
            {
              "VENDOR_NAME": "RED WING SHOE STORE"
            },
            {
              "VENDOR_NAME": "RED WING SHOES"
            },
            {
              "VENDOR_NAME": "REDNER'S MARKET"
            },
            {
              "VENDOR_NAME": "REDWOOD CAPITAL INVESTMENTS"
            },
            {
              "VENDOR_NAME": "REDWOOD HOLDINGS LLC"
            },
            {
              "VENDOR_NAME": "REDWOOD SOFTWARE INC"
            },
            {
              "VENDOR_NAME": "REDWOOD WW FREDERICK TWP LLLP"
            },
            {
              "VENDOR_NAME": "REDWOOD WW GAMBRILLS"
            },
            {
              "VENDOR_NAME": "REDWOOD WW PEMBERTON LLC"
            },
            {
              "VENDOR_NAME": "REDWOOD WW PLUMSTED"
            },
            {
              "VENDOR_NAME": "REDWOOD WW SCHUYLKILL TOWNSHIP"
            },
            {
              "VENDOR_NAME": "REED INVESTMENTS"
            },
            {
              "VENDOR_NAME": "REED J. ROBINSON"
            },
            {
              "VENDOR_NAME": "REED SCREED SALES & SERVICES"
            },
            {
              "VENDOR_NAME": "REES INDUSTRIAL INC."
            },
            {
              "VENDOR_NAME": "REFRIGERATION DESIGN &"
            },
            {
              "VENDOR_NAME": "REFRIGERATION ENGINEERS &"
            },
            {
              "VENDOR_NAME": "RefrigiWear"
            },
            {
              "VENDOR_NAME": "REGAL CINEMEDIA"
            },
            {
              "VENDOR_NAME": "REGAL REAL ESTATE"
            },
            {
              "VENDOR_NAME": "REGENCY CHEMICAL CORP"
            },
            {
              "VENDOR_NAME": "REGENCY OPITZ"
            },
            {
              "VENDOR_NAME": "REGENCY WAREHOUSING"
            },
            {
              "VENDOR_NAME": "REGIONAL REALTY INC"
            },
            {
              "VENDOR_NAME": "REGIONAL TIRE SERVICE"
            },
            {
              "VENDOR_NAME": "REGISTER NOW"
            },
            {
              "VENDOR_NAME": "REHOBETH & DEWEY BEACH"
            },
            {
              "VENDOR_NAME": "REHOBOTH BEACH FILM SOCIETY"
            },
            {
              "VENDOR_NAME": "REHOBOTH U STORE"
            },
            {
              "VENDOR_NAME": "REHRIG PACIFIC COMPANY"
            },
            {
              "VENDOR_NAME": "REICHERT"
            },
            {
              "VENDOR_NAME": "REINALDO SANTIAGO"
            },
            {
              "VENDOR_NAME": "REITNOUR INVESTMENT PROP"
            },
            {
              "VENDOR_NAME": "RELEVANTE"
            },
            {
              "VENDOR_NAME": "RELIABLE FIRE PROTECTION"
            },
            {
              "VENDOR_NAME": "REM SYSTEMS"
            },
            {
              "VENDOR_NAME": "REMCO"
            },
            {
              "VENDOR_NAME": "REMEMBERING JAMIE FOUNDATION"
            },
            {
              "VENDOR_NAME": "RENEE KAUN"
            },
            {
              "VENDOR_NAME": "RENNER BROWN INC"
            },
            {
              "VENDOR_NAME": "RENTAL SERVICE CORPORATION"
            },
            {
              "VENDOR_NAME": "RENTAL WORLD"
            },
            {
              "VENDOR_NAME": "REPUBLIC NATIONA"
            },
            {
              "VENDOR_NAME": "RESCUE REMEDIES INC."
            },
            {
              "VENDOR_NAME": "RESEARCH DESIGN PARTNERS"
            },
            {
              "VENDOR_NAME": "RESEARCH DESIGN TEAM"
            },
            {
              "VENDOR_NAME": "RESEARCH IN MOTION CORPORATION"
            },
            {
              "VENDOR_NAME": "RESER'S FINE FOODS"
            },
            {
              "VENDOR_NAME": "RES-KEM CORP."
            },
            {
              "VENDOR_NAME": "RESNICK AND ABRAHAM"
            },
            {
              "VENDOR_NAME": "RESORT MAPS OF SOUTH JERSEY"
            },
            {
              "VENDOR_NAME": "RESORTS ATLANTIC CITY"
            },
            {
              "VENDOR_NAME": "RESOURCES CONNECTION"
            },
            {
              "VENDOR_NAME": "RESOURCES GLOBAL PROFESSIONALS"
            },
            {
              "VENDOR_NAME": "RESOURCES IN DISPLAY"
            },
            {
              "VENDOR_NAME": "RESPECT"
            },
            {
              "VENDOR_NAME": "RESPONSIBLE TOBACCO SALE"
            },
            {
              "VENDOR_NAME": "RESTAURANT NEWS"
            },
            {
              "VENDOR_NAME": "RETA"
            },
            {
              "VENDOR_NAME": "RETA CONFERENCE"
            },
            {
              "VENDOR_NAME": "RETAIL ALLIANCE"
            },
            {
              "VENDOR_NAME": "RETAIL BUSINESS SUPPLIES"
            },
            {
              "VENDOR_NAME": "RETAIL MERCHANTS ASSOC."
            },
            {
              "VENDOR_NAME": "RETAIL PROCESS ENGINEERING"
            },
            {
              "VENDOR_NAME": "RETAIL TECH INC"
            },
            {
              "VENDOR_NAME": "RETIRE-IT"
            },
            {
              "VENDOR_NAME": "RETROSPECT"
            },
            {
              "VENDOR_NAME": "REVENUE COLLECTIONS"
            },
            {
              "VENDOR_NAME": "REX & SON'S INC"
            },
            {
              "VENDOR_NAME": "REX ANDERSON"
            },
            {
              "VENDOR_NAME": "REXEL"
            },
            {
              "VENDOR_NAME": "REYES HOLDING LLC"
            },
            {
              "VENDOR_NAME": "REYMET COMMUNITY FEDERAL C.U."
            },
            {
              "VENDOR_NAME": "REYNOLDS AMERICA"
            },
            {
              "VENDOR_NAME": "RF WELSH COURT OFFICER"
            },
            {
              "VENDOR_NAME": "RF WELSH CT. OFFICER"
            },
            {
              "VENDOR_NAME": "RGIS-INVENTORY SPECIALISTS"
            },
            {
              "VENDOR_NAME": "RHETT KIRCHHOFF"
            },
            {
              "VENDOR_NAME": "RHONDA L.WIESE"
            },
            {
              "VENDOR_NAME": "RIBB SHEET METAL"
            },
            {
              "VENDOR_NAME": "RIBBONS EXPRESS INC"
            },
            {
              "VENDOR_NAME": "RIBBONS UNLIMITED"
            },
            {
              "VENDOR_NAME": "RICH McNEILL"
            },
            {
              "VENDOR_NAME": "RICH PRODUCTS CORPORATION"
            },
            {
              "VENDOR_NAME": "RICHARD & LOUISA WOOD TRUST 12-20-3"
            },
            {
              "VENDOR_NAME": "RICHARD A CORDLE TREASURER"
            },
            {
              "VENDOR_NAME": "RICHARD A GODFREY"
            },
            {
              "VENDOR_NAME": "RICHARD A. FINE"
            },
            {
              "VENDOR_NAME": "RICHARD BATEMAN"
            },
            {
              "VENDOR_NAME": "RICHARD C HAILEY COURT OFFICER"
            },
            {
              "VENDOR_NAME": "RICHARD C TYLER"
            },
            {
              "VENDOR_NAME": "RICHARD C. GARRETSON"
            },
            {
              "VENDOR_NAME": "RICHARD C. HAILEY JR."
            },
            {
              "VENDOR_NAME": "RICHARD C. HAILEY JR. COURT OFFICER"
            },
            {
              "VENDOR_NAME": "RICHARD CAGLEY"
            },
            {
              "VENDOR_NAME": "RICHARD CAVANAUGH"
            },
            {
              "VENDOR_NAME": "RICHARD CORDLE"
            },
            {
              "VENDOR_NAME": "RICHARD CROCKETT COURT OFFICER"
            },
            {
              "VENDOR_NAME": "RICHARD D WOOD JR"
            },
            {
              "VENDOR_NAME": "RICHARD DILLOW"
            },
            {
              "VENDOR_NAME": "RICHARD E. CUMMINES"
            },
            {
              "VENDOR_NAME": "RICHARD EPPS"
            },
            {
              "VENDOR_NAME": "RICHARD F. JOHNSON"
            },
            {
              "VENDOR_NAME": "RICHARD GREENWALD SR."
            },
            {
              "VENDOR_NAME": "RICHARD GREENWALD"
            },
            {
              "VENDOR_NAME": "RICHARD GROSSO"
            },
            {
              "VENDOR_NAME": "RICHARD GRUB AND ASSOCIATES"
            },
            {
              "VENDOR_NAME": "RICHARD HAILEY"
            },
            {
              "VENDOR_NAME": "RICHARD HAILEY COURT OFFICER"
            },
            {
              "VENDOR_NAME": "RICHARD J NORMAN COURT OFFICER"
            },
            {
              "VENDOR_NAME": "RICHARD J SHAFFER"
            },
            {
              "VENDOR_NAME": "RICHARD J. GEORGE PH.D."
            },
            {
              "VENDOR_NAME": "RICHARD J. KOHLER"
            },
            {
              "VENDOR_NAME": "RICHARD J. NORMAN"
            },
            {
              "VENDOR_NAME": "RICHARD J. NORMAN COURT OFFICER"
            },
            {
              "VENDOR_NAME": "RICHARD J. NORMAN CT OFFICER"
            },
            {
              "VENDOR_NAME": "RICHARD J.KRATZINGER"
            },
            {
              "VENDOR_NAME": "RICHARD J.NORMAN"
            },
            {
              "VENDOR_NAME": "RICHARD KUHNS"
            },
            {
              "VENDOR_NAME": "RICHARD L. SENSENIG COMPANY"
            },
            {
              "VENDOR_NAME": "RICHARD LAPP"
            },
            {
              "VENDOR_NAME": "RICHARD M. PESCATORE"
            },
            {
              "VENDOR_NAME": "RICHARD NORMAN"
            },
            {
              "VENDOR_NAME": "RICHARD NORMAN COURT OFFICER"
            },
            {
              "VENDOR_NAME": "RICHARD NORMAN"
            },
            {
              "VENDOR_NAME": "RICHARD NORMAN"
            },
            {
              "VENDOR_NAME": "RICHARD O. KING SR & ASSOC"
            },
            {
              "VENDOR_NAME": "RICHARD POWELL"
            },
            {
              "VENDOR_NAME": "RICHARD WEAVER"
            },
            {
              "VENDOR_NAME": "RICHARD WILSON - INTERNAL TECH"
            },
            {
              "VENDOR_NAME": "RICHARDSON'S FLORAL CENTER"
            },
            {
              "VENDOR_NAME": "RICHLAND TOWNSHIP"
            },
            {
              "VENDOR_NAME": "RICHLAND TOWNSHIP WATER AUTHORITY"
            },
            {
              "VENDOR_NAME": "RICHLAND TWP FIRE AND RESCUE"
            },
            {
              "VENDOR_NAME": "RICHLAND TWP TAX COLL."
            },
            {
              "VENDOR_NAME": "RICHLIN FOOD CORP."
            },
            {
              "VENDOR_NAME": "RICHMAN & FERREN"
            },
            {
              "VENDOR_NAME": "RICHMOND"
            },
            {
              "VENDOR_NAME": "RICHMOND CITY GEN'L DIST COURT"
            },
            {
              "VENDOR_NAME": "RICHMOND CITY GEN'L DIST CT"
            },
            {
              "VENDOR_NAME": "RICHMOND DUCKPIN BOWLING LLC"
            },
            {
              "VENDOR_NAME": "RICHMOND GENERAL DIST COURT"
            },
            {
              "VENDOR_NAME": "RICHMOND GEN'L DISTRICT COURT"
            },
            {
              "VENDOR_NAME": "RICHMOND GLASS SHOP"
            },
            {
              "VENDOR_NAME": "RICHMOND REFRIGERATION"
            },
            {
              "VENDOR_NAME": "RICHMOND TIMES DISPATCH"
            },
            {
              "VENDOR_NAME": "RICK DOWNES"
            },
            {
              "VENDOR_NAME": "RICK SCHULT"
            },
            {
              "VENDOR_NAME": "RICK WIEDMANN"
            },
            {
              "VENDOR_NAME": "RICK'S AUTO REPAIR"
            },
            {
              "VENDOR_NAME": "RICK'S UTILITY SHEDS"
            },
            {
              "VENDOR_NAME": "RICKY WARWICK"
            },
            {
              "VENDOR_NAME": "RICOH AMERICAS CORP"
            },
            {
              "VENDOR_NAME": "RICOH AMERICAS CORPORATION"
            },
            {
              "VENDOR_NAME": "RIC-SAN"
            },
            {
              "VENDOR_NAME": "RIDDLE HEALTH CARE FOUNDATION"
            },
            {
              "VENDOR_NAME": "RIDDLE MEMORIAL HOSP.CARDIOLOGY GRO"
            },
            {
              "VENDOR_NAME": "RIDDLE MEMORIAL HOSPITAL"
            },
            {
              "VENDOR_NAME": "RIDDLE MEMORIAL HOSPITAL PRO AM"
            },
            {
              "VENDOR_NAME": "RIDDLE VILLAGE"
            },
            {
              "VENDOR_NAME": "RIDDLE VILLAGE SUPPORT"
            },
            {
              "VENDOR_NAME": "RIDGE OFFICE EQUIPMENT CO"
            },
            {
              "VENDOR_NAME": "RIDGID FIRE SPRINKLER"
            },
            {
              "VENDOR_NAME": "RIDLEY FENCE COMPANY"
            },
            {
              "VENDOR_NAME": "RIDLEY JR.ABA"
            },
            {
              "VENDOR_NAME": "RIDLEY PARK BOROUGH"
            },
            {
              "VENDOR_NAME": "RIDLEY SCHOOL DISTRICT"
            },
            {
              "VENDOR_NAME": "RIDLEY SWIM BOOSTERS"
            },
            {
              "VENDOR_NAME": "RIDLEY TWSP OCCUPAT'L TAX"
            },
            {
              "VENDOR_NAME": "RIEKER"
            },
            {
              "VENDOR_NAME": "RIFKIN"
            },
            {
              "VENDOR_NAME": "RIGHT MANAGEMEMT CONSULTANTS"
            },
            {
              "VENDOR_NAME": "RIL USA INC."
            },
            {
              "VENDOR_NAME": "RILEY RIPER HOLLIN ETAL"
            },
            {
              "VENDOR_NAME": "RING CONSULTING GROUP"
            },
            {
              "VENDOR_NAME": "RIO ENERGY CORPORATION"
            },
            {
              "VENDOR_NAME": "RIO GRANDE VOLUNTEER FIRE CO."
            },
            {
              "VENDOR_NAME": "RISK AND INSURANCE"
            },
            {
              "VENDOR_NAME": "RISK ENTERPRISE MGMT LIMITED"
            },
            {
              "VENDOR_NAME": "RITCHIE INTERCHANGE"
            },
            {
              "VENDOR_NAME": "RITE AID OF VIRGINIA"
            },
            {
              "VENDOR_NAME": "RITE-WAY ELECTRIC INC"
            },
            {
              "VENDOR_NAME": "RITTER & PLANTE ASSOCIATES"
            },
            {
              "VENDOR_NAME": "RITZ CAMERA CENTERS"
            },
            {
              "VENDOR_NAME": "RIVER CITY TRUCK/CAR TIRE & MECHANI"
            },
            {
              "VENDOR_NAME": "RIVERFRONT AUDIO VISUAL"
            },
            {
              "VENDOR_NAME": "RIVERFRONT AUDIO VISUAL"
            },
            {
              "VENDOR_NAME": "RIVER'S BEND BUSINESS CENTER"
            },
            {
              "VENDOR_NAME": "RIVERSIDE CHILD SUPPORT"
            },
            {
              "VENDOR_NAME": "RIVERVIEW DELI"
            },
            {
              "VENDOR_NAME": "RIVERVIEW FOUNDATION"
            },
            {
              "VENDOR_NAME": "RIVIERA FINANCE-NORTHEAST"
            },
            {
              "VENDOR_NAME": "RJR TOBACCO CO"
            },
            {
              "VENDOR_NAME": "RJ'S PROFESSIONAL FLOOR CARE"
            },
            {
              "VENDOR_NAME": "ROADWAY EXPRESS INC"
            },
            {
              "VENDOR_NAME": "ROBERT A. SLOAN"
            },
            {
              "VENDOR_NAME": "ROBERT ADAMS"
            },
            {
              "VENDOR_NAME": "ROBERT AERENSON CONSON"
            },
            {
              "VENDOR_NAME": "ROBERT BENNETT"
            },
            {
              "VENDOR_NAME": "ROBERT BOSCHETTO"
            },
            {
              "VENDOR_NAME": "ROBERT BROWN"
            },
            {
              "VENDOR_NAME": "ROBERT BROWN COURT OFFICER"
            },
            {
              "VENDOR_NAME": "ROBERT BROWNE"
            },
            {
              "VENDOR_NAME": "ROBERT BROWNE COURT OFFICER"
            },
            {
              "VENDOR_NAME": "ROBERT BROWNE CT OFFICER"
            },
            {
              "VENDOR_NAME": "ROBERT BROWNE CT. OFFICER"
            },
            {
              "VENDOR_NAME": "ROBERT BROWNE CT.OFFICER"
            },
            {
              "VENDOR_NAME": "ROBERT BROWNE"
            },
            {
              "VENDOR_NAME": "ROBERT BROWNE"
            },
            {
              "VENDOR_NAME": "ROBERT C.SCHRUM"
            },
            {
              "VENDOR_NAME": "ROBERT D. FEENEY"
            },
            {
              "VENDOR_NAME": "ROBERT DAWSON"
            },
            {
              "VENDOR_NAME": "ROBERT E BLUE"
            },
            {
              "VENDOR_NAME": "ROBERT E. HYMAN TRUSTEE"
            },
            {
              "VENDOR_NAME": "ROBERT E. HYMAN"
            },
            {
              "VENDOR_NAME": "ROBERT E.DICKS"
            },
            {
              "VENDOR_NAME": "ROBERT EMBERT"
            },
            {
              "VENDOR_NAME": "ROBERT F. GERIOT"
            },
            {
              "VENDOR_NAME": "ROBERT F. SOLIMINE"
            },
            {
              "VENDOR_NAME": "ROBERT F. SOLIMINE MARSHAL NYC"
            },
            {
              "VENDOR_NAME": "ROBERT FAGAN"
            },
            {
              "VENDOR_NAME": "ROBERT FESTA"
            },
            {
              "VENDOR_NAME": "ROBERT FICOTURO JR"
            },
            {
              "VENDOR_NAME": "ROBERT GALLAGHER AND"
            },
            {
              "VENDOR_NAME": "ROBERT H. TAFF"
            },
            {
              "VENDOR_NAME": "ROBERT H.VOGEL ENGINEERING"
            },
            {
              "VENDOR_NAME": "ROBERT HOFFECKER"
            },
            {
              "VENDOR_NAME": "ROBERT HYMAN CHAPTER 13 TRUSTEE"
            },
            {
              "VENDOR_NAME": "ROBERT HYMAN TRUSTEE"
            },
            {
              "VENDOR_NAME": "ROBERT HYMAN"
            },
            {
              "VENDOR_NAME": "ROBERT HYMAN"
            },
            {
              "VENDOR_NAME": "ROBERT J & PATRICIA A WOOD"
            },
            {
              "VENDOR_NAME": "ROBERT J DARE"
            },
            {
              "VENDOR_NAME": "ROBERT J. FICOTURO"
            },
            {
              "VENDOR_NAME": "ROBERT J. WOOD"
            },
            {
              "VENDOR_NAME": "ROBERT KEENAN"
            },
            {
              "VENDOR_NAME": "ROBERT KENNEDY"
            },
            {
              "VENDOR_NAME": "ROBERT KILBERG"
            },
            {
              "VENDOR_NAME": "ROBERT KILGERG ESQ."
            },
            {
              "VENDOR_NAME": "ROBERT KONSCHAK"
            },
            {
              "VENDOR_NAME": "ROBERT LIGNELLI"
            },
            {
              "VENDOR_NAME": "ROBERT M BROWNE COURT OFFICER"
            },
            {
              "VENDOR_NAME": "ROBERT M. BURKHOLDER"
            },
            {
              "VENDOR_NAME": "ROBERT M.BURCHUK"
            },
            {
              "VENDOR_NAME": "ROBERT M.LIPSHUTZ"
            },
            {
              "VENDOR_NAME": "ROBERT M.LIPSHUTZ"
            },
            {
              "VENDOR_NAME": "ROBERT MOORE"
            },
            {
              "VENDOR_NAME": "ROBERT MORAN"
            },
            {
              "VENDOR_NAME": "ROBERT MORRIS BLDG ASSOC"
            },
            {
              "VENDOR_NAME": "ROBERT NOVACK"
            },
            {
              "VENDOR_NAME": "ROBERT P & CAROL P BROWN"
            },
            {
              "VENDOR_NAME": "ROBERT P DUCKWORTH CLERK"
            },
            {
              "VENDOR_NAME": "ROBERT P. LAMBERTINO"
            },
            {
              "VENDOR_NAME": "ROBERT PAPOTTO"
            },
            {
              "VENDOR_NAME": "ROBERT PETROVICH"
            },
            {
              "VENDOR_NAME": "ROBERT POSA"
            },
            {
              "VENDOR_NAME": "ROBERT PRICE"
            },
            {
              "VENDOR_NAME": "ROBERT SCHULZ"
            },
            {
              "VENDOR_NAME": "ROBERT SLOAN"
            },
            {
              "VENDOR_NAME": "ROBERT SORENSEN"
            },
            {
              "VENDOR_NAME": "ROBERT STURGIS GREW"
            },
            {
              "VENDOR_NAME": "ROBERT TRUSTON"
            },
            {
              "VENDOR_NAME": "ROBERT W BRUTON"
            },
            {
              "VENDOR_NAME": "ROBERT W.KIRWAN APPRAISAL"
            },
            {
              "VENDOR_NAME": "ROBERT WAGMAN"
            },
            {
              "VENDOR_NAME": "ROBERT WOOD JOHNSON MEDICAL SCHOOL"
            },
            {
              "VENDOR_NAME": "ROBERT ZAWADSKI"
            },
            {
              "VENDOR_NAME": "ROBERTET FLAVORS"
            },
            {
              "VENDOR_NAME": "ROBERTS OXYGEN CO INC"
            },
            {
              "VENDOR_NAME": "ROBERTSON ENTERPRISES"
            },
            {
              "VENDOR_NAME": "ROBERTSON'S FLOWERS"
            },
            {
              "VENDOR_NAME": "ROBET CAHILL"
            },
            {
              "VENDOR_NAME": "ROBLEE DEVELOPMENT LLC"
            },
            {
              "VENDOR_NAME": "ROB'S TRUCK SERVICE"
            },
            {
              "VENDOR_NAME": "ROBT LOVE TAX COL"
            },
            {
              "VENDOR_NAME": "ROCCO C. CIPPARONE JR."
            },
            {
              "VENDOR_NAME": "ROCKHURST COLLEGE"
            },
            {
              "VENDOR_NAME": "ROCKLAND BAKERY"
            },
            {
              "VENDOR_NAME": "ROCKLINE INDUSTRIES"
            },
            {
              "VENDOR_NAME": "ROCKS & ROOTS INC"
            },
            {
              "VENDOR_NAME": "ROCKSTAR INC."
            },
            {
              "VENDOR_NAME": "ROCKY RUN YMCA"
            },
            {
              "VENDOR_NAME": "RODALE"
            },
            {
              "VENDOR_NAME": "RODDY'S GIFT FOUNDATION"
            },
            {
              "VENDOR_NAME": "RODEM"
            },
            {
              "VENDOR_NAME": "RODIN MARKET PARTNERS"
            },
            {
              "VENDOR_NAME": "RODIN PARTNERS L P"
            },
            {
              "VENDOR_NAME": "RODNEY KLAUMENZER"
            },
            {
              "VENDOR_NAME": "RODNEY LONG"
            },
            {
              "VENDOR_NAME": "RODNEY SMELTZ"
            },
            {
              "VENDOR_NAME": "ROE SERVICES"
            },
            {
              "VENDOR_NAME": "ROGER AND SHIRLEY SACQUETY"
            },
            {
              "VENDOR_NAME": "ROGERS SIGN CO."
            },
            {
              "VENDOR_NAME": "ROLAND LaPOINT for 662 FRANKLIN"
            },
            {
              "VENDOR_NAME": "ROLAND PARK COUNTRY SCHOOL"
            },
            {
              "VENDOR_NAME": "ROLLER HOOK INC"
            },
            {
              "VENDOR_NAME": "ROMAN CATHOLIC HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "ROMAN MOSAIC AND TILE CO"
            },
            {
              "VENDOR_NAME": "ROMANTIC GARDENS"
            },
            {
              "VENDOR_NAME": "ROMEO PHOTOGRAPHY"
            },
            {
              "VENDOR_NAME": "RON POWELL"
            },
            {
              "VENDOR_NAME": "RON RAMSEY JR"
            },
            {
              "VENDOR_NAME": "RON SON FOODS INC."
            },
            {
              "VENDOR_NAME": "RONALD A. & FLORA A. SOTO"
            },
            {
              "VENDOR_NAME": "RONALD A. & FLORA A.SOTO"
            },
            {
              "VENDOR_NAME": "RONALD APPLEGATE"
            },
            {
              "VENDOR_NAME": "RONALD GILMORE"
            },
            {
              "VENDOR_NAME": "RONALD HELMER"
            },
            {
              "VENDOR_NAME": "RONALD KUTT"
            },
            {
              "VENDOR_NAME": "RONALD MICHAEL BEALER"
            },
            {
              "VENDOR_NAME": "RONALD MOSES"
            },
            {
              "VENDOR_NAME": "RONALD MOSES MARSHAL"
            },
            {
              "VENDOR_NAME": "RONALD MOSES MARSHAL OF NY"
            },
            {
              "VENDOR_NAME": "RONALD MOSES"
            },
            {
              "VENDOR_NAME": "RONALD S NIELSEN"
            },
            {
              "VENDOR_NAME": "RONALD S NIELSON"
            },
            {
              "VENDOR_NAME": "RONALD W.SAUERBREY"
            },
            {
              "VENDOR_NAME": "RONALD WELLS"
            },
            {
              "VENDOR_NAME": "ROPE PROPERTIES"
            },
            {
              "VENDOR_NAME": "ROSANN VAN SICKLE"
            },
            {
              "VENDOR_NAME": "ROSA'S ORIGINAL LLC"
            },
            {
              "VENDOR_NAME": "ROSE SMITH"
            },
            {
              "VENDOR_NAME": "ROSE TESTA"
            },
            {
              "VENDOR_NAME": "ROSEMARY GAWRYCH"
            },
            {
              "VENDOR_NAME": "ROSEMONT SCHOOL OF THE HOLY CHILD"
            },
            {
              "VENDOR_NAME": "ROSENBERG & ASSOCIATES"
            },
            {
              "VENDOR_NAME": "ROSENBERGER'S DAIRIES"
            },
            {
              "VENDOR_NAME": "ROSENTHAL REALTY INC"
            },
            {
              "VENDOR_NAME": "ROSETTA STONE LTD"
            },
            {
              "VENDOR_NAME": "ROSEZANNA M. CZWALINA"
            },
            {
              "VENDOR_NAME": "ROSKAM BAKING CO / ROTHBURY FARMS"
            },
            {
              "VENDOR_NAME": "ROSS IS NUTS"
            },
            {
              "VENDOR_NAME": "ROSS PRODUCTS DIVISION"
            },
            {
              "VENDOR_NAME": "ROSS SYSTEMS INC"
            },
            {
              "VENDOR_NAME": "ROSSVILLE STATION ASSOC"
            },
            {
              "VENDOR_NAME": "ROTARY CLUB OF MEDIA"
            },
            {
              "VENDOR_NAME": "ROTO-ROOTER PLUMBERS"
            },
            {
              "VENDOR_NAME": "ROUTE 322 ASSOCIATES"
            },
            {
              "VENDOR_NAME": "ROUTE 40 BUSINESS ASSOC."
            },
            {
              "VENDOR_NAME": "ROUTE 40 HOLDINGS"
            },
            {
              "VENDOR_NAME": "ROUTE 42 LAND HOLDING CO."
            },
            {
              "VENDOR_NAME": "ROVITA BAILEY"
            },
            {
              "VENDOR_NAME": "ROWLANDS SALES CO INC"
            },
            {
              "VENDOR_NAME": "ROXANNE ARENA"
            },
            {
              "VENDOR_NAME": "ROXANNE BEERHALTER"
            },
            {
              "VENDOR_NAME": "ROXBOROUGH DEVELOPMENT CORP"
            },
            {
              "VENDOR_NAME": "ROXBURY TOWNSHIP"
            },
            {
              "VENDOR_NAME": "ROY KOLK"
            },
            {
              "VENDOR_NAME": "ROYAL CROWN BOTTLING CO"
            },
            {
              "VENDOR_NAME": "ROYAL ELECTRIC SUPPLY"
            },
            {
              "VENDOR_NAME": "ROYAL OLDSMOBILE COMPANY"
            },
            {
              "VENDOR_NAME": "ROYAL PETROLEUM CORP"
            },
            {
              "VENDOR_NAME": "ROYAL SONESTA HOTEL BOSTON"
            },
            {
              "VENDOR_NAME": "ROYAL VENDORS"
            },
            {
              "VENDOR_NAME": "ROYALTY PRESS GROUP"
            },
            {
              "VENDOR_NAME": "ROYSTON LLC"
            },
            {
              "VENDOR_NAME": "RP WELSH COURT OFFICER"
            },
            {
              "VENDOR_NAME": "RPJ WASTE SERVICES"
            },
            {
              "VENDOR_NAME": "RT 40 TRUST"
            },
            {
              "VENDOR_NAME": "RT ENVIROMENTAL SERVICES"
            },
            {
              "VENDOR_NAME": "RTL SERVICES"
            },
            {
              "VENDOR_NAME": "RTR RENTALS INC"
            },
            {
              "VENDOR_NAME": "RUBEN DEGUILO"
            },
            {
              "VENDOR_NAME": "RUBIN"
            },
            {
              "VENDOR_NAME": "RUBY TUESDAY"
            },
            {
              "VENDOR_NAME": "RUDY"
            },
            {
              "VENDOR_NAME": "RUIZ FOODS"
            },
            {
              "VENDOR_NAME": "RUMSEY ELECTRIC CO"
            },
            {
              "VENDOR_NAME": "RUN FOR HEROES"
            },
            {
              "VENDOR_NAME": "RUNNEMEDE SEWERAGE AUTHORITY"
            },
            {
              "VENDOR_NAME": "RUNNEMEDE YOUTH ATHLETIC"
            },
            {
              "VENDOR_NAME": "RUSSELL BYERS CHARTER SCHOOL"
            },
            {
              "VENDOR_NAME": "RUSSELL CROPPER"
            },
            {
              "VENDOR_NAME": "RUSSELL M. SCHUCKER"
            },
            {
              "VENDOR_NAME": "RUSSELL R & ALICE PALERMO"
            },
            {
              "VENDOR_NAME": "RUSSELL R PALERMO INC"
            },
            {
              "VENDOR_NAME": "RUSSELL REID"
            },
            {
              "VENDOR_NAME": "RUSSELL STOVER CANDIES"
            },
            {
              "VENDOR_NAME": "RUSTIN HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "RUSTY PHILLIPS"
            },
            {
              "VENDOR_NAME": "RUTGERS"
            },
            {
              "VENDOR_NAME": "RUTH C"
            },
            {
              "VENDOR_NAME": "RUTH C"
            },
            {
              "VENDOR_NAME": "RUTH C."
            },
            {
              "VENDOR_NAME": "RUTH HAHN"
            },
            {
              "VENDOR_NAME": "RUTH NEWMAN SHAPIRO CANCER & HEART"
            },
            {
              "VENDOR_NAME": "RUTH W NIMTON"
            },
            {
              "VENDOR_NAME": "RV CONSULTING"
            },
            {
              "VENDOR_NAME": "RWJ HAMILTON FOUNDATION"
            },
            {
              "VENDOR_NAME": "RYAN HORTON"
            },
            {
              "VENDOR_NAME": "RYAN JALLICK"
            },
            {
              "VENDOR_NAME": "RYAN MCKAY"
            },
            {
              "VENDOR_NAME": "RYAN PATRICK"
            },
            {
              "VENDOR_NAME": "RYAN ROOFING"
            },
            {
              "VENDOR_NAME": "RYAN WOLFE KOSSAR FOUNDATION"
            },
            {
              "VENDOR_NAME": "RYAN'S QUEST"
            },
            {
              "VENDOR_NAME": "RYDER TRANSPORTATION SERVICES"
            },
            {
              "VENDOR_NAME": "RYDER TRANSPORTATION SERVICES"
            },
            {
              "VENDOR_NAME": "S & D BROKERAGE"
            },
            {
              "VENDOR_NAME": "S & G ASHPALT SERVICE"
            },
            {
              "VENDOR_NAME": "S & J MANAGEMENT INC"
            },
            {
              "VENDOR_NAME": "S & L GLASS WORKS"
            },
            {
              "VENDOR_NAME": "S & S AUTO PROPERTIES"
            },
            {
              "VENDOR_NAME": "S & T LIQUORS"
            },
            {
              "VENDOR_NAME": "S B THOMAS INC"
            },
            {
              "VENDOR_NAME": "S BRUNSWICK TWP HEALTH DEPT"
            },
            {
              "VENDOR_NAME": "S.CORALUZZO CO.INC."
            },
            {
              "VENDOR_NAME": "S.E.D.A.C."
            },
            {
              "VENDOR_NAME": "S.FREEDMAN & SONS"
            },
            {
              "VENDOR_NAME": "S.W. KEASEY DISTRIBUTORS"
            },
            {
              "VENDOR_NAME": "S.ZITNER CO"
            },
            {
              "VENDOR_NAME": "SABERT CORPORATION"
            },
            {
              "VENDOR_NAME": "SABRINA JONES"
            },
            {
              "VENDOR_NAME": "SACRAMENTO DEPT.OF CHILD SUPPORT"
            },
            {
              "VENDOR_NAME": "SADLER ROAD"
            },
            {
              "VENDOR_NAME": "SAFE IN HUNTERDON"
            },
            {
              "VENDOR_NAME": "SAFEMASTERS"
            },
            {
              "VENDOR_NAME": "SAFEMASTERS/BARRINGTON"
            },
            {
              "VENDOR_NAME": "SAFETY CONNECTION"
            },
            {
              "VENDOR_NAME": "SAFETY KLEEN CORP"
            },
            {
              "VENDOR_NAME": "SAFETY MEDIA INC."
            },
            {
              "VENDOR_NAME": "SAFEWAY FREEZER STORAGE CO LLC"
            },
            {
              "VENDOR_NAME": "SAF-GARD SAFETY SHOE CO.INC."
            },
            {
              "VENDOR_NAME": "SAGE ACCPAC INTERNATIONAL"
            },
            {
              "VENDOR_NAME": "SAGE REFINED PRODUCTS"
            },
            {
              "VENDOR_NAME": "SAGE REFINED PRODUCTS-ACCRUAL"
            },
            {
              "VENDOR_NAME": "SAGER & SAGER ASSOCIATES"
            },
            {
              "VENDOR_NAME": "SAHI INDUSTRIES"
            },
            {
              "VENDOR_NAME": "SAIDUTTI"
            },
            {
              "VENDOR_NAME": "SAINT ANNES CLUB"
            },
            {
              "VENDOR_NAME": "SAINT KATHARINE SCHOOL"
            },
            {
              "VENDOR_NAME": "SAINT MARY'S CHURCH"
            },
            {
              "VENDOR_NAME": "SAINT STEPHEN'S SCHOOL"
            },
            {
              "VENDOR_NAME": "SAINTS SIMON & JUDE ASP"
            },
            {
              "VENDOR_NAME": "SAL CRACOLICI"
            },
            {
              "VENDOR_NAME": "SAL J. MAZZAGATTI COURT OFFICER"
            },
            {
              "VENDOR_NAME": "SAL MAZZAGATTI COURT OFFICER"
            },
            {
              "VENDOR_NAME": "SAL MAZZAGATTI"
            },
            {
              "VENDOR_NAME": "SALARY.COM"
            },
            {
              "VENDOR_NAME": "SALDUTTI"
            },
            {
              "VENDOR_NAME": "SALEM CO DEPT OF HEALTH"
            },
            {
              "VENDOR_NAME": "SALEM COUNTY"
            },
            {
              "VENDOR_NAME": "SALEM COUNTY COLLISION"
            },
            {
              "VENDOR_NAME": "SALESFORCE.COM"
            },
            {
              "VENDOR_NAME": "SALESPRO TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "SALISBURY TOWNSHIP"
            },
            {
              "VENDOR_NAME": "SALISBURY TOWNSHIP SEWER"
            },
            {
              "VENDOR_NAME": "SALISBURY TWSP"
            },
            {
              "VENDOR_NAME": "SALLY AND ALEC PLEET"
            },
            {
              "VENDOR_NAME": "SALLY TRACE"
            },
            {
              "VENDOR_NAME": "SALLY'S FLOWERS INC"
            },
            {
              "VENDOR_NAME": "SALSA IN THE SUBURBS"
            },
            {
              "VENDOR_NAME": "SALVATION ARMY"
            },
            {
              "VENDOR_NAME": "SALVATORE & ANTONIA"
            },
            {
              "VENDOR_NAME": "SALVATORE A.BOCCUTI"
            },
            {
              "VENDOR_NAME": "SALVATORE FINOCCHIARO T/U/WPARIV"
            },
            {
              "VENDOR_NAME": "SALVATORE MATTERA"
            },
            {
              "VENDOR_NAME": "SALVATORE MILUZZO"
            },
            {
              "VENDOR_NAME": "SALVESON STETSON GROUP"
            },
            {
              "VENDOR_NAME": "SAM ASH MUSIC STORE"
            },
            {
              "VENDOR_NAME": "SAMANTHA NEILL"
            },
            {
              "VENDOR_NAME": "SAMPLE MEDIA"
            },
            {
              "VENDOR_NAME": "SAMUEL & MARKS PLLC"
            },
            {
              "VENDOR_NAME": "SAMUEL BAKER & SON"
            },
            {
              "VENDOR_NAME": "SAMUEL HAMILL TRUST 5-2-97"
            },
            {
              "VENDOR_NAME": "SAMUEL M HAMILL JR"
            },
            {
              "VENDOR_NAME": "SAMUEL TAVE FAMILY LIMITED PARTNERS"
            },
            {
              "VENDOR_NAME": "SAMUEL TAVE FAMILY LTD PRTNS"
            },
            {
              "VENDOR_NAME": "SANCO CONSTRUCTION COMPANY"
            },
            {
              "VENDOR_NAME": "SANDLER ASSOCIATES"
            },
            {
              "VENDOR_NAME": "SANDRA FABIAN"
            },
            {
              "VENDOR_NAME": "SANDRA HARING-HENDRICK"
            },
            {
              "VENDOR_NAME": "SANDRA K.DALTON"
            },
            {
              "VENDOR_NAME": "SANDRA LEA CADWALADER"
            },
            {
              "VENDOR_NAME": "SANDRA M.WILBANKS"
            },
            {
              "VENDOR_NAME": "SANDRA WILBANKS"
            },
            {
              "VENDOR_NAME": "SANDS MOTOR LODGE"
            },
            {
              "VENDOR_NAME": "SANDSTON BASEBALL YOUTH"
            },
            {
              "VENDOR_NAME": "SANDSTON FOOTBALL LEAGUE"
            },
            {
              "VENDOR_NAME": "SANDY HILLS FARM"
            },
            {
              "VENDOR_NAME": "SANIGLAZE OF PA"
            },
            {
              "VENDOR_NAME": "SANITOR MFG.CO."
            },
            {
              "VENDOR_NAME": "SANTA BARBARA DEPT.OF CHILD SUPPORT"
            },
            {
              "VENDOR_NAME": "SANTANA PRODUCTS"
            },
            {
              "VENDOR_NAME": "SAP AMERICA"
            },
            {
              "VENDOR_NAME": "SAP AMERICA"
            },
            {
              "VENDOR_NAME": "SAP BOUTIQUE LLC"
            },
            {
              "VENDOR_NAME": "SAP INDUSTRIES"
            },
            {
              "VENDOR_NAME": "SAP LABS LLC"
            },
            {
              "VENDOR_NAME": "SAP TRIVERSITY"
            },
            {
              "VENDOR_NAME": "SAR DOG FOUNDATION INC"
            },
            {
              "VENDOR_NAME": "SARA C W THOMPSON"
            },
            {
              "VENDOR_NAME": "SARA CHATTIN THOMPSON"
            },
            {
              "VENDOR_NAME": "SARA LEE"
            },
            {
              "VENDOR_NAME": "SARA LEE COFEE & TEA SERVICE"
            },
            {
              "VENDOR_NAME": "SARA LEE COFEE AND TEA"
            },
            {
              "VENDOR_NAME": "SARA LEE COFFEE"
            },
            {
              "VENDOR_NAME": "Sara Lee Coffee & Tea"
            },
            {
              "VENDOR_NAME": "SARA LEE COFFEE & TEA FOOD SERVICE"
            },
            {
              "VENDOR_NAME": "SARA LEE COFFEE & TEA FOODSERVICE"
            },
            {
              "VENDOR_NAME": "SARA LEE COFFEE & TEA SERVICE"
            },
            {
              "VENDOR_NAME": "SARA LEE COFFEE AND TEA SERVICE"
            },
            {
              "VENDOR_NAME": "SARA LEE FOOD SERVICE"
            },
            {
              "VENDOR_NAME": "SARA LEE"
            },
            {
              "VENDOR_NAME": "SARA LEE"
            },
            {
              "VENDOR_NAME": "SARACEN ENERGY"
            },
            {
              "VENDOR_NAME": "SARAH G.HASKELL"
            },
            {
              "VENDOR_NAME": "SARGENTO FOODS"
            },
            {
              "VENDOR_NAME": "SATECH"
            },
            {
              "VENDOR_NAME": "SAUKEN ASSOCIATES LP"
            },
            {
              "VENDOR_NAME": "SAUL EWING LLP"
            },
            {
              "VENDOR_NAME": "SAXONY LLC"
            },
            {
              "VENDOR_NAME": "SAYBOLT INC."
            },
            {
              "VENDOR_NAME": "SAYREVILLE ATHLETIC ASSOCIATION"
            },
            {
              "VENDOR_NAME": "SAYREVILLE BOROUGH"
            },
            {
              "VENDOR_NAME": "SAYREVILLE DOMESTIC VIOLENCE"
            },
            {
              "VENDOR_NAME": "SAYREVILLE EMERGENCY SQUAD"
            },
            {
              "VENDOR_NAME": "SCA NORTH AMERICA"
            },
            {
              "VENDOR_NAME": "SCADU"
            },
            {
              "VENDOR_NAME": "SCALES INDUSTRIAL"
            },
            {
              "VENDOR_NAME": "SCANNING CERTIFICATION"
            },
            {
              "VENDOR_NAME": "SCANTASTIC"
            },
            {
              "VENDOR_NAME": "SCANTEK"
            },
            {
              "VENDOR_NAME": "SCCNA INC."
            },
            {
              "VENDOR_NAME": "SCENTAIR"
            },
            {
              "VENDOR_NAME": "SCEPTER SIGNS & ELECTRIC"
            },
            {
              "VENDOR_NAME": "SCHAEFFER SOUND"
            },
            {
              "VENDOR_NAME": "SCHAERER USA CORPORATION"
            },
            {
              "VENDOR_NAME": "SCHALL & BARASCH"
            },
            {
              "VENDOR_NAME": "SCHERING-PLOUGH HEALTHCARE PRO"
            },
            {
              "VENDOR_NAME": "SCHILLER'S 24-HOUR TOWING"
            },
            {
              "VENDOR_NAME": "SCHINDLER ELEVATOR CO."
            },
            {
              "VENDOR_NAME": "SCHMID-DEWLAND ASSOCIATES"
            },
            {
              "VENDOR_NAME": "SCHMIDT BAKING CO BREAD"
            },
            {
              "VENDOR_NAME": "SCHNEIDER NATIONAL"
            },
            {
              "VENDOR_NAME": "SCHOEN FUEL SERVICE"
            },
            {
              "VENDOR_NAME": "SCHOENBECK & SCHOENBECK"
            },
            {
              "VENDOR_NAME": "SCHOENBECK AND SCHOENBECK"
            },
            {
              "VENDOR_NAME": "SCHOLASTIC SPORTS"
            },
            {
              "VENDOR_NAME": "SCHOOL DIST OF HAVERFORD TWP"
            },
            {
              "VENDOR_NAME": "SCHOOL DIST OF RADNOR TOWNSHIP"
            },
            {
              "VENDOR_NAME": "SCHOOL NUTRITION ASSOC OF PA"
            },
            {
              "VENDOR_NAME": "SCHOOLER PROPERTIES"
            },
            {
              "VENDOR_NAME": "SCHOOR DEPALMA"
            },
            {
              "VENDOR_NAME": "SCHORN CONSTRUCTION INC"
            },
            {
              "VENDOR_NAME": "SCHREIBER FOODS"
            },
            {
              "VENDOR_NAME": "SCHUYLKILL TOWNSHIP"
            },
            {
              "VENDOR_NAME": "SCHWAN'S FOOD SERVICE"
            },
            {
              "VENDOR_NAME": "SCHWARTZ BARKIN MITCHELL"
            },
            {
              "VENDOR_NAME": "SCHWENKSVILLE BOROUGH AUTHORITY"
            },
            {
              "VENDOR_NAME": "SCIENTECH"
            },
            {
              "VENDOR_NAME": "SCIENTIFIC EQUIPMENT COMPANY"
            },
            {
              "VENDOR_NAME": "SCORE COMMUNICATION SERVICES"
            },
            {
              "VENDOR_NAME": "SCOTT A. FIGUEROA"
            },
            {
              "VENDOR_NAME": "SCOTT BELLOWS"
            },
            {
              "VENDOR_NAME": "SCOTT C BOORSE"
            },
            {
              "VENDOR_NAME": "SCOTT DUCELLIER"
            },
            {
              "VENDOR_NAME": "SCOTT ENTERTAINMENT"
            },
            {
              "VENDOR_NAME": "SCOTT GERARD"
            },
            {
              "VENDOR_NAME": "SCOTT K. BROWN PHOTOGRAPHY"
            },
            {
              "VENDOR_NAME": "SCOTT MARGHERIO"
            },
            {
              "VENDOR_NAME": "SCOTT MCCRACKEN"
            },
            {
              "VENDOR_NAME": "SCOTT ROOSA"
            },
            {
              "VENDOR_NAME": "SCOTWOOD INDUSTRIES INC"
            },
            {
              "VENDOR_NAME": "SCREEN GEMS"
            },
            {
              "VENDOR_NAME": "SE DELCO SCHOOL DISTRICT"
            },
            {
              "VENDOR_NAME": "SEA ISLE CITY VOL.FIRE CO."
            },
            {
              "VENDOR_NAME": "SEA ISLE ICE CO"
            },
            {
              "VENDOR_NAME": "SEA ISLE INN"
            },
            {
              "VENDOR_NAME": "SEAFOOD TRANSPORT"
            },
            {
              "VENDOR_NAME": "SEALEZE-A UNIT OF JASON"
            },
            {
              "VENDOR_NAME": "SEAMARK INTERNATIONAL"
            },
            {
              "VENDOR_NAME": "SEAN BLANCHARD"
            },
            {
              "VENDOR_NAME": "SEARCH ENGINE PARTNERS"
            },
            {
              "VENDOR_NAME": "SEARS COMMERCIAL ONE"
            },
            {
              "VENDOR_NAME": "SEARS GIFT CERTIFICATE PROGRAM"
            },
            {
              "VENDOR_NAME": "SEATTLE CHOCOLATE COMPANY"
            },
            {
              "VENDOR_NAME": "SEAVIEW BEVERAGE INC"
            },
            {
              "VENDOR_NAME": "SEAVILLE VOLUNTEER FIRE CO.INC."
            },
            {
              "VENDOR_NAME": "SEBASTIAN RIDING ASSOC."
            },
            {
              "VENDOR_NAME": "SECURIAN FINANCIAL SERVICES"
            },
            {
              "VENDOR_NAME": "SECURITY WEAVER"
            },
            {
              "VENDOR_NAME": "SEDGWICK CMS"
            },
            {
              "VENDOR_NAME": "SEIBERLING ASSOC INC"
            },
            {
              "VENDOR_NAME": "SEIU LOCAL 32BJ"
            },
            {
              "VENDOR_NAME": "SEL PROPERTIES"
            },
            {
              "VENDOR_NAME": "SELECT EVENT RENTALS"
            },
            {
              "VENDOR_NAME": "SELECT-A-VISION"
            },
            {
              "VENDOR_NAME": "SELF"
            },
            {
              "VENDOR_NAME": "SELF HELP MOVEMENT INC"
            },
            {
              "VENDOR_NAME": "SELIKOFF & COHEN"
            },
            {
              "VENDOR_NAME": "SELINA G ROSSITER"
            },
            {
              "VENDOR_NAME": "SELINA G. ROSSITER 2004 REVOCABLE"
            },
            {
              "VENDOR_NAME": "SELINA WOOD ROSSITER"
            },
            {
              "VENDOR_NAME": "SELLAS M. AND ELEANOR P. CARTER"
            },
            {
              "VENDOR_NAME": "SELL-THRU SERVICES"
            },
            {
              "VENDOR_NAME": "SEMPRA ENERGY TRADING LLC"
            },
            {
              "VENDOR_NAME": "SEMPRA ENERGY TRADING LLC-ACCRUAL"
            },
            {
              "VENDOR_NAME": "SENATE REPUBLICAN MAJORITY"
            },
            {
              "VENDOR_NAME": "SENIOR ADULT CENTER OF MONTGOMERY C"
            },
            {
              "VENDOR_NAME": "SENIOR COMMUNITY SERVICES"
            },
            {
              "VENDOR_NAME": "SENSIBLE CAR RENTAL BAYVILLE"
            },
            {
              "VENDOR_NAME": "SENSIENT FLAVORS"
            },
            {
              "VENDOR_NAME": "SENSORY EFFECTS FLAVOR SYSTEMS"
            },
            {
              "VENDOR_NAME": "SENTARA MED. GP."
            },
            {
              "VENDOR_NAME": "SENTARA MEDICAL GROUPXXX-XX3405"
            },
            {
              "VENDOR_NAME": "SENTRY EQUIPMENT CORP."
            },
            {
              "VENDOR_NAME": "SEPTIC MANAGEMENT ASSOCIATES LLC"
            },
            {
              "VENDOR_NAME": "SERENA CONQUEST-MORRIS"
            },
            {
              "VENDOR_NAME": "SERGIO WALKER"
            },
            {
              "VENDOR_NAME": "SERR ASSOCIATES"
            },
            {
              "VENDOR_NAME": "SERVICE DISTRIBU"
            },
            {
              "VENDOR_NAME": "SERVICE ELECTRIC CABLE TV"
            },
            {
              "VENDOR_NAME": "SERVICE ENERGY LLC"
            },
            {
              "VENDOR_NAME": "SERVICE INDUSTRY"
            },
            {
              "VENDOR_NAME": "SERVICE INTELLIGENCE"
            },
            {
              "VENDOR_NAME": "SERVICE LAMP CORP."
            },
            {
              "VENDOR_NAME": "SERVICE POINT USA"
            },
            {
              "VENDOR_NAME": "SERVICE TIRE TRUCK CENTER"
            },
            {
              "VENDOR_NAME": "SERVICE TODAY"
            },
            {
              "VENDOR_NAME": "SERVICE TRUCKING INC"
            },
            {
              "VENDOR_NAME": "SERVICE WORKS"
            },
            {
              "VENDOR_NAME": "SERVIT"
            },
            {
              "VENDOR_NAME": "SERVOLIFT EASTERN CORPORATION"
            },
            {
              "VENDOR_NAME": "SETANTA PUBLISHING"
            },
            {
              "VENDOR_NAME": "SETON IDENTIFICATION PRODUCTS"
            },
            {
              "VENDOR_NAME": "SETON YOUTH SHELTERS"
            },
            {
              "VENDOR_NAME": "SEVERN TRENT SERVICES"
            },
            {
              "VENDOR_NAME": "SEVERNA PARK CHAMBER OF COMMERCE"
            },
            {
              "VENDOR_NAME": "SEWELL DEVELOPMENT CORPORATION"
            },
            {
              "VENDOR_NAME": "SEXUAL ASSAULT/SPOUSE ABUSE"
            },
            {
              "VENDOR_NAME": "SGS CONTROL SERVICES INC."
            },
            {
              "VENDOR_NAME": "SGT. TIM SIMPSON BENEFIT"
            },
            {
              "VENDOR_NAME": "SGT.SAM NUTTER ORGANIZATION"
            },
            {
              "VENDOR_NAME": "SHADEWELL GROVE FOODS INC"
            },
            {
              "VENDOR_NAME": "SHADY MAPLE SMORGASBORD"
            },
            {
              "VENDOR_NAME": "SHAMONG FOUNDATION FOR EDUCATIONAL"
            },
            {
              "VENDOR_NAME": "SHAMROCK HEATING & A/C"
            },
            {
              "VENDOR_NAME": "SHANLAN CORP."
            },
            {
              "VENDOR_NAME": "SHANNON BOYER"
            },
            {
              "VENDOR_NAME": "SHANNON MECHANICAL INC."
            },
            {
              "VENDOR_NAME": "SHAREPOINT EXPERTS"
            },
            {
              "VENDOR_NAME": "SHARI E.ANISMAN"
            },
            {
              "VENDOR_NAME": "SHARKEY ENTERPRISES"
            },
            {
              "VENDOR_NAME": "SHARON BARRON"
            },
            {
              "VENDOR_NAME": "SHARON CREAMER"
            },
            {
              "VENDOR_NAME": "SHARON KELLY"
            },
            {
              "VENDOR_NAME": "SHARON L. HANCOCK"
            },
            {
              "VENDOR_NAME": "SHARON POWERS/ INQUIRER"
            },
            {
              "VENDOR_NAME": "SHARON ROMER"
            },
            {
              "VENDOR_NAME": "SHAWNEE HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "SHAWNEE INN & GOLF RESORT"
            },
            {
              "VENDOR_NAME": "SHEETZ"
            },
            {
              "VENDOR_NAME": "SHELL TRADING-US"
            },
            {
              "VENDOR_NAME": "SHENANDOAH LEGAL GROUP"
            },
            {
              "VENDOR_NAME": "SHENDOAH LEGAL GROUP - VA. BEACH DI"
            },
            {
              "VENDOR_NAME": "SHEPHERD INVESTMENT GP."
            },
            {
              "VENDOR_NAME": "SHEPPARD BUS SERVICE"
            },
            {
              "VENDOR_NAME": "SHERATON ATLANTIC CITY"
            },
            {
              "VENDOR_NAME": "SHERATON DOVER HOTEL"
            },
            {
              "VENDOR_NAME": "SHERATON PARK SOUTH HOTEL"
            },
            {
              "VENDOR_NAME": "SHERATON SUITES WILMINGTON"
            },
            {
              "VENDOR_NAME": "SHERIFF JAMES McGETTIGAN"
            },
            {
              "VENDOR_NAME": "SHERIFF OF ATLANTIC CO."
            },
            {
              "VENDOR_NAME": "SHERIFF OF ATLANTIC COUNTY"
            },
            {
              "VENDOR_NAME": "SHERIFF OF BURLINGTON CO"
            },
            {
              "VENDOR_NAME": "SHERIFF OF BURLINGTON CO."
            },
            {
              "VENDOR_NAME": "SHERIFF OF BURLINGTON COUNTY"
            },
            {
              "VENDOR_NAME": "SHERIFF OF CAMDEN COUNTY"
            },
            {
              "VENDOR_NAME": "SHERIFF OF CUMBERLAND COUNTY"
            },
            {
              "VENDOR_NAME": "SHERIFF OF GLOUCESTER CITY"
            },
            {
              "VENDOR_NAME": "SHERIFF OF GLOUCESTER CO"
            },
            {
              "VENDOR_NAME": "SHERIFF OF GLOUCESTER CO."
            },
            {
              "VENDOR_NAME": "SHERIFF OF GLOUCESTER COUNTY"
            },
            {
              "VENDOR_NAME": "SHERIFF OF GLOUSTER CO."
            },
            {
              "VENDOR_NAME": "SHERIFF OF HUNTERDON CO"
            },
            {
              "VENDOR_NAME": "SHERIFF OF HUNTERDON COUNTY"
            },
            {
              "VENDOR_NAME": "SHERIFF OF MERCER CO"
            },
            {
              "VENDOR_NAME": "SHERIFF OF MERCER COUNTY"
            },
            {
              "VENDOR_NAME": "SHERIFF OF MIDDLESEX COUNTY"
            },
            {
              "VENDOR_NAME": "SHERIFF OF MONMOUTH CO."
            },
            {
              "VENDOR_NAME": "SHERIFF OF OCEAN COUNTY"
            },
            {
              "VENDOR_NAME": "SHERIFF OF SOMERSET COUNTY"
            },
            {
              "VENDOR_NAME": "SHERIFF OF WARREN CO."
            },
            {
              "VENDOR_NAME": "SHERIFF'S OFFICE"
            },
            {
              "VENDOR_NAME": "SHERIFF'S OFFICE MIDDLESEX CO."
            },
            {
              "VENDOR_NAME": "SHERLING PLOUGH CORPORATION"
            },
            {
              "VENDOR_NAME": "SHERMAN ACQUISTION"
            },
            {
              "VENDOR_NAME": "SHERRY D. KERSTETTER"
            },
            {
              "VENDOR_NAME": "SHERRY LAKE APARTMENTS"
            },
            {
              "VENDOR_NAME": "SHERWIN - WILLIAMS"
            },
            {
              "VENDOR_NAME": "SHERWIN WILLIAMS"
            },
            {
              "VENDOR_NAME": "SHERWIN- WILLIAMS"
            },
            {
              "VENDOR_NAME": "SHERWIN WILLIAMS CO"
            },
            {
              "VENDOR_NAME": "SHERWIN WILLIAMS CO."
            },
            {
              "VENDOR_NAME": "SHERWIN-WILLIAMS"
            },
            {
              "VENDOR_NAME": "SHERWIN-WILLIAMS CO"
            },
            {
              "VENDOR_NAME": "SHERWIN-WILLIAMS CO."
            },
            {
              "VENDOR_NAME": "SHERWIN-WILLILAMS COMPANY"
            },
            {
              "VENDOR_NAME": "SHEVONNE POLASTRE"
            },
            {
              "VENDOR_NAME": "SHIFFLETT'S TRASH SERV."
            },
            {
              "VENDOR_NAME": "SHIFFLETT'S TRASH SERVICE"
            },
            {
              "VENDOR_NAME": "SHINGLE & GIBB"
            },
            {
              "VENDOR_NAME": "SHIRLEY MAE BREAST CANCER ASSIST.FU"
            },
            {
              "VENDOR_NAME": "SHIRLYNN SCHAFER"
            },
            {
              "VENDOR_NAME": "SHIV I"
            },
            {
              "VENDOR_NAME": "SHIVE ASSOCIATES INC."
            },
            {
              "VENDOR_NAME": "SHOES FOR CREWS INC"
            },
            {
              "VENDOR_NAME": "SHOOK"
            },
            {
              "VENDOR_NAME": "SHOPPES OF ST. GEORGE"
            },
            {
              "VENDOR_NAME": "SHORE FELLOWSHIP"
            },
            {
              "VENDOR_NAME": "SHORE GRILL AT BRICK"
            },
            {
              "VENDOR_NAME": "SHORE HEALTH SYS"
            },
            {
              "VENDOR_NAME": "SHORE HEALTH SYSTEM"
            },
            {
              "VENDOR_NAME": "SHORE HEALTH SYSTEMS"
            },
            {
              "VENDOR_NAME": "SHORE LINE TV & APPLIANCE"
            },
            {
              "VENDOR_NAME": "SHORE PLASTICS"
            },
            {
              "VENDOR_NAME": "SHORE WHEELS"
            },
            {
              "VENDOR_NAME": "SHORELANDS WATER CO."
            },
            {
              "VENDOR_NAME": "SHORELINE REFRIGERATION"
            },
            {
              "VENDOR_NAME": "SHORTSTOP"
            },
            {
              "VENDOR_NAME": "SHOWORKS AUDIO-VISUAL"
            },
            {
              "VENDOR_NAME": "SHRED ONE SECURITY CORP."
            },
            {
              "VENDOR_NAME": "SHRED-IT"
            },
            {
              "VENDOR_NAME": "SHRINER'S HOSPITAL FOR CHILDREN"
            },
            {
              "VENDOR_NAME": "SHRM"
            },
            {
              "VENDOR_NAME": "SHROPSHIRE ASSOCIATES LLC"
            },
            {
              "VENDOR_NAME": "SHROPSHIRE ASSOCIATES"
            },
            {
              "VENDOR_NAME": "SHS FOOTBALL BOOSTERS"
            },
            {
              "VENDOR_NAME": "SHS SWIM TEAM PARENT"
            },
            {
              "VENDOR_NAME": "SHUKAITIS PARTNERS"
            },
            {
              "VENDOR_NAME": "SHURE-LINE CONSTRUCTION CO."
            },
            {
              "VENDOR_NAME": "SHURGARD OF OYSTER POINT"
            },
            {
              "VENDOR_NAME": "SHUSTER LABORATORIES"
            },
            {
              "VENDOR_NAME": "SID HARVEY INDUSTRIES"
            },
            {
              "VENDOR_NAME": "SIDNEY B.COX"
            },
            {
              "VENDOR_NAME": "SIDNEY B.COX"
            },
            {
              "VENDOR_NAME": "SIDNEY GOLDSTEIN"
            },
            {
              "VENDOR_NAME": "SIDNEY L.GOLD & ASSOC."
            },
            {
              "VENDOR_NAME": "SIDS MID-ATLANTIC"
            },
            {
              "VENDOR_NAME": "SIEGEL DISPLAY PRODUCTS"
            },
            {
              "VENDOR_NAME": "SIEMENS BUILDING TECHNOLOGIES INC"
            },
            {
              "VENDOR_NAME": "SIGN & ENGRAVING TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "SIGNATURE MAINTENANCE"
            },
            {
              "VENDOR_NAME": "SIGNATURE SIGN INC"
            },
            {
              "VENDOR_NAME": "Signature Snacks Company"
            },
            {
              "VENDOR_NAME": "SIGNS BY TOMORROW"
            },
            {
              "VENDOR_NAME": "SIGNSTRUT LTD"
            },
            {
              "VENDOR_NAME": "SIGNSTRUT LTD."
            },
            {
              "VENDOR_NAME": "SILICATO COMMERCIAL REALTY"
            },
            {
              "VENDOR_NAME": "SILICON ENERGY CORP."
            },
            {
              "VENDOR_NAME": "SILLIKER LABORATORIES INC"
            },
            {
              "VENDOR_NAME": "SILVER REAL ESTATE CO LLC"
            },
            {
              "VENDOR_NAME": "SILVER SPRINGS CITRUS"
            },
            {
              "VENDOR_NAME": "SILVER SPRINGS MARTIN LUTHER"
            },
            {
              "VENDOR_NAME": "SILVERHAND PRODUCTIONS INC."
            },
            {
              "VENDOR_NAME": "SILVERPOP SYSTEMS"
            },
            {
              "VENDOR_NAME": "SILVERTON FIRST AID SQUAD"
            },
            {
              "VENDOR_NAME": "SILVIA JOSEPH"
            },
            {
              "VENDOR_NAME": "SILVIO TRENTALANGE"
            },
            {
              "VENDOR_NAME": "SIMONE JAFFE COLLINS"
            },
            {
              "VENDOR_NAME": "SIMPLE SOLUTIONS & INNOVATIONS"
            },
            {
              "VENDOR_NAME": "SIMPLEX TIME RECORDER CO."
            },
            {
              "VENDOR_NAME": "SIMPLEXGRINNELL LP"
            },
            {
              "VENDOR_NAME": "SIMUL8 CORPORATION"
            },
            {
              "VENDOR_NAME": "SINGH OPERATIONAL SERVICES"
            },
            {
              "VENDOR_NAME": "SINKO AND EISNER"
            },
            {
              "VENDOR_NAME": "SIR SPEEDY"
            },
            {
              "VENDOR_NAME": "SIR SPEEDY PRINTING #7105"
            },
            {
              "VENDOR_NAME": "SIR SPEEDY PRINTING CENTER #7134"
            },
            {
              "VENDOR_NAME": "SIRIUS COMPUTER SOLUTIONS INC."
            },
            {
              "VENDOR_NAME": "SIR-LANCELLOT COURIER SER"
            },
            {
              "VENDOR_NAME": "SITE SPECIFIC DESIGN"
            },
            {
              "VENDOR_NAME": "SJBPC"
            },
            {
              "VENDOR_NAME": "SJS-TILTON TIMES PLAZA"
            },
            {
              "VENDOR_NAME": "SKELLY AND LOY"
            },
            {
              "VENDOR_NAME": "SKILL PATH SEMINARS"
            },
            {
              "VENDOR_NAME": "SKILLPATH INC"
            },
            {
              "VENDOR_NAME": "SKILLPATH ON SITE"
            },
            {
              "VENDOR_NAME": "SKILLPATH SEMINARS"
            },
            {
              "VENDOR_NAME": "SKILLSOFT CORP"
            },
            {
              "VENDOR_NAME": "SKIPPACK GOLF CLUB"
            },
            {
              "VENDOR_NAME": "SKIPPACK LOCAL TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "SKIPPACK TAX COLLECTOR"
            },
            {
              "VENDOR_NAME": "SKIPPACK TOWNSHIP"
            },
            {
              "VENDOR_NAME": "SKIPPACK TWP. SEWER AUTHORITYY"
            },
            {
              "VENDOR_NAME": "SKIPPACT TAX COLLECTOR"
            },
            {
              "VENDOR_NAME": "SKM GENERAL CONTRACTING"
            },
            {
              "VENDOR_NAME": "SKY CHEF"
            },
            {
              "VENDOR_NAME": "SKYLAR INVESTMENTS"
            },
            {
              "VENDOR_NAME": "SKYLINE ADVANCED TECHNOLOGY SERVICE"
            },
            {
              "VENDOR_NAME": "SKYLINE PRODUCTS"
            },
            {
              "VENDOR_NAME": "SKYTEL INC"
            },
            {
              "VENDOR_NAME": "SLC ASSOCIATES"
            },
            {
              "VENDOR_NAME": "SLEEP INN"
            },
            {
              "VENDOR_NAME": "SLEEP INN & SUITES LEWES"
            },
            {
              "VENDOR_NAME": "SLEEP INN LAKE WRIGHT"
            },
            {
              "VENDOR_NAME": "SLEEP INN WOODBRIDGE"
            },
            {
              "VENDOR_NAME": "SLF"
            },
            {
              "VENDOR_NAME": "SLIDING HILL INVESTMENTS"
            },
            {
              "VENDOR_NAME": "SLIDING HILL PROPERTIES"
            },
            {
              "VENDOR_NAME": "SLIDING HILL PROPERTIES"
            },
            {
              "VENDOR_NAME": "SMALLWOOD VILLAGE ASSOCIATES"
            },
            {
              "VENDOR_NAME": "SMALLWOOD VILLAGE CENTER LLC"
            },
            {
              "VENDOR_NAME": "SMART BUSINESS ADVISORY"
            },
            {
              "VENDOR_NAME": "SMART PRACTICE"
            },
            {
              "VENDOR_NAME": "SMART SPACE SOLUTIONS"
            },
            {
              "VENDOR_NAME": "SMARTPROS LTD."
            },
            {
              "VENDOR_NAME": "SMECO"
            },
            {
              "VENDOR_NAME": "SMITH BROS -ZIEGLER"
            },
            {
              "VENDOR_NAME": "SMITH-KOCH"
            },
            {
              "VENDOR_NAME": "SMITH'S PROFESSIONAL GROUNDS MAINT."
            },
            {
              "VENDOR_NAME": "SMITHVILLE COMMUNITY ASSOC."
            },
            {
              "VENDOR_NAME": "SMITHVILLE COMUNITY ASSOC"
            },
            {
              "VENDOR_NAME": "SMITTY'S SUPPLY INC"
            },
            {
              "VENDOR_NAME": "SMNA ACH DEBIT"
            },
            {
              "VENDOR_NAME": "SMURFIT-STONE CONTAINER"
            },
            {
              "VENDOR_NAME": "SMYRNA TWO LLC"
            },
            {
              "VENDOR_NAME": "SMYRNA TWO"
            },
            {
              "VENDOR_NAME": "SnagAJob.Com"
            },
            {
              "VENDOR_NAME": "SNAPPLE DISTRIBUTORS INC."
            },
            {
              "VENDOR_NAME": "SNOWDEN FREDERICKSBURG"
            },
            {
              "VENDOR_NAME": "SNS SIGNS DBA SIGN A RAMA"
            },
            {
              "VENDOR_NAME": "SNYDER'S ACE HARDWARE"
            },
            {
              "VENDOR_NAME": "SNYDER'S EQUIPMENT CENTER"
            },
            {
              "VENDOR_NAME": "SNYDERS OF HANOVER"
            },
            {
              "VENDOR_NAME": "SNYDER'S OF HANOVER"
            },
            {
              "VENDOR_NAME": "SO STATES WHSLE"
            },
            {
              "VENDOR_NAME": "SOBIESKI SERVICES"
            },
            {
              "VENDOR_NAME": "SOCIAL SECURITY ADMINISTRATION"
            },
            {
              "VENDOR_NAME": "SOCIETY FOR HUMAN RESOURCE MANAGEME"
            },
            {
              "VENDOR_NAME": "SOCIETY FOR HUMAN RESOURCE MGMT."
            },
            {
              "VENDOR_NAME": "SOCIETY FOR HUMAN RESOURCES"
            },
            {
              "VENDOR_NAME": "SO-DEEP"
            },
            {
              "VENDOR_NAME": "SOFTMART INC"
            },
            {
              "VENDOR_NAME": "SOIL SAFE INCORPORATED"
            },
            {
              "VENDOR_NAME": "SOJOURN COMMUNITY CHURCH"
            },
            {
              "VENDOR_NAME": "SOLDIERS YOUTH FOOTBALL AND"
            },
            {
              "VENDOR_NAME": "SOLO COMPANY"
            },
            {
              "VENDOR_NAME": "SOLOMON EDWARDS GROUP"
            },
            {
              "VENDOR_NAME": "SOLOMON SCHECHTER DAY SCHOOL"
            },
            {
              "VENDOR_NAME": "SOLTIS & ASSOCIATES"
            },
            {
              "VENDOR_NAME": "SOLUTECH INC CHEM ASSET"
            },
            {
              "VENDOR_NAME": "SOMERDALE FIRE COMPANY"
            },
            {
              "VENDOR_NAME": "SOMERS POINT FLORIST"
            },
            {
              "VENDOR_NAME": "SOMERSET GLEN APARTMENTS"
            },
            {
              "VENDOR_NAME": "SOMERSET GLEN APTS."
            },
            {
              "VENDOR_NAME": "SOMERSET-UNION SOIL CONSERVATION DI"
            },
            {
              "VENDOR_NAME": "SOMMER MAID CREAMERY"
            },
            {
              "VENDOR_NAME": "SONIC BOOM WELLNESS"
            },
            {
              "VENDOR_NAME": "SONNENBLICK"
            },
            {
              "VENDOR_NAME": "SONOMA LEADERSHIP SYSTEMS"
            },
            {
              "VENDOR_NAME": "SOPUS PRODUCTS INC."
            },
            {
              "VENDOR_NAME": "SOPUS PRODUCTS"
            },
            {
              "VENDOR_NAME": "SOS SPEED TEAM"
            },
            {
              "VENDOR_NAME": "SOSMETAL PRODUCTS INC"
            },
            {
              "VENDOR_NAME": "SOUDERTON BOROUGH"
            },
            {
              "VENDOR_NAME": "SOUDERTON WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "SOURCE INTERLINK COMPANIES"
            },
            {
              "VENDOR_NAME": "SOURCE MEDIA"
            },
            {
              "VENDOR_NAME": "SOURCE ONE FINANCIAL STAFFING"
            },
            {
              "VENDOR_NAME": "SOUTH BEACH APARTMENTS"
            },
            {
              "VENDOR_NAME": "SOUTH BRUNSWICK"
            },
            {
              "VENDOR_NAME": "SOUTH BRUNSWICK ENTERPRISE LLC"
            },
            {
              "VENDOR_NAME": "SOUTH BRUNSWICK HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "SOUTH BRUNSWICK WATER SEWER REVENUE"
            },
            {
              "VENDOR_NAME": "SOUTH HARRISON LITTLE LEAGUE"
            },
            {
              "VENDOR_NAME": "SOUTH HARRISON TOWNSHIP"
            },
            {
              "VENDOR_NAME": "SOUTH JERSEY ENGINEERS"
            },
            {
              "VENDOR_NAME": "SOUTH JERSEY EXCAVATING LLC"
            },
            {
              "VENDOR_NAME": "SOUTH JERSEY GAS"
            },
            {
              "VENDOR_NAME": "SOUTH JERSEY GLASS CO"
            },
            {
              "VENDOR_NAME": "SOUTH JERSEY METAL INC."
            },
            {
              "VENDOR_NAME": "SOUTH JERSEY OVERHEAD DOOR"
            },
            {
              "VENDOR_NAME": "SOUTH JERSEY QUARTER"
            },
            {
              "VENDOR_NAME": "SOUTH JERSEY TESTING LABORATORY"
            },
            {
              "VENDOR_NAME": "SOUTH JERSEY WATER"
            },
            {
              "VENDOR_NAME": "SOUTH KENT SCHOOL"
            },
            {
              "VENDOR_NAME": "SOUTH PENN LOCK & SAFE CO"
            },
            {
              "VENDOR_NAME": "SOUTH POINTE LANDING"
            },
            {
              "VENDOR_NAME": "SOUTH RIVER DOMESTIC VIOLENCE"
            },
            {
              "VENDOR_NAME": "SOUTH VINELAND LITTLE LEAGUE"
            },
            {
              "VENDOR_NAME": "SOUTHAMPTON COMBINED CTR."
            },
            {
              "VENDOR_NAME": "SOUTHAMPTON WATER AUTH"
            },
            {
              "VENDOR_NAME": "SOUTHEAST PROPERTIES I"
            },
            {
              "VENDOR_NAME": "SOUTHEASTERN PA.TRANSP.AUTHORITY"
            },
            {
              "VENDOR_NAME": "SOUTHERN BAKERIES"
            },
            {
              "VENDOR_NAME": "SOUTHERN CHESTER COUNTY YMCA"
            },
            {
              "VENDOR_NAME": "SOUTHERN DEL CO AUTHORITY"
            },
            {
              "VENDOR_NAME": "SOUTHERN DEL PHYS THERAPY"
            },
            {
              "VENDOR_NAME": "SOUTHERN LEHIGH EARNED INC.TAX"
            },
            {
              "VENDOR_NAME": "SOUTHERN MARYLAND"
            },
            {
              "VENDOR_NAME": "SOUTHERN MARYLAND BLUE CRABS"
            },
            {
              "VENDOR_NAME": "SOUTHERN MARYLAND ELEC"
            },
            {
              "VENDOR_NAME": "SOUTHERN MARYLAND ELECTRICAL"
            },
            {
              "VENDOR_NAME": "SOUTHERN MARYLAND NEWSPAPERS"
            },
            {
              "VENDOR_NAME": "SOUTHERN MARYLAND SHOP"
            },
            {
              "VENDOR_NAME": "SOUTHERN OCEAN COUNTY"
            },
            {
              "VENDOR_NAME": "SOUTHERN REGIONAL"
            },
            {
              "VENDOR_NAME": "SOUTHERN STATES"
            },
            {
              "VENDOR_NAME": "SOUTHERN STORE FIXTURES"
            },
            {
              "VENDOR_NAME": "SOUTHERN WINE & SPIRITS"
            },
            {
              "VENDOR_NAME": "SOUTHPORT PLAZA ASSOCIATE"
            },
            {
              "VENDOR_NAME": "SOUTHPORT PLAZA ASSOCIATES"
            },
            {
              "VENDOR_NAME": "SOUTHSIDE FINANCIAL SERVICE"
            },
            {
              "VENDOR_NAME": "SOUTHSIDE FINANCIAL SERVICES"
            },
            {
              "VENDOR_NAME": "SOUTHSIDE FUEL"
            },
            {
              "VENDOR_NAME": "SOUTHSIDE REG MED CTR"
            },
            {
              "VENDOR_NAME": "SOUTHSIDE REG. MEDICAL CENTER"
            },
            {
              "VENDOR_NAME": "SOUTHSIDE REG. MEDICAL CTR."
            },
            {
              "VENDOR_NAME": "SOUTHSIDE REG.MEDICAL CTR"
            },
            {
              "VENDOR_NAME": "SOUTHSIDE REGIONAL MED CTR."
            },
            {
              "VENDOR_NAME": "SOUTHSIDE REGIONAL MEDICAL CENTER"
            },
            {
              "VENDOR_NAME": "SOUTHSIDE REGIONAL MEDICAL CNTR"
            },
            {
              "VENDOR_NAME": "SOUTHSIDE REGIONAL MEDICAL CTR"
            },
            {
              "VENDOR_NAME": "SOUTHWEST DELAWARE COUNTY"
            },
            {
              "VENDOR_NAME": "SOVEREIGN CONSULTING"
            },
            {
              "VENDOR_NAME": "SOVEREIGN SECURITY LLC"
            },
            {
              "VENDOR_NAME": "SPANGLER CANDY COMPANY"
            },
            {
              "VENDOR_NAME": "SPAR GROUP"
            },
            {
              "VENDOR_NAME": "SPARTAN SEWER RAIDER"
            },
            {
              "VENDOR_NAME": "SPATOLA'S PIZZA & RESTAURANT"
            },
            {
              "VENDOR_NAME": "SPAULDING LIGHTING INC"
            },
            {
              "VENDOR_NAME": "SPEAR WILDERMAN"
            },
            {
              "VENDOR_NAME": "SPECIAL OLYMPICS"
            },
            {
              "VENDOR_NAME": "SPECIAL OLYMPICS BUCKS COUNTY"
            },
            {
              "VENDOR_NAME": "SPECIAL OLYMPICS NEW JERSEY"
            },
            {
              "VENDOR_NAME": "SPECIAL OLYMPICS OF DELAWARE"
            },
            {
              "VENDOR_NAME": "SPECIAL OLYMPICS-N.J."
            },
            {
              "VENDOR_NAME": "SPECIALTY PUBLISHING COMPANY"
            },
            {
              "VENDOR_NAME": "SPECTRUM LIGHTING"
            },
            {
              "VENDOR_NAME": "SPECTRUM MECHANICAL"
            },
            {
              "VENDOR_NAME": "SPEEDIMPEX USA INC"
            },
            {
              "VENDOR_NAME": "SPEEDY CASH"
            },
            {
              "VENDOR_NAME": "SPEERS WATER CONDITIONING"
            },
            {
              "VENDOR_NAME": "SPIKE'S TROPHIES LIMITED"
            },
            {
              "VENDOR_NAME": "SPIRIT OF PHILADELPHIA"
            },
            {
              "VENDOR_NAME": "SPL INTEGRATED SOLUTIONS"
            },
            {
              "VENDOR_NAME": "SPLAT PRODUCTIONS"
            },
            {
              "VENDOR_NAME": "SPOTSYLANIA GEN'L DIST CT."
            },
            {
              "VENDOR_NAME": "SPOTSYLVANIA BABE RUTH"
            },
            {
              "VENDOR_NAME": "SPOTSYLVANIA COUNTY"
            },
            {
              "VENDOR_NAME": "SPOTSYLVANIA COUNTY TREASURER"
            },
            {
              "VENDOR_NAME": "SPOTSYLVANIA GENERAL DIST.COURT"
            },
            {
              "VENDOR_NAME": "SPOTSYLVANIA GEN'L DIST COURT"
            },
            {
              "VENDOR_NAME": "SPOTSYLVANIA GEN'L DIST CT"
            },
            {
              "VENDOR_NAME": "SPOTSYLVANIA GEN'L DIST CT."
            },
            {
              "VENDOR_NAME": "SPOTSYLVANIA GEN'L DIST. COURT"
            },
            {
              "VENDOR_NAME": "SPOTSYLVANIA GEN'L DISTRICT COURT"
            },
            {
              "VENDOR_NAME": "SPOTSYLVANIA JDR COURT"
            },
            {
              "VENDOR_NAME": "SPOTSYLVANIA YOUTH SPORTS"
            },
            {
              "VENDOR_NAME": "SPOTTS FAIN CHAPPELL &"
            },
            {
              "VENDOR_NAME": "SPOTTS FAIN CONSULTING"
            },
            {
              "VENDOR_NAME": "SPOTTS"
            },
            {
              "VENDOR_NAME": "SPOTWAVE WIRELESS LTD"
            },
            {
              "VENDOR_NAME": "SPRAGUE ENERGY"
            },
            {
              "VENDOR_NAME": "SPRAGUE ENERGY CORP-ACCRUAL"
            },
            {
              "VENDOR_NAME": "SPRING HILL REALTY CO NJ"
            },
            {
              "VENDOR_NAME": "SPRING HILL SUITES BY MARRIOTT"
            },
            {
              "VENDOR_NAME": "SPRING RIDGE"
            },
            {
              "VENDOR_NAME": "SPRING TOWNSHIP"
            },
            {
              "VENDOR_NAME": "SPRING TOWNSHIP WAGE TAX"
            },
            {
              "VENDOR_NAME": "SPRINGETTSBURY TOWNSHIP"
            },
            {
              "VENDOR_NAME": "SPRINGFIELD COUNTRY CLUB"
            },
            {
              "VENDOR_NAME": "SPRINGFIELD R/E TX-REFUSE"
            },
            {
              "VENDOR_NAME": "SPRINGFIELD TOWNSHIP"
            },
            {
              "VENDOR_NAME": "SPRINGFIELD TOWNSHIP EMS.INC."
            },
            {
              "VENDOR_NAME": "SPRINGFIELD TOWNSHIP FIRE CO."
            },
            {
              "VENDOR_NAME": "SPRINGFIELD TOWNSHIP FIRE COMPANY"
            },
            {
              "VENDOR_NAME": "SPRINGFIELD TOWNSHIP RECREATION"
            },
            {
              "VENDOR_NAME": "SPRINGFIELD TOWNSHIP REPUBLICAN CLU"
            },
            {
              "VENDOR_NAME": "SPRINGFIELD TWP"
            },
            {
              "VENDOR_NAME": "SPRINGFIELD TWP EIT LOCAL TAX"
            },
            {
              "VENDOR_NAME": "SPRINGFIELD TWP REC COMM"
            },
            {
              "VENDOR_NAME": "SPRINGFIELD TWP. POLICE DEPARTMENT"
            },
            {
              "VENDOR_NAME": "SPRINGFIELD TWP.COMMUNITY EDUCATION"
            },
            {
              "VENDOR_NAME": "SPRINGFIELD TWP.HOME & SCHOOL"
            },
            {
              "VENDOR_NAME": "SPRINGFIELD TWP.POLICE DEPT."
            },
            {
              "VENDOR_NAME": "SPRINGFIELD TWP.REC.COMM"
            },
            {
              "VENDOR_NAME": "SPRINGFIELD TWP.RECREATION"
            },
            {
              "VENDOR_NAME": "SPRINGHILL SUITES"
            },
            {
              "VENDOR_NAME": "SPRINGHOUSE COMPUTER SCHOOL"
            },
            {
              "VENDOR_NAME": "SPRINT"
            },
            {
              "VENDOR_NAME": "SQUARE PROPERTIES LLC"
            },
            {
              "VENDOR_NAME": "SRC/ AETNA"
            },
            {
              "VENDOR_NAME": "SRE"
            },
            {
              "VENDOR_NAME": "SRS TOWING & RECOVERY"
            },
            {
              "VENDOR_NAME": "SSC PROPERTY HOLDINGS"
            },
            {
              "VENDOR_NAME": "ST JOSEPH'S UNIVERSITY"
            },
            {
              "VENDOR_NAME": "ST MARGARET'S REGIONAL SCHOOL"
            },
            {
              "VENDOR_NAME": "ST OF DEL DEPT OF TRANS"
            },
            {
              "VENDOR_NAME": "ST. ANASTASIA SCHOOL"
            },
            {
              "VENDOR_NAME": "ST. AUGUSTINE PREPARATORY SCHOOL"
            },
            {
              "VENDOR_NAME": "ST. BALDRICK'S FOUNDATION"
            },
            {
              "VENDOR_NAME": "ST. CHRISTOPHER'S HOSPITAL"
            },
            {
              "VENDOR_NAME": "ST. JOSEPH HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "ST. JUDE CHILDREN'S RESEARCH HOSP"
            },
            {
              "VENDOR_NAME": "ST. MARY MAGDALEN PTA"
            },
            {
              "VENDOR_NAME": "ST. MARY'S TODAY NEWSPAPER"
            },
            {
              "VENDOR_NAME": "ST. MICHAELS APTS"
            },
            {
              "VENDOR_NAME": "ST.ANDREW SCHOOL"
            },
            {
              "VENDOR_NAME": "ST.GABRIEL'S HALL"
            },
            {
              "VENDOR_NAME": "ST.JOHN PROPERTIES"
            },
            {
              "VENDOR_NAME": "ST.JOSEPH'S UNIVERSITY"
            },
            {
              "VENDOR_NAME": "ST.JOSEPH'S UNIVERSITY-FSMF"
            },
            {
              "VENDOR_NAME": "ST.KEVIN CYO CHRISTMAS BASKETBALL"
            },
            {
              "VENDOR_NAME": "ST.MADELINE-ST.ROSE SCHOOL"
            },
            {
              "VENDOR_NAME": "ST.MARY'S COUNTY"
            },
            {
              "VENDOR_NAME": "ST.MARY'S COUNTY OFFICE OF PUBLIC W"
            },
            {
              "VENDOR_NAME": "ST.MARY'S SOIL CONSERVATION DISTRIC"
            },
            {
              "VENDOR_NAME": "ST.PAUL TRAVELERS"
            },
            {
              "VENDOR_NAME": "ST.PHILIP'S AFTER SCHOOL"
            },
            {
              "VENDOR_NAME": "STACEY GEIGER"
            },
            {
              "VENDOR_NAME": "STACI COULBOURN"
            },
            {
              "VENDOR_NAME": "STAFFORD CATERERS"
            },
            {
              "VENDOR_NAME": "STAFFORD CIRCUIT COURT"
            },
            {
              "VENDOR_NAME": "STAFFORD CO TREASURER"
            },
            {
              "VENDOR_NAME": "STAFFORD CO. TREASURER"
            },
            {
              "VENDOR_NAME": "STAFFORD COUNTY AMERICAN"
            },
            {
              "VENDOR_NAME": "STAFFORD COUNTY TREASURER"
            },
            {
              "VENDOR_NAME": "STAFFORD ELEMENTARY"
            },
            {
              "VENDOR_NAME": "STAFFORD GENERAL DIST. COURT"
            },
            {
              "VENDOR_NAME": "STAFFORD GENERAL DISTRICT COURT"
            },
            {
              "VENDOR_NAME": "STAFFORD GEN'L DIST COURT"
            },
            {
              "VENDOR_NAME": "STAFFORD GEN'L DIST CT"
            },
            {
              "VENDOR_NAME": "STAFFORD GEN'L DIST CT."
            },
            {
              "VENDOR_NAME": "STAFFORD GEN'L DISTRICT COURT"
            },
            {
              "VENDOR_NAME": "STAFFORD LAKE ASSOC."
            },
            {
              "VENDOR_NAME": "STAFFORD LAKES APTS."
            },
            {
              "VENDOR_NAME": "STAFFORD LAKES ASSOCIATES"
            },
            {
              "VENDOR_NAME": "STAFFORD LITTLE LEAGUE"
            },
            {
              "VENDOR_NAME": "STAFFORD MUNICIPAL UTIL"
            },
            {
              "VENDOR_NAME": "STAFFORD TOWNSHIP"
            },
            {
              "VENDOR_NAME": "STAN KOPCHINSKI"
            },
            {
              "VENDOR_NAME": "STANDARD CANDY COMPANY"
            },
            {
              "VENDOR_NAME": "STANDARD ELEVATOR"
            },
            {
              "VENDOR_NAME": "STANDARD REGISTER"
            },
            {
              "VENDOR_NAME": "STANDARD SPEAKER"
            },
            {
              "VENDOR_NAME": "STANDARD TITLE"
            },
            {
              "VENDOR_NAME": "STANDERS CHOICE HEALTH MATS"
            },
            {
              "VENDOR_NAME": "STANFORD GENERAL DIST. COURT"
            },
            {
              "VENDOR_NAME": "STANFORD UNIVERSITY-OFFICE OF T"
            },
            {
              "VENDOR_NAME": "STANLEY N. STORTI"
            },
            {
              "VENDOR_NAME": "STANLEY SECURITY SOLUTIONS"
            },
            {
              "VENDOR_NAME": "STANLEY YESKOLSKE"
            },
            {
              "VENDOR_NAME": "STANLEY'S HOME FURNISHINGS"
            },
            {
              "VENDOR_NAME": "STANTEC CONSULTING SERV.INC."
            },
            {
              "VENDOR_NAME": "STANTON DOOR COMPANY"
            },
            {
              "VENDOR_NAME": "STAPLES ADVANTAGE"
            },
            {
              "VENDOR_NAME": "STAPLES CREDIT PLAN"
            },
            {
              "VENDOR_NAME": "STAR BUILDING SERVICES"
            },
            {
              "VENDOR_NAME": "STAR MANUFACTURING"
            },
            {
              "VENDOR_NAME": "STAR TOBACCO"
            },
            {
              "VENDOR_NAME": "STARK & STARK"
            },
            {
              "VENDOR_NAME": "STARK AND STARK"
            },
            {
              "VENDOR_NAME": "STARKIST CO."
            },
            {
              "VENDOR_NAME": "STARSUPPLY"
            },
            {
              "VENDOR_NAME": "STAS"
            },
            {
              "VENDOR_NAME": "STATCO ENGINEERING & FABRICATORS"
            },
            {
              "VENDOR_NAME": "STATCO ENGINEERING AND"
            },
            {
              "VENDOR_NAME": "STATE CENTRAL UNIT"
            },
            {
              "VENDOR_NAME": "STATE COLLECTION AND DISBURSEMENT"
            },
            {
              "VENDOR_NAME": "STATE DISBURSEMENT"
            },
            {
              "VENDOR_NAME": "STATE DISBURSEMENT D404582"
            },
            {
              "VENDOR_NAME": "STATE DISBURSEMENT UNIT"
            },
            {
              "VENDOR_NAME": "STATE FARM INSURANCE"
            },
            {
              "VENDOR_NAME": "STATE HIGHWAY ADMINISTRATION OF MD."
            },
            {
              "VENDOR_NAME": "STATE MILK COMMISSION"
            },
            {
              "VENDOR_NAME": "STATE OF CONNECTICUT"
            },
            {
              "VENDOR_NAME": "STATE OF DE"
            },
            {
              "VENDOR_NAME": "STATE OF DE DEPT OF LABOR"
            },
            {
              "VENDOR_NAME": "STATE OF DE DIVISION OF REVENUE"
            },
            {
              "VENDOR_NAME": "STATE OF DEL DIV OF REV"
            },
            {
              "VENDOR_NAME": "STATE OF DEL DIV OF REV-Accrual (OT"
            },
            {
              "VENDOR_NAME": "STATE OF DELAWARE"
            },
            {
              "VENDOR_NAME": "STATE OF DELAWARE JUSTICE OF THE PE"
            },
            {
              "VENDOR_NAME": "STATE OF DELAWARE"
            },
            {
              "VENDOR_NAME": "STATE OF DELAWARE@2743"
            },
            {
              "VENDOR_NAME": "STATE OF DELAWARE-DIVISION OF REV"
            },
            {
              "VENDOR_NAME": "STATE OF FL DISBURSEMENT UNIT"
            },
            {
              "VENDOR_NAME": "STATE OF FLORIDA DISB.UNIT"
            },
            {
              "VENDOR_NAME": "STATE OF FLORIDA DISBURS. UNIT"
            },
            {
              "VENDOR_NAME": "STATE OF FLORIDA DISBURSE UNIT"
            },
            {
              "VENDOR_NAME": "STATE OF FLORIDA DISBURSE. UNIT"
            },
            {
              "VENDOR_NAME": "STATE OF FLORIDA DISBURSEMENT UNIT"
            },
            {
              "VENDOR_NAME": "STATE OF FLORIDA DISBURSEMENT UNIT-"
            },
            {
              "VENDOR_NAME": "STATE OF FLORIDA DIST. UNIT"
            },
            {
              "VENDOR_NAME": "STATE OF GEORGIA"
            },
            {
              "VENDOR_NAME": "STATE OF MARYLAND"
            },
            {
              "VENDOR_NAME": "STATE OF MARYLAND CENTRAL COLLECTIO"
            },
            {
              "VENDOR_NAME": "STATE OF MARYLAND DLLR"
            },
            {
              "VENDOR_NAME": "STATE OF MARYLAND-Accrual (OTP)"
            },
            {
              "VENDOR_NAME": "STATE OF MD"
            },
            {
              "VENDOR_NAME": "STATE OF MD CENTRAL COLL. UNIT"
            },
            {
              "VENDOR_NAME": "STATE OF MD CENTRAL COLLECTION UNIT"
            },
            {
              "VENDOR_NAME": "STATE OF MICHIGAN"
            },
            {
              "VENDOR_NAME": "STATE OF NEW JERSEY"
            },
            {
              "VENDOR_NAME": "STATE OF NEW JERSEY CBT"
            },
            {
              "VENDOR_NAME": "STATE OF NEW JERSEY"
            },
            {
              "VENDOR_NAME": "STATE OF NEW JERSEY"
            },
            {
              "VENDOR_NAME": "STATE OF NEW JERSEY-Accrual (OTP)"
            },
            {
              "VENDOR_NAME": "STATE OF NEW JERSEY-ATF"
            },
            {
              "VENDOR_NAME": "STATE OF NEW JERSEY-DIVISION OF C"
            },
            {
              "VENDOR_NAME": "STATE OF NEW JERSEY-LAND USE REG.PR"
            },
            {
              "VENDOR_NAME": "STATE OF NEW JERSEY-PPT"
            },
            {
              "VENDOR_NAME": "STATE OF VIRGINIA"
            },
            {
              "VENDOR_NAME": "STATE OF VIRGINIA-Accrual (OTP)"
            },
            {
              "VENDOR_NAME": "STATE TAX COMMISSION"
            },
            {
              "VENDOR_NAME": "STATEWIDE TAX RECOVERY"
            },
            {
              "VENDOR_NAME": "STAUBACH CAPITAL MARKETS"
            },
            {
              "VENDOR_NAME": "STAYBRIDGE SUITES"
            },
            {
              "VENDOR_NAME": "STEAK AND HOAGIE FACTORY"
            },
            {
              "VENDOR_NAME": "STEAM WAY"
            },
            {
              "VENDOR_NAME": "STEEL BRICK LLC"
            },
            {
              "VENDOR_NAME": "STEELMAN PHOTOGRAPHICS &"
            },
            {
              "VENDOR_NAME": "STEELTEC"
            },
            {
              "VENDOR_NAME": "STEFANIE HERON-BIRL"
            },
            {
              "VENDOR_NAME": "STEIN INVESTMENT GP LTD"
            },
            {
              "VENDOR_NAME": "STEINERT GIRLS BASKETBALL"
            },
            {
              "VENDOR_NAME": "STELLA D'ORO BISCUIT COMPANY"
            },
            {
              "VENDOR_NAME": "STEMMLE PLUMBING REPAIR"
            },
            {
              "VENDOR_NAME": "STEPHANIE ANN GERLOCK"
            },
            {
              "VENDOR_NAME": "STEPHANIE CAPACCIO"
            },
            {
              "VENDOR_NAME": "STEPHANIE OJEDA"
            },
            {
              "VENDOR_NAME": "STEPHEN DOUGHTY LYONS DOUGHTY & VEL"
            },
            {
              "VENDOR_NAME": "STEPHEN DOUGHTY"
            },
            {
              "VENDOR_NAME": "STEPHEN I. ROSS"
            },
            {
              "VENDOR_NAME": "STEPHEN J.SCHUKRAFT"
            },
            {
              "VENDOR_NAME": "STEPHEN L. CROPPER"
            },
            {
              "VENDOR_NAME": "STEPHEN LIEBERMAN"
            },
            {
              "VENDOR_NAME": "STEPHEN V. HALES"
            },
            {
              "VENDOR_NAME": "STEPHENS"
            },
            {
              "VENDOR_NAME": "STEPHENS"
            },
            {
              "VENDOR_NAME": "STEPHENSON EQUIPMENT"
            },
            {
              "VENDOR_NAME": "STEPTOE & JOHNSON"
            },
            {
              "VENDOR_NAME": "STERICYCLE"
            },
            {
              "VENDOR_NAME": "STERLING CHURCH ST FURN. STORE"
            },
            {
              "VENDOR_NAME": "STERLING CHURCH ST FURNITURE"
            },
            {
              "VENDOR_NAME": "STERLING CHURCH ST. FURN.STORE"
            },
            {
              "VENDOR_NAME": "STERLING CHURCH ST. FURNITURE"
            },
            {
              "VENDOR_NAME": "STERLING CHURCH STREET"
            },
            {
              "VENDOR_NAME": "STERLING COMMERCE"
            },
            {
              "VENDOR_NAME": "STERLING EDUCATION SERVICES"
            },
            {
              "VENDOR_NAME": "STERLING EVENT PLANNERS"
            },
            {
              "VENDOR_NAME": "STERLING H.S. WRESTLING"
            },
            {
              "VENDOR_NAME": "STERLING SOLUTIONS LLC"
            },
            {
              "VENDOR_NAME": "STERN MOBILE FIELD OFFICES"
            },
            {
              "VENDOR_NAME": "STEVE & CO. PRODUCTIONS"
            },
            {
              "VENDOR_NAME": "STEVE CHALFANT"
            },
            {
              "VENDOR_NAME": "STEVE GEMMA"
            },
            {
              "VENDOR_NAME": "STEVE McCARTY"
            },
            {
              "VENDOR_NAME": "STEVE RILEY"
            },
            {
              "VENDOR_NAME": "STEVEN A.EDWARDS"
            },
            {
              "VENDOR_NAME": "STEVEN D RIGGINS"
            },
            {
              "VENDOR_NAME": "STEVEN GAMBLE"
            },
            {
              "VENDOR_NAME": "STEVEN J PALUMBO"
            },
            {
              "VENDOR_NAME": "STEVEN MARSH"
            },
            {
              "VENDOR_NAME": "STEVEN R.HUNTER"
            },
            {
              "VENDOR_NAME": "STEVEN STEINHAUSER"
            },
            {
              "VENDOR_NAME": "STEVENS & LEE"
            },
            {
              "VENDOR_NAME": "STEVE'S ROAD SERVICE"
            },
            {
              "VENDOR_NAME": "STEWART D.FRIEDMAN"
            },
            {
              "VENDOR_NAME": "STIER GENERAL CONTRACTING"
            },
            {
              "VENDOR_NAME": "STILLWATER SEPTIC SERVICE"
            },
            {
              "VENDOR_NAME": "STILLWATER TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "STL INC"
            },
            {
              "VENDOR_NAME": "STOBBA ASSOCIATES"
            },
            {
              "VENDOR_NAME": "STOCKTON ATHLETICS @"
            },
            {
              "VENDOR_NAME": "STOFFEL SEALS"
            },
            {
              "VENDOR_NAME": "STOFFEL SEALS CORP."
            },
            {
              "VENDOR_NAME": "STOFFEL SEALS CORPORATION"
            },
            {
              "VENDOR_NAME": "STOKES EQUIPMENT CO INC"
            },
            {
              "VENDOR_NAME": "STOKLEYS"
            },
            {
              "VENDOR_NAME": "STOLLE FOR SENATE"
            },
            {
              "VENDOR_NAME": "STOLTZ MGMT"
            },
            {
              "VENDOR_NAME": "STOLTZFUS STRUCTURES LLC"
            },
            {
              "VENDOR_NAME": "STOLZFUS-MAST"
            },
            {
              "VENDOR_NAME": "STONE HARBOR RESCUE SQUAD"
            },
            {
              "VENDOR_NAME": "STONE HARBOR VOLUNTEER"
            },
            {
              "VENDOR_NAME": "STONE RIDGE APTS"
            },
            {
              "VENDOR_NAME": "STORAGE 2000 - SPOTSYLVANIA"
            },
            {
              "VENDOR_NAME": "STORAGE TECHNOLOGY CORP"
            },
            {
              "VENDOR_NAME": "STORAGE USA-701"
            },
            {
              "VENDOR_NAME": "STORCK USA LP"
            },
            {
              "VENDOR_NAME": "STORED VALUE SYSTEMS"
            },
            {
              "VENDOR_NAME": "STORIES FROM SPACE"
            },
            {
              "VENDOR_NAME": "STOSCH FOR SENATE"
            },
            {
              "VENDOR_NAME": "STR SOFTWARE COMPANY"
            },
            {
              "VENDOR_NAME": "STRADLEY"
            },
            {
              "VENDOR_NAME": "STRANGE'S FLORIST AND GARDEN CENTER"
            },
            {
              "VENDOR_NAME": "STRASBURGER & SIEGEL"
            },
            {
              "VENDOR_NAME": "STRATEGIC MANAGEMENT GROUP"
            },
            {
              "VENDOR_NAME": "STRATEGIC PRODUCTS & SERVICES"
            },
            {
              "VENDOR_NAME": "STRATEGIX"
            },
            {
              "VENDOR_NAME": "STRATFORD HALL"
            },
            {
              "VENDOR_NAME": "STRATUS PETROLEUM CORP."
            },
            {
              "VENDOR_NAME": "STRAWN ELECTRIC"
            },
            {
              "VENDOR_NAME": "STROEHMAN BAL/BIMBO FOODS INC"
            },
            {
              "VENDOR_NAME": "STROEHMANN BAKERIES"
            },
            {
              "VENDOR_NAME": "STROEHMANN HAZ/BIMBO FOODS INC"
            },
            {
              "VENDOR_NAME": "STROEHMANN NJ/BIMBO FOODS INC"
            },
            {
              "VENDOR_NAME": "STROEHMANN PAN/BIMBO FOODS INC"
            },
            {
              "VENDOR_NAME": "STROEHMANN SO VA/BIMBO FOODS INC"
            },
            {
              "VENDOR_NAME": "STROEHMANN/BIMBO FOODS INC"
            },
            {
              "VENDOR_NAME": "STROUDSBURG WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "STRUCTURAL PLASTICS CORP."
            },
            {
              "VENDOR_NAME": "STRYKER SALES CORP."
            },
            {
              "VENDOR_NAME": "STUBHUB"
            },
            {
              "VENDOR_NAME": "STUDY LOGIC"
            },
            {
              "VENDOR_NAME": "STURM FOODS"
            },
            {
              "VENDOR_NAME": "SUBSURFACE TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "SUBURBAN ELECTRIC"
            },
            {
              "VENDOR_NAME": "SUBURBAN ENERGY SERVES"
            },
            {
              "VENDOR_NAME": "SUBURBAN LAWN EQUIPMENT"
            },
            {
              "VENDOR_NAME": "SUBURBAN MATERIALS COMPANY"
            },
            {
              "VENDOR_NAME": "SUBURBAN WASTE SERVICES"
            },
            {
              "VENDOR_NAME": "SUBURBAN WASTEWATER COMPANY"
            },
            {
              "VENDOR_NAME": "SUBURBAN WATER TECHNOLOGY"
            },
            {
              "VENDOR_NAME": "SUBURBAN WHOLESALE"
            },
            {
              "VENDOR_NAME": "SUBURBAN WRECKER SERVICE"
            },
            {
              "VENDOR_NAME": "SUBURBIA SHOPPING CENTER L.P."
            },
            {
              "VENDOR_NAME": "SUCCESS ADVERTISING INC."
            },
            {
              "VENDOR_NAME": "SUCCESSFACTORS"
            },
            {
              "VENDOR_NAME": "SUCCESSFUL EVENTS"
            },
            {
              "VENDOR_NAME": "SUCCESSORIES"
            },
            {
              "VENDOR_NAME": "SUEZ ENERGY RESOURCES NA"
            },
            {
              "VENDOR_NAME": "SUFFOLK COUNTY SCDU"
            },
            {
              "VENDOR_NAME": "SUFFOLK GEN'L DIST COURT"
            },
            {
              "VENDOR_NAME": "SUGAR FOODS CORPORATION"
            },
            {
              "VENDOR_NAME": "SULLIVAN & MCGRATH"
            },
            {
              "VENDOR_NAME": "SULLIVAN'S GARAGE"
            },
            {
              "VENDOR_NAME": "SUMMIT FARMS"
            },
            {
              "VENDOR_NAME": "SUMMIT FUELS"
            },
            {
              "VENDOR_NAME": "SUMMIT REALTY ADVISORS"
            },
            {
              "VENDOR_NAME": "SUN COAST SALES & SERVICE CO."
            },
            {
              "VENDOR_NAME": "SUN MICROSYSTEMS"
            },
            {
              "VENDOR_NAME": "SUN PROMO'S INC."
            },
            {
              "VENDOR_NAME": "SUN TIMES"
            },
            {
              "VENDOR_NAME": "SUNBELT RENTALS"
            },
            {
              "VENDOR_NAME": "SUNG JAI LEE"
            },
            {
              "VENDOR_NAME": "SUNGARD AVAILABILITY SERVICES"
            },
            {
              "VENDOR_NAME": "SUNGARD AVAILABILITY SERVICES"
            },
            {
              "VENDOR_NAME": "SUNGARD ENERGY SYSTEMS"
            },
            {
              "VENDOR_NAME": "SunGuard REFERENCE DATA SOLUTIONS"
            },
            {
              "VENDOR_NAME": "SUNLIGHT ELECTRICAL"
            },
            {
              "VENDOR_NAME": "SUN-LITE CORPORATION"
            },
            {
              "VENDOR_NAME": "SUNOCO CORPORATE CARD"
            },
            {
              "VENDOR_NAME": "Sunoco Fuel"
            },
            {
              "VENDOR_NAME": "SUNOCO RECEIVABL"
            },
            {
              "VENDOR_NAME": "SUNOCO"
            },
            {
              "VENDOR_NAME": "SUNPARK"
            },
            {
              "VENDOR_NAME": "SUNRISE ONYX TOV"
            },
            {
              "VENDOR_NAME": "SUNSATIONAL"
            },
            {
              "VENDOR_NAME": "SUNTRUST BANK"
            },
            {
              "VENDOR_NAME": "SUNWAY EQUITY LLC"
            },
            {
              "VENDOR_NAME": "SUPER 8 MOTEL"
            },
            {
              "VENDOR_NAME": "SUPER 8 MOTEL-HULL STREET ROAD"
            },
            {
              "VENDOR_NAME": "SUPERIOR COURT OF N.J."
            },
            {
              "VENDOR_NAME": "SUPERIOR COURT OF NEW JERSEY"
            },
            {
              "VENDOR_NAME": "SUPERIOR COURT OF NJ"
            },
            {
              "VENDOR_NAME": "SUPERIOR COURT OF NJ S.C.P"
            },
            {
              "VENDOR_NAME": "SUPERIOR COURT OF NJ SPC CIVIL PA"
            },
            {
              "VENDOR_NAME": "SUPERIOR COURT OF NJ SPEC CIV PART"
            },
            {
              "VENDOR_NAME": "SUPERIOR COURT OF NJ SPEC CIV PT"
            },
            {
              "VENDOR_NAME": "SUPERIOR COURT OF NJ SPEC CIVIL PAR"
            },
            {
              "VENDOR_NAME": "SUPERIOR COURT OF NJ SPEC. CIVIL PA"
            },
            {
              "VENDOR_NAME": "SUPERIOR COURT OF NJ SPEC. CIVIL PT"
            },
            {
              "VENDOR_NAME": "SUPERIOR COURT OF NJ SPECIAL CIV PT"
            },
            {
              "VENDOR_NAME": "SUPERIOR COURT OF NJ SPECIAL CIVIL"
            },
            {
              "VENDOR_NAME": "SUPERIOR COURT OF NJ SPECIAL PART"
            },
            {
              "VENDOR_NAME": "SUPERIOR COURT OF NJ"
            },
            {
              "VENDOR_NAME": "SUPERIOR COURT OFFICER"
            },
            {
              "VENDOR_NAME": "SUPERIOR COURT OFNJ SPEC CIV PART"
            },
            {
              "VENDOR_NAME": "SUPERIOR CT OF NJ"
            },
            {
              "VENDOR_NAME": "SUPERIOR CT. ANTHONY FAVOROTO"
            },
            {
              "VENDOR_NAME": "SUPERIOR CT. OF N.J."
            },
            {
              "VENDOR_NAME": "SUPERIOR CT. OF NJ"
            },
            {
              "VENDOR_NAME": "SUPERIOR CT.OF N.J. SPECIAL CIVIL"
            },
            {
              "VENDOR_NAME": "SUPERIOR SIGN PRODUCTIONS"
            },
            {
              "VENDOR_NAME": "SUPERIOR WATER CO."
            },
            {
              "VENDOR_NAME": "SUPERIOR WATER COMPANY"
            },
            {
              "VENDOR_NAME": "SUPERMARKET NEWS"
            },
            {
              "VENDOR_NAME": "SUPPORT CENTER FOR CHILD ADVOCATES"
            },
            {
              "VENDOR_NAME": "SUPPORT TERMINALS OPERATING PARTNER"
            },
            {
              "VENDOR_NAME": "SUPPRESSION SYSTEMS INC"
            },
            {
              "VENDOR_NAME": "SUPREME PROTEIN"
            },
            {
              "VENDOR_NAME": "SUPREME STAFFING"
            },
            {
              "VENDOR_NAME": "SURAY PROMOTIONS"
            },
            {
              "VENDOR_NAME": "SURE POWER"
            },
            {
              "VENDOR_NAME": "SURFACE LINK CORPORATION"
            },
            {
              "VENDOR_NAME": "SURFSIDE WEST DINER"
            },
            {
              "VENDOR_NAME": "SURGICAL ASSOC OF RICHMOND"
            },
            {
              "VENDOR_NAME": "SURGICAL ASSOC. OF RICHMOND"
            },
            {
              "VENDOR_NAME": "SurveyMonkey.com"
            },
            {
              "VENDOR_NAME": "SUSAN BRATTON"
            },
            {
              "VENDOR_NAME": "SUSAN CONNER"
            },
            {
              "VENDOR_NAME": "SUSAN D GELINAS"
            },
            {
              "VENDOR_NAME": "SUSAN FURMAN"
            },
            {
              "VENDOR_NAME": "SUSAN SCAMPTON"
            },
            {
              "VENDOR_NAME": "SUSAN SULLIVAN"
            },
            {
              "VENDOR_NAME": "SUSSEX COMBINED COURT"
            },
            {
              "VENDOR_NAME": "SUSSEX COUNTY"
            },
            {
              "VENDOR_NAME": "SUSSEX COUNTY COUNCIL"
            },
            {
              "VENDOR_NAME": "SUSSEX COUNTY PLANNING DIVISION"
            },
            {
              "VENDOR_NAME": "SUSSEX COUNTY WATER/SEWER"
            },
            {
              "VENDOR_NAME": "SUZANNE FINELY"
            },
            {
              "VENDOR_NAME": "SUZANNE FINLEY"
            },
            {
              "VENDOR_NAME": "SUZANNE KEENAN"
            },
            {
              "VENDOR_NAME": "SUZANNE MENSH"
            },
            {
              "VENDOR_NAME": "SVDP ST. THOMAS MORE"
            },
            {
              "VENDOR_NAME": "SW DEL CO MUN AUTH"
            },
            {
              "VENDOR_NAME": "SWAGELOK"
            },
            {
              "VENDOR_NAME": "SWANSON HARDWARE SUPPLY"
            },
            {
              "VENDOR_NAME": "SWARTHMORE ROTARY"
            },
            {
              "VENDOR_NAME": "SWARTZ CAMPBELL LLC"
            },
            {
              "VENDOR_NAME": "SWEDISH MATCH"
            },
            {
              "VENDOR_NAME": "SWEENEY RESEARCH LLC"
            },
            {
              "VENDOR_NAME": "SWEENEY'S BAKERY INC."
            },
            {
              "VENDOR_NAME": "SWEET STREET"
            },
            {
              "VENDOR_NAME": "SWEETENERS PLUS INC."
            },
            {
              "VENDOR_NAME": "SWEETWORKS"
            },
            {
              "VENDOR_NAME": "SWEETZELS FOODS LLC"
            },
            {
              "VENDOR_NAME": "SWIFT FOOD EQUIPMENT INC"
            },
            {
              "VENDOR_NAME": "SWING SHIFT"
            },
            {
              "VENDOR_NAME": "SWISHER"
            },
            {
              "VENDOR_NAME": "SWISHER INTL"
            },
            {
              "VENDOR_NAME": "SWISSLOG LOGISTICS"
            },
            {
              "VENDOR_NAME": "SWISSLOG WHSE & DISTRIB SOLUTIONS"
            },
            {
              "VENDOR_NAME": "SWITCHBACK RIDGE III"
            },
            {
              "VENDOR_NAME": "SWREG"
            },
            {
              "VENDOR_NAME": "SYBIL K BORDEN"
            },
            {
              "VENDOR_NAME": "SYKES"
            },
            {
              "VENDOR_NAME": "SYMANTEC CORPORATION"
            },
            {
              "VENDOR_NAME": "SYMBOL TECHNOLOGIES INC"
            },
            {
              "VENDOR_NAME": "SYNERGEX INTERNATIONAL CORP."
            },
            {
              "VENDOR_NAME": "SYNOVATE"
            },
            {
              "VENDOR_NAME": "SYRACUSE LABEL & SURROUND"
            },
            {
              "VENDOR_NAME": "SYSCO FOOD SERVICES OF PHILADELPHIA"
            },
            {
              "VENDOR_NAME": "T & M ASSOCIATES"
            },
            {
              "VENDOR_NAME": "T & N VAN SERVICE"
            },
            {
              "VENDOR_NAME": "T & S TRASH SERVICE"
            },
            {
              "VENDOR_NAME": "T A MALPEZZI & E E CASTOR"
            },
            {
              "VENDOR_NAME": "T. VERRASTRO BEER DISTRIBUTOR INC"
            },
            {
              "VENDOR_NAME": "T.A.TITLE INSURANCE COMPANY"
            },
            {
              "VENDOR_NAME": "T.D. LANDIS"
            },
            {
              "VENDOR_NAME": "T.D. MAYER CONSULTING"
            },
            {
              "VENDOR_NAME": "T.H.PROPERTY MAINTENANCE"
            },
            {
              "VENDOR_NAME": "T.J.'S CATERING"
            },
            {
              "VENDOR_NAME": "T.L.C. MECHANICAL"
            },
            {
              "VENDOR_NAME": "T.L.C.MECHANICAL"
            },
            {
              "VENDOR_NAME": "TABERNACLE EDUCATION FUND"
            },
            {
              "VENDOR_NAME": "TAFF AND DAVIES"
            },
            {
              "VENDOR_NAME": "TAGUE LUMBER"
            },
            {
              "VENDOR_NAME": "TAGUE LUMBER OF MEDIA INC"
            },
            {
              "VENDOR_NAME": "TAHT"
            },
            {
              "VENDOR_NAME": "TAKE CHARGE JUVENILE DIVERSION"
            },
            {
              "VENDOR_NAME": "TAKE FIVE CLEANING SERVICES LLC"
            },
            {
              "VENDOR_NAME": "TAKE-A -BREAK"
            },
            {
              "VENDOR_NAME": "TAKE-A-LABEL"
            },
            {
              "VENDOR_NAME": "TALBOT COUNTY"
            },
            {
              "VENDOR_NAME": "TALBOT COUNTY HEALTH DEPT."
            },
            {
              "VENDOR_NAME": "TALBOT COUNTY"
            },
            {
              "VENDOR_NAME": "TALENT STRATEGY PARTNERS LLC"
            },
            {
              "VENDOR_NAME": "TALENT STRATEGY PARTNERS"
            },
            {
              "VENDOR_NAME": "TalentSmart"
            },
            {
              "VENDOR_NAME": "TALX CORPORATION"
            },
            {
              "VENDOR_NAME": "TALX UCeXpress"
            },
            {
              "VENDOR_NAME": "TAMER GOUDA"
            },
            {
              "VENDOR_NAME": "TAMIMENT RESORT & CONFERENCE CENTER"
            },
            {
              "VENDOR_NAME": "TAMMY BIXLER-AWAD"
            },
            {
              "VENDOR_NAME": "TAMMY SUBARAN"
            },
            {
              "VENDOR_NAME": "TANK INSTALLERS OF PA"
            },
            {
              "VENDOR_NAME": "TANKNOLOGY"
            },
            {
              "VENDOR_NAME": "TANTALA ASSOCIATES"
            },
            {
              "VENDOR_NAME": "TARGET BUILDING CONSTRUCTION"
            },
            {
              "VENDOR_NAME": "TARGET CORPORATION"
            },
            {
              "VENDOR_NAME": "TARGET INVESTMENTS"
            },
            {
              "VENDOR_NAME": "TASQ TECHNOLOGY"
            },
            {
              "VENDOR_NAME": "TASTE OF NATURE"
            },
            {
              "VENDOR_NAME": "TASTY BAKING CO."
            },
            {
              "VENDOR_NAME": "TASTY BAKING COMPANY"
            },
            {
              "VENDOR_NAME": "TATE & HILL INC"
            },
            {
              "VENDOR_NAME": "TAX CLAIM BUREAU"
            },
            {
              "VENDOR_NAME": "TAX COLLECTOR"
            },
            {
              "VENDOR_NAME": "TAX ENFORCEMENT OFFICE"
            },
            {
              "VENDOR_NAME": "TAX ENFORCEMENT OFFICE MC415"
            },
            {
              "VENDOR_NAME": "TAYLOR EQUIPMENT DISTRIBUTORS"
            },
            {
              "VENDOR_NAME": "TAYLOR FARMS FLORIDA"
            },
            {
              "VENDOR_NAME": "TAYLOR FREEZER SALES"
            },
            {
              "VENDOR_NAME": "TAYLOR HOSPICE"
            },
            {
              "VENDOR_NAME": "TAYLOR HOSPITAL"
            },
            {
              "VENDOR_NAME": "TAYLOR OIL & PROPANE"
            },
            {
              "VENDOR_NAME": "TAYLOR OIL COMANY"
            },
            {
              "VENDOR_NAME": "TAYLOR PRODUCTS PA"
            },
            {
              "VENDOR_NAME": "TAYLOR PRODUCTS"
            },
            {
              "VENDOR_NAME": "TAYLOR RENTAL"
            },
            {
              "VENDOR_NAME": "TAYLOR RENTAL CENTER"
            },
            {
              "VENDOR_NAME": "TBG INSURANCE SERV.CORP"
            },
            {
              "VENDOR_NAME": "TCM"
            },
            {
              "VENDOR_NAME": "TCT OIL BROKERS INC"
            },
            {
              "VENDOR_NAME": "TDS / GCR TRUCK TIRE CENTERS"
            },
            {
              "VENDOR_NAME": "TEAM CONCEPTS PROJECTS"
            },
            {
              "VENDOR_NAME": "TEAM G RIDERS"
            },
            {
              "VENDOR_NAME": "TEAM MANAGEMENT BRIEFINGS"
            },
            {
              "VENDOR_NAME": "TEAM PENNSYLVANIA FOUNDATION"
            },
            {
              "VENDOR_NAME": "TEAMSTERS LOCAL 463"
            },
            {
              "VENDOR_NAME": "TEC AMERICA"
            },
            {
              "VENDOR_NAME": "TECH DEPOT"
            },
            {
              "VENDOR_NAME": "TECH FOR LESS"
            },
            {
              "VENDOR_NAME": "TECHCALIBER CONSULTING"
            },
            {
              "VENDOR_NAME": "TECHNIBILT LTD"
            },
            {
              "VENDOR_NAME": "TECHNOLOGY COUNCIL"
            },
            {
              "VENDOR_NAME": "TECHNOLOGY REVIEW"
            },
            {
              "VENDOR_NAME": "TECHSMITH CORPORATION"
            },
            {
              "VENDOR_NAME": "TED SGRO"
            },
            {
              "VENDOR_NAME": "TEDESCO GRUCCIO & REUSS"
            },
            {
              "VENDOR_NAME": "TEKNA ADVANCE SOLUTIONS"
            },
            {
              "VENDOR_NAME": "TEKSYSTEMS"
            },
            {
              "VENDOR_NAME": "TELCY LLC"
            },
            {
              "VENDOR_NAME": "TELECONFERENCING SERVICES"
            },
            {
              "VENDOR_NAME": "TELEGENIX"
            },
            {
              "VENDOR_NAME": "TELEPHONE DOCTOR"
            },
            {
              "VENDOR_NAME": "TELERIK CORP."
            },
            {
              "VENDOR_NAME": "TELFORD BOROUGH AUTHORITY"
            },
            {
              "VENDOR_NAME": "TELKO ENTERPRISE"
            },
            {
              "VENDOR_NAME": "TELLERMATE"
            },
            {
              "VENDOR_NAME": "TEMPLE TOURS"
            },
            {
              "VENDOR_NAME": "TEMPLE UNIVERSITY HOSPITAL"
            },
            {
              "VENDOR_NAME": "TEMPORARY TECHNICAL SOLUTIONS"
            },
            {
              "VENDOR_NAME": "TENABLE NETWORK SECURITY INC."
            },
            {
              "VENDOR_NAME": "TENNANT"
            },
            {
              "VENDOR_NAME": "TENNESSEE CHILD SUPPORT"
            },
            {
              "VENDOR_NAME": "TERESA CONLIN"
            },
            {
              "VENDOR_NAME": "TERESA MCCOY"
            },
            {
              "VENDOR_NAME": "TERMINIX COMMERCIAL"
            },
            {
              "VENDOR_NAME": "TERRA LANDSCAPING & HARDSCAPING"
            },
            {
              "VENDOR_NAME": "TERRATECH"
            },
            {
              "VENDOR_NAME": "TERRAWAVE SOLUTIONS"
            },
            {
              "VENDOR_NAME": "TERRENCE HERLING"
            },
            {
              "VENDOR_NAME": "TERRENCE WHITE"
            },
            {
              "VENDOR_NAME": "TERRI MICKLIN"
            },
            {
              "VENDOR_NAME": "TERRY B. BURKE"
            },
            {
              "VENDOR_NAME": "TERRY JOHNSON"
            },
            {
              "VENDOR_NAME": "TERRY KOTULKA"
            },
            {
              "VENDOR_NAME": "TERRY LEE DECKER"
            },
            {
              "VENDOR_NAME": "TERRY MCCARTHY"
            },
            {
              "VENDOR_NAME": "TERRYBERRY"
            },
            {
              "VENDOR_NAME": "TERSTAPPEN'S BAKE SHOP"
            },
            {
              "VENDOR_NAME": "test"
            },
            {
              "VENDOR_NAME": "Test for regional site"
            },
            {
              "VENDOR_NAME": "Test for regionalsite2"
            },
            {
              "VENDOR_NAME": "Test sub range"
            },
            {
              "VENDOR_NAME": "Test Utility1"
            },
            {
              "VENDOR_NAME": "Test Utlity Company"
            },
            {
              "VENDOR_NAME": "Test Vendor creation"
            },
            {
              "VENDOR_NAME": "Test Water1 Utility"
            },
            {
              "VENDOR_NAME": "Testing a vendor"
            },
            {
              "VENDOR_NAME": "Test-NISC HOVendor"
            },
            {
              "VENDOR_NAME": "TETLEY HARRIS FOOD GROUP"
            },
            {
              "VENDOR_NAME": "TETRA TECH INC."
            },
            {
              "VENDOR_NAME": "TEXAS CHILD SUPPORT"
            },
            {
              "VENDOR_NAME": "TEXAS CHILD SUPPORT S.D.U."
            },
            {
              "VENDOR_NAME": "TEXAS CHILD SUPPORT SDU"
            },
            {
              "VENDOR_NAME": "TEXAS GUARANTEED"
            },
            {
              "VENDOR_NAME": "TEXON BUTANE BLENDING LLC"
            },
            {
              "VENDOR_NAME": "TEXON BUTANE BLENDING LLC-ACCRUAL"
            },
            {
              "VENDOR_NAME": "TFC RECYCLING"
            },
            {
              "VENDOR_NAME": "TFJR ASSOCIATES"
            },
            {
              "VENDOR_NAME": "TFJR ASSOCIATES"
            },
            {
              "VENDOR_NAME": "THALHIMER"
            },
            {
              "VENDOR_NAME": "THALHIMER SULLIVAN PROPERTIES LLC"
            },
            {
              "VENDOR_NAME": "THANASI FOODS"
            },
            {
              "VENDOR_NAME": "THE ACADEMY OF MANAGEMENT"
            },
            {
              "VENDOR_NAME": "THE ACE CENTER"
            },
            {
              "VENDOR_NAME": "THE ADAM C. CONBOY MEMORIAL FUND"
            },
            {
              "VENDOR_NAME": "THE AMERICAN LAWYER"
            },
            {
              "VENDOR_NAME": "THE ANNE S. MACHSON TRUST"
            },
            {
              "VENDOR_NAME": "THE ARCHER GROUP"
            },
            {
              "VENDOR_NAME": "THE AUXILIARY OF SHORE MEMORIAL"
            },
            {
              "VENDOR_NAME": "THE BARASH GROUP"
            },
            {
              "VENDOR_NAME": "THE BARKING LOT"
            },
            {
              "VENDOR_NAME": "THE BARS PROGRAM"
            },
            {
              "VENDOR_NAME": "THE BEACH HAVEN EXCHANGE CLUB"
            },
            {
              "VENDOR_NAME": "THE BELL GROUP"
            },
            {
              "VENDOR_NAME": "THE BENJAMIN FORBES COMPANY"
            },
            {
              "VENDOR_NAME": "THE BLADE RUNNER"
            },
            {
              "VENDOR_NAME": "THE BOROUGH OF LAKEHURST"
            },
            {
              "VENDOR_NAME": "THE BOROUGH OF SAYREVILLE"
            },
            {
              "VENDOR_NAME": "THE BRAHNEY GROUP LLC"
            },
            {
              "VENDOR_NAME": "THE BRICKMAN GROUP"
            },
            {
              "VENDOR_NAME": "THE BRIGGS COMPANY"
            },
            {
              "VENDOR_NAME": "THE BUREAU OF NATIONAL AFFAIRS"
            },
            {
              "VENDOR_NAME": "THE BURN CENTER AT ST BARNABAS"
            },
            {
              "VENDOR_NAME": "THE BUTTERFLY CHARITIES"
            },
            {
              "VENDOR_NAME": "THE CADDIE COMPANY"
            },
            {
              "VENDOR_NAME": "THE CARMEN DEGREGORIO HERO FUND"
            },
            {
              "VENDOR_NAME": "THE CBORD GROUP"
            },
            {
              "VENDOR_NAME": "THE CENTER FOUNDATION"
            },
            {
              "VENDOR_NAME": "THE CHARTER SCHOOL OF WILMINGTON"
            },
            {
              "VENDOR_NAME": "THE CHILDREN'S HOSPITAL"
            },
            {
              "VENDOR_NAME": "THE CHILDREN'S HOSPITAL FOUNDATION"
            },
            {
              "VENDOR_NAME": "THE CITY OF CAPE MAY"
            },
            {
              "VENDOR_NAME": "THE CLERK OF YORK COUNTY"
            },
            {
              "VENDOR_NAME": "THE COCA COLA COMPANY"
            },
            {
              "VENDOR_NAME": "THE COCA-COLA COMPANY"
            },
            {
              "VENDOR_NAME": "THE COLEMAN PARTNERSHIP"
            },
            {
              "VENDOR_NAME": "THE COLOGNE VOLUNTEER FIRE"
            },
            {
              "VENDOR_NAME": "THE COLONEL'S CORNER LC"
            },
            {
              "VENDOR_NAME": "THE COLONIES AT GINTER PARK"
            },
            {
              "VENDOR_NAME": "THE COMMITTEE TO ELECT DAVID TACKET"
            },
            {
              "VENDOR_NAME": "THE CONCOURS GROUP"
            },
            {
              "VENDOR_NAME": "THE CONFERENCE BOARD INC"
            },
            {
              "VENDOR_NAME": "THE COPPER GOLF TOURNAMENT"
            },
            {
              "VENDOR_NAME": "THE COPPER GOLF TOURNAMENT COMMITTE"
            },
            {
              "VENDOR_NAME": "THE COPPER OPEN"
            },
            {
              "VENDOR_NAME": "THE CORBIN FAMILY LLC"
            },
            {
              "VENDOR_NAME": "THE COUNTRY PRESS"
            },
            {
              "VENDOR_NAME": "THE COURIER NEWSPAPER"
            },
            {
              "VENDOR_NAME": "THE CRADLE GROUP"
            },
            {
              "VENDOR_NAME": "THE CRIME VICTIMS' CENTER"
            },
            {
              "VENDOR_NAME": "THE CUTTING BOARD COMPANY"
            },
            {
              "VENDOR_NAME": "THE DAILY JOURNAL"
            },
            {
              "VENDOR_NAME": "THE DAILY TIMES"
            },
            {
              "VENDOR_NAME": "THE DANIEL WEAVER COMPANY"
            },
            {
              "VENDOR_NAME": "THE DANNON COMPANY"
            },
            {
              "VENDOR_NAME": "THE DAVEY TREE EXPERT INC"
            },
            {
              "VENDOR_NAME": "THE DAVID GALLAGHER FOUNDATION"
            },
            {
              "VENDOR_NAME": "THE DELFIELD COMPANY"
            },
            {
              "VENDOR_NAME": "THE DEVEREUX FOUNDATION"
            },
            {
              "VENDOR_NAME": "THE DISNEY INSTITUTE"
            },
            {
              "VENDOR_NAME": "THE DOCUMENT TERMINATION COMPANY"
            },
            {
              "VENDOR_NAME": "THE DONEGAL ASSOCIATION"
            },
            {
              "VENDOR_NAME": "THE ECONOMIST"
            },
            {
              "VENDOR_NAME": "THE EDEN INSTITUTE FOUND"
            },
            {
              "VENDOR_NAME": "THE EDEN INSTITUTE FOUNDATION"
            },
            {
              "VENDOR_NAME": "THE EFFECTIVE EDGE"
            },
            {
              "VENDOR_NAME": "THE ELECTION FUND OF THE BLOCK TEAM"
            },
            {
              "VENDOR_NAME": "THE ELI'S CHEESECAKE COMPANY"
            },
            {
              "VENDOR_NAME": "THE ESOP ASSOCIATION"
            },
            {
              "VENDOR_NAME": "THE ESTATE OF ADELE SANDS"
            },
            {
              "VENDOR_NAME": "THE EVERGREENS"
            },
            {
              "VENDOR_NAME": "THE EXPRESS TIMES"
            },
            {
              "VENDOR_NAME": "THE FAIRMONT PRESS"
            },
            {
              "VENDOR_NAME": "THE FIRM CONNECTION"
            },
            {
              "VENDOR_NAME": "THE FOOD ALLERGY AND"
            },
            {
              "VENDOR_NAME": "THE FOOD MARKETING"
            },
            {
              "VENDOR_NAME": "THE FOREIGN CANDY CO. INC."
            },
            {
              "VENDOR_NAME": "THE FOUNDATION OF THE DELAWARE COUN"
            },
            {
              "VENDOR_NAME": "THE FREDERICK GROUP LTD"
            },
            {
              "VENDOR_NAME": "THE FREDERICK NEWS-POST"
            },
            {
              "VENDOR_NAME": "THE FRIENDS OF WEST GOSHEN PARK"
            },
            {
              "VENDOR_NAME": "THE FRS COMPANY"
            },
            {
              "VENDOR_NAME": "THE FUN DEPARTMENT"
            },
            {
              "VENDOR_NAME": "THE GALLAGHER GROUP"
            },
            {
              "VENDOR_NAME": "THE GALLERY COLLECTION"
            },
            {
              "VENDOR_NAME": "THE GALLUP ORGANIZATION"
            },
            {
              "VENDOR_NAME": "THE GARO YEPREMIAN FOUNDATION"
            },
            {
              "VENDOR_NAME": "THE GILBERTSON GROUP"
            },
            {
              "VENDOR_NAME": "THE GLOUCESTER COUNTY VOCATIONAL"
            },
            {
              "VENDOR_NAME": "THE GLOUCESTER TWSP M U A"
            },
            {
              "VENDOR_NAME": "THE GOLDEN INN"
            },
            {
              "VENDOR_NAME": "THE GRAFTON ASSOCIATION"
            },
            {
              "VENDOR_NAME": "THE GREAT FREDERICK FAIR INC"
            },
            {
              "VENDOR_NAME": "THE HAIN CELESTIAL GROUP"
            },
            {
              "VENDOR_NAME": "THE HARRY KALAS HIGH HOPES"
            },
            {
              "VENDOR_NAME": "THE HERSHEY LODGE"
            },
            {
              "VENDOR_NAME": "THE HOBBS GROUP"
            },
            {
              "VENDOR_NAME": "THE HOME DEPOT"
            },
            {
              "VENDOR_NAME": "THE HOME DEPOT SUPPLY"
            },
            {
              "VENDOR_NAME": "THE HORSHAM CLINIC"
            },
            {
              "VENDOR_NAME": "THE HUMAN RESOURCE"
            },
            {
              "VENDOR_NAME": "THE ICEE COMPANY"
            },
            {
              "VENDOR_NAME": "THE INFOHRM GROUP INC."
            },
            {
              "VENDOR_NAME": "THE INN AT READING"
            },
            {
              "VENDOR_NAME": "THE INN AT TOWAMENCIN"
            },
            {
              "VENDOR_NAME": "THE INSTITUTE OF INTERNAL AUDITORS"
            },
            {
              "VENDOR_NAME": "THE INTELLIGENCER"
            },
            {
              "VENDOR_NAME": "THE INTERNATIONAL INSTITUTE"
            },
            {
              "VENDOR_NAME": "THE INVENTURE GROUP"
            },
            {
              "VENDOR_NAME": "THE IRIS COMPANIES"
            },
            {
              "VENDOR_NAME": "THE J MICHAEL FRALINGER SR."
            },
            {
              "VENDOR_NAME": "THE J. PAUL COMPANY"
            },
            {
              "VENDOR_NAME": "THE JAG FUND"
            },
            {
              "VENDOR_NAME": "THE JASON DANE CO INC"
            },
            {
              "VENDOR_NAME": "THE JEFF PANEK"
            },
            {
              "VENDOR_NAME": "THE JERRY SEGAL CLASSIC"
            },
            {
              "VENDOR_NAME": "THE JUDGE GROUP"
            },
            {
              "VENDOR_NAME": "THE JUDGEMENT GROUP"
            },
            {
              "VENDOR_NAME": "THE KEN BLANCHARD COMPANIES"
            },
            {
              "VENDOR_NAME": "THE KEY WORKS INC"
            },
            {
              "VENDOR_NAME": "THE LANDINGS AT PINE LAKE"
            },
            {
              "VENDOR_NAME": "THE LANDIS COMPANY"
            },
            {
              "VENDOR_NAME": "THE LEADER'S EDGE"
            },
            {
              "VENDOR_NAME": "THE LEGAL INTELLIGENCER"
            },
            {
              "VENDOR_NAME": "THE LEUKEMIA & LYMPHOMA SOCIETY"
            },
            {
              "VENDOR_NAME": "THE LINDA CREED BREAST CANCER"
            },
            {
              "VENDOR_NAME": "THE LOGAN TOWNSHIP M U A"
            },
            {
              "VENDOR_NAME": "THE LOGO SHOP OF MARYLAND"
            },
            {
              "VENDOR_NAME": "THE LOVITZ LAW FIRM"
            },
            {
              "VENDOR_NAME": "THE LUTHERAN CHURCH OF THE RESURREC"
            },
            {
              "VENDOR_NAME": "THE MARKET AT OPITZ CROSSING"
            },
            {
              "VENDOR_NAME": "THE MARTY LYONS FOUNDATION"
            },
            {
              "VENDOR_NAME": "THE MASCOT ORGANIZATION"
            },
            {
              "VENDOR_NAME": "THE MASCOT ORGANIZATION"
            },
            {
              "VENDOR_NAME": "THE MAUREEN A.O'CONNOR MEMORIAL FUN"
            },
            {
              "VENDOR_NAME": "THE McGRAW HILL COMPANIES"
            },
            {
              "VENDOR_NAME": "THE MEDALLION COMP.INC."
            },
            {
              "VENDOR_NAME": "THE MERCURY"
            },
            {
              "VENDOR_NAME": "THE MEYERCORD CO"
            },
            {
              "VENDOR_NAME": "THE MILKWEED"
            },
            {
              "VENDOR_NAME": "THE MINISTRY OF CARING"
            },
            {
              "VENDOR_NAME": "THE MNM GROUP"
            },
            {
              "VENDOR_NAME": "THE MONUMENT GROUP"
            },
            {
              "VENDOR_NAME": "THE MORNING CALL"
            },
            {
              "VENDOR_NAME": "THE MUNICIPAL UTILITIES COMMISSION"
            },
            {
              "VENDOR_NAME": "THE NATIONAL CONFERENCE CENTER"
            },
            {
              "VENDOR_NAME": "THE NATIONAL LAW JOURNAL"
            },
            {
              "VENDOR_NAME": "THE NATIONAL MPS SOCIETY"
            },
            {
              "VENDOR_NAME": "THE NAT'L CENTER FOR EMPLOYEE OWNER"
            },
            {
              "VENDOR_NAME": "THE NEWTON ENGINEERING GROUP"
            },
            {
              "VENDOR_NAME": "THE NEXXUS MARKETING GROUP LLC"
            },
            {
              "VENDOR_NAME": "THE NIEMEYER CORP."
            },
            {
              "VENDOR_NAME": "THE NORTH HIGHLAND CO."
            },
            {
              "VENDOR_NAME": "THE NORTH HIGHLAND COMPANY"
            },
            {
              "VENDOR_NAME": "THE NPD GROUP"
            },
            {
              "VENDOR_NAME": "THE OCEAN COUNTY"
            },
            {
              "VENDOR_NAME": "THE OMELETTE KING"
            },
            {
              "VENDOR_NAME": "THE PENN CITY ELEVATOR CO"
            },
            {
              "VENDOR_NAME": "THE PENN STATE UNIV"
            },
            {
              "VENDOR_NAME": "THE PENNSYLVANIA GOVERNOR'S CONFERE"
            },
            {
              "VENDOR_NAME": "THE PENNSYLVANIA STATE UNIVERSITY"
            },
            {
              "VENDOR_NAME": "THE PHILADELPHIA PRE-COOKED"
            },
            {
              "VENDOR_NAME": "THE PINES AT GREEN RUN"
            },
            {
              "VENDOR_NAME": "THE PLAN SOURCE"
            },
            {
              "VENDOR_NAME": "THE PLANT PLACE"
            },
            {
              "VENDOR_NAME": "THE PLECZYNSKI FOUNDATION"
            },
            {
              "VENDOR_NAME": "THE PRINCETON PACKET"
            },
            {
              "VENDOR_NAME": "THE PRODUCT SURETY CENTER"
            },
            {
              "VENDOR_NAME": "THE PROGRESS-INDEX"
            },
            {
              "VENDOR_NAME": "THE PROMOTION IN MOTION"
            },
            {
              "VENDOR_NAME": "THE PRUDENTIAL ZACK"
            },
            {
              "VENDOR_NAME": "THE QUAKER OATS COMPANY"
            },
            {
              "VENDOR_NAME": "THE RAINBOW CHORALE OF DELAWARE"
            },
            {
              "VENDOR_NAME": "THE RECTOR AND VISITORS OF THE UNIV"
            },
            {
              "VENDOR_NAME": "THE RENDELL INAUGURAL COMMITTEE"
            },
            {
              "VENDOR_NAME": "THE REPORTER"
            },
            {
              "VENDOR_NAME": "THE RESOURCE NETWORK"
            },
            {
              "VENDOR_NAME": "THE REVIEW"
            },
            {
              "VENDOR_NAME": "THE RICHARDS GROUP"
            },
            {
              "VENDOR_NAME": "THE ROBINSON GROUP"
            },
            {
              "VENDOR_NAME": "THE ROSE RESTAURANT"
            },
            {
              "VENDOR_NAME": "THE ROTARY CLUB OF FORKED RIVER"
            },
            {
              "VENDOR_NAME": "THE ROUSE & ASSOCIATES RETAIL GROUP"
            },
            {
              "VENDOR_NAME": "THE SAFETY RAIL SOURCE"
            },
            {
              "VENDOR_NAME": "THE SAFETY ZONE CORP. HQ"
            },
            {
              "VENDOR_NAME": "THE SALVATION ARMY"
            },
            {
              "VENDOR_NAME": "THE SAN MIGUEL SCHOOL"
            },
            {
              "VENDOR_NAME": "THE SANDPIPER CONDOMINIUMS OF MAYS"
            },
            {
              "VENDOR_NAME": "THE SCHOOL FOR CHILDREN WITH"
            },
            {
              "VENDOR_NAME": "THE SCHULTZ ORGANIZATION"
            },
            {
              "VENDOR_NAME": "THE SENSORY PLAYHOUSE"
            },
            {
              "VENDOR_NAME": "THE SERWIN-WILLIAMS CO"
            },
            {
              "VENDOR_NAME": "THE SHERIFF OF GLOUCESTER CO"
            },
            {
              "VENDOR_NAME": "THE SHERWIN WILLIAMS CO"
            },
            {
              "VENDOR_NAME": "THE SHERWIN WILLIAMS CO."
            },
            {
              "VENDOR_NAME": "THE SHERWIN WILLIAMS COMPANY"
            },
            {
              "VENDOR_NAME": "THE SHERWIN-WILLIAMS CO"
            },
            {
              "VENDOR_NAME": "THE SHERWIN-WILLIAMS CO."
            },
            {
              "VENDOR_NAME": "THE SHERWIN-WILLIAMS COMPANY"
            },
            {
              "VENDOR_NAME": "THE SHIRLEY EVES DEVELOPMENT AND"
            },
            {
              "VENDOR_NAME": "THE SHOOT DOWN CANCER"
            },
            {
              "VENDOR_NAME": "THE SILK & FRESH FLORIST"
            },
            {
              "VENDOR_NAME": "THE SIRINE GROUP"
            },
            {
              "VENDOR_NAME": "THE SNACK FACTORY"
            },
            {
              "VENDOR_NAME": "THE SODEXHO COMPANY"
            },
            {
              "VENDOR_NAME": "THE STAR DEMOCRAT/CHESAPEAKE PUB."
            },
            {
              "VENDOR_NAME": "THE STAR-LEDGER"
            },
            {
              "VENDOR_NAME": "THE STATE CHEMICAL MFG.CO."
            },
            {
              "VENDOR_NAME": "THE STAYWELL COMPANY"
            },
            {
              "VENDOR_NAME": "THE STEPHEN LICZBINSKI FAMILY"
            },
            {
              "VENDOR_NAME": "THE STERLING INN"
            },
            {
              "VENDOR_NAME": "THE TATNALL SCHOOL"
            },
            {
              "VENDOR_NAME": "THE TC EAST COMPANY"
            },
            {
              "VENDOR_NAME": "THE THEME FACTORY OF"
            },
            {
              "VENDOR_NAME": "THE TIMES HERALD"
            },
            {
              "VENDOR_NAME": "THE TIMES OF TRENTON"
            },
            {
              "VENDOR_NAME": "THE TOPPS COMPANY"
            },
            {
              "VENDOR_NAME": "THE TOWNSHIP OF LOWER"
            },
            {
              "VENDOR_NAME": "THE TOWNSHIP OF MEDFORD"
            },
            {
              "VENDOR_NAME": "THE TRAFFIC GROUP"
            },
            {
              "VENDOR_NAME": "THE TRENTONIAN"
            },
            {
              "VENDOR_NAME": "THE TROPHY NUT COMPANY"
            },
            {
              "VENDOR_NAME": "THE TWP OF PENNSVILLE"
            },
            {
              "VENDOR_NAME": "THE TYLER ARBORETUM"
            },
            {
              "VENDOR_NAME": "THE UNIVERSITY OF TENNESSEE"
            },
            {
              "VENDOR_NAME": "THE UPLAND COUNTRY DAY SCHOOL"
            },
            {
              "VENDOR_NAME": "THE UPPER DECK COMPANY"
            },
            {
              "VENDOR_NAME": "THE URIAH GROUP"
            },
            {
              "VENDOR_NAME": "THE VALERIE FUND"
            },
            {
              "VENDOR_NAME": "THE VALLEY TRADER"
            },
            {
              "VENDOR_NAME": "THE VALVOLINE COMPANY"
            },
            {
              "VENDOR_NAME": "THE VILLAGE AT ENGLAND RUN"
            },
            {
              "VENDOR_NAME": "THE VILLANOVA CONFERENCE CENTER"
            },
            {
              "VENDOR_NAME": "THE VIRGINIA HISPANIC CHAMBER FOUND"
            },
            {
              "VENDOR_NAME": "THE VIRGINIA PILOT"
            },
            {
              "VENDOR_NAME": "THE VIRGINIAN PILOT"
            },
            {
              "VENDOR_NAME": "THE WAETZMAN PLANNING GROUP"
            },
            {
              "VENDOR_NAME": "THE WALSH GROUP"
            },
            {
              "VENDOR_NAME": "THE WALTMAN AGENCY"
            },
            {
              "VENDOR_NAME": "THE WASHINGTON POST"
            },
            {
              "VENDOR_NAME": "THE WASHINGTON TIMES"
            },
            {
              "VENDOR_NAME": "THE WASHINGTON TOWNSHIP"
            },
            {
              "VENDOR_NAME": "THE WELLNESS COMMUNITY OF DEL MAR V"
            },
            {
              "VENDOR_NAME": "THE WELLNESS COMMUNITY OF THE"
            },
            {
              "VENDOR_NAME": "THE WILMINGTON MUSIC SCHOOL"
            },
            {
              "VENDOR_NAME": "THE WORNICK COMPANY"
            },
            {
              "VENDOR_NAME": "THE YGS GROUP"
            },
            {
              "VENDOR_NAME": "THELEN REID & PRIEST"
            },
            {
              "VENDOR_NAME": "THEODORE ANDREWS"
            },
            {
              "VENDOR_NAME": "THEODORE MENSAH"
            },
            {
              "VENDOR_NAME": "THEODORE RYAN"
            },
            {
              "VENDOR_NAME": "THEODORE SUPPLEE"
            },
            {
              "VENDOR_NAME": "THEODORE W IOBST II"
            },
            {
              "VENDOR_NAME": "THERE DUPONT"
            },
            {
              "VENDOR_NAME": "THERESA REID"
            },
            {
              "VENDOR_NAME": "THERMMAX SCIENTIFIC PRODUCTS"
            },
            {
              "VENDOR_NAME": "THERMOFORMED PLASTICS"
            },
            {
              "VENDOR_NAME": "THERMOFORMED PLASTICS OF NEW ENGLAN"
            },
            {
              "VENDOR_NAME": "THERMO-KING OF PHILA"
            },
            {
              "VENDOR_NAME": "THETA PHI ALPHA'S"
            },
            {
              "VENDOR_NAME": "THIEBLOT RYAN"
            },
            {
              "VENDOR_NAME": "THIEBLOT RYAN P.A."
            },
            {
              "VENDOR_NAME": "THIEBLOT"
            },
            {
              "VENDOR_NAME": "THIEBOLT"
            },
            {
              "VENDOR_NAME": "THINK SERVICE INC."
            },
            {
              "VENDOR_NAME": "THOMAS & MARY LAUDENSLAGER T/E"
            },
            {
              "VENDOR_NAME": "THOMAS A & MARY K"
            },
            {
              "VENDOR_NAME": "THOMAS A LAUDENSLAGER"
            },
            {
              "VENDOR_NAME": "THOMAS A. DIPIETRO"
            },
            {
              "VENDOR_NAME": "THOMAS AGEMIAN"
            },
            {
              "VENDOR_NAME": "THOMAS AUTO ENT."
            },
            {
              "VENDOR_NAME": "THOMAS BRYDEN"
            },
            {
              "VENDOR_NAME": "THOMAS C. PINTO COURT OFFICER"
            },
            {
              "VENDOR_NAME": "THOMAS C. PINTO OFFICER"
            },
            {
              "VENDOR_NAME": "THOMAS C. PINTO"
            },
            {
              "VENDOR_NAME": "THOMAS CANEVARI"
            },
            {
              "VENDOR_NAME": "THOMAS CAPUZZI"
            },
            {
              "VENDOR_NAME": "THOMAS CATERINA"
            },
            {
              "VENDOR_NAME": "THOMAS CHEVROLET INC"
            },
            {
              "VENDOR_NAME": "THOMAS DiSTEFANO"
            },
            {
              "VENDOR_NAME": "THOMAS FANELLI"
            },
            {
              "VENDOR_NAME": "THOMAS G.SAVOY"
            },
            {
              "VENDOR_NAME": "THOMAS GAYNOR"
            },
            {
              "VENDOR_NAME": "THOMAS' HEADLINE CAFE"
            },
            {
              "VENDOR_NAME": "THOMAS J MILLER"
            },
            {
              "VENDOR_NAME": "THOMAS J. STIGLICH"
            },
            {
              "VENDOR_NAME": "THOMAS JEFFERSON UNIV.HOSPITAL"
            },
            {
              "VENDOR_NAME": "THOMAS KELSO"
            },
            {
              "VENDOR_NAME": "THOMAS L GALLUCCI"
            },
            {
              "VENDOR_NAME": "THOMAS P.COOK"
            },
            {
              "VENDOR_NAME": "THOMAS PAGE"
            },
            {
              "VENDOR_NAME": "THOMAS PINTO"
            },
            {
              "VENDOR_NAME": "THOMAS PINTO COURT OFFICER"
            },
            {
              "VENDOR_NAME": "THOMAS PINTO OFFICER"
            },
            {
              "VENDOR_NAME": "THOMAS PINTO"
            },
            {
              "VENDOR_NAME": "THOMAS PINTO"
            },
            {
              "VENDOR_NAME": "THOMAS R. & LAURA RIDGE"
            },
            {
              "VENDOR_NAME": "THOMAS REDMOND"
            },
            {
              "VENDOR_NAME": "THOMAS SIMMONS"
            },
            {
              "VENDOR_NAME": "THOMAS VOUGHT"
            },
            {
              "VENDOR_NAME": "THOMAS W. STAVOLA"
            },
            {
              "VENDOR_NAME": "THOMAS WELCH"
            },
            {
              "VENDOR_NAME": "THOMPSON FINANCIAL"
            },
            {
              "VENDOR_NAME": "THOMPSON LEARNING DIST.CENTER"
            },
            {
              "VENDOR_NAME": "THOMPSON MAPPING SYSTEMS"
            },
            {
              "VENDOR_NAME": "THOMPSON PUBLISHING GROUP"
            },
            {
              "VENDOR_NAME": "THOMSON"
            },
            {
              "VENDOR_NAME": "THOMSON REUTERS"
            },
            {
              "VENDOR_NAME": "THON DANCE MARATHON 2008"
            },
            {
              "VENDOR_NAME": "THOR SYSTEMS"
            },
            {
              "VENDOR_NAME": "THORNBURG AUTO & TRUCK REPAIR"
            },
            {
              "VENDOR_NAME": "THORNBURY TOWNSHIP"
            },
            {
              "VENDOR_NAME": "THORNBURY TWP DELAWARE COUNTY"
            },
            {
              "VENDOR_NAME": "THORNDALE INVESTMENT GROUP"
            },
            {
              "VENDOR_NAME": "THORNDALE PRESS"
            },
            {
              "VENDOR_NAME": "THREE BRIDGES VOL.FIRE CO."
            },
            {
              "VENDOR_NAME": "THREE FOUR CORPORATION"
            },
            {
              "VENDOR_NAME": "THRIFT INVESTMENT CORPORATION"
            },
            {
              "VENDOR_NAME": "THYME CATERING"
            },
            {
              "VENDOR_NAME": "THYSSEN KRUPP ELEVATOR"
            },
            {
              "VENDOR_NAME": "TIBERSOFT CORP."
            },
            {
              "VENDOR_NAME": "TIDEWATER ATHLETIC CLUB"
            },
            {
              "VENDOR_NAME": "TIDEWATER FINANCE CO"
            },
            {
              "VENDOR_NAME": "TIDEWATER NEWS"
            },
            {
              "VENDOR_NAME": "TIDEWATER NEWSPAPER"
            },
            {
              "VENDOR_NAME": "TIDEWATER TRADING POST"
            },
            {
              "VENDOR_NAME": "TIDEWATER UTILITIES INC"
            },
            {
              "VENDOR_NAME": "TIFFANY & TIFFANY"
            },
            {
              "VENDOR_NAME": "TILGHMAN FUNERAL HOME"
            },
            {
              "VENDOR_NAME": "TIM LYDON"
            },
            {
              "VENDOR_NAME": "TIM MOLESKI"
            },
            {
              "VENDOR_NAME": "TIM O'BRIEN"
            },
            {
              "VENDOR_NAME": "TIM O'HARA"
            },
            {
              "VENDOR_NAME": "TIM TANGHARE"
            },
            {
              "VENDOR_NAME": "TIMBER CREEK SOCCER BOOSTER CLUB"
            },
            {
              "VENDOR_NAME": "TIMBER CREEK WRESTLING"
            },
            {
              "VENDOR_NAME": "TIME RECORDERS UNLIMITED"
            },
            {
              "VENDOR_NAME": "TIMES - VIRGINIA"
            },
            {
              "VENDOR_NAME": "TIMES HERALD PUBLISHING CO."
            },
            {
              "VENDOR_NAME": "TIMES NEWS"
            },
            {
              "VENDOR_NAME": "TIMIKA DICKERSON - CHAMBERS"
            },
            {
              "VENDOR_NAME": "TIMONEY KNOX"
            },
            {
              "VENDOR_NAME": "TIMOTHY ABBOTT"
            },
            {
              "VENDOR_NAME": "TIMOTHY D. MOG"
            },
            {
              "VENDOR_NAME": "TIMOTHY D.MICHEL"
            },
            {
              "VENDOR_NAME": "TIMOTHY IPPOLITO"
            },
            {
              "VENDOR_NAME": "TIMOTHY M. PRIME"
            },
            {
              "VENDOR_NAME": "TIMOTHY M. PRIME"
            },
            {
              "VENDOR_NAME": "TIMOTHY M.KOLMAN & ASSOCIATES"
            },
            {
              "VENDOR_NAME": "TIMOTHY MCDARBY"
            },
            {
              "VENDOR_NAME": "TIMOTHY TANSEY"
            },
            {
              "VENDOR_NAME": "TINA BRYANT"
            },
            {
              "VENDOR_NAME": "TINA TORRANCE"
            },
            {
              "VENDOR_NAME": "TINICUM TOWNSHIP"
            },
            {
              "VENDOR_NAME": "TINICUM TOWNSHIP LOCAL TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "TIRE CENTERS"
            },
            {
              "VENDOR_NAME": "TITAN PUBLISHING CORPORATION"
            },
            {
              "VENDOR_NAME": "TITLE ABSTRACT COMPANY OF PA"
            },
            {
              "VENDOR_NAME": "TITLE ALLIANCE OF CAPE MAY CTY LLC"
            },
            {
              "VENDOR_NAME": "TJD SOLUTIONS"
            },
            {
              "VENDOR_NAME": "TM SHEA PRODUCTS"
            },
            {
              "VENDOR_NAME": "TMT SOFTWARE COMPANY"
            },
            {
              "VENDOR_NAME": "TOBYHANNA TOWNSHIP"
            },
            {
              "VENDOR_NAME": "TODD BORGER"
            },
            {
              "VENDOR_NAME": "TODD J. HETRICK"
            },
            {
              "VENDOR_NAME": "TODD LOMBARDI"
            },
            {
              "VENDOR_NAME": "TODD SOUDERS"
            },
            {
              "VENDOR_NAME": "TOLEDO MUNICIPAL COURT"
            },
            {
              "VENDOR_NAME": "TOLLSON & COMPANY"
            },
            {
              "VENDOR_NAME": "TOM BARRETT DISC JOCKEYS LLC"
            },
            {
              "VENDOR_NAME": "TOM BARRETT DISC JOCKEYS"
            },
            {
              "VENDOR_NAME": "TOM DELUCAS TOWING"
            },
            {
              "VENDOR_NAME": "TOM DOBSON"
            },
            {
              "VENDOR_NAME": "TOM FABIETTI SCHOLARSHIP FOUNDATION"
            },
            {
              "VENDOR_NAME": "TOM GALLUCCI"
            },
            {
              "VENDOR_NAME": "TOM KEARNS"
            },
            {
              "VENDOR_NAME": "TOM MCGINN"
            },
            {
              "VENDOR_NAME": "TOM MILCZARSKI"
            },
            {
              "VENDOR_NAME": "TOM QUINN"
            },
            {
              "VENDOR_NAME": "TOM SHULL"
            },
            {
              "VENDOR_NAME": "TOM V. RANDOLPH"
            },
            {
              "VENDOR_NAME": "TOM V.RANDOLPH"
            },
            {
              "VENDOR_NAME": "TOM WILKERSON AND ASSOC"
            },
            {
              "VENDOR_NAME": "TOMIKE"
            },
            {
              "VENDOR_NAME": "TOMLIN BROS."
            },
            {
              "VENDOR_NAME": "TOMMY DEE'S SUNDOWN"
            },
            {
              "VENDOR_NAME": "TOM'S REPAIR SERVICE"
            },
            {
              "VENDOR_NAME": "TOMS RIVER EAST LITTLE LEAGUE"
            },
            {
              "VENDOR_NAME": "TOMS RIVER MUA"
            },
            {
              "VENDOR_NAME": "TOMS RIVER MUNICIPAL COURT"
            },
            {
              "VENDOR_NAME": "TOMS RIVER SOCCER ASSOCIATION"
            },
            {
              "VENDOR_NAME": "TOMS RIVER TOWNSHIP"
            },
            {
              "VENDOR_NAME": "TOMS RIVER-OCEAN COUNTY CHAMBER OF"
            },
            {
              "VENDOR_NAME": "TONE PRODUCTS INC."
            },
            {
              "VENDOR_NAME": "TONY HIGGINS COURT OFFICER"
            },
            {
              "VENDOR_NAME": "TONY HUGGINS COURT OFFICER"
            },
            {
              "VENDOR_NAME": "TONY HUGGINS"
            },
            {
              "VENDOR_NAME": "TONY HUGGINS"
            },
            {
              "VENDOR_NAME": "TONY HUGGINS"
            },
            {
              "VENDOR_NAME": "TONY'S GENERAL MERCHANDISE"
            },
            {
              "VENDOR_NAME": "TOOTSIE ROLL"
            },
            {
              "VENDOR_NAME": "TOPCO ASSOCIATES"
            },
            {
              "VENDOR_NAME": "TOPCO ASSOCIATES LLC"
            },
            {
              "VENDOR_NAME": "TOPCO ASSOCIATES"
            },
            {
              "VENDOR_NAME": "TOPCO ASSOCIATES"
            },
            {
              "VENDOR_NAME": "TOPP PORTABLE AIR"
            },
            {
              "VENDOR_NAME": "TOPTECH SYSTEMS"
            },
            {
              "VENDOR_NAME": "TORNQUIST GARAGE"
            },
            {
              "VENDOR_NAME": "TORRISSI TRANSPORT"
            },
            {
              "VENDOR_NAME": "TORRISSI TRANSPORT-ACCRUAL"
            },
            {
              "VENDOR_NAME": "TOSCO"
            },
            {
              "VENDOR_NAME": "TOTAL BUILDING MAINTENANCE"
            },
            {
              "VENDOR_NAME": "TOTAL CARE FOR WOMEN"
            },
            {
              "VENDOR_NAME": "TOTAL IMAGING SYSTEMS"
            },
            {
              "VENDOR_NAME": "TOTAL MEDIA"
            },
            {
              "VENDOR_NAME": "TOTAL PETROCHEMICALS"
            },
            {
              "VENDOR_NAME": "TOTAL PETROCHEMICALS USA INC"
            },
            {
              "VENDOR_NAME": "TOTAL RENTAL"
            },
            {
              "VENDOR_NAME": "TOTAL RESTORATION CONTRACTING"
            },
            {
              "VENDOR_NAME": "TOTAL TESTING"
            },
            {
              "VENDOR_NAME": "TOWAMENCIN RETAIL DEVELOP. LLC"
            },
            {
              "VENDOR_NAME": "TOWAMENCIN TOWNSHIP"
            },
            {
              "VENDOR_NAME": "TOWAMENCIN TOWNSHIP WAGE TAX PAYABL"
            },
            {
              "VENDOR_NAME": "TOWAMENCIN TWP SEWER RENTAL ACCT"
            },
            {
              "VENDOR_NAME": "TOWAMENCIN TWP TAX"
            },
            {
              "VENDOR_NAME": "TOWAMENCIN YOUTH ASSOC"
            },
            {
              "VENDOR_NAME": "TOWER ENERGY GROUP"
            },
            {
              "VENDOR_NAME": "TOWERS WATSON PA. INC."
            },
            {
              "VENDOR_NAME": "TOWN OF BEL AIR"
            },
            {
              "VENDOR_NAME": "TOWN OF BETHANY BEACH"
            },
            {
              "VENDOR_NAME": "TOWN OF BRIDGEVILLE"
            },
            {
              "VENDOR_NAME": "TOWN OF DELMAR"
            },
            {
              "VENDOR_NAME": "TOWN OF EASTON"
            },
            {
              "VENDOR_NAME": "TOWN OF ELKTON"
            },
            {
              "VENDOR_NAME": "TOWN OF ELSMERE"
            },
            {
              "VENDOR_NAME": "TOWN OF HAMMONTON"
            },
            {
              "VENDOR_NAME": "TOWN OF LA PLATA"
            },
            {
              "VENDOR_NAME": "TOWN OF LaPLATA"
            },
            {
              "VENDOR_NAME": "TOWN OF MIDDLETOWN"
            },
            {
              "VENDOR_NAME": "TOWN OF MILLSBORO"
            },
            {
              "VENDOR_NAME": "TOWN OF OCEAN CITY"
            },
            {
              "VENDOR_NAME": "TOWN OF PHILLIPSBURG"
            },
            {
              "VENDOR_NAME": "TOWN OF SMYRNA"
            },
            {
              "VENDOR_NAME": "TOWN TOPICS"
            },
            {
              "VENDOR_NAME": "TOWNE PLACE SUITES/MARRIOTT"
            },
            {
              "VENDOR_NAME": "TOWNES SITE ENGINEERING P.C."
            },
            {
              "VENDOR_NAME": "TOWNSHIP EAST BRUNSWICK"
            },
            {
              "VENDOR_NAME": "TOWNSHIP NORTH BRUNSWICK"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF ABERDEEN"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF AMITY"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF ANDOVER"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF BARNEGAT"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF BERKELEY"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF BERKLEY"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF BERLIN"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF BORDENTOWN"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF BRICK"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF BURLINGTON"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF CHERRY HILL"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF CONCORD"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF DELRAN"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF DENNIS"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF DEPTFORD"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF DOVER"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF EAST VINCENT"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF ELK"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF EVESHAM"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF FALLS"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF FALLS AUTHORITY"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF FLORENCE"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF FRANKLIN"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF GALLOWAY"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF GLOUCESTER"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF GREENWICH"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF HADDON"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF HAMILTON"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF HAMPTON"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF HAVERFORD"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF HAZLET"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF HILLSBOROUGH"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF HORSHAM"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF HOWELL"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF JACKSON"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF LAKEWOOD"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF LONG BEACH"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF LOWER"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF MANALAPAN"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF MAPLE SHADE"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF MARPLE"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF MEDFORD"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF MIDDLETOWN"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF MILLSTONE"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF MONORE"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF MONROE"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF MUHLENBERG"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF NEWTOWN"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF NORTH BRUNSWICK"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF OCEAN"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF OCEAN BOARD OF HEALTH"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF OCEAN TAX COL"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF OLD BRIDGE"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF PALMER"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF PARSIPPANY"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF PEMBERTON"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF PENNSVILLE"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF PILESGROVE"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF PLUMSTEAD"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF PLUMSTED"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF POHATCONG"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF RADNOR"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF RADNOR/SEWER RENT"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF RARITAN"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF READINGTON"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF RIDLEY"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF SHAMONG"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF SOUTHAMPTON"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF SPRINGFIELD"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF STAFFORD"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF TILDEN"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF TINICUM"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF TOBYHANNA"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF TOMS RIVER"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF TREDYFFRIN"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF UPPER"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF UPPER PROVIDENCE"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF VOORHEES"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF WARMINSTER"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF WARWICK"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF WASHINGTON"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF WATERFORD"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF WEST HEMPFIELD"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF WESTAMPTON"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF WHITE"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF WILLINGBORO"
            },
            {
              "VENDOR_NAME": "TOWNSHIP OF WINSLOW"
            },
            {
              "VENDOR_NAME": "TOYOTA OF RUNNEMEDE"
            },
            {
              "VENDOR_NAME": "TOYS R US"
            },
            {
              "VENDOR_NAME": "TRACIE VAN HORN"
            },
            {
              "VENDOR_NAME": "TRADE TALK"
            },
            {
              "VENDOR_NAME": "TRADESMEN INTERNATIONAL"
            },
            {
              "VENDOR_NAME": "TRAFFIC AND PARKING CONTROL INC."
            },
            {
              "VENDOR_NAME": "TRAFFIC CONCEPTS"
            },
            {
              "VENDOR_NAME": "TRAFFIC PLAN & DESIGN INC"
            },
            {
              "VENDOR_NAME": "TRAFIGURA AG"
            },
            {
              "VENDOR_NAME": "TRAIMAN CORPORATION"
            },
            {
              "VENDOR_NAME": "TRAINING"
            },
            {
              "VENDOR_NAME": "TRAINOR BOROUGH POLICE DEPT."
            },
            {
              "VENDOR_NAME": "TRANS & EQUIPMENT"
            },
            {
              "VENDOR_NAME": "TRANS MONTAIGNE TERMINALLING"
            },
            {
              "VENDOR_NAME": "TRANSAMERICAN OFFICE FURNITURE INC"
            },
            {
              "VENDOR_NAME": "TRANSAXLE"
            },
            {
              "VENDOR_NAME": "TRANSCENTIVE"
            },
            {
              "VENDOR_NAME": "TRANSMONTAIGNE"
            },
            {
              "VENDOR_NAME": "TRANSPORT TOPICS"
            },
            {
              "VENDOR_NAME": "TRAP ZAP MID ATLANTIC"
            },
            {
              "VENDOR_NAME": "TRAP-ZAP ENVIR SYSTEMS"
            },
            {
              "VENDOR_NAME": "TRAP-ZAP ENVIRO SYSTEMS"
            },
            {
              "VENDOR_NAME": "TRAVEL BOARDS LEASING CO."
            },
            {
              "VENDOR_NAME": "TRAVEL CONSULTANTS INT'L"
            },
            {
              "VENDOR_NAME": "TRAVIS S BORDEN"
            },
            {
              "VENDOR_NAME": "TRAVIS YODER"
            },
            {
              "VENDOR_NAME": "TRAYPAK CORPORATION"
            },
            {
              "VENDOR_NAME": "TREAS OF FREDERICK COUNTY"
            },
            {
              "VENDOR_NAME": "TREAS.MONTGOMERY CTY"
            },
            {
              "VENDOR_NAME": "TREAS.OF CHESTERFIELD COUNTY"
            },
            {
              "VENDOR_NAME": "TREAS.-STATE OF N.J."
            },
            {
              "VENDOR_NAME": "TREASURER CITY OF CHARLOTTESVILLE"
            },
            {
              "VENDOR_NAME": "TREASURER COUNTY OF YORK"
            },
            {
              "VENDOR_NAME": "TREASURER DINWIDDIE CO"
            },
            {
              "VENDOR_NAME": "TREASURER MONTGOMERY CTY"
            },
            {
              "VENDOR_NAME": "TREASURER OF AMELIA CO."
            },
            {
              "VENDOR_NAME": "TREASURER OF CECIL COUNTY"
            },
            {
              "VENDOR_NAME": "TREASURER OF CHESTER COUN"
            },
            {
              "VENDOR_NAME": "TREASURER OF CHESTER COUNTY"
            },
            {
              "VENDOR_NAME": "TREASURER OF CHESTER CTY."
            },
            {
              "VENDOR_NAME": "TREASURER OF CHESTERFIELD CLUNTY"
            },
            {
              "VENDOR_NAME": "TREASURER OF CHESTERFIELD CO"
            },
            {
              "VENDOR_NAME": "TREASURER OF CHESTERFIELD COUNTY"
            },
            {
              "VENDOR_NAME": "TREASURER OF DELAWARE COUNTY"
            },
            {
              "VENDOR_NAME": "TREASURER OF DELAWARE CTY"
            },
            {
              "VENDOR_NAME": "TREASURER OF FREDERICK COUNTY"
            },
            {
              "VENDOR_NAME": "TREASURER OF GLOUCESTER COUNTY"
            },
            {
              "VENDOR_NAME": "TREASURER OF HANOVER CO."
            },
            {
              "VENDOR_NAME": "TREASURER OF ORANGE CO"
            },
            {
              "VENDOR_NAME": "TREASURER OF ORANGE CO."
            },
            {
              "VENDOR_NAME": "TREASURER OF ORANGE COUNTY"
            },
            {
              "VENDOR_NAME": "TREASURER OF POWHATAN"
            },
            {
              "VENDOR_NAME": "TREASURER OF SPOTSYLVANIA"
            },
            {
              "VENDOR_NAME": "TREASURER OF SPOTSYLVANIA COUNTY"
            },
            {
              "VENDOR_NAME": "TREASURER OF VIRGINIA"
            },
            {
              "VENDOR_NAME": "TREASURER OF WESTMORLAND COUNTY"
            },
            {
              "VENDOR_NAME": "TREASURER SPOTSYLVANIA COUNTY"
            },
            {
              "VENDOR_NAME": "TREASURER STATE OF MAINE"
            },
            {
              "VENDOR_NAME": "TREASURER STATE OF N J"
            },
            {
              "VENDOR_NAME": "TREASURER STATE OF NEW JERSEY"
            },
            {
              "VENDOR_NAME": "TREASURER STATE OF NJ"
            },
            {
              "VENDOR_NAME": "TREASURER WESTMORELAND CO"
            },
            {
              "VENDOR_NAME": "TREASURER WESTMORLAND COUNTY"
            },
            {
              "VENDOR_NAME": "TREASURER"
            },
            {
              "VENDOR_NAME": "TREASURER"
            },
            {
              "VENDOR_NAME": "TREASURER"
            },
            {
              "VENDOR_NAME": "TREASURER"
            },
            {
              "VENDOR_NAME": "TREASURER"
            },
            {
              "VENDOR_NAME": "TREASURER"
            },
            {
              "VENDOR_NAME": "TREASURER"
            },
            {
              "VENDOR_NAME": "TREASURER"
            },
            {
              "VENDOR_NAME": "TREASURER"
            },
            {
              "VENDOR_NAME": "TREASURER"
            },
            {
              "VENDOR_NAME": "TREASURER"
            },
            {
              "VENDOR_NAME": "TREASURER"
            },
            {
              "VENDOR_NAME": "TREASURER"
            },
            {
              "VENDOR_NAME": "TREASURER"
            },
            {
              "VENDOR_NAME": "TREASURER"
            },
            {
              "VENDOR_NAME": "TREASURER"
            },
            {
              "VENDOR_NAME": "TREASURER"
            },
            {
              "VENDOR_NAME": "TREASURER"
            },
            {
              "VENDOR_NAME": "TREASURER"
            },
            {
              "VENDOR_NAME": "TREASURER-CHESTERFIELD CTY"
            },
            {
              "VENDOR_NAME": "TREASURER-CO MONTGOMERY"
            },
            {
              "VENDOR_NAME": "TREASURER-COUNTY OF YORK"
            },
            {
              "VENDOR_NAME": "TREASURER-GLOUCESTER COUNTY"
            },
            {
              "VENDOR_NAME": "TREASURER-OXFORD AREA SCHOOL DIST."
            },
            {
              "VENDOR_NAME": "TREASURER'S OFFICE"
            },
            {
              "VENDOR_NAME": "TREASURER-STATE OF N.J."
            },
            {
              "VENDOR_NAME": "TREASURER-STATE OF NEW JERSEY"
            },
            {
              "VENDOR_NAME": "TREDYFFRIN EASTTOWN SCHOOL DIST."
            },
            {
              "VENDOR_NAME": "TRELLEBORG ROAD TAPE"
            },
            {
              "VENDOR_NAME": "TRENDWAY CORP."
            },
            {
              "VENDOR_NAME": "TRENT P. CONELIAS"
            },
            {
              "VENDOR_NAME": "TRENTON BABE RUTH LEAGUE"
            },
            {
              "VENDOR_NAME": "TRENTON CORRUGATED PRODUCTS"
            },
            {
              "VENDOR_NAME": "TRENTON THUNDER"
            },
            {
              "VENDOR_NAME": "TRENTON WATER WORKS"
            },
            {
              "VENDOR_NAME": "TRENTONIAN NEWSPAPER"
            },
            {
              "VENDOR_NAME": "TRI CITIES BEVER"
            },
            {
              "VENDOR_NAME": "TRI COUNTY SIGN SYSTEMS INC"
            },
            {
              "VENDOR_NAME": "TRI GAS & OIL CO."
            },
            {
              "VENDOR_NAME": "TRI GAS & OIL CO."
            },
            {
              "VENDOR_NAME": "TRI GROUP ENTERPRISES"
            },
            {
              "VENDOR_NAME": "TRIANGLE TRUCK SERVICE"
            },
            {
              "VENDOR_NAME": "TRI-CHEM CORPORATION"
            },
            {
              "VENDOR_NAME": "TRICIA SHUPP-ALTEMOSE"
            },
            {
              "VENDOR_NAME": "TRI-CITY PAPER"
            },
            {
              "VENDOR_NAME": "TRI-CITY PRODUCTS"
            },
            {
              "VENDOR_NAME": "TRI-COUNTY COMMUNITY ACTION PARTNER"
            },
            {
              "VENDOR_NAME": "TRICOUNTY NEWS"
            },
            {
              "VENDOR_NAME": "TRI-CURRENT ELECTRIC"
            },
            {
              "VENDOR_NAME": "TRID!UM"
            },
            {
              "VENDOR_NAME": "TRIDENT ENVIRONMENTAL"
            },
            {
              "VENDOR_NAME": "TRIDENT LAND TRANSFER CO."
            },
            {
              "VENDOR_NAME": "TRIDENT"
            },
            {
              "VENDOR_NAME": "TRIGON BLUE CROSS BLUE SHIELD"
            },
            {
              "VENDOR_NAME": "TRILOGY LEASING CO."
            },
            {
              "VENDOR_NAME": "TRILOGY SOLUTIONS"
            },
            {
              "VENDOR_NAME": "TRIM-LOK"
            },
            {
              "VENDOR_NAME": "TRIMM GLASS CO INC"
            },
            {
              "VENDOR_NAME": "TRION GROUP"
            },
            {
              "VENDOR_NAME": "TRION INDUSTRIES"
            },
            {
              "VENDOR_NAME": "TRIPLE R TRUCK PARTS"
            },
            {
              "VENDOR_NAME": "TRIPLE D LLC"
            },
            {
              "VENDOR_NAME": "TRIPLE NET INVESTMENTS XVIII"
            },
            {
              "VENDOR_NAME": "TRISH GALLOTTA"
            },
            {
              "VENDOR_NAME": "TRI-STATE CO-OP"
            },
            {
              "VENDOR_NAME": "TRI-STATE DIAGNOSTICS CORPORATION"
            },
            {
              "VENDOR_NAME": "TRI-STATE FIRE PROTECTION"
            },
            {
              "VENDOR_NAME": "TRI-STATE LIGHT & ENERGY"
            },
            {
              "VENDOR_NAME": "TRITON REGIONAL HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "TROEMNER"
            },
            {
              "VENDOR_NAME": "TROOP 70 CINNAMINSON"
            },
            {
              "VENDOR_NAME": "TROOPER CASTELLANO CHILDREN'S"
            },
            {
              "VENDOR_NAME": "TROY S. TYLER"
            },
            {
              "VENDOR_NAME": "TRUCK TIRE SERVICE CORP."
            },
            {
              "VENDOR_NAME": "TRUE DISTRIBUTION COMPANY"
            },
            {
              "VENDOR_NAME": "TRUMAN ARNOLD COMPANIES"
            },
            {
              "VENDOR_NAME": "TRUST ACCOUNT OF CT. OFF.J.FRANKLIN"
            },
            {
              "VENDOR_NAME": "TRUST ACCOUNT OF L. GADDY"
            },
            {
              "VENDOR_NAME": "TRUST ACCOUNT OF LESHAUN GADDY"
            },
            {
              "VENDOR_NAME": "TRUST ACCT OF COURT OFFICE"
            },
            {
              "VENDOR_NAME": "TRUST ACC'T OF COURT OFFICER"
            },
            {
              "VENDOR_NAME": "TRUST ACCT OF JOHN FRANKLIN"
            },
            {
              "VENDOR_NAME": "TRUST ACC'T OF JOHN FRANKLIN"
            },
            {
              "VENDOR_NAME": "TRUST ACCT OF JOHN MCKERNAN"
            },
            {
              "VENDOR_NAME": "TRUST ACC'T OF JOHN MCKERNAN"
            },
            {
              "VENDOR_NAME": "TRUST ACCT OF LESHAUN GADDY"
            },
            {
              "VENDOR_NAME": "TRUST ACCT OF LESJAUN GADDY"
            },
            {
              "VENDOR_NAME": "TRUST AGREEMENT OF D.H.PRICE for"
            },
            {
              "VENDOR_NAME": "TRUST AGREEMENT OF DONAL H.PRICE fo"
            },
            {
              "VENDOR_NAME": "TRUST AGREEMENT OF DONALD H.PRICE f"
            },
            {
              "VENDOR_NAME": "TRUST f/b/o BENJAMIN FRANKLIN du PO"
            },
            {
              "VENDOR_NAME": "TRUST F/B/O GRACE HAYES HOLCOMB DUP"
            },
            {
              "VENDOR_NAME": "TRUST FBO CHARLOTTE M. DUPONT"
            },
            {
              "VENDOR_NAME": "TRUST FBO CHARLOTTE M.DUPONT ZOLLER"
            },
            {
              "VENDOR_NAME": "TRUST FBO EMILY JANE A. duPONT"
            },
            {
              "VENDOR_NAME": "TRUST FBO GRACE HAYES HOLCOMB du PO"
            },
            {
              "VENDOR_NAME": "TRUST FBO MADELEINE RAVENEL ZOLLER"
            },
            {
              "VENDOR_NAME": "TRUST FBO NICHOLAS R.POWERS duPONT"
            },
            {
              "VENDOR_NAME": "TRUST FBO PHEBE P.R.DUPONT"
            },
            {
              "VENDOR_NAME": "TRUST FBO PIERRE S duPONT VI"
            },
            {
              "VENDOR_NAME": "TRUST FBO PIERRE S. duPONT VI"
            },
            {
              "VENDOR_NAME": "TRUST U/W RICHARD D WOOD"
            },
            {
              "VENDOR_NAME": "TRUSTEE OF THE EST. OF LOUISA FOULK"
            },
            {
              "VENDOR_NAME": "TRUSTEE OF THE UNIVE. OF PENNSYLVAN"
            },
            {
              "VENDOR_NAME": "TRUSTEES OF THE UNIV.OF PA."
            },
            {
              "VENDOR_NAME": "TRUSTEES OF THE UNIVERSITY OF PENN."
            },
            {
              "VENDOR_NAME": "TRUSTWAVE HOLDINGS"
            },
            {
              "VENDOR_NAME": "TRUSTWORTH PROPERTIES LLC"
            },
            {
              "VENDOR_NAME": "TSD ENVIRONOMICS"
            },
            {
              "VENDOR_NAME": "TSYS ACQUIRING SOLUTIONS LLC"
            },
            {
              "VENDOR_NAME": "TTA-RESEARCH & GUIDANCE"
            },
            {
              "VENDOR_NAME": "TTC"
            },
            {
              "VENDOR_NAME": "T-TECH SOLUTIONS"
            },
            {
              "VENDOR_NAME": "TULKOFF FOOD PRODUCT"
            },
            {
              "VENDOR_NAME": "TULL GROUP LLC"
            },
            {
              "VENDOR_NAME": "TUOLUMNE"
            },
            {
              "VENDOR_NAME": "TURBOCHEF TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "TURNING POINT OF LEHIGH VALLEY"
            },
            {
              "VENDOR_NAME": "TURNKEY CONSTRUCTION SERVICES"
            },
            {
              "VENDOR_NAME": "TURTLE & HUGHES"
            },
            {
              "VENDOR_NAME": "TUSCANY HOUSE AT"
            },
            {
              "VENDOR_NAME": "TUSCANY TILE & STONE CO. INC."
            },
            {
              "VENDOR_NAME": "TW CLEANING SERVICES"
            },
            {
              "VENDOR_NAME": "TWIN OAKS TOWING & AUTO SRV.INC."
            },
            {
              "VENDOR_NAME": "TWIN TOWN NEWS"
            },
            {
              "VENDOR_NAME": "TWINZ TOWING AND RECOVERY"
            },
            {
              "VENDOR_NAME": "TWO DOWN"
            },
            {
              "VENDOR_NAME": "TWO JAYS BINGO SUPPLIES"
            },
            {
              "VENDOR_NAME": "TWO RIVERS COUNTRY CLUB"
            },
            {
              "VENDOR_NAME": "TWP OF BRICK-TAX COL"
            },
            {
              "VENDOR_NAME": "TWP OF CHERRY HILL"
            },
            {
              "VENDOR_NAME": "TWP OF EDGEWATER PARK"
            },
            {
              "VENDOR_NAME": "TWP OF EGG HARBOR"
            },
            {
              "VENDOR_NAME": "TWP OF FRANKLIN CLERK O"
            },
            {
              "VENDOR_NAME": "TWP OF GALLOWAY"
            },
            {
              "VENDOR_NAME": "TWP OF MAURICE RIVER"
            },
            {
              "VENDOR_NAME": "TWP OF MIDDLETOWN"
            },
            {
              "VENDOR_NAME": "TWP OF MOORESTOWN"
            },
            {
              "VENDOR_NAME": "TWP OF PENNSAUKEN-TAX COL"
            },
            {
              "VENDOR_NAME": "TWP OF PLUMSTEAD"
            },
            {
              "VENDOR_NAME": "TWP OF SOUTH BRUNSWICK"
            },
            {
              "VENDOR_NAME": "TWP OF SPRING VOLUNTEER"
            },
            {
              "VENDOR_NAME": "TWP OF UPPER CHICHESTER"
            },
            {
              "VENDOR_NAME": "TWP OF WHITPAIN"
            },
            {
              "VENDOR_NAME": "TWP OF WINSLOW"
            },
            {
              "VENDOR_NAME": "TWP- UPPER PROVIDENCE"
            },
            {
              "VENDOR_NAME": "TWP WATER PENNSVILLE"
            },
            {
              "VENDOR_NAME": "TWP. OF PARSIPPANY - TROY HILLS"
            },
            {
              "VENDOR_NAME": "TWP.OF BORDENTWON"
            },
            {
              "VENDOR_NAME": "TWP-MONROE BRD OF HEALTH"
            },
            {
              "VENDOR_NAME": "TWP-UPPER MORELAND"
            },
            {
              "VENDOR_NAME": "TWP-UPPER PROV"
            },
            {
              "VENDOR_NAME": "TX CHILD SUPPORT SDU"
            },
            {
              "VENDOR_NAME": "TYDINGS & ROSENBERG LLP"
            },
            {
              "VENDOR_NAME": "TYLER WILLIAMSON"
            },
            {
              "VENDOR_NAME": "TYSON FOOD"
            },
            {
              "VENDOR_NAME": "TY-STITCH"
            },
            {
              "VENDOR_NAME": "U. S. DEPT OF TREASURY"
            },
            {
              "VENDOR_NAME": "U.S. CUSTOMS AND BORDER PROTECTION"
            },
            {
              "VENDOR_NAME": "U.S. DEPARTMENT OF TREASURY"
            },
            {
              "VENDOR_NAME": "U.S. DOL-OSHA"
            },
            {
              "VENDOR_NAME": "U.S. FAMILY MARTIAL ARTS CENTER"
            },
            {
              "VENDOR_NAME": "U.S. REALTY ADVISORS"
            },
            {
              "VENDOR_NAME": "U.S. SURVEYOR"
            },
            {
              "VENDOR_NAME": "U.S. VENTURE"
            },
            {
              "VENDOR_NAME": "U.S. VENTURE"
            },
            {
              "VENDOR_NAME": "U.S.DEPARTMENT OF EDUCATION"
            },
            {
              "VENDOR_NAME": "U.S.DEPT OF TREASURY"
            },
            {
              "VENDOR_NAME": "U.S.FUND FOR UNICEF"
            },
            {
              "VENDOR_NAME": "U.S.SECURITY ASSOCIATES"
            },
            {
              "VENDOR_NAME": "UCG"
            },
            {
              "VENDOR_NAME": "UCG TECHNOLOGY"
            },
            {
              "VENDOR_NAME": "UCSI DISTRIBUTION"
            },
            {
              "VENDOR_NAME": "UGI CENTRAL PENN GAS"
            },
            {
              "VENDOR_NAME": "UGI CORPORATION"
            },
            {
              "VENDOR_NAME": "UGI UTILITIES"
            },
            {
              "VENDOR_NAME": "U-HAUL INTERNATIONAL"
            },
            {
              "VENDOR_NAME": "ULINE"
            },
            {
              "VENDOR_NAME": "ULINE"
            },
            {
              "VENDOR_NAME": "ULSTER COUNTY SCU"
            },
            {
              "VENDOR_NAME": "ULTRA FLOW IRRIGATION LLC"
            },
            {
              "VENDOR_NAME": "ULTRACON INC."
            },
            {
              "VENDOR_NAME": "UNDERGROUND SERVICES"
            },
            {
              "VENDOR_NAME": "UNDERWOOD MEMORIAL HOSPITAL FOUNDAT"
            },
            {
              "VENDOR_NAME": "UNIFORM CODE COUNCIL"
            },
            {
              "VENDOR_NAME": "UNIFUND CCR PARNERS"
            },
            {
              "VENDOR_NAME": "UNIFUND CCR PARTNERS"
            },
            {
              "VENDOR_NAME": "UNIFUND CCR PARTNERS LLC"
            },
            {
              "VENDOR_NAME": "UNILEVER BESTFOODS"
            },
            {
              "VENDOR_NAME": "UNILEVER BESTFOODS FOODSERVICE"
            },
            {
              "VENDOR_NAME": "UNION BANK AND TRUST"
            },
            {
              "VENDOR_NAME": "UNION FIRE COMPANY NO. 1"
            },
            {
              "VENDOR_NAME": "UNIONVILLE-CHADDSFORD"
            },
            {
              "VENDOR_NAME": "UNISYS CORP."
            },
            {
              "VENDOR_NAME": "UNITED AGAINST MUSCULAR DYSTROPHY"
            },
            {
              "VENDOR_NAME": "UNITED AUDIOVISUAL INC"
            },
            {
              "VENDOR_NAME": "UNITED CAPITAL FUNDING CORP."
            },
            {
              "VENDOR_NAME": "UNITED CEREBRAL PALSY ASSOC."
            },
            {
              "VENDOR_NAME": "UNITED COMMUNICATIONS GROUP"
            },
            {
              "VENDOR_NAME": "UNITED ELEVATOR COMPANY"
            },
            {
              "VENDOR_NAME": "UNITED ENVELOPE HUXLEY CORP."
            },
            {
              "VENDOR_NAME": "UNITED FUELS INTERNATIONAL"
            },
            {
              "VENDOR_NAME": "UNITED INSPECTION AGENCY"
            },
            {
              "VENDOR_NAME": "UNITED PACKAGING SUPPLY CO."
            },
            {
              "VENDOR_NAME": "UNITED PARCEL SERVICE"
            },
            {
              "VENDOR_NAME": "UNITED PROPERTY ASSOCIATES"
            },
            {
              "VENDOR_NAME": "UNITED REFRIGERATION INC"
            },
            {
              "VENDOR_NAME": "UNITED RESEARCH & EDUCATION FOUNDAT"
            },
            {
              "VENDOR_NAME": "UNITED SIGN CO"
            },
            {
              "VENDOR_NAME": "UNITED SIGN COMPANY"
            },
            {
              "VENDOR_NAME": "UNITED SILICONE INC."
            },
            {
              "VENDOR_NAME": "UNITED STATES COAST GUARD"
            },
            {
              "VENDOR_NAME": "UNITED STATES PLASTIC CORP."
            },
            {
              "VENDOR_NAME": "UNITED STATES POSTAL SERV"
            },
            {
              "VENDOR_NAME": "UNITED STATES POSTAL SERVICE"
            },
            {
              "VENDOR_NAME": "UNITED STATES TREASURY"
            },
            {
              "VENDOR_NAME": "UNITED STUDENT AID FUNDS"
            },
            {
              "VENDOR_NAME": "UNITED VAN LINES"
            },
            {
              "VENDOR_NAME": "UNITED WATER BETHEL"
            },
            {
              "VENDOR_NAME": "UNITED WATER DELAWARE"
            },
            {
              "VENDOR_NAME": "UNITED WATER TOMS RIVER"
            },
            {
              "VENDOR_NAME": "UNITED WAY OF HOPEWELL"
            },
            {
              "VENDOR_NAME": "UNITED WAY OF SE DEL CO"
            },
            {
              "VENDOR_NAME": "UNITY COMMUNITY CENTER OF S.J. INC."
            },
            {
              "VENDOR_NAME": "UNIVAR USA"
            },
            {
              "VENDOR_NAME": "UNIVERSAL DISTRIBUTING"
            },
            {
              "VENDOR_NAME": "UNIVERSAL INDUSTRIAL GROUP INC."
            },
            {
              "VENDOR_NAME": "UNIVERSAL INDUSTRIAL GROUP"
            },
            {
              "VENDOR_NAME": "UNIVERSAL SUPPLY CO."
            },
            {
              "VENDOR_NAME": "UNIVERSAL VALVE COMPANY"
            },
            {
              "VENDOR_NAME": "UNIVERSAL WIRELESS ACCESSORIES"
            },
            {
              "VENDOR_NAME": "UNIVERSITY OF DELAWARE"
            },
            {
              "VENDOR_NAME": "UNIVERSITY OF MARYLAND"
            },
            {
              "VENDOR_NAME": "UNIVERSITY OF PA. HOSPITAL"
            },
            {
              "VENDOR_NAME": "UNIVERSITY OF PENNSYLVANIA"
            },
            {
              "VENDOR_NAME": "UNIVERSITY OF PENNSYLVANIA MEDICAL"
            },
            {
              "VENDOR_NAME": "UNIVERSITY OF TEXAS"
            },
            {
              "VENDOR_NAME": "UNIVERSITY OF THE SCIENCES"
            },
            {
              "VENDOR_NAME": "UNIVERSITY SERVICES MRO"
            },
            {
              "VENDOR_NAME": "UNLIMITED RESTORATION"
            },
            {
              "VENDOR_NAME": "UNO FOODS"
            },
            {
              "VENDOR_NAME": "UNUMPROVIDENT CORP."
            },
            {
              "VENDOR_NAME": "UPBEAT"
            },
            {
              "VENDOR_NAME": "UPPER CHESAPEAKE FLEX ONE"
            },
            {
              "VENDOR_NAME": "UPPER CHICHESTER"
            },
            {
              "VENDOR_NAME": "UPPER CHICHESTER TOWNSHIP"
            },
            {
              "VENDOR_NAME": "UPPER DARBY FOOTBALL BOOSTERS"
            },
            {
              "VENDOR_NAME": "UPPER DARBY POLICE DEPARTMENT"
            },
            {
              "VENDOR_NAME": "UPPER DARBY SCHOOL DISTRICT"
            },
            {
              "VENDOR_NAME": "UPPER DARBY TOWNSHIP"
            },
            {
              "VENDOR_NAME": "UPPER DEARDIELD SHOPRITE"
            },
            {
              "VENDOR_NAME": "UPPER DEERFIELD TOWNSHIP"
            },
            {
              "VENDOR_NAME": "UPPER DUBLIN"
            },
            {
              "VENDOR_NAME": "UPPER DUBLIN P.B.A."
            },
            {
              "VENDOR_NAME": "UPPER DUBLIN TOWNSHIP"
            },
            {
              "VENDOR_NAME": "UPPER GWYNEDD LOCAL TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "UPPER GWYNEDD TOWNSHIP"
            },
            {
              "VENDOR_NAME": "UPPER GWYNEDD TOWNSHIP POLICE DEPT."
            },
            {
              "VENDOR_NAME": "UPPER MACUNGIE TOWNSHIP"
            },
            {
              "VENDOR_NAME": "UPPER MERION SEWER REVENUE"
            },
            {
              "VENDOR_NAME": "UPPER MERION TAX"
            },
            {
              "VENDOR_NAME": "UPPER MERION TWP"
            },
            {
              "VENDOR_NAME": "UPPER MORELAND TOWNSHIP"
            },
            {
              "VENDOR_NAME": "UPPER MORELAND TWP"
            },
            {
              "VENDOR_NAME": "UPPER MORELAND WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "UPPER MORELAND-HATBORO SEWER"
            },
            {
              "VENDOR_NAME": "UPPER PERKIOMEN EARNED INCOME TX"
            },
            {
              "VENDOR_NAME": "UPPER PITTSGROVE TOWNSHIP"
            },
            {
              "VENDOR_NAME": "UPPER PROVIDENCE MONTGOMERY CNTY TA"
            },
            {
              "VENDOR_NAME": "UPPER PROVIDENCE TOWNSHIP"
            },
            {
              "VENDOR_NAME": "UPPER PROVIDENCE TWP"
            },
            {
              "VENDOR_NAME": "UPPER TOWNSHIP"
            },
            {
              "VENDOR_NAME": "UPPER TOWNSHIP MUNICIPAL COURT"
            },
            {
              "VENDOR_NAME": "UPPER TOWNSHIP PLANNING AND ZONING"
            },
            {
              "VENDOR_NAME": "UPPER TOWNSHIP RESCUE SQUAD"
            },
            {
              "VENDOR_NAME": "UPPER UWCHLAN TOWNSHIP"
            },
            {
              "VENDOR_NAME": "UPS CUSTOMHOUSE BROKERAGE"
            },
            {
              "VENDOR_NAME": "UPS FREIGHT"
            },
            {
              "VENDOR_NAME": "UPS SUPPLY CHAIN SOLUTIONS"
            },
            {
              "VENDOR_NAME": "UPTOWN BAKERIES"
            },
            {
              "VENDOR_NAME": "UPTOWN BAKERIES-HANDLING FEES"
            },
            {
              "VENDOR_NAME": "URIE & BLANTON"
            },
            {
              "VENDOR_NAME": "URSINUS COLLEGE"
            },
            {
              "VENDOR_NAME": "US DEPARTMENT OF THE TREASURY-FMS"
            },
            {
              "VENDOR_NAME": "US DEPARTMENT OF TREASURY"
            },
            {
              "VENDOR_NAME": "US DEPARTMENT OF TREASURY-FMS"
            },
            {
              "VENDOR_NAME": "US DEPT OF THE TREASURY-FMS"
            },
            {
              "VENDOR_NAME": "US DEPT OF TREASURY"
            },
            {
              "VENDOR_NAME": "US DEPT. OF THE TREASURY - FMS"
            },
            {
              "VENDOR_NAME": "US ENVIROMENTAL INC."
            },
            {
              "VENDOR_NAME": "US FOOD & DAIRY LABORATORIES"
            },
            {
              "VENDOR_NAME": "US FOODSERVICE"
            },
            {
              "VENDOR_NAME": "US FOODSERVICE-PHILA"
            },
            {
              "VENDOR_NAME": "US HEALTHCARE"
            },
            {
              "VENDOR_NAME": "US LABEL"
            },
            {
              "VENDOR_NAME": "US NUTRITION INC."
            },
            {
              "VENDOR_NAME": "US PAVER CONTRACTING"
            },
            {
              "VENDOR_NAME": "US UNIQUE SERVICES LLP"
            },
            {
              "VENDOR_NAME": "USA DISCOUNTERS CREDIT CORP."
            },
            {
              "VENDOR_NAME": "USA FUNDS"
            },
            {
              "VENDOR_NAME": "USA MOBILITY WIRELESS"
            },
            {
              "VENDOR_NAME": "USA OIL REFINING LLC"
            },
            {
              "VENDOR_NAME": "USA POLICE K-9 ASSOC. RG15"
            },
            {
              "VENDOR_NAME": "USA Today"
            },
            {
              "VENDOR_NAME": "USA TODAY - SPECIAL EDITIONS"
            },
            {
              "VENDOR_NAME": "USA TODAY - WASHINGTON"
            },
            {
              "VENDOR_NAME": "USFILTER"
            },
            {
              "VENDOR_NAME": "USSTB INC"
            },
            {
              "VENDOR_NAME": "UTC FIRE & SECURITY - MAStermind"
            },
            {
              "VENDOR_NAME": "UTILI-SAVE"
            },
            {
              "VENDOR_NAME": "UTILITY DEPT"
            },
            {
              "VENDOR_NAME": "UTZ QUALITY FOODS INC"
            },
            {
              "VENDOR_NAME": "UWCHLAN TOWNSHIP"
            },
            {
              "VENDOR_NAME": "UWCHLAN TOWNSHIP COMMUNUTY DAY"
            },
            {
              "VENDOR_NAME": "UWCHLAN TOWNSHIP TAX COLLECTOR"
            },
            {
              "VENDOR_NAME": "UWCHLAN TOWNSHIP WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "V & H MATERIAL HANDLING"
            },
            {
              "VENDOR_NAME": "V & K LIMOUSINE SERVICE"
            },
            {
              "VENDOR_NAME": "V W R INTERNATIONAL"
            },
            {
              "VENDOR_NAME": "V.P.C.G.A."
            },
            {
              "VENDOR_NAME": "VA BEACH GENERAL HOSPITAL"
            },
            {
              "VENDOR_NAME": "VA BEACH GEN'L DISTRICT COURT"
            },
            {
              "VENDOR_NAME": "VA DEPARTMENT OF TAXATION"
            },
            {
              "VENDOR_NAME": "VA DEPT OF TAXATION"
            },
            {
              "VENDOR_NAME": "VA DEPT. OF TAXATION"
            },
            {
              "VENDOR_NAME": "VAC LLLP"
            },
            {
              "VENDOR_NAME": "VAC LTD. PARTNERSHIP"
            },
            {
              "VENDOR_NAME": "VALERIE HUPCHICK"
            },
            {
              "VENDOR_NAME": "VALERO ENERGY CO"
            },
            {
              "VENDOR_NAME": "VALLEY FORGE BENEFIT ADMINISTRATORS"
            },
            {
              "VENDOR_NAME": "VALLEY FORGE MILITARY ACADEMY"
            },
            {
              "VENDOR_NAME": "VALLEY FORGE SEWER AUTHORITY"
            },
            {
              "VENDOR_NAME": "VALLEY RICH DAIRY"
            },
            {
              "VENDOR_NAME": "VALTS ROOFING"
            },
            {
              "VENDOR_NAME": "VAN RU CREDIT CORP."
            },
            {
              "VENDOR_NAME": "VAN RU CREDIT CORPORATION"
            },
            {
              "VENDOR_NAME": "VANASSE HANGEN BRUSTLIN"
            },
            {
              "VENDOR_NAME": "VANDELAY MARKETING"
            },
            {
              "VENDOR_NAME": "VANDEMARK & LYNCH"
            },
            {
              "VENDOR_NAME": "VANE LINE BUNKERING"
            },
            {
              "VENDOR_NAME": "VANN DODGE CHRYSLER LLC"
            },
            {
              "VENDOR_NAME": "VANTIX LOGISTICS"
            },
            {
              "VENDOR_NAME": "VARIETY-THE CHILDREN'S CHARITY"
            },
            {
              "VENDOR_NAME": "VARINA YOUTH SPORTS"
            },
            {
              "VENDOR_NAME": "VASOLI ELECTRIC CO."
            },
            {
              "VENDOR_NAME": "VB ONE LP"
            },
            {
              "VENDOR_NAME": "VCU MASSEY CANCER CENTER"
            },
            {
              "VENDOR_NAME": "VECTOR SECURITY"
            },
            {
              "VENDOR_NAME": "VEDDER PRICE KAUFMAN"
            },
            {
              "VENDOR_NAME": "VELERO MARKETING AND SUPPLY"
            },
            {
              "VENDOR_NAME": "VELOCITA WIRELESS"
            },
            {
              "VENDOR_NAME": "VELOCITI ALLIANCE NORTH AMERICA"
            },
            {
              "VENDOR_NAME": "VENABLE LLP"
            },
            {
              "VENDOR_NAME": "Vendor1 4AltPayee"
            },
            {
              "VENDOR_NAME": "Vendor2 4AltPayee"
            },
            {
              "VENDOR_NAME": "VENTNOR CITY FIRE DEPT"
            },
            {
              "VENDOR_NAME": "VENTNOR CITY WATER DEPT"
            },
            {
              "VENDOR_NAME": "VENTURA FOODS"
            },
            {
              "VENDOR_NAME": "VEOLIA ES TECHNICAL SOLUTIONS"
            },
            {
              "VENDOR_NAME": "VERAMARK TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "VERASUN MARKETING"
            },
            {
              "VENDOR_NAME": "VERIO"
            },
            {
              "VENDOR_NAME": "VERISIGN"
            },
            {
              "VENDOR_NAME": "VERIZON"
            },
            {
              "VENDOR_NAME": "VERIZON BUSINESS"
            },
            {
              "VENDOR_NAME": "VERIZON CABS"
            },
            {
              "VENDOR_NAME": "VERIZON DELAWARE"
            },
            {
              "VENDOR_NAME": "VERIZON LEGAL COMPLIANCE"
            },
            {
              "VENDOR_NAME": "VERIZON MARYLAND"
            },
            {
              "VENDOR_NAME": "VERIZON NETWORK INTEGRATION CORP."
            },
            {
              "VENDOR_NAME": "VERIZON NJ"
            },
            {
              "VENDOR_NAME": "VERIZON NJ INC"
            },
            {
              "VENDOR_NAME": "VERIZON NORTH"
            },
            {
              "VENDOR_NAME": "VERIZON NORTH"
            },
            {
              "VENDOR_NAME": "VERIZON OF VA"
            },
            {
              "VENDOR_NAME": "VERIZON SELECT SERVICES INC"
            },
            {
              "VENDOR_NAME": "VERIZON SOUTH"
            },
            {
              "VENDOR_NAME": "VERIZON SOUTH"
            },
            {
              "VENDOR_NAME": "VERIZON VIRGINIA"
            },
            {
              "VENDOR_NAME": "VERIZON WIRELESS"
            },
            {
              "VENDOR_NAME": "VERIZON-NEW JERSEY"
            },
            {
              "VENDOR_NAME": "VERIZON-PA"
            },
            {
              "VENDOR_NAME": "VERIZON-SOUTHERN NEW JERSEY"
            },
            {
              "VENDOR_NAME": "VERMONT OFFICE OF CHILD SUPP"
            },
            {
              "VENDOR_NAME": "VERMONT OFFICE OF CHILD SUPPORT"
            },
            {
              "VENDOR_NAME": "VERNON C. & SARAH L.WALKER"
            },
            {
              "VENDOR_NAME": "VERNON C.& SARAH L.WALKER"
            },
            {
              "VENDOR_NAME": "VERNON COMPANY"
            },
            {
              "VENDOR_NAME": "VERNON CREEK LANDSCAPING"
            },
            {
              "VENDOR_NAME": "VERNON S TOWNSEND"
            },
            {
              "VENDOR_NAME": "VERONICA E ANDERSON"
            },
            {
              "VENDOR_NAME": "VERSAILLES AT THE RAMADA INN"
            },
            {
              "VENDOR_NAME": "VERSALIFT EAST"
            },
            {
              "VENDOR_NAME": "VERSATEK ENTERPRISES"
            },
            {
              "VENDOR_NAME": "VERTEX INC."
            },
            {
              "VENDOR_NAME": "VESTA PARTNERS"
            },
            {
              "VENDOR_NAME": "VETERANS PARK PLAYGROUND FUND"
            },
            {
              "VENDOR_NAME": "VETERINARY EMERGENCY CTR."
            },
            {
              "VENDOR_NAME": "VHDA"
            },
            {
              "VENDOR_NAME": "VIC MUSSO"
            },
            {
              "VENDOR_NAME": "VICEROY BUILDERS LTD"
            },
            {
              "VENDOR_NAME": "VICKI HICKLE"
            },
            {
              "VENDOR_NAME": "VICKI SCHWARTZ"
            },
            {
              "VENDOR_NAME": "VICKY HARE"
            },
            {
              "VENDOR_NAME": "VICTIM'S FUND"
            },
            {
              "VENDOR_NAME": "VICTOR B. GIBBS"
            },
            {
              "VENDOR_NAME": "VICTOR MUSSO"
            },
            {
              "VENDOR_NAME": "VICTOR VINCOLATO"
            },
            {
              "VENDOR_NAME": "VICTORIA SCIECINSKI"
            },
            {
              "VENDOR_NAME": "VICTORY BEVERAGE COMPANY"
            },
            {
              "VENDOR_NAME": "VICTORY FIRE PROTECTION"
            },
            {
              "VENDOR_NAME": "VIDEO MONITORING SERVICES"
            },
            {
              "VENDOR_NAME": "VIDEO PIPE SERVICES"
            },
            {
              "VENDOR_NAME": "VIDEO TRAINING INC."
            },
            {
              "VENDOR_NAME": "VIDEOJET TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "VIE PARTNERS"
            },
            {
              "VENDOR_NAME": "VIEIRA'S BAKERY"
            },
            {
              "VENDOR_NAME": "VIEIRAs BAKERY"
            },
            {
              "VENDOR_NAME": "VIGILANT"
            },
            {
              "VENDOR_NAME": "VIGNETTE CORPORATION"
            },
            {
              "VENDOR_NAME": "VILLA MARIA ACADEMY"
            },
            {
              "VENDOR_NAME": "VILLA NOVA PROPERTIES"
            },
            {
              "VENDOR_NAME": "VILLAGE CATERERS"
            },
            {
              "VENDOR_NAME": "VILLAGE GREEN CENTRE PTNRSHIP"
            },
            {
              "VENDOR_NAME": "VILLAGE PROFILE"
            },
            {
              "VENDOR_NAME": "VILLANOVA UNIV.CAREER"
            },
            {
              "VENDOR_NAME": "VILLANOVA UNIVERSITY"
            },
            {
              "VENDOR_NAME": "VILLANOVA UNIVERSITY CAREER"
            },
            {
              "VENDOR_NAME": "VILLANOVA UNIVERSITY-DEPT.OF CIVIL"
            },
            {
              "VENDOR_NAME": "VILLAS FIRE COMPANY"
            },
            {
              "VENDOR_NAME": "VILLAS VOLUNTEER FIRE COMPANY"
            },
            {
              "VENDOR_NAME": "VINCE FAZIO"
            },
            {
              "VENDOR_NAME": "VINCENT & SUSAN VOLPI"
            },
            {
              "VENDOR_NAME": "VINCENT ANDERSON"
            },
            {
              "VENDOR_NAME": "VINCENT B.MANCINI"
            },
            {
              "VENDOR_NAME": "VINCENT B.McLAUGHLIN"
            },
            {
              "VENDOR_NAME": "VINCENT GIORDANO"
            },
            {
              "VENDOR_NAME": "VINCENT IACONE"
            },
            {
              "VENDOR_NAME": "VINCENT MORRISON"
            },
            {
              "VENDOR_NAME": "VINCENT OR KATHLEEN MCLAUGHLIN"
            },
            {
              "VENDOR_NAME": "VINCENT P & VERONICA E"
            },
            {
              "VENDOR_NAME": "VINCENT P ANDERSON"
            },
            {
              "VENDOR_NAME": "VINE PLACE II ASSOC."
            },
            {
              "VENDOR_NAME": "VINELAND ENVIROMENTAL LABS."
            },
            {
              "VENDOR_NAME": "VINELAND FIRE COMPANY GOLF"
            },
            {
              "VENDOR_NAME": "VINELAND GLASS CO INC"
            },
            {
              "VENDOR_NAME": "VINELAND MUNICIPAL ELECTRIC UTILITY"
            },
            {
              "VENDOR_NAME": "VINELAND MUNICIPAL UTILITIES"
            },
            {
              "VENDOR_NAME": "VINELAND PATRIOTS"
            },
            {
              "VENDOR_NAME": "VINELAND SOCCER ASSOCIATION"
            },
            {
              "VENDOR_NAME": "VINELAND SYRUP"
            },
            {
              "VENDOR_NAME": "VINMAR OVERSEAS LTD"
            },
            {
              "VENDOR_NAME": "VINMAR OVERSEAS LTD-ACCRUAL"
            },
            {
              "VENDOR_NAME": "VINOD KUMAR VISHWANATH"
            },
            {
              "VENDOR_NAME": "VIRENDRA PATEL"
            },
            {
              "VENDOR_NAME": "VIRGINIA AMERICAN WATER"
            },
            {
              "VENDOR_NAME": "VIRGINIA AQUARIUM"
            },
            {
              "VENDOR_NAME": "VIRGINIA BEACH BEVERAGES"
            },
            {
              "VENDOR_NAME": "VIRGINIA BEACH GEN'L DIST COURT"
            },
            {
              "VENDOR_NAME": "VIRGINIA BEACH LIFEGUARD ASSOCIATIO"
            },
            {
              "VENDOR_NAME": "VIRGINIA BREAST CENTER"
            },
            {
              "VENDOR_NAME": "VIRGINIA BREEZE BASEBALL CLUB"
            },
            {
              "VENDOR_NAME": "VIRGINIA BUILDING SERVICES"
            },
            {
              "VENDOR_NAME": "VIRGINIA COMMONWEALTH UNIVERSITY"
            },
            {
              "VENDOR_NAME": "VIRGINIA COUNTRY MANAGEMENT"
            },
            {
              "VENDOR_NAME": "VIRGINIA COUNTRY MGT"
            },
            {
              "VENDOR_NAME": "VIRGINIA CREDIT UNION"
            },
            {
              "VENDOR_NAME": "VIRGINIA CREDIT UNION"
            },
            {
              "VENDOR_NAME": "VIRGINIA D. TEAFORD"
            },
            {
              "VENDOR_NAME": "VIRGINIA D.O.T."
            },
            {
              "VENDOR_NAME": "VIRGINIA DEPARTMENT OF TAXATION"
            },
            {
              "VENDOR_NAME": "VIRGINIA DEPT OF"
            },
            {
              "VENDOR_NAME": "VIRGINIA DEPT OF MOTOR VEHICLES"
            },
            {
              "VENDOR_NAME": "VIRGINIA DEPT OF TAXATION"
            },
            {
              "VENDOR_NAME": "VIRGINIA DEPT. OF TAXATION"
            },
            {
              "VENDOR_NAME": "VIRGINIA DEPT.OF SOCIAL SERVICES"
            },
            {
              "VENDOR_NAME": "VIRGINIA DEPT.OF TAXATIOM"
            },
            {
              "VENDOR_NAME": "VIRGINIA DEPT.OF TAXATION"
            },
            {
              "VENDOR_NAME": "VIRGINIA DEPT.TAXATION"
            },
            {
              "VENDOR_NAME": "VIRGINIA DISTRIB"
            },
            {
              "VENDOR_NAME": "VIRGINIA ELECTRIC AND POWER"
            },
            {
              "VENDOR_NAME": "VIRGINIA EMPLOYMENT COMM"
            },
            {
              "VENDOR_NAME": "VIRGINIA EMPLOYMENT COMM - ACCRUAL"
            },
            {
              "VENDOR_NAME": "VIRGINIA EXPLORATION"
            },
            {
              "VENDOR_NAME": "VIRGINIA GAZETTE"
            },
            {
              "VENDOR_NAME": "VIRGINIA HARVEY"
            },
            {
              "VENDOR_NAME": "VIRGINIA LEMONS"
            },
            {
              "VENDOR_NAME": "VIRGINIA LOGOS"
            },
            {
              "VENDOR_NAME": "VIRGINIA MERGO"
            },
            {
              "VENDOR_NAME": "VIRGINIA NATURAL GAS"
            },
            {
              "VENDOR_NAME": "VIRGINIA NATURAL GAS"
            },
            {
              "VENDOR_NAME": "VIRGINIA PETROLEUM"
            },
            {
              "VENDOR_NAME": "VIRGINIA PHYSICIANS FOR WOMEN"
            },
            {
              "VENDOR_NAME": "VIRGINIA POWER"
            },
            {
              "VENDOR_NAME": "VIRGINIA SIDS ALLIANCE"
            },
            {
              "VENDOR_NAME": "VIRGINIA WASTE SERVICES"
            },
            {
              "VENDOR_NAME": "VIRGINIANS FOR JERRY KILGORE"
            },
            {
              "VENDOR_NAME": "VIRTUA AT WORK"
            },
            {
              "VENDOR_NAME": "VIRTUA WINGS OF STEEL"
            },
            {
              "VENDOR_NAME": "VISIBLE FILING CONCEPTS"
            },
            {
              "VENDOR_NAME": "VISION POINT"
            },
            {
              "VENDOR_NAME": "VISTA OUTDOOR ADVERTISING"
            },
            {
              "VENDOR_NAME": "VISTA PRINT USA"
            },
            {
              "VENDOR_NAME": "VISTAR CORPORATION"
            },
            {
              "VENDOR_NAME": "VISUAL SOUND"
            },
            {
              "VENDOR_NAME": "VITALI'S RESTAURANT"
            },
            {
              "VENDOR_NAME": "VITOL S.A. INC."
            },
            {
              "VENDOR_NAME": "VITOL SA INC"
            },
            {
              "VENDOR_NAME": "VIVIAN HOUGHTON"
            },
            {
              "VENDOR_NAME": "VJ PASTORIOUS CONSTRUCTION SER"
            },
            {
              "VENDOR_NAME": "VKT ELECTRICAL CONTRACTORS LLC"
            },
            {
              "VENDOR_NAME": "VM WARE"
            },
            {
              "VENDOR_NAME": "VNS PARTNERSHIP"
            },
            {
              "VENDOR_NAME": "VODIS PARTNERS"
            },
            {
              "VENDOR_NAME": "VOICE PRINT INTERNATIONAL"
            },
            {
              "VENDOR_NAME": "VOLLMER ASSOCIATES"
            },
            {
              "VENDOR_NAME": "VOLPI CYNWYD SUNOCO"
            },
            {
              "VENDOR_NAME": "VOLT TECHNICAL RESOURCES"
            },
            {
              "VENDOR_NAME": "VOLVO FINANCE N.A.INC."
            },
            {
              "VENDOR_NAME": "VONTU"
            },
            {
              "VENDOR_NAME": "VOOHREES TOWNSHIP"
            },
            {
              "VENDOR_NAME": "VOORHEES TOWNSHIP"
            },
            {
              "VENDOR_NAME": "VOORHEES TWP DISTRICT 3"
            },
            {
              "VENDOR_NAME": "VOORHEES TWP. DISTRICT #2"
            },
            {
              "VENDOR_NAME": "VOVICI CORPORATION"
            },
            {
              "VENDOR_NAME": "VPI INC."
            },
            {
              "VENDOR_NAME": "W & K REALTY LLC"
            },
            {
              "VENDOR_NAME": "W A ROSSITER & SG ROSSITER CHARIT"
            },
            {
              "VENDOR_NAME": "W B SAUL HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "W BARRY WRIGHT"
            },
            {
              "VENDOR_NAME": "W CHESTER SCHOOL DIST"
            },
            {
              "VENDOR_NAME": "W D PROPERTIES"
            },
            {
              "VENDOR_NAME": "W G MALDEN INC"
            },
            {
              "VENDOR_NAME": "W G ROHRER EDUC TRUST"
            },
            {
              "VENDOR_NAME": "W GARY KAUFMANN"
            },
            {
              "VENDOR_NAME": "W H WHITTLE CO"
            },
            {
              "VENDOR_NAME": "W W GRAINGER INC"
            },
            {
              "VENDOR_NAME": "W. ALLEN ROSSITER"
            },
            {
              "VENDOR_NAME": "W. B. MASON COMPANY"
            },
            {
              "VENDOR_NAME": "W. BAXTER PERKINSON DDS"
            },
            {
              "VENDOR_NAME": "W.B.HULL STREET ASSOC."
            },
            {
              "VENDOR_NAME": "W.B.ROSEMONT ASSOCIATES"
            },
            {
              "VENDOR_NAME": "W.J. RAPP COMPANY"
            },
            {
              "VENDOR_NAME": "W.McLOUGHLIN PAINTING"
            },
            {
              "VENDOR_NAME": "W.P.LARGE"
            },
            {
              "VENDOR_NAME": "W.W.GRAINGER"
            },
            {
              "VENDOR_NAME": "WA HOLDINGS LLC"
            },
            {
              "VENDOR_NAME": "WACHOVIA BANK"
            },
            {
              "VENDOR_NAME": "WACO ASSOCIATES"
            },
            {
              "VENDOR_NAME": "WACO"
            },
            {
              "VENDOR_NAME": "WADE POWELL & ASSOCIATES"
            },
            {
              "VENDOR_NAME": "WAFPA"
            },
            {
              "VENDOR_NAME": "WALDMAN & ASOCIATES"
            },
            {
              "VENDOR_NAME": "WALDMAN & ASSOCIATES"
            },
            {
              "VENDOR_NAME": "WALKERS SHORTBREAD"
            },
            {
              "VENDOR_NAME": "WALKLETT GROUP"
            },
            {
              "VENDOR_NAME": "WALL ST JOURNAL"
            },
            {
              "VENDOR_NAME": "WALL TOWNSHIP WATER DEPT."
            },
            {
              "VENDOR_NAME": "WALLACE J. MICHALS COURT OFFICER"
            },
            {
              "VENDOR_NAME": "WALLACE J. MICHALS OFFICER"
            },
            {
              "VENDOR_NAME": "WALLACE MICHALS COURT OFFICER"
            },
            {
              "VENDOR_NAME": "WALLACE MICHALS OFFICER COURT"
            },
            {
              "VENDOR_NAME": "WALLYPARK"
            },
            {
              "VENDOR_NAME": "WALSH COLUCCI LUBELEY EMRICH WALSH"
            },
            {
              "VENDOR_NAME": "WALT SPICER"
            },
            {
              "VENDOR_NAME": "WALTCO TRUCK EQUIPMENT CO."
            },
            {
              "VENDOR_NAME": "WALTER D.RICHARDSON"
            },
            {
              "VENDOR_NAME": "WALTER F MICHEL"
            },
            {
              "VENDOR_NAME": "WALTER L FOULKE"
            },
            {
              "VENDOR_NAME": "WALTER L. FOULKE"
            },
            {
              "VENDOR_NAME": "WALTER PAYNE"
            },
            {
              "VENDOR_NAME": "WALTER PETERS"
            },
            {
              "VENDOR_NAME": "WALTER RADZISZEWSKI"
            },
            {
              "VENDOR_NAME": "WALTERS SERVICES"
            },
            {
              "VENDOR_NAME": "WANTAGE TOWNSHIP"
            },
            {
              "VENDOR_NAME": "WANTZ DISTRIBUTORS"
            },
            {
              "VENDOR_NAME": "WARD SAND AND MATERIALS"
            },
            {
              "VENDOR_NAME": "WARDS CORNER ASSOCIATES"
            },
            {
              "VENDOR_NAME": "WARD'S TRUCK & AUTO CENTER"
            },
            {
              "VENDOR_NAME": "WAREHOUSE EQUIPMENT & SUPPLY CO."
            },
            {
              "VENDOR_NAME": "WAREHOUSE TECHNOLOGY INC"
            },
            {
              "VENDOR_NAME": "WARETOWN VOLUNTEER FIRE"
            },
            {
              "VENDOR_NAME": "WARMINSTER EARNED INC TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "WARMINSTER MUNICIPAL AUTH"
            },
            {
              "VENDOR_NAME": "WARMINSTER TOWNSHIP"
            },
            {
              "VENDOR_NAME": "WARNER LAMBERT COMPANY"
            },
            {
              "VENDOR_NAME": "WARNER REAL ESTATE &"
            },
            {
              "VENDOR_NAME": "WARREN BRADY EXCAVATING"
            },
            {
              "VENDOR_NAME": "WARREN COUNTY MUA"
            },
            {
              "VENDOR_NAME": "WARREN COUNTY PLANNING BOARD"
            },
            {
              "VENDOR_NAME": "WARRINGTON TOWNSHIP"
            },
            {
              "VENDOR_NAME": "WARRINGTON TOWNSHIP TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "WARWICK LEGACY PROPERTIES"
            },
            {
              "VENDOR_NAME": "WASHINGTON GAS"
            },
            {
              "VENDOR_NAME": "WASHINGTON POST"
            },
            {
              "VENDOR_NAME": "WASHINGTON POST-OUTER MARKET"
            },
            {
              "VENDOR_NAME": "WASHINGTON SPEAKERS BUREAU"
            },
            {
              "VENDOR_NAME": "WASHINGTON STATE CHILD SUPPORT"
            },
            {
              "VENDOR_NAME": "WASHINGTON STATE SUPPORT REG"
            },
            {
              "VENDOR_NAME": "WASHINGTON STATE SUPPORT REGISTRY"
            },
            {
              "VENDOR_NAME": "WASHINGTON TOWNSHIP"
            },
            {
              "VENDOR_NAME": "WASHINGTON TOWNSHIP PARK SERVICE"
            },
            {
              "VENDOR_NAME": "WASHINGTON TWP SOCCER ASSN"
            },
            {
              "VENDOR_NAME": "WASHINGTON TWP. TAX COLLE"
            },
            {
              "VENDOR_NAME": "WASHINGTON TWP.PLANNING BOARD"
            },
            {
              "VENDOR_NAME": "WASPLUNDH LLC"
            },
            {
              "VENDOR_NAME": "WASTE INDUSTRIES USA INC."
            },
            {
              "VENDOR_NAME": "WASTE MANAGEMENT"
            },
            {
              "VENDOR_NAME": "WASTE MANAGEMENT INC."
            },
            {
              "VENDOR_NAME": "WASTE MANAGEMENT OF PA INC"
            },
            {
              "VENDOR_NAME": "WASTE MGMT.OF N.J."
            },
            {
              "VENDOR_NAME": "WASTE SYS.AUTHORITY OF"
            },
            {
              "VENDOR_NAME": "WASTE SYSTEM AUTHORITY OF EASTERN"
            },
            {
              "VENDOR_NAME": "WATCH HILL PARTNERS"
            },
            {
              "VENDOR_NAME": "WATCHUNG SPRING WATER CO"
            },
            {
              "VENDOR_NAME": "WATER DEPOT"
            },
            {
              "VENDOR_NAME": "WATER RESOURCE TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "WATER REVENUE BUREAU"
            },
            {
              "VENDOR_NAME": "WATER TESTING LABS"
            },
            {
              "VENDOR_NAME": "WATER TESTING LABS OF"
            },
            {
              "VENDOR_NAME": "WATERFALL BANQUET"
            },
            {
              "VENDOR_NAME": "WATERFORD TWP. WAR MEMORIAL FUND"
            },
            {
              "VENDOR_NAME": "WATKINS FOR SENATE"
            },
            {
              "VENDOR_NAME": "WATSON WYATT DATA SERVICES"
            },
            {
              "VENDOR_NAME": "WATSON'S PETROLEUM EQUIP.SERV.INC."
            },
            {
              "VENDOR_NAME": "WATTS RESTORATION"
            },
            {
              "VENDOR_NAME": "WAWA BEVERAGE F&R EDI"
            },
            {
              "VENDOR_NAME": "Wawa Corp"
            },
            {
              "VENDOR_NAME": "WAWA CORP. PROCUREMENT TEST VENDOR"
            },
            {
              "VENDOR_NAME": "WAWA DELMARVA REGIONAL GOLF OUTING"
            },
            {
              "VENDOR_NAME": "WAWA ESOP TRUST"
            },
            {
              "VENDOR_NAME": "WAWA INC."
            },
            {
              "VENDOR_NAME": "WAWA INC. POLITICAL ACTION COMMITTE"
            },
            {
              "VENDOR_NAME": "Wawa Penske"
            },
            {
              "VENDOR_NAME": "Wawa"
            },
            {
              "VENDOR_NAME": "WAWA"
            },
            {
              "VENDOR_NAME": "WAYNE & SANDRA MULLINS"
            },
            {
              "VENDOR_NAME": "WAYNE COUNTY SCU"
            },
            {
              "VENDOR_NAME": "WAYNE NEWS SERVICE"
            },
            {
              "VENDOR_NAME": "WAYNE RICHARDSON"
            },
            {
              "VENDOR_NAME": "WAYNE SCARBOROUGH"
            },
            {
              "VENDOR_NAME": "WAYNE STOCKTON-BROWN"
            },
            {
              "VENDOR_NAME": "WAYNE WORTHINGTON"
            },
            {
              "VENDOR_NAME": "WBC"
            },
            {
              "VENDOR_NAME": "WBQB-FM & WFVA-AM"
            },
            {
              "VENDOR_NAME": "WC RESEARCH INC."
            },
            {
              "VENDOR_NAME": "WCR ROONEY LP"
            },
            {
              "VENDOR_NAME": "WE SEAL CONCRETE"
            },
            {
              "VENDOR_NAME": "WEATHER WORKS LLC"
            },
            {
              "VENDOR_NAME": "WEAVER AND TIDWELL"
            },
            {
              "VENDOR_NAME": "WEAVER LANDSCAPING"
            },
            {
              "VENDOR_NAME": "WEBBER/SMITH ASSOC. INC."
            },
            {
              "VENDOR_NAME": "WEBSURVEYOR CORPORATION"
            },
            {
              "VENDOR_NAME": "WEICHERT REALTORS"
            },
            {
              "VENDOR_NAME": "WEICHERT REFERRAL"
            },
            {
              "VENDOR_NAME": "WEICHERT RELOCATION CO."
            },
            {
              "VENDOR_NAME": "WEIGHT WATCHERS OF PHILA"
            },
            {
              "VENDOR_NAME": "WEIGHTS & MEASURES FUND"
            },
            {
              "VENDOR_NAME": "WEINSTEIN INDUSTRIAL"
            },
            {
              "VENDOR_NAME": "WEISFELD & MULLER P.C."
            },
            {
              "VENDOR_NAME": "WELCH'S FOODS"
            },
            {
              "VENDOR_NAME": "WELCOA"
            },
            {
              "VENDOR_NAME": "WELCOME ABOARD"
            },
            {
              "VENDOR_NAME": "WELCOME AMERICA"
            },
            {
              "VENDOR_NAME": "WELL BREAD CORP."
            },
            {
              "VENDOR_NAME": "WELLESLEY INFORMATION SERVICES"
            },
            {
              "VENDOR_NAME": "WELLINGTON APT"
            },
            {
              "VENDOR_NAME": "WELLINGTON AT WESTERB BRANCH"
            },
            {
              "VENDOR_NAME": "WELLINGTON AT WESTERN BANK"
            },
            {
              "VENDOR_NAME": "WELLINGTON AT WESTERN BRANCH"
            },
            {
              "VENDOR_NAME": "WELLS FARGO BANK NORTHWEST"
            },
            {
              "VENDOR_NAME": "WELLS FARGO BANK"
            },
            {
              "VENDOR_NAME": "WELLS FARGO TRUST OPER.-CHOPS"
            },
            {
              "VENDOR_NAME": "WELSH CHESTET GALINEY MATONE INC"
            },
            {
              "VENDOR_NAME": "WENDY L. MARSHALL"
            },
            {
              "VENDOR_NAME": "WENDY L.MARSHALL"
            },
            {
              "VENDOR_NAME": "WENDY MARIE LEWIS"
            },
            {
              "VENDOR_NAME": "WENDY THOMAS"
            },
            {
              "VENDOR_NAME": "WENTWORTH NURSERY INC"
            },
            {
              "VENDOR_NAME": "WERNER BUS LINES"
            },
            {
              "VENDOR_NAME": "WERTZ MOTOR COACHES"
            },
            {
              "VENDOR_NAME": "WEST AMWELL TOWNSHIP"
            },
            {
              "VENDOR_NAME": "WEST ASSET MANAGEMENT"
            },
            {
              "VENDOR_NAME": "WEST CATHOLIC HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "WEST CHESTER BOROUGH"
            },
            {
              "VENDOR_NAME": "WEST CHESTER UNITED METHODIST"
            },
            {
              "VENDOR_NAME": "WEST CHESTER UNIVERSITY"
            },
            {
              "VENDOR_NAME": "WEST CHESTER WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "WEST COAST SPECIALTIES"
            },
            {
              "VENDOR_NAME": "WEST COAST SPECIALTIES & INSTALLATI"
            },
            {
              "VENDOR_NAME": "WEST COMMODORE IND. PARK LLC"
            },
            {
              "VENDOR_NAME": "WEST CONSHOHOCKEN MUNICIPAL AUTHORI"
            },
            {
              "VENDOR_NAME": "WEST CONSHOHOCKEN WAGE TAX"
            },
            {
              "VENDOR_NAME": "WEST CUMBERLAND"
            },
            {
              "VENDOR_NAME": "WEST DEPTFORD HIGH SCHOOL"
            },
            {
              "VENDOR_NAME": "WEST DEPTFORD TOWNSHIP"
            },
            {
              "VENDOR_NAME": "WEST END FIRE CO."
            },
            {
              "VENDOR_NAME": "WEST END ORTHOPAEDIC CLINIC"
            },
            {
              "VENDOR_NAME": "WEST GOSHEN TOWNSHIP"
            },
            {
              "VENDOR_NAME": "WEST GOSHEN TWP"
            },
            {
              "VENDOR_NAME": "WEST GOSHEN WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "WEST MARINE PRODUCTS"
            },
            {
              "VENDOR_NAME": "WEST NOTTINGHAM TWP."
            },
            {
              "VENDOR_NAME": "WEST NOTTINGHAM WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "WEST PAYMENT CENTER"
            },
            {
              "VENDOR_NAME": "WEST PIKELAND LOCAL TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "WEST ROCKHILL TOWNSHIP"
            },
            {
              "VENDOR_NAME": "WEST SADSBURY TOWNSHIP"
            },
            {
              "VENDOR_NAME": "WEST WHITELAND POLICE DEPT."
            },
            {
              "VENDOR_NAME": "WEST WHITELAND TOWNSHIP"
            },
            {
              "VENDOR_NAME": "WEST WHITELAND WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "WESTAFF"
            },
            {
              "VENDOR_NAME": "WESTAMPTON TOWNSHIP"
            },
            {
              "VENDOR_NAME": "WESTERN EXTRALITE COMPANY"
            },
            {
              "VENDOR_NAME": "WESTERN FUMIGATION"
            },
            {
              "VENDOR_NAME": "WESTERN GRAPHICS & DATA"
            },
            {
              "VENDOR_NAME": "WESTERN MONMOUTH UTILITY"
            },
            {
              "VENDOR_NAME": "WESTERN PEST SERVICES"
            },
            {
              "VENDOR_NAME": "WESTERN PEST SERVICES"
            },
            {
              "VENDOR_NAME": "WESTERN PETROLEUM CO."
            },
            {
              "VENDOR_NAME": "WESTERN PETROLEUM CO.-ACCRUAL"
            },
            {
              "VENDOR_NAME": "WESTERN REFINING YORKTOWN"
            },
            {
              "VENDOR_NAME": "WESTERN TERM&PEST CONTROL"
            },
            {
              "VENDOR_NAME": "WESTERN TERMITE & PEST"
            },
            {
              "VENDOR_NAME": "WESTFALIA SEPARATOR"
            },
            {
              "VENDOR_NAME": "WESTFALIA SEPARATOR"
            },
            {
              "VENDOR_NAME": "WESTFALIA TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "WESTGATE VILLAGE APARTMENTS"
            },
            {
              "VENDOR_NAME": "WESTIN - MOUNT LAUREL"
            },
            {
              "VENDOR_NAME": "WESTLAKE PLASTICS COMPANY"
            },
            {
              "VENDOR_NAME": "WESTMINSTER MARKETPLACE OWNER'S"
            },
            {
              "VENDOR_NAME": "WESTMONT EMERGENCY"
            },
            {
              "VENDOR_NAME": "WESTMONT FIRE COMPANY NO. 1"
            },
            {
              "VENDOR_NAME": "WESTPORT PETROLEUM"
            },
            {
              "VENDOR_NAME": "WESTPORT STRATEGIES"
            },
            {
              "VENDOR_NAME": "WESTTOWN CONSULTING"
            },
            {
              "VENDOR_NAME": "WESTTOWN TOWNSHIP"
            },
            {
              "VENDOR_NAME": "WESTTOWN WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "WESTWOOD CENTER"
            },
            {
              "VENDOR_NAME": "WESTWOOD PROPERTIES LLC"
            },
            {
              "VENDOR_NAME": "WHARTON CENTER FOR HUMAN RESOURCES"
            },
            {
              "VENDOR_NAME": "WHEELING POWER & INDUSTRIAL SERVICE"
            },
            {
              "VENDOR_NAME": "WHIRLEY INDUSTRIES"
            },
            {
              "VENDOR_NAME": "WHIRLEY INDUSTRIES INC"
            },
            {
              "VENDOR_NAME": "WHIRLEY INDUSTRIES"
            },
            {
              "VENDOR_NAME": "WHITAKER CONSULTING LTD"
            },
            {
              "VENDOR_NAME": "WHITE HORSE VILLAGE RESIDENTS ASSOC"
            },
            {
              "VENDOR_NAME": "WHITE REALTY ASSOCIATES"
            },
            {
              "VENDOR_NAME": "WHITE TIRE"
            },
            {
              "VENDOR_NAME": "WHITEHALL CONVENIENCE"
            },
            {
              "VENDOR_NAME": "WHITEHALL TOWNSHIP"
            },
            {
              "VENDOR_NAME": "WHITEHALL TWP FIRE DEPARTMENT"
            },
            {
              "VENDOR_NAME": "WHITEHALL TWP FIRE POLICE"
            },
            {
              "VENDOR_NAME": "WHITEMARSH TOWNSHIP"
            },
            {
              "VENDOR_NAME": "WHITEMARSH TOWNSHIP LOCAL TAX PAYAB"
            },
            {
              "VENDOR_NAME": "WHITESTONE ASSOCIATES"
            },
            {
              "VENDOR_NAME": "WHITPAIN TOWNSHIP"
            },
            {
              "VENDOR_NAME": "WHITPAIN WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "WI SCTF"
            },
            {
              "VENDOR_NAME": "WIB ALUMNI AWARDS FUND"
            },
            {
              "VENDOR_NAME": "WICKS KITCHENS"
            },
            {
              "VENDOR_NAME": "WICOMICO COUNTY"
            },
            {
              "VENDOR_NAME": "WICOMICO CTY HEALTH DEPT"
            },
            {
              "VENDOR_NAME": "WIDENER UNIVERSITY"
            },
            {
              "VENDOR_NAME": "WILBER SMITH ASSOCIATES"
            },
            {
              "VENDOR_NAME": "WILBUR CURTIS CO INC"
            },
            {
              "VENDOR_NAME": "WILCO SUPPLY"
            },
            {
              "VENDOR_NAME": "WILD FLAVORS"
            },
            {
              "VENDOR_NAME": "WILD WATERS"
            },
            {
              "VENDOR_NAME": "WILDHAWK ENERGY INC."
            },
            {
              "VENDOR_NAME": "WILDWOOD FMBA LOCAL 50"
            },
            {
              "VENDOR_NAME": "WILDWOOD JUNIOR WARRIORS"
            },
            {
              "VENDOR_NAME": "WILDWOOD LIONS CENTER"
            },
            {
              "VENDOR_NAME": "WILDWOOD MUNICIPAL COURT"
            },
            {
              "VENDOR_NAME": "WILDWOOD TAX OFFICE"
            },
            {
              "VENDOR_NAME": "WILDWOOD VOLUNTEER"
            },
            {
              "VENDOR_NAME": "WILDWOODS CONVENTION CENTER"
            },
            {
              "VENDOR_NAME": "WILENTZ"
            },
            {
              "VENDOR_NAME": "WILKES ARTIS"
            },
            {
              "VENDOR_NAME": "WILKES BARRE WAGE TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "WILLARD BISHOP CONSULTING"
            },
            {
              "VENDOR_NAME": "WILLIAM A.ABELE"
            },
            {
              "VENDOR_NAME": "WILLIAM AND MARY RECREATIONAL SPORT"
            },
            {
              "VENDOR_NAME": "WILLIAM B LAMPERT"
            },
            {
              "VENDOR_NAME": "WILLIAM B. HIPPLE"
            },
            {
              "VENDOR_NAME": "WILLIAM BETZ INC"
            },
            {
              "VENDOR_NAME": "WILLIAM BROWN"
            },
            {
              "VENDOR_NAME": "WILLIAM BURTT"
            },
            {
              "VENDOR_NAME": "WILLIAM C. FELKER"
            },
            {
              "VENDOR_NAME": "WILLIAM C. MILLER TRUSTEE"
            },
            {
              "VENDOR_NAME": "WILLIAM C.MILLER"
            },
            {
              "VENDOR_NAME": "WILLIAM CALEB ROYSTON"
            },
            {
              "VENDOR_NAME": "WILLIAM CARRINGTON"
            },
            {
              "VENDOR_NAME": "WILLIAM D CRANE ASSOC INC"
            },
            {
              "VENDOR_NAME": "WILLIAM DeGUILO"
            },
            {
              "VENDOR_NAME": "WILLIAM DELGUIDICE"
            },
            {
              "VENDOR_NAME": "WILLIAM DEPRINZIO"
            },
            {
              "VENDOR_NAME": "WILLIAM EILER"
            },
            {
              "VENDOR_NAME": "WILLIAM F MEYERS"
            },
            {
              "VENDOR_NAME": "WILLIAM F. HAZARD SR."
            },
            {
              "VENDOR_NAME": "WILLIAM F.BOLGER CENTER"
            },
            {
              "VENDOR_NAME": "WILLIAM FALZINI"
            },
            {
              "VENDOR_NAME": "WILLIAM FALZINI COURT OFFICER"
            },
            {
              "VENDOR_NAME": "WILLIAM FALZINI"
            },
            {
              "VENDOR_NAME": "WILLIAM G. FOULKE"
            },
            {
              "VENDOR_NAME": "WILLIAM H. MILLER JR."
            },
            {
              "VENDOR_NAME": "WILLIAM H.B.HAMILL"
            },
            {
              "VENDOR_NAME": "WILLIAM HEALEY"
            },
            {
              "VENDOR_NAME": "WILLIAM HERRMANN"
            },
            {
              "VENDOR_NAME": "WILLIAM J WILLS"
            },
            {
              "VENDOR_NAME": "WILLIAM KEENAN"
            },
            {
              "VENDOR_NAME": "WILLIAM KELLY"
            },
            {
              "VENDOR_NAME": "WILLIAM L BRUECKMAN"
            },
            {
              "VENDOR_NAME": "WILLIAM L. BELL"
            },
            {
              "VENDOR_NAME": "WILLIAM L.BELL"
            },
            {
              "VENDOR_NAME": "WILLIAM MASCIANTONIO"
            },
            {
              "VENDOR_NAME": "WILLIAM MILLER"
            },
            {
              "VENDOR_NAME": "WILLIAM O'BRIEN"
            },
            {
              "VENDOR_NAME": "WILLIAM OVERTON JR."
            },
            {
              "VENDOR_NAME": "WILLIAM PATTIE"
            },
            {
              "VENDOR_NAME": "WILLIAM R BENJAMIN"
            },
            {
              "VENDOR_NAME": "WILLIAM R STRONG"
            },
            {
              "VENDOR_NAME": "WILLIAM ROBINS"
            },
            {
              "VENDOR_NAME": "WILLIAM ROESSLER"
            },
            {
              "VENDOR_NAME": "WILLIAM ROSSON"
            },
            {
              "VENDOR_NAME": "WILLIAM SMITH"
            },
            {
              "VENDOR_NAME": "WILLIAM STRONG"
            },
            {
              "VENDOR_NAME": "WILLIAM TRIMARCO"
            },
            {
              "VENDOR_NAME": "WILLIAM V P NEWLIN JR"
            },
            {
              "VENDOR_NAME": "WILLIAM VANARTSDALEN"
            },
            {
              "VENDOR_NAME": "WILLIAM W. ZITTLE"
            },
            {
              "VENDOR_NAME": "WILLIAM WOOD DEMPSEY"
            },
            {
              "VENDOR_NAME": "WILLIAM YOST"
            },
            {
              "VENDOR_NAME": "WILLIAMETTE MANAGEMENT ASSOC."
            },
            {
              "VENDOR_NAME": "WILLIAMS F. SANDS"
            },
            {
              "VENDOR_NAME": "WILLIAMS FOR SENATE"
            },
            {
              "VENDOR_NAME": "WILLIAMS GERARD PRODUCTIONS"
            },
            {
              "VENDOR_NAME": "WILLIAMS SCOTSMAN"
            },
            {
              "VENDOR_NAME": "WILLIAMS"
            },
            {
              "VENDOR_NAME": "WILLIAMSBURG ENVIRONMENTAL GRP"
            },
            {
              "VENDOR_NAME": "WILLIAMSBURG/JAMES CITY GEN DIST CT"
            },
            {
              "VENDOR_NAME": "WILLIAMSBURG/JAMES CITY GEN'L"
            },
            {
              "VENDOR_NAME": "WILLIAMSBURG-JAMES GEN'L DIST.CT"
            },
            {
              "VENDOR_NAME": "WILLIAMSON HOSPITALITY"
            },
            {
              "VENDOR_NAME": "WILLIAMSON SCHOOL"
            },
            {
              "VENDOR_NAME": "WILLIE E. WILLIAMS"
            },
            {
              "VENDOR_NAME": "WILLIE KINCAID"
            },
            {
              "VENDOR_NAME": "WILLIE WYMAN"
            },
            {
              "VENDOR_NAME": "WILLIER ELECTRIC MOTOR REPAIR CO."
            },
            {
              "VENDOR_NAME": "WILLINGBORO MUN UTIL AUTH"
            },
            {
              "VENDOR_NAME": "WILLINGBORO PANTHERS"
            },
            {
              "VENDOR_NAME": "WILLINGBORO RECREATION"
            },
            {
              "VENDOR_NAME": "WILLIS CORROON CORP OF PA"
            },
            {
              "VENDOR_NAME": "WILLISTOWN CONSERVATION TRUST"
            },
            {
              "VENDOR_NAME": "WILLISTOWN TOWNSHIP WAGE TAX PAYABL"
            },
            {
              "VENDOR_NAME": "WILLISTOWN UNITED METHODIST CHURCH"
            },
            {
              "VENDOR_NAME": "WILLNER AND ASSOCIATES"
            },
            {
              "VENDOR_NAME": "WILLOW SPRINGS INV GROUP"
            },
            {
              "VENDOR_NAME": "WILMER MARSHALL"
            },
            {
              "VENDOR_NAME": "WILMINGTON COURTYARD MARRIOTT"
            },
            {
              "VENDOR_NAME": "WILMINGTON NEWS JOURNAL"
            },
            {
              "VENDOR_NAME": "WILMINGTON PIANO COMPANY"
            },
            {
              "VENDOR_NAME": "WILMINGTON TRUST CO."
            },
            {
              "VENDOR_NAME": "WILMINGTON TRUST CO."
            },
            {
              "VENDOR_NAME": "WILMINGTON TRUST CO."
            },
            {
              "VENDOR_NAME": "WILMINGTON TRUST CO.TRUSTEE FOR"
            },
            {
              "VENDOR_NAME": "WILMINGTON TRUST"
            },
            {
              "VENDOR_NAME": "WILMINTON TRUST CUSTODIAN FOR"
            },
            {
              "VENDOR_NAME": "WILSON SCHOOL DISTRICT"
            },
            {
              "VENDOR_NAME": "WILSON'S HARDWARE"
            },
            {
              "VENDOR_NAME": "WINCOR NIXDORF"
            },
            {
              "VENDOR_NAME": "WINDHAM PROFESSIONAL INC"
            },
            {
              "VENDOR_NAME": "WINDHAM PROFESSIONAL INC."
            },
            {
              "VENDOR_NAME": "WINDHAM PROFESSIONALS INC"
            },
            {
              "VENDOR_NAME": "WINDHAM PROFESSIONALS INC."
            },
            {
              "VENDOR_NAME": "WINDHAM PROFESSIONALS"
            },
            {
              "VENDOR_NAME": "WINDHAM PROFESSIONALS"
            },
            {
              "VENDOR_NAME": "WINDING SPECIALTIES"
            },
            {
              "VENDOR_NAME": "WINDOVER VILLAS"
            },
            {
              "VENDOR_NAME": "WINDSOR SERVICE"
            },
            {
              "VENDOR_NAME": "WINDSTREAM"
            },
            {
              "VENDOR_NAME": "WINDSTREAM COMMUNICATIONS"
            },
            {
              "VENDOR_NAME": "WINGATE INN BY WYNDHAM"
            },
            {
              "VENDOR_NAME": "WINN CO.INC."
            },
            {
              "VENDOR_NAME": "WINN RESIDENTIAL MANAGEMENT"
            },
            {
              "VENDOR_NAME": "WINN RESIDENTIAL MGT LLC"
            },
            {
              "VENDOR_NAME": "WINN TRANSPORTATION"
            },
            {
              "VENDOR_NAME": "WINNER FORD NEWARK"
            },
            {
              "VENDOR_NAME": "WINSLOW EMS"
            },
            {
              "VENDOR_NAME": "WINSTON INDUSTRIES"
            },
            {
              "VENDOR_NAME": "WINTER EQUIPMENT CORPORATION"
            },
            {
              "VENDOR_NAME": "WIRELESS COMMUNICATION"
            },
            {
              "VENDOR_NAME": "WISCONSIN SCTF"
            },
            {
              "VENDOR_NAME": "WISCTF"
            },
            {
              "VENDOR_NAME": "WISERPRISE LLC"
            },
            {
              "VENDOR_NAME": "WISSAHICKON SPRING WATER"
            },
            {
              "VENDOR_NAME": "WISSAHICKON SPRING WATER"
            },
            {
              "VENDOR_NAME": "WISSAHICKON TAX PAYABLE"
            },
            {
              "VENDOR_NAME": "WJS ENGINEERS"
            },
            {
              "VENDOR_NAME": "WM BOLTHOUSE FARMS INC"
            },
            {
              "VENDOR_NAME": "WM C MILLER STANDING TRUSTEE"
            },
            {
              "VENDOR_NAME": "WM SMITH & GEORGE BARNARD"
            },
            {
              "VENDOR_NAME": "WM. FALZINI CT. OFFICER"
            },
            {
              "VENDOR_NAME": "WM. HENDERSON INC."
            },
            {
              "VENDOR_NAME": "WM. W. FALZINI COURT OFFICER"
            },
            {
              "VENDOR_NAME": "WM.P.McGOVERN"
            },
            {
              "VENDOR_NAME": "WMMR-FM"
            },
            {
              "VENDOR_NAME": "WOEBER MUSTARD MFG CO"
            },
            {
              "VENDOR_NAME": "WOLPOFF & ABRAMSON"
            },
            {
              "VENDOR_NAME": "WOLPOFF & ABRAMSON-DELAWARE"
            },
            {
              "VENDOR_NAME": "WOLPOFF AND ABRAMSON"
            },
            {
              "VENDOR_NAME": "WOMEN AGAINST ABUSE"
            },
            {
              "VENDOR_NAME": "WOMEN GOLFERS GIVE BACK"
            },
            {
              "VENDOR_NAME": "WOMEN'S CRISIS SERVICES"
            },
            {
              "VENDOR_NAME": "WOMEN'S FOOD SERVICE FORUM"
            },
            {
              "VENDOR_NAME": "WOMEN'S REGIONAL BUSINESS COUNCIL"
            },
            {
              "VENDOR_NAME": "WOMEN'S WORKOUT"
            },
            {
              "VENDOR_NAME": "WONDER BREAD NEW JERSEY"
            },
            {
              "VENDOR_NAME": "WONDER RICHMOND"
            },
            {
              "VENDOR_NAME": "WOODBURY CITY MUNICIPAL COURT"
            },
            {
              "VENDOR_NAME": "WOODLAND TOWNSHIP"
            },
            {
              "VENDOR_NAME": "WOODLANE ASSOCIATES"
            },
            {
              "VENDOR_NAME": "WOODLAWN CONSTRUCTION CO."
            },
            {
              "VENDOR_NAME": "WOODROW & ASSOCIATES"
            },
            {
              "VENDOR_NAME": "WOODS SERVICES FOUNDATION"
            },
            {
              "VENDOR_NAME": "WOODSDALE DEVELOPMENT"
            },
            {
              "VENDOR_NAME": "WOODSTOWN SEWERAGE"
            },
            {
              "VENDOR_NAME": "WOOLWICH TOWNSHIP"
            },
            {
              "VENDOR_NAME": "WOOLWICH TOWNSHIP TAX COL"
            },
            {
              "VENDOR_NAME": "WORCESTER COUNTY"
            },
            {
              "VENDOR_NAME": "WORCESTER COUNTY COURT HOUSE"
            },
            {
              "VENDOR_NAME": "WORCESTER COUNTY HEALTH DEPT."
            },
            {
              "VENDOR_NAME": "WORCESTER CTY OFFICE OF"
            },
            {
              "VENDOR_NAME": "WORCESTER TOWNSHIP"
            },
            {
              "VENDOR_NAME": "WORCESTER TOWNSHIP LOCAL TAX PAYABL"
            },
            {
              "VENDOR_NAME": "WORKING MOTHER"
            },
            {
              "VENDOR_NAME": "WORKPLACE INNOVATIONS"
            },
            {
              "VENDOR_NAME": "WORKRITE ERGONOMICS"
            },
            {
              "VENDOR_NAME": "WORKSTREAM USA"
            },
            {
              "VENDOR_NAME": "WORKWELL-PENN MEDICINE AT RADNOR"
            },
            {
              "VENDOR_NAME": "WORLD ASSET PURCHASING"
            },
            {
              "VENDOR_NAME": "WORLD AT WORK"
            },
            {
              "VENDOR_NAME": "WORLD COM"
            },
            {
              "VENDOR_NAME": "WORLD FUEL SERVICE"
            },
            {
              "VENDOR_NAME": "WORLD FUEL SERVICES - ACCRUAL"
            },
            {
              "VENDOR_NAME": "WORLD FUEL SERVICES"
            },
            {
              "VENDOR_NAME": "WORLD MANUFACTURING"
            },
            {
              "VENDOR_NAME": "WORLD MUSIC CORP"
            },
            {
              "VENDOR_NAME": "WORLD VISION'S 30 HOUR FAMINE"
            },
            {
              "VENDOR_NAME": "WORLDWIDE ASSET PURCHASING LLC"
            },
            {
              "VENDOR_NAME": "WPB & DSF"
            },
            {
              "VENDOR_NAME": "WPL"
            },
            {
              "VENDOR_NAME": "WQSR-FM"
            },
            {
              "VENDOR_NAME": "WRIGHSTOWN VOLUNTEER"
            },
            {
              "VENDOR_NAME": "WRIGHT LAND DEVELOPMENT"
            },
            {
              "VENDOR_NAME": "WRIGHTSTOWN PLUMBING & HEATING"
            },
            {
              "VENDOR_NAME": "WRIGLEY SALES COMPANY"
            },
            {
              "VENDOR_NAME": "WS BLANCHARD TRUSTEE"
            },
            {
              "VENDOR_NAME": "WSFS BANK"
            },
            {
              "VENDOR_NAME": "WTHS PROJECT GRADUATION"
            },
            {
              "VENDOR_NAME": "WTYFB"
            },
            {
              "VENDOR_NAME": "WV-PP TOWNE CENTER"
            },
            {
              "VENDOR_NAME": "WYCB-AM"
            },
            {
              "VENDOR_NAME": "WYETH CONSUMER HEALTHCARE"
            },
            {
              "VENDOR_NAME": "WYNDHAM PROFESSIONALS"
            },
            {
              "VENDOR_NAME": "WYNNWOOD INN & STE LYNNHAVEN"
            },
            {
              "VENDOR_NAME": "WYOMISSING AREA SCHOOL DISTRICT"
            },
            {
              "VENDOR_NAME": "WYOMISSING BORO INCOME TAX"
            },
            {
              "VENDOR_NAME": "XATA CORPORATION"
            },
            {
              "VENDOR_NAME": "XEROX CORP"
            },
            {
              "VENDOR_NAME": "XERXES CORPORATION"
            },
            {
              "VENDOR_NAME": "XPEDX"
            },
            {
              "VENDOR_NAME": "XPERT PRINTING SERVICES"
            },
            {
              "VENDOR_NAME": "XRT AMERICA INC."
            },
            {
              "VENDOR_NAME": "YAHOO! HOTJOBS"
            },
            {
              "VENDOR_NAME": "YARD TRUCK SPECIALISTS"
            },
            {
              "VENDOR_NAME": "YARDLEY BOROUGH"
            },
            {
              "VENDOR_NAME": "YASH TECHNOLOGIES"
            },
            {
              "VENDOR_NAME": "YELLOW PAGES"
            },
            {
              "VENDOR_NAME": "YENI C. GOMEZ-VARGAS"
            },
            {
              "VENDOR_NAME": "YMCA"
            },
            {
              "VENDOR_NAME": "YMCA CAMP OCKINICKEN"
            },
            {
              "VENDOR_NAME": "YMCA OF THE BRANDYWINE VALLEY"
            },
            {
              "VENDOR_NAME": "YMCA OF THE UPPER MAIN LINE"
            },
            {
              "VENDOR_NAME": "YMCA SCHOLARSHIP FUND"
            },
            {
              "VENDOR_NAME": "YORK ADAMS TAX BUREAU"
            },
            {
              "VENDOR_NAME": "YORK AREA TAX BUREAU"
            },
            {
              "VENDOR_NAME": "YORK COUNTY"
            },
            {
              "VENDOR_NAME": "YORK COUNTY CLERK OF COURTS"
            },
            {
              "VENDOR_NAME": "YORK COURT"
            },
            {
              "VENDOR_NAME": "YORK LABEL"
            },
            {
              "VENDOR_NAME": "YORK RIVER WETLANDS"
            },
            {
              "VENDOR_NAME": "YORK SERVICE CO."
            },
            {
              "VENDOR_NAME": "YORK WASTE DISPOSAL"
            },
            {
              "VENDOR_NAME": "YOUR CREDIT"
            },
            {
              "VENDOR_NAME": "YOUR CREDIT"
            },
            {
              "VENDOR_NAME": "YOUTH ACHIEVERS' COMMITTEE"
            },
            {
              "VENDOR_NAME": "YOUTH SPORTS DIGEST"
            },
            {
              "VENDOR_NAME": "YPO"
            },
            {
              "VENDOR_NAME": "ZACHARY S.MEYERS"
            },
            {
              "VENDOR_NAME": "ZAFFERA LLC"
            },
            {
              "VENDOR_NAME": "ZAJUR PARTNERSHIP"
            },
            {
              "VENDOR_NAME": "ZAMBOTTI SOFT DRINKS"
            },
            {
              "VENDOR_NAME": "ZANNINO ENGINEERING"
            },
            {
              "VENDOR_NAME": "ZARCA INTERACTIVE"
            },
            {
              "VENDOR_NAME": "ZARZYCKI BUILDERS"
            },
            {
              "VENDOR_NAME": "ZEP MFG CO"
            },
            {
              "VENDOR_NAME": "ZIPP & TANNENBAUM"
            },
            {
              "VENDOR_NAME": "ZITOMER REAL ESTATE"
            },
            {
              "VENDOR_NAME": "ZOMMICK-MCMAHON COMMERCIAL"
            }
          ];
    }
}

