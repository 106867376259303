import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
        <div class="container-fluid navSectionFooter" [ngClass]="siteClassName" style="padding:0px !important;">
            <div nz-row [nzGutter]="[2]">
                <div nz-col [nzXs]="{ span: 6 }" [nzSm]="{ span: 6 }" [nzMd]="{ span: 6 }" [nzLg]="{ span: 6 }" [nzXl]="{ span: 6 }" [nzXXl]="{ span: 6 }">
                    <ul class="list-inline sitelinks m-b-0 text-left ">
                        <li><a href="{{siteSettings[0]?.termServiceUrl}}" target="_blank">Terms of Service</a></li>
                        <li><a href="{{siteSettings[0]?.privacyPolicyUrl}}" target="_blank">Privacy Policy</a></li>
                        <li><a href="{{siteSettings[0]?.contactUsUrl}}" target="_blank">Contact Us</a></li>
                    </ul>
                </div>
                <div nz-col [nzXs]="{ span: 18 }" [nzSm]="{ span: 18 }" [nzMd]="{ span: 18 }" [nzLg]="{ span: 18 }" [nzXl]="{ span: 18 }" [nzXXl]="{ span: 18 }">
                    <ul class="list-inline m-b-0 text-right">
                        <li><a href="{{siteSettings[0]?.poweredByUrl}}" target="_blank">© {{siteSettings[0]?.copyRightYear}} - {{siteSettings[0]?.copyRightCompanyName}} All rights reserved</a></li>
                        <li><a href="{{siteSettings[0]?.poweredByUrl}}" target="_blank">Powered by {{siteSettings[0]?.poweredByName}}</a></li>
                    </ul>
                </div>
            </div>
        </div>
    `,
    styles: [`
        .navSectionFooter{position:absolute;bottom:0;left:0;right:0;width:100%;z-index:3;}
        .navSectionFooter .list-inline {width:100%;float: right;padding: 7px 7px 7px 0px;}
        .navSectionFooter ul.list-inline li a{font-weight:400;padding:0px 5px !important;cursor:pointer;font-size:13px;}
        .navSectionFooter ul.list-inline li{border-right:1px solid #fff;}
        .navSectionFooter ul.list-inline li:last-child{border-right:0px solid #fff;}
        .navSectionFooter ul.list-inline.sitelinks li a:hover{color:#2196f3;}

        .navSectionFooter.grcarapp ul.list-inline li a, 
        .navSectionFooter.compliancecart ul.list-inline li a{color:#ffffff}
        .navSectionFooter.hourstock ul.list-inline li a{color:#000000}

        .navSectionFooter.grcarapp ul.list-inline li, 
        .navSectionFooter.compliancecart ul.list-inline li{border-right-color: #ffffff;}
        .navSectionFooter.hourstock ul.list-inline li{border-right-color: #000000;}

        .navSectionFooter.grcarapp ul.list-inline li:last-child, 
        .navSectionFooter.compliancecart ul.list-inline li:last-child{border-right-color: #ffffff;}
        .navSectionFooter.hourstock ul.list-inline li:last-child{border-right-color: #000000;}
    `]
})
    
    
export class FooterComponent implements OnInit {

    @Input() siteSettings:any;
    public siteClassName: string;

    constructor() { }

    ngOnInit() {
        
    }

    ngOnChanges(changes: SimpleChanges) { 
        if (this.siteSettings != undefined) {
            if (this.siteSettings.length > 0) {
                this.siteClassName = this.siteSettings[0]?.domainName.split('.')[0];
            }
        }
    }

}