import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-carousel-slide',
    template: `
        <nz-carousel *ngIf="slides.length > 0" [nzEffect]="'fade'" [nzDots]="false" [nzAutoPlay]="true" [nzAutoPlaySpeed]="7000">
            <div nz-carousel-content  
                *ngFor="let slide of slides"
                style="width:100%; height:100vh; background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url('{{slide?.img}}');">
            </div>
        </nz-carousel>
        <div *ngIf="slides.length < 0" style="background-color:#828282;width:100%; height:100vh; "></div>
    `,
    styles: [``]
})
    
    
export class CarouselSlideComponent implements OnInit {

    @Input() slides:any;
    
    constructor() { }

    ngOnInit() {

    }

}