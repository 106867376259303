import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as CryptoJS from 'crypto-js';


@Injectable({
  providedIn: 'root'
})

export class CommonService {

  //Objects
  private type: string;
  private url: string;
  private port: string;
  private Environment: string;
  private rootPackage: string;
  private companyId: number;
  private roleId: number;
  private baseURL: string;

  constructor(
    private httpClient: HttpClient
  ) { 
    if (environment.ENVIRONMENT.title === 'dev' || environment.ENVIRONMENT.title === 'qa') {
      this.baseURL = `https://${environment.ENVIRONMENT.title}.groupsoftapps.com/api/`;
    } else if(environment.ENVIRONMENT.title === 'prod') {
      this.baseURL = `https://groupsoftapps.com/api/`;
    }
  }

  /**************************************** NODEJS API ********************************************* */

  // Common Get Method
  public get_HttpRequest(url: any) {
    this.onloadData();
    let get_HttpRequest = {
      "environment": this.Environment,
      "companyId": this.companyId,
      "roleId": this.roleId,
      "reqUrl": this.type + '://' + this.url + (this.port != '' ? ':' : '') + this.port +'/'+ this.rootPackage +'/'+ url
    };
    return this.httpClient.post(this.baseURL + 'sapApi/get-sap', get_HttpRequest);
  }

  // Common Get Method
  public getM_HttpRequest(url: any) {
    this.onloadData();
    let get_HttpRequest = {
      "environment": this.Environment,
      "companyId": this.companyId,
      "roleId": this.roleId,
      "reqUrl": this.type + '://' + this.url + (this.port != '' ? ':' : '') + this.port +'/'+ this.rootPackage +'/'+ url
    };
    return this.httpClient.post(this.baseURL + 'sapApi/get-sap', get_HttpRequest);
  }


  // Common Post Record Method
  public post_HttpRequest(url: string, data: any) {
    this.onloadData();
    let post_HttpRequest = {
      "environment": this.Environment,
      "companyId": this.companyId,
      "roleId": this.roleId,
      "reqUrl": this.type + '://' + this.url + (this.port != '' ? ':' : '') + this.port +'/'+ this.rootPackage +'/'+ url,
      "data": data
    };
    return this.httpClient.post(this.baseURL + 'sapApi/post-sap', post_HttpRequest);
  }

  // Common Post Record Method
  public postM_HttpRequest(url: string, data: any) {
    this.onloadData();
    let post_HttpRequest = {
      "environment": this.Environment,
      "companyId": this.companyId,
      "roleId": this.roleId,
      "reqUrl": this.type + '://' + this.url + (this.port != '' ? ':' : '') + this.port +'/'+ this.rootPackage +'/'+ url,
      "data": data
    };
    return this.httpClient.post(this.baseURL + 'sapApi/post-sap', post_HttpRequest);
  }

  public put_HttpRequest(url: string, data: any) {
    this.onloadData();
    let put_HttpRequest = {
      "environment": this.Environment,
      "companyId": this.companyId,
      "roleId": this.roleId,
      "reqUrl": this.type + '://' + this.url + (this.port != '' ? ':' : '') + this.port +'/'+ this.rootPackage +'/'+ url,
      "data": data
    };
    return this.httpClient.put(this.baseURL + 'sapApi/put-sap', put_HttpRequest, this.createAuthorizationHeader());
  }

  // Common Delete Record Method
  public delete_HttpRequest(url: string, data: any) {
    this.onloadData();
    let delete_HttpRequest = {
      "environment": this.Environment,
      "companyId": this.companyId,
      "roleId": this.roleId,
      "reqUrl": this.type + '://' + this.url + (this.port != '' ? ':' : '') + this.port +'/'+ this.rootPackage +'/'+ url,
      "data": data
    };
    return this.httpClient.post(this.baseURL + 'sapApi/delete-sap', delete_HttpRequest);
  }



  /**************************************** NODEJS API ********************************************* */

  // IP Address GET Method
  public getIPAdressRequest(url: any) {
    return this.httpClient.get(url);
  }

  // Login Get Method with token
  public getHttpReqNodeapi_login(url: any) {
    return this.httpClient.post(this.baseURL + url, '');
  }

  // Logs Method with token
  public postHttpReqNodeapi_Logs(url: any, data: any) {
    return this.httpClient.post(this.baseURL + url, data);
  }

  // Get Method with token
  public getHttpReqNodeapi_withoutToken(url: any) {
    return this.httpClient.get(this.baseURL + url);
  }

  // Get Method
  public getHttpReqNodeapi(url: any) {
    return this.httpClient.get(this.baseURL + url, this.createAuthorizationHeader());
  }

  // Get single Method
  public getsingleHttpReqNodeapi(url: any, id: any) {
    return this.httpClient.get(this.baseURL + url + id, this.createAuthorizationHeader());
  }

  // Post Method
  public postHttpReqNodeapi(url: any, data: any) {
    return this.httpClient.post(this.baseURL + url, data, this.createAuthorizationHeader());
  }

  // Post Img Method
  public postImgHttpReqNodeapi(url: any, data: any) {
    return this.httpClient.post(this.baseURL + url, data, this.createAuthorizationHeaderWithoutPB());
  }

  // Post Ticket Img Method
  public postTicketImgHttpReqNodeapi(url: any, data: any) {
    return this.httpClient.post(this.baseURL + url, data, this.createAuthorizationHeaderWithoutPB()).toPromise();
  }

  // Put Method with Id and Data
  public putHttpReqNodeapi(url: any, id: any, data: any) {
    return this.httpClient.put(this.baseURL + url + id, data, this.createAuthorizationHeader());
  }

  // Put Method without Id
  public putHttpReqNodeapi_Noid(url: any, data: any) {
    return this.httpClient.put(this.baseURL + url, data, this.createAuthorizationHeader());
  }

  // Put Method for only ticket
  public putHttpReqNodeapi_ticket(url?: any, data?: any) {
    return this.httpClient.put(this.baseURL + url, data, this.createAuthorizationHeader());
  }

  /********************************* Call Https get without Progressbar ******************************************* */

  // Get Method
  public getHttpReqNodeapipb(url: any) {
    return this.httpClient.get(this.baseURL + url, this.createAuthorizationHeaderWithoutPB());
  }

  /********************************* Token Header ******************************************* */

  private createAuthorizationHeader() {
    /** Decryption of UserData */
    if (localStorage.getItem('authToken') !== null) {
      const bytes = CryptoJS.AES.decrypt(localStorage.getItem('authToken'), 'authToken');
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': bytes.toString(CryptoJS.enc.Utf8)
        })
      };
      return httpOptions;
    }
  }

  private createAuthorizationHeaderWithoutPB() {
    /** Decryption of UserData */
    if (localStorage.getItem('authToken') !== null) {
      const bytes = CryptoJS.AES.decrypt(localStorage.getItem('authToken'), 'authToken');
      const httpOptions = {
        headers: new HttpHeaders({
          'ignoreLoadingBar': '',
          'Content-Type': 'application/json',
          'token': bytes.toString(CryptoJS.enc.Utf8)
        })
      };
      return httpOptions;
    }
  }

  public getAPI_Token() {
    if (localStorage.getItem('authToken') !== null) {
      const bytes = CryptoJS.AES.decrypt(localStorage.getItem('authToken'), 'authToken');      
      return { 'url': this.baseURL, 'token': null };
      // return { 'url': this.baseURL, 'token': bytes.toString(CryptoJS.enc.Utf8) };
    } else {
      return { 'url': this.baseURL, 'token': null };
    }
  }

  private onloadData() {
    this.companyId = JSON.parse(this.getLoginData())[0].companyId;
    this.roleId = JSON.parse(this.getLoginData())[0].roleId;
    this.type = JSON.parse(this.getLoginData())[0]['connection'][0].type;
    this.url = JSON.parse(this.getLoginData())[0]['connection'][0].url;
    this.port = JSON.parse(this.getLoginData())[0]['connection'][0].port;
    this.Environment = JSON.parse(this.getLoginData())[0]['connection'][0].environment;
    this.rootPackage = JSON.parse(this.getLoginData())[0]['connection'][0].serviceUrl;
  }

  // To Clear TextBox/InputBox Fields
  public clearInputFields(e) {
    let all = e.target.querySelectorAll('input');
    Object.keys(all).forEach(key => {
      console.log(all[key].value = '');
    });
  }

  getLoginData() {
    return JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('loginData'), 'loginData').toString(CryptoJS.enc.Utf8));
  }

}











// Unwanted Code Reference
// 'Authentication': 'Basic–HANA'+ btoa(this.user + ':' + this.pass),
//return this.httpClient.get('https://us2211zbu1027:4340/HanaDevelopments/VMPlanning/db/services/xsjs/ModelType.xsjs', httpOptions);


