<ngx-loading-bar></ngx-loading-bar>

<div *ngIf="loadingSite" class="h-100 w-100">
    <div class="d-flex align-content-stretch" style="justify-content: center;align-items:center;height:100vh;" >
        <div class="spinner-border" role="status" >
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>

<!--Start Main Screen-->
<nz-layout *ngIf="!loadingSite" class="app-layout">

    <nz-header>
        <div nz-row>
            <div nz-col nzXs="2" nzSm="4" nzMd="6" nzLg="8" nzXl="10" class="header-left">
                <div class="header-groupsoft ">
                    <span *ngIf="siteSettings[0]?.domainName === 'grcarapp.com'" class="header-trigger pointer">
                        <svg nz-tooltip nzTooltipTitle="Switch Applications" [(nzVisible)]="isVisible" nz-dropdown
                            nzTrigger="click" [nzDropdownMenu]="applicationMenu" width="30" height="30" viewBox="0 0 30 30"
                            role="presentation">
                            <path fill="currentColor" fill-rule="evenodd"
                                d="M4 5.01C4 4.451 4.443 4 5.01 4h1.98C7.549 4 8 4.443 8 5.01v1.98C8 7.549 7.557 8 6.99 8H5.01C4.451 8 4 7.557 4 6.99V5.01zm0 6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98C8 13.549 7.557 14 6.99 14H5.01C4.451 14 4 13.557 4 12.99v-1.98zm6-6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98C14 7.549 13.557 8 12.99 8h-1.98C10.451 8 10 7.557 10 6.99V5.01zm0 6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98c0 .558-.443 1.01-1.01 1.01h-1.98c-.558 0-1.01-.443-1.01-1.01v-1.98zm6-6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98C20 7.549 19.557 8 18.99 8h-1.98C16.451 8 16 7.557 16 6.99V5.01zm0 6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98c0 .558-.443 1.01-1.01 1.01h-1.98c-.558 0-1.01-.443-1.01-1.01v-1.98zm-12 6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98C8 19.549 7.557 20 6.99 20H5.01C4.451 20 4 19.557 4 18.99v-1.98zm6 0c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98c0 .558-.443 1.01-1.01 1.01h-1.98c-.558 0-1.01-.443-1.01-1.01v-1.98zm6 0c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98c0 .558-.443 1.01-1.01 1.01h-1.98c-.558 0-1.01-.443-1.01-1.01v-1.98z">
                            </path>
                        </svg>
                        <nz-dropdown-menu #applicationMenu="nzDropdownMenu">
                            <nz-card [nzBordered]="true" class="applications-dropdown">
                                <div [hidden]="switchSetupMenu">
                                    <div class="applications-dropdown-header">
                                        <h5 class="m-b-0">Switch to</h5>
                                    </div>
                                    <nz-list [nzBordered]="false" nzSize="large" class="pointer">    
                                        <nz-list-item *ngFor="let m of Menulist" title="{{m.applicationName}}"
                                            [ngClass]="{'active': selectedItem === m.router}"
                                            [hidden]="showTrialDays(m.expirydaysCount) === 0 || m.subscription === 0 || m.applicationName === 'Business Partner'"
                                            (click)="mainMenuClick($event, m.router);" routerLink="/{{m.router === 'assortment-performance' ? 'assortment-performance-sf':m.router}}">
                                            <img src="./assets/app-icon/{{m.router}}.svg" alt="" />
                                            {{m.applicationName}}
                                        </nz-list-item>
                                        <nz-list-item (click)="switchSetupMenu = true">
                                            <img src="./assets/app-icon/setup.svg" alt="" />  Setup
                                        </nz-list-item>
                                    </nz-list>
                                </div>

                                <div [hidden]="!switchSetupMenu">
                                    <div class="applications-dropdown-header" (click)="switchSetupMenu = false">
                                        <h5 class="m-b-0 back-header"><i nz-icon nzType="arrow-left"></i> Back to
                                            Applications
                                        </h5>
                                    </div>
                                    <nz-list [nzBordered]="false" nzSize="small" class="pointer">
                                        <nz-list-item routerLink="/modeltype" [state]="{ ignoreLoadingBar: true }"
                                            [ngClass]="{'active': selectedItem === 'modeltype' }"
                                            (click)="mainMenuClick($event,'modeltype');">
                                            <i nz-icon nzType="setting"></i> Model Type
                                        </nz-list-item>
                                        <nz-list-item routerLink="/markdown-strategy" [state]="{ ignoreLoadingBar: true }"
                                            [ngClass]="{'active': selectedItem === 'markdown-strategy' }"
                                            (click)="mainMenuClick($event, 'markdown-strategy');">
                                            <i nz-icon nzType="setting"></i> Markdown Strategy
                                        </nz-list-item>
                                    </nz-list>
                                </div>
                            </nz-card>
                        </nz-dropdown-menu>
                    </span>
                    <span *ngIf="siteSettings[0]?.domainName === 'grcarapp.com'" routerLink="/dashboard" class="smallLetter pointer"><span class="boldLetter">DM</span><span class="capLetter"> Platform</span></span>
                    <!-- <span *ngIf="siteSettings[0]?.domainName === 'grcarapp.com'" routerLink="/dashboard" class="smallLetter pointer"><span class="boldLetter">gr</span><span class="capLetter">CAR</span>app</span> -->
                    <span *ngIf="siteSettings[0]?.domainName === 'compliancecart.com' && siteSettings[0].siteLogoSmall != null" routerLink="/dashboard" class="pointer">
                        <img src="https://grcarapp-email-images-s3bucket.s3.amazonaws.com/{{siteSettings[0].siteLogoSmall}}" class="applicationlogo" style="margin-left:-10px;">
                    </span>
                    <span *ngIf="siteSettings[0]?.domainName === 'hourstock.com' && siteSettings[0].siteLogoSmall != null" routerLink="/dashboard" class="pointer">
                        <img src="https://grcarapp-email-images-s3bucket.s3.amazonaws.com/Hourstock_logo_cropped.png" class="applicationlogo" class="m-t-2" style="width:150px !important;" />
                    </span>
                    <span *ngIf="siteSettings[0]?.domainName === 'grcarapp.com' && partnerCompany.length > 0" class="header-component text-uppercase"> {{selectedCompany}} </span>
                    <span *ngIf="siteSettings[0]?.domainName === 'grcarapp.com'" class="header-component"> {{title_1}}{{title_2}} </span>
                </div>
            </div>
            <div nz-col nzXs="20" nzSm="16" nzMd="12" nzLg="8" nzXl="4" class="header-center"></div>
            <div nz-col nzXs="2" nzSm="4" nzMd="6" nzLg="8" nzXl="10" class="header-right">
                <div class="header-setting">

                    <!-- <app-request-access ></app-request-access> -->

                    <nz-select *ngIf="userdetails.partnerCompany.length > 0 && siteSettings[0]?.domainName === 'grcarapp.com'" nzShowSearch nzPlaceHolder="Switch Company" style="margin-right:10px;width: 200px;" [(ngModel)]="selectedCompany" (ngModelChange)="switchCompany()">
                        <nz-option [nzLabel]="userdetails.companyName" [nzValue]="userdetails.siteURL"></nz-option>
                        <nz-option *ngFor="let option of userdetails.partnerCompany" nzCustomContent [nzLabel]="option.partnerCompanyName" [nzValue]="option.partnerCompanySiteUrl">
                            <h6 class="m-b-0 font-14 font-500 text-capitalize">{{option.partnerCompanyName}}</h6>
                            <small class="font-12 font-400">{{option.partnerCompanySiteUrl === 'walmart' ? 'abc':option.partnerCompanySiteUrl}}</small>
                        </nz-option>
                    </nz-select>
                    <nz-select *ngIf="userdetails.partnerCompany.length <= 0 && userdetails.siteURL === 'wawa' && siteSettings[0]?.domainName === 'grcarapp.com'" nzShowSearch style="margin-right:10px;width:250px;" nzPlaceHolder="Search Vendor" >
                        <nz-option *ngFor="let option of vendorList" [nzLabel]="option.VENDOR_NAME" [nzValue]="option.VENDOR_NAME"></nz-option>
                    </nz-select>

                    <app-app-notification class="m-r-5" [companyId]="userdetails.companyId" [userId]="userdetails.id"></app-app-notification>
                    
                    <div class="d-flex m-r-5">
                        <img *ngIf="customerLogo" routerLink="/dashboard" src="{{customerLogo}}" class="img-logo pointer" />
                    </div>

                    <div class="profile_info d-flex pointer" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
                        <div class="author_name m-r-10"  > 
                            <h4 class="m-b-0 font-15 font-600">{{userdetails.title}}</h4>
                            <!-- <p class="font-400 font-13">{{roleName}}</p> -->
                        </div>
                        <div class="m-r-5">
                            <img src="{{userImgUrl}}" class="user-profileImg" onerror="this.src='./assets/img/users/default-profile.png';">
                        </div>
                    </div>
                    <nz-dropdown-menu #menu="nzDropdownMenu">
                        <ul class="profile-menu-list" nz-menu nzSelectable>
                            <li class="profile-menu-list-item" nz-menu-item routerLink="/profile" [state]="{ ignoreLoadingBar: true }">
                                <a href="javascript:void(0)" >
                                    <i nz-icon nzType="user" nzTheme="outline"></i> Profile
                                </a>
                            </li>
                            <li class="profile-menu-list-item" nz-menu-item *ngIf="!vendorPlan">
                                <a href="javascript:void(0)" routerLink="/business-partner" [state]="{ ignoreLoadingBar: true }">
                                    <i nz-icon nzType="team" nzTheme="outline"></i> Business Partner
                                </a>
                            </li>
                            <li class="profile-menu-list-item" nz-menu-item>
                                <a href="javascript:void(0)" routerLink="/tickets" [state]="{ ignoreLoadingBar: true }">
                                    <i nz-icon nzType="bug" nzTheme="outline"></i> Tickets
                                </a>
                            </li>
                            <li class="profile-menu-list-item" nz-menu-item (click)="openChat()">
                                <a href="javascript:void(0)">
                                    <i nz-icon nzType="comment" nzTheme="outline"></i> Chat
                                </a>
                            </li>
                            <li class="profile-menu-list-item" nz-menu-item>
                                <a href="javascript:void(0)" routerLink="/faq" [state]="{ ignoreLoadingBar: true }">
                                    <i nz-icon nzType="question-circle" nzTheme="outline"></i> FAQ
                                </a>
                            </li>
                            <li class="profile-menu-list-item" nz-menu-item *ngIf="showCustomerPortal" (click)="customerPortalClicked()">
                                <a href="javascript:void(0)">
                                    <i nz-icon nzType="sliders" nzTheme="outline"></i> Customer Portal
                                </a>
                            </li>
                            <li class="profile-menu-list-item" nz-menu-item>
                                <a href="javascript:void(0)" routerLink="/logout" [state]="{ ignoreLoadingBar: true }">
                                    <i nz-icon nzType="poweroff" nzTheme="outline"></i> Logout
                                </a>
                            </li>
                        </ul>
                    </nz-dropdown-menu>
                </div>
            </div>
        </div>
    </nz-header>

    <nz-content>
        <router-outlet></router-outlet>
    </nz-content>

</nz-layout>
<!--End Main Screen-->


<div *ngIf="showCompanyModal" class="container-fluid contentSection" style="padding:0px !important;position:fixed;top:0;z-index:1050;background: rgb(0 0 0 / 21%);backdrop-filter: blur(8px);">    
    <div nz-row [nzGutter]="[2]" nzJustify="space-around" nzAlign="middle" style="height:100vh;">
        <div nz-col nzSpan="7" class="partnerContainer">
            
            <div nz-row [nzGutter]="[2]">
                <div nz-col nzSpan="24" class="m-b-10 text-center">
                    <div class="p-15">
                        <h1 class="partnerHeading">Choose an company</h1>
                        <p class="partnerDesc">or continue with <span class="currentCompany" (click)="showCompanyModal=false;this.loadingSite = false;">{{userdetails.companyName}}</span></p>
                    </div>
                </div>
            </div>
            <div nz-row [nzGutter]="[2]">
                <div nz-col nzSpan="24">
                    <nz-input-group nzSize="large" [nzSuffix]="suffixIconSearch" class="border-0">
                        <input type="text" [(ngModel)]="SearchPartnerCompany" class="font-600" nz-input placeholder="Search Partner Company..." />
                    </nz-input-group>
                    <ng-template #suffixIconSearch>
                        <i nz-icon nzType="search"></i>
                    </ng-template>
                </div>
                <div nz-col nzSpan="24">
                    <div class="demo-infinite-container">
                        <nz-list [nzBordered]="false" nzSize="small">
                            <ng-container *ngFor="let option of userdetails.partnerCompany | filter:SearchPartnerCompany">
                                <nz-list-item class="pointer border-0" (click)="getPartnerData( option )">
                                    <nz-list-item-meta >
                                        <nz-list-item-meta-title>
                                            <a href="javascript:void(0)" class="text-inverse font-15 font-600">{{ option.partnerCompanyName }}</a>
                                        </nz-list-item-meta-title>
                                        <nz-list-item-meta-description>
                                            {{option.partnerCompanySiteUrl === 'walmart' ? 'abc':option.partnerCompanySiteUrl}}
                                        </nz-list-item-meta-description>
                                    </nz-list-item-meta>
                                    <div style="position:absolute;right:0;margin-right:60px;">
                                        <img width="80px" alt="logo" src="{{ partnerCompanyLogo(option.partnerCompanySiteUrl, option.fileURL) }}" />
                                    </div>
                                    <div class="divider-list"></div>
                                </nz-list-item>
                            </ng-container>
                        </nz-list>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>

   

<!--No Interent Connection-->
<div *ngIf="!isConnected" class="noInternetOverlap">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-12">
                <div class="connectionText">
                    <h1>INTERNET NOT FOUND</h1>
                    <!-- <img src="./assets/img/oninternet.svg" alt="nointernet" class="text-center w-100" /> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!--No Interent Connection-->


