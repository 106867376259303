import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CompanyDetailsService } from '../../api-service/companyDetails/companyDetails.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  unreadNotificationCount: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);

  constructor( private companyDetailsService: CompanyDetailsService ) { }

  getUnReadNotificationCount() {
    let returnValue:number;
    this.unreadNotificationCount.asObservable().subscribe((value) => {
      returnValue = value;
    });
    return returnValue;
  }

  refreshNotificationCount(companyId, userId) {
    const hostName = JSON.parse(localStorage.getItem('siteSettings'));
    let filters: any;
    if (hostName.domainName === 'grcarapp.com') {
      filters = `filter[where][receiverCompanyId]=${companyId}&filter[where][receiverUserId]=${userId}`;
    } else {
      filters = `filter[where][receiverCompanyId]=${companyId}&filter[where][receiverUserId]=${userId}&filter[where][applicationId]=${hostName.applicationId}`;
    }

    this.companyDetailsService.getNotificationsCount(filters).subscribe((res: any) => {
      if (res.success === true) {
        const unreadCount = res.data.map((x: any) => x.count).map((y: any) => y.unread).reduce((a, b) => +a + +b, 0);
        this.setUnReadNotificationCount(unreadCount + 1);
      }
    });
  }

  setUnReadNotificationCount(value: number) {
    this.unreadNotificationCount.next(value);
  }

}
